import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 会員規約、会員名表示フラグ情報取得
 *
 * @param
 */
export function getSiteSetting(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.SETTING.SITE_SETTING.SITE_SETTINGS, { id: param.id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新処理
 *
 * @param
 */
export function updateSiteSetting(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.SETTING.SITE_SETTING.SITE_SETTINGS, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
