import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box, Link,
} from '@material-ui/core';
import format from 'date-fns/format';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Yearpicker from '../../../components/atoms/yearpicker/Yearpicker';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import EventCalendarListTable from './eventCalendarListTable/EventCalendarListTable';
import Validation from './validation';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiInitEventCalendarList,
  actCallApiEventCalendarListSearch,
  actSetSearchCondition,
} from '../../../redux/event/eventCalendarList/action';

class EventCalendarList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallApiInitEventCalendarList());

    this.breadcrumbsText = [
      { name: '予約カレンダー作成' },
    ];

    let isMain = false;
    let searchStore = null;
    let searchContents = null;
    let searchDate = format(new Date(), 'yyyy');

    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    if (props.eventCalendarList.searchCondition) {
      searchStore = props.eventCalendarList.searchCondition.searchStore;
      searchContents = props.eventCalendarList.searchCondition.searchContents;
      searchDate = props.eventCalendarList.searchCondition.searchDate;
    }

    this.state = {
      isMain,
      stores: [],
      contents: [],
      storeContentLinks: [],
      searchStore,
      searchContents,
      searchDate,
      loading: false,
      datas: [],
      linkedContents: '',
      dateMessage: '',
      contentsMessage: '',
    };
  }

  componentDidMount() {
    document.title = '予約カレンダー作成';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.initSearch(this.props.common.user.store);
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setLoading(this.props.common.isLoading);
    }
    if (prevProps.eventCalendarList.searchSelects !== this.props.eventCalendarList.searchSelects) {
      if (this.props.eventCalendarList.searchSelects) {
        this.setSelects(this.props.eventCalendarList.searchSelects);
      }
    }
    if (prevProps.eventCalendarList.datas !== this.props.eventCalendarList.datas) {
      this.setDatas(this.props.eventCalendarList.datas);
    }
  }

  setSelects(selects) {
    const { searchStore, searchContents, searchDate } = this.state;
    const linkedContents = this.mapStoreContentLinks(searchStore, selects.storeContentLinks);
    const selectsContents = selects.contents.filter(
      (contentFilter) => selects.contents && linkedContents.includes(contentFilter.id),
    );
    let contents = selectsContents.length !== 0 ? selectsContents[0] : null;
    if (searchContents) { contents = searchContents; }
    this.setState({
      stores: selects.stores,
      contents: selects.contents,
      storeContentLinks: selects.storeContentLinks,
      linkedContents,
      searchContents: contents,
    });
    if (contents) {
      this.search(searchStore, contents, searchDate);
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setLoading(loading) {
    this.setState({ loading });
  }

  setSearchCondition = () => {
    const { searchStore, searchContents, searchDate } = this.state;
    this.props.dispatch(actSetSearchCondition({
      searchStore, searchContents, searchDate,
    }));
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    this.setState({ searchStore: value });
    if (!value) {
      return;
    }
    const { searchContents, storeContentLinks } = this.state;

    const linkedContents = this.mapStoreContentLinks(value, storeContentLinks);

    if (searchContents && !linkedContents.includes(searchContents.id)) {
      this.setState({ searchContents: null });
    }
    this.setState({ linkedContents });
  }

  onContentChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchContents: value,
      contentsMessage: Validation.formValidate('contents', value),
    });
  }

  onDateChange = (_, values) => {
    this.setState({
      searchDate: values,
      dateMessage: Validation.formValidate('year', values),
    });
  }

  onSearch = () => {
    const {
      searchStore, searchContents, searchDate, dateMessage,
    } = this.state;
    const contentsMessage = Validation.formValidate('contents', searchContents);
    this.setState({ contentsMessage });
    if (dateMessage || contentsMessage) return;
    this.setSearchCondition();
    this.search(searchStore, searchContents, searchDate);
  }

  mapStoreContentLinks = ((values, storeContentLinks) => storeContentLinks
    .filter((link) => link.mstStoreId === values.id)
    .map((link) => link.mstContentId)
  );

  search = (searchStore, searchContents, searchDate) => {
    this.props.dispatch(actCallApiEventCalendarListSearch(
      {
        searchStoreId: searchStore && searchStore.id,
        searchContentId: searchContents && searchContents.id,
        searchDate,
      },
    ));
  }

  initSearch(searchStore) {
    this.setState({ searchStore });
  }

  render() {
    const {
      isMain, stores, contents, dateMessage,
      searchStore, searchContents, searchDate, datas, linkedContents,
      loading, contentsMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex" mt={1}>
              <Box display={isMain ? '' : 'none'} width="20%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" disableClearable />
              </Box>
              <Box width="20%">
                <FormTitle title="コンテンツ" />
                <SearchSelectBox
                  options={(searchStore) ? (
                    contents.filter((contentFilter) => contents
                    && linkedContents.includes(contentFilter.id))
                  ) : (contents)}
                  value={searchContents}
                  onChange={this.onContentChange}
                  name="searchContents"
                  disableClearable
                  error={contentsMessage !== ''}
                />
                <FormErrorText>{contentsMessage}</FormErrorText>
              </Box>
              <Box width="20%" ml={2}>
                <FormTitle title="表示年" />
                <Yearpicker
                  handleChange={this.onDateChange}
                  value={searchDate}
                  error={dateMessage !== ''}
                />
                <FormErrorText>{dateMessage}</FormErrorText>
              </Box>
            </Box>
          </SearchBox>
          {datas.length > 0 && (
            <EventCalendarListTable
              datas={datas}
              onSearch={this.onSearch}
              isMain={isMain}
            />
          )}
          {!loading && searchContents && datas.length === 0 && (
            <Box textAlign="center" mt={5} boxShadow={3} p={5}>
              <Box fontWeight="fontWeightBold" m={1} fontSize={17}>ルールが適用されていません。</Box>
              <Box m={1} fontWeight="fontWeightBold" fontSize={17}>
                <Link href="/rules">こちら</Link>
                からルールを適用してください。
              </Box>
            </Box>
          )}
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventCalendarList: state.eventCalendarList,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(EventCalendarList));
