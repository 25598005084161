import React from 'react';
import { Route, Switch } from 'react-router-dom';

import urls from '../../constants/urls';

import ApplicationError from './ApplicationError';
import NotFound from './NotFound';

import Home from '../other/home/Home';

import SystemUserList from '../userSetting/systemUserList/SystemUserList';
import RoleList from '../userSetting/roleList/RoleList';
import RoleDetail from '../userSetting/roleDetail/RoleDetail';

import RuleList from '../setting/ruleList/RuleList';
import BannerImageList from '../setting/bannerImageList/BannerImageList';
import MailList from '../setting/mailList/MailList';
import MailDetail from '../setting/mailDetail/MailDetail';
import SiteSetting from '../setting/siteSetting/SiteSetting';
import SurveySetting from '../setting/surveySetting/SurveySetting';

import BookingList from '../booking/bookingList/BookingList';
import BookingDetail from '../booking/bookingDetail/BookingDetail';
import SubscriberList from '../booking/subscriberList/SubscriberList';
import RoomSchedule from '../booking/roomSchedule/RoomSchedule';
import GolfSchedule from '../booking/golfSchedule/GolfSchedule';

import MemberList from '../member/memberList/MemberList';
import IndividualEntry from '../member/entry/individualEntry/IndividualEntry';
import CorporationEntry from '../member/entry/corporationEntry/CorporationEntry';

import EventWeekScheduleList from '../event/eventWeekScheduleList/EventWeekScheduleList';
import EventWeekScheduleDetail from '../event/eventWeekScheduleDetail/EventWeekScheduleDetail';
import EventCalendarList from '../event/eventCalendarList/EventCalendarList';
import EventCalendarDetailRoom from '../event/eventCalendarDetail/room/EventCalendarDetailRoom';
import EventCalendarDetailGolf from '../event/eventCalendarDetail/golf/EventCalendarDetailGolf';

import SchoolWeekScheduleList from '../school/schoolWeekScheduleList/SchoolWeekScheduleList';
import SchoolWeekScheduleDetail from '../school/schoolWeekScheduleDetail/SchoolWeekScheduleDetail';
import MemberLinkage from '../school/memberLinkage/MemberLinkage';
import WeekSelect from '../school/weekSelect/WeekSelect';
import SchoolCalenderList from '../school/schoolCalenderList/SchoolCalenderList';

import CalendarScheduleDetail from '../share/calendarScheduleDetail/CalendarScheduleDetail';

import RoomInfo from '../info/roomInfo/RoomInfo';
import ClassInfomation from '../info/classInfomation/ClassInfomation';
import TeacherContactTaskList from '../info/teacherContactTaskList/TeacherContactTaskList';
import AttendanceEntryList from '../info/attendanceEntryList/AttendanceEntryList';

import TeacherList from '../master/teacherList/TeacherList';
import NoticeList from '../master/notice/NoticeList';
import NoticeDetail from '../master/notice/NoticeDetail';
import MemberDetail from '../member/memberDetail/MemberDetail';
import HolidayList from '../setting/holiday/HolidayList';

export default function Routes() {
  return (
    <Switch>
      <Route exact path={urls.USER_SETTING.SYSTEM_USERS} component={SystemUserList} />
      <Route exact path={urls.USER_SETTING.ROLES} component={RoleList} />
      <Route exact path={urls.USER_SETTING.ROLE_DETAIL} component={RoleDetail} />

      <Route exact path={urls.SETTING.RULES} component={RuleList} />
      <Route exact path={urls.SETTING.BANNERS} component={BannerImageList} />
      <Route exact path={urls.SETTING.MAIL_LIST} component={MailList} />
      <Route exact path={urls.SETTING.MAIL_DETAIL} component={MailDetail} />
      <Route exact path={urls.SETTING.SITE_SETTING} component={SiteSetting} />
      <Route exact path={urls.SETTING.SURVEY_SETTING} component={SurveySetting} />
      <Route exact path={urls.SETTING.HOLIDAY} component={HolidayList} />

      <Route exact path={urls.BOOKING.BOOKING_LIST} component={BookingList} />
      <Route exact path={urls.BOOKING.BOOKING_DETAIL} component={BookingDetail} />
      <Route exact path={urls.BOOKING.SUBSCRIBER_LIST} component={SubscriberList} />
      <Route exact path={urls.BOOKING.ROOM_SCHEDULES} component={RoomSchedule} />
      <Route exact path={urls.BOOKING.GOLF_SCHEDULES} component={GolfSchedule} />

      <Route exact path={urls.MEMBER.MEMBER_LIST} component={MemberList} />
      <Route exact path={urls.MEMBER.INDIVIDUAL_ENTRY} component={IndividualEntry} />
      <Route exact path={urls.MEMBER.CORPORATION_ENTRY} component={CorporationEntry} />
      <Route exact path={urls.MEMBER.MEMBER_DETAIL} component={MemberDetail} />

      <Route exact path={urls.EVENT.WEEK_SCHEDULES} component={EventWeekScheduleList} />
      <Route exact path={urls.EVENT.WEEK_SCHEDULES_DETAIL} component={EventWeekScheduleDetail} />
      <Route exact path={urls.EVENT.EVENT_CALENDARS} component={EventCalendarList} />
      <Route exact path={urls.EVENT.EVENT_CALENDARS_DETAIL} component={CalendarScheduleDetail} />
      <Route exact path={urls.EVENT.ROOM_CALENDAR_DETAIL} component={EventCalendarDetailRoom} />
      <Route exact path={urls.EVENT.GOLF_CALENDAR_DETAIL} component={EventCalendarDetailGolf} />

      <Route exact path={urls.SCHOOL.WEEK_SCHEDULES} component={SchoolWeekScheduleList} />
      <Route exact path={urls.SCHOOL.WEEK_SCHEDULE_DETAIL} component={SchoolWeekScheduleDetail} />
      <Route exact path={urls.SCHOOL.MEMBER_LINKAGE} component={MemberLinkage} />
      <Route exact path={urls.SCHOOL.WEEK_SELECT} component={WeekSelect} />
      <Route exact path={urls.SCHOOL.SCHOOL_CALENDARS} component={SchoolCalenderList} />
      <Route exact path={urls.SCHOOL.SCHOOL_CALENDARS_DETAIL} component={CalendarScheduleDetail} />

      <Route exact path={urls.SYSTEM_ERROR} component={ApplicationError} />
      <Route exact path={urls.OTHER.HOME} component={Home} />

      <Route exact path={urls.INFO.ROOM_INFO} component={RoomInfo} />
      <Route exact path={urls.INFO.CLASS_INFOMATION} component={ClassInfomation} />
      <Route exact path={urls.INFO.TEACHER_CONTACT_TASK_LIST} component={TeacherContactTaskList} />
      <Route exact path={urls.INFO.ATTENDANCE_ENTRY_LIST} component={AttendanceEntryList} />

      <Route exact path={urls.MASTER.TEACHER_LIST} component={TeacherList} />
      <Route exact path={urls.MASTER.NOTICE_LIST} component={NoticeList} />
      <Route exact path={urls.MASTER.NOTICE_DETAIL} component={NoticeDetail} />

      <Route component={NotFound} />
    </Switch>
  );
}
