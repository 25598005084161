import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から会員情報を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_SCHEDULE_LIST.SCHOOL_SCHEDULE_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 選択肢取得
 *
 * @returns
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_SCHEDULE_LIST.SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} deleteList
 */
export function deleteSchoolScheduleList(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.SCHOOL.SCHOOL_SCHEDULE_LIST.SCHOOL_SCHEDULE_LIST, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 定期曜日別スケジュール追加
 *
 *
 */
export function insertSchoolSchedule(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.SCHOOL_SCHEDULE_LIST.SCHOOL_SCHEDULE_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 *  IDによるスクール型曜日別スケジュールヘッダーを取得する
 *
 * @param {object} id
 */
export function searchSchoolScheduleHeaderInfo(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.SCHOOL.SCHOOL_SCHEDULE_LIST.SCHOOL_SCHEDULE_HEADER_INFO, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
