import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { TextField, Box } from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiGetSelectList, actCallApiGetSystemUsers,
  actCallApiSystemUserDelete, actResetSystemUserFlg,
} from '../../../redux/userSetting/systemUserList/action';
import SystemUserDetail from '../systemUserDetail/SystemUserDetail';
import ChangePassword from '../../other/changePassword/ChangePassword';

class SystemUserList extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallApiGetSelectList());

    this.breadcrumbsText = [
      { name: 'システムユーザー' },
    ];

    this.headCells = [
      { id: 'name', numeric: false, label: '名前' },
      { id: 'authName', numeric: false, label: '権限' },
      { id: 'storeName', numeric: false, label: '店舗' },
      { id: 'email', numeric: false, label: 'メールアドレス' },
    ];

    let searchStore = null;
    let isMain = false;
    if (this.props.common.user.store) {
      searchStore = this.props.common.user.store;
      isMain = props.common.user.main;
      props.dispatch(
        actCallApiGetSystemUsers({
          page: 0,
          records: 10,
          searchStoreId: searchStore && searchStore.id,
        }),
      );
    }

    this.state = {
      isMain,
      selects: {
        stores: [],
        roles: [],
      },
      datas: {},
      searchCondition: {
        searchStore,
        searchRole: null,
        searchName: '',
        page: 0,
        records: 10,
      },
      isSuccessSnackOpen: false,
      successSnackMessage: '',
      isDetailModalOpen: false,
      isChangePasswordOpen: false,
      selectedRow: {},
      detailSelectedId: null,
    };
  }

  componentDidMount() {
    document.title = 'システムユーザー';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.systemUsers.datas !== this.props.systemUsers.datas) {
      if (this.props.systemUsers.datas) {
        this.setDatas(this.props.systemUsers.datas);
      }
    }
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.systemUsers.selects !== this.props.systemUsers.selects) {
      if (this.props.systemUsers.selects) {
        this.setSelects(this.props.systemUsers.selects);
      }
    }
    if (prevProps.systemUsers.isDeleteSuccess !== this.props.systemUsers.isDeleteSuccess) {
      if (this.props.systemUsers.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setUser(user) {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStoreId: user.store,
    };
    this.setState({
      isMain: user.main,
      searchCondition: tempCondition,
    });
    this.search(tempCondition);
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setDeleteSuccess() {
    this.onClickSearch();
    this.setState({
      isSuccessSnackOpen: true,
      successSnackMessage: 'システムユーザーの削除が完了しました',
    });
  }

  setChangePasswordSuccess = () => {
    const { selectedRow } = this.state;
    this.setState({
      isSuccessSnackOpen: true,
      isChangePasswordOpen: false,
      successSnackMessage: `${selectedRow.name}のパスワード変更が完了しました`,
    });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  onClickSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (value) => {
    this.setState({ isSuccessSnackOpen: false });
    this.props.dispatch(actResetSystemUserFlg());
    this.props.dispatch(
      actCallApiSystemUserDelete({
        deleteList: value,
      }),
    );
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessSnackOpen: false });
  }

  onAdd = () => {
    this.setState({ isDetailModalOpen: true, detailSelectedId: null });
  }

  onDetail = (_, row) => {
    this.setState({ isDetailModalOpen: true, detailSelectedId: row.id });
  }

  onChangePassword = (_, row) => {
    this.setState({
      isChangePasswordOpen: true,
      selectedRow: row,
    });
  }

  handleDetailClose = () => {
    this.setState({ isDetailModalOpen: false });
  }

  passwordDialogClose = () => {
    this.setState({ isChangePasswordOpen: false });
  }

  upsertSuccess = () => {
    this.setState({ isDetailModalOpen: false });
    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  search = (condition) => {
    this.props.dispatch(
      actCallApiGetSystemUsers({
        ...condition,
        searchStoreId: condition.searchStore && condition.searchStore.id,
        searchRoleId: condition.searchRole && condition.searchRole.id,
      }),
    );
  }

  render() {
    const {
      selects,
      datas,
      isMain,
      searchCondition,
      isSuccessSnackOpen,
      isDetailModalOpen,
      detailSelectedId,
      isChangePasswordOpen,
      successSnackMessage,
      selectedRow,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onClickSearch}>
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} width="20%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox
                  options={selects.stores}
                  value={searchCondition.searchStore}
                  onChange={this.onChange}
                  name="searchStore"
                />
              </Box>

              <Box width="20%">
                <FormTitle title="権限" />
                <SearchSelectBox
                  options={
                    searchCondition.searchStore ? selects.roles.filter(
                      (data) => data.mstStoreId === searchCondition.searchStore.id,
                    ) : selects.roles
                  }
                  value={searchCondition.searchRole}
                  onChange={this.onChange}
                  name="searchRole"
                />
              </Box>

              <Box ml={2}>
                <FormTitle title="名前" />
                <TextField name="searchName" variant="outlined" fullWidth value={searchCondition.searchName} onChange={this.onChange} />
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="システムユーザーの追加"
            deleteButtonLabel="システムユーザーの削除"
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            deleteDialogTitle="選択したシステムユーザーの削除"
            deleteDialogContent="選択したシステムユーザーを削除します。この操作は元に戻すことができません。"
            onDetail={this.onDetail}
            onSecondFunc={this.onChangePassword}
            secondFuncText="パスワードを変更"
          />
          <SuccessSnackbar
            open={isSuccessSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />
        </MainBody>

        <SystemUserDetail
          open={isDetailModalOpen}
          id={detailSelectedId}
          handleClose={this.handleDetailClose}
          upsertSuccess={this.upsertSuccess}
        />
        <ChangePassword
          open={isChangePasswordOpen}
          onClose={this.passwordDialogClose}
          userId={selectedRow.id}
          flg={0}
          name={selectedRow.name}
          changePasswordSuccess={this.setChangePasswordSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ systemUsers: state.systemUsers, common: state.common });

export default connect(mapStateToProps)(SystemUserList);
