import isValid from 'date-fns/isValid';

const dateValidation = (value) => {
  if (!value) return '選択してください';
  if (!isValid(new Date(`${value}/01`))) return '無効な日付です';
  return '';
};
const storeValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};
const contentValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'date':
      return dateValidation(value);
    case 'store':
      return storeValidation(value);
    case 'contents':
      return contentValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
