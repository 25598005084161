import { call, put, takeLatest } from 'redux-saga/effects';
import {
  searchCalendarMenu, searchGolfCalendarMenu, saveCalendarSchedule, saveGolfCalendarSchedule,
} from '../../../services/share/calendarScheduleMenu.service';
import {
  ACTION_TYPE,
  actCallApiCalendarScheduleSearchSuccess, actCallApiCalendarScheduleSaveSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 予約枠カレンダー取得
 * @param
 */
function* callApiCalendarScheduleMenuSearch(param) {
  try {
    let data = null;
    if (param.contentId !== 2) {
      data = yield call(searchCalendarMenu, param.id);
    }
    if (param.contentId === 2) {
      data = yield call(searchGolfCalendarMenu, param.id);
    }
    yield put(actCallApiCalendarScheduleSearchSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠カレンダー作成、更新
 * @param
 */
function* callApiCalendarScheduleSave(param) {
  try {
    if (param.contentId !== 2) {
      yield call(saveCalendarSchedule, param.data);
    }
    if (param.contentId === 2) {
      yield call(saveGolfCalendarSchedule, param.data);
    }
    yield put(actCallApiCalendarScheduleSaveSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCalendarScheduleMenu() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SEARCH, callApiCalendarScheduleMenuSearch,
  );
  yield takeLatest(ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SAVE, callApiCalendarScheduleSave);
}
