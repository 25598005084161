import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  LinearProgress, Box, Typography, TextField,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import Validation from './validation';
import {
  actApiEventScheduleDetailUpdate, actApiEventScheduleDetailFlgReset,
} from '../../../redux/event/eventWeekScheduleDetail/action';
import { actApiEventScheduleListCreate, actApiEventScheduleListCopy } from '../../../redux/event/eventWeekScheduleList/action';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class EventWeekScheduleDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: {
        id: null,
        store: {
          name: '',
        },
        content: {},
        name: '',
      },
      message: {
        content: '',
        store: '',
        name: '',
      },
      loading: false,
      linkedContents: '',
      changeLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    const { header } = this.state;
    const { storeContentLinks } = this.props;

    const linkedContents = this.mapStoreContentLinks(value, storeContentLinks);

    let { content } = header;
    if (header.content && !linkedContents.includes(header.content.id)) {
      content = null;
    }
    this.setState({
      header: {
        ...header, store: value, content,
      },
      linkedContents,
    });
  }

  onChange = (event) => {
    const { header, message } = this.state;
    const { value, name } = event.target;
    this.setState({
      header: { ...header, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  handleSave = () => {
    const { header, message } = this.state;
    const { copyFlg } = this.props;

    let tempMessage = message;
    let isError = false;
    Object.keys(header).map(
      (key) => {
        const msg = Validation.formValidate(key, header[key]);
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        if (msg) {
          isError = true;
        }
        return msg;
      },
    );
    this.setState({ message: tempMessage });

    if (copyFlg === 1 && !isError) {
      this.props.dispatch(actApiEventScheduleDetailFlgReset());
      this.props.dispatch(actApiEventScheduleListCreate(
        {
          mstStoreId: header.store.id,
          mstContentId: header.content.id,
          name: header.name,
        },
      ));
    }

    if (copyFlg === 2 && !isError) {
      this.props.dispatch(actApiEventScheduleDetailFlgReset());
      this.props.dispatch(actApiEventScheduleDetailUpdate(
        {
          id: header.id,
          name: header.name,
        },
      ));
    }

    if (copyFlg === 3 && !isError) {
      this.props.dispatch(actApiEventScheduleDetailFlgReset());
      this.props.dispatch(actApiEventScheduleListCopy(
        {
          id: header.id,
          mstStoreId: header.store.id,
          mstContentId: header.content.id,
          name: header.name,
        },
      ));
    }
  }

  mapStoreContentLinks = (values, storeContentLinks) => {
    const links = storeContentLinks.filter(
      (link) => link.mstStoreId === values.id,
    ).map((link) => link.mstContentId) || [];
    return links;
  }

  init = () => {
    const { header, copyFlg } = this.props;
    let tempHeader = {
      id: null,
      store: this.props.common.user.store,
      content: {
        name: '',
      },
      name: '',
    };
    let linkedContents = [];
    if (copyFlg === 1) {
      linkedContents = this.mapStoreContentLinks(
        this.props.common.user.store, this.props.storeContentLinks,
      );
    }
    if (copyFlg !== 1) {
      tempHeader = header;
    }

    this.setState({
      header: tempHeader,
      message: {
        content: '',
        name: '',
      },
      loading: false,
      linkedContents,
    });
  }

  render() {
    const {
      isMain, open, handleClose, copyFlg,
      stores, contents,
    } = this.props;

    const {
      header, message, loading, linkedContents, changeLoading,
    } = this.state;

    let dialogTitle = '';
    let dialogSaveLabel = '';
    if (copyFlg === 1) {
      dialogTitle = '曜日別スケジュールの追加';
      dialogSaveLabel = '作成';
    }
    if (copyFlg === 2) {
      dialogTitle = '管理名の変更';
      dialogSaveLabel = '変更';
    }
    if (copyFlg === 3) {
      dialogTitle = '曜日別スケジュールのコピー';
      dialogSaveLabel = 'コピーして作成';
    }

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <LinearProgress style={{ display: loading ? '' : 'none' }} />
          <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
            <Box mb={3} display={isMain ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" my={1} fontSize={15}>
                {copyFlg === 1 ? (
                  <Box>
                    <FormTitle title="店舗" isRequired />
                    <SearchSelectBox
                      options={stores}
                      value={header.store}
                      onChange={this.onStoreChange}
                      name="store"
                      disableClearable
                    />
                  </Box>
                ) : (
                  <Box>
                    <FormTitle title="店舗" isRequired />
                    <Typography variant="subtitle1" gutterBottom>{header.store.name}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mb={3}>
              {copyFlg === 1 ? (
                <Box>
                  <FormTitle title="コンテンツ" isRequired />
                  <SearchSelectBox
                    options={
                      contents.filter((contentFilter) => contents
                      && linkedContents.includes(contentFilter.id))
                    }
                    value={header.content}
                    onChange={this.onChange}
                    name="content"
                    disableClearable
                    error={message.content !== ''}
                  />
                  <FormErrorText>{message.content}</FormErrorText>
                </Box>
              ) : (
                <Box>
                  <FormTitle title="コンテンツ" isRequired />
                  <Box>{header.content.name}</Box>
                </Box>
              )}
            </Box>
            <Box>
              <FormTitle title="管理名" isRequired />
              <TextField
                onChange={this.onChange}
                variant="outlined"
                name="name"
                value={header.name}
                inputProps={{ maxLength: 50 }}
                style={{ width: '100%' }}
                error={message.name !== ''}
              />
              <FormErrorText>{message.name}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions style={{ display: loading ? 'none' : '' }}>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton
              onClick={this.handleSave}
              label={dialogSaveLabel}
              loading={changeLoading}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(EventWeekScheduleDialog));
