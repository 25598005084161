import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Drawer, IconButton, Divider, Box, Chip, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { actCallApiSubscriberDetail, actResetUpdateSuccessFlg, actCallApiSubscriberCancel } from '../../../redux/booking/subscriberList/action';
import SubscriberUpdateStatusDialog from './SubscriberUpdateStatusDialog';
import BookingMemberConfirmDialog from '../bookingDetail/BookingMemberConfirmDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const styles = (theme) => ({
  drawer: {
    width: 500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  transferSourceChip: {
    color: '#fff',
    backgroundColor: '#00b050',
  },
  experienceChip: {
    color: '#fff',
    backgroundColor: '#e67d05',
  },
});

function getObject() {
  return {
    bookingCode: null,
    memberId: null,
    memberNo: null,
    corporationName: null,
    memberName: null,
    memberNameKana: null,
    targetDate: null,
    targetTime: null,
    menuName: null,
    contentId: null,
    contentName: null,
    bookingStatusName: null,
    memberStatus: null,
    memberStatusName: null,
    content: null,
    annotation: null,
    roomName: null,
    teacherName: null,
    materialFee: null,
    transferAheadId: null,
  };
}

class MenuDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getObject(),
      updateStatusOpen: false,
      transferCancelOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isClickDrawer !== this.props.isClickDrawer) {
      if (this.props.isClickDrawer) {
        this.init();
      }
    }
    if (prevProps.subscriber.isGetDetailSuccess !== this.props.subscriber.isGetDetailSuccess) {
      if (this.props.subscriber.isGetDetailSuccess) {
        this.setData(this.props.subscriber.data);
      }
    }
  }

  setData(data) {
    this.setState({
      data,
    });
  }

  handleOpenUpdateStatus = () => {
    this.props.dispatch(
      actResetUpdateSuccessFlg(),
    );

    this.setState({
      updateStatusOpen: true,
    });
  }

  handleCloseUpdateStatus = () => {
    this.setState({
      updateStatusOpen: false,
    });
  }

  handleTransferCancel = () => {
    const { data } = this.state;
    this.setState({ transferCancelOpen: false });
    this.props.dispatch(actResetUpdateSuccessFlg());
    this.props.dispatch(
      actCallApiSubscriberCancel(data.bookingCode),
    );
  }

  handleTransferModalOpen = () => {
    this.setState({ transferCancelOpen: true });
  }

  handleTransferModalClose = () => {
    this.setState({ transferCancelOpen: false });
  }

  init() {
    const { row, handleClearDrawer } = this.props;
    handleClearDrawer();
    this.props.dispatch(actResetUpdateSuccessFlg());
    this.props.dispatch(actCallApiSubscriberDetail({
      bookingCode: row.bookingCode,
    }));
    this.setState({
      data: getObject(),
      updateStatusOpen: false,
    });
  }

  render() {
    const { classes, drawerOpen, handleDrawerClose } = this.props;
    const { data, updateStatusOpen, transferCancelOpen } = this.state;

    return (
      <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
            {data.memberName}
          </div>
          <Divider />
          <Box display={data.bookingCode ? '' : 'none'}>
            <Box p={2} fontSize={17}>
              <Box mb={2} display={data.memberNo && data.memberNo.substring(0, 3) === 'EXP' ? '' : 'none'}>
                <Chip label="体験者" className={classes.experienceChip} size="small" />
              </Box>
              <Box mb={2} display={data.transferAheadId ? '' : 'none'}>
                <Chip label={`${data.transferOriginDate}から振替`} size="small" className={classes.transferSourceChip} />
              </Box>
              <Box mb={2}>
                <FormTitle title="予約番号" />
                <Box>{data.bookingCode}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="会員番号" />
                <Box>{data.memberNo}</Box>
              </Box>
              <Box mb={2} display={data.corporationName ? '' : 'none'}>
                <FormTitle title="法人名" />
                <Box>{data.corporationName}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="名前" />
                <Box>{data.memberNameKana}</Box>
                <Box>{data.memberName}</Box>
              </Box>
              <Box mb={1}>
                <FormTitle title="ステータス" />
                <Box>{data.memberStatusName}</Box>
              </Box>
              <Box display="flex">
                <Button color="primary" onClick={this.handleOpenUpdateStatus} variant="contained" size="small">ステータスを変更する</Button>
                <Box ml={2} display={data.transferAheadId ? '' : 'none'}>
                  <Button color="secondary" onClick={this.handleTransferModalOpen} variant="contained" size="small">振替をキャンセルする</Button>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box fontSize={15} p={2} fontWeight="fontWeightBold">予約情報詳細</Box>
            <Divider />
            <Box p={2} fontSize={17}>
              <Box mb={1}><Chip label={data.bookingStatusName} size="small" /></Box>
              <Box mb={2}>
                <FormTitle title="実施日" />
                <Box>{`${data.targetDate} ${data.targetTime}`}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="コンテンツ" />
                <Box>{data.contentName}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="メニュー名" />
                <Box>{data.menuName}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="教材費" />
                <Box>{data.materialFee}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="管理費" />
                <Box>{data.managementFee}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="Room" />
                <Box>{data.roomName}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="内容" />
                <Box>{data.content}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="特記事項" />
                <Box>{data.annotation}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="先生名" />
                <Box>{data.teacherName}</Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
        <SubscriberUpdateStatusDialog
          open={updateStatusOpen}
          row={data}
          close={this.handleCloseUpdateStatus}
        />
        <BookingMemberConfirmDialog
          open={transferCancelOpen}
          handleExecute={this.handleTransferCancel}
          handleClose={this.handleTransferModalClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ subscriber: state.subscriber });

export default withStyles(styles)(connect(mapStateToProps)(MenuDetailDrawer));
