import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';

import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import MailListDataTable from './MailListDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

import {
  actCallInitMailList, actCallApiMailList, actChangeMailStopFlg,
  actSetSearchCondition, actChangeMailStopFlgReset,
} from '../../../redux/setting/mailList/action';
import urls from '../../../constants/urls';

class MailList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallInitMailList());

    this.breadcrumbsText = [
      { name: '自動配信メール設定' },
    ];

    let isMain = false;
    let searchStore = null;
    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    if (props.mails.searchCondition) {
      searchStore = props.mails.searchCondition.searchStore;
    }
    if (searchStore) {
      this.search(searchStore);
    }

    this.state = {
      isMain,
      stores: [],
      datas: [],
      searchStore,
      updateStopFlgSnackOpen: false,
      snackStopFlg: false,
    };
  }

  componentDidMount() {
    document.title = '自動配信メール設定';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.mails.datas !== this.props.mails.datas) {
      if (this.props.mails.datas) {
        this.setDatas(this.props.mails.datas);
      }
    }
    if (prevProps.mails.stores !== this.props.mails.stores) {
      if (this.props.mails.stores) {
        this.setStores(this.props.mails.stores);
      }
    }
    if (prevProps.mails.isChangeSuccess !== this.props.mails.isChangeSuccess) {
      if (this.props.mails.isChangeSuccess) {
        this.setUpdateStopFlg();
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main, searchStore: user.store });
    this.search(user.store);
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setUpdateStopFlg() {
    this.setState({ updateStopFlgSnackOpen: true });
    this.onSearch();
  }

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  }

  onStoreChangeSearch = (values) => {
    this.setState({ searchStore: values });

    this.props.dispatch(
      actCallApiMailList({
        searchStoreId:
        values && values.id,
      }),
    );
  }

  onSearch = () => {
    const {
      searchStore,
    } = this.state;
    this.search(searchStore);
  }

  search = (searchStore) => {
    this.props.dispatch(
      actCallApiMailList({
        searchStoreId:
        searchStore && searchStore.id,
      }),
    );
  }

  onChangeStopFlg = (_, id, stopFlg, type) => {
    this.props.dispatch(actChangeMailStopFlgReset());
    this.setState({ snackStopFlg: stopFlg, snackType: type });
    this.props.dispatch(actChangeMailStopFlg(id));
  }

  onMailDetail = (_, id) => {
    const { searchStore } = this.state;
    this.props.dispatch(actSetSearchCondition({
      searchStore,
    }));
    this.props.history.push({
      pathname: urls.SETTING.MAIL_DETAIL,
      state: {
        detailId: id,
      },
    });
  }

  handleUpdateStopFlgSnackbarClose = () => {
    this.setState({ updateStopFlgSnackOpen: false });
  }

  render() {
    const {
      stores,
      datas,
      isMain,
      searchStore,
      updateStopFlgSnackOpen,
      snackStopFlg,
      snackType,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          {isMain && (
            <SearchBox onSearch={this.onSearch}>
              <Box width="30%">
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" disableClearable />
              </Box>
            </SearchBox>
          )}
          <MailListDataTable
            rows={datas || []}
            onChangeStopFlg={this.onChangeStopFlg}
            onClick={this.onMailDetail}
          />
          <SuccessSnackbar
            open={updateStopFlgSnackOpen}
            handleClose={this.handleUpdateStopFlgSnackbarClose}
            message={snackStopFlg ? `${snackType}を配信開始しました` : `${snackType}を配信停止しました`}
          />
        </MainBody>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ mails: state.mails, common: state.common });

export default withRouter(connect(mapStateToProps)(MailList));
