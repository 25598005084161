import React from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, withStyles, TableContainer,
} from '@material-ui/core';

import WeekBookingCard from '../weekBookingCard/WeekBookingCard';

const styles = () => ({
  headerCell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  headerCellEnd: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  tableCell: {
    width: '14%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    padding: '0 10px 15px',
    verticalAlign: 'top',
    background: '#fff',
  },
  tableCellEnd: {
    width: '14%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: '0 10px 15px',
    verticalAlign: 'top',
    background: '#fff',
  },
  container: {
    maxHeight: '70vh',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  emptyTableCell: {
    textAlign: 'center',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    fontSize: '17px',
    background: '#fff',
  },
});

class WeekScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monMenus: [],
      tueMenus: [],
      wedMenus: [],
      thuMenus: [],
      friMenus: [],
      satMenus: [],
      sunMenus: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.datas !== this.props.datas) {
      if (this.props.datas) {
        this.setDivideWeekMenus(this.props.datas);
      }
    }
  }

  setDivideWeekMenus(datas) {
    let monMenus = [];
    let tueMenus = [];
    let wedMenus = [];
    let thuMenus = [];
    let friMenus = [];
    let satMenus = [];
    let sunMenus = [];

    if (datas) {
      monMenus = datas.filter((data) => data.week === 1);
      tueMenus = datas.filter((data) => data.week === 2);
      wedMenus = datas.filter((data) => data.week === 3);
      thuMenus = datas.filter((data) => data.week === 4);
      friMenus = datas.filter((data) => data.week === 5);
      satMenus = datas.filter((data) => data.week === 6);
      sunMenus = datas.filter((data) => data.week === 7);
    }
    this.setState({
      monMenus,
      tueMenus,
      wedMenus,
      thuMenus,
      friMenus,
      satMenus,
      sunMenus,
    });
  }

  render() {
    const {
      classes,
      onMenuUpdateDialog,
      schoolFlg,
      weeklyNumber,
      onMemberLinkage,
      datas,
    } = this.props;

    const {
      monMenus, tueMenus, wedMenus, thuMenus, friMenus, satMenus, sunMenus,
    } = this.state;

    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} align="center">月</TableCell>
              <TableCell className={classes.headerCell} align="center">火</TableCell>
              <TableCell className={classes.headerCell} align="center">水</TableCell>
              <TableCell className={classes.headerCell} align="center">木</TableCell>
              <TableCell className={classes.headerCell} align="center">金</TableCell>
              <TableCell className={classes.headerCell} align="center">土</TableCell>
              <TableCell className={classes.headerCellEnd} align="center">日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(datas && datas.length >= 1) && (
            <TableRow>
              <TableCell name="mon" className={classes.tableCell}>
                {monMenus.map((mon) => (
                  <WeekBookingCard
                    key={`mon-menu-${mon.id}`}
                    menuInfo={mon}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="tue" className={classes.tableCell}>
                {tueMenus.map((tue) => (
                  <WeekBookingCard
                    key={`tue-menu-${tue.id}`}
                    menuInfo={tue}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="wed" className={classes.tableCell}>
                {wedMenus.map((wed) => (
                  <WeekBookingCard
                    key={`wed-menu-${wed.id}`}
                    menuInfo={wed}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="thu" className={classes.tableCell}>
                {thuMenus.map((thu) => (
                  <WeekBookingCard
                    key={`thu-menu-${thu.id}`}
                    menuInfo={thu}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="fri" className={classes.tableCell}>
                {friMenus.map((fri) => (
                  <WeekBookingCard
                    key={`fri-menu-${fri.id}`}
                    menuInfo={fri}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="sat" className={classes.tableCell}>
                {satMenus.map((sat) => (
                  <WeekBookingCard
                    key={`sat-menu-${sat.id}`}
                    menuInfo={sat}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
              <TableCell name="sun" className={classes.tableCellEnd}>
                {sunMenus.map((sun) => (
                  <WeekBookingCard
                    key={`sun-menu-${sun.id}`}
                    menuInfo={sun}
                    onMenuUpdateDialog={onMenuUpdateDialog}
                    schoolFlg={schoolFlg}
                    weeklyNumber={weeklyNumber}
                    onMemberLinkage={onMemberLinkage}
                  />
                ))}
              </TableCell>
            </TableRow>
            )}
            {(!datas || datas.length < 1) && (
              <TableRow>
                <TableCell className={classes.emptyTableCell} colSpan={7}>
                  データがありません
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles)(WeekScheduleTable);
