import React, { Component } from 'react';
import format from 'date-fns/format';
import clsx from 'clsx';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Drawer, IconButton, Divider, Box, Button,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import beginner from '../../../public/assets/beginner.svg';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import {
  actCallApiGetRoomSchedule,
  actApiRoomScheduleFlgReset,
  actCallApiGetRoomScheduleDetail,
  actCallApiPostUpdateRoomMember,
  actCallApiRoomScheduleDeleteByDeteil,
} from '../../../redux/booking/roomSchedule/action';

const drawerWidth = 400;
const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: 700,
  },
  text: {
    textAlign: 'center',
    fontSize: '20px',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5) + 1,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  memberName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '1px',
  },
});

class RoomSearch extends Component {
  constructor(props) {
    super(props);

    let store = props.common.user ? props.common.user.store : null;
    store = props.roomSchedule.info ? props.roomSchedule.info.store : store;
    const date = props.date ? props.date : format(new Date(), 'yyyy/MM/dd');

    this.state = {
      roomData: {},
      date,
      store,
      roomMembers: [],
      isDelectCheckOpen: false,
      isSuccess: false,
      selectedId: null,
      statuses: [],
    };
    if (props.common.user) {
      this.search(store, date);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.isClick !== this.props.isClick) {
      if (this.props.isClick) {
        this.init();
      }
    }
    if (prevProps.roomSchedule.roomData !== this.props.roomSchedule.roomData) {
      if (this.props.roomSchedule.roomData) {
        this.setData(this.props.roomSchedule.roomData, this.props.roomSchedule.roomMembers);
      }
    }
    if (prevProps.roomSchedule.isUpdateMember !== this.props.roomSchedule.isUpdateMember) {
      if (this.props.roomSchedule.isUpdateMember) {
        this.reGetDatas();
      }
    }
    if (prevProps.roomSchedule.isDeleteByDetailSuccess
      !== this.props.roomSchedule.isDeleteByDetailSuccess) {
      if (this.props.roomSchedule.isDeleteByDetailSuccess) {
        this.deleteSuccess('貸し会議室スケジュールの削除が完了しました');
      }
    }
    if (prevProps.roomSchedule.isUpsertSuccessFlg !== this.props.roomSchedule.isUpsertSuccessFlg) {
      if (this.props.roomSchedule.isUpsertSuccessFlg && !this.props.roomSchedule.isDuplicateFlg
        && this.props.id) {
        this.init();
      }
    }
  }

  setData = (roomData, roomMembers) => {
    const { selects } = this.props;
    this.setState({
      selectedId: null,
      roomMembers,
      roomData,
      statuses: roomData.regular ? selects.regularStatuses : selects.eventStatuses,
    });
  }

  setUser(user) {
    const store = this.props.roomSchedule.info ? this.props.roomSchedule.info.store : user.store;
    const curDate = this.props.date ? this.props.date : format(new Date(), 'yyyy/MM/dd');

    this.setState({
      store,
    });
    this.search(store, curDate);
  }

  deleteCheck = () => {
    this.setState({
      isDelectCheckOpen: true,
      isSuccess: false,
    });
  }

  onDateChange = (_, date) => {
    this.setState({ date });
    const { store } = this.state;
    this.search(store, date);
    this.props.onDateChange(date);
  }

  onSelectClick = (_, id, paymentCheck) => {
    this.props.dispatch(actCallApiPostUpdateRoomMember({ id, ticketPaid: !paymentCheck }));
  }

  onClickStatusUpdate = () => {
    const { selectedId, changeStatus } = this.state;
    if (!changeStatus || !changeStatus.id) {
      return;
    }
    this.props.dispatch(actCallApiPostUpdateRoomMember({
      id: selectedId, status: changeStatus.id,
    }));
  };

  onEditClick = (_, selectedId, status, statusName) => {
    this.setState({ selectedId, changeStatus: { id: status, name: statusName } });
  }

  onChangeStatus = (event) => {
    this.setState({ changeStatus: event.target.value });
  }

  onEditCloseClick = () => {
    this.setState({ selectedId: null });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { date } = this.state;
    this.setState({ [name]: value });
    this.search(value, date);
    this.props.onStoreChange(value);
  }

  createData = (name, calories, fat, carbs, protein) => ({
    name, calories, fat, carbs, protein,
  })

  onDeleteClose = () => {
    this.setState({ isDelectCheckOpen: false });
  }

  onSnackbarClose = () => {
    this.setState({ isSuccess: false });
  }

  onDelete = () => {
    const { roomData } = this.state;
    this.props.dispatch(actApiRoomScheduleFlgReset());
    this.props.dispatch(actCallApiRoomScheduleDeleteByDeteil(roomData.id));
  }

  deleteSuccess(successMessage) {
    this.setState({ isSuccess: true, successMessage, isDelectCheckOpen: false });
    this.props.onClose();
  }

  search(store, date) {
    this.props.dispatch(actApiRoomScheduleFlgReset());
    this.props.dispatch(actCallApiGetRoomSchedule(
      { searchStoreId: store.id, searchTargetDate: date },
    ));
  }

  reGetDatas() {
    this.props.dispatch(actApiRoomScheduleFlgReset());
    const { detailData } = this.props;
    this.props.dispatch(actCallApiGetRoomScheduleDetail({ id: detailData.bookingId }));
  }

  init() {
    const { detailData, handleClearisClick } = this.props;
    handleClearisClick();
    this.props.dispatch(actCallApiGetRoomScheduleDetail({ id: detailData.bookingId }));
  }

  render() {
    const {
      classes, open, onClose, editData,
    } = this.props;
    const {
      roomData, roomMembers, isDelectCheckOpen, successMessage, isSuccess,
      selectedId, statuses, changeStatus,
    } = this.state;

    return (
      <>
        <Drawer
          variant="persistent"
          anchor="right"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            {open && (
              <IconButton onClick={onClose}>
                <ClearIcon />
              </IconButton>
            )}
          </div>
          <Divider />
          {open && (
            <>
              <Box my={2}>
                <Box className={classes.text}>
                  {`${roomData.roomName}  ${roomData.startTime}-${roomData.endTime}`}
                </Box>
                <Box display={roomData.contentId === 5 ? 'none' : ''} className={classes.text}>
                  {`講師名:${roomData.teacherName}`}
                </Box>
                <Box className={classes.text}>
                  {`教材費 ${roomData.cost}円 + 管理手数料 ${roomData.managementFee}円  計 ${roomData.cost + roomData.managementFee}円 `}
                </Box>

                <Box display={roomData.contentId === 5 ? 'flex' : 'none'} justifyContent="flex-end">
                  <Button className={classes.button} color="primary" onClick={() => { editData(roomData.id); }} variant="contained" size="small">編集</Button>
                  <Button className={classes.button} color="secondary" onClick={this.deleteCheck} variant="contained" size="small">削除</Button>
                </Box>
              </Box>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="meddle" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">氏名</TableCell>
                      <TableCell align="center">会員番号</TableCell>
                      <TableCell align="center">電話番号</TableCell>
                      <TableCell align="center">支払いチェック</TableCell>
                      <TableCell align="center">ステータス</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {roomMembers.length > 0 ? roomMembers.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" align="center">
                          <Box className={classes.memberName}>
                            {row.visitor && (
                            <img src={beginner} alt="ビジター" style={{ width: '15px', height: 'auto' }} />
                            )}
                            {!row.name ? '' : `${row.name}`}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{row.memberNo}</TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        {selectedId !== row.id && (
                          <TableCell align="center">
                            <Checkbox
                              checked={row.paymentCheck}
                              onChange={(e) => this.onSelectClick(e, row.id, row.paymentCheck)}
                            />
                          </TableCell>
                        )}
                        <TableCell align="center" colSpan={selectedId !== row.id ? 1 : 2}>
                          {selectedId !== row.id && (
                            <Box display="flex" textAlign="center">
                              <Box>{row.statusName}</Box>
                              <IconButton size="small" onClick={(e) => this.onEditClick(e, row.id, row.status, row.statusName)}>
                                <EditIcon />
                              </IconButton>
                            </Box>
                          )}
                          {selectedId === row.id && (
                            <Box display="flex">
                              <Box style={{ flex: '1 1 60%' }}>
                                <SearchSelectBox
                                  options={statuses}
                                  value={changeStatus}
                                  onChange={this.onChangeStatus}
                                  name="changeStatus"
                                  disableClearable
                                  widthValue="100%"
                                />
                              </Box>
                              <Box ml={1} display="flex" alignItems="center">
                                <Button color="primary" size="small" variant="contained" onClick={this.onClickStatusUpdate} disabled={!changeStatus}>保存</Button>
                                <IconButton size="small" onClick={this.onEditCloseClick}>
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )) : (
                      <TableCell align="center" colSpan={5}>
                        予約者はいません。
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </>
          )}
        </Drawer>
        <ConfirmDialog
          open={isDelectCheckOpen}
          handleClose={this.onDeleteClose}
          executeText="削除する"
          handleExecute={this.onDelete}
          title="データ削除"
          contentText={roomData.roomName ? `${roomData.roomName}のデータを削除します。よろしいですか？` : 'このデータを削除します。よろしいですか？'}
          buttonColor="secondary"
        />
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  roomSchedule: state.roomSchedule,
  roomData: state.roomData,
  roomMembers: state.roomMembers,
});

export default withStyles(styles)(connect(mapStateToProps)(RoomSearch));
