import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, getWeekSchedules, createDatas,
  createGolfDatas, reservationCheck, resetDatas, golfReservationCheck,
  resetGolfDatas, createRoomDatas, cultureCheck, getSelectes, createOnlineCultureDatas,
} from '../../../services/event/eventCalendarList.service';
import {
  ACTION_TYPE,
  actCallApiInitEventCalendarListSuccess,
  actCallApiEventCalendarListSearchSuccess,
  actCallApiEventCalendarListCreateSuccess,
  actCallApiEventCalendarListReservationCheckSuccess,
  actCallApiEventCalendarListResetSuccess,
  actCallApiEventCalendarListRoomCultureCheckSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * カレンダー作成（スクール系、イベント系）用のセレクトボックスリスト
 */
function* callApiInitEventCalendarList() {
  try {
    const datas = yield call(getSelectes, { regularFlg: 1 });
    yield put(actCallApiInitEventCalendarListSuccess({
      stores: datas.stores,
      contents: datas.contents,
      storeContentLinks: datas.storeContentLinks,
    }));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  カレンダー作成一覧検索
 * @param
 */
function* callApiEventCalendarListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    const weekSchedules = yield call(getWeekSchedules, searchCondition);
    yield put(actCallApiEventCalendarListSearchSuccess(datas, weekSchedules));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  カレンダー作成一覧検索
 * @param
 */
function* callApiEventCalendarListCreate({ params }) {
  try {
    let response = null;
    switch (params.contentId) {
      case 2: response = yield call(createGolfDatas, params); break;
      case 5: response = yield call(createRoomDatas, params); break;
      case 17: response = yield call(createOnlineCultureDatas, params); break;
      default: response = yield call(createDatas, params);
    }
    yield put(actCallApiEventCalendarListCreateSuccess(response));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  予約者確認
 * @param
 */
function* callApiEventCalendarListReservationCheck({ searchCondition }) {
  try {
    let reservationFlg = false;
    if (searchCondition.contentId !== 2) {
      reservationFlg = yield call(reservationCheck, searchCondition);
    }
    if (searchCondition.contentId === 2) {
      reservationFlg = yield call(golfReservationCheck, searchCondition);
    }
    yield put(actCallApiEventCalendarListReservationCheckSuccess(reservationFlg));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  予約枠リセット
 * @param
 */
function* callApiEventCalendarListReset({ resetCondition }) {
  try {
    if (resetCondition.contentId !== 2) {
      yield call(resetDatas, resetCondition);
    }
    if (resetCondition.contentId === 2) {
      yield call(resetGolfDatas, resetCondition);
    }
    yield put(actCallApiEventCalendarListResetSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  カルチャー、子供カルチャーチェック
 * @param
 */
function* callApiEventCalendarListRoomCultureCheck({ checkCondition }) {
  try {
    const roomCultureCheckFlg = yield call(cultureCheck, checkCondition);
    yield put(actCallApiEventCalendarListRoomCultureCheckSuccess(roomCultureCheckFlg));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallEventCalendarList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_EVENT_CALENDAR_LIST, callApiInitEventCalendarList);
  yield takeLatest(ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_SEARCH, callApiEventCalendarListSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_CREATE, callApiEventCalendarListCreate);
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK,
    callApiEventCalendarListReservationCheck,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESET, callApiEventCalendarListReset,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK,
    callApiEventCalendarListRoomCultureCheck,
  );
}
