// #region Type
export const ACTION_TYPE = {
  CALL_API_SYSTEM_USER_CREATE: 'CALL_API_SYSTEM_USER_CREATE',
  CALL_API_SYSTEM_USER_CREATE_SUCCESS: 'CALL_API_SYSTEM_USER_CREATE_SUCCESS',
  CALL_API_SYSTEM_USER_UPDATE: 'CALL_API_SYSTEM_USER_UPDATE',
  CALL_API_SYSTEM_USER_UPDATE_SUCCESS: 'CALL_API_SYSTEM_USER_UPDATE_SUCCESS',
  CALL_API_GET_SYSTEM_USER: 'CALL_API_GET_SYSTEM_USER',
  CALL_API_GET_SYSTEM_USER_SUCCESS: 'CALL_API_GET_SYSTEM_USER_SUCCESS',
  CALL_API_SYSTEM_USER_MAIL_CHECK: 'CALL_API_SYSTEM_USER_MAIL_CHECK',
  CALL_API_SYSTEM_USER_MAIL_CHECK_SUCCESS: 'CALL_API_SYSTEM_USER_MAIL_CHECK_SUCCESS',
  RESET_SYSTEM_USER_DETAIL_FLG: 'RESET_SYSTEM_USER_DETAIL_FLG',
};
// #endregion

// #region action
export const actCallApiSystemUserCreate = (info) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE,
  info,
});

export const actCallApiSystemUserCreateSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE_SUCCESS,
  data,
});

export const actCallApiSystemUserUpdate = (info) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE,
  info,
});

export const actCallApiSystemUserUpdateSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE_SUCCESS,
  data,
});

export const actCallGetSystemUser = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SYSTEM_USER,
  param,
});

export const actCallGetSystemUserSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_SYSTEM_USER_SUCCESS,
  data,
});

export const actCallApiSystemUserMailCheck = (param) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_MAIL_CHECK,
  param,
});

export const actCallApiSystemUserMailCheckSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_MAIL_CHECK_SUCCESS,
  data,
});

export const actResetSystemUserDetailFlg = () => ({
  type: ACTION_TYPE.RESET_SYSTEM_USER_DETAIL_FLG,
});
// #endregion
