import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約カレンダー詳細取得(ゴルフ以外)
 * @param id
 */
export function searchCalendarMenu(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_MENU_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約カレンダー詳細取得(ゴルフ)
 * @param id
 */
export function searchGolfCalendarMenu(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.CALENDAR_SCHEDULE.GOLF_CALENDAR_SCHEDULE_MENU_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約カレンダー詳細作成(ゴルフ以外)
 * @param datas
 */
export function saveCalendarSchedule(data) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_MENU, data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約カレンダー詳細作成(ゴルフ)
 * @param datas
 */
export function saveGolfCalendarSchedule(data) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.CALENDAR_SCHEDULE.GOLF_CALENDAR_SCHEDULE_MENU, data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
