import { isValid, format } from 'date-fns';
import compareDateCheck from './compareDateCheck';

const selectValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const capacityValidation = (value) => {
  if (value.length === 0) return '入力してください';
  if (value < 0) return '0以上の数値を入力してください';
  const regex = /^([1-9]\d*|0)$/;
  if (!regex.test(value)) return '整数値を入力してください';
  return '';
};

const compareTimeCheck = (startTime, endTime) => {
  if (startTime > endTime) return '開始時以降の値を入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '無効な日付です';
  return '';
};

const minMaxCheckValidation = (value) => {
  const { targetedOn, minDate, maxDate } = value;
  const date = new Date(targetedOn);

  if (!value || !targetedOn) return '入力してください';
  if (!isValid(date)) return '無効な日付です';
  if (compareDateCheck(date, minDate)
    || compareDateCheck(maxDate, date)
  ) return `${format(minDate, 'yyyy/MM/dd')}から${format(maxDate, 'yyyy/MM/dd')}内で入力してください`;
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'menu':
      case 'startTime':
      case 'endTime':
        return selectValidation(value);
      case 'capacity':
        return capacityValidation(value);
      case 'targetedOn':
      case 'publishedOn':
        return dateValidation(value);
      case 'minMaxCheck':
        return minMaxCheckValidation(value);
      default:
        return '';
    }
  };

  static compareTime = (startTime, endTime) => compareTimeCheck(startTime, endTime);
}

export default Validation;
