import React, { Component } from 'react';
import { format } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Container, Button, Paper,
} from '@material-ui/core';

import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import WeekScheduleTable from '../../../components/templates/weekScheduleTable/WeekScheduleTable';
import WeekScheduleMenuDialog from '../../share/weekScheduleMenuDialog/WeekScheduleMenuDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import urls from '../../../constants/urls';
import { actCallInitSchoolScheduleDetail, actApiSchoolScheduleDetailFlgReset } from '../../../redux/school/schoolWeekScheduleDetail/action';
import { actCallApiSelectList } from '../../../redux/share/weekScheduleMenu/action';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
  paper: {
    width: 'fit-content',
  },
});

class SchoolWeekScheduleDetail extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.SCHOOL.WEEK_SCHEDULES,
        name: '定期曜日別スケジュール一覧',
      },
      {
        name: '定期曜日別スケジュール詳細',
      },
    ];

    let successSnackOpen = false;
    let snackMessage = '';

    if (props.schoolWeekScheduleDetail.isCreated) {
      successSnackOpen = true;
      snackMessage = '曜日別スケジュールの追加が完了しました';
      props.dispatch(actApiSchoolScheduleDetailFlgReset());
    }
    if (props.schoolWeekScheduleDetail.isCopied) {
      successSnackOpen = true;
      snackMessage = '曜日別スケジュールのコピーが完了しました';
      props.dispatch(actApiSchoolScheduleDetailFlgReset());
    }

    this.state = {
      header: [],
      datas: {},
      successSnackOpen,
      dialogOpen: false,
      scheduleDetailId: null,
      snackMessage,
    };

    this.headerId = null;
    this.isMain = false;
    if (!props.location.state) {
      this.props.history.push({
        pathname: urls.SCHOOL.WEEK_SCHEDULES,
      });
      return;
    }
    this.props.dispatch(actCallInitSchoolScheduleDetail(
      { id: this.props.location.state.id },
    ));
    this.headerId = this.props.location.state.id;
    this.isMain = this.props.location.state.isMain;
  }

  componentDidMount() {
    document.title = '定期曜日別スケジュール詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolWeekScheduleDetail.headerData
      !== this.props.schoolWeekScheduleDetail.headerData) {
      if (this.props.schoolWeekScheduleDetail.headerData) {
        this.setHeaderData(this.props.schoolWeekScheduleDetail.headerData);
      }
    }
    if (prevProps.schoolWeekScheduleDetail.datas !== this.props.schoolWeekScheduleDetail.datas) {
      if (this.props.schoolWeekScheduleDetail.datas) {
        this.setScheduleDetail(this.props.schoolWeekScheduleDetail.datas);
      }
    }
    if (prevProps.weekScheduleMenu.isSaved !== this.props.weekScheduleMenu.isSaved) {
      if (this.props.weekScheduleMenu.isSaved) {
        this.successMenuSaveDelete(true);
      }
    }
    if (prevProps.weekScheduleMenu.isDeleted !== this.props.weekScheduleMenu.isDeleted) {
      if (this.props.weekScheduleMenu.isDeleted) {
        this.successMenuSaveDelete(false);
      }
    }
  }

  setHeaderData(headerDbData) {
    let header = {
      id: '',
      contentId: '',
      contentName: '',
      targetedOn: null,
    };
    if (headerDbData) {
      header = headerDbData;
    }
    this.setState({ header });
    this.props.dispatch(actCallApiSelectList(header.contentId, header.storeId));
  }

  setScheduleDetail(datas) {
    this.setState({ datas });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  onScheduleMenuDialog = (_, id) => {
    this.setState({ dialogOpen: true, scheduleDetailId: id, successSnackOpen: false });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  onMemberLinkage = (_, id) => {
    this.setState({ successSnackOpen: false });
    const { header } = this.state;
    this.props.history.push({
      pathname: urls.SCHOOL.MEMBER_LINKAGE,
      state: { detailId: id, headerId: header.id, isMain: this.isMain },
    });
  }

  successMenuSaveDelete(flg) {
    let snackMessage = '保存処理が完了しました';
    if (!flg) {
      snackMessage = '削除処理が完了しました';
    }
    this.setState({
      dialogOpen: false, successSnackOpen: true, snackMessage,
    });
    this.props.dispatch(actCallInitSchoolScheduleDetail({ id: this.headerId }));
  }

  render() {
    const { classes } = this.props;

    const {
      header, datas, snackMessage, successSnackOpen, dialogOpen, scheduleDetailId,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <Paper elevation={0} variant="outlined" className={classes.paper}>
            <Box display="flex" m={2}>
              <Box mr={10} display={this.isMain ? '' : 'none'}>
                <FormTitle title="店舗" />
                <Box>{header.storeName}</Box>
              </Box>
              <Box mr={10}>
                <FormTitle title="対象年月" />
                <Box>{header.targetedOn && format(new Date(header.targetedOn), 'yyyy年MM月')}</Box>
              </Box>
              <Box mr={10}>
                <FormTitle title="コンテンツ" />
                <Box>{header.contentName}</Box>
              </Box>
            </Box>
          </Paper>

          <Box textAlign="right" m={1}>
            <Button variant="contained" color="primary" size="large" onClick={(event) => this.onScheduleMenuDialog(event, null)}>メニューの追加</Button>
          </Box>
          <WeekScheduleTable
            datas={datas}
            schoolFlg
            onMenuUpdateDialog={this.onScheduleMenuDialog}
            onMemberLinkage={this.onMemberLinkage}
          />
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={snackMessage}
          />
          <WeekScheduleMenuDialog
            open={dialogOpen}
            onClose={this.handleDialogClose}
            schoolFlg
            id={scheduleDetailId}
            contentId={header.contentId}
            headerId={header.id}
            storeId={header.storeId}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolWeekScheduleDetail: state.schoolWeekScheduleDetail,
  weekScheduleMenu: state.weekScheduleMenu,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(SchoolWeekScheduleDetail)));
