import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/restApiUrls';
import {
  ACTION_TYPE,
  actCallApiGetStoresSuccess, actCallApiGolfScheduleAddSuccess,
  actCallApiGetGolfScheduleSuccess, actCallApiGetGolfScheduleAddSuccess,
  actCallApiSaveGolfScheduleSuccess, actCallApiGolfScheduleDeleteSuccess,
  actCallApiGetGolfScheduleDetailSuccess,
  actCallApiUpdateGolfScheduleDetailSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import {
  getDatas, saveData, getDataById, updateData, deleteDatas,
} from '../../../services/base.service';

/**
 * 初期表示
 */
function* callApiGetStores() {
  try {
    const data = yield call(getDatas, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE_SELECT);
    yield put(actCallApiGetStoresSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetDatas({ searchCondition }) {
  try {
    const datas = yield call(
      getDatas, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE, searchCondition,
    );
    yield put(actCallApiGetGolfScheduleSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiSaveData({ param }) {
  try {
    yield call(saveData, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE, param);
    yield put(actCallApiSaveGolfScheduleSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetDetails({ param }) {
  try {
    const data = yield call(getDataById, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE_ID, param);
    yield put(actCallApiGetGolfScheduleDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateDetails({ param }) {
  try {
    yield call(updateData, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE_ID, param);
    yield put(actCallApiUpdateGolfScheduleDetailSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetAdd({ param }) {
  try {
    const data = yield call(getDatas, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE_ADD, param);
    yield put(actCallApiGetGolfScheduleAddSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiAdd({ param }) {
  try {
    yield call(saveData, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE_ADD, param);
    yield put(actCallApiGolfScheduleAddSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiDelete({ param }) {
  try {
    yield call(deleteDatas, REST_API.BOOKING.GOLF_SCHEDULE.GOLF_SCHEDULE, param);
    yield put(actCallApiGolfScheduleDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallGolfSchedule() {
  yield takeLatest(ACTION_TYPE.CALL_API_GOLF_SCHEDULE_GET_STORES, callApiGetStores);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE, callApiGetDatas);
  yield takeLatest(ACTION_TYPE.CALL_API_SAVE_GOLF_SCHEDULE, callApiSaveData);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_DETAIL, callApiGetDetails);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL, callApiUpdateDetails);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_ADD, callApiGetAdd);
  yield takeLatest(ACTION_TYPE.CALL_API_GOLF_SCHEDULE_ADD, callApiAdd);
  yield takeLatest(ACTION_TYPE.CALL_API_GOLF_SCHEDULE_DELETE, callApiDelete);
}
