import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/restApiUrls';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';
import {
  ACTION_TYPE,
  actCallApiInitNoticeListSuccess,
  actCallApiNoticeListSearchSuccess,
  actCallApiNoticeDetailSearchSuccess,
  actCallApiSaveNoticeSuccess,
  callApiDeleteNoticeSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 加盟企業とカテゴリ取得
 */
function* callApiInitNoticeList() {
  try {
    const select = yield call(getDatas, REST_API.MASTER.NOTICE.NOTICE_SELECT);
    yield put(actCallApiInitNoticeListSuccess(select));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * お知らせ一覧取得
 */
function* callApiNoticeListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, REST_API.MASTER.NOTICE.NOTICE, searchCondition);
    yield put(actCallApiNoticeListSearchSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * お知らせ詳細取得
 */
function* callApiNoticeDetailSearch({ param }) {
  try {
    const data = yield call(getDataById, REST_API.MASTER.NOTICE.NOTICE_DETAIL, param);
    yield put(actCallApiNoticeDetailSearchSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * お知らせ新規作成
 */
function* callApiSaveNotice({ notice }) {
  try {
    const saveId = yield call(saveData, REST_API.MASTER.NOTICE.NOTICE, notice);
    yield put(actCallApiSaveNoticeSuccess(saveId));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * お知らせ削除
 */
function* callApiDeleteNotice({ deleteList }) {
  try {
    const result = yield call(deleteDatas, REST_API.MASTER.NOTICE.NOTICE, deleteList);
    yield put(callApiDeleteNoticeSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallNotice() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_NOTICE_LIST, callApiInitNoticeList);
  yield takeLatest(ACTION_TYPE.CALL_API_NOTICE_LIST_SEARCH, callApiNoticeListSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_NOTICE_DETAIL_SEARCH, callApiNoticeDetailSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_SAVE_NOTICE, callApiSaveNotice);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_NOTICE, callApiDeleteNotice);
}
