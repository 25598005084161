import React, { Component } from 'react';
import {
  Box, Container, Button,
} from '@material-ui/core';
import { isValid, format } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import urls from '../../../constants/urls';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actCallInitClassInfomation } from '../../../redux/info/classInfomation/aciton';

class ClassInfomation extends Component {
  constructor(props) {
    super(props);

    if (props.common.user) {
      props.dispatch(actCallInitClassInfomation(props.common.user.store.id));
    }

    const date = format(new Date(), 'yyyy/MM/dd');

    this.state = {
      searchRooms: [],
      date,
      isShowDateErrorMsg: false,
      isShowRoomErrorMsg: false,
    };
  }

  componentDidMount() {
    document.title = '教室案内';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
  }

  setUser = (user) => {
    this.props.dispatch(actCallInitClassInfomation(user.store.id));
  }

  onChangeSelectBox = (event) => {
    this.setState({
      searchRooms: event.target.value,
    });
  }

  onSearch = () => {
    const { searchRooms, date } = this.state;
    const ids = [];
    searchRooms.map((room) => ids.push(room.id));
    let isShowRoomErrorMsg = false;
    let isShowDateErrorMsg = false;
    if (ids.length <= 0) {
      isShowRoomErrorMsg = true;
    }
    if (!isValid(new Date(date)) || !date) {
      isShowDateErrorMsg = true;
    }
    if (isShowRoomErrorMsg || isShowDateErrorMsg) {
      this.setState({ isShowRoomErrorMsg, isShowDateErrorMsg });
      return;
    }

    this.props.history.push({
      pathname: urls.INFO.CLASS_INFOMATION_DETAIL,
      state: {
        searchRooms, date, ids,
      },
    });
  }

  handleDateChange = (_, values) => {
    this.setState({ date: values });
  }

  render() {
    const {
      searchRooms, date, isShowDateErrorMsg, isShowRoomErrorMsg,
    } = this.state;

    const rooms = this.props.classInfomation.rooms ? this.props.classInfomation.rooms.rooms : [];

    return (
      <Container>
        <Box border={1} borderColor="grey.500" p={2} mt={3} borderRadius="borderRadius">
          <Box mt={2}>
            <Box fontWeight="fontWeightBold" fontSize={17}>Room</Box>
            <SearchMultiSelectBox
              name="searchRooms"
              value={searchRooms}
              options={rooms}
              onChange={this.onChangeSelectBox}
              error={isShowRoomErrorMsg}
            />
            <Box display={isShowRoomErrorMsg ? '' : 'none'}>
              <FormErrorText>選択してください</FormErrorText>
            </Box>
          </Box>
          <Box mt={3}>
            <Box fontWeight="fontWeightBold" fontSize={17}>表示日</Box>
            <Datepicker
              id="date-picker-date"
              value={date}
              handleChange={this.handleDateChange}
              disableToolbar={false}
              formatter="yyyy/MM/dd"
              error={isShowDateErrorMsg}
            />
            <Box display={isShowDateErrorMsg ? '' : 'none'}>
              <FormErrorText>無効な日付です</FormErrorText>
            </Box>
          </Box>
          <Box mt={3}>
            <Button onClick={this.onSearch} variant="contained" color="primary">この内容で開始する</Button>
          </Box>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  classInfomation: state.classInfomation,
  common: state.common,
});
export default (connect(mapStateToProps)(ClassInfomation));
