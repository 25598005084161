import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  Box, Paper,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiBookingUpdateStatus,
} from '../../../redux/booking/bookingList/action';

class BookingUpdateStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      row: {},
      updateStatus: '',
      changeLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  handleUpdateStatus = () => {
    this.setChangeLoading(true);
    const {
      row,
      updateStatus,
    } = this.state;

    this.props.dispatch(
      actCallApiBookingUpdateStatus({
        ids: [row.bookingId],
        status: updateStatus,
        summarizeUpdFlg: false,
      }),
    );
  }

  handlePreClose = () => {
    this.setChangeLoading(false);
    this.props.handleClose();
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  init() {
    const {
      info,
    } = this.props;

    this.setState({
      row: info,
      updateStatus: info.statusCode === 2 ? 0 : 2,
      changeLoading: false,
    });
  }

  render() {
    const {
      open,
      handleClose,
    } = this.props;
    const {
      row,
      updateStatus,
      changeLoading,
    } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          onEscapeKeyDown={handleClose}
        >
          <DialogTitle>ステータスの変更</DialogTitle>
          <DialogContent dividers>
            <Box>
              以下の予約のステータスを
              {updateStatus === 2 ? '休講' : '開催予定'}
              に更新します。
            </Box>
            <Box>よろしいですか？</Box>
            <Paper elevation={0} variant="outlined">
              <Box m={1}>
                <Box>
                  <FormTitle title="実施日" />
                  <Box>{row.targetDate}</Box>
                </Box>
                <Box mt={1}>
                  <FormTitle title="時間" />
                  <Box>{row.targetTime}</Box>
                </Box>
                <Box mt={1}>
                  <FormTitle title="コンテンツ" />
                  <Box>{row.contentName}</Box>
                </Box>
                <Box mt={1}>
                  <FormTitle title="メニュー" />
                  <Box>{row.menuName}</Box>
                </Box>
              </Box>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handlePreClose}>
              キャンセル
            </Button>
            <LoadingButton color={updateStatus === 2 ? 'primary' : 'secondary'} label={updateStatus === 2 ? '休講にする' : '休講を取消する'} onClick={this.handleUpdateStatus} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDialog: state.booking,
});

export default withRouter(connect(mapStateToProps)(BookingUpdateStatusDialog));
