/* eslint-disable prefer-destructuring */
import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FreeSoloBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  return (
    <Autocomplete
      size="small"
      freeSolo
      options={options.map((option) => option.name)}
      renderInput={(params) => <TextField {...params} name={name} onChange={onChange} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      value={value}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}
