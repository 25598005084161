import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import '../public/css/style.css';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import store from '../store/store';
import Login from './other/login/Login';
import Routes from './common/Routes';
import Auth from './common/Auth';
import Loading from '../components/organisms/loading/Loading';
import urls from '../constants/urls';
import Header from './common/header/Header';
import HealthCheck from './info/healthCheck/HealthCheck';
import ClassInfomationDetail from './info/classInfomation/ClassInfomationDetail';
import AttendanceEntryDetail from './info/attendanceEntryDetail/AttendanceEntryDetail';
import ServerErrorProc from './common/ServerErrorProc';
import RoomInfoDetail from './info/roomInfo/RoomInfoDetail';
import MemberWithdrawal from './member/memberDetail/MemberWithdrawal';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5b85de',
      main: '#3367D6',
      dark: '#234895',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#E8F0FE',
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: '#000',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem',
        wordBreak: 'break-all',
      },
      head: {
        fontWeight: '600',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  },
});

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Loading />
        <ServerErrorProc />
        <Router history={createHashHistory()}>
          <Switch>
            <Route exact path={urls.OTHER.LOGIN} component={Login} />
            <Auth>
              <Switch>
                <Route exact path={urls.INFO.HEALTH_CHECK} component={HealthCheck} />
                <Route
                  exact
                  path={urls.INFO.CLASS_INFOMATION_DETAIL}
                  component={ClassInfomationDetail}
                />
                <Route exact path={urls.INFO.ROOM_INFO_DETAIL} component={RoomInfoDetail} />
                <Route
                  exact
                  path={urls.INFO.ATTENDANCE_ENTRY_DETAIL}
                  component={AttendanceEntryDetail}
                />
                <Route
                  exact
                  path={urls.MEMBER.MEMBER_DETAIL_WITHDRAWAL}
                  component={MemberWithdrawal}
                />
                <Header>
                  <Routes />
                </Header>
              </Switch>
            </Auth>
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}
