import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  emptyTableCell: {
    textAlign: 'center',
  },
  emptyStopFlgCell: {
    width: '73px',
  },
});

class MailListDataTable extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  render() {
    const {
      classes,
      rows,
      onChangeStopFlg,
      onClick,
    } = this.props;

    return (
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  配信種類
                </TableCell>
                <TableCell>
                  配信タイミング
                </TableCell>
                <TableCell>
                  配信状況
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                >
                  <TableCell>
                    {row.type}
                  </TableCell>
                  <TableCell>
                    {row.timing}
                  </TableCell>
                  <TableCell>
                    {row.stopFlg ? ('配信停止中') : ('配信中')}
                  </TableCell>
                  <TableCell>
                    {!row.systemFlg ? (
                      <Button color={row.stopFlg ? 'primary' : 'secondary'} onClick={(event) => onChangeStopFlg(event, row.id, row.stopFlg, row.type)}>{row.stopFlg ? ('配信開始') : ('配信停止')}</Button>
                    ) : (
                      <Button className={classes.emptyStopFlgCell} disabled />
                    )}
                    <Button color="primary" onClick={(event) => onClick(event, row.id)}>詳細を見る</Button>
                  </TableCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell className={classes.emptyTableCell} colSpan={6}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

MailListDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onChangeStopFlg: PropTypes.func.isRequired,
};

export default withStyles(styles)(MailListDataTable);
