import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * スケジュール詳細IDによるスケジュール情報取得
 *
 * @param {object}
 */
export function getMemberLinkageInfo(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.SCHOOL.MEMBER_LINKAGE.MEMBER_LINKAGE_INFO, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * スケジュール詳細IDによる会員紐付け一覧を取得
 *
 * @param {object}
 */
export function getMemberDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.MEMBER_LINKAGE.MEMBER_LINKAGE_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員紐付け削除
 *
 * @param {object}
 */
export function deleteMemberLinkageList(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.SCHOOL.MEMBER_LINKAGE.MEMBER_LINKAGE_LIST, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員追加モーダル内の条件による会員検索
 *
 * @param {object} searchCondition
 */
export function getMembers(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.MEMBER_LINKAGE.MEMBER_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員紐付け追加
 *
 *
 */
export function InsertMemberLinkageList(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.MEMBER_LINKAGE.MEMBER_LINKAGE_LIST, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
