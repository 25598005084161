import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 画面一覧取得
 *
 * @param
 */
export function getMailDetail(id) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .getById(REST_API.SETTING.MAIL_DETAIL.MAIL_DETAIL, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * メール詳細更新
 *
 * @param
 */
export function updateMailDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.SETTING.MAIL_DETAIL.MAIL_DETAIL, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
