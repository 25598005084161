import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * ルールを取得する
 *
 * @param {object} storeId
 */
export function getDatas(storeId) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.RULE.RULE, { storeId })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * カルチャーのルールを作成・更新する
 * @param {*} updateContents
 */
export function saveCultureRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.CULTURE_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ゴルフのルールを作成・更新する
 * @param {*} updateContents
 */
export function saveGolfRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.GOLF_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ルームのルールを作成・更新する
 * @param {*} updateContents
 */
export function saveRoomRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.ROOM_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * レンタルコートのルールを作成・更新する
 * @param {*} updateContents
 */
export function saveRentalCourtRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.RENTAL_COURT_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 定期のルールを作成・更新する
 * @param {*} updateContents
 */
export function saveRegularRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.REGULAR_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ゴルフ時間を作成更新する
 * @param {*} updateContents
 * @returns
 */
export function saveGolfTimes(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.GOLF_TIMES, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * オンラインカルチャーを作成更新する
 * @param {*} updateContents
 * @returns
 */
export function saveOnlineCultureRules(updateContents) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.RULE.ONLINE_CULTURE_RULE, updateContents)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
