import { ACTION_TYPE } from './action';

const roomSchedule = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_ROOM_SCHEDULE_GET_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.members,
      };
    case ACTION_TYPE.CALL_API_ROOM_SCHEDULE_FLG_RESET:
      return {
        ...state,
        isUpsertSuccessFlg: false,
        isDuplicateFlg: false,
        // isDeleteSuccessFlg: false,
        isUpdateMember: false,
        isDeleteByDetailSuccess: false,
      };
    case ACTION_TYPE.CALL_API_ROOM_SCHEDULE_UPSERT_SUCCESS:
      return {
        ...state,
        existData: action.existData,
        isUpsertSuccessFlg: true,
        isDuplicateFlg: (!!action.existData),
      };
    case ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        scheduleData: action.scheduleData,
      };
    case ACTION_TYPE.CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL_SUCCESS:
      return {
        ...state,
        isDeleteByDetailSuccess: true,
      };
    case ACTION_TYPE.CALL_SET_SEARCH_INFO:
      return {
        ...state,
        info: action.info,
      };
    case ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        roomData: action.data.roomData,
        roomMembers: action.data.roomMembers,
      };
    case ACTION_TYPE.CALL_API_POST_ROOM_MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateMember: true,
      };
    default:
      return state;
  }
};

export default roomSchedule;
