import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, TextField, Box, Button, Accordion,
  AccordionSummary, Typography,
} from '@material-ui/core';
import format from 'date-fns/format';
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import SubscriberListDataTable from './SubscriberListDataTable';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiSubscriberList,
  actCallApiUpdateSubscriberStatusAccepted,
  actCallInitSubscriberSearchList,
  actResetUpdateSuccessFlg,
} from '../../../redux/booking/subscriberList/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MenuDetailDrawer from './MenuDetailDrawer';
import HistoryStatusDrawer from './HistoryStatusDrawer';

const styles = (theme) => ({
  accordionRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class SubscriberList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallInitSubscriberSearchList());

    this.breadcrumbsText = [
      { name: '予約者一覧' },
    ];

    const searchCondition = {
      page: 0,
      records: 10,
      searchStore: props.common.user ? props.common.user.store : null,
      searchMemberNo: '',
      searchStartDate: format(new Date(), 'yyyy/MM/dd'),
      searchEndDate: format(new Date(), 'yyyy/MM/dd'),
      searchStartTime: '',
      searchEndTime: '',
      searchBookingMember: '',
      searchBookingMemberKana: '',
      searchContents: [],
      searchMenu: null,
      searchTeacher: null,
      searchStatuses: [],
      searchBookingCode: '',
    };

    this.state = {
      isMain: props.common.user ? props.common.user.main : false,
      datas: {},
      selects: {
        stores: [],
        contents: [],
        menus: [],
        teachers: [],
        storeContentLinks: [],
        status: {
          regularStatusList: [],
          eventStatusList: [],
        },
      },
      searchCondition,
      successSnackOpen: false,
      snackMessage: '',
      selectedRow: {},
      drawerOpen: false,
      isClickDrawer: false,
    };

    if (props.common.user) {
      this.search(searchCondition);
    }
  }

  componentDidMount() {
    document.title = '予約者一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }

    if (prevProps.subscriber.selects !== this.props.subscriber.selects) {
      if (this.props.subscriber.selects) {
        this.setSelects(this.props.subscriber.selects);
      }
    }

    if (prevProps.subscriber.datas !== this.props.subscriber.datas) {
      if (this.props.subscriber.datas) {
        this.setDatas(this.props.subscriber.datas);
      }
    }

    if (prevProps.subscriber.isTransferCancelSuccess
      !== this.props.subscriber.isTransferCancelSuccess) {
      if (this.props.subscriber.isTransferCancelSuccess) {
        this.setUpdateSuccess('の振替キャンセルが完了しました');
      }
    }

    if (prevProps.subscriber.isUpdateStatus !== this.props.subscriber.isUpdateStatus) {
      if (this.props.subscriber.isUpdateStatus) {
        this.setUpdateSuccess('のステータス変更が完了しました');
      }
    }

    if (prevProps.subscriber.isAcceptedUpdateStatus
      !== this.props.subscriber.isAcceptedUpdateStatus) {
      if (this.props.subscriber.isAcceptedUpdateStatus) {
        this.setUpdateSuccess('を受付済みに変更しました');
      }
    }
  }

  setUser(user) {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStore: user.store,
    };
    this.setState({ isMain: user.main, searchCondition: tempCondition });
    this.search(tempCondition);
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setUpdateSuccess(message) {
    const { selectedRow } = this.state;

    this.setState({
      successSnackOpen: true,
      snackMessage: selectedRow.bookingMember + message,
      isClickDrawer: true,
    });

    const { searchCondition } = this.state;

    this.search(searchCondition);
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  search = (searchCondition) => {
    const searchContentsIds = searchCondition.searchContents.map((n) => n.id);
    const searchStatuses = searchCondition.searchStatuses.map((n) => n.id);

    this.props.dispatch(
      actCallApiSubscriberList({
        ...searchCondition,
        searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
        searchContentsIds,
        searchStatuses,
        searchMenuId: searchCondition.searchMenu && searchCondition.searchMenu.id,
        searchTeacherId: searchCondition.searchTeacher && searchCondition.searchTeacher.id,
      }),
    );
  }

  onItemChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  onStoreChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      searchContents: [],
      searchMenu: null,
      searchTeacher: null,
    };
    this.setState({ searchCondition: tempCondition });
  }

  onTodayClick = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStartDate: format(new Date(), 'yyyy/MM/dd'),
      searchEndDate: format(new Date(), 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tempCondition });
  }

  onTomorrowClick = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStartDate: format(date, 'yyyy/MM/dd'),
      searchEndDate: format(date, 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tempCondition });
  }

  handleClickNowTime = () => {
    const date = new Date();
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStartTime: format(date, 'HH:mm'),
    };
    this.setState({ searchCondition: tempCondition });
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  onSearchClear = () => {
    this.setState({
      searchCondition: {
        page: 0,
        records: 10,
        searchStore: this.props.common.user ? this.props.common.user.store : null,
        searchMemberNo: '',
        searchStartDate: null,
        searchEndDate: null,
        searchStartTime: '',
        searchEndTime: '',
        searchBookingMember: '',
        searchBookingMemberKana: '',
        searchContents: [],
        searchMenu: null,
        searchTeacher: null,
        searchStatuses: [],
        searchBookingCode: '',
      },
    });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  };

  handleFocusOut = () => {
    this.onSearch();
  }

  handleSuccessSnackbarClose = () => {
    this.setState({
      successSnackOpen: false,
    });
  }

  handleClickAccepted = (row) => {
    this.props.dispatch(
      actResetUpdateSuccessFlg(),
    );
    this.props.dispatch(
      actCallApiUpdateSubscriberStatusAccepted({
        id: row.bookingCode,
        searchCondition: {
          status: row.regular ? 1 : 6,
          contentId: row.contentId,
          transferAheadFlg: row.transferAheadId !== null,
        },
      }),
    );
    this.setState({
      selectedRow: row,
      successSnackOpen: false,
    });
  }

  onDateChange = (value, name) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  handleDrawerOpen = (row) => {
    this.setState({
      isHistoryOpen: false, drawerOpen: true, isClickDrawer: true, selectedRow: row,
    });
  }

  handleHistoryOpen = (row) => {
    this.setState({
      drawerOpen: false, isHistoryOpen: true, isHistoryDrawer: true, selectedRow: row,
    });
  }

  handleClearDrawer = () => {
    this.setState({ isClickDrawer: false });
  }

  handleHistoryClear = () => {
    this.setState({ isHistoryDrawer: false });
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false, isClickDrawer: false, selectedRow: {} });
  }

  handleHistoryClose = () => {
    this.setState({ isHistoryOpen: false, isHistoryDrawer: false, selectedRow: {} });
  }

  getContents = (selects, searchStore) => {
    if (!searchStore || selects.contents.length <= 0) {
      return selects.contents || [];
    }

    const linkedContents = selects.storeContentLinks.filter(
      (link) => link.mstStoreId === searchStore.id,
    ).map((link) => link.mstContentId);
    return selects.contents.filter((content) => linkedContents.includes(content.id));
  }

  render() {
    const { classes } = this.props;
    const {
      isMain, selects, searchCondition,
      datas, successSnackOpen, snackMessage,
      selectedRow, drawerOpen, isClickDrawer, isHistoryOpen, isHistoryDrawer,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody style={(drawerOpen || isHistoryOpen) ? classes.contentShift : null}>
          <SearchBox onSearch={this.onSearch} onSecondFunc={this.onSearchClear} onSecondFuncText="クリアする">
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} mr={2} width="30%">
                <FormTitle title="店舗" />
                <SearchSelectBox options={selects.stores} value={searchCondition.searchStore} onChange={this.onStoreChange} name="searchStore" onBlur={this.handleFocusOut} />
              </Box>
              <Box>
                <FormTitle title="会員番号" />
                <TextField
                  onBlur={this.handleFocusOut}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.onItemChange}
                  variant="outlined"
                  value={searchCondition.searchMemberNo}
                  inputProps={{ maxLength: 10 }}
                  name="searchMemberNo"
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="予約日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker id="date-picker-start" formatter="yyyy/MM/dd" value={searchCondition.searchStartDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchStartDate')} onBlur={this.handleFocusOut} />
                </Box>
                <Box mr={4} ml={4}>～</Box>
                <Box>
                  <Datepicker id="date-picker-end" formatter="yyyy/MM/dd" value={searchCondition.searchEndDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchEndDate')} onBlur={this.handleFocusOut} />
                </Box>
                <Box mr={2} ml={2}>
                  <Button variant="outlined" size="large" onClick={this.onTodayClick}>今日</Button>
                </Box>
                <Box>
                  <Button variant="outlined" size="large" onClick={this.onTomorrowClick}>明日</Button>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="予約時間" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <TextField
                    type="time"
                    variant="outlined"
                    name="searchStartTime"
                    value={searchCondition.searchStartTime}
                    onChange={this.onItemChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleFocusOut}
                  />
                </Box>
                <Box mr={2} ml={2}>～</Box>
                <Box>
                  <TextField
                    type="time"
                    variant="outlined"
                    name="searchEndTime"
                    value={searchCondition.searchEndTime}
                    onChange={this.onItemChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleFocusOut}
                  />
                </Box>
                <Box ml={2}>
                  <Button variant="outlined" size="large" onClick={this.handleClickNowTime}>現在時刻</Button>
                </Box>
              </Box>
            </Box>
            <Box mt={3} display="flex">
              <Box>
                <FormTitle title="予約者" />
                <TextField
                  onKeyDown={this.handleKeyDown}
                  onChange={this.onItemChange}
                  onBlur={this.handleFocusOut}
                  variant="outlined"
                  value={searchCondition.searchBookingMember}
                  inputProps={{ maxLength: 100 }}
                  style={{ width: '100%' }}
                  name="searchBookingMember"
                />
              </Box>
              <Box ml={4}>
                <FormTitle title="予約者フリガナ" />
                <TextField
                  onKeyDown={this.handleKeyDown}
                  onChange={this.onItemChange}
                  onBlur={this.handleFocusOut}
                  variant="outlined"
                  value={searchCondition.searchBookingMemberKana}
                  inputProps={{ maxLength: 200 }}
                  style={{ width: '100%' }}
                  name="searchBookingMemberKana"
                />
              </Box>
            </Box>
            <Box mt={3}>
              <Accordion className={classes.accordionRoot}>
                <AccordionSummary
                  pb={0}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>詳細な検索条件</Typography>
                </AccordionSummary>
                <Box pl={3} mb={3}>
                  <Box width="80%" mt={3}>
                    <FormTitle title="コンテンツ" />
                    <SearchMultiSelectBox
                      name="searchContents"
                      value={searchCondition.searchContents}
                      options={this.getContents(selects, searchCondition.searchStore)}
                      onChange={this.onItemChange}
                      onBlur={this.handleFocusOut}
                    />
                  </Box>
                  <Box width="80%" mt={3}>
                    <FormTitle title="ステータス" />
                    <SearchMultiSelectBox
                      name="searchStatuses"
                      value={searchCondition.searchStatuses}
                      options={
                        selects.status.eventStatusList.concat(selects.status.regularStatusList)
                      }
                      onChange={this.onItemChange}
                      onBlur={this.handleFocusOut}
                    />
                  </Box>
                  <Box display="flex" mt={3}>
                    <Box width="30%">
                      <FormTitle title="メニュー" />
                      <SearchSelectBox
                        options={
                          searchCondition.searchStore ? selects.menus.filter(
                            (key) => key.mstOrganizationCompanyId
                              === searchCondition.searchStore.mstOrganizationCompanyId,
                          ) : selects.menus
                        }
                        value={searchCondition.searchMenu}
                        onChange={this.onItemChange}
                        onBlur={this.handleFocusOut}
                        name="searchMenu"
                      />
                    </Box>
                    <Box ml={4} width="30%">
                      <FormTitle title="先生" />
                      <SearchSelectBox
                        options={
                          searchCondition.searchStore ? selects.teachers.filter(
                            (key) => key.mstStoreId === searchCondition.searchStore.id,
                          ) : selects.teachers
                        }
                        value={searchCondition.searchTeacher}
                        onChange={this.onItemChange}
                        onBlur={this.handleFocusOut}
                        name="searchTeacher"
                      />
                    </Box>
                  </Box>
                </Box>
              </Accordion>
            </Box>
          </SearchBox>

          <SubscriberListDataTable
            selectedRow={selectedRow}
            rows={datas.content ? datas.content : []}
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            onFirstFunc={this.handleClickAccepted}
            onSecondFunc={this.handleClickUpdateStatus}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleDrawerOpen={this.handleDrawerOpen}
            handleHistoryOpen={this.handleHistoryOpen}
          />

          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={snackMessage}
          />
        </MainBody>
        <MenuDetailDrawer
          drawerOpen={drawerOpen}
          isClickDrawer={isClickDrawer}
          row={selectedRow}
          handleClearDrawer={this.handleClearDrawer}
          handleDrawerClose={this.handleDrawerClose}
        />
        <HistoryStatusDrawer
          isOpen={isHistoryOpen}
          isHistoryDrawer={isHistoryDrawer}
          row={selectedRow}
          handleHistoryClear={this.handleHistoryClear}
          handleHistoryClose={this.handleHistoryClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ subscriber: state.subscriber, common: state.common });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(SubscriberList)));
