import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Button,
  Menu, MenuItem,
  Collapse,
  withStyles,
  Box,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import {
  isShowArea,
  getMainList,
  getEventList,
  getSchoolList,
  getSettingList,
  getUserSettingList,
  getInfoList,
  getMasterList,
} from './checkShowScreen';
import HeaderMenuItem from './HeaderMenuItem';
import urls from '../../../constants/urls';
import ChangePassword from './ChangePassword';
import NestList from './NestList';

const drawerWidth = 240;

// #region css
const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0px 0px 0px 0px',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  headerLink: {
    color: 'white',
    textDecoration: 'none',
  },
  userInfoText: {
    color: 'white',
  },
  userInfoContent: {
    padding: 20,
  },
  menuIcon: {
    color: theme.palette.primary.contrastText,
  },
  divider: {
    zIndex: 1,
  },
});
// #endregion

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      isOpenEvent: false,
      isOpenSchool: false,
      isOpenSetting: false,
      isOpenUserSetting: false,
      isOpenMaster: false,
      anchorEl: null,
      changePasswordModalOpen: false,
    };
  }

  handleDrawer = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  handleHeader = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  clearHeader = () => {
    this.setState({ anchorEl: null });
  }

  passwordDialogOpen = () => {
    this.setState({ changePasswordModalOpen: true });
    this.setState({ anchorEl: null });
  }

  passwordDialogClose = () => {
    this.setState({ changePasswordModalOpen: false });
  }

  onClickLogout = () => {
    localStorage.clear();
    window.location.href = urls.OTHER.LOGIN;
  }

  onMenuClose = () => {
    this.setState({
      open: false,
    });
  }

  onClickEventMenu = () => {
    const { isOpenEvent } = this.state;

    this.setState({
      isOpenEvent: !isOpenEvent,
      isOpenUserSetting: false,
      isOpenSetting: false,
      isOpenSchool: false,
      isOpenMaster: false,
    });
  }

  onClickSchoolMenu = () => {
    const { isOpenSchool } = this.state;

    this.setState({
      isOpenSchool: !isOpenSchool,
      isOpenEvent: false,
      isOpenSetting: false,
      isOpenUserSetting: false,
      isOpenMaster: false,
    });
  }

  onClickMasterMenu = () => {
    const { isOpenMaster } = this.state;

    this.setState({
      isOpenMaster: !isOpenMaster,
      isOpenSchool: false,
      isOpenEvent: false,
      isOpenSetting: false,
      isOpenUserSetting: false,
    });
  }

  onClickSettingMenu = () => {
    const { isOpenSetting } = this.state;

    this.setState({
      isOpenSetting: !isOpenSetting,
      isOpenEvent: false,
      isOpenSchool: false,
      isOpenUserSetting: false,
      isOpenMaster: false,
    });
  }

  onClickUserSettingMenu = () => {
    const { isOpenUserSetting } = this.state;

    this.setState({
      isOpenUserSetting: !isOpenUserSetting,
      isOpenEvent: false,
      isOpenSetting: false,
      isOpenSchool: false,
      isOpenMaster: false,
    });
  }

  render() {
    const { classes } = this.props;

    const {
      open,
      isOpenEvent,
      isOpenSchool,
      isOpenSetting,
      isOpenUserSetting,
      isOpenMaster,
      anchorEl,
      changePasswordModalOpen,
    } = this.state;
    const {
      children,
      common,
    } = this.props;
    const { user } = common;
    const screenList = user ? user.screenList : [];
    const companyId = user ? user.store.mstOrganizationCompanyId : 1;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <Typography variant="h6" noWrap>
              <a href="/" className={classes.headerLink}>{companyId === 4 ? '士衛塾' : 'ENJOY LIFE CLUB'}</a>
            </Typography>
            <div className={classes.grow} />
            <Box display="flex">
              <Button
                className={classes.userInfoText}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleHeader}
              >
                <Typography variant="h6" noWrap>
                  {user && user.user.lastName + user.user.firstName}
                </Typography>
                <ExpandMoreIcon />
              </Button>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.clearHeader}>
                <MenuItem
                  onClick={this.passwordDialogOpen}
                  className={classes.userInfoContent}
                >
                  パスワード変更
                </MenuItem>
                <MenuItem
                  onClick={this.onClickLogout}
                  className={classes.userInfoContent}
                >
                  ログアウト
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
          <ChangePassword
            open={changePasswordModalOpen}
            onClose={this.passwordDialogClose}
            userId={user && user.user.id}
          />
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawer}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />

          <List>
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.BOOKING.BOOKING_LIST} menuName="予約検索" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.BOOKING.SUBSCRIBER_LIST} menuName="予約者一覧" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.BOOKING.ROOM_SCHEDULES} menuName="教室スケジュール" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.BOOKING.GOLF_SCHEDULES} menuName="ゴルフスケジュール" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.MEMBER.MEMBER_LIST} menuName="会員一覧" />
          </List>
          <Divider className={classes.divider} style={{ display: isShowArea(screenList, getMainList()) ? '' : 'none' }} />

          <NestList screenList={screenList} targetList={getEventList()} onClick={this.onClickEventMenu} title="時間割管理" isOpen={isOpenEvent}>
            <Collapse in={isOpenEvent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.EVENT.WEEK_SCHEDULES} menuName="曜日別スケジュール一覧" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.EVENT.EVENT_CALENDARS} menuName="予約カレンダー作成" />
              </List>
            </Collapse>
          </NestList>

          <NestList screenList={screenList} targetList={getSchoolList()} onClick={this.onClickSchoolMenu} title="定期時間割管理" isOpen={isOpenSchool}>
            <Collapse in={isOpenSchool} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SCHOOL.WEEK_SCHEDULES} menuName="曜日別スケジュール一覧" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SCHOOL.SCHOOL_CALENDARS} menuName="定期予約カレンダー作成" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SCHOOL.WEEK_SELECT} menuName="曜日選択" />
              </List>
            </Collapse>
          </NestList>

          <NestList screenList={screenList} targetList={getMasterList()} onClick={this.onClickMasterMenu} title="マスタ" isOpen={isOpenMaster}>
            <Collapse in={isOpenMaster} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.MASTER.TEACHER_LIST} menuName="先生パスワード変更" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.MASTER.NOTICE_LIST} menuName="お知らせ一覧" />
              </List>
            </Collapse>
          </NestList>

          <NestList screenList={screenList} targetList={getSettingList()} onClick={this.onClickSettingMenu} title="設定" isOpen={isOpenSetting}>
            <Collapse in={isOpenSetting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.MAIL_LIST} menuName="自動配信メール設定" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.RULES} menuName="予約ルール登録" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.SITE_SETTING} menuName="サイト設定" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.SURVEY_SETTING} menuName="授業アンケート設定" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.BANNERS} menuName="バナー画像登録" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.SETTING.HOLIDAY} menuName="休日設定" />
              </List>
            </Collapse>
          </NestList>

          <NestList screenList={screenList} targetList={getUserSettingList()} onClick={this.onClickUserSettingMenu} title="ユーザー設定" isOpen={isOpenUserSetting}>
            <Collapse in={isOpenUserSetting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.USER_SETTING.SYSTEM_USERS} menuName="システムユーザー" />
                <HeaderMenuItem onClose={this.onMenuClose} url={urls.USER_SETTING.ROLES} menuName="権限" />
              </List>
            </Collapse>
          </NestList>

          <Divider style={{ display: isShowArea(screenList, getInfoList()) ? '' : 'none' }} />
          <List>
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.INFO.HEALTH_CHECK} menuName="ヘルスチェック" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.INFO.CLASS_INFOMATION} menuName="教室案内" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.INFO.ROOM_INFO} menuName="ROOM案内" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.INFO.TEACHER_CONTACT_TASK_LIST} menuName="先生連絡一覧" />
            <HeaderMenuItem onClose={this.onMenuClose} isNotNested url={urls.INFO.ATTENDANCE_ENTRY_LIST} menuName="出席登録" />
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <div>
            {children}
          </div>
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({ common: state.common });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Header)));
