import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from '@material-ui/core';
import { ja } from 'date-fns/locale';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

export default function EventCalendarListDuplicateDialog(props) {
  const {
    open,
    handleClose,
    contentText,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
    >
      <DialogTitle error="true">重複エラー</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Box>
            <Box fontWeight="fontWeightBold">
              以下のメニューが重複のため、予約枠作成できませんでした
            </Box>
            <Box mt={2}>
              {
                contentText && contentText.map((data) => (
                  <Box mb={1} border={1} borderColor="#aaa" p={1}>
                    <table width="100%">
                      <tr>
                        <td>メニュー名</td>
                        <td>{`：${data.menuName}`}</td>
                      </tr>
                      <tr>
                        <td>開始時間</td>
                        <td>{`：${data.startTime}`}</td>
                      </tr>
                      <tr>
                        <td>曜日</td>
                        <td>{`：${ja.localize.day(data.dateNumber, { width: 'short' })}曜日`}</td>
                      </tr>
                    </table>
                  </Box>
                ))
              }
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}

EventCalendarListDuplicateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  contentText: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
