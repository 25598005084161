import urls from '../../../constants/urls';

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function isShowArea(list, targets) {
  if (list.length <= 0) {
    return false;
  }
  let isShow = false;
  for (const key in targets) {
    const target = targets[key];
    if (list.filter((row) => row.url === target).length > 0) {
      isShow = true;
    }
  }
  return isShow;
}

export function getMainList() {
  return [
    urls.BOOKING.BOOKING_LIST, urls.BOOKING.SUBSCRIBER_LIST, urls.MEMBER.MEMBER_LIST,
  ];
}

export function getEventList() {
  return [
    urls.EVENT.WEEK_SCHEDULES, urls.EVENT.EVENT_CALENDARS,
  ];
}

export function getSchoolList() {
  return [
    urls.SCHOOL.WEEK_SCHEDULES, urls.SCHOOL.SCHOOL_CALENDARS, urls.SCHOOL.WEEK_SELECT,
  ];
}

export function getSettingList() {
  return [
    urls.SETTING.MAIL_LIST,
    urls.SETTING.RULES,
    urls.SETTING.SITE_SETTING,
    urls.SETTING.SURVEY_SETTING,
    urls.SETTING.BANNERS,
    urls.SETTING.HOLIDAY,
  ];
}

export function getUserSettingList() {
  return [
    urls.USER_SETTING.SYSTEM_USERS,
    urls.USER_SETTING.ROLES,
  ];
}

export function getInfoList() {
  return [
    urls.INFO.HEALTH_CHECK, urls.INFO.CLASS_INFOMATION, urls.INFO.TEACHER_CONTACT_TASK_LIST,
  ];
}

export function getMasterList() {
  return [
    urls.MASTER.TEACHER_LIST, urls.MASTER.NOTICE_LIST,
  ];
}

export function isShowItem(list, target) {
  if (list.length <= 0) {
    return false;
  }
  return list.filter((row) => row.url === target).length > 0;
}
