import { ACTION_TYPE } from './action';

const teachers = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_TEACHER_LIST_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_GET_TEACHER_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_UPDATE_TEACHER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateData: action.data,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.RESET_TEACHER_LIST_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};

export default teachers;
