const valueValidation = (value, check) => {
  if (!check) {
    return '';
  }
  if (!value) return '入力してください';
  const regex = /^([1-9]\d*|0)$/;
  if (!regex.test(value)) return '整数値を入力してください';
  if (value < 0) return '0以上の数値を入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value, check) => {
    switch (type) {
      case 'surveyAnswerPeriod':
      case 'surveyPointNumber':
      case 'surveyPointPeriod':
        return valueValidation(value, check);
      default:
        return '';
    }
  };
}
export default Validation;
