import React from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px',
    paddingTop: '60px',
  },
}));

export default function MainBody(props) {
  const classes = useStyles();

  const { children, style } = props;

  return (
    <Container className={`${classes.root} ${style}`}>
      {children}
    </Container>
  );
}

MainBody.propTypes = {
  children: PropTypes.node.isRequired,
};
