import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import {
  actCallApiGetGolfBoxs, actCallApiGolfBoxUpdate, actCallApiGetGolfBoxUpdateFlgReset,
} from '../../../redux/booking/bookingDetail/action';

class BookingMemberChangeStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeLoading: false,
      errorMessage: '',
      golfBoxs: [],
      boxNumber: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.bookingDetail.golfBoxs !== this.props.bookingDetail.golfBoxs) {
      if (this.props.bookingDetail.golfBoxs) {
        this.setGolfBoxs(this.props.bookingDetail.golfBoxs);
      }
    }
    if (prevProps.bookingDetail.golfBoxUpdateFlg !== this.props.bookingDetail.golfBoxUpdateFlg) {
      if (this.props.bookingDetail.golfBoxUpdateFlg === 2) {
        this.setMessage('既に使用されている打席番号です');
      }
      if (this.props.bookingDetail.golfBoxUpdateFlg === 3) {
        this.setMessage('選択した打席には変更できません');
      }
    }
  }

  setMessage = (errorMessage) => {
    this.setState({ errorMessage });
  }

  setGolfBoxs = (golfBoxs) => {
    this.setState({ golfBoxs });
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  onChangeBoxNumber = (event) => {
    this.setState({ boxNumber: event.target.value, errorMessage: '' });
  }

  init = () => {
    const { booking } = this.props.bookingDetail;
    const { golfBoxRow } = this.props;
    let boxNumber = {};
    if (golfBoxRow.boxNumber) {
      boxNumber = { id: golfBoxRow.boxNumber, name: `${golfBoxRow.boxNumber}` };
    }
    this.setState({
      errorMessage: '',
      boxNumber,
    });
    this.props.dispatch(actCallApiGetGolfBoxs({
      storeId: booking.mstStoreId,
      targetType: booking.targetType,
    }));
  }

  handleUpdateGolfBox = () => {
    this.setChangeLoading(true);
    const { golfBoxRow, booking } = this.props;
    const { boxNumber } = this.state;
    this.props.dispatch(actCallApiGetGolfBoxUpdateFlgReset());
    this.props.dispatch(
      actCallApiGolfBoxUpdate({
        golfBookingId: booking.id,
        mstStoreId: booking.mstStoreId,
        targetedOn: booking.targetedOn,
        startTime: booking.startTime,
        endTime: booking.endTime,
        boxNumber: boxNumber ? boxNumber.name : null,
        golfMemberId: golfBoxRow.id,
      }),
    );
    this.setChangeLoading(false);
  }

  render() {
    const {
      open,
      handleClose,
      golfBoxRow,
    } = this.props;
    const {
      changeLoading,
      errorMessage,
      golfBoxs,
      boxNumber,
    } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="xs"
          aria-labelledby="update-memberStatus-title"
          aria-describedby="update-memberStatus-description"
          disableBackdropClick
          onEscapeKeyDown={handleClose}
        >
          <DialogTitle>
            <Box>打席番号を変更</Box>
            <Box>{golfBoxRow.name}</Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <SearchSelectBox
                options={golfBoxs}
                width="100%"
                value={boxNumber}
                onChange={this.onChangeBoxNumber}
                name="golfBoxs"
                error={errorMessage !== ''}
              />
              <FormErrorText>{errorMessage}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton color="primary" label="変更" onClick={this.handleUpdateGolfBox} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

export default withRouter(connect(mapStateToProps)(BookingMemberChangeStatusDialog));
