import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatas, deleteRoles } from '../../../services/userSetting/roleList.service';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actCallApiSelectRoleListSuccess,
  actCallApiGetRoleListSuccess,
  actCallApiRoleListDeleteSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiGetSelectRoleList() {
  try {
    const stores = yield call(getStores);
    yield put(actCallApiSelectRoleListSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param
 */
function* callApiGetRoleList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiGetRoleListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiRoleListDelete({ values }) {
  try {
    yield call(deleteRoles, values);
    yield put(actCallApiRoleListDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRoleList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_ROLE_LIST, callApiGetSelectRoleList);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_LIST_SEARCH, callApiGetRoleList);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_LIST_DELETE, callApiRoleListDelete);
}
