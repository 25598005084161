const times = ['00', '15', '30', '45'];

const timeValidation = (value) => {
  if (!value) return '入力してください';
  const min = value.split(':')[1];
  if (!times.includes(min)) {
    return '15分単位で入力してください';
  }
  return '';
};

const blankValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'startTime':
      case 'endTime':
        return timeValidation(value);
      case 'boxNumber':
        return blankValidation(value);
      default:
        return '';
    }
  };
}
export default Validation;
