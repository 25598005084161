import Validation from './validation';
import timeValidation from './golf/time-validation';

export function getCultureDatas() {
  return {
    mstStoreId: '',
    startTime: '',
    endTime: '',
    cancelWaitAccepted: false,
    advanceTimeLimited: false,
    advanceReferencedOn: '',
    advanceInterval: '',
    advanceNumber: '',
    memberRule: '',
  };
}

export function getGolfDatas() {
  return {
    mstStoreId: '',
    freeCloseTime: '',
    freeCancelTime: '',
    lessonCloseTime: '',
    lessonCancelTime: '',
    targetCloseTime: '',
    targetCancelTime: '',
    reservationUnit: '',
    freeAdvanceNumber: '',
    lessonAdvanceNumber: '',
    targetAdvanceNumber: '',
    freeCancelWaitAccepted: false,
    lessonCancelWaitAccepted: false,
    targetCancelWaitAccepted: false,
    targetAccepted: false,
    memberRule: '',
  };
}

export function getRoomDatas() {
  return {
    mstStoreId: '',
    startTime: '',
    endTime: '',
    closeDay: '',
    cancelDay: '',
    reservationUnit: '',
    cultureBeforeTime: '',
    cultureAfterTime: '',
    memberRule: '',
  };
}

export function getRentalCourtDatas() {
  return {
    mstStoreId: '',
    startTime: '',
    endTime: '',
    closeDay: '',
    cancelDay: '',
    advanceNumber: '',
    memberRule: '',
  };
}

export function getRegularDatas() {
  return {
    mstStoreId: '',
    transferCloseDay: '',
    limitOnce: '',
    limitTwice: '',
    limitThree: '',
  };
}

export function getOnlineCultureDatas() {
  return {
    mstStoreId: '',
    startTime: '',
    endTime: '',
    closeDay: '',
    cancelDay: '',
    memberPossibleEnterNumber: '',
    teacherPossibleEnterNumber: '',
    memberRule: '',
  };
}

export function getObject() {
  return {
    cultureDatas: getCultureDatas(),
    golfDatas: {
      golfDatas: getGolfDatas(),
      boxList: [
        {
          boxNumber: 1,
          startTime: '',
          endTime: '',
        },
      ],
      targetList: [
        {
          boxNumber: 1,
          startTime: '',
          endTime: '',
        },
      ],
    },
    roomDatas: getRoomDatas(),
    rentalCourtDatas: getRentalCourtDatas(),
    regularDatas: getRegularDatas(),
    onlineCultureDatas: getOnlineCultureDatas(),
  };
}

export function compareTime(tempMessage, startTime, endTime) {
  const start = Number(startTime.replace(':', '').substring(0, 4));
  const end = Number(endTime.replace(':', '').substring(0, 4));
  return { ...tempMessage, endTime: Validation.compareTime(start, end) };
}

export function checkValidation(datas, message) {
  let tempMessage = message;
  let isError = false;

  Object.keys(datas).map((key) => {
    let msg = '';
    if (key !== 'advanceInterval' || (key === 'advanceInterval' && datas.advanceTimeLimited)) {
      msg = Validation.formValidate(key, datas[key]);
    }
    if (key === 'endTime' && message.endTime) {
      msg = message.endTime;
    }
    tempMessage = { ...tempMessage, [key]: msg };
    if (msg) isError = true;
    return msg;
  });

  return {
    isError,
    messages: tempMessage,
  };
}

export function checkTimeValidation(datas, message) {
  let tempMessage = message;
  let isError = false;

  Object.keys(datas).map((key) => {
    let msg = timeValidation.formValidate(key, datas[key]);
    if (key === 'endTime' && message.endTime) msg = message.endTime;
    tempMessage = { ...tempMessage, [key]: msg };
    if (msg) isError = true;
    return msg;
  });

  return {
    isError,
    messages: tempMessage,
  };
}
