import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getMemberProductInfo, getWeekSelectSchedule, insertWeekSelectMemberLinkage, getSelects,
} from '../../../services/school/weekSelect.service';
import {
  ACTION_TYPE,
  actCallInitWeekSelectSuccess,
  actCallMemberProductInfoSuccess,
  actCallApiSearchWeekSelectScheduleSuccess,
  actApiInsertWeekSelectMemberLinkageSuccess,
  actCallDialogMemberProductInfoSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitWeekSelect() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallInitWeekSelectSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員情報及び契約情報取得
 *
 * @param
 */
function* callApiMemberProductInfo({ searchCondition }) {
  try {
    const memberInfoData = yield call(getMemberProductInfo, searchCondition);
    yield put(actCallMemberProductInfoSuccess(memberInfoData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索条件に当たる曜日別スケジュール取得
 *
 * @param
 */
function* callApiSearchWeekSelectSchedule({ searchCondition }) {
  try {
    const scheduleDatas = yield call(getWeekSelectSchedule, searchCondition);
    yield put(actCallApiSearchWeekSelectScheduleSuccess(scheduleDatas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員紐付け登録
 *
 * @param
 */
function* callApiInsertWeekSelectMemberLinkage({ param }) {
  try {
    const datas = yield call(insertWeekSelectMemberLinkage, param);
    yield put(actApiInsertWeekSelectMemberLinkageSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員情報及び契約情報取得
 *
 * @param
 */
function* callApiDialogMemberProductInfo({ searchCondition }) {
  try {
    const dialogMemberData = yield call(getMemberProductInfo, searchCondition);
    yield put(actCallDialogMemberProductInfoSuccess(dialogMemberData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallWeekSelect() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_WEEK_SELECT, callApiInitWeekSelect);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_PRODUCT_INFO, callApiMemberProductInfo);
  yield takeLatest(
    ACTION_TYPE.CALL_API_SEARCH_WEEK_SELECT_SCHEDULE, callApiSearchWeekSelectSchedule,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_DIALOG_MEMBER_PRODUCT_INFO, callApiDialogMemberProductInfo,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE, callApiInsertWeekSelectMemberLinkage,
  );
}
