import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約枠カレンダー(ヘッダー、詳細)取得
 * @param id
 */
export function getGolfCalendarHeader(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.CALENDAR_DETAIL_GOLF.HEADER_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 公開日更新
 * @param {*} id
 * @param {*} param
 * @returns
 */
export function updateGolfHeader(id, param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.CALENDAR_DETAIL_GOLF.HEADER_INFO, { id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ゴルフ予約枠取得
 * @param id
 */
export function searchGolfBookingDetail(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.CALENDAR_DETAIL_GOLF.BOOKING_DETAIL, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ゴルフ予約枠（フリー・的打ち）変更
 * @param id
 * @param param
 */
export function updateGolfFrame(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.CALENDAR_DETAIL_GOLF.UPDATE_BOOKING_DETAIL, { id: param.selectedId }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ゴルフ予約枠一括変更
 * @param param
 */
export function updateBulkGolfFrame(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.CALENDAR_DETAIL_GOLF.UPDATE_BULK_UPDATE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
