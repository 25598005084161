import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  LinearProgress,
  Box,
} from '@material-ui/core';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import {
  actCallApiEventCalendarListReservationCheck,
  actCallApiEventCalendarListFlgReset,
  actCallApiEventCalendarListReset,
} from '../../../../redux/event/eventCalendarList/action';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

class EventCalendarListResetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      btnLoading: false,
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.eventCalendarList.resetFlg !== this.props.eventCalendarList.resetFlg) {
      if (this.props.eventCalendarList.resetFlg) {
        this.setReservationFlg(this.props.eventCalendarList.reservationFlg);
      }
    }
  }

  setReservationFlg(reservationFlg) {
    this.setState({
      loading: false, btnLoading: false, isError: !reservationFlg,
    });
  }

  onReset = () => {
    const {
      storeId, contentId, id, targetDate,
    } = this.props.selectedRow;

    this.setState({ btnLoading: true });
    this.props.dispatch(actCallApiEventCalendarListFlgReset());
    this.props.dispatch(actCallApiEventCalendarListReset({
      headerId: id,
      storeId,
      contentId,
      targetedOn: targetDate,
    }));
  }

  init() {
    const { storeId, contentId, targetDate } = this.props.selectedRow;
    this.props.dispatch(actCallApiEventCalendarListFlgReset());
    this.props.dispatch(actCallApiEventCalendarListReservationCheck({
      storeId,
      contentId,
      targetedOn: targetDate,
    }));
    this.setState({ loading: true, btnLoading: true, isError: false });
  }

  render() {
    const {
      loading,
      btnLoading,
      isError,
    } = this.state;

    const {
      open,
      onResetModalClose,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onResetModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        fullWidth
      >
        <DialogTitle error={isError}>{isError ? 'エラー' : '予約枠のリセット'}</DialogTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers>
          <Box display={loading ? 'none' : ''}>
            <Box display={isError ? 'none' : ''}>予約枠をリセットします。この操作は元に戻すことができません。</Box>
            <Box display={isError ? '' : 'none'}>予約者がいるためリセット出来ません。予約者を予約枠から削除した上で再度実行してください。</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetModalClose}>{isError ? '閉じる' : 'キャンセル'}</Button>
          {!isError && (
            <LoadingButton
              onClick={this.onReset}
              label="リセットする"
              loading={btnLoading}
              color="primary"
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  eventCalendarList: state.eventCalendarList,
  common: state.common,
});

export default connect(mapStateToProps)(EventCalendarListResetDialog);
