import isValid from 'date-fns/isValid';

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(`${value}/01`))) return '無効な日付です';
  return '';
};

const contentsValidation = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'searchContent':
      return contentsValidation(value);
    case 'searchMonth':
      return dateValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
