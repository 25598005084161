import React from 'react';
import { Card, Button, Box } from '@material-ui/core';
import { lowerThanDate } from './calendar-schedule-detail-proc';

export default function CalendarBookingCard(props) {
  const {
    menuInfo, onMenuUpdateDialog, onBookingDetailLinkage,
  } = props;

  return (
    <Box ml={0.5} mr={0.5} mt={2}>
      <Card>
        <Box alignItems="center" display="flex" bgcolor={menuInfo.menuColor} p={1} style={{ wordBreak: 'break-all' }}>
          <Box width="35%" justifyContent="center" alignItems="center" display="flex" bgcolor="#fff">
            <Box fontWeight="fontWeightBold" m={0} fontSize={15} align="center">
              <Box width="100%" mt={1} mb={1}>
                {menuInfo.capacity}
                名
              </Box>
            </Box>
          </Box>
          <Box width="65%" align="center" p={1} pb={0} pt={0} style={{ wordBreak: 'break-all' }}>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>{menuInfo.menuName}</Box>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>
              {menuInfo.startTime}
              ~
              {menuInfo.endTime}
            </Box>
          </Box>
        </Box>
        <Box p={1} pt={0} display="flex" bgcolor={menuInfo.menuColor} justifyContent="space-evenly">
          <Box m={0.3} mt={0}>
            <Button size="small" variant="contained" onClick={(event) => onBookingDetailLinkage(event, menuInfo.id, null)}>詳細</Button>
          </Box>
          <Box m={0.3} mt={0}>
            <Button style={{ display: lowerThanDate(new Date(menuInfo.targetedOn), new Date()) ? 'none' : '' }} size="small" variant="contained" color="primary" onClick={(event) => onMenuUpdateDialog(event, menuInfo.id)}>変更</Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
