import { ACTION_TYPE } from './action';

const weekScheduleMenu = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SAVE_SUCCESS:
      return {
        ...state,
        isSaved: true,
        isError: false,
      };
    case ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        isError: false,
      };
    case ACTION_TYPE.CALL_API_WEEK_SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isError: false,
      };
    case ACTION_TYPE.CALL_API_WEEK_SCHEDULE_FLG_RESET:
      return {
        ...state,
        isSaved: false,
        isDeleted: false,
      };
    case ACTION_TYPE.CALL_API_WEEK_MENU_SELECT_LIST_SUCCESS:
      return {
        ...state,
        selectList: action.selectList,
        isError: false,
      };
    default:
      return state;
  }
};

export default weekScheduleMenu;
