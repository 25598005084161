import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 先生情報取得
 * @param {*} searchCondition
 * @returns
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MASTER.TEACHER_LIST.TEACHER_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * パスワード更新
 * @param {*} param
 * @returns
 */
export function updatePassword(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .put(REST_API.MASTER.TEACHER_LIST.PASSWORD, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
