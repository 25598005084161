import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField,
  Dialog, DialogActions, DialogContent,
  LinearProgress, Box,
  Typography,
} from '@material-ui/core';
import {
  actCallGetSystemUser, actCallApiSystemUserCreate,
  actCallApiSystemUserMailCheck, actResetSystemUserDetailFlg,
  actCallApiSystemUserUpdate,
} from '../../../redux/userSetting/systemUserDetail/action';
import { actCallApiMe } from '../../../redux/common/common/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import Validation from './validation';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import LogoutModal from './LogoutModal';

class SystemUserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selects: {
        roles: [],
        stores: [],
      },
      id: null,
      name: '',
      isSuccessSnackOpen: false,
      successSnackMessage: '',
      isLogoutOpen: false,
      info: {
        mstStore: null,
        lastName: '',
        firstName: '',
        email: '',
        password: null,
        mstRole: null,
      },
      message: {
        mstStore: '',
        lastName: '',
        firstName: '',
        email: '',
        password: '',
        mstRole: '',
      },
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.systemUserDetail.data !== this.props.systemUserDetail.data) {
      if (this.props.systemUserDetail.data) {
        this.setData(this.props.systemUserDetail.data);
      }
    }
    if (prevProps.systemUserDetail.mail !== this.props.systemUserDetail.mail) {
      if (this.props.systemUserDetail.mail) {
        this.setMailCheck();
      }
    }
    if (prevProps.systemUserDetail.isCreateSuccess
      !== this.props.systemUserDetail.isCreateSuccess) {
      if (this.props.systemUserDetail.isCreateSuccess) {
        this.setSuccess(this.props.systemUserDetail.resultData, '作成', false);
      }
    }
    if (prevProps.systemUserDetail.isUpdateSuccess
      !== this.props.systemUserDetail.isUpdateSuccess) {
      if (this.props.systemUserDetail.isUpdateSuccess) {
        this.setSuccess(this.props.systemUserDetail.resultData, '更新', true);
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setData(data) {
    this.setState({
      info: {
        ...data,
        password: '',
      },
      name: data.lastName + data.firstName,
      loading: false,
      btnLoading: false,
    });
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  setMailCheck() {
    const { message } = this.state;
    const msg = '既に使用されているメールアドレスです';
    let tempMessage = message;
    tempMessage = {
      ...tempMessage,
      email: msg,
    };
    this.setState({ message: tempMessage });
  }

  handleSave = () => {
    const { info, message } = this.state;
    const { id } = this.props;

    let tempMessage = message;
    let msg = '';
    let isError = false;

    if (message.email) {
      return;
    }

    Object.keys(info).map((key) => {
      if (id && key === 'password') {
        return null;
      }
      msg = Validation.formValidate(key, info[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      return null;
    });

    this.setState({ message: tempMessage, btnLoading: !isError });

    if (isError) {
      return;
    }

    const param = {
      ...info,
      mstStoreId: info.mstStore.id,
      mstRoleId: info.mstRole.id,
    };

    this.props.dispatch(actResetSystemUserDetailFlg());
    if (id) {
      this.props.dispatch(actCallApiSystemUserUpdate(param));
    } else {
      this.props.dispatch(actCallApiSystemUserCreate(param));
    }
  }

  onAuthChange = (event) => {
    const { info, message } = this.state;
    const { value, name } = event.target;

    this.setState({
      info: { ...info, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  onStoreChange = (event) => {
    const { info } = this.state;
    this.setState({ info: { ...info, mstStore: event.target.value, mstRole: null } });
  }

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { info, message } = this.state;
    this.setState({
      info: { ...info, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
    });
  }

  mailCheck = () => {
    const { message, info } = this.state;
    const { id } = this.props;

    if (message.email || !info.email) {
      return;
    }

    this.props.dispatch(actCallApiSystemUserMailCheck({ id, email: info.email }));
  }

  init = () => {
    let loading = false;
    let btnLoading = false;
    const { id } = this.props;

    const info = {
      mstStore: this.props.common.user.store,
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      mstRole: null,
    };

    const message = {
      mstStore: '',
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      mstRole: '',
    };

    if (id) {
      loading = true;
      btnLoading = true;
      this.props.dispatch(actCallGetSystemUser({ id }));
    }

    this.setState({
      isMain: this.props.common.user.main,
      loading,
      selects: this.props.systemUsers.selects,
      id,
      info,
      message,
      btnLoading,
      isSuccessSnackOpen: false,
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessSnackOpen: false });
  }

  setSuccess = (data, proc, isUpdate) => {
    const loginUser = this.props.common ? this.props.common.user.user : {};
    const isSameUser = loginUser.id === data.id;
    const isChangeLoginId = loginUser.email !== data.email;
    let isLogoutOpen = false;
    let isSuccessSnackOpen = true;
    if (isSameUser && isUpdate && isChangeLoginId) {
      isLogoutOpen = true;
      isSuccessSnackOpen = false;
    }
    if (isSameUser && isUpdate && !isChangeLoginId) {
      this.props.dispatch(actCallApiMe());
    }

    if (!isLogoutOpen) {
      this.props.upsertSuccess();
    }

    this.setState({
      isSuccessSnackOpen,
      isLogoutOpen,
      successSnackMessage: `${data.lastName + data.firstName}の${proc}が完了しました`,
    });
  }

  render() {
    const { open, handleClose } = this.props;

    const {
      selects,
      info,
      message,
      loading,
      id,
      isMain,
      name,
      btnLoading,
      isSuccessSnackOpen,
      successSnackMessage,
      isLogoutOpen,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>
            <Box display={id ? 'none' : ''}>
              システムユーザーの追加
            </Box>
            <Box display={id ? '' : 'none'}>
              <Box>次のシステムユーザーの変更</Box>
              <Box>
                {name}
              </Box>
            </Box>
          </DialogTitle>
          <LinearProgress style={{ display: loading ? '' : 'none' }} />
          <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
            <Box display={isMain ? '' : 'none'} mb={2}>
              <FormTitle title="店舗" isRequired />
              <SearchSelectBox error={message.mstStore !== ''} options={selects.stores} value={info.mstStore} onChange={this.onStoreChange} name="mstStore" disableClearable />
              <FormErrorText>{message.mstStore}</FormErrorText>
            </Box>
            <Box display="flex" mb={2}>
              <Box>
                <FormTitle title="姓" isRequired />
                <TextField
                  name="lastName"
                  variant="outlined"
                  value={info.lastName}
                  onChange={this.handleChange}
                  error={message.lastName !== ''}
                  inputProps={{
                    maxLength: 80,
                  }}
                />
                <FormErrorText>{message.lastName}</FormErrorText>
              </Box>
              <Box ml={2}>
                <FormTitle title="名" isRequired />
                <TextField
                  id="first-name"
                  name="firstName"
                  variant="outlined"
                  value={info.firstName}
                  onChange={this.handleChange}
                  error={message.firstName !== ''}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
                <FormErrorText>{message.firstName}</FormErrorText>
              </Box>
            </Box>
            <Box mb={2}>
              <FormTitle title="メールアドレス" isRequired />
              <TextField
                id="email"
                name="email"
                variant="outlined"
                value={info.email}
                onChange={this.handleChange}
                fullWidth
                error={message.email !== ''}
                onBlur={() => { this.mailCheck(); }}
                inputProps={{
                  maxLength: 80,
                }}
              />
              <FormErrorText>{message.email}</FormErrorText>
            </Box>
            <Box>
              <FormTitle title="権限" isRequired />
              <SearchSelectBox
                error={message.mstRole !== ''}
                options={selects.roles.filter((data) => data.mstStoreId === info.mstStore.id)}
                value={info.mstRole}
                onChange={this.onAuthChange}
                name="mstRole"
                disableClearable
              />
              <FormErrorText>{message.mstRole}</FormErrorText>
            </Box>
            <Box mt={2} display={id ? 'none' : ''}>
              <FormTitle title="パスワード" isRequired />
              <PasswordField
                password={info.password}
                onChange={this.handleChange}
                name="password"
                isError={message.password !== ''}
              />
              <FormErrorText>{message.password}</FormErrorText>
              <Typography variant="caption" display="block" gutterBottom>8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください</Typography>
            </Box>
            <Box mt={1} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <LoadingButton loading={btnLoading} onClick={this.handleSave} label="保存" />
          </DialogActions>
        </Dialog>
        <SuccessSnackbar
          open={isSuccessSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successSnackMessage}
        />
        <LogoutModal content={successSnackMessage} open={isLogoutOpen} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUsers: state.systemUsers,
  systemUserDetail: state.systemUserDetail,
  common: state.common,
});

export default connect(mapStateToProps)(SystemUserDetail);
