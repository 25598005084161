/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';

import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actHolidayFlgReset, actCallHolidayGetStore, actCallApiHolidayList, actCallApiHolidayDelete,
} from '../../../redux/setting/holiday/action';
import Monthpicker from '../../../components/atoms/monthpicker/Monthpicker';
import AddHolidayDialog from './AddHolidayDialog';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';

class HolidayList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallHolidayGetStore());

    this.breadcrumbsText = [
      { name: '休日設定' },
    ];

    this.headCells = [
      { id: 'storeName', numeric: false, label: '店舗名' },
      { id: 'holiday', numeric: false, label: '休日' },
    ];

    let isMain = false;
    let searchStore = null;
    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    const today = new Date();

    // 年、月を取得
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;

    const searchMonth = `${year}/${month}`;
    this.search(0, 10, searchStore, searchMonth);

    this.state = {
      isMain,
      stores: [],
      datas: [],
      searchStore,
      searchMonth,
      errorMessage: {
        searchStore: '',
        searchMonth: '',
      },
      page: 0,
      records: 10,
      successSnackOpen: false,
      successSnackMessage: '',
    };
  }

  componentDidMount() {
    document.title = '休日設定';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.holidays.datas !== this.props.holidays.datas) {
      if (this.props.holidays.datas) {
        this.setDatas(this.props.holidays.datas);
      }
    }
    if (prevProps.holidays.stores !== this.props.holidays.stores) {
      if (this.props.holidays.stores) {
        this.setStores(this.props.holidays.stores);
      }
    }
    if (prevProps.holidays.isCreatedSuccess !== this.props.holidays.isCreatedSuccess) {
      if (this.props.holidays.isCreatedSuccess) {
        this.setSuccess('作成');
      }
    }

    if (prevProps.holidays.isDeleteSuccess !== this.props.holidays.isDeleteSuccess) {
      if (this.props.holidays.isDeleteSuccess) {
        this.setSuccess('削除');
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main, searchStore: user.store });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setStores(stores) {
    this.setState({ stores });
  }

  onChange = (event) => {
    const { errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      errorMessage: { ...errorMessage, [name]: Validation.formValidate(name, value) },
    });
  }

  onSearch = () => {
    const {
      searchStore,
      searchMonth,
      records,
      errorMessage,
    } = this.state;

    let tempMessage = errorMessage;
    tempMessage = {
      ...tempMessage,
      searchStore: Validation.formValidate('searchStore', searchStore),
      searchMonth: Validation.formValidate('searchMonth', searchMonth),
    };
    this.setState({ errorMessage: tempMessage });

    this.search(0, records, searchStore, searchMonth);
  }

  handleChangePage = (_, newPage) => {
    const {
      searchStore, searchMonth, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(newPage, records, searchStore, searchMonth);
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchStore, searchMonth,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchStore, searchMonth);
  };

  onAdd = () => {
    this.setState({ isModalOpen: true });
  }

  onDelete = (value) => {
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actHolidayFlgReset());
    this.props.dispatch(actCallApiHolidayDelete({
      deleteList: value,
    }));
  }

  onClose = () => {
    this.setState({ isModalOpen: false });
  }

  search = (page, records, searchStore, searchMonth) => {
    this.props.dispatch(actCallApiHolidayList(
      {
        page,
        records,
        storeId: searchStore.id,
        targetDate: searchMonth,
      },
    ));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  setSuccess = (name) => {
    const {
      page,
      records,
      searchStore,
      searchMonth,
    } = this.state;
    this.setState({ successSnackOpen: true, successSnackMessage: `休日の${name}が完了しました`, isModalOpen: false });
    this.search(page, records, searchStore, searchMonth);
  }

  render() {
    const {
      stores,
      datas,
      isMain,
      searchStore,
      searchMonth,
      errorMessage,
      page,
      records,
      isModalOpen,
      successSnackOpen,
      successSnackMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>

          <SearchBox onSearch={this.onSearch}>
            <Box display="flex" mt={1} alignItems="center">
              {isMain && (
                <Box width="30%">
                  <FormTitle title="店舗" isRequired />
                  <SearchSelectBox
                    options={stores}
                    value={searchStore}
                    onChange={this.onChange}
                    name="searchStore"
                    disableClearable
                    error={errorMessage.searchStore !== ''}
                  />
                  <FormErrorText>{errorMessage.searchStore}</FormErrorText>
                </Box>
              )}
              <Box width="30%" style={{ marginLeft: '10px' }}>
                <FormTitle title="対象月" isRequired />
                <Monthpicker
                  value={searchMonth}
                  handleChange={(dateValue, value) => this.onChange({ target: { name: 'searchMonth', value } })}
                  name="searchMonth"
                  disableClearable
                  error={errorMessage.searchMonth !== ''}
                />
                <FormErrorText>{errorMessage.searchMonth}</FormErrorText>
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="休日の追加"
            deleteButtonLabel="休日の削除"
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            deleteDialogTitle="選択した休日の削除"
            deleteDialogContent="選択した休日を削除します。この操作は元に戻すことができません。"
            onAdd={this.onAdd}
            isMain={isMain}
          />
          <AddHolidayDialog
            open={isModalOpen}
            handleClose={this.onClose}
            stores={stores}
            isMain={isMain}
          />
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />
        </MainBody>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ holidays: state.holiday, common: state.common });

export default withRouter(connect(mapStateToProps)(HolidayList));
