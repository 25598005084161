import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actHolidayFlgReset, actCallApiMainHolidayCreate } from '../../../redux/setting/holiday/action';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import Validation from './validation';

class AddHolidayDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        targetStore: null,
        targetDate: null,
      },
      errorMessage: {
        targetStore: '',
        targetDate: '',
      },
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  handleSave = () => {
    const { form, errorMessage } = this.state;

    let tempMessage = errorMessage;
    let isError = false;
    Object.keys(form).map(
      (key) => {
        const msg = Validation.formValidate(key, form[key]);
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        if (msg) {
          isError = true;
        }
        return msg;
      },
    );
    this.setState({ errorMessage: tempMessage });

    if (isError) {
      return;
    }

    this.props.dispatch(actHolidayFlgReset());
    this.props.dispatch(actCallApiMainHolidayCreate({
      storeId: form.targetStore.id,
      targetDate: form.targetDate,
    }));
  }

  onChange = (event) => {
    const { form, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      form: { ...form, [name]: value },
      errorMessage: { ...errorMessage, [name]: Validation.formValidate(name, value) },
    });
  }

  init = () => {
    this.setState({
      form: {
        targetStore: this.props.common.user.store,
        targetDate: null,
      },
      errorMessage: {
        targetStore: '',
        targetDate: '',
      },
      btnLoading: false,
    });
  }

  render() {
    const {
      open, handleClose, stores, isMain,
    } = this.props;
    const {
      form, btnLoading, errorMessage,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>休日の追加</DialogTitle>
          <DialogContent dividers>
            {isMain && (
              <Box width="50%" mb={3}>
                <FormTitle title="店舗" isRequired />
                <SearchSelectBox options={stores} value={form.targetStore} onChange={this.onChange} name="targetStore" disableClearable error={errorMessage.targetStore !== ''} />
                <FormErrorText>{errorMessage.targetStore}</FormErrorText>
              </Box>
            )}
            <Box width="50%">
              <FormTitle title="対象日" isRequired />
              <Datepicker
                value={form.targetDate}
                name="targetDate"
                handleChange={(dateValue, value) => this.onChange({ target: { name: 'targetDate', value } })}
                error={errorMessage.targetDate !== ''}
              />
              <FormErrorText>{errorMessage.targetDate}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.handleSave} label="保存" loading={btnLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(AddHolidayDialog));
