import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getBookingDetail,
  insertBookingMemberList, getMembers,
  deleteBookingMember, updateStatus, insertExperience, transferCancel, getBookingMembers,
  reSendZoomUrlTeacher, createWebinars, updateMemo, getGolfBoxs, updateGolfBox,
} from '../../../services/booking/bookingDetail.service';
import {
  ACTION_TYPE,
  actInitBookingSearchDetailSuccess,
  actCallApiInsertBookingMemberSuccess,
  actCallApiBookingDialogMemberListSuccess,
  actCallApiBookingMemberDeleteSuccess,
  actCallApiBookingMemberUpdateSuccess,
  actCallApiBookingExperienceInsertSuccess,
  actCallApiBookingMemberCancelSuccess,
  actCallApiBookingDetailMemberListSuccess,
  actCallApiReSendZoomUrlTeacherSuccess,
  actCallApiCreateWebinarSuccess,
  actCallApiBookingMemoUpdateSuccess,
  actCallApiGetGolfBoxsSuccess,
  actCallApiGolfBoxUpdateSuccess,
  actCallApiGolfBoxUpdateError,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 */
function* callInitBookingDetailSearch({ searchCondition }) {
  try {
    const bookingDetail = yield call(getBookingDetail, searchCondition);
    yield put(actInitBookingSearchDetailSuccess(bookingDetail));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約者取得
 */
function* callBookingDetailMemberList({ searchCondition }) {
  try {
    const bookingDetail = yield call(getBookingMembers, searchCondition);
    yield put(actCallApiBookingDetailMemberListSuccess(bookingDetail));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約者追加
 *
 * @param
 */
function* callApiInsertBookingMember({ values }) {
  try {
    const datas = yield call(insertBookingMemberList, values);
    yield put(actCallApiInsertBookingMemberSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiBookingMemberDelete({ values }) {
  try {
    yield call(deleteBookingMember, values);
    yield put(actCallApiBookingMemberDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠の会員追加モーダル内会員検索
 *
 * @param userInfo
 */
function* callApiBookingDialogMemberList({ searchCondition }) {
  try {
    const members = yield call(getMembers, searchCondition);
    yield put(actCallApiBookingDialogMemberListSuccess(members));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約者ステータス更新
 *
 * @param userInfo
 */
function* callApiBookingMemberUpdate({ params }) {
  try {
    yield call(updateStatus, params.id, {
      status: params.status,
      contentId: params.contentId,
      isTransferAhead: params.isTransferAhead,
    });
    yield put(actCallApiBookingMemberUpdateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 振替キャンセル
 *
 * @param userInfo
 */
function* callApiBookingMemberCancel({ params }) {
  try {
    yield call(transferCancel, params.id);
    yield put(actCallApiBookingMemberCancelSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 体験者追加
 *
 * @param userInfo
 */
function* callApiBookingExperienceInsert({ param }) {
  try {
    yield call(insertExperience, param);
    yield put(actCallApiBookingExperienceInsertSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 先生へZoomUrl再送
 *
 * @param userInfo
 */
function* callApiReSendZoomUrlTeacher({ param }) {
  try {
    yield call(reSendZoomUrlTeacher, param.id);
    yield put(actCallApiReSendZoomUrlTeacherSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiCreateWebinar({ param }) {
  try {
    yield call(createWebinars, param.id);
    yield put(actCallApiCreateWebinarSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 内部メモ更新
 */
function* callApiBookingMemoUpdate({ params }) {
  try {
    yield call(updateMemo, params.id, {
      memo: params.memo,
      contentId: params.contentId,
    });
    yield put(actCallApiBookingMemoUpdateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 打席番号取得
 */
function* callApiGetGolfBoxs({ param }) {
  try {
    const golfBoxs = yield call(getGolfBoxs, param);
    yield put(actCallApiGetGolfBoxsSuccess(golfBoxs));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 打席番号更新
 * @param
 */
function* callApiGolfBoxUpdate({ param }) {
  try {
    const golfBoxUpdateFlg = yield call(updateGolfBox, param);
    if (golfBoxUpdateFlg === 1) {
      yield put(actCallApiGolfBoxUpdateSuccess(golfBoxUpdateFlg));
    } else {
      yield put(actCallApiGolfBoxUpdateError(golfBoxUpdateFlg));
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBookingEventDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_BOOKING_DETAIL_SEARCH, callInitBookingDetailSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_DETAIL_MEMBER_LIST, callBookingDetailMemberList);
  yield takeLatest(ACTION_TYPE.CALL_API_INSERT_BOOKING_MEMBER, callApiInsertBookingMember);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST, callApiBookingDialogMemberList);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_MEMBER_DELETE, callApiBookingMemberDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_MEMBER_UPDATE, callApiBookingMemberUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_MEMBER_CANCEL, callApiBookingMemberCancel);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_EXPERIENCE_INSERT, callApiBookingExperienceInsert);
  yield takeLatest(ACTION_TYPE.CALL_API_RESEND_ZOOMURL_TEACHER, callApiReSendZoomUrlTeacher);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_WEBINAR, callApiCreateWebinar);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_MENU_UPDATE, callApiBookingMemoUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_GOLF_BOXS, callApiGetGolfBoxs);
  yield takeLatest(ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE, callApiGolfBoxUpdate);
}
