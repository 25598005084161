// region Type
export const ACTION_TYPE = {
  CALL_API_CHANGE_PASSWORD_COMMON: 'CALL_API_CHANGE_PASSWORD_COMMON',
  CHANGE_PASSWORD_COMMON_SUCCESS: 'CHANGE_PASSWORD_COMMON_SUCCESS',
  PASSWORD_COMMON_RESET_FLG: 'PASSWORD_COMMON_RESET_FLG',
};
// endregion

// region action
export const actCallApiChangePassword = (params) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD_COMMON,
  params,
});

export const actChangePasswordSuccess = (datas) => ({
  type: ACTION_TYPE.CHANGE_PASSWORD_COMMON_SUCCESS,
  datas,
});

// 更新成功フラグ初期化
export const actResetUpdateFlg = () => ({
  type: ACTION_TYPE.PASSWORD_COMMON_RESET_FLG,
});
// endregion
