import { call, put, takeLatest } from 'redux-saga/effects';
import {
  searchSchoolWeekSchedule, saveSchoolWeekSchedule, deleteSchoolWeekSchedule,
  searchEventWeekSchedule, saveEventWeekSchedule, deleteEventWeekSchedule,
} from '../../../services/share/weekScheduleMenu.service';
import { getWeekSheduleMenuSelectList } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actCallApiWeekScheduleSaveSuccess,
  actCallApiWeekScheduleSearchSuccess,
  actCallApiWeekScheduleDeleteSuccess,
  actCallApiSelectListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 曜日別スケジュール（スクール系、イベント系）作成、更新
 * @param
 */
function* callApiWeekScheduleSave({ schoolFlg, data }) {
  try {
    // スクール系
    if (schoolFlg) {
      yield call(saveSchoolWeekSchedule, data);
      yield put(actCallApiWeekScheduleSaveSuccess());
    }
    // イベント系
    if (!schoolFlg) {
      yield call(saveEventWeekSchedule, data);
      yield put(actCallApiWeekScheduleSaveSuccess());
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュール（スクール系、イベント系）取得
 * @param
 */
function* callApiWeekScheduleMenuSearch({ schoolFlg, id }) {
  try {
    let data = null;

    // スクール系
    if (schoolFlg) {
      data = yield call(searchSchoolWeekSchedule, id);
      yield put(actCallApiWeekScheduleSearchSuccess(data));
    }
    // イベント系
    if (!schoolFlg) {
      data = yield call(searchEventWeekSchedule, id);
      yield put(actCallApiWeekScheduleSearchSuccess(data));
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュール（スクール系、イベント系）削除
 */
function* callApiWeekScheduleDelete({ schoolFlg, id }) {
  try {
    // スクール系
    if (schoolFlg) {
      yield call(deleteSchoolWeekSchedule, id);
      yield put(actCallApiWeekScheduleDeleteSuccess());
    }
    // イベント系
    if (!schoolFlg) {
      yield call(deleteEventWeekSchedule, id);
      yield put(actCallApiWeekScheduleDeleteSuccess());
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiSelectList({ contentId, storeId }) {
  try {
    const selecteList = yield call(getWeekSheduleMenuSelectList, contentId, storeId);
    yield put(actCallApiSelectListSuccess(selecteList));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallWeekScheduleMenu() {
  yield takeLatest(ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SAVE, callApiWeekScheduleSave);
  yield takeLatest(ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SEARCH, callApiWeekScheduleMenuSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_WEEK_SCHEDULE_DELETE, callApiWeekScheduleDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_WEEK_MENU_SELECT_LIST, callApiSelectList);
}
