import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 *  曜日別スケジュール詳細リストを取得する
 *
 * @param {object} id
 */
export function getDatas(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_DETAIL, id.id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ヘッダー更新
 *
 * @param
 */
export function updateHeader(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_DETAIL, null, param.params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
