import { ACTION_TYPE } from './action';

// region Reducer
const memberLinkage = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_MEMBER_LINKAGE_SUCCESS:
      return {
        ...state,
        infoData: action.infoData,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_MEMBER_LINKAGE_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_MEMBER_LINKAGE_DELETE_SUCCESS:
      return {
        ...state,
        delResultDatas: action.datas,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.CALL_API_LINKAGE_DIALOG_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.members,
        isSearchSuccess: true,
      };
    case ACTION_TYPE.CALL_API_INSERT_MEMBER_LINKAGE_SUCCESS:
      return {
        ...state,
        insertDatas: action.datas,
        isInsertSuccess: true,
      };
    case ACTION_TYPE.CALL_API_MEMBER_LINKAGE_FLG_RESET:
      return {
        ...state,
        isSearchSuccess: false,
        isDeleteSuccess: false,
        isInsertSuccess: false,
      };
    default:
      return state;
  }
};

export default memberLinkage;
