import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  LinearProgress,
} from '@material-ui/core';
import Validation from '../validation';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import RequiredChip from '../../../../components/atoms/requiredChip/RequiredChip';
import SearchMultiSelectBox from '../../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import DateTimePicker from '../../../../components/atoms/dateTimePicker/DateTimePicker';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import {
  actCallApiEventCalendarListRoomCultureCheck,
  actCallApiEventCalendarListFlgReset,
  actCallApiEventCalendarListCreate,
} from '../../../../redux/event/eventCalendarList/action';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

class EventCalendarListSaveDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roomCultureCheckLabel: '',
      loading: false,
      btnLoading: false,
      message: {
        schedules: '',
        date: '',
      },
      selectedWeekSchedules: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.eventCalendarList.checkedFlg !== this.props.eventCalendarList.checkedFlg) {
      if (this.props.eventCalendarList.checkedFlg) {
        this.setRoomCultureCheck(this.props.eventCalendarList.roomCultureCheckFlg);
      }
    }
  }

  setRoomCultureCheck(roomCultureCheckLabel) {
    this.setState({
      loading: false,
      btnLoading: false,
      roomCultureCheckLabel,
    });
  }

  onContinueSave = () => {
    this.setState({ roomCultureCheckLabel: '' });
  }

  onCalendarSave = () => {
    const {
      publishedDate, selectedWeekSchedules,
    } = this.state;
    const { storeId, contentId, targetDate } = this.props.selectedRow;

    const tempMessage = {
      schedules: Validation.formValidate('schedules', selectedWeekSchedules),
      date: Validation.formValidate('date', publishedDate),
    };

    this.setState({ message: tempMessage });
    if (contentId === 5 && (tempMessage.date)) {
      return;
    }
    if (contentId !== 5 && (tempMessage.schedules || tempMessage.date)) {
      return;
    }
    this.setState({ btnLoading: true });
    const schedulesIds = selectedWeekSchedules.map((schedule) => schedule.id);
    const schedulesNames = selectedWeekSchedules.map((schedule) => schedule.name);
    this.props.dispatch(actCallApiEventCalendarListFlgReset());
    this.props.dispatch(actCallApiEventCalendarListCreate(
      {
        storeId,
        contentId,
        targetedOn: targetDate,
        publishedDate,
        schedulesIds,
        schedulesNames,
      },
    ));
  }

  onPublishedDateChange = (publishedDate) => {
    const { message } = this.state;
    this.setState({
      publishedDate,
      message: { ...message, date: Validation.formValidate('date', publishedDate) },
    });
  }

  onScheduleChange = (event) => {
    const { value } = event.target;
    const { message } = this.state;
    this.setState({
      selectedWeekSchedules: value,
      message: { ...message, schedules: Validation.formValidate('schedules', value) },
    });
  }

  init() {
    const { contentId, storeId, targetDate } = this.props.selectedRow;
    let loading = false;
    let btnLoading = false;
    if (contentId === 5) {
      loading = true;
      btnLoading = true;
      this.props.dispatch(actCallApiEventCalendarListFlgReset());
      this.props.dispatch(actCallApiEventCalendarListRoomCultureCheck(
        {
          storeId,
          targetedOn: targetDate,
        },
      ));
    }
    this.setState({
      loading,
      roomCultureCheckLabel: '',
      btnLoading,
      publishedDate: `${format(new Date(), 'yyyy/MM/dd')} 00:00`,
      selectedWeekSchedules: [],
      message: {
        schedules: '',
        date: '',
      },
    });
  }

  render() {
    const {
      roomCultureCheckLabel, loading, btnLoading, message, publishedDate,
      selectedWeekSchedules,
    } = this.state;

    const weekSchedules = this.props.eventCalendarList.weekSchedules || [];

    const {
      open,
      onClose,
      selectedRow,
    } = this.props;

    return (
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        disableBackdropClick
        onEscapeKeyDown={onClose}
      >
        <DialogTitle error={roomCultureCheckLabel !== ''}>
          {roomCultureCheckLabel !== '' ? '続行する前に以下を確認してください' : '曜日別スケジュールの適用'}
        </DialogTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: roomCultureCheckLabel !== '' || loading ? 'none' : '' }}>
          <Box mb={3} display={selectedRow.contentId !== 5 ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" m={1} fontSize={15}>
              曜日別スケジュール
              <RequiredChip />
            </Box>
            <SearchMultiSelectBox
              name="selectedWeekSchedules"
              value={selectedWeekSchedules}
              options={weekSchedules}
              onChange={this.onScheduleChange}
              error={message.schedules !== ''}
            />
            <FormErrorText>{message.schedules}</FormErrorText>
          </Box>
          <Box mb={3}>
            <Box fontWeight="fontWeightBold" m={1} fontSize={15}>
              公開日
              <RequiredChip />
            </Box>
            <DateTimePicker
              id="date-picker-published-date"
              handleChange={(_, value) => this.onPublishedDateChange(value)}
              value={publishedDate}
              error={message.date !== ''}
            />
            <FormErrorText>{message.date}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogContent dividers style={{ display: roomCultureCheckLabel !== '' ? '' : 'none' }}>
          <Box>
            {`${roomCultureCheckLabel}が未適用です`}
          </Box>
          <Box>適用を続行しますか？</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            キャンセル
          </Button>
          <Box display={roomCultureCheckLabel === '' ? '' : 'none'}>
            <LoadingButton onClick={this.onCalendarSave} label="適用する" loading={btnLoading} />
          </Box>
          <Box display={roomCultureCheckLabel !== '' ? '' : 'none'}>
            <LoadingButton onClick={this.onContinueSave} label="続行する" loading={btnLoading} />
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  eventCalendarList: state.eventCalendarList,
});

export default connect(mapStateToProps)(EventCalendarListSaveDialog);
