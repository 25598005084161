import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog,
  Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, TextField, Button, LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { endOfMonth } from 'date-fns';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import DateTimePicker from '../../../components/atoms/dateTimePicker/DateTimePicker';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import Validation from './validation';
import compareDateCheck from './compareDateCheck';
import {
  actCallApiCalendarScheduleDelete,
  actCallApiCalendarScheduleFlgReset,
} from '../../../redux/share/calendarScheduleDetail/action';
import {
  actCallApiCalendarScheduleSearch,
  actCallApiCalendarScheduleSave,
  actCallResetMenuSaveFlg,
} from '../../../redux/share/calendarScheduleMenu/action';
import { getSpecificDateDatas } from '../calendarScheduleDetail/share/calendar-schedule-detail-proc';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
  flexButton: {
    flex: '1 1 50%',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const obj = {
  largeClasses: [],
  mediumClasses: [],
  smallClasses: [],
  initMediumClasses: [],
  initSmallClasses: [],
  initMenuList: [],
  menuList: [],
  data: {
    menu: null,
    largeClass: null,
    mediumClass: null,
    smallClass: null,
    targetedOn: null,
    startTime: '',
    endTime: '',
    rooms: [],
    teacher: null,
    capacity: '',
    delImposibleFlg: true,
    publishedOn: null,
  },
  roomList: [],
  teacherList: [],
  message: {
    menu: '',
    targetedOn: '',
    startTime: '',
    endTime: '',
    capacity: '',
    publishedOn: '',
  },
  loading: false,
  changeLoading: false,
  deleteConfirmOpen: false,
  isShowPublishDate: false,
};

class CalendarScheduleMenuDialog extends Component {
  constructor(props) {
    super(props);
    this.state = obj;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
        if (this.props.id) {
          this.search(this.props.id);
        }
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.calendarScheduleMenu.data !== this.props.calendarScheduleMenu.data) {
      if (this.props.calendarScheduleMenu.data) {
        this.setdata(this.props.calendarScheduleMenu.data);
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  init = () => {
    const { selectList } = this.props.weekScheduleMenu;

    this.setState({
      ...obj,
      largeClasses: selectList.classes.largeClasses,
      mediumClasses: [],
      smallClasses: [],
      initMediumClasses: selectList.classes.mediumClasses,
      initSmallClasses: selectList.classes.smallClasses,
      initMenuList: selectList.menuList,
      menuList: selectList.menuList,
      roomList: selectList.roomList,
      teacherList: selectList.teacherList,
    });
  }

  setdata = (data) => {
    const { initMediumClasses, initSmallClasses, initMenuList } = this.state;
    let tempData = data;
    let menuList = initMenuList || [];
    let mediumClasses = [];
    let smallClasses = [];

    if (tempData.largeClass.id) {
      menuList = initMenuList.filter(
        (values) => values.mstLargeClassId === tempData.largeClass.id,
      );
      mediumClasses = initMediumClasses.filter(
        (values) => values.parentId === tempData.largeClass.id,
      );
    } else {
      tempData = { ...tempData, largeClass: null };
    }

    if (tempData.mediumClass.id) {
      menuList = menuList.filter(
        (values) => values.mstMediumClassId === tempData.mediumClass.id,
      );
      smallClasses = initSmallClasses.filter(
        (values) => values.parentId === tempData.mediumClass.id,
      );
    } else {
      tempData = { ...tempData, mediumClass: null };
    }

    if (tempData.smallClass.id) {
      menuList = menuList.filter((values) => values.mstSmallClassId === tempData.smallClass.id);
    } else {
      tempData = { ...tempData, smallClass: null };
    }

    if (!tempData.menu.id && !tempData.menu.name) {
      tempData = { ...tempData, menu: null };
    }

    if (!tempData.teacher.id && !tempData.teacher.name) {
      tempData = { ...tempData, teacher: null };
    }

    if (!tempData.capacity) {
      tempData = { ...tempData, capacity: '' };
    }

    this.setState({
      data: tempData,
      mediumClasses,
      smallClasses,
      menuList,
    });
  }

  setDeleteConfirm = (state) => {
    this.setState({ deleteConfirmOpen: state });
  }

  onChangeLargeClasses = (event) => {
    const { value } = event.target;
    const { data, initMediumClasses, initMenuList } = this.state;
    let tempDatas = data;
    let { menu } = data;
    if (value && data.menu && data.menu.mstLargeClassId !== value.id) {
      menu = null;
    }
    tempDatas = {
      ...tempDatas,
      largeClass: value,
      mediumClass: null,
      smallClass: null,
      menu,
    };
    this.setState({
      data: tempDatas,
      mediumClasses: value
        ? initMediumClasses.filter((values) => values.parentId === value.id) : [],
      smallClasses: [],
      menuList: value
        ? initMenuList.filter((values) => values.mstLargeClassId === value.id) : initMenuList,
    });
  }

  onChangeMediumClasses = (event) => {
    const { value } = event.target;
    const { data, initSmallClasses, initMenuList } = this.state;
    let tempDatas = data;
    let menu = null;
    if (value && data.menu && data.menu.mstMediumClassId === value.id) {
      menu = data.menu;
    }

    if (!value && data.menu && data.menu.mstLargeClassId === data.largeClass.id) {
      menu = data.menu;
    }
    tempDatas = {
      ...tempDatas,
      mediumClass: value,
      smallClass: null,
      menu,
    };
    this.setState({
      data: tempDatas,
      smallClasses: value
        ? initSmallClasses.filter((values) => values.parentId === value.id) : [],
      menuList: value
        ? initMenuList.filter((valuse) => valuse.mstMediumClassId === value.id)
        : initMenuList.filter((valuse) => valuse.mstLargeClassId === data.largeClass.id),
    });
  }

  onChangeSmallClasses = (event) => {
    const { value } = event.target;
    const { data, initMenuList } = this.state;
    let tempDatas = data;
    let menu = null;
    if (value && data.menu && data.menu.mstSmallClassId === value.id) {
      menu = data.menu;
    }

    if (!value && data.menu && data.menu.mstMediumClassId === data.mediumClass.id) {
      menu = data.menu;
    }

    tempDatas = {
      ...tempDatas,
      smallClass: value,
      menu,
    };
    this.setState({
      data: tempDatas,
      menuList: value
        ? initMenuList.filter((values) => values.mstSmallClassId === value.id)
        : initMenuList.filter((values) => values.mstMediumClassId === data.mediumClass.id),
    });
  }

  onChangeTimeTextfield = (event, name) => {
    const { data, message } = this.state;
    const { value } = event.target;
    const key = name;
    const tempMessage = { ...message, [key]: Validation.formValidate(key, value) };
    this.setState({
      data: { ...data, [key]: value },
      message: tempMessage,
    });

    if (key === 'startTime' && value && data.endTime) {
      this.compareTime(tempMessage, value, data.endTime);
    }

    if (key === 'endTime' && value && data.startTime) {
      this.compareTime(tempMessage, data.startTime, value);
    }
  }

  compareTime = (tempMessage, startTime, endTime) => {
    const start = Number(startTime.replace(':', ''));
    const end = Number(endTime.replace(':', ''));
    this.setState({
      message: { ...tempMessage, endTime: Validation.compareTime(start, end) },
    });
    return { ...tempMessage, endTime: Validation.compareTime(start, end) };
  }

  onChangeSelectBox = (event) => {
    const { value, name } = event.target;
    const { data, message } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  onTargetedDateChange = (_, targetedOn) => {
    const { data, message } = this.state;
    const { minDate, maxDate } = this;
    const { contentId, bookings } = this.props;
    let isShowPublishDate = false;
    let publishedOn = null;
    if (targetedOn && contentId !== 2) {
      const dayDatas = getSpecificDateDatas(new Date(targetedOn), bookings);
      if (dayDatas.length === 0) {
        isShowPublishDate = true;
      } else {
        publishedOn = dayDatas[0].publishedOn;
      }
    }

    this.setState({
      data: { ...data, targetedOn, publishedOn },
      message: { ...message, targetedOn: Validation.formValidate('minMaxCheck', { targetedOn, minDate, maxDate }) },
      isShowPublishDate,
    });
  }

  onPublishDateChange = (_, publishedOn) => {
    const { data, message } = this.state;
    this.setState({
      data: { ...data, publishedOn },
      message: { ...message, publishedOn: Validation.formValidate('publishedOn', publishedOn) },
    });
  }

  handleSave = () => {
    const { data, message } = this.state;
    const {
      menu, targetedOn, startTime, endTime, rooms, teacher, capacity, publishedOn,
    } = this.state.data;
    const { isShowPublishDate } = this.state;
    const {
      id, contentId, storeId, headerId,
    } = this.props;

    let tempMessage = message;
    let isError = false;

    Object.keys(data).map(
      (key) => {
        if (id && contentId === 2 && key === 'targetedOn') return '';
        if (key === 'targetedOn' && tempMessage.targetedOn !== '') {
          isError = true;
          return '';
        }
        let msg = '';
        if (key !== 'publishedOn' || (key === 'publishedOn' && isShowPublishDate)) {
          msg = Validation.formValidate(key, data[key]);
        }

        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        if (msg) {
          isError = true;
        }
        return msg;
      },
    );

    if (startTime && endTime) {
      tempMessage = this.compareTime(tempMessage, startTime, endTime);
    }

    this.setState({ message: tempMessage });

    if (message.endTime || isError) {
      return null;
    }

    this.props.dispatch(actCallApiCalendarScheduleFlgReset());
    this.setState({ changeLoading: true });

    const roomIds = rooms.map((room) => room.id);

    this.props.dispatch(actCallResetMenuSaveFlg());
    this.props.dispatch(
      actCallApiCalendarScheduleSave(
        contentId,
        {
          id,
          contentId,
          storeId,
          headerId,
          menuId: menu && menu.id && menu.id,
          menuName: menu && !menu.id && menu.name,
          targetedOn,
          startTime,
          endTime,
          roomIds,
          teacherId: teacher && teacher.id && teacher.id,
          teacherName: teacher && !teacher.id && teacher.name,
          capacity,
          publishedOn,
        },
      ),
    );
    return null;
  }

  handleDelete = () => {
    const { id, regular } = this.props;
    this.props.dispatch(actCallApiCalendarScheduleFlgReset());
    this.props.dispatch(actCallApiCalendarScheduleDelete(id, regular));
  }

  search = (id) => {
    this.props.dispatch(
      actCallApiCalendarScheduleSearch(this.props.contentId, id),
    );
  }

  render() {
    const {
      classes, id, open, onClose, contentId, targetDate,
    } = this.props;

    const {
      largeClasses, mediumClasses, smallClasses, menuList,
      roomList, teacherList, data, message, loading,
      deleteConfirmOpen, changeLoading, isShowPublishDate,
    } = this.state;

    let targetedDate = new Date();
    if (targetDate) { targetedDate = new Date(targetDate); }
    this.minDate = compareDateCheck(targetedDate, new Date()) ? new Date() : targetedDate;
    this.maxDate = endOfMonth(targetedDate);

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { id ? '予約枠を変更' : '予約枠を追加'}
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <Box display="flex" mt={2}>
              <Box pr={2} width="30%">
                <FormTitle title="大分類" />
                <SearchSelectBox
                  name="largeClass"
                  options={largeClasses}
                  onChange={this.onChangeLargeClasses}
                  value={data.largeClass}
                />
              </Box>
              <Box pr={2} width="30%">
                <FormTitle title="中分類" />
                <SearchSelectBox
                  name="mediumClass"
                  options={mediumClasses}
                  onChange={this.onChangeMediumClasses}
                  value={data.mediumClass}
                />
              </Box>
              <Box width="30%">
                <FormTitle title="小分類" />
                <SearchSelectBox
                  name="smallClass"
                  options={smallClasses}
                  onChange={this.onChangeSmallClasses}
                  value={data.smallClass}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="メニュー" isRequired />
              { data.menu && !data.menu.id ? (
                <TextField
                  value={data.menu.name}
                  onChange={(event) => this.onChangeTimeTextfield(event, 'menu')}
                  variant="outlined"
                  className={classes.menuInput}
                  error={message.menu.length > 0}
                  inputProps={{ maxLength: 80 }}
                />
              ) : (
                <SearchSelectBox
                  name="menu"
                  options={menuList}
                  onChange={this.onChangeSelectBox}
                  value={data.menu}
                  error={message.menu !== ''}
                />
              )}
              <FormErrorText>{message.menu}</FormErrorText>
            </Box>
            <Box mt={3}>
              <FormTitle title="開催日" isRequired />
              { contentId === 2 && id && data.targetedOn && (
                <Box my={1} fontSize={17}>
                  {format(new Date(data.targetedOn), 'yyyy年MM月dd日')}
                </Box>
              )}
              { contentId !== 2 && (
                <Datepicker
                  id="date-picker-targeted-date"
                  handleChange={this.onTargetedDateChange}
                  formatter="yyyy/MM/dd"
                  value={data.targetedOn}
                  error={message.targetedOn.length > 0}
                  minDate={this.minDate}
                  maxDate={this.maxDate}
                />
              )}
              <FormErrorText>{message.targetedOn}</FormErrorText>
            </Box>
            <Box mt={3} display="flex">
              <Box className={classes.timeInputBox}>
                <FormTitle title="開始時間" isRequired />
                { contentId === 2 && (
                  <Box my={1} fontSize={17}>
                    {data.startTime}
                  </Box>
                )}
                { contentId !== 2 && (
                  <div>
                    <TextField
                      type="time"
                      className={classes.timeInput}
                      variant="outlined"
                      value={data.startTime}
                      onChange={(event) => this.onChangeTimeTextfield(event, 'startTime')}
                      error={message.startTime.length > 0}
                    />
                    <FormErrorText>{message.startTime}</FormErrorText>
                  </div>
                )}
              </Box>
              <Box ml={3} className={classes.timeInputBox}>
                <FormTitle title="終了時間" isRequired />
                { contentId === 2 && (
                  <Box my={1} fontSize={17}>{data.endTime}</Box>
                )}
                { contentId !== 2 && (
                  <div>
                    <TextField
                      type="time"
                      className={classes.timeInput}
                      variant="outlined"
                      value={data.endTime}
                      onChange={(event) => this.onChangeTimeTextfield(event, 'endTime')}
                      error={message.endTime.length > 0}
                    />
                    <FormErrorText>{message.endTime}</FormErrorText>
                  </div>
                )}
              </Box>
            </Box>
            {contentId !== 2 && (
              <Box mt={3}>
                <FormTitle title="Room" />
                <SearchMultiSelectBox
                  name="rooms"
                  value={data.rooms}
                  options={roomList}
                  onChange={this.onChangeSelectBox}
                />
              </Box>
            )}
            <Box mt={3}>
              <FormTitle title="先生" />
              { data.teacher && !data.teacher.id ? (
                <TextField
                  value={data.teacher.name}
                  onChange={(event) => this.onChangeTimeTextfield(event, 'teacher')}
                  variant="outlined"
                  className={classes.menuInput}
                  inputProps={{ maxLength: 120 }}
                />
              ) : (
                <SearchSelectBox
                  name="teacher"
                  options={teacherList}
                  value={data.teacher}
                  onChange={this.onChangeSelectBox}
                />
              )}
            </Box>
            <Box mt={3}>
              <FormTitle title="定員" isRequired />
              <TextField
                value={data.capacity}
                onChange={(event) => this.onChangeTimeTextfield(event, 'capacity')}
                variant="outlined"
                className={classes.capacityInput}
                error={message.capacity.length > 0}
                inputProps={{ maxLength: 9 }}
              />
              <FormErrorText>{message.capacity}</FormErrorText>
            </Box>
            { isShowPublishDate && (
              <Box mt={3}>
                <FormTitle title="公開日" isRequired />
                <DateTimePicker
                  handleChange={this.onPublishDateChange}
                  value={data.publishedOn}
                  error={message.publishedOn !== ''}
                />
                <FormErrorText>{message.publishedOn}</FormErrorText>
              </Box>
            )}
          </Container>
        </DialogContent>
        <DialogActions style={{ display: loading ? 'none' : '' }}>
          <Box display={!data.delImposibleFlg ? '' : 'none'} className={classes.flexButton}>
            <Button onClick={() => this.setDeleteConfirm(true)} color="secondary">
              削除する
            </Button>
          </Box>
          <LoadingButton loading={changeLoading} onClick={this.handleSave} label="保存する" />
        </DialogActions>
        <ConfirmDialog
          open={deleteConfirmOpen}
          handleClose={() => this.setDeleteConfirm(false)}
          executeText="削除する"
          handleExecute={this.handleDelete}
          title="予約枠の削除"
          contentText="予約枠を削除します。この操作は元に戻すことができません。"
          loading={changeLoading}
          buttonColor="secondary"
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  weekScheduleMenu: state.weekScheduleMenu,
  calendarScheduleMenu: state.calendarScheduleMenu,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(CalendarScheduleMenuDialog));

CalendarScheduleMenuDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  contentId: PropTypes.number,
};

CalendarScheduleMenuDialog.defaultProps = {
  id: null,
  contentId: null,
};
