import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper,
  Button,
} from '@material-ui/core';
import Pagination from '../../../components/atoms/pagination/Pagination';

export default function FormErrorText(props) {
  const {
    rows,
    onDetailClick,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>時間</TableCell>
              <TableCell>メニュー</TableCell>
              <TableCell>先生</TableCell>
              <TableCell>Room</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow hover tabIndex={-1} key={row.id}>
                <TableCell>{row.targetTime}</TableCell>
                <TableCell>{row.menuName}</TableCell>
                <TableCell>{row.teacherName}</TableCell>
                <TableCell>{row.roomName}</TableCell>
                <TableCell>
                  <Button color="primary" onClick={(event) => onDetailClick(event, row)}>出席登録を開始する</Button>
                </TableCell>
              </TableRow>
            ))}
            {
              rows.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                    データがありません
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalElements={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
