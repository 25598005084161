import { call, put, takeLatest } from 'redux-saga/effects';
import setUpdate from '../../../services/other/changePassword.service';
import {
  ACTION_TYPE,
  actChangePasswordSuccess,
} from './action';
import { actCallApiServerError } from '../common/action';

/**
 * 更新処理
 */
function* callApiChangePassword({ params }) {
  try {
    const datas = yield call(setUpdate, params.id, params.param);
    yield put(actChangePasswordSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallHeaderChangePassword() {
  yield takeLatest(ACTION_TYPE.CALL_API_CHANGE_PASSWORD_COMMON, callApiChangePassword);
}
