// region Type
export const ACTION_TYPE = {
  CALL_API_INIT_SITE_SETTING: 'CALL_API_INIT_SITE_SETTING',
  CALL_API_INIT_SITE_SETTING_SUCCESS: 'CALL_API_INIT_SITE_SETTING_SUCCESS',
  CALL_API_SITE_SETTING_SEARCH: 'CALL_API_SITE_SETTING_SEARCH',
  CALL_API_SITE_SETTING_SEARCH_SUCCESS: 'CALL_API_SITE_SETTING_SEARCH_SUCCESS',
  CALL_API_SITE_SETTING_UPDATE: 'CALL_API_SITE_SETTING_UPDATE',
  CALL_API_SITE_SETTING_UPDATE_SUCCESS: 'CALL_API_SITE_SETTING_UPDATE_SUCCESS',
  RESET_SITE_SETTING_FLG: 'RESET_SITE_SETTING_FLG',
};
// endregion

// region action
export const actCallInitSiteSetting = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SITE_SETTING,
});

export const actCallInitSiteSettingSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_INIT_SITE_SETTING_SUCCESS,
  stores,
});

export const actCallApiSiteSettingSearch = (param) => ({
  type: ACTION_TYPE.CALL_API_SITE_SETTING_SEARCH,
  param,
});

export const actCallApiSiteSettingSearchSuccess = (siteSetting) => ({
  type: ACTION_TYPE.CALL_API_SITE_SETTING_SEARCH_SUCCESS,
  siteSetting,
});

export const actCallApiSiteSettingUpdate = (param) => ({
  type: ACTION_TYPE.CALL_API_SITE_SETTING_UPDATE,
  param,
});

export const actCallApiSiteSettingUpdateSuccess = (siteSetting) => ({
  type: ACTION_TYPE.CALL_API_SITE_SETTING_UPDATE_SUCCESS,
  siteSetting,
});

export const actResetSiteSettingFlg = () => ({
  type: ACTION_TYPE.RESET_SITE_SETTING_FLG,
});

// endregion
