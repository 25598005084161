import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box, TextField, FormControl,
  Select, Radio, RadioGroup, FormControlLabel,
  Button, withStyles, Paper, FormHelperText,
  Dialog, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogContent, LinearProgress, Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import validation from './validation';
import {
  getZipCodeAddress, actResetContractFlg, actUpdateMember, actCheckMemberNo,
} from '../../../redux/member/memberDetail/action';
import { actCallApiInitSelectValues } from '../../../redux/member/entry/action';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// #region css
const styles = (theme) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
  flexButton: {
    flex: '1 1 50%',
  },
  root: {
    marginBottom: theme.spacing(10),
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '75px',
    },
  },
  smallTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
  smallTextFieldDate: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
});
// #endregion

class MemberDetailChangeDialog extends React.Component {
  constructor(props) {
    super(props);
    let mstStoreId = { mstOrganizationCompanyId: null };
    if (props.common.user) {
      mstStoreId = props.common.user.store;
    }
    const errorMessage = {
      corporationName: '',
      mstStoreId: '',
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      zipCode: '',
      mstCodePrefectureCode: '',
      city: '',
      street: '',
      email: '',
      memberNo: '',
      phone: '',
      phoneTwo: '',
      birthday: '',
      mstCodeGenderCode: '',
      emergencyLastName: '',
      emergencyFirstName: '',
      emergencyPhone: '',
    };
    const member = {
      corporationName: '',
      lastName: '',
      memberNo: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      zipCodeBefore: '',
      zipCodeAfter: '',
      mstCodePrefectureCode: null,
      city: '',
      street: '',
      email: '',
      phoneBefore: '',
      phoneMiddle: '',
      phoneAfter: '',
      phoneTwoBefore: '',
      phoneTwoMiddle: '',
      phoneTwoAfter: '',
      birthdayYear: '',
      birthdayMonth: '',
      birthdayDay: '',
      mstCodeGenderCode: 1,
      emergencyLastName: '',
      emergencyFirstName: '',
      emergencyPhoneBefore: '',
      emergencyPhoneMiddle: '',
      emergencyPhoneAfter: '',
      stop: false,
    };
    const initSelectValues = {
      prefexturesSelectList: [],
      storesSelectList: [],
      contractCodeSelectList: [],
      genderSelectList: [],
      applicantTypeSelectList: [],
      membershipAgreementSelectList: [],
    };
    props.dispatch(actCallApiInitSelectValues());
    this.state = {
      errorMessage,
      mstStoreId,
      member,
      isCorporation: false,
      initSelectValues,
      isLoading: false,
      errorMessageShowFlg: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.setMember(this.props.member);
      }
    }
    if (prevProps.entry.initSelectValues !== this.props.entry.initSelectValues) {
      if (this.props.entry.initSelectValues) {
        this.setInitSelectValues(this.props.entry.initSelectValues);
      }
    }
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setStore(this.props.common.user);
      }
    }
    if (prevProps.memberDetail.zipcode !== this.props.memberDetail.zipcode) {
      if (this.props.memberDetail.zipcode) {
        this.setAddress(this.props.memberDetail.zipcode);
      }
    }
    if (prevProps.memberDetail.isCheckSuccess !== this.props.memberDetail.isCheckSuccess) {
      if (this.props.memberDetail.isCheckSuccess) {
        this.setMemberCheck(this.props.memberDetail.isCheckResult);
      }
    }
  }

  setMemberCheck = (result) => {
    const { errorMessage } = this.state;
    this.setState({
      errorMessage: {
        ...errorMessage,
        memberNo: result ? '既に存在する会員番号です' : '',
      },
    });
  }

  setInitSelectValues = (initSelectValues) => {
    this.setState({ initSelectValues });
  }

  setMember = (member) => {
    const { initSelectValues } = this.state;
    const zipCodes = member.zipCode ? member.zipCode.split('-') : [];
    const phones = member.phone ? member.phone.split('-') : [];
    const phoneTwos = member.phoneTwo ? member.phoneTwo.split('-') : [];
    const birthdays = member.birthday ? member.birthday.split('-') : [];
    const emergencyPhones = member.emergencyPhone ? member.emergencyPhone.split('-') : [];
    let mstCodePrefectureCode = {};
    if (member.mstCodePrefectureCode) {
      initSelectValues.prefexturesSelectList.forEach((data) => {
        if (member.mstCodePrefectureCode === data.id) {
          mstCodePrefectureCode = data;
        }
      });
    }
    this.setState({
      isCorporation: member.mstCodeContractCode && member.mstCodeContractCode !== 1,
      member: {
        ...member,
        mstCodePrefectureCode,
        zipCodeBefore: zipCodes.length > 0 ? zipCodes[0] : '',
        zipCodeAfter: zipCodes.length > 0 ? zipCodes[1] : '',
        phoneBefore: phones.length > 0 ? phones[0] : '',
        phoneMiddle: phones.length > 0 ? phones[1] : '',
        phoneAfter: phones.length > 0 ? phones[2] : '',
        phoneTwoBefore: phoneTwos.length > 0 ? phoneTwos[0] : '',
        phoneTwoMiddle: phoneTwos.length > 0 ? phoneTwos[1] : '',
        phoneTwoAfter: phoneTwos.length > 0 ? phoneTwos[2] : '',
        birthdayYear: birthdays.length > 0 ? birthdays[0] : '',
        birthdayMonth: birthdays.length > 0 ? parseInt(birthdays[1], 10) : '',
        birthdayDay: birthdays.length > 0 ? birthdays[2] : '',
        emergencyPhoneBefore: emergencyPhones.length > 0 ? emergencyPhones[0] : '',
        emergencyPhoneMiddle: emergencyPhones.length > 0 ? emergencyPhones[1] : '',
        emergencyPhoneAfter: emergencyPhones.length > 0 ? emergencyPhones[2] : '',
      },
    });
  }

  setStore = (user) => {
    this.setState({ mstStoreId: user.store });
  }

  setAddress = (value) => {
    const { member, errorMessage } = this.state;
    let mstCodePrefectureCode = null;
    let city = '';
    let street = '';
    let zipCode = '';

    if (value.results && value.results.length > 0) {
      mstCodePrefectureCode = {
        name: value.results[0].address1,
        id: parseInt(value.results[0].prefcode, 10),
      };
      city = value.results[0].address2;
      street = value.results[0].address3;
    }
    if (!value.results) {
      zipCode = '存在しない郵便番号です';
    }
    this.setState({
      member: {
        ...member,
        mstCodePrefectureCode,
        city,
        street,
      },
      errorMessage: {
        ...errorMessage,
        zipCode,
        mstCodePrefectureCode: validation.formValidate('mstCodePrefectureCode', mstCodePrefectureCode),
        city: validation.formValidate('city', city),
        street: validation.formValidate('street', street),
      },
      isZipLoading: false,
    });
  }

  onClickUpdateCorrect = () => {
    const {
      isContracter,
    } = this.props;

    const {
      errorMessage,
      member,
      mstStoreId,
      isCorporation,
    } = this.state;
    const companyId = mstStoreId.mstOrganizationCompanyId;

    if (!isContracter && errorMessage.memberNo) {
      document.getElementById('body').scrollTo(0, 0);
      return;
    }

    let tempMessage = errorMessage;

    const birthdayVal = `${member.birthdayYear}/${member.birthdayMonth}/${member.birthdayDay}`;
    const birthdayKey = (companyId === 4 || !isContracter) ? 'birthdayNoRequired' : 'birthday';
    const birthday = validation.formValidate(birthdayKey, birthdayVal);

    const phoneVal = `${member.phoneBefore}-${member.phoneMiddle}-${member.phoneAfter}`;
    const phone = validation.formValidate('phone', phoneVal);

    const phoneTwoVal = `${member.phoneTwoBefore}-${member.phoneTwoMiddle}-${member.phoneTwoAfter}`;
    const phoneTwo = validation.formValidate('phoneTwo', phoneTwoVal);

    const zipCodeVal = `${member.zipCodeBefore}-${member.zipCodeAfter}`;
    const zipCodeKey = !isContracter ? 'zipCodeNorequired' : 'zipCode';
    const zipCode = validation.formValidate(zipCodeKey, zipCodeVal);

    let email = validation.formValidate('email', member.email);
    let emergencyLastName = '';
    let emergencyFirstName = '';
    let emergencyPhoneVal = '';
    let emergencyPhone = '';
    let corporationName = '';

    if (isContracter) {
      emergencyLastName = validation.formValidate('emergencyLastName', member.emergencyLastName);
      emergencyFirstName = validation.formValidate('emergencyFirstName', member.emergencyFirstName);
      emergencyPhoneVal = `${member.emergencyPhoneBefore}-${member.emergencyPhoneMiddle}-${member.emergencyPhoneAfter}`;
      emergencyPhone = validation.formValidate('emergencyPhone', emergencyPhoneVal);
      corporationName = validation.formValidate('corporationName', member.corporationName);
    }

    if (companyId === 4) {
      email = validation.formValidate('emailNoRequired', member.email);
      emergencyPhone = '';
      emergencyLastName = '';
      emergencyFirstName = '';
    }

    tempMessage = {
      ...tempMessage,
      birthday,
      phone,
      phoneTwo,
      zipCode,
      emergencyPhone,
      corporationName,
      email,
      emergencyLastName,
      emergencyFirstName,
    };

    Object.keys(tempMessage).map(
      (key) => {
        if ((!isCorporation && key === 'corporationName')
        || (!isContracter && (key === 'mstCodePrefectureCode' || key === 'city' || key === 'street'))
        || (key === 'birthday') || (key === 'phone')
        || (key === 'phoneTwo') || (key === 'zipCode')
        || (key === 'mstStoreId') || (key === 'email')
        || (key === 'emergencyPhone')
        || (key === 'emergencyLastName') || (key === 'emergencyFirstName')) { return null; }

        tempMessage = {
          ...tempMessage,
          [key]: validation.formValidate(key, member[key]),
        };
        return null;
      },
    );

    const isError = Object.values(tempMessage).filter((value) => value !== '').length > 0;

    if (!isError) {
      this.props.dispatch(actResetContractFlg());
      this.props.dispatch(actUpdateMember({
        ...member,
        mstCodePrefectureCode: member.mstCodePrefectureCode
          ? member.mstCodePrefectureCode.id : null,
        contracter: isContracter,
      }));
    } else {
      document.getElementById('body').scrollTo(0, 0);
    }

    this.setState({ errorMessageShowFlg: isError, errorMessage: tempMessage });
  }

  onChangeTextField = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage, mstStoreId } = this.state;
    const companyId = mstStoreId.mstOrganizationCompanyId;

    member[name] = value;

    let validationName = name;
    if (companyId === 4 && name === 'email') {
      validationName = 'emailNoRequired';
    }
    if (companyId === 4 && (name === 'emergencyFirstName' || name === 'emergencyLastName')) {
      validationName = '';
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: validation.formValidate(validationName, value),
      },
    });
  }

  onChangePhone = (event) => {
    const { member, errorMessage } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'phoneBefore') {
      phoneVal = `${value}-${member.phoneMiddle}-${member.phoneAfter}`;
    }
    if (name === 'phoneMiddle') {
      phoneVal = `${member.phoneBefore}-${value}-${member.phoneAfter}`;
    }
    if (name === 'phoneAfter') {
      phoneVal = `${member.phoneBefore}-${member.phoneMiddle}-${value}`;
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phone: validation.formValidate('phone', phoneVal),
      },
    });
  }

  onChangePhoneTwo = (event) => {
    const { member, errorMessage } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'phoneTwoBefore') {
      phoneVal = `${value}-${member.phoneTwoMiddle}-${member.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoMiddle') {
      phoneVal = `${member.phoneTwoBefore}-${value}-${member.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoAfter') {
      phoneVal = `${member.phoneTwoBefore}-${member.phoneTwoMiddle}-${value}`;
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phoneTwo: validation.formValidate('phoneTwo', phoneVal),
      },
    });
  }

  onChangeEmergencyPhone = (event) => {
    const { member, errorMessage, mstStoreId } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'emergencyPhoneBefore') {
      phoneVal = `${value}-${member.emergencyPhoneMiddle}-${member.emergencyPhoneAfter}`;
    }
    if (name === 'emergencyPhoneMiddle') {
      phoneVal = `${member.emergencyPhoneBefore}-${value}-${member.emergencyPhoneAfter}`;
    }
    if (name === 'emergencyPhoneAfter') {
      phoneVal = `${member.emergencyPhoneBefore}-${member.emergencyPhoneMiddle}-${value}`;
    }

    const key = mstStoreId.mstOrganizationCompanyId === 4 ? 'phoneTwo' : 'emergencyPhone';

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        emergencyPhone: validation.formValidate(key, phoneVal),
      },
    });
  }

  onChangeBirthday = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage, mstStoreId } = this.state;
    let birthdayVal = null;
    if (name === 'birthdayYear') {
      birthdayVal = `${value}/${member.birthdayMonth}/${member.birthdayDay}`;
    }
    if (name === 'birthdayMonth') {
      birthdayVal = `${member.birthdayYear}/${value}/${member.birthdayDay}`;
    }
    if (name === 'birthdayDay') {
      birthdayVal = `${member.birthdayYear}/${member.birthdayMonth}/${value}`;
    }

    const key = mstStoreId.mstOrganizationCompanyId === 4 ? 'birthdayNoRequired' : 'birthday';

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        birthday: validation.formValidate(key, birthdayVal),
      },
    });
  }

  onChangeZipCode = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage } = this.state;

    let zipCodeVal = null;
    if (name === 'zipCodeBefore') {
      zipCodeVal = `${value}-${member.zipCodeAfter}`;
    }
    if (name === 'zipCodeAfter') {
      zipCodeVal = `${member.zipCodeBefore}-${value}`;
    }

    let isLoading = false;
    if (zipCodeVal.length === 8) {
      isLoading = true;
      this.props.dispatch(getZipCodeAddress({
        zipcode: zipCodeVal,
      }));
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        zipCode: validation.formValidate('zipCode', zipCodeVal),
      },
      isZipLoading: isLoading,
    });
  }

  onChangeMemberNo = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage } = this.state;

    if (value.length === 10) {
      this.props.dispatch(actResetContractFlg());
      this.props.dispatch(actCheckMemberNo({
        id: member.id,
        memberNo: value,
      }));
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        memberNo: validation.formValidate('memberNo', value),
      },
    });
  }

  onChangeSearchSelectBox = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage } = this.state;

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: validation.formValidate([name], value),
      },
    });
  }

  onChangeRadio = (event) => {
    const { member } = this.state;
    const { name, value } = event.target;

    this.setState({
      member: {
        ...member,
        [name]: parseInt(value, 10),
      },
    });
  }

  onCheckChange = (event) => {
    const { member } = this.state;
    this.setState({
      member: {
        ...member,
        stop: event.target.checked,
      },
    });
  }

  render() {
    const {
      classes,
      isContracter,
      open,
      onClose,
    } = this.props;

    const {
      isCorporation,
      errorMessage,
      errorMessageShowFlg,
      member,
      mstStoreId,
      initSelectValues,
      isLoading, isZipLoading,
    } = this.state;

    const companyId = mstStoreId && mstStoreId.mstOrganizationCompanyId;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {isContracter ? '契約者情報の変更' : '会員情報の変更'}
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: isLoading ? '' : 'none' }} />
        <DialogContent id="body" dividers style={{ display: isLoading ? 'none' : '' }} ref={this.formRef}>
          <Container>
            <Box className={classes.root}>
              <Box my={3}>
                <Alert style={{ display: errorMessageShowFlg ? '' : 'none' }} severity="error">入力エラーがあります。確認してください。</Alert>
              </Box>

              <Paper elevation={0} variant="outlined">
                <Box p={3}>

                  <Box mb={3} display={isCorporation ? '' : 'none'}>
                    <FormTitle title="法人名" isRequired />
                    <TextField
                      name="corporationName"
                      value={member.corporationName}
                      variant="outlined"
                      autoComplete="off"
                      onChange={this.onChangeTextField}
                      error={errorMessage.corporationName !== ''}
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                    <FormErrorText>{errorMessage.corporationName}</FormErrorText>
                  </Box>
                  <Box mb={3} display={!isContracter ? '' : 'none'}>
                    <FormTitle title="会員番号" isRequired />
                    <TextField
                      name="memberNo"
                      value={member.memberNo}
                      variant="outlined"
                      autoComplete="off"
                      onChange={this.onChangeMemberNo}
                      error={errorMessage.memberNo !== ''}
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                    />
                    <FormErrorText>{errorMessage.memberNo}</FormErrorText>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="氏名" isRequired />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box key="lastNameKey">
                          <TextField
                            name="lastName"
                            id="lastNameContract"
                            value={member.lastName}
                            variant="outlined"
                            onChange={this.onChangeTextField}
                            placeholder="姓"
                            autoComplete="off"
                            error={errorMessage.lastName !== ''}
                            inputProps={{ maxLength: 80 }}
                          />
                          <FormErrorText>{errorMessage.lastName}</FormErrorText>
                        </Box>
                        <Box ml={2} key="firstNameKey">
                          <TextField
                            name="firstName"
                            id="firstNameContract"
                            value={member.firstName}
                            variant="outlined"
                            onChange={this.onChangeTextField}
                            placeholder="名"
                            autoComplete="off"
                            error={errorMessage.firstName !== ''}
                            inputProps={{ maxLength: 40 }}
                          />
                          <FormErrorText>{errorMessage.firstName}</FormErrorText>
                        </Box>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="氏名カナ" isRequired />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box>
                          <TextField
                            name="lastNameKana"
                            id="lastNameKanaContract"
                            variant="outlined"
                            value={member.lastNameKana}
                            onChange={this.onChangeTextField}
                            placeholder="セイカナ"
                            autoComplete="off"
                            error={errorMessage.lastNameKana !== ''}
                            inputProps={{ maxLength: 100 }}
                          />
                          <FormErrorText>{errorMessage.lastNameKana}</FormErrorText>
                        </Box>
                        <Box ml={2} key="firstNameKanaKey">
                          <TextField
                            name="firstNameKana"
                            id="firstNameKanaContract"
                            variant="outlined"
                            value={member.firstNameKana}
                            onChange={this.onChangeTextField}
                            placeholder="メイカナ"
                            autoComplete="off"
                            error={errorMessage.firstNameKana !== ''}
                            inputProps={{ maxLength: 100 }}
                          />
                          <FormErrorText>{errorMessage.firstNameKana}</FormErrorText>
                        </Box>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="郵便番号" isRequired={isContracter} />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box>
                          <TextField
                            name="zipCodeBefore"
                            id="zipCodeBeforeContract"
                            value={member.zipCodeBefore}
                            variant="outlined"
                            onChange={this.onChangeZipCode}
                            placeholder="950"
                            autoComplete="off"
                            className={classes.smallTextField}
                            error={errorMessage.zipCode !== ''}
                            inputProps={{ maxLength: 3 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} p={2}><span>-</span></Box>
                        <Box>
                          <TextField
                            name="zipCodeAfter"
                            id="zipCodeAfterContract"
                            value={member.zipCodeAfter}
                            variant="outlined"
                            onChange={this.onChangeZipCode}
                            placeholder="0032"
                            autoComplete="off"
                            className={classes.smallTextField}
                            error={errorMessage.zipCode !== ''}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <FormErrorText>{errorMessage.zipCode}</FormErrorText>
                      </Box>
                      <Box>
                        <FormHelperText style={{ color: 'black' }}>
                          郵便番号をご入力頂くと、住所の一部が自動入力されます
                        </FormHelperText>
                      </Box>
                      <Box display={isZipLoading ? '' : 'none'}>
                        <FormHelperText style={{ color: 'black' }}>
                          検索しています..
                        </FormHelperText>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="都道府県" isRequired={isContracter} />
                    <SearchSelectBox
                      name="mstCodePrefectureCode"
                      onChange={this.onChangeSearchSelectBox}
                      value={member.mstCodePrefectureCode}
                      options={initSelectValues.prefexturesSelectList}
                      error={errorMessage.mstCodePrefectureCode !== ''}
                      placeholder="選択してください"
                      disableClearable
                    />
                    <FormErrorText>{errorMessage.mstCodePrefectureCode}</FormErrorText>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="市区町村" isRequired={isContracter} />
                    <TextField
                      name="city"
                      id="cityContract"
                      variant="outlined"
                      autoComplete="off"
                      onChange={this.onChangeTextField}
                      placeholder="例）新潟市江南区両川"
                      value={member.city}
                      error={errorMessage.city !== ''}
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                    />
                    <FormErrorText>{errorMessage.city}</FormErrorText>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="町名・番地・その他" isRequired={isContracter} />
                    <TextField
                      name="street"
                      id="streetContract"
                      variant="outlined"
                      onChange={this.onChangeTextField}
                      placeholder="例）2丁目3927-15"
                      autoComplete="off"
                      value={member.street}
                      error={errorMessage.street !== ''}
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                    />
                    <FormErrorText>{errorMessage.street}</FormErrorText>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="メールアドレス" isRequired={companyId !== 4} />
                    <TextField
                      name="email"
                      id="emailContract"
                      variant="outlined"
                      onChange={this.onChangeTextField}
                      placeholder="例）example@email.com"
                      value={member.email}
                      autoComplete="off"
                      error={errorMessage.email !== ''}
                      inputProps={{ maxLength: 80 }}
                      fullWidth
                    />
                    <FormErrorText>{errorMessage.email}</FormErrorText>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="電話番号" isRequired />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box>
                          <TextField
                            name="phoneBefore"
                            id="phoneBeforeContract"
                            variant="outlined"
                            onChange={this.onChangePhone}
                            placeholder="025"
                            autoComplete="off"
                            value={member.phoneBefore}
                            error={errorMessage.phone !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 5 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} p={2}><span>-</span></Box>
                        <Box key="phoneMiddleKey">
                          <TextField
                            name="phoneMiddle"
                            id="phoneMiddleContract"
                            variant="outlined"
                            onChange={this.onChangePhone}
                            placeholder="250"
                            autoComplete="off"
                            value={member.phoneMiddle}
                            error={errorMessage.phone !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} p={2}><span>-</span></Box>
                        <Box key="phoneAfterKey">
                          <TextField
                            name="phoneAfter"
                            id="phoneAfterContract"
                            variant="outlined"
                            onChange={this.onChangePhone}
                            placeholder="7112"
                            autoComplete="off"
                            value={member.phoneAfter}
                            error={errorMessage.phone !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <FormErrorText>{ errorMessage.phone }</FormErrorText>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="電話番号2" />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box key="phoneTwoBeforeKey">
                          <TextField
                            name="phoneTwoBefore"
                            id="phoneTwoBeforeContract"
                            variant="outlined"
                            onChange={this.onChangePhoneTwo}
                            placeholder="090"
                            autoComplete="off"
                            value={member.phoneTwoBefore}
                            error={errorMessage.phoneTwo !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 5 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} p={2}><span>-</span></Box>
                        <Box key="phoneTwoMiddleKey">
                          <TextField
                            name="phoneTwoMiddle"
                            id="phoneTwoMiddleContract"
                            variant="outlined"
                            onChange={this.onChangePhoneTwo}
                            placeholder="1234"
                            autoComplete="off"
                            value={member.phoneTwoMiddle}
                            error={errorMessage.phoneTwo !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} p={2}><span>-</span></Box>
                        <Box key="phoneTwoAfterKey">
                          <TextField
                            name="phoneTwoAfter"
                            id="phoneTwoAfterContract"
                            variant="outlined"
                            onChange={this.onChangePhoneTwo}
                            placeholder="5678"
                            autoComplete="off"
                            value={member.phoneTwoAfter}
                            error={errorMessage.phoneTwo !== ''}
                            className={classes.phoneTextField}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <FormErrorText>{ errorMessage.phoneTwo }</FormErrorText>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="生年月日" isRequired={companyId !== 4 && isContracter} />
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box>
                          <TextField
                            name="birthdayYear"
                            id="birthdayYearContract"
                            onChange={this.onChangeBirthday}
                            variant="outlined"
                            placeholder="年"
                            autoComplete="off"
                            value={member.birthdayYear}
                            error={errorMessage.birthday !== ''}
                            className={classes.smallTextFieldDate}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} pr={2} pl={1}><span>年</span></Box>
                        <Box>
                          <FormControl variant="outlined" error={errorMessage.birthday !== ''} className={classes.smallTextFieldDate}>
                            <Select native name="birthdayMonth" id="birthdayMonthContract" onChange={this.onChangeBirthday} value={member.birthdayMonth}>
                              <option aria-label="None" value="" />
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                              <option value={11}>11</option>
                              <option value={12}>12</option>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box className={classes.textCenter} pr={2} pl={1}><span>月</span></Box>
                        <Box>
                          <TextField
                            name="birthdayDay"
                            id="birthdayDayContract"
                            value={member.birthdayDay}
                            onChange={this.onChangeBirthday}
                            variant="outlined"
                            placeholder="日"
                            autoComplete="off"
                            className={classes.smallTextFieldDate}
                            error={errorMessage.birthday !== ''}
                            inputProps={{ maxLength: 2 }}
                          />
                        </Box>
                        <Box className={classes.textCenter} pr={2} pl={1}><span>日</span></Box>
                      </Box>
                      <Box display="flex">
                        <FormErrorText>{errorMessage.birthday}</FormErrorText>
                      </Box>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <FormTitle title="性別" isRequired={companyId !== 4} />
                    <RadioGroup row aria-label="gender" id="mstCodeGenderCode" value={member.mstCodeGenderCode} onChange={this.onChangeRadio}>
                      {initSelectValues.genderSelectList.map((option) => (
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          value={option.id}
                          key={option.id}
                          name="mstCodeGenderCode"
                          label={option.name}
                          checked={member.mstCodeGenderCode === option.id}
                        />
                      ))}
                    </RadioGroup>
                  </Box>

                  {isContracter && (
                    <Box mb={3}>
                      <FormTitle title="緊急連絡先氏名" isRequired={companyId !== 4} />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="emergencyLastName"
                              id="emergencyLastNameContract"
                              value={member.emergencyLastName}
                              onChange={this.onChangeTextField}
                              variant="outlined"
                              placeholder="姓"
                              autoComplete="off"
                              error={errorMessage.emergencyLastName !== ''}
                              inputProps={{ maxLength: 80 }}
                            />
                            <FormErrorText>{errorMessage.emergencyLastName}</FormErrorText>
                          </Box>
                          <Box ml={2}>
                            <TextField
                              name="emergencyFirstName"
                              id="emergencyFirstNameContract"
                              value={member.emergencyFirstName}
                              onChange={this.onChangeTextField}
                              variant="outlined"
                              placeholder="名"
                              autoComplete="off"
                              error={errorMessage.emergencyFirstName !== ''}
                              inputProps={{ maxLength: 40 }}
                            />
                            <FormErrorText>{errorMessage.emergencyFirstName}</FormErrorText>
                          </Box>
                        </Box>
                      </FormControl>
                    </Box>
                  )}

                  {isContracter && (
                    <Box mb={3}>
                      <FormTitle title="緊急連絡先電話番号" isRequired={companyId !== 4} />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="emergencyPhoneBefore"
                              id="emergencyPhoneBeforeContract"
                              value={member.emergencyPhoneBefore}
                              onChange={this.onChangeEmergencyPhone}
                              variant="outlined"
                              placeholder="025"
                              autoComplete="off"
                              className={classes.phoneTextField}
                              error={errorMessage.emergencyPhone !== ''}
                              inputProps={{ maxLength: 5 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="emergencyPhoneMiddle"
                              id="emergencyPhoneMiddleContract"
                              value={member.emergencyPhoneMiddle}
                              onChange={this.onChangeEmergencyPhone}
                              variant="outlined"
                              placeholder="2502"
                              autoComplete="off"
                              className={classes.phoneTextField}
                              error={errorMessage.emergencyPhone !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="emergencyPhoneAfter"
                              id="emergencyPhoneAfterContract"
                              value={member.emergencyPhoneAfter}
                              onChange={this.onChangeEmergencyPhone}
                              variant="outlined"
                              placeholder="7112"
                              autoComplete="off"
                              className={classes.phoneTextField}
                              error={errorMessage.emergencyPhone !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <FormErrorText>{ errorMessage.emergencyPhone }</FormErrorText>
                        </Box>
                      </FormControl>
                    </Box>
                  )}
                  {isContracter && (
                    <Box>
                      <FormTitle title="利用停止" />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={member.stop}
                            onChange={this.onCheckChange}
                          />
                        )}
                        label="利用停止"
                      />
                    </Box>
                  )}
                </Box>
              </Paper>

              <Box align="center" my={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.onClickUpdateCorrect}
                >
                  保存する
                </Button>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
  members: state.members,
  common: state.common,
  entry: state.entry,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberDetailChangeDialog)));
