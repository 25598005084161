import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function LoadingButton(props) {
  const {
    loading,
    onClick,
    label,
    color,
    disabled,
    startIcon,
  } = props;

  return (
    <Box>
      <Button style={{ display: loading ? 'none' : '', pointerEvents: loading ? 'none' : '' }} onClick={onClick} color={color} disabled={disabled} startIcon={startIcon}>{label}</Button>
      <Box ml={1} pt={1} pr={3} style={{ display: loading ? '' : 'none' }}>
        <CircularProgress size={20} />
      </Box>
    </Box>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.elementType,
};

LoadingButton.defaultProps = {
  color: 'primary',
  disabled: false,
  startIcon: null,
};
