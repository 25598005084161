import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Paper, Container, Button,
} from '@material-ui/core';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import WeekScheduleTable from '../../../components/templates/weekScheduleTable/WeekScheduleTable';
import WeekScheduleMenuDialog from '../../share/weekScheduleMenuDialog/WeekScheduleMenuDialog';
import EventWeekScheduleDialog from '../eventWeekScheduleDialog/EventWeekScheduleDialog';
import { actCallInitEventScheduleDetail, actApiEventScheduleDetailFlgReset } from '../../../redux/event/eventWeekScheduleDetail/action';
import { actCallApiSelectList } from '../../../redux/share/weekScheduleMenu/action';
import urls from '../../../constants/urls';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
  paper: {
    width: 'fit-content',
    marginBottom: '20px',
  },
  headerUpdateBtn: {
    borderTop: '1px solid #eeeeee',
    paddingTop: '10px',
  },
  headerName: {
    wordBreak: 'break-word',
  },
});

class EventWeekScheduleDetail extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.EVENT.WEEK_SCHEDULES,
        name: '曜日別スケジュール一覧',
      },
      {
        name: '曜日別スケジュール詳細',
      },
    ];

    const {
      headerId, isMain,
    } = props.location.state;

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.eventWeekScheduleDetail.isCreated) {
      successSnackOpen = true;
      successSnackMessage = '曜日別スケジュールの作成が完了しました';
    }
    if (props.eventWeekScheduleDetail.isCopied) {
      successSnackOpen = true;
      successSnackMessage = '曜日別スケジュールのコピーが完了しました';
    }
    props.dispatch(actApiEventScheduleDetailFlgReset());

    this.state = {
      header: {
        id: null,
        store: {},
        content: {},
        name: '',
      },
      headerId,
      isMain,
      datas: {},
      successSnackOpen,
      successSnackMessage,
      dialogOpen: false,
      scheduleDetailId: null,
      scheduleModalOpen: false,
    };

    if (!this.state.headerId) {
      props.history.push({
        pathname: urls.EVENT.WEEK_SCHEDULES,
      });
      return;
    }

    props.dispatch(actCallInitEventScheduleDetail(
      { id: this.state.headerId },
    ));
  }

  componentDidMount() {
    document.title = '曜日別スケジュール詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventWeekScheduleDetail.headerData
      !== this.props.eventWeekScheduleDetail.headerData) {
      if (this.props.eventWeekScheduleDetail.headerData) {
        this.setHeaderData(this.props.eventWeekScheduleDetail.headerData);
      }
    }
    if (prevProps.eventWeekScheduleDetail.datas !== this.props.eventWeekScheduleDetail.datas) {
      if (this.props.eventWeekScheduleDetail.datas) {
        this.setDatas(this.props.eventWeekScheduleDetail.datas);
      }
    }
    if (prevProps.eventWeekScheduleDetail.isUpdated
      !== this.props.eventWeekScheduleDetail.isUpdated) {
      if (this.props.eventWeekScheduleDetail.isUpdated) {
        this.setSuccessMessage('管理名の変更が完了しました');
      }
    }
    if (prevProps.weekScheduleMenu.isSaved !== this.props.weekScheduleMenu.isSaved) {
      if (this.props.weekScheduleMenu.isSaved) {
        this.setSuccessMessage('メニューの保存が完了しました');
      }
    }
    if (prevProps.weekScheduleMenu.isDeleted !== this.props.weekScheduleMenu.isDeleted) {
      if (this.props.weekScheduleMenu.isDeleted) {
        this.setSuccessMessage('メニューの削除が完了しました');
      }
    }
  }

  setHeaderData(header) {
    this.setState({ header });
    this.props.dispatch(actCallApiSelectList(header.content.id, header.store.id));
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  handleDialogOpen = (_, id) => {
    this.setState({ dialogOpen: true, scheduleDetailId: id, successSnackOpen: false });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false, scheduleModalOpen: false });
  }

  handleScheduleModalOpen = () => {
    this.setState({ scheduleModalOpen: true, successSnackOpen: false });
  }

  setInitSuccessMessage = (message) => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: message,
    });
  }

  setSuccessMessage = (message) => {
    const { headerId } = this.state;
    this.setState({
      successSnackOpen: true,
      successSnackMessage: message,
      dialogOpen: false,
      scheduleModalOpen: false,
    });
    this.props.dispatch(actCallInitEventScheduleDetail({ id: headerId }));
  }

  render() {
    const { classes } = this.props;

    const {
      header, dialogOpen, scheduleModalOpen, scheduleDetailId,
      successSnackOpen, successSnackMessage, datas, isMain,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <Paper elevation={0} variant="outlined" className={classes.paper}>
            <Box display="flex" m={2}>
              <Box mr={10} display={isMain ? '' : 'none'}>
                <FormTitle title="店舗" />
                <Box>{header.store.name}</Box>
              </Box>
              <Box mr={10}>
                <FormTitle title="コンテンツ" />
                <Box>{header.content.name}</Box>
              </Box>
              <Box mr={10}>
                <FormTitle title="管理名" />
                <Box className={classes.headerName}>{header.name}</Box>
              </Box>
            </Box>
            <Box className={classes.headerUpdateBtn} textAlign="right" m={1}>
              <Button color="primary" size="medium" onClick={this.handleScheduleModalOpen}>管理名の変更</Button>
            </Box>
          </Paper>
          <Box textAlign="right" m={1}>
            <Button variant="contained" color="primary" size="large" onClick={(event) => this.handleDialogOpen(event, null)}>メニューの追加</Button>
          </Box>
          <WeekScheduleTable
            datas={datas}
            schoolFlg={false}
            onMenuUpdateDialog={this.handleDialogOpen}
          />
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />
          <EventWeekScheduleDialog
            id={header.id}
            open={scheduleModalOpen}
            handleClose={this.handleDialogClose}
            copyFlg={2}
            header={header}
          />
          <WeekScheduleMenuDialog
            open={dialogOpen}
            onClose={this.handleDialogClose}
            schoolFlg={false}
            id={scheduleDetailId}
            contentId={header.content.id}
            headerId={header.id}
            storeId={header.store.id}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventWeekScheduleDetail: state.eventWeekScheduleDetail,
  weekScheduleMenu: state.weekScheduleMenu,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventWeekScheduleDetail)));
