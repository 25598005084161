import { ACTION_TYPE } from './aciton';
// region Reducer
const classInfomation = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_CLASS_INFOMATION_SUCCESS:
      return {
        ...state,
        rooms: action.rooms,
      };
    case ACTION_TYPE.ACT_SEARCH_ROOMS_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.ACT_ATTEND_SUCCESS:
      return {
        ...state,
        isAttend: action.result,
        isUpdate: true,
      };
    case ACTION_TYPE.ACT_ISUPDATE_RESET:
      return {
        ...state,
        isUpdate: false,
      };
    default:
      return state;
  }
};

export default classInfomation;
