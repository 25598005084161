// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_MEMBER_LIST: 'CALL_API_INIT_MEMBER_LIST',
  INIT_MEMBER_LIST_SUCCESS: 'INIT_MEMBER_LIST_SUCCESS',
  CALL_API_MEMBER_LIST_SEARCH: 'CALL_API_MEMBER_LIST_SEARCH',
  CALL_API_MEMBER_LIST_SUCCESS: 'CALL_API_MEMBER_LIST_SUCCESS',
  CALL_API_CHANGE_MEMBER_PASSWORD: 'CALL_API_CHANGE_MEMBER_PASSWORD',
  CHANGE_MEMBER_PASSWORD_SUCCESS: 'CHANGE_MEMBER_PASSWORD_SUCCESS',
  PASSWORD_RESET_FLG: 'PASSWORD_RESET_FLG',
  CALL_API_GET_CSV_MEMBER_DATA: 'CALL_API_GET_CSV_MEMBER_DATA',
  CALL_API_GET_CSV_MEMBER_DATA_SUCCESS: 'CALL_API_GET_CSV_MEMBER_DATA_SUCCESS',
  SET_SEARCH_CONDITION: 'SET_SEARCH_CONDITION',
};
// #endregion

// #region Type
export const actCallInitMemberList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_MEMBER_LIST,
});

export const actInitMemberListSuccess = (stores) => ({
  type: ACTION_TYPE.INIT_MEMBER_LIST_SUCCESS,
  stores,
});

export const actCallApiMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LIST_SEARCH,
  searchCondition,
});

export const actCallApiMemberListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LIST_SUCCESS,
  datas,
});

export const actCallApiChangeMemberPassword = (params) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_MEMBER_PASSWORD,
  params,
});

export const actChangeMemberPasswordSuccess = (datas) => ({
  type: ACTION_TYPE.CHANGE_MEMBER_PASSWORD_SUCCESS,
  datas,
});

// 更新成功フラグ初期化
export const actResetMemberUpdateFlg = () => ({
  type: ACTION_TYPE.PASSWORD_RESET_FLG,
});

export const actCallApiGetCsvMemberData = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA,
  searchCondition,
});

export const actCallApiGetCsvMemberDataSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA_SUCCESS,
  datas,
});

export const setSearchCondition = ({ searchCondition }) => ({
  type: ACTION_TYPE.SET_SEARCH_CONDITION,
  searchCondition,
});
// #endregion
