import { ACTION_TYPE } from './action';

const schoolWeekSchedules = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_SCHOOL_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_DELETE_SUCCESS:
      return {
        ...state,
        delResultDatas: action.datas,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_HEADER_INFO_SUCCESS:
      return {
        ...state,
        headerData: action.headerData,
      };
    case ACTION_TYPE.SET_SEARCH_SCHOOL_SCHEDULE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_FLG_RESET:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default schoolWeekSchedules;
