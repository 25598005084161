import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { getSelected } from '../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class DataTable extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: [],
      deleteConfopen: false,
      loading: false,
    };
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => !row.main)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  onPreDelete = () => {
    this.setState({ deleteConfopen: true, loading: false });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: false });
    this.props.onDelete(selected);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
      onAdd,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      onPreview,
      previewClose,
      onPreviewUpdate,
      previewRelease,

    } = this.props;
    const {
      selected, deleteConfopen, loading,
    } = this.state;

    return (
      <>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : null}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 ? (
              <Tooltip title="広告バナーの削除">
                <Button color="secondary" onClick={this.onPreDelete}>広告バナーの削除</Button>
              </Tooltip>
            ) : (
              <Tooltip title="広告バナーの追加">
                <Button color="primary" onClick={onAdd}>広告バナーの追加</Button>
              </Tooltip>
            )}
          </Toolbar>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length
                        && rows.filter((row) => !row.main).length !== selected.length}
                      checked={
                        rows.filter((row) => !row.main).length > 0
                        && rows.filter((row) => !row.main).length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length < 1 || rows.filter((row) => !row.main).length < 1
                      }
                    />
                  </TableCell>
                  <TableCell>区分</TableCell>
                  <TableCell>画像名</TableCell>
                  <TableCell>ステータス</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows).map((row, index) => {
                  const isItemSelected = this.isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {!row.main && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{row.main ? 'メイン画像' : '広告バナー'}</TableCell>
                      <TableCell>
                        {row.displayFileName}
                        <Button color="primary" onClick={(event) => onPreview(event, row.url)} onClose={previewClose}>プレビュー</Button>
                      </TableCell>
                      {row.main ? <TableCell /> : <TableCell>{row.released ? '公開中' : '公開停止'}</TableCell>}
                      <TableCell>
                        {row.main && <Button color="primary" onClick={() => onPreviewUpdate(null, row.id)}>変更する</Button>}
                        {!row.main && <Button color={row.released ? 'secondary' : 'primary'} onClick={() => previewRelease(row.id)}>{row.released ? '公開停止' : '公開する'}</Button>}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={6}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <ConfirmDialog
            open={deleteConfopen}
            handleClose={this.handleClose}
            executeText="削除する"
            handleExecute={this.onDelete}
            title="選択した広告バナーの削除"
            contentText="選択した広告バナーを削除します。この操作は元に戻すことができません。"
            loading={loading}
            buttonColor="secondary"
          />
        </Paper>
      </>
    );
  }
}

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onAdd: PropTypes.func.isRequired,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  previewClose: PropTypes.func.isRequired,
  onPreviewUpdate: PropTypes.func.isRequired,
  previewRelease: PropTypes.func.isRequired,
};

DataTable.defaultProps = {
  totalElements: 0,
};

export default withStyles(styles)(DataTable);
