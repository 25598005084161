export const ACTION_TYPE = {
  CALL_API_INIT_MAIL_DETAIL: 'CALL_API_INIT_MAIL_DETAIL',
  INIT_MAIL_DETAIL_SUCCESS: 'INIT_MAIL_DETAIL_SUCCESS',
  CALL_API_UPDATE_MAIL_DETAIL: 'CALL_API_UPDATE_MAIL_DETAIL',
  UPDATE_MAIL_DETAIL_SUCCESS: 'UPDATE_MAIL_DETAIL_SUCCESS',
  CALL_UPDATE_MAIL_FLG_RESET: 'CALL_UPDATE_MAIL_FLG_RESET',
};

export const actCallInitMailDetail = (id) => ({
  type: ACTION_TYPE.CALL_API_INIT_MAIL_DETAIL,
  id,
});

export const actInitMailDetailSuccess = (data) => ({
  type: ACTION_TYPE.INIT_MAIL_DETAIL_SUCCESS,
  data,
});

export const actUpdateMailDetail = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MAIL_DETAIL,
  id: data.id,
  title: data.title,
  contents: data.contents,
});

export const actUpdateMailDetailSuccess = (data) => ({
  type: ACTION_TYPE.UPDATE_MAIL_DETAIL_SUCCESS,
  data,
});

export const actCallUpdateMailFlgReset = () => ({
  type: ACTION_TYPE.CALL_UPDATE_MAIL_FLG_RESET,
});
