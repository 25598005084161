import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 *  IDによるスクール型曜日別スケジュール詳細を取得する
 *
 * @param {object} id
 */
export function getDetailDatas(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_SCHEDULE_DETAIL.SCHOOL_SCHEDULE_DETAIL, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 定期曜日別スケジュールコピー
 *
 *
 */
export function insertSchoolScheduleCopy(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.SCHOOL_SCHEDULE_DETAIL.COPY_SCHEDULE_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
