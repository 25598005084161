import { ACTION_TYPE } from './action';
// region Reducer
const home = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_HOME_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.ACT_CALL_API_HOME_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
};

export default home;
