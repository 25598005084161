import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getGolfCalendarHeader, searchGolfBookingDetail, updateGolfHeader, updateGolfFrame,
  updateBulkGolfFrame,
} from '../../../services/event/eventCalendarDetailGolf.service';
import {
  ACTION_TYPE,
  actCallApiGetGolfHeaderSuccess,
  actCallApiUpdateGolfPublishDateSuccess,
  actCallApiGetGolfBookingDetailSuccess,
  actCallApiUpdateGolfFrameSuccess,
  apiCallUpdateBulkFrameLimitSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 予約枠カレンダー(ヘッダ)取得
 * @param
 */
function* callApiGetGolfHeader(param) {
  try {
    const datas = yield call(getGolfCalendarHeader, param.param.headerId);
    yield put(actCallApiGetGolfHeaderSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠ヘッダー公開日変更
 * @param
 */
function* callApiUpdateGolfPublishDate(param) {
  try {
    yield call(updateGolfHeader, param.param.headerId, param.param);
    yield put(actCallApiUpdateGolfPublishDateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠カレンダー取得
 * @param
 */
function* callApiGolfBookingDetail(param) {
  try {
    const data = yield call(searchGolfBookingDetail, param.param);
    yield put(actCallApiGetGolfBookingDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * ゴルフ予約枠（フリー・的打ち）変更
 * @param
 */
function* callApiCalendarScheduleGolfFrameUpdate({ param }) {
  try {
    yield call(updateGolfFrame, param);
    yield put(actCallApiUpdateGolfFrameSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * ゴルフ予約枠一括変更
 * @param
 */
function* callApiCalendarScheduleGolfBulkUpdate({ data }) {
  try {
    yield call(updateBulkGolfFrame, data);
    yield put(apiCallUpdateBulkFrameLimitSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCalendarDetailGolf() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_GET_CALENDAR_GOLF_HEADER, callApiGetGolfHeader,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE, callApiUpdateGolfPublishDate,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_GET_GOLF_BOOKING_DETAIL, callApiGolfBookingDetail,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_GOLF_FRAME_UPDATE,
    callApiCalendarScheduleGolfFrameUpdate,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_GOLF_BULK_UPDATE,
    callApiCalendarScheduleGolfBulkUpdate,
  );
}
