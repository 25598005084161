import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Box } from '@material-ui/core';
import EntryStepper from '../entryForm/stepper/EntryStepper';
import urls from '../../../../constants/urls';
import MainBody from '../../../../components/templates/mainBody/MainBody';
import BasicBreadcrumbs from '../../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import ContractForm from '../entryForm/contract/ContractForm';
import ConfirmForm from '../entryForm/confirm/ConfirmForm';
import UserForm from '../entryForm/user/UserForm';
import PasswordForm from '../entryForm/password/PasswordForm';
import CompleteEntry from '../entryForm/complete/CompleteEntry';

import {
  actCallApiInitSelectValues, actCallSetMemberEntryForm,
} from '../../../../redux/member/entry/action';
import { createForm } from '../entryForm/entry-proc';

class IndividualEntry extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { url: urls.MEMBER.MEMBER_LIST, name: '会員' },
      { name: '個人会員登録' },
    ];

    const initSelectValues = {
      prefexturesSelectList: [],
      storesSelectList: [],
      contractCodeSelectList: [],
      genderSelectList: [],
      applicantTypeSelectList: [],
      membershipAgreementSelectList: [],
    };
    props.dispatch(actCallApiInitSelectValues());

    this.state = {
      activeStep: 0,
      initSelectValues,
      isFromConfirmForm: false,
      isSameContractUser: '1',
      saveDatas: {},
    };

    this.scrollToTop();
    props.dispatch(actCallSetMemberEntryForm(createForm()));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entry.initSelectValues !== this.props.entry.initSelectValues) {
      if (this.props.entry.initSelectValues) {
        this.setInitSelectValues(this.props.entry.initSelectValues);
      }
    }
    if (prevProps.entry.isSaveSuccess !== this.props.entry.isSaveSuccess) {
      if (this.props.entry.isSaveSuccess) {
        this.setComplete(this.props.entry.saveMembers);
      }
    }
  }

  setComplete = (saveDatas) => {
    this.scrollToTop();
    this.setState({ saveDatas, activeStep: 4 });
  }

  setInitSelectValues = (initSelectValues) => {
    this.setState({ initSelectValues });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onClickToNext = (activeStep) => {
    this.scrollToTop();
    this.setState({ activeStep });
  }

  onClickGoBackFromConfirm = (activeStep, isFromConfirmForm) => {
    this.scrollToTop();
    this.setState({ activeStep, isFromConfirmForm });
  }

  onClickToNextSetContract = (activeStep, isSameContractUser) => {
    this.setState({ activeStep, isSameContractUser });
  }

  render() {
    const {
      activeStep,
      isFromConfirmForm,
      initSelectValues,
      isSameContractUser,
      saveDatas,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />
        <MainBody>
          <EntryStepper
            activeStep={activeStep}
          />

          <Box display={activeStep === 0 ? '' : 'none'}>
            <ContractForm
              onClickToNext={this.onClickToNext}
              isFromConfirmForm={isFromConfirmForm}
              initSelectValues={initSelectValues}
            />
          </Box>

          <Box display={activeStep === 1 ? '' : 'none'}>
            <UserForm
              onClickToNext={this.onClickToNext}
              onClickToNextSetContract={this.onClickToNextSetContract}
              isFromConfirmForm={isFromConfirmForm}
              initSelectValues={initSelectValues}
            />
          </Box>

          <Box display={activeStep === 2 ? '' : 'none'}>
            <PasswordForm
              onClickToNext={this.onClickToNext}
            />
          </Box>

          <Box display={activeStep === 3 ? '' : 'none'}>
            <ConfirmForm
              onClickGoBackFromConfirm={this.onClickGoBackFromConfirm}
              isSameContractUser={isSameContractUser}
              initSelectValues={initSelectValues}
            />
          </Box>

          <Box display={activeStep === 4 ? '' : 'none'}>
            <CompleteEntry datas={saveDatas} />
          </Box>
        </MainBody>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ entry: state.entry });

export default connect(mapStateToProps)(IndividualEntry);
