import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getScreens, getRole, insertData, updateRoleScreen, updateData,
} from '../../../services/userSetting/roleDetail.service';
import {
  ACTION_TYPE,
  actCallApiInitScreensSuccess, actCallApiGetRoleSuccess, actCallApiUpdateRoleScreenSuccess,
  actCallApiInsertRoleSuccess, actCallApiUpdateRoleSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 画面一覧取得
 *
 * @param
 */
function* callApiInitScreens() {
  try {
    const screens = yield call(getScreens);
    yield put(actCallApiInitScreensSuccess(screens));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限取得
 *
 * @param
 */
function* callApiGetRoles({ param }) {
  try {
    const data = yield call(getRole, param);
    yield put(actCallApiGetRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限画面更新
 *
 * @param
 */
function* callApiUpdateRoleScreen({ param }) {
  try {
    const data = yield call(updateRoleScreen, param);
    yield put(actCallApiUpdateRoleScreenSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限追加
 *
 * @param
 */
function* callApiInsertRole({ param }) {
  try {
    const data = yield call(insertData, param);
    yield put(actCallApiInsertRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限更新
 *
 * @param
 */
function* callApiUpdateRole({ param }) {
  try {
    const data = yield call(updateData, param);
    yield put(actCallApiUpdateRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRoleDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SCREENS, callApiInitScreens);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROLES, callApiGetRoles);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREENS, callApiUpdateRoleScreen);
  yield takeLatest(ACTION_TYPE.CALL_API_INSERT_ROLE, callApiInsertRole);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_ROLE, callApiUpdateRole);
}
