import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import format from 'date-fns/format';
import {
  Card, Box, withStyles, Checkbox, Radio, RadioGroup, FormControlLabel,
  Button, TextField, Grid, Typography, Container,
} from '@material-ui/core';
import { actCallInitHealthCheck, actInsertHealthCheckResult, actCallApiMemberInfo } from '../../../redux/info/healthCheck/action';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import elcLogo from '../../../public/assets/elcLogo.png';
import urls from '../../../constants/urls';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
  elcLogo: {
    width: '45%',
  },
  homeBtn: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  resultNoticeSub: {
    color: 'gray',
  },
  completeButton: {
    marginLeft: '20px',
  },
  completeTitle: {
    wordBreak: 'break-all',
  },
  footer: {
    padding: '10px',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  footerCopyright: {
    textAlign: 'center',
    backgroundColor: '#f0f0f4',
    color: 'black',
    fontSize: '15px',
  },
});

class HealthCheck extends Component {
  constructor(props) {
    super(props);

    let storeId = '';
    if (this.props.common.user) {
      storeId = this.props.common.user.store.id;
      this.props.dispatch(actCallInitHealthCheck({ id: storeId }));
    }

    this.state = {
      healthCheckInfo: {
        content1: '・ヘルスチェックは来店されるお客様、及び業者様、スタッフ全員にご記入頂くものです。',
        content2: '・当施設では、利用者皆様に安全で快適にお過ごしいただく為に、会則に従い以下の各項目をご了承いただけない方にはご利用をお断りしております。',
      },
      ctrlBtn: true,
      storeId,
      radioValue: 'member',
      healthCheckItem: [],
      agreeCheck: false,
      info: {
        memberId: '',
        otherName: '',
      },
      message: {
        memberId: '',
        otherName: '',
      },
      memberName: '',
      resultPageFlg: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.healthChecks.items !== this.props.healthChecks.items) {
      if (this.props.healthChecks.items) {
        this.setItems(this.props.healthChecks.items);
      }
    }
    if (prevProps.healthChecks.member !== this.props.healthChecks.member) {
      if (this.props.healthChecks.member) {
        this.setMemberInfo(this.props.healthChecks.member);
      }
    }
  }

  setUser(user) {
    this.setState({ storeId: user.store.id });
    this.props.dispatch(actCallInitHealthCheck({ id: user.store.id }));
  }

  setItems(items) {
    this.setState({ healthCheckItem: items });
  }

  setMemberInfo(member) {
    const { firstName, lastName } = member;
    let name = null;
    if (firstName && !lastName) {
      name = firstName;
    }
    if (!firstName && lastName) {
      name = lastName;
    }
    if (firstName && lastName) {
      name = `${lastName} ${firstName}`;
    }
    this.setState({ memberName: '存在しない会員番号です' });

    if (name) {
      this.setState({ memberName: name });
    }
    const { agreeCheck } = this.state;

    if (agreeCheck && name) {
      this.setState({ ctrlBtn: false });
    }
  }

  handleRadioChange = (event) => {
    const { agreeCheck, memberName, info } = this.state;
    let ctrlBtn = true;

    if (agreeCheck && (
      (event.target.value === 'member' && memberName && memberName !== '存在しない会員番号です')
        || (event.target.value !== 'member' && info.otherName)
    )) {
      ctrlBtn = false;
    }
    this.setState({ radioValue: event.target.value, ctrlBtn });
  }

  handleCheckChange = (event) => {
    const { memberName, radioValue, info } = this.state;
    let ctrlBtn = true;

    if (event.target.checked && (
      (radioValue === 'member' && memberName && memberName !== '存在しない会員番号です')
        || (radioValue !== 'member' && info.otherName)
    )) {
      ctrlBtn = false;
    }
    this.setState({ agreeCheck: event.target.checked, ctrlBtn });
  }

  onIdChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { info, message } = this.state;

    this.setState({
      info: { ...info, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
      memberName: '存在しない会員番号です',
      ctrlBtn: true,
    });

    if (value.length === 10) {
      this.props.dispatch(actCallApiMemberInfo({ memberNo: value }));
    }
  }

  onNameChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { info, message, agreeCheck } = this.state;
    let ctrlBtn = true;

    if (value && agreeCheck) {
      ctrlBtn = false;
    }
    this.setState({
      info: { ...info, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
      ctrlBtn,
    });
  }

  handleSave = () => {
    const { info, storeId, radioValue } = this.state;
    this.props.dispatch(actInsertHealthCheckResult({
      memberNo: info.memberId, name: info.otherName, storeId, resType: radioValue,
    }));
    this.setState({ resultPageFlg: true });
    this.intervalId = setInterval(() => {
      this.moveHealthCheck();
    }, 10000);
  }

  moveCursor = () => {
    document.getElementById('input-id').focus();
  }

  moveHome = () => {
    window.location.href = urls.OTHER.HOME;
  }

  moveHealthCheck = () => {
    window.location.reload();
  }

  render() {
    const { classes } = this.props;

    const {
      radioValue, info, message, healthCheckItem, agreeCheck,
      memberName, ctrlBtn, resultPageFlg, healthCheckInfo,
    } = this.state;

    return (
      <div className={classes.root}>
        <Box>
          <Container>
            <img src={elcLogo} alt="ロゴ" className={classes.elcLogo} />
            <Box display={resultPageFlg ? 'none' : ''}>
              <Box fontWeight="fontWeightBold" fontSize={25} my={3}>ヘルスチェック</Box>
              <Card variant="outlined">
                <Box p={3}>
                  <Box fontWeight="fontWeightBold" fontSize={18}>確認事項</Box>
                  <Box my={1}>{healthCheckInfo.content1}</Box>
                  <Box>{healthCheckInfo.content2}</Box>
                </Box>
              </Card>

              <Box my={3}>
                <Box fontWeight="fontWeightBold" fontSize={18} mt={1}>確認項目</Box>
                {healthCheckItem.map((checkItem, i) => (
                  <Box mt={2} key={checkItem.id}>
                    {`${i + 1}. `}
                    {checkItem.content}
                  </Box>
                ))}
              </Box>
              <hr />

              <Box mt={3} align="right">
                {format(new Date(), 'yyyy年MM年dd日')}
              </Box>

              <Box mt={1}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={agreeCheck} onChange={this.handleCheckChange} />}
                  label="上記事項を私の自由意思により同意し、間違いはございません。"
                />
              </Box>

              <Box my={2}>
                <RadioGroup row aria-label="position" name="position" defaultValue="member" onChange={this.handleRadioChange}>
                  <FormControlLabel value="member" control={<Radio color="primary" />} label="会員" />
                  <FormControlLabel value="teacher" control={<Radio color="primary" />} label="先生" />
                  <FormControlLabel value="other" control={<Radio color="primary" />} label="その他" />
                </RadioGroup>
              </Box>

              {radioValue === 'member' ? (
                <Box>
                  <Box fontWeight="fontWeightBold" fontSize={17}>会員番号</Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        id="input-id"
                        name="memberId"
                        variant="outlined"
                        fullWidth
                        onChange={this.onIdChange}
                        value={info.memberId}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                      {message.memberId
                      && (<div><FormErrorText>{message.memberId}</FormErrorText></div>)}
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant="contained" onClick={this.moveCursor}>QRコードリーダーと接続</Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Box fontWeight="fontWeightBold" fontSize={17}>{memberName ? ('名前') : ('')}</Box>
                      <Box>
                        {memberName}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Box fontWeight="fontWeightBold" fontSize={17}>名前</Box>
                  <TextField
                    id="input-name"
                    name="otherName"
                    variant="outlined"
                    fullWidth
                    onChange={this.onNameChange}
                    value={info.otherName}
                    inputProps={{
                      maxLength: 80,
                    }}
                    autoComplete="off"
                  />
                  {message.otherName && (<FormErrorText>{message.otherName}</FormErrorText>)}
                </Box>
              )}

              <Box textAlign="center" my={5}>
                <Button size="large" variant="contained" color="primary" disabled={ctrlBtn} onClick={this.handleSave}>同意する</Button>
              </Box>
            </Box>

            <Box mt={4} display={resultPageFlg ? '' : 'none'}>
              <Box textAlign="center">
                {memberName && memberName !== '存在しない会員番号です' ? (
                  <Typography variant="h3" gutterBottom>
                    {info.memberId}
                    &nbsp;
                    {memberName}
                    &nbsp;様
                  </Typography>
                ) : (
                  <Typography variant="h3" gutterBottom className={classes.completeTitle}>
                    {info.otherName}
                    &nbsp;様
                  </Typography>
                )}
              </Box>
              <Box textAlign="center"><h1>ヘルスチェックが完了しました。</h1></Box>
              <Box textAlign="center"><h1>ご協力ありがとうございます。</h1></Box>
              <Box className={classes.resultNoticeSub} textAlign="center"><h2>この画面のままスタッフにお声掛けください。</h2></Box>
              <Box textAlign="center" mt={5}>
                <Button variant="outlined" onClick={this.moveHome}>TOP画面に戻る</Button>
                <Button variant="outlined" onClick={this.moveHealthCheck} className={classes.completeButton}>ヘルスチェック画面に戻る</Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <div className={classes.footer}>
          <Box className={classes.homeBtn} onClick={this.moveHome} mt={5}>
            TOPに戻る
          </Box>
          <Box fontWeight="fontWeightBold" p={1} className={classes.footerCopyright}>Copyright© VERTEX All Rights Reserved.</Box>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common, healthChecks: state.healthChecks,
});

export default withStyles(styles)(connect(mapStateToProps)(HealthCheck));
