import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Checkbox, Card, CardContent, Grid, Button, withStyles, Box, FormControlLabel,
  Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Divider,
} from '@material-ui/core';

import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import {
  actCallApiInitScreens, actCallApiGetRole, actCallApiUpdateRoleScreen, actResetRoleDetailFlg,
} from '../../../redux/userSetting/roleDetail/action';
import RoleDetailDialog from '../roleDetailDialog/RoleDetailDialog';
import urls from '../../../constants/urls';

const styles = () => ({
  container: {
    maxHeight: '70vh',
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
});

class RoleDetail extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.USER_SETTING.ROLES,
        name: '権限',
      },
      {
        name: '権限詳細',
      },
    ];

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.roleDetail.isCreatedSuccessMessage) {
      successSnackOpen = true;
      successSnackMessage = '権限の登録が完了しました';
      props.dispatch(actResetRoleDetailFlg());
    }

    const roleId = this.props.location.state.roleId || null;

    this.state = {
      roleId,
      isMain: this.props.common.user.main,
      screens: [],
      roleScreenIds: [],
      updateModalOpen: false,
      roleDetailInfo: {
        name: '',
        mstStore: {
          name: '',
        },
      },
      successSnackOpen,
      successSnackMessage,
      btnLoading: false,
    };

    if (!props.location.state) {
      props.history.push({
        pathname: urls.USER_SETTING.ROLES,
      });
    } else {
      props.dispatch(actCallApiGetRole({ id: roleId }));
      props.dispatch(actCallApiInitScreens());
    }
  }

  componentDidMount() {
    document.title = '権限詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.roleDetail.screens !== this.props.roleDetail.screens) {
      if (this.props.roleDetail.screens) {
        this.setScreens(this.props.roleDetail.screens);
      }
    }
    if (prevProps.roleDetail.data !== this.props.roleDetail.data) {
      if (this.props.roleDetail.data) {
        this.setData(this.props.roleDetail.data);
      }
    }
    if (prevProps.roleDetail.isScreenUpdateSuccess
      !== this.props.roleDetail.isScreenUpdateSuccess) {
      if (this.props.roleDetail.isScreenUpdateSuccess) {
        this.setUpdateSuccess('権限画面');
      }
    }
    if (prevProps.roleDetail.isUpdateSuccess
      !== this.props.roleDetail.isUpdateSuccess) {
      if (this.props.roleDetail.isUpdateSuccess) {
        this.setUpdateSuccess('権限');
      }
    }
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  setScreens(screens) {
    this.setState({ screens });
  }

  setData = (data) => {
    this.setState({
      roleDetailInfo: {
        name: data.name,
        mstStore: data.mstStore,
      },
      roleScreenIds: data.roleScreens,
    });
  }

  updateRoleScreen = () => {
    const { roleId, roleScreenIds } = this.state;
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actResetRoleDetailFlg());
    this.props.dispatch(actCallApiUpdateRoleScreen({ roleId, roleScreenIds }));
  }

  onUpdate = () => {
    this.setState({ updateModalOpen: true, successSnackOpen: false });
  }

  handleSelectAllClick = () => {
    const { screens, roleScreenIds } = this.state;
    let selectedData = [];
    if (roleScreenIds.length !== screens.length) {
      const newSelecteds = screens.map((scr) => scr.id);
      selectedData = newSelecteds;
    }

    this.setState({ roleScreenIds: selectedData });
  };

  handleClick = (_, screenId) => {
    const { roleScreenIds } = this.state;
    const selectedIndex = roleScreenIds.indexOf(screenId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(roleScreenIds, screenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(roleScreenIds.slice(1));
    } else if (selectedIndex === roleScreenIds.length - 1) {
      newSelected = newSelected.concat(roleScreenIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        roleScreenIds.slice(0, selectedIndex),
        roleScreenIds.slice(selectedIndex + 1),
      );
    }

    this.setState({ roleScreenIds: newSelected });
  };

  handleDetailClose = () => {
    this.setState({ updateModalOpen: false });
  }

  setUpdateSuccess = (name) => {
    const { roleId } = this.state;
    this.setState({ successSnackOpen: true, successSnackMessage: `${name}の変更が完了しました`, updateModalOpen: false });
    this.props.dispatch(actCallApiGetRole({ id: roleId }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  isSelected = (screenId) => this.state.roleScreenIds.includes(screenId);

  render() {
    const { classes } = this.props;

    const {
      isMain, roleId,
      screens, updateModalOpen, roleDetailInfo,
      successSnackOpen, successSnackMessage, roleScreenIds, btnLoading,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Box display={isMain ? '' : 'none'}>
                    <FormTitle title="店舗" />
                    <Box>{roleDetailInfo.mstStore.name}</Box>
                  </Box>
                  <Box mt={2}>
                    <FormTitle title="権限名" />
                    <Box>{roleDetailInfo.name}</Box>
                  </Box>
                </CardContent>
                <Divider />
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" onClick={this.onUpdate}>権限名の変更</Button>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Paper>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={
                            screens.length === roleScreenIds.length ? classes.highlight : null
                          }
                        >
                          <span>
                            <Button onClick={this.handleSelectAllClick}>
                              {roleScreenIds.length === screens.length ? ('全解除') : ('全選択')}
                            </Button>
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {screens.map((screen) => {
                        const isSelected = this.isSelected(screen.id);
                        return (
                          <TableRow
                            key={`box-check-${screen.id}`}
                            hover
                            aria-checked={isSelected}
                            selected={isSelected}
                          >
                            <TableCell>
                              <Box>
                                <FormControlLabel
                                  control={<Checkbox checked={isSelected} color="primary" onClick={(event) => this.handleClick(event, screen.id)} />}
                                  label={screen.name}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box textAlign="right" p={1}>
                  <LoadingButton loading={btnLoading} onClick={this.updateRoleScreen} label="保存" />
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />
        </MainBody>

        <RoleDetailDialog
          open={updateModalOpen}
          handleClose={this.handleDetailClose}
          roleName={roleDetailInfo.name}
          mstStore={roleDetailInfo.mstStore}
          roleId={roleId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roleDetail: state.roleDetail,
  roles: state.roles,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RoleDetail)));
