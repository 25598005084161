// region Type
export const ACTION_TYPE = {
  CALL_API_HOLIDAY_GET_STORE: 'CALL_API_HOLIDAY_GET_STORE',
  CALL_API_HOLIDAY_GET_STORE_SUCCESS: 'CALL_API_HOLIDAY_GET_STORE_SUCCESS',
  CALL_API_HOLIDAY_LIST: 'CALL_API_HOLIDAY_LIST',
  HOLIDAY_LIST_SUCCESS: 'HOLIDAY_LIST_SUCCESS',
  CALL_API_HOLIDAY_CREATE: 'CALL_API_HOLIDAY_CREATE',
  HOLIDAY_CREATE_SUCCESS: 'HOLIDAY_CREATE_SUCCESS',
  CALL_API_HOLIDAY_DELETE: 'CALL_API_HOLIDAY_DELETE',
  HOLIDAY_DELETE_SUCCESS: 'HOLIDAY_DELETE_SUCCESS',
  HOLIDAY_FLG_RESET: 'HOLIDAY_FLG_RESET',
};
// endregion

// region action
export const actCallApiHolidayList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_HOLIDAY_LIST,
  searchCondition,
});

export const actHolidayListSuccess = (datas) => ({
  type: ACTION_TYPE.HOLIDAY_LIST_SUCCESS,
  datas,
});

export const actCallHolidayGetStore = () => ({
  type: ACTION_TYPE.CALL_API_HOLIDAY_GET_STORE,
});
export const actCallHolidayGetStoreSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_HOLIDAY_GET_STORE_SUCCESS,
  datas,
});

export const actCallApiMainHolidayCreate = (data) => ({
  type: ACTION_TYPE.CALL_API_HOLIDAY_CREATE,
  data,
});

export const actHolidayCreateSuccess = (datas) => ({
  type: ACTION_TYPE.HOLIDAY_CREATE_SUCCESS,
  datas,
});

export const actCallApiHolidayDelete = (deleteList) => ({
  type: ACTION_TYPE.CALL_API_HOLIDAY_DELETE,
  deleteList,
});

export const actHolidayDeleteSuccess = () => ({
  type: ACTION_TYPE.HOLIDAY_DELETE_SUCCESS,
});

export const actHolidayFlgReset = () => ({
  type: ACTION_TYPE.HOLIDAY_FLG_RESET,
});
// endregion
