import React from 'react';
import {
  Box, Paper, Tabs, Tab,
} from '@material-ui/core';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import BaseGolfRule from './BaseGolfRule';
import BoxTimeRule from './BoxTimeRule';
import TargetTimeRule from './TargetTimeRule';

class GolfRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 2,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 1) {
        this.setTab(0);
      }
      if (prevProps.value === 1 && this.props.value !== 1) {
        this.setTab(2);
      }
    }
  }

  setTab = (tabValue) => {
    this.setState({ tabValue });
  }

  handleChange = (_, tabValue) => {
    this.setState({ tabValue });
  }

  render() {
    const {
      value, data, onSave, onTimeSave,
    } = this.props;

    const { tabValue } = this.state;

    return (
      <TabPanel value={value} index={1}>
        <Box display="flex" justifyContent="center">
          <Paper square>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
            >
              <Tab label="共通" />
              <Tab label="打席時間" />
              <Tab label="的打ち時間" />
            </Tabs>
          </Paper>
        </Box>
        <BaseGolfRule
          value={tabValue}
          golfDatas={data}
          onSave={onSave}
        />
        <BoxTimeRule
          value={tabValue}
          golfDatas={data}
          onSave={onTimeSave}
        />
        <TargetTimeRule
          value={tabValue}
          golfDatas={data}
          onSave={onTimeSave}
        />
      </TabPanel>
    );
  }
}

export default GolfRules;
