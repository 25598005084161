import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 全件表示
 */
export default function getData(storeObject) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.OTHER.HOME.HOME, { storeId: storeObject.storeId })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
