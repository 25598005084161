import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Box, Paper, Link,
} from '@material-ui/core';
import format from 'date-fns/format';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Yearpicker from '../../../components/atoms/yearpicker/Yearpicker';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import Validation from './validation';
import {
  actCallApiInitSchoolCalendarList,
  actCallApiSchoolCalendarListSearch,
  actSetSearchCondition,
} from '../../../redux/school/schoolCalenderList/action';
import SchoolCalendarListTable from './schoolCalenderListTable/SchoolCalenderListTable';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const styles = (theme) => ({
  paperSearch: {
    width: '100%',
    padding: theme.spacing(2),
  },
  paperTabel: {
    width: '100%',
    marginTop: theme.spacing(6),
    padding: '40px',
    textAlign: 'center',
  },
  searchButton: {
    position: 'relative',
    top: '51px',
  },
  tableCell: {
    fontSize: '1rem',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  headerRow: {
    backgroundColor: '#F5F5F5',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
});

class SchoolCalenderList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallApiInitSchoolCalendarList());

    this.breadcrumbsText = [
      { name: '定期予約カレンダー作成' },
    ];

    let isMain = false;
    let searchStore = null;
    let searchContents = null;
    let searchDate = format(new Date(), 'yyyy');

    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    if (props.schoolCalendarList.searchCondition) {
      searchStore = props.schoolCalendarList.searchCondition.searchStore;
      searchContents = props.schoolCalendarList.searchCondition.searchContents;
      searchDate = props.schoolCalendarList.searchCondition.searchDate;
    }

    this.state = {
      isMain,
      stores: [],
      contents: [],
      storeContentLinks: [],
      searchStore,
      searchContents,
      searchDate,
      loading: false,
      datas: [],
      linkedContents: '',
      dateMessage: '',
      contentsMessage: '',
    };
  }

  componentDidMount() {
    document.title = '定期予約カレンダー作成';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.initSearch(this.props.common.user.store);
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setLoading(this.props.common.isLoading);
    }
    if (
      prevProps.schoolCalendarList.searchSelects !== this.props.schoolCalendarList.searchSelects
    ) {
      if (this.props.schoolCalendarList.searchSelects) {
        this.setSelects(this.props.schoolCalendarList.searchSelects);
      }
    }
    if (prevProps.schoolCalendarList.datas !== this.props.schoolCalendarList.datas) {
      this.setDatas(this.props.schoolCalendarList.datas);
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setLoading(loading) {
    this.setState({ loading });
  }

  setSelects(selects) {
    const { searchStore, searchContents } = this.state;
    const linkedContents = this.mapStoreContentLinks(searchStore, selects.storeContentLinks);
    const selectsContents = selects.contents.filter(
      (contentFilter) => selects.contents && linkedContents.includes(contentFilter.id),
    );
    let contents = selectsContents.length !== 0 ? selectsContents[0] : null;
    if (searchContents) { contents = searchContents; }
    this.setState({
      stores: selects.stores,
      contents: selects.contents,
      storeContentLinks: selects.storeContentLinks,
      linkedContents,
      searchContents: contents,
    });
    if (contents) {
      this.search(searchStore, contents, format(new Date(), 'yyyy'));
    }
  }

  setSearchCondition = () => {
    const { searchStore, searchContents, searchDate } = this.state;
    this.props.dispatch(actSetSearchCondition({
      searchStore, searchContents, searchDate,
    }));
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    this.setState({ searchStore: value });
    if (!value) {
      return;
    }
    const { searchContents, storeContentLinks } = this.state;

    const linkedContents = this.mapStoreContentLinks(value, storeContentLinks);

    if (searchContents && !linkedContents.includes(searchContents.id)) {
      this.setState({ searchContents: null });
    }
    this.setState({ linkedContents });
  }

  onContentChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchContents: value,
      contentsMessage: Validation.formValidate('contents', value),
    });
  }

  onDateChange = (_, values) => {
    this.setState({
      searchDate: values,
      dateMessage: Validation.formValidate('year', values),
    });
  }

  onSearch = () => {
    const {
      searchStore, searchContents, searchDate, dateMessage,
    } = this.state;
    const contentsMessage = Validation.formValidate('contents', searchContents);
    this.setState({ contentsMessage });
    if (dateMessage || contentsMessage) return;
    this.search(searchStore, searchContents, searchDate);
  }

  mapStoreContentLinks = ((values, storeContentLinks) => storeContentLinks
    .filter((link) => link.mstStoreId === values.id)
    .map((link) => link.mstContentId)
  );

  search = (searchStore, searchContents, searchDate) => {
    const storeId = searchStore && searchStore.id;
    const contentsId = searchContents && searchContents.id;
    this.setState({
      modalContents: searchContents,
    });
    this.setSearchCondition();
    this.props.dispatch(actCallApiSchoolCalendarListSearch(
      {
        searchStoreId: storeId,
        searchContentId: contentsId,
        searchDate,
      },
    ));
  }

  initSearch(searchStore) {
    this.setState({ searchStore });
  }

  render() {
    const { classes } = this.props;
    const {
      isMain, stores, contents, dateMessage,
      searchStore, searchContents, searchDate, linkedContents, datas,
      contentsMessage, modalContents, loading,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex" mt={1}>
              <Box display={isMain ? '' : 'none'} width="20%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" disableClearable />
              </Box>
              <Box width="20%" mr={2}>
                <FormTitle title="コンテンツ" />
                <SearchSelectBox
                  options={(searchStore) ? (
                    contents.filter((contentFilter) => contents
                    && linkedContents.includes(contentFilter.id))
                  ) : (contents)}
                  value={searchContents}
                  onChange={this.onContentChange}
                  name="searchContents"
                  disableClearable
                  error={contentsMessage !== ''}
                />
                <FormErrorText>{contentsMessage}</FormErrorText>
              </Box>
              <Box>
                <FormTitle title="表示年" />
                <Yearpicker
                  handleChange={this.onDateChange}
                  value={searchDate}
                  error={dateMessage !== ''}
                />
                <FormErrorText>{dateMessage}</FormErrorText>
              </Box>
            </Box>
          </SearchBox>
          {
            datas.length > 0 && (
              <SchoolCalendarListTable
                datas={datas}
                onSearch={this.onSearch}
                isMain={isMain}
                modalContents={modalContents}
              />
            )
          }
          {
            !loading && modalContents && datas.length === 0 && (
              <Paper className={classes.paperTabel}>
                <Box fontWeight="fontWeightBold" m={1} fontSize={17}>ルールが適用されていません。</Box>
                <Box m={1} fontWeight="fontWeightBold" fontSize={17}>
                  <Link href="/rules">こちら</Link>
                  からルールを適用してください。
                </Box>
              </Paper>
            )
          }
        </MainBody>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolCalendarList: state.schoolCalendarList,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(SchoolCalenderList));
