import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, Button, withStyles,
  Toolbar, Typography, IconButton,
  Tooltip, Checkbox, TableSortLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '../../../components/atoms/pagination/Pagination';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import { actCallTeacherContactTaskContacted, actResetTeacherContactSuccessFlg } from '../../../redux/info/teacherContactTaskList/action';
import { getComparator, stableSort, getSelected } from '../../../helpers/tableSort.helper';
import urls from '../../../constants/urls';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  funcBtn: {
    width: '100px',
    'white-space': 'nowrap',
  },
});

class TeacherContactTaskListDataTable extends React.Component {
  constructor() {
    super();

    this.state = {
      selected: [],
      selectedContactedOpen: false,
      loading: false,
      order: 'asc',
      orderBy: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      if (this.props.rows) {
        this.handleHeaderClose();
      }
    }
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      selectedData = rows.filter((v) => !v.isContacted).map((n) => n.id);
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
  };

  onPreContacted = () => {
    this.setState({ selectedContactedOpen: true, loading: false });
  }

  handleClose = () => {
    this.setState({ selectedContactedOpen: false });
  }

  onContacted = () => {
    const { selected } = this.state;
    const { rows } = this.props;
    this.setState({ selectedContactedOpen: false, selected: [], loading: true });

    const selectedIds = rows
      .filter((row) => selected.indexOf(row.id) !== -1)
      .map((n) => n.id);

    this.props.dispatch(
      actResetTeacherContactSuccessFlg(),
    );

    this.props.dispatch(
      actCallTeacherContactTaskContacted({
        ids: selectedIds,
      }),
    );
  }

  getTableHeadCell(headCell) {
    const { isMain, classes } = this.props;
    const { order, orderBy } = this.state;
    if (headCell.id === 'storeName' && !isMain) {
      return null;
    }
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? 'right' : 'left'}
        padding="default"
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={this.createSortHandler(headCell.id)}
          className={classes.tableLabel}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  onDetail = () => {
    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_LIST,
    });
  }

  render() {
    const {
      classes,
      rows,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      headCells,
    } = this.props;

    const {
      selected,
      selectedContactedOpen,
      loading,
      order,
      orderBy,
    } = this.state;

    return (
      <>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : ''}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 && (
              <Tooltip title="連絡済みにする">
                <Button color="primary" onClick={this.onPreContacted}>連絡済みにする</Button>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && selected.length < rows.filter((v) => !v.isContacted).length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((v) => !v.isContacted).length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length === 0
                        || rows.filter((v) => v.isContacted).length === rows.length
                      }
                    />
                  </TableCell>
                  {headCells.map((headCell) => this.getTableHeadCell(headCell))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = this.isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {
                          !row.isContacted && (
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              color="primary"
                              onClick={(event) => this.handleClick(event, row.id)}
                            />
                          )
                        }
                      </TableCell>
                      <TableCell>
                        {row.targetDate}
                      </TableCell>
                      <TableCell>
                        {row.menuName}
                      </TableCell>
                      <TableCell>
                        {row.teacherName}
                      </TableCell>
                      <TableCell>
                        {row.teacherPhone}
                      </TableCell>
                      <TableCell>
                        {row.teacherEmail}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={headCells.length + 1}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <ConfirmDialog
            open={selectedContactedOpen}
            handleClose={this.handleClose}
            executeText="連絡済みにする"
            handleExecute={this.onContacted}
            title="選択した先生連絡タスクの連絡済み"
            contentText="選択したスケジュールを連絡済みにします。"
            loading={loading}
            buttonColor="primary"
          />
        </Paper>
      </>
    );
  }
}

TeacherContactTaskListDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

TeacherContactTaskListDataTable.defaultProps = {
  totalElements: 0,
};

const mapStateToProps = (state) => ({
  bookingTable: state.booking,
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(TeacherContactTaskListDataTable)),
);
