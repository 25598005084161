// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_TEACHER_LIST: 'CALL_API_INIT_TEACHER_LIST',
  CALL_API_INIT_TEACHER_LIST_SUCCESS: 'CALL_API_INIT_TEACHER_LIST_SUCCESS',
  CALL_API_GET_TEACHER_LIST: 'CALL_API_GET_TEACHER_LIST',
  CALL_API_GET_TEACHER_LIST_SUCCESS: 'CALL_API_GET_TEACHER_LIST_SUCCESS',
  CALL_API_UPDATE_TEACHER_PASSWORD: 'CALL_API_UPDATE_TEACHER_PASSWORD',
  CALL_API_UPDATE_TEACHER_PASSWORD_SUCCESS: 'CALL_API_UPDATE_TEACHER_PASSWORD_SUCCESS',
  RESET_TEACHER_LIST_FLG: 'RESET_TEACHER_LIST_FLG',
};
// #endregion

// #region Type
export const actCallApiInitTeacherList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_TEACHER_LIST,
});

export const actCallApiInitTeacherListSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_INIT_TEACHER_LIST_SUCCESS,
  stores,
});

export const actCallApiGetTeacherList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_TEACHER_LIST,
  searchCondition,
});

export const actCallApiGetTeacherListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_TEACHER_LIST_SUCCESS,
  datas,
});

export const actCallApiUpdateTeacherPassword = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_TEACHER_PASSWORD,
  param,
});

export const actCallApiUpdateTeacherPasswordSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_TEACHER_PASSWORD_SUCCESS,
  data,
});

export const actResetTeacherListFlg = () => ({
  type: ACTION_TYPE.RESET_TEACHER_LIST_FLG,
});
// #endregion
