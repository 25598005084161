import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSelects,
  getDatas,
  updateStatus,
} from '../../../services/booking/bookingList.service';
import {
  ACTION_TYPE,
  actInitBookingSearchListSuccess,
  actCallApiBookingListSearchSuccess,
  actCallApiBookingUpdateStatusSuccess,
  actCallApiBookingSumUpdateStatusSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 */
function* callApiInitBookingSearchList() {
  try {
    const selects = yield call(getSelects);
    yield put(actInitBookingSearchListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠データ取得
 */
function* callApiBookingListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiBookingListSearchSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約ステータス更新
 */
function* callApiBookingUpdateStatus({ params }) {
  try {
    yield call(updateStatus, { ids: params.ids, status: params.status });
    if (params.summarizeUpdFlg) {
      yield put(actCallApiBookingSumUpdateStatusSuccess());
    } else {
      yield put(actCallApiBookingUpdateStatusSuccess());
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBookingList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_BOOKING_SEARCH_LIST, callApiInitBookingSearchList);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_LIST_SEARCH, callApiBookingListSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_UPDATE_STATUS, callApiBookingUpdateStatus);
}
