import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getData, checkMail,
  createSystemUser, updateSystemUser,
} from '../../../services/userSetting/sysytemUserDetail.service';
import {
  ACTION_TYPE,
  actCallGetSystemUserSuccess,
  actCallApiSystemUserCreateSuccess,
  actCallApiSystemUserUpdateSuccess,
  actCallApiSystemUserMailCheckSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 作成処理
 *
 * @param userInfo
 */
function* callApiSystemUserCreate({ info }) {
  try {
    const data = yield call(createSystemUser, info);
    yield put(actCallApiSystemUserCreateSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 *
 * @param userInfo
 */
function* callApiSystemUserUpdate({ info }) {
  try {
    const datas = yield call(updateSystemUser, info);
    yield put(actCallApiSystemUserUpdateSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一件取得処理
 *
 */
function* callApiGetSystemUser({ param }) {
  try {
    const data = yield call(getData, param);
    yield put(actCallGetSystemUserSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メール存在チェック
 *
 */
function* callApiSystemUserMailCheck({ param }) {
  try {
    const data = yield call(checkMail, param);
    yield put(actCallApiSystemUserMailCheckSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSystemUserDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE, callApiSystemUserCreate);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE, callApiSystemUserUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SYSTEM_USER, callApiGetSystemUser);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_MAIL_CHECK, callApiSystemUserMailCheck);
}
