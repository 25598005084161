import React from 'react';
import {
  DialogActions, DialogContent, Button, Dialog,
} from '@material-ui/core';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import urls from '../../../constants/urls';

export default function LogoutModal(props) {
  const logout = () => {
    window.location.href = `${urls.OTHER.LOGIN}`;
  };

  const {
    open,
    content,
  } = props;

  return (
    <Dialog open={open} onClose={logout}>
      <DialogTitle>ログアウトします</DialogTitle>
      <DialogContent dividers>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={logout}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}
