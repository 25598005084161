import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from '@material-ui/core';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

export default function RoomSchedulDuplicateDialog(props) {
  const {
    open,
    handleClose,
    title,
    contentData,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
    >
      <DialogTitle error>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Box mb={2}>
            以下のRoomが重複しています
          </Box>
          {
            contentData.map((room) => (
              <Box>
                {room.roomName}
              </Box>
            ))
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}

RoomSchedulDuplicateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  contentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired).isRequired,
};
