import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box, TextField } from '@material-ui/core';

import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import TeacherListTable from './TeacherListTable';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiInitTeacherList, actCallApiGetTeacherList,
} from '../../../redux/master/teacherList/action';

class TeacherList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallApiInitTeacherList());

    this.breadcrumbsText = [
      { name: '先生パスワード変更' },
    ];

    let searchStore = null;
    let isMain = false;
    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
      this.props.dispatch(
        actCallApiGetTeacherList({
          page: 0,
          records: 10,
          searchStoreId: props.common.user.store.id,
        }),
      );
    }

    this.state = {
      isMain,
      stores: [],
      datas: {},
      searchCondition: {
        searchStore,
        searchTeacherNo: '',
        searchTeacherName: '',
        page: 0,
        records: 10,
      },
    };
  }

  componentDidMount() {
    document.title = '先生パスワード変更';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.teachers.datas !== this.props.teachers.datas) {
      if (this.props.teachers.datas) {
        this.setDatas(this.props.teachers.datas);
      }
    }
    if (prevProps.teachers.stores !== this.props.teachers.stores) {
      if (this.props.teachers.stores) {
        this.setStores(this.props.teachers.stores);
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setUser = (user) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStore: user.store,
    };

    this.setState({
      searchCondition: tempCondition,
      isMain: user.main,
    });

    this.search(tempCondition);
  }

  onChange = (event) => {
    const { searchCondition } = this.state;
    const { name, value } = event.target;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiGetTeacherList({
        ...searchCondition,
        searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      }),
    );
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      stores,
      datas,
      isMain,
      searchCondition,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} width="30%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchCondition.searchStore} onChange={this.onChange} name="searchStore" />
              </Box>
              <Box width="30%">
                <FormTitle title="先生番号" />
                <TextField name="searchTeacherNo" variant="outlined" fullWidth value={searchCondition.searchTeacherNo} onChange={this.onChange} />
              </Box>
            </Box>

            <Box display="flex" mt={2}>
              <Box width="30%" mr={2}>
                <FormTitle title="名前" />
                <TextField name="searchTeacherName" variant="outlined" fullWidth value={searchCondition.searchTeacherName} onChange={this.onChange} />
              </Box>
            </Box>
          </SearchBox>

          <TeacherListTable
            rows={datas.content ? datas.content : []}
            page={searchCondition.page}
            isMain={isMain}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ teachers: state.teachers, common: state.common });

export default withRouter(connect(mapStateToProps)(TeacherList));
