import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  FormControl, Box, RadioGroup,
  FormControlLabel, Radio,
} from '@material-ui/core';
import urls from '../../../constants/urls';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

class MemberAddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberType: '0',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  handleChange = (event) => {
    this.setState({ memberType: event.target.value });
  }

  handleNext = () => {
    const { memberType } = this.state;
    let url = urls.MEMBER.INDIVIDUAL_ENTRY;
    if (memberType === '1') {
      url = urls.MEMBER.CORPORATION_ENTRY;
    }
    this.props.history.push({ pathname: url });
  }

  init() {
    this.setState({ memberType: '0' });
  }

  render() {
    const {
      open, handleClose,
    } = this.props;
    const { memberType } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>会員の追加</DialogTitle>
          <DialogContent dividers>
            <Box fontWeight="fontWeightBold" mb={1}>会員のタイプを選択してください。</Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="gender" name="memberType" value={memberType} onChange={this.handleChange}>
                <FormControlLabel value="0" control={<Radio />} label="個人" />
                <FormControlLabel value="1" control={<Radio />} label="法人" />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <Button onClick={this.handleNext} color="primary">次へ</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(MemberAddDialog);
