import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 選択肢取得
 * @param checkCondition
 * @returns
 */
export function getSelectes(checkCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.CALENDAR_SELECT_LIST, checkCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成の予約枠ヘッダー検索
 *
 * @param {object} id
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成用の曜日別スケジュールリスト取得する
 * @param {*}
 */
export function getWeekSchedules(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_WEEK_SCHEDULES, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成
 * @param {*}
 */
export function createDatas(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系(ゴルフ)カレンダー作成
 * @param {*}
 */
export function createGolfDatas(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_GOLF, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系(Room)カレンダー作成
 * @param {*}
 */
export function createRoomDatas(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_ROOM, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系(Room)カレンダー作成
 * @param {*}
 */
export function createOnlineCultureDatas(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_ONLINE_CULTURE, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成の予約者チェック(ゴルフ以外)
 *
 * @param {object} id
 */
export function reservationCheck(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_RESERVATION_CHECK, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成の予約者チェック(ゴルフ)
 *
 * @param {object} id
 */
export function golfReservationCheck(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_RESERVATION_CHECK_GOLF, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠リセット(ゴルフ以外)
 * @param {*} resetCondition
 */
export function resetDatas(resetCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST, resetCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠リセット(ゴルフ以外)
 * @param {*} resetCondition
 */
export function resetGolfDatas(resetCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_GOLF, resetCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * カルチャー、子供カルチャーチェック
 *
 * @param {object} id
 */
export function cultureCheck(checkCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_CALENDAR.EVENT_CALENDAR_LIST_CULTURE_CHECK, checkCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
