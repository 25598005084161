// #region Type
export const ACTION_TYPE = {
  SET_MEMBER_ENTRY_FORM: 'SET_MEMBER_ENTRY_FORM',
  CALL_API_INIT_SELECT_VALUES: 'CALL_API_INIT_SELECT_VALUES',
  CALL_API_INIT_SELECT_VALUES_SUCCESS: 'CALL_API_INIT_SELECT_VALUES_SUCCESS',
  GET_ZIP_CODE_ADDRESS: 'GET_ZIP_CODE_ADDRESS',
  GET_ZIP_CODE_ADDRESS_SUCCESS: 'GET_ZIP_CODE_ADDRESS_SUCCESS',
  GET_ZIP_CODE_ADDRESS_USER_SUCCESS: 'GET_ZIP_CODE_ADDRESS_USER_SUCCESS',
  RESET_MEMBER_ENTRY_FLG: 'RESET_MEMBER_ENTRY_FLG',
  CALL_API_SAVE_ENTRY: 'CALL_API_SAVE_ENTRY',
  CALL_API_SAVE_ENTRY_SUCCESS: 'CALL_API_SAVE_ENTRY_SUCCESS',
};
// #endregion

// #region action
export const actCallApiInitSelectValues = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SELECT_VALUES,
});

export const actCallApiInitSelectValuesSuccess = (initSelectValues) => ({
  type: ACTION_TYPE.CALL_API_INIT_SELECT_VALUES_SUCCESS,
  initSelectValues,
});

export const actCallSetMemberEntryForm = (memberEntry) => ({
  type: ACTION_TYPE.SET_MEMBER_ENTRY_FORM,
  memberEntry,
});

export const getZipCodeAddress = (contidion) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS,
  contidion,
});

export const getZipCodeAddressSuccess = (zipcode) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS_SUCCESS,
  zipcode,
});

export const getZipCodeAddressUserSuccess = (zipcode) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS_USER_SUCCESS,
  zipcode,
});

export const actCallSaveEntry = (param) => ({
  type: ACTION_TYPE.CALL_API_SAVE_ENTRY,
  param,
});

export const actSaveEntrySuccess = (saveMembers) => ({
  type: ACTION_TYPE.CALL_API_SAVE_ENTRY_SUCCESS,
  saveMembers,
});

export const actResetMemberEntryFlg = () => ({
  type: ACTION_TYPE.RESET_MEMBER_ENTRY_FLG,
});
// #endregion
