import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, createData, updateData, deleteDatas, releaseData,
} from '../../../services/setting/bannerImage.service';
import {
  ACTION_TYPE,
  actBannerImageListSuccess,
  actBannerReleaseSuccess,
  actBannerUploadSuccess,
  actBannerDeleteSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiBannerImageList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actBannerImageListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 広告バナー画像作成
 * @param {*} param0
 */
function* callApiMainBannerCreate({ data }) {
  try {
    const resultData = yield call(createData, data);
    yield put(actBannerUploadSuccess(resultData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メインバナー画像更新
 * @param {*} param0
 */
function* callApiMainBannerUpdate({ id, data }) {
  try {
    const resultData = yield call(updateData, id, data);
    yield put(actBannerUploadSuccess(resultData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiBannerDelete({ deleteList }) {
  try {
    yield call(deleteDatas, deleteList);
    yield put(actBannerDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * バナーリリース
 * @param {*} param
 */
function* callApiBannerRelease({ id }) {
  try {
    const datas = yield call(releaseData, id);
    yield put(actBannerReleaseSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBannerImageList() {
  yield takeLatest(ACTION_TYPE.CALL_API_BANNER_IMAGE_LIST, callApiBannerImageList);
  yield takeLatest(ACTION_TYPE.CALL_API_MAIN_BANNER_CREATE, callApiMainBannerCreate);
  yield takeLatest(ACTION_TYPE.CALL_API_MAIN_BANNER_UPDATE, callApiMainBannerUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_BANNER_DELETE, callApiBannerDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_BANNER_RELEASE, callApiBannerRelease);
}
