import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableSortLabel,
  TableRow, Button, withStyles, Paper,
} from '@material-ui/core';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { getComparator, stableSort } from '../../../helpers/tableSort.helper';
import ChangePassword from './ChangePassword';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class TeacherListTable extends React.Component {
  constructor() {
    super();
    this.headCells = [
      { id: 'teacherNo', numeric: false, label: '先生番号' },
      { id: 'storeName', numeric: false, label: '店舗' },
      { id: 'name', numeric: false, label: '名前' },
    ];
    this.state = {
      order: 'asc',
      orderBy: '',
      selectedRow: {},
      isPasswordModalOpen: false,
      isSuccessSnackOpen: false,
      successMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teachers.isUpdateSuccess !== this.props.teachers.isUpdateSuccess) {
      if (this.props.teachers.isUpdateSuccess) {
        this.changePasswordSuccess(this.props.teachers.updateData);
      }
    }
  }

  changePasswordSuccess = (data) => {
    const name = `${data.lastName} ${data.firstName}`;
    this.setState({ isSuccessSnackOpen: true, successMessage: `${name}のパスワード変更が完了しました`, isPasswordModalOpen: false });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  getTableHeadCell = (headCell) => {
    const { isMain, classes } = this.props;
    const { order, orderBy } = this.state;
    if (headCell.id === 'storeName' && !isMain) {
      return null;
    }
    return (
      <TableCell
        key={headCell.id}
        align="left"
        padding="default"
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={this.createSortHandler(headCell.id)}
          className={classes.tableLabel}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  onPasswordDialogClose = () => {
    this.setState({ isPasswordModalOpen: false });
  }

  onPasswordDialogOpen = (_, selectedRow) => {
    this.setState({ isPasswordModalOpen: true, selectedRow });
  }

  onSuccessSnackbarClose = () => {
    this.setState({ isSuccessSnackOpen: false });
  }

  render() {
    const {
      classes,
      isMain,
      rows,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this.props;
    const {
      order, orderBy, selectedRow, isPasswordModalOpen,
      successMessage, isSuccessSnackOpen,
    } = this.state;

    return (
      <>
        <Paper>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {this.headCells.map((headCell) => this.getTableHeadCell(headCell))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>{row.teacherNo}</TableCell>
                      <TableCell style={{ display: isMain ? '' : 'none' }}>
                        {row.storeName}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={(event) => this.onPasswordDialogOpen(event, row)}>パスワード変更</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={4}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <ChangePassword
          open={isPasswordModalOpen}
          onClose={this.onPasswordDialogClose}
          selectedRow={selectedRow}
        />
        <SuccessSnackbar
          open={isSuccessSnackOpen}
          handleClose={this.onSuccessSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ teachers: state.teachers });

export default withStyles(styles)(connect(mapStateToProps)(TeacherListTable));
