import React from 'react';
import {
  makeStyles, List, ListItem, ListItemText,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import {
  isShowArea,
} from './checkShowScreen';

const useStyles = makeStyles((theme) => ({
  accordionCard: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function NestList(props) {
  const classes = useStyles();
  const {
    screenList, targetList, isOpen, onClick, title, children,
  } = props;

  return (
    <List
      component="nav"
      className={classes.accordionCard}
      style={{ display: isShowArea(screenList, targetList) ? '' : 'none' }}
    >
      <ListItem button onClick={onClick}>
        <ListItemText disableTypography style={{ fontWeight: 'bold' }}>{title}</ListItemText>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      {children}
    </List>
  );
}
