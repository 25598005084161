import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

export function getInitSelectValues() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.ENTRY.ENTRY_SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getZipCodeAddress(ZipCodeRequest) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.COMMON.ZIPCODEADDRESS, { zipCode: ZipCodeRequest.zipCode })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function saveEntry(memberEntry) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MEMBER.MEMBER_LIST.MEMBER_LIST, memberEntry)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
