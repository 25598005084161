import { ACTION_TYPE } from './action';

const attendanceEntry = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_ATTENDANCE_ENTRY_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_ATTENDANCE_ENTRY_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_CREATE_ATTENDANCE_ENTRY_SUCCESS:
      return {
        ...state,
        result: action.result,
        isCreate: true,
      };
    case ACTION_TYPE.RESET_ATTENDANCE_ENTRY_FLG:
      return {
        ...state,
        isCreate: false,
      };
    default:
      return state;
  }
};

export default attendanceEntry;
