import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button,
  withStyles, Grid, Box, Card,
} from '@material-ui/core';
import format from 'date-fns/format';
import { lowerThanDate } from '../../../share/calendarScheduleDetail/share/calendar-schedule-detail-proc';
import {
  actCallApiGetGolfBookingDetail,
} from '../../../../redux/event/eventCalendarDetailGolf/action';
import { actSetHeader } from '../../../../redux/share/calendarScheduleDetail/action';
import CalendarScheduleGolfFrameDialog from './EventCalendarGolfFrameDialog';
import urls from '../../../../constants/urls';
import getWeekName from '../room/room-proc';

const styles = (theme) => ({
  tableContainer: {
    maxHeight: '700px',
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
  },
  headerRow: {
    backgroundColor: '#F5F5F5',
  },
  borderCell: {
    fontSize: '1rem',
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
  },
  bookingBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  highlight: {
    color: '#000',
    backgroundColor: '#E8F0FE',
  },
});

class EventCalendarGolfTable extends React.Component {
  constructor(props) {
    super(props);

    const { selectedDate } = props;

    this.state = {
      bookingDatas: {
        timeList: [],
        golfBookings: [],
      },
      monthly: [],
      targetDate: selectedDate,
      frameModalOpen: false,
      frame: '',
      selectedId: null,
      freeFlg: true,
      selectedMenuId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarDetailGolf.datas !== this.props.calendarDetailGolf.datas) {
      if (this.props.calendarDetailGolf.datas) {
        this.setBookingDatas(this.props.calendarDetailGolf.datas);
      }
    }
    if (prevProps.calendarDetailGolf.calendarList !== this.props.calendarDetailGolf.calendarList) {
      if (this.props.calendarDetailGolf.calendarList) {
        this.setCalendar(this.props.calendarDetailGolf.calendarList);
      }
    }
    if (prevProps.calendarDetailGolf.isFrameUpdated
      !== this.props.calendarDetailGolf.isFrameUpdated) {
      if (this.props.calendarDetailGolf.isFrameUpdated) {
        this.frameUpdateSuccess();
      }
    }
    if (prevProps.calendarScheduleMenu.isSaved !== this.props.calendarScheduleMenu.isSaved) {
      if (this.props.calendarScheduleMenu.isSaved) {
        this.menuUpdateSuccess();
      }
    }
    if (prevProps.calendarDetailGolf.isBulkFrameUpdate
      !== this.props.calendarDetailGolf.isBulkFrameUpdate) {
      if (this.props.calendarDetailGolf.isBulkFrameUpdate) {
        this.regetDetail();
      }
    }
  }

  setCalendar(monthly) {
    this.setState({ monthly });
  }

  setBookingDatas(bookingDatas) {
    this.setState({ bookingDatas });
  }

  onDetail = (_, targetDate) => {
    const { header } = this.props;
    this.setState({ targetDate });
    this.props.dispatch(
      actCallApiGetGolfBookingDetail({ storeId: header.storeId, targetedOn: targetDate }),
    );
  }

  regetDetail = () => {
    const { header } = this.props;
    const { targetDate } = this.state;
    if (!targetDate) return;
    this.props.dispatch(
      actCallApiGetGolfBookingDetail({ storeId: header.storeId, targetedOn: targetDate }),
    );
  }

  handleFrameModalOpen = (_, frame, selectedId, freeFlg, selectedMenuId) => {
    this.props.onCloseMessage();
    this.setState({
      frame,
      frameModalOpen: true,
      selectedId,
      freeFlg,
      selectedMenuId,
    });
  }

  handleFrameModalClose = () => {
    this.setState({ frameModalOpen: false });
  }

  onBookingDetailLinkage = (_, id, targetType) => {
    const { header, isMain } = this.props;
    const { targetDate } = this.state;

    const breadcrumbsObj = [
      {
        url: urls.EVENT.EVENT_CALENDARS,
        name: '予約カレンダー作成',
      },
      {
        url: urls.EVENT.GOLF_CALENDAR_DETAIL,
        name: '予約カレンダー詳細',
      },
      { name: '予約検索詳細' },
    ];
    this.props.dispatch(actSetHeader({
      isMain, header, targetDate,
    }));
    const bookingInfo = {
      bookingId: id,
      contentId: header.contentId,
      regular: false,
      targetType,
    };
    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_DETAIL,
      state: {
        bookingInfo,
        breadcrumbsObj,
      },
    });
  };

  frameUpdateSuccess() {
    const { success, header } = this.props;
    const { targetDate, freeFlg } = this.state;
    this.props.dispatch(actCallApiGetGolfBookingDetail({
      storeId: header.storeId, targetedOn: targetDate,
    }));
    let message = '的打ち';
    if (freeFlg) {
      message = 'シミュレーション';
    }
    success(`${message}の更新が完了しました`);
  }

  menuUpdateSuccess() {
    const { success, header } = this.props;
    const { targetDate } = this.state;
    this.props.dispatch(actCallApiGetGolfBookingDetail({
      storeId: header.storeId, targetedOn: targetDate,
    }));
    success('メニューの保存が完了しました');
  }

  render() {
    const {
      classes,
      handleMenuDialogOpen,
    } = this.props;

    const {
      targetDate, monthly, bookingDatas, frameModalOpen,
      frame, freeFlg, selectedId, selectedMenuId,
    } = this.state;

    const { golfBookings } = bookingDatas;

    const isLower = lowerThanDate(new Date(targetDate), new Date());

    return (
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TableContainer className={classes.tableContainer}>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                stickyHeader
              >
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell align="center">日</TableCell>
                    <TableCell align="center">曜日</TableCell>
                    <TableCell align="center">公開日</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monthly.map((row) => (
                    <TableRow
                      tabIndex={-1}
                      key={row.day}
                      className={row.targetDate === targetDate ? classes.highlight : ''}
                    >
                      <TableCell align="center">{row.day}</TableCell>
                      <TableCell align="center">{getWeekName(row.targetDate)}</TableCell>
                      <TableCell align="center">{row.publishedOn}</TableCell>
                      <TableCell component="th" scope="row" padding="default" align="center">
                        <Button color="primary" onClick={(event) => this.onDetail(event, row.targetDate)}>詳細を見る</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={9}>
            <Card>
              <Box p={2} fontWeight="fontWeightBold" fontSize={23}>{format(new Date(targetDate), 'yyyy年MM月dd日')}</Box>
              <TableContainer className={classes.tableContainer}>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      <TableCell key="time-cell" size="small" className={classes.borderCell} />
                      <TableCell key="day-cell" align="center" padding="default" className={classes.borderCell}>
                        <div>シミュ</div>
                        <div>レーション</div>
                      </TableCell>
                      <TableCell key="week-cell" align="center" padding="default" className={classes.borderCell}>
                        レッスン
                      </TableCell>
                      <TableCell key="booking-cnt-cell" align="center" padding="default" className={classes.borderCell}>
                        的打ち
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {golfBookings.map((row) => (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" id={row.id} scope="row" padding="default" align="center" className={classes.borderCell} size="small">
                          {format(new Date(`${targetDate} ${row.startTime}`), 'HH:mm')}
                        </TableCell>
                        <TableCell component="th" id={row.id} scope="row" padding="default" align="center" className={classes.borderCell}>
                          <Box pb={1}>{row.freeFrame}</Box>
                          <Box p={1} pt={0} display="flex" justifyContent="center">
                            <Button size="small" variant="contained" className={classes.bookingBtn} onClick={(event) => this.onBookingDetailLinkage(event, row.id, 1)}>詳細を見る</Button>
                            <Button style={{ display: isLower ? 'none' : '' }} size="small" variant="contained" color="primary" className={classes.bookingBtn} onClick={(event) => this.handleFrameModalOpen(event, row.freeFrame, row.id, true, row.mstFreeMenuId)}>変更する</Button>
                          </Box>
                        </TableCell>
                        <TableCell component="th" id={row.id} scope="row" padding="default" align="center" className={classes.borderCell}>
                          <Box pb={1}>{row.lessonFrame}</Box>
                          <Box p={1} pt={0} display="flex" justifyContent="center">
                            <Button size="small" variant="contained" className={classes.bookingBtn} onClick={(event) => this.onBookingDetailLinkage(event, row.id, 2)}>詳細を見る</Button>
                            <Button style={{ display: isLower ? 'none' : '' }} size="small" variant="contained" color="primary" className={classes.bookingBtn} onClick={(event) => handleMenuDialogOpen(event, row.id)}>変更する</Button>
                          </Box>
                        </TableCell>
                        <TableCell component="th" id={row.id} scope="row" padding="default" align="center" className={classes.borderCell}>
                          <Box pb={1}>{row.targetFrame}</Box>
                          <Box p={1} pt={0} display="flex" justifyContent="center">
                            <Button size="small" variant="contained" className={classes.bookingBtn} onClick={(event) => this.onBookingDetailLinkage(event, row.id, 3)}>詳細を見る</Button>
                            <Button style={{ display: isLower ? 'none' : '' }} size="small" variant="contained" color="primary" className={classes.bookingBtn} onClick={(event) => this.handleFrameModalOpen(event, row.targetFrame, row.id, false, row.mstTargetMenuId)}>変更する</Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>

        <CalendarScheduleGolfFrameDialog
          open={frameModalOpen}
          onClose={this.handleFrameModalClose}
          selectedId={selectedId}
          frame={frame}
          freeFlg={freeFlg}
          menuId={selectedMenuId}
        />

      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarDetailGolf: state.calendarDetailGolf,
  calendarScheduleMenu: state.calendarScheduleMenu,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventCalendarGolfTable)));
