import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

export default function WeekSelectChoiceDialog(props) {
  const {
    open, handleClose, rows, onSetContract, memberName,
  } = props;

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" disableBackdropClick onEscapeKeyDown={handleClose}>
        <DialogTitle>契約の選択</DialogTitle>
        <DialogContent>
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" mt={1} mb={3}>
            <Box p={1}>
              <Box>
                {`${memberName}には複数の契約が存在します。`}
              </Box>
              <Box mt={1}>検索条件に設定する契約情報を選択してください。</Box>
            </Box>
          </Box>
          <Paper>
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>コンテンツ</TableCell>
                    <TableCell>メニュー</TableCell>
                    <TableCell>表示開始日</TableCell>
                    <TableCell>表示終了日</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>{row.contentName}</TableCell>
                      <TableCell>{row.menuName}</TableCell>
                      <TableCell>{row.startDate}</TableCell>
                      <TableCell>{row.endDate}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={(event) => onSetContract(event, row)}>検索条件に設定する</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
