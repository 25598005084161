import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索表示
 */
export function getDatas(rooms) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.CLASS_INFOMATION.CLASS_INFOMATION, {
      ids: rooms.ids, date: rooms.date, storeId: rooms.storeId,
    })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getSelects(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.CLASS_INFOMATION.SELECTS, { storeId: param })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 出席管理
 */
export function getAttend(users) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.INFO.CLASS_INFOMATION.CLASS_INFOMATION, null, {
      ids: users.ids, date: users.date, memberNo: users.value,
    })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
