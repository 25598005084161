import React from 'react';
import { Box, Paper } from '@material-ui/core';
import SideDisplayGrid from '../../../../../components/organisms/sideDisplayGrid/SideDisplayGrid';

export default function CompleteEntry(props) {
  const { datas } = props;

  return (
    <Box my={3}>
      <Paper variant="outlined">
        <Box p={3}>
          <Box fontWeight="fontWeightBold" textAlign="center" mb={3} fontSize="h6.fontSize">会員登録が完了しました。</Box>

          <Box>
            <SideDisplayGrid title="申込番号" content={datas.contract && datas.contract.requestNo} />
          </Box>

          <Box>
            {datas.members && datas.members.map((member) => (
              <Box key={member.memberNo}>
                <SideDisplayGrid title={`${member.lastName} ${member.firstName}様の会員番号`} content={member.memberNo} />
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
