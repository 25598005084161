import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Drawer, IconButton, Divider, Box, Button, LinearProgress, TextField,
  FormControl, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiGetGolfScheduleDetail, actApiGolfScheduleFlgReset,
  actCallApiUpdateGolfScheduleDetail, actCallApiGolfScheduleDelete,
} from '../../../redux/booking/golfSchedule/action';
import beginner from '../../../public/assets/beginner.svg';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

const styles = (theme) => ({
  drawer: {
    width: 500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
  },
});

class DetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      statuses: [],
      isSuccess: false,
      isCheckDelete: false,
      isDelectCheckOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isClickDrawer !== this.props.isClickDrawer) {
      if (this.props.isClickDrawer) {
        this.init();
      }
    }
    if (prevProps.golfSchedule.data !== this.props.golfSchedule.data) {
      if (this.props.golfSchedule.data) {
        this.setData(this.props.golfSchedule.data);
      }
    }
    if (prevProps.golfSchedule.isUpdateSuccess !== this.props.golfSchedule.isUpdateSuccess) {
      if (this.props.golfSchedule.isUpdateSuccess) {
        this.success('保存が完了しました');
      }
    }
    if (prevProps.golfSchedule.isDeleteSuccess !== this.props.golfSchedule.isDeleteSuccess) {
      if (this.props.golfSchedule.isDeleteSuccess) {
        this.deleteSuccess('削除が完了しました');
      }
    }
  }

  onChange = (event) => {
    const { value, name } = event.target;
    const { data } = this.state;

    this.setState({
      data: { ...data, [name]: value },
    });
  }

  onSave = () => {
    const { data } = this.state;
    if (!data.updateState || !data.updateState.id) {
      return;
    }
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiUpdateGolfScheduleDetail({
      id: data.memberId,
      memberId: data.memberId,
      status: data.updateState.id,
      memo: data.memo,
      paymentType: data.paymentType,
    }));
  }

  onSnackbarClose = () => {
    this.setState({ isSuccess: false });
  }

  setData(data) {
    this.setState({
      data: {
        ...data,
        memo: data.memberMemo || '',
        updateState: { id: data.status, name: data.statusName },
      },
    });
  }

  deleteCheck = () => {
    this.setState({ isDelectCheckOpen: true, isSuccess: false });
  }

  onDeleteClose = () => {
    this.setState({ isDelectCheckOpen: false });
  }

  onDelete = () => {
    const { data } = this.state;
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGolfScheduleDelete({ id: data.memberId }));
  }

  success(successMessage) {
    this.setState({ isSuccess: true, successMessage });
  }

  deleteSuccess(successMessage) {
    this.setState({ isSuccess: true, successMessage, isDelectCheckOpen: false });
    this.props.onClose();
  }

  init() {
    const { row, handleClearDrawer } = this.props;
    handleClearDrawer();
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGetGolfScheduleDetail({
      id: row.memberId,
    }));
    this.setState({
      data: {},
      isSuccess: false,
      statuses: this.props.golfSchedule.select.statuses || [],
    });
  }

  render() {
    const {
      classes, isOpen, onClose, isLoading,
    } = this.props;
    const {
      data, isSuccess, isCheckDelete, isDelectCheckOpen, statuses, successMessage,
    } = this.state;

    return (
      <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Box display="flex" fontSize={17}>
              <Box>{data.bookingName}</Box>
              <Box>
                {data.beginner && (
                  <img src={beginner} alt="初心者" style={{ width: '15px', height: 'auto' }} />
                )}
              </Box>
            </Box>
          </div>
          <Divider />
          {isLoading && (<LinearProgress color="primary" />)}
          <Box>
            <Box p={2} fontSize={17}>
              <Box mb={2}>
                <FormTitle title="実施日" />
                <Box>{data.targetedOn}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="実施時間" />
                <Box>{`${data.startTime}～${data.endTime}`}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="ステータス" />
                <Box>{data.statusName}</Box>
              </Box>
            </Box>
            <Divider />
            <Box p={2} fontSize={17}>
              <Box style={{ width: '50%' }}>
                <SearchSelectBox options={statuses} value={data.updateState} onChange={this.onChange} name="updateState" />
              </Box>
              <Box mb={2}>
                <FormControl component="fieldset">
                  <RadioGroup row value={data.paymentType} onChange={this.onChange} defaultValue={data.paymentType} name="paymentType">
                    <FormControlLabel value="現金" control={<Radio checked={data.paymentType === '現金'} color="primary" />} label="現金" />
                    <FormControlLabel value="チケット" control={<Radio checked={data.paymentType === 'チケット'} color="primary" />} label="チケット" />
                    <FormControlLabel value="" control={<Radio checked={data.paymentType === ''} color="primary" />} label="なし" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormTitle title="メモ" />
                <Box>
                  <TextField
                    id="content"
                    name="memo"
                    multiline
                    fullWidth
                    rows={5}
                    variant="outlined"
                    value={data.memo}
                    onChange={this.onChange}
                    inputProps={{
                      maxLength: 30000,
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Button className={classes.button} color="primary" onClick={this.onSave} variant="contained" size="small">保存</Button>
                <Button className={classes.button} color="secondary" onClick={this.deleteCheck} variant="contained" size="small">削除</Button>
              </Box>
            </Box>

          </Box>
        </Drawer>
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onSnackbarClose}
          message={successMessage}
        />
        <SuccessSnackbar
          open={isCheckDelete}
          handleClose={this.onSnackbarClose}
          message="確認"
        />
        <ConfirmDialog
          open={isDelectCheckOpen}
          handleClose={this.onDeleteClose}
          executeText="削除する"
          handleExecute={this.onDelete}
          title="データ削除"
          contentText={`${data.bookingName}さんのデータを削除します。よろしいですか？`}
          buttonColor="secondary"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  golfSchedule: state.golfSchedule,
  isLoading: state.common.isLoading,
});

export default withStyles(styles)(connect(mapStateToProps)(DetailDrawer));
