import isValid from 'date-fns/isValid';
import { differenceInYears } from 'date-fns';

const errorCheckText = (value) => {
  if (!value || value.length === 0) return '入力してください';
  return '';
};

const errorCheckPhone = (value) => {
  const values = value.split('-');
  const regex = /^[0-9]*$/;
  let error = '';

  values.forEach((item) => {
    if (item && !regex.test(item)) {
      error = '正しい電話番号を入力してください';
    }
    if (!item) {
      error = '入力してください';
    }
  });
  return error;
};

const errorCheckPhoneTwo = (value) => {
  const values = value.split('-');
  const regex = /^[0-9]*$/;
  let error = '';
  const isInput = values.filter((item) => (item !== '')).length > 0;
  values.forEach((item) => {
    if (!regex.test(item)) {
      error = '正しい電話番号を入力してください';
    }
    if (isInput && item === '') {
      error = '入力してください';
    }
  });
  return error;
};

const errorCheckZipCode = (value) => {
  const values = value.split('-');
  const regex = /^[0-9]*$/;
  let error = '';
  values.forEach((item) => {
    if (!regex.test(item)) {
      error = '正しい郵便番号を入力してください';
    }
    if (item === 'undefined' || item === '') {
      error = '入力してください';
    }
  });
  if (error === '' && value.length !== 8) {
    error = '正しい郵便番号を入力してください';
  }
  return error;
};

const errorCheckZipCodeNoRequired = (value) => {
  const values = value.split('-');
  const regex = /^[0-9]*$/;
  let error = '';
  const isInput = values.filter((item) => (item !== '')).length > 0;
  values.forEach((item) => {
    if (!regex.test(item)) {
      error = '正しい郵便番号を入力してください';
    }
    if (isInput && item === '') {
      error = '入力してください';
    }
  });
  return error;
};

const errorCheckMail = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (!regex.test(value)) return '正しい形式で入力してください';
  return '';
};

const errorCheckMailNoRequired = (value) => {
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (value && !regex.test(value)) return '正しい形式で入力してください';
  return '';
};

const errorCheckSelectList = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

const errorCheckPassword = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
  if (!regex.test(value)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
  return '';
};

const errorCheckDate = (value) => {
  const values = value.split('/');
  let isError = false;
  values.forEach((item) => {
    if (item === 'undefined' || item === '') {
      isError = true;
    }
  });
  if (isError) return '入力してください';
  const birthday = new Date(value);
  if (!isValid(birthday)) return '正しい日付を入力してください';
  const minDate = new Date('1700/01/01');
  if (minDate > birthday) return '正しい日付を入力してください';
  const age = differenceInYears(new Date(), birthday);
  if (age < 16) return '16歳未満は契約者として登録できません';
  return '';
};

const errorCheckDateNoRequired = (value) => {
  const values = value.split('/');
  const filterDate = values.filter((item) => (item === 'undefined' || item === ''));
  const minDate = new Date('1700/01/01');

  if (filterDate.length === 3) return '';
  if (filterDate.length > 0 || !isValid(new Date(value)) || minDate > new Date(value)) return '正しい日付を入力してください';
  return '';
};

class validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'mstStoreId':
    case 'mstCodePrefectureCode':
      return errorCheckSelectList(value);
    case 'lastName':
    case 'firstName':
    case 'lastNameKana':
    case 'firstNameKana':
    case 'city':
    case 'street':
    case 'emergencyLastName':
    case 'emergencyFirstName':
    case 'corporationName':
      return errorCheckText(value);
    case 'email':
      return errorCheckMail(value);
    case 'emailNoRequired':
      return errorCheckMailNoRequired(value);
    case 'phone':
    case 'emergencyPhone':
      return errorCheckPhone(value);
    case 'zipCode':
      return errorCheckZipCode(value);
    case 'zipCodeNorequired':
      return errorCheckZipCodeNoRequired(value);
    case 'phoneTwo':
      return errorCheckPhoneTwo(value);
    case 'birthday':
      return errorCheckDate(value);
    case 'birthdayNoRequired':
      return errorCheckDateNoRequired(value);
    case 'password':
      return errorCheckPassword(value);
    default:
      return '';
  }
};
}

export default validation;
