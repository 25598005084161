import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography,
  Paper, Checkbox, Tooltip,
  IconButton, Button, withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import { getSelected } from '../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class DataTable extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: [],
      deleteConfopen: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      if (this.props.rows) {
        this.handleHeaderClose();
      }
    }
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  onPreDelete = () => {
    this.setState({ deleteConfopen: true, loading: false });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: true });
    this.props.onDelete(selected);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
    } = this.props;
    const {
      selected, deleteConfopen, loading,
    } = this.state;

    return (
      <>
        <Paper style={{ marginTop: '20px' }}>
          <Toolbar className={selected.length > 0 ? classes.highlight : null}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 && (
              <Tooltip title="予約枠の削除">
                <Button color="secondary" onClick={this.onPreDelete}>予約枠の削除</Button>
              </Tooltip>
            )}
          </Toolbar>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length
                        && rows.filter((row) => !row.delImposibleFlg).length !== selected.length}
                      checked={
                        rows.filter((row) => !row.delImposibleFlg).length > 0
                        && rows.filter((row) => !row.delImposibleFlg).length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length < 1 || rows.filter((row) => !row.delImposibleFlg).length < 1
                      }
                    />
                  </TableCell>
                  <TableCell>予約日</TableCell>
                  <TableCell>メニュー名</TableCell>
                  <TableCell>開始時間</TableCell>
                  <TableCell>終了時間</TableCell>
                  <TableCell>公開日</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = this.isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {!row.delImposibleFlg && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{row.targetedOn}</TableCell>
                      <TableCell>{row.menuName}</TableCell>
                      <TableCell>{row.startTime}</TableCell>
                      <TableCell>{row.endTime}</TableCell>
                      <TableCell>{row.publishedOn}</TableCell>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={6}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <ConfirmDialog
            open={deleteConfopen}
            handleClose={this.handleClose}
            executeText="削除する"
            handleExecute={this.onDelete}
            title="選択した予約枠の削除"
            contentText="選択した予約枠を削除します。この操作は元に戻すことができません。"
            loading={loading}
            buttonColor="secondary"
          />
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(DataTable);
