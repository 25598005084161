import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約者一覧取得
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.SUBSCRIBER_LIST.SUBSCRIBER_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約者詳細
 */
export function getDetailData(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.SUBSCRIBER_LIST.SUBSCRIBER_DETAIL, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約ステータスを更新する
 *
 * @param {object} params
 */
export function updateStatus(id, searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.SUBSCRIBER_LIST.SUBSCRIBERS, { id }, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 振替キャンセル
 * @param {*} id
 * @param {*} searchCondition
 * @returns
 */
export function transferCancel(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.SUBSCRIBER_LIST.SUBSCRIBER_CANCEL, { id }, null)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getStatus(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.SUBSCRIBER_LIST.GET_STATUS, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
