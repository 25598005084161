import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Container, Box, Button, TextField,
} from '@material-ui/core';

import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import WeekSelectTable from './WeekSelectTable';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Monthpicker from '../../../components/atoms/monthpicker/Monthpicker';
import WeekSelectChoiceDialog from './WeekSelectChoiceDialog';
import WeekSelectLinkDialog from './WeekSelectLinkDialog';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import Validation from './validation';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

import {
  actCallInitWeekSelect, actCallMemberProductInfo,
  actCallApiSearchWeekSelectSchedule, actApiInsertWeekSelectMemberLinkage,
  actApiWeekSelectFlgReset,
} from '../../../redux/school/weekSelect/action';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
});

class WeekSelect extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        name: '定期曜日選択',
      },
    ];

    this.state = {
      isMain: this.props.common.user ? this.props.common.user.main : false,
      datas: null,
      searchCondition: {
        searchMonth: format(new Date(), 'yyyy/MM'),
        searchStore: this.props.common.user ? this.props.common.user.store : null,
        searchContent: null,
        searchMenu: null,
        searchMemberNo: '',
      },
      selects: {
        stores: [],
        contents: [],
        menus: [],
        storeContentLinks: [],
      },
      message: {
        searchMonth: '',
        searchContent: '',
      },
      selectedMemberInfo: null,
      selectedContract: null,
      selectedIds: [],
      isLinkDialogOpen: false,
      isContractChoiceOpen: false,
      successSnackOpen: false,
    };

    this.props.dispatch(actCallInitWeekSelect());
  }

  componentDidMount() {
    document.title = '定期曜日選択';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.weekSelect.selects !== this.props.weekSelect.selects) {
      if (this.props.weekSelect.selects) {
        this.setSelects(this.props.weekSelect.selects);
      }
    }
    if (prevProps.weekSelect.memberData !== this.props.weekSelect.memberData) {
      if (this.props.weekSelect.memberData) {
        this.setMemberContract(this.props.weekSelect.memberData);
      }
    }
    if (prevProps.weekSelect.scheduleDatas !== this.props.weekSelect.scheduleDatas) {
      if (this.props.weekSelect.scheduleDatas) {
        this.setScheduleDatas(this.props.weekSelect.scheduleDatas);
      }
    }
    if (prevProps.weekSelect.isInsertSuccess !== this.props.weekSelect.isInsertSuccess) {
      if (this.props.weekSelect.isInsertSuccess) {
        this.setMemberLinkageSuccess();
      }
    }
  }

  setMemberContract(memberData) {
    const { searchCondition } = this.state;
    let tempCondition = searchCondition;

    let isContractChoiceOpen = false;
    let selectedContract = null;
    if (memberData.contracts.length === 1) {
      const contract = memberData.contracts[0];
      selectedContract = contract;
      tempCondition = this.setContractCondition(memberData, contract, tempCondition);
    }
    if (memberData.contracts.length > 1) {
      isContractChoiceOpen = true;
    }
    this.setState({
      searchCondition: tempCondition,
      selectedMemberInfo: memberData,
      isContractChoiceOpen,
      selectedContract,
    });
  }

  setContractCondition = (memberData, contract, condition) => {
    let tempCondition = condition;
    if (memberData.storeId) {
      tempCondition = {
        ...tempCondition,
        searchStore: {
          id: memberData.storeId,
          name: memberData.storeName,
          mstOrganizationCompanyId: memberData.companyId,
        },
      };
    }
    let searchMonth = '';
    if (contract.startDate) {
      searchMonth = format(new Date(contract.startDate), 'yyyy/MM');
    }
    let searchContent = null;
    if (contract.contentId) {
      searchContent = {
        id: contract.contentId,
        name: contract.contentName,
      };
    }
    let searchMenu = null;
    if (contract.menuId) {
      searchMenu = {
        id: contract.menuId,
        name: contract.menuName,
      };
    }
    return {
      ...tempCondition,
      searchMonth,
      searchContent,
      searchMenu,
    };
  }

  onSetContract = (_, contaract) => {
    const { selectedMemberInfo, searchCondition } = this.state;
    const tempCondition = this.setContractCondition(selectedMemberInfo, contaract, searchCondition);
    this.setState({
      searchCondition: tempCondition,
      isContractChoiceOpen: false,
      selectedContract: contaract,
    });
  }

  setUser(user) {
    const { searchCondition } = this.state;
    this.setState({
      isMain: user.main,
      searchCondition: {
        ...searchCondition,
        searchStore: user.store,
      },
    });
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setScheduleDatas(datas) {
    this.setState({ datas });
  }

  onChange = (event) => {
    const { value, name } = event.target;
    const { searchCondition, datas } = this.state;
    let tempDatas = datas;
    if (name !== 'searchMemberNo') {
      tempDatas = [];
    }
    this.setState({
      datas: tempDatas,
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  }

  onDateChange = (_, searchMonth) => {
    const { searchCondition } = this.state;
    this.setState({
      datas: [],
      searchCondition: {
        ...searchCondition,
        searchMonth,
      },
    });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    let tempMessage = null;
    let isError = false;
    Object.keys(searchCondition).map((key) => {
      const msg = Validation.formValidate(key, searchCondition[key]);
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      if (msg) {
        isError = true;
      }
      return msg;
    });

    this.setState({ message: tempMessage });
    if (isError) {
      return;
    }
    this.props.dispatch(actCallApiSearchWeekSelectSchedule({
      ...searchCondition,
      searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      searchContentId: searchCondition.searchContent && searchCondition.searchContent.id,
      searchMenuId: searchCondition.searchMenu && searchCondition.searchMenu.id,
    }));
  }

  handleSave = (_, searchMemberNo, selectedMemberInfo) => {
    const { selectedIds, searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchMemberNo,
    };
    this.setState({ selectedMemberInfo, searchCondition: tempCondition, isLinkDialogOpen: false });

    this.props.dispatch(actApiWeekSelectFlgReset());
    this.props.dispatch(actApiInsertWeekSelectMemberLinkage({
      memberId: selectedMemberInfo.memberId,
      insertDetailIds: selectedIds,
      contentId: searchCondition.searchContent.id,
      selectDate: `${searchCondition.searchMonth}/01`,
      storeId: searchCondition.searchStore.id,
    }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  setMemberLinkageSuccess = () => {
    this.setState({ successSnackOpen: true });
    this.onSearch();
  }

  getContents = (searchCondition, selects) => {
    const storeId = searchCondition.searchStore && searchCondition.searchStore.id;
    if (!storeId) {
      return selects.contents;
    }
    const fileterContent = selects.storeContentLinks.filter(
      (link) => link.mstStoreId === storeId,
    ).map((n) => n.mstContentId);

    return selects.contents.filter(
      (contentFilter) => fileterContent.includes(contentFilter.id),
    );
  }

  getMenus = (searchCondition, selects) => {
    const companyId = searchCondition.searchStore
    && searchCondition.searchStore.mstOrganizationCompanyId;
    const contentId = searchCondition.searchContent && searchCondition.searchContent.id;

    if (!companyId) {
      return selects.menus;
    }
    const fileterMenu = selects.menus.filter(
      (link) => link.mstOrganizationCompanyId === companyId,
    );
    if (!contentId) {
      return fileterMenu;
    }
    return fileterMenu.filter(
      (link) => link.mstContentId === contentId,
    );
  }

  handleLinkOpen = (_, selectedIds) => {
    this.setState({ isLinkDialogOpen: true, selectedIds });
  }

  handleLinkClose = () => {
    this.setState({ isLinkDialogOpen: false });
  }

  onSearchMemberInfo = () => {
    const { searchMemberNo } = this.state.searchCondition;
    if (searchMemberNo.length !== 10) {
      this.setState({ selectedMemberInfo: null });
    }

    this.props.dispatch(actCallMemberProductInfo({ searchMemberNo }));
  }

  handleSelectChoiceClose = () => {
    this.setState({ isContractChoiceOpen: false });
  }

  render() {
    const { classes } = this.props;

    const {
      datas, isMain, selects, searchCondition, message,
      successSnackOpen, isLinkDialogOpen, selectedIds,
      selectedMemberInfo, selectedContract, isContractChoiceOpen,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>

          <SearchBox onSearch={this.onSearch}>
            <Box display="flex" mt={1}>
              <Box>
                <FormTitle title="会員番号" />
                <TextField
                  name="searchMemberNo"
                  inputProps={{
                    maxLength: 10,
                  }}
                  variant="outlined"
                  fullWidth
                  value={searchCondition.searchMemberNo}
                  onChange={this.onChange}
                />
              </Box>

              <Box m={1} alignSelf="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.onSearchMemberInfo}
                  disabled={searchCondition.searchMemberNo.length !== 10}
                >
                  会員番号から検索条件を設定
                </Button>
              </Box>

              {searchCondition.searchMemberNo.length === 10 && selectedMemberInfo && (
                <Box alignItems="center" display="flex">
                  <Box display={!selectedMemberInfo.memberId ? '' : 'none'}>
                    存在しない会員です
                  </Box>
                  <Box display={selectedMemberInfo.memberId ? 'flex' : 'none'}>
                    <Box ml={10}>
                      <FormTitle title="名前" />
                      <Box>{selectedMemberInfo.nameKana}</Box>
                      <Box>{selectedMemberInfo.name}</Box>
                    </Box>
                    <Box ml={10}>
                      <FormTitle title="週回数" />
                      <Box>{selectedContract && selectedContract.weekCount}</Box>
                    </Box>
                  </Box>
                </Box>
              )}

            </Box>

            <Box display="flex" mt={2}>
              <Box width="20%" mr={2} display={isMain ? '' : 'none'}>
                <FormTitle title="店舗" isRequired />
                <SearchSelectBox options={selects.stores} value={searchCondition.searchStore} onChange={this.onChange} name="searchStore" disableClearable />
              </Box>

              <Box width="30%" mr={2}>
                <FormTitle title="コンテンツ" isRequired />
                <SearchSelectBox
                  options={this.getContents(searchCondition, selects)}
                  value={searchCondition.searchContent}
                  onChange={this.onChange}
                  name="searchContent"
                />
                <FormErrorText>{message.searchContent}</FormErrorText>
              </Box>

              <Box mr={2}>
                <FormTitle title="表示開始月" isRequired />
                <Monthpicker
                  value={searchCondition.searchMonth}
                  handleChange={this.onDateChange}
                />
                <FormErrorText>{message.searchMonth}</FormErrorText>
              </Box>
              <Box width="30%" mr={2}>
                <FormTitle title="メニュー" />
                <SearchSelectBox
                  options={this.getMenus(searchCondition, selects)}
                  value={searchCondition.searchMenu}
                  onChange={this.onChange}
                  name="searchMenu"
                />
              </Box>
            </Box>
          </SearchBox>

          <WeekSelectTable
            datas={datas}
            handleLinkOpen={this.handleLinkOpen}
          />
          <WeekSelectChoiceDialog
            rows={selectedMemberInfo ? selectedMemberInfo.contracts : []}
            memberName={selectedMemberInfo && selectedMemberInfo.name}
            open={isContractChoiceOpen}
            handleClose={this.handleSelectChoiceClose}
            onSetContract={this.onSetContract}
          />
          <WeekSelectLinkDialog
            selectedMemberInfo={selectedMemberInfo}
            searchCondition={searchCondition}
            open={isLinkDialogOpen}
            selectedIds={selectedIds}
            datas={datas}
            handleClose={this.handleLinkClose}
            handleSave={this.handleSave}
          />
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="会員紐付けが完了しました" />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  weekSelect: state.weekSelect, common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(WeekSelect)));
