import { call, put, takeLatest } from 'redux-saga/effects';
import { getStores } from '../../../services/common/selectList.service';
import { getDatas, updateStopFlg } from '../../../services/setting/mailList.service';
import {
  ACTION_TYPE,
  actInitMailListSuccess,
  actCallApiMailListSuccess,
  actChangeMailStopFlgSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param userInfo
 */
function* callApiInitMailList() {
  try {
    const stores = yield call(getStores);
    yield put(actInitMailListSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param
 */
function* callApiMailList({ storeId }) {
  try {
    const datas = yield call(getDatas, { storeId });
    yield put(actCallApiMailListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 配信状況変更
 *
 * @param
 */
function* changeMailStopFlg({ id }) {
  try {
    yield call(updateStopFlg, id);
    yield put(actChangeMailStopFlgSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMailList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_MAIL_LIST, callApiInitMailList);
  yield takeLatest(ACTION_TYPE.CALL_API_MAIL_LIST, callApiMailList);
  yield takeLatest(ACTION_TYPE.CHANGE_MAIL_STOP_FLG, changeMailStopFlg);
}
