import { ACTION_TYPE } from './action';

const weekSelect = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_WEEK_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_MEMBER_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        memberData: action.memberInfoData,
      };
    case ACTION_TYPE.CALL_API_SEARCH_WEEK_SELECT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleDatas: action.scheduleDatas,
      };
    case ACTION_TYPE.CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        isInsertSuccess: true,
      };
    case ACTION_TYPE.CALL_API_DIALOG_MEMBER_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        dialogMemberData: action.dialogMemberData,
      };
    case ACTION_TYPE.CALL_API_WEEK_SELECT_FLG_RESET:
      return {
        ...state,
        isInsertSuccess: false,
      };
    default:
      return state;
  }
};

export default weekSelect;
