import React from 'react';
import {
  TextField, Box, Typography, Button, Divider,
  FormControl, Select, MenuItem, withStyles,
  FormControlLabel, Switch,
} from '@material-ui/core';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import Validation from '../validation';
import { getGolfDatas, checkValidation } from '../rule-list-func';

const styles = () => ({
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class BaseGolfRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getGolfDatas(),
      messages: getGolfDatas(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 0) {
        this.setData(this.props.golfDatas);
      }
    }
  }

  setData = (data) => {
    this.setState({ data: data.golfDatas || getGolfDatas(), messages: getGolfDatas() });
  }

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: checked,
      },
    });
  }

  onChange = (event) => {
    const { data, messages } = this.state;
    const { value, name } = event.target;
    let tempMessage = { ...messages, [name]: Validation.formValidate(name, value) };

    if (name === 'startTime' && value && data.endTime) {
      tempMessage = this.compareTime(tempMessage, value, data.endTime);
    }
    if (name === 'endTime' && value && data.startTime) {
      tempMessage = this.compareTime(tempMessage, data.startTime, value);
    }

    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      messages: tempMessage,
    });
  }

  onSave = (event) => {
    const { data, messages } = this.state;
    const errObj = checkValidation(data, messages);
    this.setState({ messages: errObj.messages });
    if (errObj.isError) return;
    this.props.onSave(event, data);
  }

  render() {
    const {
      value, classes,
    } = this.props;
    const { data, messages } = this.state;

    return (
      <TabPanel value={value} index={0}>
        <Box p={2}>
          <Box textAlign="right">
            <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
          </Box>
          <Box m={3}>
            <FormTitle title="予約枠単位" isRequired />
            <FormControl variant="outlined" error={messages.reservationUnit !== ''}>
              <Select
                value={data.reservationUnit}
                onChange={this.onChange}
                name="reservationUnit"
              >
                <MenuItem value=""><em> </em></MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={60}>60</MenuItem>
              </Select>
            </FormControl>
            <Typography className={classes.ruleLabel} component="span">分単位</Typography>
            <FormErrorText>{messages.reservationUnit}</FormErrorText>
          </Box>
          <Box m={3}>
            <Box mb={1} fontWeight="fontWeightBold" fontSize={20}>シミュレーション</Box>
            <Divider />
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約受付締切期限" isRequired />
              <TextField
                variant="outlined"
                name="freeCloseTime"
                value={data.freeCloseTime}
                onChange={this.onChange}
                error={messages.freeCloseTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.freeCloseTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約キャンセル可能期限" isRequired />
              <TextField
                variant="outlined"
                name="freeCancelTime"
                value={data.freeCancelTime}
                onChange={this.onChange}
                error={messages.freeCancelTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.freeCancelTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <FormTitle title="事前予約可能数" />
            <TextField
              variant="outlined"
              name="freeAdvanceNumber"
              value={data.freeAdvanceNumber || ''}
              onChange={this.onChange}
              error={messages.freeAdvanceNumber !== ''}
              inputProps={{ maxLength: 9 }}
            />
            <FormErrorText>{messages.freeAdvanceNumber}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="キャンセル待ち受付" isRequired />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.freeCancelWaitAccepted}
                  onChange={this.onCheckChange}
                  name="freeCancelWaitAccepted"
                  color="primary"
                />
              )}
              label="キャンセル待ちを受け付ける"
            />
          </Box>
          <Box m={3}>
            <Box mb={1} fontWeight="fontWeightBold" fontSize={20}>レッスン</Box>
            <Divider />
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約受付締切期限" isRequired />
              <TextField
                variant="outlined"
                name="lessonCloseTime"
                value={data.lessonCloseTime}
                onChange={this.onChange}
                error={messages.lessonCloseTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.lessonCloseTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約キャンセル可能期限" isRequired />
              <TextField
                variant="outlined"
                name="lessonCancelTime"
                value={data.lessonCancelTime}
                onChange={this.onChange}
                error={messages.lessonCancelTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.lessonCancelTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <FormTitle title="事前予約可能数" />
            <TextField
              variant="outlined"
              name="lessonAdvanceNumber"
              value={data.lessonAdvanceNumber || ''}
              onChange={this.onChange}
              error={messages.lessonAdvanceNumber !== ''}
              inputProps={{ maxLength: 9 }}
            />
            <FormErrorText>{messages.lessonAdvanceNumber}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="キャンセル待ち受付" isRequired />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.lessonCancelWaitAccepted}
                  onChange={this.onCheckChange}
                  name="lessonCancelWaitAccepted"
                  color="primary"
                />
              )}
              label="キャンセル待ちを受け付ける"
            />
          </Box>
          <Box m={3}>
            <Box mb={1} fontWeight="fontWeightBold" fontSize={20}>的打ち</Box>
            <Divider />
          </Box>
          <Box m={3}>
            <FormTitle title="的打ち受付" isRequired />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.targetAccepted}
                  onChange={this.onCheckChange}
                  name="targetAccepted"
                  color="primary"
                />
              )}
              label="的打ち受付"
            />
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約受付締切期限" isRequired />
              <TextField
                variant="outlined"
                name="targetCloseTime"
                value={data.targetCloseTime}
                onChange={this.onChange}
                error={messages.targetCloseTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.targetCloseTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約キャンセル可能期限" isRequired />
              <TextField
                variant="outlined"
                name="targetCancelTime"
                value={data.targetCancelTime}
                onChange={this.onChange}
                error={messages.targetCancelTime !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.targetCancelTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <FormTitle title="事前予約可能数" />
            <TextField
              variant="outlined"
              name="targetAdvanceNumber"
              value={data.targetAdvanceNumber || ''}
              onChange={this.onChange}
              error={messages.targetAdvanceNumber !== ''}
              inputProps={{ maxLength: 9 }}
            />
            <FormErrorText>{messages.targetAdvanceNumber}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="キャンセル待ち受付" isRequired />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.targetCancelWaitAccepted}
                  onChange={this.onCheckChange}
                  name="targetCancelWaitAccepted"
                  color="primary"
                />
              )}
              label="キャンセル待ちを受け付ける"
            />
          </Box>
          <Box mt={1}>
            <FormTitle title="予約ルール" />
            <TextField
              id="memberRule"
              name="memberRule"
              multiline
              fullWidth
              rows={25}
              variant="outlined"
              value={data.memberRule}
              onChange={this.onChange}
              inputProps={{
                maxLength: 30000,
              }}
            />
          </Box>
          <Box mt={1}>
            <FormTitle title="ゲスト予約ルール" />
            <TextField
              id="guestMemberRule"
              name="guestMemberRule"
              multiline
              fullWidth
              rows={25}
              variant="outlined"
              value={data.guestMemberRule}
              onChange={this.onChange}
              inputProps={{
                maxLength: 30000,
              }}
            />
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default withStyles(styles)(BaseGolfRule);
