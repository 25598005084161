import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Route, withRouter } from 'react-router-dom';
import { actCallApiMe, actResetGetMeFlg } from '../../redux/common/common/action';
import urls from '../../constants/urls';

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(actCallApiMe());
    this.exclusionList = [urls.OTHER.HOME, urls.SYSTEM_ERROR];
    this.check();
  }

  componentDidUpdate() {
    if (this.props.common.isGetMeError === true) {
      this.reset();
    }
  }

  reset() {
    this.props.dispatch(actResetGetMeFlg());
    localStorage.clear();
    window.location.href = urls.OTHER.LOGIN;
  }

  check() {
    const url = window.location.pathname;
    const isExclusion = this.exclusionList.includes(url);
    if (isExclusion) {
      return;
    }
    const secondUrl = window.location.pathname.split('/')[1];
    const { user } = this.props.common;
    const screenList = user ? user.screenList : [];
    const result = screenList.some((c) => c.url.indexOf(secondUrl) >= 0);
    if (!result || screenList.length === 0) {
      window.location.href = urls.OTHER.HOME;
    }
  }

  render() {
    return (
      <Route>
        {this.props.children}
      </Route>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common });

export default withRouter(connect(mapStateToProps)(Auth));
