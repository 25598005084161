import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  LinearProgress,
} from '@material-ui/core';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import {
  actCallApiSchoolCalendarListFlgReset, actCallApiSchoolCalendarListExperiencerCheck,
  actCallApiSchoolCalendarListReset,
} from '../../../../redux/school/schoolCalenderList/action';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

class SchoolCalendarListResetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      btnLoading: false,
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetOpen !== this.props.resetOpen) {
      if (this.props.resetOpen) {
        this.init();
      }
    }
    if (prevProps.schoolCalendarList.isCheckSuccess
      !== this.props.schoolCalendarList.isCheckSuccess) {
      if (this.props.schoolCalendarList.isCheckSuccess) {
        this.setResetFlg(this.props.schoolCalendarList.resetFlg);
      }
    }
  }

  setResetFlg(resetFlg) {
    this.setState({ isError: !resetFlg, loading: false, btnLoading: false });
  }

  onReset = () => {
    this.setState({ btnLoading: true });
    const { selectedRow } = this.props;
    this.props.dispatch(actCallApiSchoolCalendarListFlgReset());
    this.props.dispatch(actCallApiSchoolCalendarListReset({
      headerId: selectedRow.id,
      storeId: selectedRow.storeId,
      contentId: selectedRow.contentId,
      targetedOn: selectedRow.targetDate,
    }));
  }

  init() {
    const { storeId, contentId, targetDate } = this.props.selectedRow;
    this.props.dispatch(actCallApiSchoolCalendarListFlgReset());
    this.props.dispatch(actCallApiSchoolCalendarListExperiencerCheck({
      storeId,
      contentId,
      targetedOn: targetDate,
    }));
    this.setState({ loading: true, btnLoading: true, isError: false });
  }

  render() {
    const {
      loading, btnLoading, isError,
    } = this.state;

    const {
      resetOpen,
      onResetModalClose,
    } = this.props;

    return (
      <Dialog
        open={resetOpen}
        onClose={onResetModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        fullWidth
      >
        <DialogTitle error={isError}>{isError ? 'エラー' : '予約枠のリセット'}</DialogTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <DialogContentText>
            {!isError
              ? (
                <>
                  予約枠をリセットします。この操作は元に戻すことができません。
                </>
              )
              : (
                <>
                  体験者がいるためリセット出来ません。
                  <br />
                  体験者を予約枠から削除した上で再度実行してください。
                </>
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetModalClose}>{!isError ? 'キャンセル' : '閉じる'}</Button>
          {!isError && (
            <LoadingButton
              onClick={this.onReset}
              label="リセットする"
              loading={btnLoading}
              color="primary"
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolCalendarList: state.schoolCalendarList,
});

export default connect(mapStateToProps)(SchoolCalendarListResetDialog);
