import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Tabs, Tab, Box, Card, Button,
  FormControlLabel, Switch, TextField,
} from '@material-ui/core';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import {
  actCallApiInitSurveySetting, actCallApiGetSurveySetting,
  actCallApiUpsertSurveySetting, actResetSurveySettingFlg,
} from '../../../redux/setting/surveySetting/action';
import Validation from './validation';

const styles = (theme) => ({
  cardMenu: {
    display: 'flex',
    minHeight: '300px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: '20px',
  },
});

const tempErrorMessage = {
  surveyAnswerPeriod: '',
  surveyPointNumber: '',
  surveyPointPeriod: '',
};

class SurveySetting extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsTextList = [
      { name: '授業アンケート設定' },
    ];

    let isMain = false;
    let searchStore = null;

    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    this.props.dispatch(actCallApiInitSurveySetting());

    if (searchStore !== null) {
      this.search(searchStore);
    }

    this.state = {
      isMain,
      searchStore,
      stores: [],
      tabIndex: 0,
      errorMessage: tempErrorMessage,
      surveySetting: {
        mstStoreId: null,
        mstContentId: null,
        surveyReception: false,
        surveyAnswerPeriod: '',
        surveyPointGranted: false,
        surveyPointNumber: '',
        surveyPointPeriod: '',
      },
      successSnackOpen: false,
    };
  }

  componentDidMount() {
    document.title = '授業アンケート設定';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.surveySetting.stores !== this.props.surveySetting.stores) {
      if (this.props.surveySetting.stores) {
        this.setStores(this.props.surveySetting.stores);
      }
    }
    if (prevProps.surveySetting.datas !== this.props.surveySetting.datas) {
      if (this.props.surveySetting.datas) {
        this.setData(this.props.surveySetting.datas);
      }
    }
    if (prevProps.surveySetting.isUpdateSuccess !== this.props.surveySetting.isUpdateSuccess) {
      if (this.props.surveySetting.isUpdateSuccess) {
        this.setResultDatas();
      }
    }
  }

  setResultDatas() {
    this.setState({ successSnackOpen: true });
    const { searchStore } = this.state;
    this.search(searchStore);
  }

  setUser(user) {
    const { searchStore } = this.state;
    this.setState({
      isMain: user.main,
      searchStore: user.store,
    });
    this.search(searchStore);
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setData(datas) {
    if (datas.length < 1) {
      return;
    }
    const { tabIndex } = this.state;
    this.setState({ surveySetting: datas[tabIndex] });
  }

  onSearch = () => {
    const { searchStore } = this.state;
    this.search(searchStore);
    this.setState({ errorMessage: tempErrorMessage });
  }

  search = (searchStore) => {
    const searchStoreId = searchStore && searchStore.id;
    this.props.dispatch(actCallApiGetSurveySetting({
      storeId: searchStoreId,
    }));
  }

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { surveySetting, errorMessage } = this.state;
    let check = surveySetting.surveyPointGranted;
    if (name === 'surveyAnswerPeriod') {
      check = surveySetting.surveyReception;
    }
    this.setState({
      surveySetting: {
        ...surveySetting,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value, check),
      },
    });
  };

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { surveySetting } = this.state;
    this.setState({
      surveySetting: {
        ...surveySetting,
        [name]: checked,
      },
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  onSave = () => {
    const { surveySetting } = this.state;

    let isError = false;
    let tempMessage = tempErrorMessage;
    Object.keys(surveySetting).forEach((key) => {
      let check = surveySetting.surveyPointGranted;
      if (key === 'surveyAnswerPeriod') {
        check = surveySetting.surveyReception;
      }
      const msg = Validation.formValidate(key, surveySetting[key], check);
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      if (msg) {
        isError = true;
      }
    });

    this.setState({ errorMessage: tempMessage });

    if (isError) {
      return;
    }

    this.props.dispatch(actResetSurveySettingFlg());
    this.props.dispatch(actCallApiUpsertSurveySetting(surveySetting));
  }

  onTabChange = (_, tabIndex) => {
    const { datas } = this.props.surveySetting;
    const surveySetting = datas[tabIndex];
    this.setState({ surveySetting, tabIndex, errorMessage: tempErrorMessage });
  };

  render() {
    const { classes } = this.props;
    const datas = this.props.surveySetting.datas || [];
    const {
      isMain, stores, searchStore, tabIndex, successSnackOpen, surveySetting, errorMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsTextList} />
        <MainBody>
          {isMain && (
            <Box mb={2}>
              <SearchBox onSearch={this.onSearch}>
                <Box display="flex">
                  <Box width="30%">
                    <FormTitle title="店舗" />
                    <SearchSelectBox
                      options={stores}
                      value={searchStore}
                      onChange={this.onStoreChange}
                      disableClearable
                      name="searchStore"
                    />
                  </Box>
                </Box>
              </SearchBox>
            </Box>
          )}

          <Card className={classes.cardMenu}>
            <Tabs
              orientation="vertical"
              value={tabIndex}
              onChange={this.onTabChange}
              className={classes.tabs}
            >
              {datas.map((data) => (
                <Tab label={data.contentName} key={data.mstContentId} />
              ))}
            </Tabs>

            {datas.length > 0 && (

              <Box p={3}>
                <Box mb={3}><Button color="primary" size="medium" onClick={this.onSave}>保存する</Button></Box>
                <Box>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={surveySetting.surveyReception}
                        onChange={this.onCheckChange}
                        name="surveyReception"
                        color="primary"
                      />
                    )}
                    label="授業アンケートを受け付ける"
                  />
                </Box>
                {surveySetting.surveyReception && (
                  <>
                    <Box mt={3}>
                      <FormTitle title="受付期間" isRequired />
                      <Box>講義終了から</Box>
                      <Box display="flex">
                        <TextField
                          variant="outlined"
                          name="surveyAnswerPeriod"
                          value={surveySetting.surveyAnswerPeriod}
                          error={errorMessage.surveyAnswerPeriod !== ''}
                          onChange={this.onChange}
                          inputProps={{ maxLength: 3 }}
                        />
                        <Box display="flex" alignItems="flex-end" ml={1}>日間回答可能</Box>
                      </Box>
                      <FormErrorText>{errorMessage.surveyAnswerPeriod}</FormErrorText>
                    </Box>
                  </>
                )}

                <Box mt={3}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={surveySetting.surveyPointGranted}
                        onChange={this.onCheckChange}
                        name="surveyPointGranted"
                        color="primary"
                      />
                    )}
                    label="授業アンケート回答後ポイントを付与する"
                  />
                </Box>
                {surveySetting.surveyPointGranted && (
                  <>
                    <Box mt={3}>
                      <FormTitle title="ポイント付与数" isRequired />
                      <Box display="flex">
                        <TextField
                          variant="outlined"
                          name="surveyPointNumber"
                          value={surveySetting.surveyPointNumber}
                          error={errorMessage.surveyPointNumber !== ''}
                          onChange={this.onChange}
                          inputProps={{ maxLength: 3 }}
                        />
                        <Box display="flex" alignItems="flex-end" ml={1}>ポイント</Box>
                      </Box>
                      <FormErrorText>{errorMessage.surveyPointNumber}</FormErrorText>
                    </Box>
                    <Box mt={3}>
                      <FormTitle title="ポイント有効期限" isRequired />
                      <Box>ポイント付与から</Box>
                      <Box display="flex">
                        <TextField
                          variant="outlined"
                          name="surveyPointPeriod"
                          value={surveySetting.surveyPointPeriod}
                          error={errorMessage.surveyPointPeriod !== ''}
                          onChange={this.onChange}
                          inputProps={{ maxLength: 3 }}
                        />
                        <Box display="flex" alignItems="flex-end" ml={1}>日間</Box>
                      </Box>
                      <FormErrorText>{errorMessage.surveyPointPeriod}</FormErrorText>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Card>
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="保存が完了しました" />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  surveySetting: state.surveySetting,
});

export default withStyles(styles)(connect(mapStateToProps)(SurveySetting));
