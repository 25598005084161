import { ACTION_TYPE } from './action';

const bookingDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_BOOKING_SEARCH_DETAIL_SUCCESS:
      return {
        ...state,
        booking: action.bookingDetail.booking,
        bookingMembers: action.bookingDetail.bookingMembers,
        memberStatusList: action.bookingDetail.memberStatusList,
      };
    case ACTION_TYPE.CALL_API_BOOKING_DETAIL_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        bookingMembers: action.bookingMembers,
      };
    case ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.members,
        isSearchSuccess: true,
      };
    case ACTION_TYPE.CALL_API_INSERT_BOOKING_MEMBER_SUCCESS:
      return {
        ...state,
        insertDatas: action.datas,
        isInsertSuccess: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_MEMBER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        isStatusUpdateSuccess: true,
        isSelectedClear: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_MEMBER_CANCEL_SUCCESS:
      return {
        ...state,
        isCancelSuccess: true,
        isSelectedClear: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_EXPERIENCE_INSERT_SUCCESS:
      return {
        ...state,
        isExperienceInsertSuccess: true,
      };
    case ACTION_TYPE.CALL_API_RESEND_ZOOMURL_TEACHER_SUCCESS:
      return {
        ...state,
        isReSendZoomUrlTeacherSuccess: true,
      };
    case ACTION_TYPE.CALL_API_CREATE_WEBINAR_SUCCESS:
      return {
        ...state,
        isCreateZoomSuccess: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_MEMBER_FLG_RESET:
      return {
        ...state,
        isSearchSuccess: false,
        isDeleteSuccess: false,
        isInsertSuccess: false,
        isStatusUpdateSuccess: false,
        isExperienceInsertSuccess: false,
        isCancelSuccess: false,
        isReSendZoomUrlTeacherSuccess: false,
        isCreateZoomSuccess: false,
        isBookingMemoUpdateSuccess: false,
        isGolfBoxUpdateSuccess: false,
      };
    case ACTION_TYPE.CALL_API_SELECTED_CLEAR_RESET:
      return {
        ...state,
        isSelectedClear: false,
      };
    case ACTION_TYPE.CALL_API_BOOKING_MENU_UPDATE_SUCCESS:
      return {
        ...state,
        isBookingMemoUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_GOLF_BOXS_SUCCESS:
      return {
        ...state,
        golfBoxs: action.golfBoxs,
      };
    case ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_SUCCESS:
      return {
        ...state,
        golfBoxUpdateFlg: action.golfBoxUpdateFlg,
        isGolfBoxUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_ERROR:
      return {
        ...state,
        golfBoxUpdateFlg: action.golfBoxUpdateFlg,
      };
    case ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_FLG_RESET:
      return {
        ...state,
        golfBoxUpdateFlg: 0,
      };
    default:
      return state;
  }
};

export default bookingDetail;
