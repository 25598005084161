import React from 'react';
import {
  TextField, Checkbox, Chip,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

export default function SearchMultiSelectBox(props) {
  const {
    name,
    options,
    onChange,
    error,
    value,
    onBlur,
  } = props;

  return (
    <Autocomplete
      multiple
      style={{ width: '100%' }}
      options={options}
      disableCloseOnSelect
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue,
          },
        });
      }}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      getOptionSelected={(option, values) => option.id === values.id}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={
              <CheckBoxOutlineBlankIcon fontSize="small" />
            }
            checkedIcon={
              <CheckBoxIcon fontSize="small" />
            }
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" error={error} onBlur={onBlur} />
      )}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip label={option.name} {...getTagProps({ index })} style={{ color: 'black' }} />
      ))}
      value={value}
      noOptionsText="データがありません"
    />
  );
}

SearchMultiSelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SearchMultiSelectBox.defaultProps = {
  error: false,
  value: [],
};
