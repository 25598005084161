import { call, put, takeLatest } from 'redux-saga/effects';
import { getItems, insertData, getMemberInfo } from '../../../services/info/healthCheck.service';

import {
  ACTION_TYPE,
  actCallApiMemberInfoSuccess,
  actInitHealthCheckSuccess,
  actInsertHealthCheckResultSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitHealthCheck({ param }) {
  try {
    const items = yield call(getItems, param);
    yield put(actInitHealthCheckSuccess(items));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * ヘルスチェック結果追加
 *
 * @param
 */
function* callInsertHealthCheckResult({ values }) {
  try {
    const datas = yield call(insertData, values);
    yield put(actInsertHealthCheckResultSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員名取得
 *
 * @param
 */
function* callApiMemberInfo({ memberNo }) {
  try {
    const member = yield call(getMemberInfo, memberNo);
    yield put(actCallApiMemberInfoSuccess(member));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallHealthCheck() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_HEALTH_CHECK, callApiInitHealthCheck);
  yield takeLatest(ACTION_TYPE.CALL_INSERT_HEALTH_CHECK_RESULT, callInsertHealthCheckResult);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_INFO, callApiMemberInfo);
}
