import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import {
  actCallApiBookingMemberUpdate,
} from '../../../redux/booking/bookingDetail/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class BookingMemberChangeStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusCodes: [],
      updateStatus: null,
      changeLoading: false,
      contentsMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  onItemChange = (_, values) => {
    this.setState({ updateStatus: values });
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  onChangeStatus = (event) => {
    this.setState({ updateStatus: event.target.value, contentsMessage: '' });
  }

  handleUpdateStatus = () => {
    this.setChangeLoading(true);
    const { row } = this.props;
    const { updateStatus } = this.state;

    if (!updateStatus || Object.keys(updateStatus).length === 0) {
      this.setChangeLoading(false);
      this.setState({ contentsMessage: '変更後のステータスを選択してください' });
    } else {
      this.props.dispatch(
        actCallApiBookingMemberUpdate({
          id: row.id,
          status: updateStatus.id,
          contentId: this.props.contentId,
          isTransferAhead: row.transferSourceDate !== null,
        }),
      );
      this.setChangeLoading(false);
      this.props.handleClose();
    }
  }

  init() {
    const { row, regularFlg, bookingDetail } = this.props;
    const memberStatusCode = (regularFlg && row.transferSourceDate)
      ? row.transferAheadStatusCode
      : row.memberStatusCode;

    const setCodes = bookingDetail.memberStatusList
      .filter((memberstatus) => {
        if (memberstatus.id === memberStatusCode) return false;
        if (regularFlg && row.transferSourceDate && memberstatus.id === 3) return false;
        return true;
      });

    this.setState({
      statusCodes: setCodes,
      updateStatus: null,
      contentsMessage: '',
    });
  }

  render() {
    const {
      open,
      handleClose,
      row,
    } = this.props;
    const {
      updateStatus,
      changeLoading,
      statusCodes,
      contentsMessage,
    } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="xs"
          aria-labelledby="update-memberStatus-title"
          aria-describedby="update-memberStatus-description"
          disableBackdropClick
          onEscapeKeyDown={handleClose}
        >
          <DialogTitle>
            <Box>次のステータスを変更</Box>
            <Box>{row.name}</Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Box mb={3}>
                <FormTitle title="変更前" />
                <Box>
                  {row.transferAheadStatusName ? row.transferAheadStatusName : row.memberStatusName}
                </Box>
              </Box>
              <Box>
                <FormTitle title="変更後" isRequired />
                <SearchSelectBox
                  options={statusCodes}
                  width="100%"
                  value={updateStatus}
                  onChange={this.onChangeStatus}
                  name="updateStatus"
                  error={contentsMessage !== ''}
                />
                <FormErrorText>{contentsMessage}</FormErrorText>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton color="primary" label="変更" onClick={this.handleUpdateStatus} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

export default withRouter(connect(mapStateToProps)(BookingMemberChangeStatusDialog));
