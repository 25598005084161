import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box, FormControl,
  RadioGroup, FormControlLabel, Radio, TextField, withStyles,
} from '@material-ui/core';
import {
  format, isAfter, addMonths, subDays,
} from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import {
  actCallGolfBookingResetFlg, apiCallUpdateBulkFrameLimit,
} from '../../../../redux/event/eventCalendarDetailGolf/action';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import Datepicker from '../../../../components/atoms/datepicker/Datepicker';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';

const styles = () => ({
  timeInputBox: {
    width: '40%',
  },
  timeInput: {
    width: '100%',
  },
});

class EventCalendarBulkChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        storeId: null,
        type: '1',
        startDate: format(new Date(), 'yyyy/MM/dd'),
        endDate: format(new Date(), 'yyyy/MM/dd'),
        limitNumber: '',
      },
      btnLoading: false,
      errorMessage: {
        limitNumber: '',
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open && this.props.storeId) {
        this.init(this.props.storeId);
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  init = (storeId) => {
    const targetMonth = this.props.targetDate;
    this.setState({
      info: {
        type: '1',
        startDate: format(new Date(targetMonth), 'yyyy/MM/dd'),
        endDate: format(new Date(targetMonth), 'yyyy/MM/dd'),
        startTime: '',
        endTime: '',
        limitNumber: '',
        storeId,
      },
      btnLoading: false,
      minDate: format(targetMonth, 'yyyy/MM/dd'),
      maxDate: format(subDays(addMonths(targetMonth, 1), 1), 'yyyy/MM/dd'),
      errorMessage: {
        limitNumber: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
      },
    });
  }

  onChangeDate = (value, name) => {
    // 不正値か判定
    const isInvalidDate = !value || Number.isNaN(value.getTime());

    const { info } = this.state;
    const targetDate = isInvalidDate ? '' : format(new Date(value), 'yyyy/MM/dd');

    const tempErrorMessage = this.checkDate(targetDate, name);
    this.setState({ info: { ...info, [name]: targetDate }, errorMessage: tempErrorMessage });
  }

  checkDate = (checkDate, name) => {
    const { info, errorMessage } = this.state;
    const tempInfo = {
      ...info,
      [name]: checkDate,
    };
    const { startDate, endDate } = tempInfo;
    let tempErrorMessage = {
      ...errorMessage,
    };
    // 日付以外が入力
    const inputErrorMsg = !checkDate ? '正しい日付を入力してください。' : '';

    // 表示年月と不一致
    const targetMonth = this.props.targetDate.getMonth();
    const notMatchTargetMonth = checkDate !== '' && this.checkTargetDate(checkDate);
    tempErrorMessage = {
      ...errorMessage,
      [name]: notMatchTargetMonth ? `${targetMonth + 1}月以外は入力出来ません。` : inputErrorMsg,
    };
    // 開始日< 終了日か
    if (startDate && endDate && !notMatchTargetMonth) {
      const isAfterStart = isAfter(new Date(startDate), new Date(endDate));
      tempErrorMessage = {
        ...errorMessage,
        startDate: isAfterStart ? '適用開始日は適用終了日より未来の日付は入力できません。' : '',
        endDate: isAfterStart ? '適用終了日は適用開始日より過去の日付は入力できません。' : '',
      };
    }
    return tempErrorMessage;
  }

  checkTargetDate = (value) => {
    const targetMonth = this.props.targetDate.getMonth();
    const targetYear = this.props.targetDate.getFullYear();
    return targetYear !== new Date(value).getFullYear()
    || targetMonth !== new Date(value).getMonth();
  }

  onTypeChange = (e) => {
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        type: e.target.value,
      },
    });
  }

  onUpdateNumberChange = (event) => {
    const { value } = event.target;
    const { info, errorMessage } = this.state;
    this.setState({
      info: {
        ...info,
        limitNumber: value,
      },
      errorMessage: {
        ...errorMessage,
        limitNumber: this.updateFrameValidationCheck(value),
      },
    });
  }

  updateFrameValidationCheck = (updateFrame) => {
    if (!updateFrame) return '入力してください';
    if (updateFrame < 0) return '0以上の数値を入力してください';
    const regex = /^([1-9]\d*|0)$/;
    if (!regex.test(updateFrame)) return '整数値を入力してください';
    return '';
  }

  onChangeTimeTextfield = (event, name) => {
    const { info, errorMessage } = this.state;
    const { value } = event.target;
    const key = name;
    let tempMessage = { ...errorMessage, [key]: this.timeValidation(value) };
    if (key === 'startTime' && value && info.endTime) {
      tempMessage = this.compareTime(tempMessage, value, info.endTime);
    }

    if (key === 'endTime' && value && info.startTime) {
      tempMessage = this.compareTime(tempMessage, info.startTime, value);
    }
    this.setState({
      info: { ...info, [key]: value },
      errorMessage: tempMessage,
    });
  }

  compareTime = (tempMessage, startTime, endTime) => {
    let errorMessage = tempMessage;
    errorMessage = {
      ...errorMessage,
      startTime: this.timeValidation(startTime),
      endTime: this.timeValidation(endTime),
    };
    if (startTime && endTime) {
      const start = Number(startTime.replace(':', ''));
      const end = Number(endTime.replace(':', ''));
      const message = start > end ? '開始時間以降の値を入力してください' : '';
      errorMessage = { ...tempMessage, endTime: message };
    }
    return errorMessage;
  }

  timeValidation = (value) => {
    if (!value) return '選択してください';
    return '';
  };

  onSave = () => {
    const { storeId } = this.props;
    const {
      info, errorMessage,
    } = this.state;
    const { startTime, endTime } = this.compareTime(errorMessage, info.startTime, info.endTime);
    const message = this.updateFrameValidationCheck(info.limitNumber);
    const { startDate } = this.checkDate(info.startDate, 'startDate');
    const { endDate } = this.checkDate(info.endDate, 'endDate');
    this.setState({
      errorMessage: {
        ...errorMessage,
        limitNumber: message,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    });
    if (message || startDate || endDate || startTime || endTime) {
      return;
    }
    const data = {
      ...info,
      storeId,
    };
    this.props.dispatch(actCallGolfBookingResetFlg());
    this.props.dispatch(apiCallUpdateBulkFrameLimit(data));
  }

  render() {
    const {
      open, onClose,
      classes,
    } = this.props;
    const {
      info, btnLoading, errorMessage, minDate, maxDate,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={onclose}>
          <DialogTitle>一括打席変更</DialogTitle>
          <DialogContent dividers>
            <Box mb={2} width="100%">
              <FormTitle title="タイプ" isRequired />
              <FormControl component="fieldset">
                <RadioGroup row value={info.type} onChange={this.onTypeChange} defaultValue="1" name="memberType">
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="シミュレーション" />
                  <FormControlLabel value="2" control={<Radio color="primary" />} label="レッスン" />
                  <FormControlLabel value="3" control={<Radio color="primary" />} label="的打ち" />
                </RadioGroup>
              </FormControl>
            </Box>
            <FormTitle title="適用日" isRequired />
            <Box mb={2} display="flex" alignItems="center">
              <Box className={classes.timeInputBox}>
                <Datepicker
                  value={info.startDate}
                  name="startDate"
                  minDate={minDate}
                  maxDate={maxDate}
                  handleChange={(value) => this.onChangeDate(value, 'startDate')}
                />
                { errorMessage.startDate
                && <FormErrorText>{errorMessage.startDate}</FormErrorText> }
              </Box>
              <Box mr={4} ml={4}>～</Box>
              <Box className={classes.timeInputBox}>
                <Datepicker
                  value={info.endDate}
                  name="endDate"
                  minDate={minDate}
                  maxDate={maxDate}
                  handleChange={(value) => this.onChangeDate(value, 'endDate')}
                />
                { errorMessage.endDate && <FormErrorText>{errorMessage.endDate}</FormErrorText> }
              </Box>
            </Box>
            <FormTitle title="適用時間" isRequired />
            <Box mb={2} display="flex" alignItems="center">
              <Box className={classes.timeInputBox}>
                <div>
                  <TextField
                    type="time"
                    className={classes.timeInput}
                    variant="outlined"
                    value={info.startTime}
                    onChange={(event) => this.onChangeTimeTextfield(event, 'startTime')}
                    error={errorMessage.startTime}
                  />
                  <FormErrorText>{errorMessage.startTime}</FormErrorText>
                </div>
              </Box>
              <Box mr={4} ml={4}>～</Box>
              <Box className={classes.timeInputBox}>
                <div>
                  <TextField
                    type="time"
                    className={classes.timeInput}
                    variant="outlined"
                    value={info.endTime}
                    onChange={(event) => this.onChangeTimeTextfield(event, 'endTime')}
                    error={errorMessage.endTime}
                  />
                  <FormErrorText>{errorMessage.endTime}</FormErrorText>
                </div>
              </Box>
            </Box>
            <Box mb={2}>
              <FormTitle title="打席数" isRequired />
              <TextField
                value={info.limitNumber}
                onChange={this.onUpdateNumberChange}
                variant="outlined"
                error={errorMessage.limitNumber !== ''}
                inputProps={{ maxLength: 9 }}
              />
              <FormErrorText>{errorMessage.limitNumber}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.onSave} label="保存" loading={btnLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  calendarScheduleDetail: state.calendarScheduleDetail,
  calendarDetailGolf: state.calendarDetailGolf,
  common: state.common,
});
export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(EventCalendarBulkChangeModal)),
);
