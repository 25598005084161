import { ACTION_TYPE } from './action';

// #region Reducer
const users = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        isError: false,
      };
    case ACTION_TYPE.LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isError: true,
      };
    case ACTION_TYPE.LOGIN_DATA_RESET:
      return {
        ...state,
        token: null,
        isError: null,
      };
    default:
      return state;
  }
};

export default users;
