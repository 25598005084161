import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actCallApiSubscriberListSuccess,
  actInitSubscriberListSearchSuccess,
  actCallApiUpdateSubscriberStatusSuccess,
  actCallApiUpdateSubscriberStatusAcceptedSuccess,
  actCallApiSubscriberDetailSuccess,
  actCallApiSubscriberCancelSuccess,
  actCallApiGetChangeStatusSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import {
  getDatas,
  updateStatus,
  getDetailData,
  transferCancel,
  getStatus,
} from '../../../services/booking/subscriberList.service';
import {
  getSelects,
} from '../../../services/booking/bookingList.service';

/**
 * 初期表示
 */
function* callApiInitSubscriberSearchList() {
  try {
    const selects = yield call(getSelects);
    yield put(actInitSubscriberListSearchSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠データ取得
 */
function* callApiSubscriberList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiSubscriberListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メンバーステータス更新
 */
function* callApiUpdateSubscriberStatus(params) {
  try {
    const datas = yield call(updateStatus, params.id, params.searchCondition);
    yield put(actCallApiUpdateSubscriberStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メンバーステータスを受付済み更新
 */
function* callApiUpdateSubscriberStatusAccepted(params) {
  try {
    const datas = yield call(updateStatus, params.id, params.searchCondition);
    yield put(actCallApiUpdateSubscriberStatusAcceptedSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callSubscriberDetail(param) {
  try {
    const data = yield call(getDetailData, param.searchCondition);
    yield put(actCallApiSubscriberDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callSubscriberCancel(param) {
  try {
    yield call(transferCancel, param.id);
    yield put(actCallApiSubscriberCancelSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApigetStatus({ param }) {
  try {
    const datas = yield call(getStatus, param);
    yield put(actCallApiGetChangeStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSubscriberList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SUBSCRIBER_SEARCH_LIST,
    callApiInitSubscriberSearchList);
  yield takeLatest(ACTION_TYPE.CALL_API_SUBSCRIBER_LIST, callApiSubscriberList);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS, callApiUpdateSubscriberStatus);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED,
    callApiUpdateSubscriberStatusAccepted);
  yield takeLatest(ACTION_TYPE.CALL_API_SUBSCRIBER_DETAIL, callSubscriberDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_SUBSCRIBER_CANCEL, callSubscriberCancel);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_CHANGE_STATUS, callApigetStatus);
}
