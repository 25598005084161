import React from 'react';
import {
  TextField, Box, Button, Typography, withStyles,
} from '@material-ui/core';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { getOnlineCultureDatas, compareTime, checkValidation } from './rule-list-func';
import Validation from './validation';

const styles = () => ({
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class OnlineCultureRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: getOnlineCultureDatas(),
      data: getOnlineCultureDatas(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 5) {
        this.setData(this.props.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ data: data || getOnlineCultureDatas(), messages: getOnlineCultureDatas() });
  }

  onChange = (event) => {
    const { data, messages } = this.state;
    const { value, name } = event.target;
    let tempMessage = { ...messages, [name]: Validation.formValidate(name, value) };

    if (name === 'startTime' && value && data.endTime) {
      tempMessage = compareTime(tempMessage, value, data.endTime);
    }
    if (name === 'endTime' && value && data.startTime) {
      tempMessage = compareTime(tempMessage, data.startTime, value);
    }
    this.setState({
      data: { ...data, [name]: value },
      messages: tempMessage,
    });
  }

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: checked,
      },
    });
  }

  onSave = (event) => {
    const { data, messages } = this.state;
    const errObj = checkValidation(data, messages);
    this.setState({ messages: errObj.messages });
    if (errObj.isError) return;
    this.props.onSave(event, data);
  }

  render() {
    const { value, classes } = this.props;

    const { data, messages } = this.state;

    return (
      <TabPanel value={value} index={5}>
        <Box p={2}>
          <Box textAlign="right">
            <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約カレンダー表示開始時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="startTime"
                value={data.startTime}
                onChange={this.onChange}
                error={messages.startTime !== ''}
              />
              <FormErrorText>{messages.startTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約カレンダー表示終了時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="endTime"
                value={data.endTime}
                onChange={this.onChange}
                error={messages.endTime !== ''}
              />
              <FormErrorText>{messages.endTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <Box display="flex">
              <Box m={1}>
                <FormTitle title="予約受付締切日" isRequired />
                <TextField
                  variant="outlined"
                  name="closeDay"
                  value={data.closeDay}
                  onChange={this.onChange}
                  error={messages.closeDay !== ''}
                  inputProps={{ maxLength: 2 }}
                />
                <Typography className={classes.ruleLabel} component="span">日前</Typography>
                <FormErrorText>{messages.closeDay}</FormErrorText>
              </Box>
            </Box>
            <Box display="flex">
              <Box m={1}>
                <FormTitle title="キャンセル可能期限日" isRequired />
                <TextField
                  variant="outlined"
                  name="cancelDay"
                  value={data.cancelDay}
                  onChange={this.onChange}
                  error={messages.cancelDay !== ''}
                  inputProps={{ maxLength: 2 }}
                />
                <Typography className={classes.ruleLabel} component="span">日前</Typography>
                <FormErrorText>{messages.cancelDay}</FormErrorText>
              </Box>
            </Box>
            <Box display="flex">
              <Box m={1}>
                <FormTitle title="会員入室可能時間" isRequired />
                <Box fontSize={15}>講義開始</Box>
                <TextField
                  variant="outlined"
                  name="memberPossibleEnterNumber"
                  style={{ width: '50%' }}
                  value={data.memberPossibleEnterNumber}
                  onChange={this.onChange}
                  error={messages.memberPossibleEnterNumber !== ''}
                  inputProps={{ maxLength: 2 }}
                />
                <Typography className={classes.ruleLabel} component="span">分前から入室可能</Typography>
                <FormErrorText>{messages.memberPossibleEnterNumber}</FormErrorText>
              </Box>
            </Box>
            <Box display="flex">
              <Box m={1}>
                <FormTitle title="先生入室可能時間" isRequired />
                <Box fontSize={15}>講義開始</Box>
                <TextField
                  variant="outlined"
                  name="teacherPossibleEnterNumber"
                  style={{ width: '50%' }}
                  value={data.teacherPossibleEnterNumber}
                  onChange={this.onChange}
                  error={messages.teacherPossibleEnterNumber !== ''}
                  inputProps={{ maxLength: 2 }}
                />
                <Typography className={classes.ruleLabel} component="span">分前から入室可能</Typography>
                <FormErrorText>{messages.teacherPossibleEnterNumber}</FormErrorText>
              </Box>
            </Box>
            <Box mt={1}>
              <FormTitle title="予約ルール" />
              <TextField
                id="memberRule"
                name="memberRule"
                multiline
                fullWidth
                rows={25}
                variant="outlined"
                value={data.memberRule}
                onChange={this.onChange}
                inputProps={{
                  maxLength: 30000,
                }}
              />
            </Box>
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default withStyles(styles)(OnlineCultureRules);
