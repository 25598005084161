import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip,
  IconButton, Button, withStyles, Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { getComparator, stableSort, getSelected } from '../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class MemberLinkageDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'memberNo', numeric: false, label: '会員番号' },
      { id: 'name', numeric: false, label: '名前' },
      { id: 'phone', numeric: false, label: '電話番号' },
      { id: 'phoneTwo', numeric: false, label: '電話番号2' },
      { id: 'email', numeric: false, label: 'メール' },
    ];

    if (props.statusFlg) {
      this.headCells.push(
        { id: 'memberStatusName', numeric: false, label: 'ステータス' },
      );
    }

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      deleteConfopen: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberLinkage.isInsertSuccess !== this.props.memberLinkage.isInsertSuccess
      || prevProps.memberLinkage.insertDatas !== this.props.memberLinkage.insertDatas) {
      if (this.props.memberLinkage.isInsertSuccess) {
        this.setSelected();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.memberLinkage.isSearchSuccess !== this.props.memberLinkage.isSearchSuccess
      || prevProps.memberLinkage.members !== this.props.memberLinkage.members) {
      if (this.props.memberLinkage.isSearchSuccess) {
        this.setSelected();
      }
    }
  }

  setChangeLoading(loading) {
    this.setState({ loading });
  }

  setSelected = () => {
    this.setState({ selected: [] });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
    if (this.props.dialogFlg) {
      this.props.onChecked(selectedData);
    }
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
    if (this.props.dialogFlg) {
      this.props.onChecked(newSelected);
    }
  };

  handleHeaderClose = () => {
    const selectedData = [];
    this.setState({ selected: selectedData });
    if (this.props.dialogFlg) {
      this.props.onChecked(selectedData);
    }
  }

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  onPreDelete = () => {
    this.setState({ deleteConfopen: true });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: true });
    this.props.onDelete(selected);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
      deleteButtonLabel,
      addButtonLabel,
      onAdd,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      deleteDialogTitle,
      deleteDialogContent,
      dialogFlg,
      capacity,
      firstFuncText,
      onFirstFunc,
      secondFuncText,
      onSecondFunc,
      statusFlg,
    } = this.props;

    const {
      order, orderBy, selected, deleteConfopen, loading,
    } = this.state;

    return (
      <div>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : ''}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 ? (
              <Tooltip title={deleteButtonLabel}>
                <span>
                  <Button color="secondary" onClick={this.onPreDelete} disabled={dialogFlg}>{deleteButtonLabel}</Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={addButtonLabel}>
                <span>
                  <Button color="primary" onClick={onAdd} disabled={dialogFlg || capacity === rows.length}>{capacity !== rows.length && addButtonLabel}</Button>
                </span>
              </Tooltip>
            )}
          </Toolbar>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && rows.length === selected.length}
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.length < 1}
                    />
                  </TableCell>
                  {this.headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding="default"
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={this.createSortHandler(headCell.id)}
                        className={classes.tableLabel}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  { (firstFuncText || secondFuncText) && (<TableCell key="func-cell" />)}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell>
                          {row.memberNo}
                        </TableCell>
                        <TableCell>
                          <Box>
                            {row.kanaName}
                          </Box>
                          <Box>
                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {row.phone}
                        </TableCell>
                        <TableCell>
                          {row.phoneTwo}
                        </TableCell>
                        <TableCell>
                          {row.email}
                        </TableCell>
                        { statusFlg && (
                        <TableCell>
                          {row.memberStatusName}
                        </TableCell>
                        )}
                        { (firstFuncText || secondFuncText) && (
                        <TableCell>
                          {firstFuncText && <Button color="primary" onClick={(event) => onFirstFunc(event, row)}>{firstFuncText}</Button>}
                          {secondFuncText && <Button color="primary" onClick={(event) => onSecondFunc(event, row)}>{secondFuncText}</Button>}
                        </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.emptyTableCell}
                        colSpan={statusFlg ? this.headCells.length + 1 : this.headCells.length + 2}
                      >
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <ConfirmDialog
            open={deleteConfopen}
            handleClose={this.handleClose}
            executeText="削除する"
            handleExecute={this.onDelete}
            title={deleteDialogTitle}
            contentText={deleteDialogContent}
            loading={loading}
            buttonColor="secondary"
          />
        </Paper>
      </div>
    );
  }
}

MemberLinkageDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  deleteButtonLabel: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  deleteDialogTitle: PropTypes.string.isRequired,
  deleteDialogContent: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  firstFuncText: PropTypes.string,
  onFirstFunc: PropTypes.func,
  secondFuncText: PropTypes.string,
  onSecondFunc: PropTypes.func,
  statusFlg: PropTypes.bool,
};

MemberLinkageDataTable.defaultProps = {
  totalElements: 0,
  onAdd: null,
  onDelete: null,
  firstFuncText: null,
  onFirstFunc: null,
  secondFuncText: null,
  onSecondFunc: null,
  statusFlg: false,
};
const mapStateToProps = (state) => ({
  memberLinkage: state.memberLinkage,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberLinkageDataTable)));
