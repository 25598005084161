import React from 'react';
import {
  Button, FormControl, RadioGroup, FormControlLabel, Radio,
  Dialog,
  DialogContent,
  DialogActions, Box,
  DialogContentText,
} from '@material-ui/core';
import { format, isValid } from 'date-fns';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import DatePicker from '../../../components/atoms/datepicker/Datepicker';
import RequiredChip from '../../../components/atoms/requiredChip/RequiredChip';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';

export default function WithdrawalDialog(props) {
  const [type, setType] = React.useState('0');
  const [date, setDate] = React.useState(format(new Date(), 'yyyy/MM/dd'));
  const [error, setError] = React.useState('');
  const {
    open,
    handleClose,
    memberType,
    handleExecute,
  } = props;

  const onMemberChange = (e) => {
    setType(e.target.value);
  };

  const dateValidation = (value) => {
    if (!value) return '入力してください';
    if (!isValid(new Date(value))) return '無効な日付です';
    return '';
  };

  const onHandleExecute = () => {
    const errorMsg = dateValidation(date);
    if (errorMsg) {
      setError(errorMsg);
      return;
    }
    handleExecute(type, date);
  };

  const onDateChange = (value) => {
    setDate(value);
    setError(dateValidation(value));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle>退会処理</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>退会タイプを選択してください</DialogContentText>
        <Box my={2}>
          <FormControl component="fieldset">
            <RadioGroup row value={type} onChange={onMemberChange} defaultValue="0" name="type">
              <FormControlLabel value="0" control={<Radio color="primary" />} label="完全退会" />
              {memberType !== '0' && (<FormControlLabel value="1" control={<Radio color="primary" />} label="ビジター会員に変更して退会" />)}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mb={3}>
          <Box fontWeight="fontWeightBold" fontSize={15} mb={1}>
            退会日
            <RequiredChip />
          </Box>
          <DatePicker
            handleChange={(_, value) => onDateChange(value)}
            value={date}
            error={error !== ''}
          />
          <FormErrorText>{error}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <LoadingButton
          onClick={onHandleExecute}
          label="退会画面へ"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
}
