import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Container,
} from '@material-ui/core';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import { actCallApiSelectList } from '../../../../redux/share/weekScheduleMenu/action';
import {
  actCallApiCalendarScheduleFlgReset,
} from '../../../../redux/share/calendarScheduleDetail/action';
import { actCallApiCalendarHeaderRoom, actCallApiCalendarRoomDetail } from '../../../../redux/event/eventCalendarDetailRoom/action';
import urls from '../../../../constants/urls';
import CalendarSchedulePublishDialog from '../../../share/calendarScheduleDetail/CalendarSchedulePublishDialog';
import EventCalendarRoomTable from './EventCalendarRoomTable';
import BookingHeader from '../../../share/calendarScheduleDetail/BookingHeader';

const styles = () => (
  {
    root: {
      paddingTop: '60px',
      paddingBottom: '40px',
    },
    headerUpdateBtn: {
      borderTop: '1px solid #eeeeee',
      paddingTop: '10px',
    },
    paper: {
      width: 'fit-content',
      marginBottom: '20px',
    },
  });

class EventCalendarDetailRoom extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.EVENT.EVENT_CALENDARS,
        name: '予約カレンダー作成',
      },
      {
        name: '予約カレンダー詳細',
      },
    ];
    this.scrollToTop();
    let headerId = null;
    let storeId = null;
    let contentId = null;
    let isRelationMain = false;
    let selectedDate = null;

    if (props.calendarScheduleDetail.header) {
      const { header, isMain, targetDate } = props.calendarScheduleDetail.header;
      headerId = header.id;
      storeId = header.storeId;
      contentId = header.contentId;
      isRelationMain = isMain;
      selectedDate = targetDate;
    }

    if (props.location.state) {
      headerId = props.location.state.headerId;
      storeId = props.location.state.storeId;
      contentId = props.location.state.contentId;
      isRelationMain = props.location.state.isMain;
      selectedDate = props.location.state.targetDate;
    }

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.calendarScheduleDetail.isCreated) {
      successSnackOpen = true;
      successSnackMessage = '予約カレンダーの作成が完了しました';
    }
    this.props.dispatch(actCallApiCalendarScheduleFlgReset());

    if (!headerId) {
      props.history.push({
        pathname: urls.OTHER.HOME,
      });
      return;
    }
    if (headerId) {
      this.props.dispatch(actCallApiSelectList(contentId, storeId));
      this.props.dispatch(actCallApiCalendarHeaderRoom({ headerId }));
      this.props.dispatch(
        actCallApiCalendarRoomDetail({
          storeId,
          targetedOn: selectedDate,
        }),
      );
    }

    this.state = {
      header: {
        id: headerId,
        storeId,
        storeName: '',
        contentId,
        contentName: '',
        targetedOn: null,
        publishedOn: '',
      },
      selectedDate,
      isMain: isRelationMain,
      headerDialogOpen: false,
      successSnackOpen,
      successSnackMessage,
    };
  }

  componentDidMount() {
    document.title = '予約カレンダー詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarDetailRoom.headerDatas !== this.props.calendarDetailRoom.headerDatas) {
      if (this.props.calendarDetailRoom.headerDatas) {
        this.setData(this.props.calendarDetailRoom.headerDatas);
      }
    }
    if (prevProps.calendarScheduleDetail.isUpdated
      !== this.props.calendarScheduleDetail.isUpdated) {
      if (this.props.calendarScheduleDetail.isUpdated) {
        this.success('公開日の変更が完了しました');
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  setSuccessMessage = (message) => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: message,
      headerDialogOpen: false,
    });
  }

  setData = (header) => {
    this.setState({ header });
  }

  success = (message) => {
    const { header } = this.state;
    this.props.dispatch(actCallApiCalendarHeaderRoom({ headerId: header.id }));
    this.setSuccessMessage(message);
  }

  handleScheduleHeaderModalOpen = () => {
    this.setState({ headerDialogOpen: true, successSnackOpen: false });
  }

  handleScheduleHeaderModalClose = () => {
    this.setState({ headerDialogOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  render() {
    const { classes } = this.props;
    const {
      isMain, header,
      successSnackOpen, successSnackMessage, headerDialogOpen,
      selectedDate,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <BookingHeader
            header={header}
            isMain={isMain}
            handleOpen={this.handleScheduleHeaderModalOpen}
            contentId={header.contentId}
          />

          <EventCalendarRoomTable
            header={header}
            isMain={isMain}
            selectedDate={selectedDate}
            success={this.setSuccessMessage}
            onMessageClose={this.handleSuccessSnackbarClose}
          />

          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />

          <CalendarSchedulePublishDialog
            open={headerDialogOpen}
            onClose={this.handleScheduleHeaderModalClose}
            headerId={header.id}
            contentId={header.contentId}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarScheduleDetail: state.calendarScheduleDetail,
  calendarDetailRoom: state.calendarDetailRoom,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventCalendarDetailRoom)));
