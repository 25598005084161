export default function getCsvItem() {
  return [
    { label: '会員名', value: 'memberName' },
    { label: '会員グレード', value: 'memberGrade' },
    { label: 'フリガナ', value: 'memberNameKana' },
    { label: '年齢', value: 'age' },
    { label: '所属支部', value: 'className' },
    { label: '電話番号', value: 'phone' },
  ];
}
