import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog,
  Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, TextField, Button, LinearProgress, Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { endOfMonth } from 'date-fns';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import SearchMultiSelectBox from '../../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import { compareDateCheck } from '../room-schedule-proc';
import Validation from './validation';
import RoomMemberAddDialog from './RoomMemberAddDialog';
import RoomSchedulDuplicateDialog from './RoomSchedulDuplicateDialog';
import {
  actApiRoomScheduleFlgReset,
  actApiRoomScheduleUpsert,
  actCallApiGetRoomScheduleData,
} from '../../../../redux/booking/roomSchedule/action';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
  flexButton: {
    flex: '1 1 50%',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const obj = {
  data: {
    id: '',
    menuName: '',
    startTime: '',
    endTime: '',
    rooms: [],
    memo: '',
    member: null,
  },
  roomList: [],
  message: {
    menuName: '',
    startTime: '',
    endTime: '',
    rooms: '',
    memo: '',
    member: '',
  },
  existData: [],
  loading: false,
  changeLoading: false,
  memberSearchDialogOpen: false,
  duplicateDialogOpen: false,
  deleteConfirmOpen: false,
};

class RoomScheduleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = obj;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
        if (this.props.id) {
          this.search(this.props.id);
        }
      }
    }
    if (prevProps.roomSchedule.data !== this.props.roomSchedule.data) {
      if (this.props.roomSchedule.data) {
        this.setdata(this.props.roomSchedule.data);
      }
    }

    if (prevProps.roomSchedule.isUpsertSuccessFlg !== this.props.roomSchedule.isUpsertSuccessFlg) {
      if (this.props.roomSchedule.isUpsertSuccessFlg) {
        this.checkExist(this.props.roomSchedule.existData);
      }
    }

    if (prevProps.roomSchedule.scheduleData !== this.props.roomSchedule.scheduleData) {
      this.setScheduleData(this.props.roomSchedule.scheduleData);
    }
  }

  init = () => {
    const { datas } = this.props.roomSchedule;

    this.setState({
      ...obj,
      roomList: datas.rooms,
    });

    this.props.dispatch(actApiRoomScheduleFlgReset());
  }

  setdata = (data) => {
    let tempData = data;

    if (!tempData.menuName) {
      tempData = { ...tempData, menuName: null };
    }

    if (!tempData.memo) {
      tempData = { ...tempData, memo: null };
    }

    this.setState({
      data: tempData,
      loading: false,
    });
  }

  setScheduleData = (scheduleData) => {
    this.setState({
      loading: false,
      data: {
        id: scheduleData.id,
        menuName: scheduleData.menuName,
        startTime: scheduleData.startTime,
        endTime: scheduleData.endTime,
        rooms: scheduleData.rooms,
        memo: scheduleData.memo,
        member: scheduleData.member,
      },
    });
  }

  checkExist = (existData) => {
    this.setState({
      changeLoading: false,
    });

    if (existData) {
      this.setState({
        duplicateDialogOpen: true,
        existData,
      });
    } else {
      this.props.onClose();
    }
  }

  onChangeTimeTextfield = (event, name) => {
    const { data, message } = this.state;
    const { value } = event.target;
    const key = name;
    const tempMessage = { ...message, [key]: Validation.formValidate(key, value) };
    this.setState({
      data: { ...data, [key]: value },
      message: tempMessage,
    });

    if (key === 'startTime' && value && data.endTime) {
      this.compareTime(tempMessage, value, data.endTime);
    }

    if (key === 'endTime' && value && data.startTime) {
      this.compareTime(tempMessage, data.startTime, value);
    }
  }

  onChangeSelectBox = (event) => {
    const { value, name } = event.target;
    const { data, message } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  compareTime = (tempMessage, startTime, endTime) => {
    const start = Number(startTime.replace(':', ''));
    const end = Number(endTime.replace(':', ''));
    this.setState({
      message: { ...tempMessage, endTime: Validation.compareTime(start, end) },
    });
    return { ...tempMessage, endTime: Validation.compareTime(start, end) };
  }

  search = (id) => {
    this.setState({ loading: true });
    this.props.dispatch(
      actCallApiGetRoomScheduleData(id),
    );
  }

  handleOpenMemberSearch = () => {
    this.setState({ memberSearchDialogOpen: true });
  }

  handleCloseMemberSearch = () => {
    this.setState({ memberSearchDialogOpen: false });
  }

  handleClickSelectUser = (row) => {
    const { data, message } = this.state;
    this.setState({
      data: {
        ...data,
        member: row,
      },
      memberSearchDialogOpen: false,
      message: { ...message, member: Validation.formValidate('member', row) },
    });
  }

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { data, message } = this.state;
    this.setState({
      data: { ...data, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
    });
  }

  handleSave = () => {
    const { data, message } = this.state;
    const { id, targetStore, targetDate } = this.props;

    let tempMessage = message;
    let msg = '';
    let isError = false;

    Object.keys(data).map(
      (key) => {
        msg = Validation.formValidate(key, data[key]);
        if (msg) {
          isError = true;
        }
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        return msg;
      },
    );
    this.setState({ message: tempMessage, changeLoading: !isError });

    if (isError) {
      return;
    }

    const params = {
      id,
      menuName: data.menuName,
      startTime: data.startTime,
      endTime: data.endTime,
      rooms: data.rooms.map((room) => room.id),
      memo: data.memo,
      memberId: data.member && data.member.id,
      storeId: targetStore.id,
      targetDate,
    };

    this.props.dispatch(actApiRoomScheduleFlgReset());
    this.props.dispatch(actApiRoomScheduleUpsert(params));
  }

  onClickMemberDelete = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        member: null,
      },
    });
  }

  handleCloseDulicateDialog = () => {
    this.setState({ duplicateDialogOpen: false });
  }

  render() {
    const {
      classes, id, open, onClose, targetDate, targetStore,
    } = this.props;

    const {
      data, message, loading,
      changeLoading, existData,
      roomList, memberSearchDialogOpen, duplicateDialogOpen,
    } = this.state;

    let targetedDate = new Date();
    if (targetDate) { targetedDate = new Date(targetDate); }
    this.minDate = compareDateCheck(targetedDate, new Date()) ? new Date() : targetedDate;
    this.maxDate = endOfMonth(targetedDate);

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { id ? '貸し会議室スケジュールを変更' : '貸し会議室スケジュールを追加'}
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <Box mt={3}>
              <FormTitle title="メニュー名" isRequired />
              <TextField
                name="menuName"
                fullWidth
                value={data.menuName}
                variant="outlined"
                className={classes.menuInput}
                onChange={this.handleChange}
                error={message.menuName.length > 0}
                inputProps={{ maxLength: 80 }}
              />
              <FormErrorText>{message.menuName}</FormErrorText>
            </Box>
            <Box mt={3} display="flex">
              <Box className={classes.timeInputBox}>
                <FormTitle title="開始時間" isRequired />
                <div>
                  <TextField
                    type="time"
                    className={classes.timeInput}
                    variant="outlined"
                    value={data.startTime}
                    onChange={(event) => this.onChangeTimeTextfield(event, 'startTime')}
                    error={message.startTime.length > 0}
                  />
                  <FormErrorText>{message.startTime}</FormErrorText>
                </div>
              </Box>
              <Box ml={3} className={classes.timeInputBox}>
                <FormTitle title="終了時間" isRequired />
                <div>
                  <TextField
                    type="time"
                    className={classes.timeInput}
                    variant="outlined"
                    value={data.endTime}
                    onChange={(event) => this.onChangeTimeTextfield(event, 'endTime')}
                    error={message.endTime.length > 0}
                  />
                  <FormErrorText>{message.endTime}</FormErrorText>
                </div>
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="Room" isRequired />
              <SearchMultiSelectBox
                name="rooms"
                value={data.rooms}
                options={roomList}
                onChange={this.onChangeSelectBox}
                error={message.rooms.length > 0}
              />
              <FormErrorText>{message.rooms}</FormErrorText>
            </Box>
            <Box mt={3}>
              <FormTitle title="内部メモ" />
              <TextField
                name="memo"
                multiline
                fullWidth
                rows={3}
                variant="outlined"
                value={data.memo}
                onChange={this.handleChange}
                inputProps={{
                  maxLength: 30000,
                }}
                error={message.memo.length > 0}
              />
              <FormErrorText>{message.memo}</FormErrorText>
            </Box>
            <Box mt={3}>
              <FormTitle title="会員" />
              <Box display="">
                <Box>
                  <Button variant="contained" color="primary" size="small" onClick={() => this.handleOpenMemberSearch(true)}>
                    会員を検索する
                  </Button>
                </Box>
                {
                  data.member && data.member.id && (
                  <Box display="flex" mt={1.5}>
                    <Box>
                      <Box>
                        {data.member.memberNo}
                      </Box>
                      <Box>
                        {data.member.name}
                      </Box>
                    </Box>
                    <Box>
                      <Tooltip title="削除">
                        <IconButton aria-label="delete" onClick={this.onClickMemberDelete}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  )
                }
                <FormErrorText>{message.member}</FormErrorText>
              </Box>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions style={{ display: loading ? 'none' : '' }}>
          <LoadingButton loading={changeLoading} onClick={this.handleSave} label="保存する" />
        </DialogActions>
        <RoomMemberAddDialog
          open={memberSearchDialogOpen}
          onClose={this.handleCloseMemberSearch}
          targetStore={targetStore}
          handleClickSelectUser={this.handleClickSelectUser}
        />
        <RoomSchedulDuplicateDialog
          open={duplicateDialogOpen}
          handleClose={this.handleCloseDulicateDialog}
          title="貸し会議室スケジュールを追加"
          contentData={existData}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  roomSchedule: state.roomSchedule,
});

export default withStyles(styles)(connect(mapStateToProps)(RoomScheduleDialog));

RoomScheduleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  targetDate: PropTypes.string.isRequired,
  targetStore: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
};

RoomScheduleDialog.defaultProps = {
  id: null,
};
