import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年MMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'MMM', { locale: this.locale });
  }
}

export default function Monthpicker(props) {
  const {
    value,
    handleChange,
    label,
    minDate,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        size="medium"
        openTo="month"
        views={['year', 'month']}
        inputVariant="outlined"
        format="yyyy/MM"
        autoComplete="off"
        id="date-picker-inline"
        value={value}
        minDate={minDate}
        onChange={handleChange}
        label={label}
        autoOk
        cancelLabel="キャンセル"
        okLabel="設定"
        clearLabel="クリア"
        todayLabel="今日"
        showTodayButton
        clearable
        disableToolbar={false}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        helperText=""
        maxDate="9999/12/31"
      />
    </MuiPickersUtilsProvider>
  );
}

Monthpicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

Monthpicker.defaultProps = {
  value: null,
};
