import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatas, updatePassword } from '../../../services/master/teacherList.service';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actCallApiInitTeacherListSuccess,
  actCallApiGetTeacherListSuccess,
  actCallApiUpdateTeacherPasswordSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗取得
 */
function* callApiInitTeacherList() {
  try {
    const stores = yield call(getStores);
    yield put(actCallApiInitTeacherListSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 先生取得
 * @param {*} param0
 */
function* callApiGetTeacherList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiGetTeacherListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * パスワード更新
 * @param {*} param0
 */
function* callApiUpdateTeacherPassword({ param }) {
  try {
    const data = yield call(updatePassword, param);
    yield put(actCallApiUpdateTeacherPasswordSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallTeacherList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_TEACHER_LIST, callApiInitTeacherList);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_TEACHER_LIST, callApiGetTeacherList);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_TEACHER_PASSWORD, callApiUpdateTeacherPassword);
}
