import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog, FormControl, RadioGroup, Radio, FormControlLabel,
  FormGroup, Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, TextField, Button, Checkbox, LinearProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import Validation from './validation';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiWeekScheduleSearch,
  actCallApiWeekScheduleSave,
  actCallApiWeekScheduleDelete,
  actCallApiWeekScheduleFlgReset,
} from '../../../redux/share/weekScheduleMenu/action';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
  flexButton: {
    flex: '1 1 50%',
  },
  timeInputBox: {
    width: '15%',
  },
  timeInput: {
    width: '90%',
    marginRight: 10,
  },
  capacityInput: {
    width: '50%',
  },
});

function setObject() {
  return {
    largeClasses: [],
    mediumClasses: [],
    smallClasses: [],
    initMediumClasses: [],
    initSmallClasses: [],
    initMenuList: [],
    menuList: [],
    data: {
      headerId: null,
      menu: null,
      largeClass: null,
      mediumClass: null,
      smallClass: null,
      week: 1,
      weeklyNumber: [],
      startTime: '',
      startTimeSelect: null,
      endTime: '',
      room: [],
      teacher: null,
      capacity: '',
      delImposibleFlg: true,
    },
    roomList: [],
    teacherList: [],
    golfTimeList: [],
    message: {
      menu: '',
      weeklyNumber: '',
      startTime: '',
      endTime: '',
      capacity: '',
    },
    loading: false,
    changeLoading: false,
    deleteConfirmOpen: false,
  };
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class WeekScheduleMenuDialog extends Component {
  constructor(props) {
    super(props);

    this.weekList = [
      { name: '月曜日', id: 1 },
      { name: '火曜日', id: 2 },
      { name: '水曜日', id: 3 },
      { name: '木曜日', id: 4 },
      { name: '金曜日', id: 5 },
      { name: '土曜日', id: 6 },
      { name: '日曜日', id: 7 },
    ];

    this.state = setObject();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
        this.setSelectList(this.props.weekScheduleMenu.selectList);
        if (this.props.id) {
          this.setLoading();
          this.props.dispatch(actCallApiWeekScheduleSearch(this.props.schoolFlg, this.props.id));
        }
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.weekScheduleMenu.data !== this.props.weekScheduleMenu.data) {
      if (this.props.weekScheduleMenu.data) {
        this.setdata(this.props.weekScheduleMenu.data);
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  init = () => {
    this.setState(setObject());
  }

  setLoading = () => {
    this.setState({ loading: true });
  }

  setSelectList = (selectLists) => {
    this.setState({
      largeClasses: selectLists.classes.largeClasses,
      initMediumClasses: selectLists.classes.mediumClasses,
      initSmallClasses: selectLists.classes.smallClasses,
      initMenuList: selectLists.menuList,
      menuList: selectLists.menuList,
      teacherList: selectLists.teacherList,
      golfTimeList: selectLists.golfTimeList,
      roomList: selectLists.roomList,
    });
  }

  setdata = (data) => {
    const { initMediumClasses, initSmallClasses, initMenuList } = this.state;
    let tempData = data;
    let menuList = initMenuList || [];
    let mediumClasses = [];
    let smallClasses = [];

    if (tempData.largeClass.id) {
      menuList = initMenuList.filter(
        (values) => values.mstLargeClassId === tempData.largeClass.id,
      );
      mediumClasses = initMediumClasses.filter(
        (values) => values.parentId === tempData.largeClass.id,
      );
    } else {
      tempData = { ...tempData, largeClass: null };
    }

    if (tempData.mediumClass.id) {
      menuList = menuList.filter(
        (values) => values.mstMediumClassId === tempData.mediumClass.id,
      );
      smallClasses = initSmallClasses.filter(
        (values) => values.parentId === tempData.mediumClass.id,
      );
    } else {
      tempData = { ...tempData, mediumClass: null };
    }

    if (tempData.smallClass.id) {
      menuList = menuList.filter((values) => values.mstSmallClassId === tempData.smallClass.id);
    } else {
      tempData = { ...tempData, smallClass: null };
    }

    this.setState({
      data: tempData,
      loading: false,
      mediumClasses,
      smallClasses,
      menuList,
    });
  }

  setDeleteConfirm = (state) => {
    this.setState({ deleteConfirmOpen: state });
  }

  onChangeLargeClasses = (event) => {
    const { value } = event.target;
    const { data, initMediumClasses, initMenuList } = this.state;
    let tempDatas = data;
    let { menu } = data;
    if (value && data.menu && data.menu.mstLargeClassId !== value.id) {
      menu = null;
    }
    tempDatas = {
      ...tempDatas,
      largeClass: value,
      mediumClass: null,
      smallClass: null,
      menu,
    };
    this.setState({
      data: tempDatas,
      mediumClasses: value
        ? initMediumClasses.filter((values) => values.parentId === value.id) : [],
      smallClasses: [],
      menuList: value
        ? initMenuList.filter((values) => values.mstLargeClassId === value.id) : initMenuList,
    });
  }

  onChangeMediumClasses = (event) => {
    const { value } = event.target;
    const { data, initSmallClasses, initMenuList } = this.state;
    let tempDatas = data;
    let menu = null;
    if (value && data.menu && data.menu.mstMediumClassId === value.id) {
      menu = data.menu;
    }

    if (!value && data.menu && data.menu.mstLargeClassId === data.largeClass.id) {
      menu = data.menu;
    }
    tempDatas = {
      ...tempDatas,
      mediumClass: value,
      smallClass: null,
      menu,
    };
    this.setState({
      data: tempDatas,
      smallClasses: value
        ? initSmallClasses.filter((values) => values.parentId === value.id) : [],
      menuList: value
        ? initMenuList.filter((valuse) => valuse.mstMediumClassId === value.id)
        : initMenuList.filter((valuse) => valuse.mstLargeClassId === data.largeClass.id),
    });
  }

  onChangeSmallClasses = (event) => {
    const { value } = event.target;
    const { data, initMenuList } = this.state;
    let tempDatas = data;
    let menu = null;
    if (value && data.menu && data.menu.mstSmallClassId === value.id) {
      menu = data.menu;
    }

    if (!value && data.menu && data.menu.mstMediumClassId === data.mediumClass.id) {
      menu = data.menu;
    }

    tempDatas = {
      ...tempDatas,
      smallClass: value,
      menu,
    };
    this.setState({
      data: tempDatas,
      menuList: value
        ? initMenuList.filter((values) => values.mstSmallClassId === value.id)
        : initMenuList.filter((values) => values.mstMediumClassId === data.mediumClass.id),
    });
  }

  onChangeRadio = (event) => {
    const { data } = this.state;
    const key = 'week';
    const value = Number(event.target.value);
    this.setState({ data: { ...data, [key]: value } });
  }

  onChangeCheckBox = (event) => {
    const { data, message } = this.state;
    const { weeklyNumber } = this.state.data;
    const key = 'weeklyNumber';
    const evnetValue = event.target.value;

    const tempCheckedValue = weeklyNumber;
    const index = tempCheckedValue.indexOf(evnetValue);
    if (index > -1) {
      tempCheckedValue.splice(index, 1);
    }
    if (index === -1) {
      tempCheckedValue.push(evnetValue);
    }
    this.setState({
      data: { ...data, [key]: tempCheckedValue.sort() },
      message: { ...message, [key]: Validation.formValidate(key, weeklyNumber) },
    });
  }

  onChangeGolfTime = (_, value) => {
    const { data, message } = this.state;
    const startTimeKey = 'startTime';
    const startTimeSelectKey = 'startTimeSelect';
    const endTimeKey = 'endTime';

    this.setState({
      data: {
        ...data,
        [startTimeSelectKey]: value,
        [startTimeKey]: value ? value.id : null,
        [endTimeKey]: value ? value.endTime : '',
      },
      message: { ...message, [startTimeKey]: Validation.formValidate(startTimeKey, value) },
    });
  }

  onChangeTimeTextfield = (event, name) => {
    const { data, message } = this.state;
    const { value } = event.target;
    const key = name;
    const tempMessage = { ...message, [key]: Validation.formValidate(key, value) };
    this.setState({
      data: { ...data, [key]: value },
      message: tempMessage,
    });

    if (key === 'startTime' && value && data.endTime) {
      this.compareTime(tempMessage, value, data.endTime);
    }

    if (key === 'endTime' && value && data.startTime) {
      this.compareTime(tempMessage, data.startTime, value);
    }
  }

  compareTime = (tempMessage, startTime, endTime) => {
    const start = Number(startTime.replace(':', ''));
    const end = Number(endTime.replace(':', ''));
    this.setState({
      message: { ...tempMessage, endTime: Validation.compareTime(start, end) },
    });
    return { ...tempMessage, endTime: Validation.compareTime(start, end) };
  }

  onChangeSelectBox = (event) => {
    const { name, value } = event.target;
    const { data, message } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  checkboxChecked = (value) => {
    const { weeklyNumber } = this.state.data;
    if (weeklyNumber.indexOf(value) > -1) {
      return true;
    }
    return false;
  }

  handleSave = () => {
    const { data, message } = this.state;
    const {
      menu, week, weeklyNumber, startTime, endTime, room, teacher, capacity,
    } = this.state.data;
    const { id, schoolFlg, headerId } = this.props;

    let tempMessage = message;
    let cnt = 0;

    Object.keys(data).map(
      (key) => {
        const msg = Validation.formValidate(key, data[key]);
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        cnt = msg ? cnt + 1 : cnt;
        return msg;
      },
    );

    if (startTime && endTime) {
      tempMessage = this.compareTime(tempMessage, startTime, endTime);
    }

    this.setState({ message: tempMessage });

    if (message.endTime || cnt > 0) {
      return null;
    }

    if (cnt === 0) {
      this.props.dispatch(actCallApiWeekScheduleFlgReset());
      this.setState({ changeLoading: true });

      const roomIds = [];
      room.map((rooms) => {
        roomIds.push(Number(rooms.id));
        return rooms.id;
      });

      this.props.dispatch(
        actCallApiWeekScheduleSave(
          schoolFlg,
          {
            id,
            headerId,
            menuId: menu && menu.id,
            week,
            weeklyNumber,
            startTime,
            endTime,
            roomIds,
            teacherId: teacher && teacher.id,
            capacity,
          },
        ),
      );
    }
    return null;
  }

  handleDelete = () => {
    const { id, schoolFlg } = this.props;
    this.props.dispatch(actCallApiWeekScheduleFlgReset());
    this.props.dispatch(actCallApiWeekScheduleDelete(schoolFlg, id));
  }

  render() {
    const {
      classes, id, onClose, open, contentId,
    } = this.props;

    const {
      largeClasses, mediumClasses, smallClasses, menuList,
      roomList, teacherList, data, golfTimeList, message, loading,
      deleteConfirmOpen, changeLoading,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { id ? 'メニューを変更' : 'メニューを追加'}
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <Box display="flex" mt={2}>
              <Box pr={2} width="30%">
                <FormTitle title="大分類" />
                <SearchSelectBox
                  name="largeClass"
                  options={largeClasses}
                  onChange={this.onChangeLargeClasses}
                  value={data.largeClass}
                />
              </Box>
              <Box pr={2} width="30%">
                <FormTitle title="中分類" />
                <SearchSelectBox
                  name="mediumClass"
                  options={mediumClasses}
                  onChange={this.onChangeMediumClasses}
                  value={data.mediumClass}
                />
              </Box>
              <Box width="30%">
                <FormTitle title="小分類" />
                <SearchSelectBox
                  name="smallClass"
                  options={smallClasses}
                  onChange={this.onChangeSmallClasses}
                  value={data.smallClass}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="メニュー" isRequired />
              <SearchSelectBox
                name="menu"
                options={menuList}
                onChange={this.onChangeSelectBox}
                value={data.menu}
                error={message.menu !== ''}
              />
              <FormErrorText>{message.menu}</FormErrorText>
            </Box>
            <Box mt={3}>
              <FormTitle title="開催曜日" isRequired />
              <FormControl component="fieldset">
                <RadioGroup row aria-label="week" name="week" defaultValue={1} value={data.week} onChange={this.onChangeRadio}>
                  {this.weekList.map((contents) => (
                    <FormControlLabel key={contents.id} value={contents.id} control={<Radio color="primary" />} label={contents.name} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormTitle title="開催週" isRequired />
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox value={1} color="primary" onChange={this.onChangeCheckBox} checked={this.checkboxChecked('1')} />}
                  label="1週目"
                />
                <FormControlLabel
                  control={<Checkbox value={2} color="primary" onChange={this.onChangeCheckBox} checked={this.checkboxChecked('2')} />}
                  label="2週目"
                />
                <FormControlLabel
                  control={<Checkbox value={3} color="primary" onChange={this.onChangeCheckBox} checked={this.checkboxChecked('3')} />}
                  label="3週目"
                />
                <FormControlLabel
                  control={<Checkbox value={4} color="primary" onChange={this.onChangeCheckBox} checked={this.checkboxChecked('4')} />}
                  label="4週目"
                />
                <FormControlLabel
                  control={<Checkbox value={5} color="primary" onChange={this.onChangeCheckBox} checked={this.checkboxChecked('5')} />}
                  label="5週目"
                />
              </FormGroup>
              <FormErrorText>{message.weeklyNumber}</FormErrorText>
            </Box>
            <Box mt={3} display="flex">
              <Box className={classes.timeInputBox}>
                <FormTitle title="開始時間" isRequired />
                { contentId === 2 && (
                  <div>
                    <Autocomplete
                      options={golfTimeList}
                      getOptionLabel={(option) => (option.name ? option.name : '')}
                      getOptionSelected={(option, valueObj) => option.id === valueObj.id}
                      renderInput={(params) => <TextField {...params} variant="outlined" error={message.startTime.length > 0} />}
                      style={{ width: '100%' }}
                      value={data.startTimeSelect}
                      onChange={this.onChangeGolfTime}
                      noOptionsText="データがありません"
                    />
                    <FormErrorText>{message.startTime}</FormErrorText>
                  </div>
                )}
                { contentId !== 2 && (
                  <div>
                    <TextField
                      type="time"
                      className={classes.timeInput}
                      variant="outlined"
                      value={data.startTime}
                      onChange={(event) => this.onChangeTimeTextfield(event, 'startTime')}
                      error={message.startTime.length > 0}
                    />
                    <FormErrorText>{message.startTime}</FormErrorText>
                  </div>
                )}
              </Box>
              <Box ml={3} className={classes.timeInputBox}>
                <FormTitle title="終了時間" isRequired />
                { contentId === 2 && (
                  <Box pt={2} my={1} fontSize={15}>{data.endTime}</Box>
                )}
                { contentId !== 2 && (
                  <div>
                    <TextField
                      type="time"
                      className={classes.timeInput}
                      variant="outlined"
                      value={data.endTime}
                      onChange={(event) => this.onChangeTimeTextfield(event, 'endTime')}
                      error={message.endTime.length > 0}
                    />
                    <FormErrorText>{message.endTime}</FormErrorText>
                  </div>
                )}
              </Box>
            </Box>

            {contentId !== 2 && (
              <Box mt={3}>
                <FormTitle title="Room" />
                <SearchMultiSelectBox
                  name="room"
                  value={data.room}
                  options={roomList}
                  onChange={this.onChangeSelectBox}
                />
              </Box>
            )}

            <Box mt={3}>
              <FormTitle title="先生" />
              <SearchSelectBox
                name="teacher"
                options={teacherList}
                value={data.teacher}
                onChange={this.onChangeSelectBox}
              />
            </Box>
            <Box mt={3}>
              <FormTitle title="定員" isRequired />
              <TextField
                value={data.capacity}
                onChange={(event) => this.onChangeTimeTextfield(event, 'capacity')}
                variant="outlined"
                className={classes.capacityInput}
                error={message.capacity.length > 0}
                inputProps={{ maxLength: 9 }}
              />
              <FormErrorText>{message.capacity}</FormErrorText>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions style={{ display: loading ? 'none' : '' }}>
          <Box display={!data.delImposibleFlg ? '' : 'none'} className={classes.flexButton}>
            <Button onClick={() => this.setDeleteConfirm(true)} color="secondary">
              削除する
            </Button>
          </Box>
          <LoadingButton loading={changeLoading} onClick={this.handleSave} label="保存する" />
        </DialogActions>
        <ConfirmDialog
          open={deleteConfirmOpen}
          handleClose={() => this.setDeleteConfirm(false)}
          executeText="削除する"
          handleExecute={this.handleDelete}
          title="メニューの削除"
          contentText="メニューを削除します。この操作は元に戻すことができません。"
          loading={changeLoading}
          buttonColor="secondary"
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  share: state.share,
  common: state.common,
  weekScheduleMenu: state.weekScheduleMenu,
});

export default withStyles(styles)(connect(mapStateToProps)(WeekScheduleMenuDialog));

WeekScheduleMenuDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  schoolFlg: PropTypes.bool,
  id: PropTypes.number,
  contentId: PropTypes.number,
  headerId: PropTypes.number,
};

WeekScheduleMenuDialog.defaultProps = {
  id: null,
  schoolFlg: false,
  contentId: null,
  headerId: null,
};
