import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, Button, withStyles,
  Box, TableSortLabel, Chip,
} from '@material-ui/core';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { getComparator, stableSort } from '../../../helpers/tableSort.helper';

const styles = () => ({
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  emptyCell: {
    width: '73px',
  },
  funcBtn: {
    width: '150px',
    'white-space': 'nowrap',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  btnCell: {
    padding: 0,
  },
  chipColor: {
    backgroundColor: 'rgba(46, 125, 50, 0.7)',
  },
});

class SubscriberListDataTable extends React.Component {
  constructor() {
    super();

    this.state = {
      order: 'asc',
      orderBy: '',
    };
  }

  getTableHeadCell(id, label) {
    const { classes } = this.props;
    const { order, orderBy } = this.state;
    return (
      <TableCell
        align="left"
        padding="default"
        sortDirection={orderBy === id ? order : false}
      >
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? order : 'asc'}
          onClick={this.createSortHandler(id)}
          className={classes.tableLabel}
        >
          {label}
          {orderBy === id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {
    const {
      order,
      orderBy,
    } = this.state;

    const {
      classes,
      selectedRow,
      rows,
      onFirstFunc,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      handleDrawerOpen,
      handleHistoryOpen,
    } = this.props;

    return (
      <div>
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {this.getTableHeadCell('targetDate', '予約日')}
                  {this.getTableHeadCell('targetTime', '時間')}
                  {this.getTableHeadCell('menuName', 'メニュー')}
                  {this.getTableHeadCell('bookingMemberNo', '会員番号')}
                  <TableCell
                    align="left"
                    padding="default"
                    sortDirection={orderBy === 'bookingMember' ? order : false}
                  >
                    <Box>
                      フリガナ
                    </Box>
                    <TableSortLabel
                      active={orderBy === 'bookingMember'}
                      direction={orderBy === 'bookingMember' ? order : 'asc'}
                      onClick={this.createSortHandler('bookingMember')}
                      className={classes.tableLabel}
                    >
                      名前
                      {orderBy === 'bookingMember' ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  {this.getTableHeadCell('statusName', 'ステータス')}
                  {this.getTableHeadCell('materialFee', '徴収費')}
                  {this.getTableHeadCell('joinCount', '受講回数')}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const acceptedFlg = row.transferAheadStatusCode
                    ? (row.transferAheadStatusCode === 1 || row.transferAheadStatusCode === 6)
                    : (row.statusCode === 1 || row.statusCode === 6);
                  const labelId = `enhanced-table-${index}`;
                  return (
                    <TableRow
                      hover
                      className="tableRow"
                      tabIndex={-1}
                      key={labelId}
                      selected={selectedRow && selectedRow.bookingCode === row.bookingCode}
                    >
                      <TableCell>{row.targetDate}</TableCell>
                      <TableCell>{row.targetTime}</TableCell>
                      <TableCell>{row.menuName}</TableCell>
                      <TableCell>{row.bookingMemberNo}</TableCell>
                      <TableCell>
                        {row.currentContractType === '0' && (
                          <Chip
                            label="ビジター"
                            color="secondary"
                            size="small"
                            classes={{
                              colorSecondary: classes.chipColor,
                            }}
                          />
                        )}
                        <Box>
                          {row.bookingMemberKana}
                        </Box>
                        {row.bookingMember}
                      </TableCell>
                      <TableCell>
                        {row.transferAheadStatusName
                          ? row.transferAheadStatusName : row.statusName}
                      </TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        {row.materialFee ? `${row.materialFee.toLocaleString()}円` : '0円'}
                      </TableCell>
                      <TableCell>{row.joinCount}</TableCell>
                      <td className="customTd">
                        <div className="customButton">
                          {!acceptedFlg && (
                            <Button
                              ml={0}
                              mr={0}
                              className={classes.funcBtn}
                              color="primary"
                              disabled={acceptedFlg}
                              onClick={() => onFirstFunc(row)}
                            >
                              受付済みにする
                            </Button>
                          )}
                          <Button color="primary" onClick={() => handleDrawerOpen(row)}>詳細を見る</Button>
                          <Button color="primary" onClick={() => handleHistoryOpen(row)}>ステータス変更履歴</Button>
                        </div>
                      </td>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={8}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

SubscriberListDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

SubscriberListDataTable.defaultProps = {
  totalElements: 0,
};

export default withStyles(styles)(SubscriberListDataTable);
