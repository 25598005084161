import React, { Component } from 'react';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import { Box, Button } from '@material-ui/core';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { actCallApiGetGolfSchedule, actApiGolfScheduleFlgReset, actGolfScheduleSetCurDate } from '../../../redux/booking/golfSchedule/action';

class GolfSearch extends Component {
  constructor(props) {
    super(props);

    let store = props.common.user ? props.common.user.store : null;
    store = props.golfSchedule.info ? props.golfSchedule.info.store : store;

    const date = props.date ? props.date : format(new Date(), 'yyyy/MM/dd');

    this.state = {
      stores: [],
      date,
      store,
      isMain: props.common.user ? props.common.user.main : false,
    };
    if (props.common.user) {
      this.search(store, date);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.golfSchedule.select !== this.props.golfSchedule.select) {
      if (this.props.golfSchedule.select) {
        this.setStores(this.props.golfSchedule.select);
      }
    }
    if (prevProps.golfSchedule.isUpsertSuccessFlg !== this.props.golfSchedule.isUpsertSuccessFlg) {
      if (this.props.golfSchedule.isUpsertSuccessFlg && !this.props.golfSchedule.isDuplicateFlg) {
        this.search(this.state.store, this.state.date);
      }
    }
    if (prevProps.golfSchedule.isDeleteSuccessFlg !== this.props.golfSchedule.isDeleteSuccessFlg) {
      if (this.props.golfSchedule.isDeleteSuccessFlg) {
        this.search(this.state.store, this.state.date);
      }
    }
  }

  setUser(user) {
    const store = this.props.golfSchedule.info ? this.props.golfSchedule.info.store : user.store;
    const curDate = this.props.date ? this.props.date : format(new Date(), 'yyyy/MM/dd');

    this.setState({ isMain: user.main, store });
    this.search(store, curDate);
  }

  setStores(select) {
    this.setState({ stores: select.stores });
  }

  onDateChange = (_, date) => {
    this.setState({ date });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onSearch = () => {
    const { date, store } = this.state;
    this.props.onResearch(store, date);
    this.search(store, date);
  }

  search(store, date) {
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGetGolfSchedule(
      { searchStoreId: store.id, searchTargetDate: date },
    ));
    this.props.dispatch(actGolfScheduleSetCurDate(date));
  }

  render() {
    const {
      stores, date, isMain, store,
    } = this.state;

    return (
      <Box display="flex">
        <Box>
          <Datepicker
            value={date}
            name="date"
            handleChange={this.onDateChange}
          />
        </Box>
        <Box display={isMain ? '' : 'none'} ml={2} style={{ width: '50%' }}>
          <SearchSelectBox options={stores} value={store} onChange={this.onChange} name="store" />
        </Box>
        <Box ml={2} alignItems="center" display="flex"><Button variant="contained" color="primary" onClick={this.onSearch}>検索</Button></Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, golfSchedule: state.golfSchedule });

export default connect(mapStateToProps)(GolfSearch);
