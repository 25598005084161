import React, { Component } from 'react';
import {
  Box, withStyles, Divider, Dialog, DialogContent,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import { QrReader } from 'react-qr-reader';
import connect from 'react-redux/es/connect/connect';
import Sound from 'react-sound';
import phoneLogo from '../../../public/assets/phoneLogo.svg';
import success from '../../../public/assets/success.mp3';
import error from '../../../public/assets/error.mp3';
import { actCallApiSearchRooms, actCallApiAttend, actisUpdateReset } from '../../../redux/info/classInfomation/aciton';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
  elcLogo: {
    width: '45%',
  },
  marginTop: {
    marginTop: 5,
  },
  alert: {
    margin: '0 auto',
    justifyContent: 'center',
    fontSize: '30px',
  },
  tableContainer: {
    maxHeight: '400px',
    transform: 'translateZ(0)',
  },
  text: {
    position: 'relative',
    top: '10px',
    left: '10px',
    zIndex: '-1',
  },
  center: {
    textAlign: 'center',
  },
  largeFont: {
    fontSize: 30,
  },
  font: {
    fontSize: 20,
  },
});

class RoomInfoDetail extends Component {
  constructor(props) {
    super(props);

    const storeId = props.common.user.store.id;
    const {
      searchRooms, date, ids,
    } = props.location.state;

    this.props.dispatch(actCallApiSearchRooms(ids, date, storeId));

    const day = format(new Date(date), 'yyyy年MM月dd日');

    const today = new Date();
    this.isSendApi = false;
    this.state = {
      searchRooms,
      date,
      day,
      today,
      isAttend: true,
      dialogOpen: false,
      storeId,
      datas: [],
      playing: 'STOPPED',
      music: success,
    };
    this.intervalId = setInterval(() => {
      this.update();
    }, 120000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classInfomation.datas !== this.props.classInfomation.datas) {
      if (this.props.classInfomation.datas) {
        this.setDatas(this.props.classInfomation.datas);
      }
    }
    if (prevProps.classInfomation.isUpdate !== this.props.classInfomation.isUpdate) {
      if (this.props.classInfomation.isUpdate) {
        this.setIsAttend(this.props.classInfomation.isAttend);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setDatas(datas) {
    const { date } = this.state;
    const today = format(new Date(), 'yyyy/MM/dd HH:mm');

    const result = datas.map((data) => {
      const param = data;
      const startTime = format(new Date(new Date(`${date} ${param.start}`) - 600000), 'yyyy/MM/dd HH:mm');
      const endTime = format(new Date(`${date} ${param.end}`), 'yyyy/MM/dd HH:mm');

      param.isReception = false;
      if (startTime <= today && today <= endTime) {
        param.isReception = true;
      }
      return param;
    });
    this.setState({ datas: result });
  }

  setIsAttend(isAttend) {
    let music = success;
    const playing = 'PLAYING';
    const dialogOpen = true;
    let timeOut = 5000;

    if (!isAttend) {
      music = error;
      timeOut = 10000;
    }
    window.setTimeout(() => { this.setComplete(); }, timeOut);
    this.setState({
      isAttend, playing, dialogOpen, music,
    });
  }

  setComplete() {
    this.setState({ dialogOpen: false, playing: 'STOPPED' });
    this.isSendApi = false;
  }

  update = () => {
    const { date, searchRooms } = this.props.location.state;
    const { storeId } = this.state;
    const ids = [];
    searchRooms.map((room) => ids.push(room.id));
    this.props.dispatch(actCallApiSearchRooms(ids, date, storeId));
  }

  handleScan = (result) => {
    if (this.isSendApi || !result || !result.text) {
      return;
    }
    const {
      today, day, datas, date,
    } = this.state;
    const value = result.text;
    const checkDate = format(new Date(today), 'yyyy年MM月dd日');
    if (checkDate === day && value.length === 10) {
      this.isSendApi = true;
      this.props.dispatch(actisUpdateReset());
      const ids = [];
      datas.map((data) => ids.push(data.id));
      this.props.dispatch(actCallApiAttend(
        ids, value, date,
      ));
    } else {
      this.isSendApi = false;
    }
    this.setState({ dialogOpen: false });
  }

  render() {
    const { classes } = this.props;
    const {
      searchRooms, datas, isAttend, dialogOpen, music, playing,
    } = this.state;
    return (
      <div className={classes.root}>
        <Sound
          url={music}
          playStatus={playing}
        />
        <Box>
          <QrReader
            scanDelay={300}
            onResult={this.handleScan}
            videoStyle={{ width: '100%', height: 'auto' }}
            videoContainerStyle={{ paddingTop: '78%' }}
          />
        </Box>
        <Box>
          <Alert icon={<img src={phoneLogo} alt="リーダー" width="45px" height="auto" />} severity="error" className={classes.alert}>会員QRコードをカメラにかざしてください</Alert>
          <Box p={3} textAlign="center">
            <Box fontWeight="fontWeightBold" fontSize={36}>
              本日のスケジュール
            </Box>
            <Divider />
            <Divider className={classes.marginTop} />
            <Box mt={4}>
              {searchRooms.map((room) => (
                <Box key={room.id} mt={1} fontWeight="fontWeightBold" fontSize={36} style={{ wordBreak: 'break-all', background: '#000', color: '#fff' }}>
                  {room.name}
                </Box>
              ))}
            </Box>
          </Box>
          <Box display={datas.length > 0 ? '' : 'none'}>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>開始時間</TableCell>
                    <TableCell>終了時間</TableCell>
                    <TableCell>教室名</TableCell>
                    <TableCell>講師</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((room) => (
                    <TableRow key={room.id}>
                      <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                        {room.start}
                      </TableCell>
                      <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                        {room.end}
                      </TableCell>
                      <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                        {room.menuName}
                      </TableCell>
                      <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                        {room.teacher}
                      </TableCell>
                      <TableCell>
                        <Box display={room.isReception ? '' : 'none'}>
                          <Chip color="secondary" label="受付中" style={{ fontSize: '20px' }} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box display={datas.length > 0 ? 'none' : ''}>
            <Box fontWeight="fontWeightBold" fontSize={36} className={classes.center}>予定されている講義がありません</Box>
          </Box>
          <Box>
            <Box fontWeight="fontWeightBold" mt={3} fontSize={15}>＜注意事項＞</Box>
            <Box fontWeight="fontWeightBold" m={1} fontSize={15}>受講者様は受付で入館処理をお済ませください。</Box>
            <Box fontWeight="fontWeightBold" m={1} fontSize={15}>入室は教室開始10分前からとさせていただきます。</Box>
          </Box>
        </Box>
        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
          <DialogContent dividers>
            {isAttend
              ? (<Box>認証が完了しました。</Box>)
              : (<Box color="#ff0000">認証に失敗しました。恐れ入りますが、受付にお問い合わせください。</Box>)}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  classInfomation: state.classInfomation,
  common: state.common,
});
export default withStyles(styles)(connect(mapStateToProps)(RoomInfoDetail));
