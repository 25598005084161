import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableSortLabel, Paper,
  Button, withStyles, Grid, Box,
} from '@material-ui/core';
import Pagination from '../../../../components/atoms/pagination/Pagination';
import { getComparator, stableSort, getSelected } from '../../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  transferAheadChip: {
    color: '#fff',
    backgroundColor: '#666666',
  },
  transferSourceChip: {
    color: '#fff',
    backgroundColor: '#00b050',
  },
  experienceChip: {
    color: '#fff',
    backgroundColor: '#e67d05',
  },
  funcBtn: {
    width: '150px',
    'white-space': 'nowrap',
  },
});

class RoomMemberDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'memberNo', numeric: false, label: '会員番号' },
      { id: 'name', numeric: false, label: '名前' },
      { id: 'phone', numeric: false, label: '電話番号' },
      { id: 'phoneTwo', numeric: false, label: '電話番号2' },
      { id: 'email', numeric: false, label: 'メール' },
    ];

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roomSchedule.isInsertSuccess !== this.props.roomSchedule.isInsertSuccess
      || prevProps.roomSchedule.insertDatas !== this.props.roomSchedule.insertDatas) {
      if (this.props.roomSchedule.isInsertSuccess) {
        this.setSelected();
      }
    }
  }

  setSelected = () => {
    this.setState({ selected: [] });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    const { rows, regularFlg } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => !(regularFlg && (row.transferAheadDate || row.transferSourceDate)))
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
    if (this.props.dialogFlg) {
      this.props.onChecked(selectedData);
    }
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
    if (this.props.dialogFlg) {
      this.props.onChecked(newSelected);
    }
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      onFirstFunc,
    } = this.props;

    const {
      order, orderBy,
    } = this.state;

    return (
      <>
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  {this.headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={this.createSortHandler(headCell.id)}
                        className={classes.tableLabel}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {row.memberNo}
                        </TableCell>
                        <TableCell>
                          <Box>
                            {row.kanaName}
                          </Box>
                          <Box>
                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {row.phone}
                        </TableCell>
                        <TableCell>
                          {row.phoneTwo}
                        </TableCell>
                        <TableCell>
                          {row.email}
                        </TableCell>
                        <TableCell>
                          <Grid container spacing={0} wrap="nowrap">
                            <Grid item xs>
                              <Button ml={0} mr={0} className={classes.funcBtn} color="primary" onClick={(event) => onFirstFunc(event, row)}>選択</Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.emptyTableCell}
                        colSpan={this.headCells.length + 3}
                      >
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}

RoomMemberDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onFirstFunc: PropTypes.func,
};

RoomMemberDataTable.defaultProps = {
  totalElements: 0,
  onFirstFunc: null,
};
const mapStateToProps = (state) => ({
  roomSchedule: state.roomSchedule,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RoomMemberDataTable)));
