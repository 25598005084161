import React from 'react';
import {
  makeStyles, Box,
} from '@material-ui/core';
import { getMonthly, getSpecificDateDatas, getPublishDate } from './calendar-schedule-detail-proc';
import CalendarBookingCard from './CalendarBookingCard';

const useStyles = makeStyles(() => ({
  calendarTable: {
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
    textAlign: 'center',
    tableLayout: 'fixed',
  },
  calendarTd: {
    border: 'solid 1px #DDD',
    width: '14.2%',
    verticalAlign: 'top',
    padding: '10px',
    fontSize: 'x-large',
  },
  calendarCellTd: {
    border: 'solid 1px #DDD',
    width: '14.2%',
    verticalAlign: 'top',
    fontSize: 'large',
    background: '#fff',
    padding: '10px 0 10px 0',
  },
}));

export default function CalendarScheduleCalendarTable(props) {
  const classes = useStyles();

  const {
    header,
    bookings,
    handleMenuDialogOpen,
    onBookingDetailLinkage,
  } = props;

  const dayElement = (item) => {
    const dayDatas = getSpecificDateDatas(item.date, bookings);
    return (
      <div>
        {dayDatas.map((data) => (
          <CalendarBookingCard menuInfo={data} onMenuUpdateDialog={handleMenuDialogOpen} onBookingDetailLinkage={onBookingDetailLinkage} key={`${data.id}month`} />
        ))}
      </div>
    );
  };

  let monthly = [];
  if (header.targetedOn) {
    monthly = getMonthly(header.targetedOn);
  }

  return (
    <Box mt={2} mx={1}>
      <table width="100%" className={classes.calendarTable}>
        <thead>
          <tr>
            <th className={classes.calendarTd}>月</th>
            <th className={classes.calendarTd}>火</th>
            <th className={classes.calendarTd}>水</th>
            <th className={classes.calendarTd}>木</th>
            <th className={classes.calendarTd}>金</th>
            <th className={classes.calendarTd}>土</th>
            <th className={classes.calendarTd}>日</th>
          </tr>
        </thead>
        <tbody>
          {monthly.map((weekRow, index) => (
            <tr key={`${weekRow[index].date}month`}>
              {weekRow.map((item) => (
                <td key={item.day} className={classes.calendarCellTd} height="200px">
                  <div>{item.isSomeMonth ? item.day : ''}</div>
                  <div>{getPublishDate(item.date, bookings)}</div>
                  {dayElement(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
