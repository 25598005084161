import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, setMemberPasswordUpdate, getCsvDatas,
} from '../../../services/member/memberList.service';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actInitMemberListSuccess,
  actCallApiMemberListSuccess,
  actChangeMemberPasswordSuccess,
  actCallApiGetCsvMemberDataSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param userInfo
 */
function* callApiInitMemberList() {
  try {
    const stores = yield call(getStores);
    yield put(actInitMemberListSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param userInfo
 */
function* callApiMemberList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiMemberListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 */
function* callApiChangeMemberPassword({ params }) {
  try {
    const datas = yield call(setMemberPasswordUpdate, params.id, params.param);
    yield put(actChangeMemberPasswordSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * CSV出力用データ取得
 * @param {*} param0
 */
function* callApiGetCsvMembers({ searchCondition }) {
  try {
    const datas = yield call(getCsvDatas, searchCondition);
    yield put(actCallApiGetCsvMemberDataSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_MEMBER_LIST, callApiInitMemberList);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_LIST_SEARCH, callApiMemberList);
  yield takeLatest(ACTION_TYPE.CALL_API_CHANGE_MEMBER_PASSWORD, callApiChangeMemberPassword);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA, callApiGetCsvMembers);
}
