/* eslint-disable max-len */
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip,
  IconButton, Button, withStyles, Box, Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { actCallApiSelectedClearReset } from '../../../redux/booking/bookingDetail/action';
import { getComparator, stableSort, getSelected } from '../../../helpers/tableSort.helper';
import '../../../public/css/tableButton.css';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  transferAheadChip: {
    color: '#fff',
    backgroundColor: '#666666',
  },
  transferSourceChip: {
    color: '#fff',
    backgroundColor: '#00b050',
  },
  experienceChip: {
    color: '#fff',
    backgroundColor: '#e67d05',
  },
  funcBtn: {
    width: '150px',
    'white-space': 'nowrap',
  },
  chipColor: {
    backgroundColor: 'rgba(46, 125, 50, 0.7)',
  },
});

class BookingMemberDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'memberNo', numeric: false, label: '会員番号' },
      { id: 'name', numeric: false, label: '名前' },
      { id: 'phone', numeric: false, label: '電話番号' },
      { id: 'phoneTwo', numeric: false, label: '電話番号2' },
      { id: 'email', numeric: false, label: 'メール' },
      { id: 'email', numeric: false, label: '受講回数' },
    ];

    if (!props.isFromMemberAdd) {
      this.headCells.push(
        { id: 'memberStatusName', numeric: false, label: 'ステータス' },
      );
    }

    if (props.golfFlg) {
      this.headCells.push(
        { id: 'boxNumber', numeric: false, label: '打席番号' },
      );
    }

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      deleteConfopen: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingDetail.isInsertSuccess !== this.props.bookingDetail.isInsertSuccess
      || prevProps.bookingDetail.insertDatas !== this.props.bookingDetail.insertDatas) {
      if (this.props.bookingDetail.isInsertSuccess) {
        this.setSelected();
      }
    }

    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }

    if (prevProps.bookingDetail.isSearchSuccess !== this.props.bookingDetail.isSearchSuccess
      || prevProps.bookingDetail.members !== this.props.bookingDetail.members) {
      if (this.props.bookingDetail.isSearchSuccess) {
        this.setSelected();
      }
    }

    if (prevProps.bookingDetail.isSelectedClear !== this.props.bookingDetail.isSelectedClear) {
      if (this.props.bookingDetail.isSelectedClear) {
        this.handleHeaderClose();
      }
    }
  }

  setChangeLoading(loading) {
    this.setState({ loading });
  }

  setSelected = () => {
    this.setState({ selected: [] });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    const { rows, regularFlg } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => !(regularFlg && (row.transferAheadDate || row.transferSourceDate)))
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
    if (this.props.dialogFlg) {
      this.props.onChecked(selectedData);
    }
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
    if (this.props.dialogFlg) {
      this.props.onChecked(newSelected);
    }
  };

  handleHeaderClose = () => {
    const selectedData = [];
    this.setState({ selected: selectedData });
    if (this.props.dialogFlg) {
      this.props.onChecked(selectedData);
    }
    this.props.dispatch(actCallApiSelectedClearReset());
  }

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  onPreDelete = () => {
    this.setState({ deleteConfopen: true });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: true });
    this.props.onDelete(selected);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  disableAllCheck = (rows) => {
    const { bookingNotAcceptFlg } = this.props;
    const filterData = rows.filter((row) => row.transferAheadDate || row.transferSourceDate);
    if (bookingNotAcceptFlg || filterData.length === rows.length) return true;
    return false;
  }

  render() {
    const {
      classes,
      rows,
      onAdd,
      onAddSecond,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      dialogFlg,
      booking,
      onFirstFunc,
      onSecondFunc,
      onThirdFunc,
      bookingNotAcceptFlg,
      regularFlg,
      onOpenFaxDialog,
      isShowFaxBtn,
      isFromMemberAdd,
      golfFlg,
    } = this.props;

    const {
      order, orderBy, selected, deleteConfopen, loading,
    } = this.state;
    let addLabel = '体験者の追加';
    if (!regularFlg) {
      addLabel = 'ビジターの追加';
    }

    const bookingButton = () => {
      // 選択ある場合
      if (selected.length > 0) {
        return (
          <Tooltip title="予約者の削除">
            <span>
              <Button color="secondary" onClick={this.onPreDelete} disabled={dialogFlg || bookingNotAcceptFlg}>予約者の削除</Button>
            </span>
          </Tooltip>
        );
      }
      // 選択なしで予約枠一杯に予約ある場合→キャンセル待ち
      if (booking.capacity <= booking.bookingNumber) {
        return (
          <Tooltip title="キャンセル待ちの追加">
            <span>
              <Button color="primary" onClick={() => { onAdd(true); }} disabled={dialogFlg || bookingNotAcceptFlg}>キャンセル待ちの追加</Button>
              {isShowFaxBtn && (<Button color="primary" onClick={onOpenFaxDialog}>FAX用紙出力</Button>)}
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip title="予約者の追加">
          <span>
            <Button color="primary" onClick={() => { onAdd(false); }} disabled={dialogFlg || bookingNotAcceptFlg}>予約者の追加</Button>
            {isShowFaxBtn && (<Button color="primary" onClick={onOpenFaxDialog}>FAX用紙出力</Button>)}
          </span>
        </Tooltip>
      );
    };

    return (
      <>
        <Paper>
          {!isFromMemberAdd && (
            <Toolbar className={selected.length > 0 ? classes.highlight : null}>
              {selected.length > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                  <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  {selected.length}
                  {' '}
                  件選択中
                </Typography>
              ) : (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
              )}
              {
                (selected.length === 0) && (
                  <Tooltip title={addLabel}>
                    <span>
                      <Button color="primary" onClick={onAddSecond} disabled={dialogFlg || bookingNotAcceptFlg || (booking.capacity <= booking.bookingNumber)}>{addLabel}</Button>
                    </span>
                  </Tooltip>
                )
              }
              {bookingButton()}
            </Toolbar>
          )}

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && selected.length < rows.length
                      }
                      checked={
                        !this.disableAllCheck(rows)
                        && rows.length > 0
                        && rows.length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length < 1
                        || this.disableAllCheck(rows)
                      }
                    />
                  </TableCell>
                  {this.headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={this.createSortHandler(headCell.id)}
                        className={classes.tableLabel}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        className="tableRow"
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell padding="checkbox">
                          {
                            !bookingNotAcceptFlg
                            && !(regularFlg && (row.transferAheadDate || row.transferSourceDate))
                            && (
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                color="primary"
                                onClick={(event) => this.handleClick(event, row.id)}
                              />
                            )
                          }
                        </TableCell>
                        <TableCell>
                          {row.memberNo}
                        </TableCell>
                        <TableCell>
                          {
                            regularFlg && row.transferAheadDate && (
                              <Box mb={1}>
                                <Chip label={`${row.transferAheadDate}に振替`} className={classes.transferAheadChip} size="small" />
                              </Box>
                            )
                          }
                          {
                            regularFlg && row.transferSourceDate && (
                              <Box mb={1}>
                                <Chip label={`${row.transferSourceDate}から振替`} className={classes.transferSourceChip} size="small" />
                              </Box>
                            )
                          }
                          {
                            regularFlg && row.experienceNo && (
                              <Box mb={1}>
                                <Chip label="体験者" className={classes.experienceChip} size="small" />
                              </Box>
                            )
                          }
                          {row.currentContractType === '0' && (
                          <Chip
                            label="ビジター"
                            color="secondary"
                            size="small"
                            classes={{
                              colorSecondary: classes.chipColor,
                            }}
                          />
                          )}
                          <Box>
                            {row.kanaName}
                          </Box>
                          <Box>
                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row.phoneTwo}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.joinCount}</TableCell>
                        {!isFromMemberAdd && (
                          <>
                            <TableCell>
                              {
                                row.transferSourceDate
                                  ? row.transferAheadStatusName
                                  : row.memberStatusName
                              }
                            </TableCell>
                          </>
                        )}
                        {golfFlg && (
                          <>
                            <TableCell>
                              {row.boxNumber}
                            </TableCell>
                          </>
                        )}
                        <td className="customTd">
                          <div className="customButton">
                            {!isFromMemberAdd && (
                              <>
                                <Button ml={0} mr={0} className={classes.funcBtn} color="primary" disabled={regularFlg && row.transferAheadDate !== null} onClick={(event) => onFirstFunc(event, row)}>{regularFlg && row.transferAheadDate ? '' : 'ステータスを変更する'}</Button>
                                <Button ml={0} mr={0} className={classes.funcBtn} color="secondary" disabled={!regularFlg || (regularFlg && (row.transferAheadDate === null && row.transferSourceDate === null))} onClick={(event) => onSecondFunc(event, row)}>{(!regularFlg || (regularFlg && (!row.transferAheadDate && !row.transferSourceDate))) ? '' : '振替をキャンセルする'}</Button>
                              </>
                            )}
                            {golfFlg && (
                              <Button ml={0} mr={0} className={classes.funcBtn} color="primary" disabled={!golfFlg} onClick={(event) => onThirdFunc(event, row)}>{golfFlg ? '打席番号を変更する' : ''}</Button>
                            )}
                          </div>
                        </td>
                      </TableRow>
                    );
                  })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.emptyTableCell}
                        colSpan={this.headCells.length + 3}
                      >
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <ConfirmDialog
            open={deleteConfopen}
            handleClose={this.handleClose}
            executeText="削除する"
            handleExecute={this.onDelete}
            title="予約者の削除"
            contentText="選択した予約者を削除します。この操作は元に戻すことができません。"
            loading={loading}
            buttonColor="secondary"
          />
        </Paper>
      </>
    );
  }
}

BookingMemberDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onAdd: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onFirstFunc: PropTypes.func,
  onSecondFunc: PropTypes.func,
  bookingNotAcceptFlg: PropTypes.bool,
  isFromMemberAdd: PropTypes.bool,
};

BookingMemberDataTable.defaultProps = {
  totalElements: 0,
  onAdd: null,
  onDelete: null,
  onFirstFunc: null,
  onSecondFunc: null,
  bookingNotAcceptFlg: false,
  isFromMemberAdd: false,
};
const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingMemberDataTable)));
