// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_CALENDAR_HEADER_ROOM: 'CALL_API_GET_CALENDAR_HEADER_ROOM',
  CALL_API_GET_CALENDAR_HEADER_ROOM_SUCCESS: 'CALL_API_GET_CALENDAR_HEADER_ROOM_SUCCESS',
  CALL_API_CALENDAR_ROOM_DETAIL: 'CALL_API_CALENDAR_ROOM_DETAIL',
  CALL_API_CALENDAR_ROOM_DETAIL_SUCCESS: 'CALL_API_CALENDAR_ROOM_DETAIL_SUCCESS',
  CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE: 'CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE',
  CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE_SUCCESS',
  RESET_ROOM_CREATE_FLG: 'RESET_ROOM_CREATE_FLG',
};
// #endregion

// #region action
export const actCallRoomCreateFlg = () => ({
  type: ACTION_TYPE.RESET_ROOM_CREATE_FLG,
});

export const actCallApiCalendarHeaderRoom = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_CALENDAR_HEADER_ROOM,
  param,
});

export const actCallApiCalendarHeaderRoomSuccess = (headerDatas) => ({
  type: ACTION_TYPE.CALL_API_GET_CALENDAR_HEADER_ROOM_SUCCESS,
  headerDatas,
});

export const actCallApiCalendarRoomDetail = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_ROOM_DETAIL,
  searchCondition,
});

export const actCallApiCalendarRoomDetailSuccess = (bookingDatas) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_ROOM_DETAIL_SUCCESS,
  bookingDatas,
});

export const actCallApiCalendarScheduleRoomCreate = (param) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE,
  param,
});

export const actCallApiCalendarScheduleRoomCreateSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE_SUCCESS,
});

// #endregion
