import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約枠カレンダー(ヘッダー、詳細)取得
 * @param id
 */
export function getCalendarScheduleDetail(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_DETAIL_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠ヘッダー公開日変更
 * @param datas
 */
export function updateHeaderCalendarSchedule(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(
      REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_DETAIL_INFO, { id: param.headerId }, param,
    )
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約カレンダー詳細削除
 * @param id
 */
export function deleteCalendarSchedule(id, regular) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_MENU, { id, regular })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function deleteBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.CALENDAR_SCHEDULE.CALENDAR_SCHEDULE_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
