import React, { Component } from 'react';
import { Breadcrumbs, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  breadcrumbs: {
    zIndex: '1000',
    width: '100%!important',
    position: 'fixed!important',
    background: '#fff',
    paddingLeft: '28px',
    paddingTop: '5px',
    height: '30px',
    boxShadow: '0 8px 10px -9px rgba(0,0,0,.05), 0 3px 14px 2px rgba(0,0,0,.01), 0 5px 5px -3px rgba(0,0,0,.05)',
  },
  breadcrumbsText: {
    fontSize: '14px!important',
  },
  breadcrumbsColor: {
    color: '#000',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

class BasicBreadcrumbs extends Component {
  handleClick = (url, id) => {
    let state = null;
    if (id) {
      state = { id };
    }
    this.props.history.push({
      pathname: url,
      state,
    });
  }

  render() {
    const { breadcrumbs, classes } = this.props;
    return (
      <div className={classes.breadcrumbs}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsText}>
          {
            breadcrumbs.map((path) => (path.url
              ? (
                <div className={classes.link} onClick={() => this.handleClick(path.url, path.id)} aria-hidden="true" key={`link-${path.url}`}>
                  {path.name}
                </div>
              ) : (
                <div className={classes.breadcrumbsColor} key={`label-${path.url}`}>{path.name}</div>
              )))
          }
        </Breadcrumbs>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(BasicBreadcrumbs));

BasicBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired).isRequired,
};
