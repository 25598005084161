// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_BOOKING_DETAIL_SEARCH: 'CALL_API_INIT_BOOKING_DETAIL_SEARCH',
  INIT_BOOKING_SEARCH_DETAIL_SUCCESS: 'INIT_BOOKING_SEARCH_DETAIL_SUCCESS',
  CALL_API_BOOKING_DETAIL_MEMBER_LIST: 'CALL_API_BOOKING_DETAIL_MEMBER_LIST',
  CALL_API_BOOKING_DETAIL_MEMBER_LIST_SUCCESS: 'CALL_API_BOOKING_DETAIL_MEMBER_LIST_SUCCESS',
  CALL_API_INSERT_BOOKING_MEMBER: 'CALL_API_INSERT_BOOKING_MEMBER',
  CALL_API_INSERT_BOOKING_MEMBER_SUCCESS: 'CALL_API_INSERT_BOOKING_MEMBER_SUCCESS',
  CALL_API_BOOKING_MEMBER_FLG_RESET: 'CALL_API_BOOKING_MEMBER_FLG_RESET',
  CALL_API_BOOKING_DIALOG_MEMBER_LIST: 'CALL_API_BOOKING_DIALOG_MEMBER_LIST',
  CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS: 'CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS',
  CALL_API_BOOKING_MEMBER_DELETE: 'CALL_API_BOOKING_MEMBER_DELETE',
  CALL_API_BOOKING_MEMBER_DELETE_SUCCESS: 'CALL_API_BOOKING_MEMBER_DELETE_SUCCESS',
  CALL_API_BOOKING_MEMBER_UPDATE: 'CALL_API_BOOKING_MEMBER_UPDATE',
  CALL_API_BOOKING_MEMBER_UPDATE_SUCCESS: 'CALL_API_BOOKING_MEMBER_UPDATE_SUCCESS',
  CALL_API_BOOKING_MEMBER_CANCEL: 'CALL_API_BOOKING_MEMBER_CANCEL',
  CALL_API_BOOKING_MEMBER_CANCEL_SUCCESS: 'CALL_API_BOOKING_MEMBER_CANCEL_SUCCESS',
  CALL_API_BOOKING_EXPERIENCE_INSERT: 'CALL_API_BOOKING_EXPERIENCE_INSERT',
  CALL_API_BOOKING_EXPERIENCE_INSERT_SUCCESS: 'CALL_API_BOOKING_EXPERIENCE_INSERT_SUCCESS',
  CALL_API_RESEND_ZOOMURL_TEACHER: 'CALL_API_RESEND_ZOOMURL_TEACHER',
  CALL_API_RESEND_ZOOMURL_TEACHER_SUCCESS: 'CALL_API_RESEND_ZOOMURL_TEACHER_SUCCESS',
  CALL_API_CREATE_WEBINAR: 'CALL_API_CREATE_WEBINAR',
  CALL_API_CREATE_WEBINAR_SUCCESS: 'CALL_API_CREATE_WEBINAR_SUCCESS',
  CALL_API_SELECTED_CLEAR_RESET: 'CALL_API_SELECTED_CLEAR_RESET',
  CALL_API_BOOKING_MENU_UPDATE: 'CALL_API_BOOKING_MENU_UPDATE',
  CALL_API_BOOKING_MENU_UPDATE_SUCCESS: 'CALL_API_BOOKING_MENU_UPDATE_SUCCESS',
  CALL_API_GET_GOLF_BOXS: 'CALL_API_GET_GOLF_BOXS',
  CALL_API_GET_GOLF_BOXS_SUCCESS: 'CALL_API_GET_GOLF_BOXS_SUCCESS',
  CALL_API_GOLF_BOX_UPDATE: 'CALL_API_GOLF_BOX_UPDATE',
  CALL_API_GOLF_BOX_UPDATE_SUCCESS: 'CALL_API_GOLF_BOX_UPDATE_SUCCESS',
  CALL_API_GOLF_BOX_UPDATE_ERROR: 'CALL_API_GOLF_BOX_UPDATE_ERROR',
  CALL_API_GOLF_BOX_UPDATE_FLG_RESET: 'CALL_API_GOLF_BOX_UPDATE_FLG_RESET',

};
// #endregion

// #region Type
export const actCallInitBookingDetailSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_INIT_BOOKING_DETAIL_SEARCH,
  searchCondition,
});

export const actInitBookingSearchDetailSuccess = (bookingDetail) => ({
  type: ACTION_TYPE.INIT_BOOKING_SEARCH_DETAIL_SUCCESS,
  bookingDetail,
});

export const actCallApiBookingDetailMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DETAIL_MEMBER_LIST,
  searchCondition,
});

export const actCallApiBookingDetailMemberListSuccess = (bookingMembers) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DETAIL_MEMBER_LIST_SUCCESS,
  bookingMembers,
});

export const actCallApiBookingDialogMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST,
  searchCondition,
});

export const actCallApiBookingDialogMemberListSuccess = (members) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS,
  members,
});

export const actCallApiInsertBookingMember = (values) => ({
  type: ACTION_TYPE.CALL_API_INSERT_BOOKING_MEMBER,
  values,
});

export const actCallApiInsertBookingMemberSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_INSERT_BOOKING_MEMBER_SUCCESS,
  datas,
});

export const actApiBookingMemberFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_FLG_RESET,
});

export const actCallApiBookingMemberDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_DELETE,
  values,
});

export const actCallApiBookingMemberDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_DELETE_SUCCESS,
});

export const actCallApiBookingMemberUpdate = (params) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_UPDATE,
  params,
});

export const actCallApiBookingMemberUpdateSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_UPDATE_SUCCESS,
  datas,
});

export const actCallApiBookingMemberCancel = (params) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_CANCEL,
  params,
});

export const actCallApiBookingMemberCancelSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MEMBER_CANCEL_SUCCESS,
  datas,
});

export const actCallApiBookingExperienceInsert = (param) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_EXPERIENCE_INSERT,
  param,
});

export const actCallApiBookingExperienceInsertSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_EXPERIENCE_INSERT_SUCCESS,
  datas,
});

export const actCallApiReSendZoomUrlTeacher = (param) => ({
  type: ACTION_TYPE.CALL_API_RESEND_ZOOMURL_TEACHER,
  param,
});

export const actCallApiReSendZoomUrlTeacherSuccess = () => ({
  type: ACTION_TYPE.CALL_API_RESEND_ZOOMURL_TEACHER_SUCCESS,
});

export const actCallApiCreateWebinar = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_WEBINAR,
  param,
});

export const actCallApiCreateWebinarSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CREATE_WEBINAR_SUCCESS,
});

export const actCallApiSelectedClearReset = () => ({
  type: ACTION_TYPE.CALL_API_SELECTED_CLEAR_RESET,
});

export const actCallApiBookingMemoUpdate = (params) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MENU_UPDATE,
  params,
});

export const actCallApiBookingMemoUpdateSuccess = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_MENU_UPDATE_SUCCESS,

});

export const actCallApiGetGolfBoxs = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_BOXS,
  param,
});

export const actCallApiGetGolfBoxsSuccess = (golfBoxs) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_BOXS_SUCCESS,
  golfBoxs,
});

export const actCallApiGolfBoxUpdate = (param) => ({
  type: ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE,
  param,
});

export const actCallApiGolfBoxUpdateSuccess = (golfBoxUpdateFlg) => ({
  type: ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_SUCCESS,
  golfBoxUpdateFlg,
});

export const actCallApiGolfBoxUpdateError = (golfBoxUpdateFlg) => ({
  type: ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_ERROR,
  golfBoxUpdateFlg,
});

export const actCallApiGetGolfBoxUpdateFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_GOLF_BOX_UPDATE_FLG_RESET,
});
// #endregion
