import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { TextField, Box } from '@material-ui/core';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiSelectRoleList, actCallApiGetRoleList,
  actCallApiRoleListDelete, actSetSearchCondition, actResetRoleListFlg,
} from '../../../redux/userSetting/roleList/action';
import RoleDetailDialog from '../roleDetailDialog/RoleDetailDialog';
import urls from '../../../constants/urls';

class RoleList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallApiSelectRoleList());

    this.breadcrumbsText = [
      { name: '権限' },
    ];

    this.headCells = [
      { id: 'authName', numeric: false, label: '権限名' },
      { id: 'storeName', numeric: false, label: '店舗' },
    ];

    let isMain = false;
    let searchStore = null;
    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    let searchAuthName = '';
    let page = 0;
    let records = 10;
    if (props.roles.searchCondition) {
      const { searchCondition } = props.roles;
      searchStore = searchCondition.searchStore;
      searchAuthName = searchCondition.searchAuthName;
      page = searchCondition.page;
      records = searchCondition.records;
    }

    if (props.roles.searchCondition || props.common.user) {
      this.search(page, records, searchStore, searchAuthName);
    }

    this.state = {
      isMain,
      datas: {},
      searchStore,
      searchAuthName,
      page,
      records,
      successSnackOpen: false,
      detailModalOpen: false,
    };
  }

  componentDidMount() {
    document.title = '権限';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.roles.datas !== this.props.roles.datas) {
      if (this.props.roles.datas) {
        this.setDatas(this.props.roles.datas);
      }
    }
    if (prevProps.roles.isDeleteSuccess !== this.props.roles.isDeleteSuccess) {
      if (this.props.roles.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main, searchStore: user.store });
    this.search(0, 10, user.store, '');
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ successSnackOpen: true });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onSearch = () => {
    const {
      searchStore, searchAuthName, records,
    } = this.state;
    this.setState({ page: 0 });
    this.search(0, records, searchStore, searchAuthName);
  }

  search = (page, records, searchStore, searchAuthName) => {
    this.props.dispatch(actCallApiGetRoleList(
      {
        page,
        records,
        searchStoreId: searchStore && searchStore.id,
        searchAuthName,
      },
    ));
  }

  handleChangePage = (_, newPage) => {
    const { searchStore, searchAuthName, records } = this.state;
    this.setState({ page: newPage });
    this.search(newPage, records, searchStore, searchAuthName);
  };

  handleChangeRowsPerPage = (event) => {
    const { searchStore, searchAuthName } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchStore, searchAuthName);
  };

  onDelete = (value) => {
    this.setState({ successSnackOpen: false, page: 0 });
    this.props.dispatch(actResetRoleListFlg());
    this.props.dispatch(actCallApiRoleListDelete({ deleteList: value }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  onAdd = () => {
    const {
      searchStore, searchAuthName, page, records,
    } = this.state;
    this.props.dispatch(actSetSearchCondition({
      searchStore, searchAuthName, page, records,
    }));
    this.setState({ detailModalOpen: true, successSnackOpen: false });
  }

  onDetail = (_, row) => {
    const {
      searchStore, searchAuthName, page, records,
    } = this.state;
    this.props.dispatch(actSetSearchCondition({
      searchStore, searchAuthName, page, records,
    }));
    this.props.history.push({
      pathname: urls.USER_SETTING.ROLE_DETAIL,
      state: {
        roleId: row.id,
      },
    });
  }

  handleDetailClose = () => {
    this.setState({ detailModalOpen: false });
  }

  render() {
    const {
      datas, searchStore, searchAuthName, page, records,
      successSnackOpen, detailModalOpen, isMain, defaultStore,
    } = this.state;
    const stores = this.props.roles.stores || [];

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} width="30%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onChange} name="searchStore" />
              </Box>

              <Box>
                <FormTitle title="権限名" />
                <TextField variant="outlined" fullWidth value={searchAuthName} name="searchAuthName" onChange={this.onChange} />
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            isMain={isMain}
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="権限の追加"
            deleteButtonLabel="権限の削除"
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            deleteDialogTitle="選択した権限の削除"
            deleteDialogContent="選択した権限を削除します。この操作は元に戻すことができません。"
            onDetail={this.onDetail}
          />
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="権限の削除が完了しました" />
        </MainBody>

        <RoleDetailDialog
          isMain={isMain}
          stores={stores}
          open={detailModalOpen}
          handleClose={this.handleDetailClose}
          defaultStore={defaultStore}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ roles: state.roles, common: state.common });

export default withRouter(connect(mapStateToProps)(RoleList));
