import { ACTION_TYPE } from './action';

// region Reducer
const schoolWeekScheduleDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_SCHOOL_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        headerData: action.headerData,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_INSERT_SUCCESS:
      return {
        ...state,
        insertSchedule: action.data,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_COPY_SUCCESS:
      return {
        ...state,
        insertSchedule: action.data,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_CREATED:
      return {
        ...state,
        isCreated: true,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_COPIED:
      return {
        ...state,
        isCopied: true,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_FLG_RESET:
      return {
        ...state,
        isCreated: false,
        isCopied: false,
      };
    default:
      return state;
  }
};

export default schoolWeekScheduleDetail;
