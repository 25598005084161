import React, { Component } from 'react';
import {
  Box, Container, withStyles, Card, Divider, Dialog, DialogContent,
  Backdrop, CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { QrReader } from 'react-qr-reader';
import connect from 'react-redux/es/connect/connect';
import Sound from 'react-sound';
import phoneLogo from '../../../public/assets/phoneLogo.svg';
import success from '../../../public/assets/success.mp3';
import error from '../../../public/assets/error.mp3';
import {
  actCallApiCreateAttendanceEntry,
  actResetAttendanceEntryFlg,
} from '../../../redux/info/attendanceEntry/action';
import urls from '../../../constants/urls';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
  elcLogo: {
    width: '45%',
  },
  marginTop: {
    marginTop: 5,
  },
  alert: {
    width: '80%',
    margin: '0 auto',
    justifyContent: 'center',
    marginBottom: 30,
  },
  container: {
    padding: 10,
  },
  text: {
    position: 'relative',
    top: '10px',
    left: '10px',
    zIndex: '-1',
  },
});

class AttendanceEntryDetail extends Component {
  constructor(props) {
    super(props);

    let tempData = null;
    if (props.location.state) {
      tempData = props.location.state.data;
    }

    if (!tempData) {
      props.history.push({ pathname: urls.INFO.ATTENDANCE_ENTRY_LIST });
    }

    this.state = {
      data: tempData,
      isAttend: true,
      dialogOpen: false,
      playing: 'STOPPED',
      music: success,
      errorMessage: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.attendanceEntry.isCreate !== this.props.attendanceEntry.isCreate) {
      if (this.props.attendanceEntry.isCreate) {
        this.setIsAttend(this.props.attendanceEntry.result);
      }
    }
  }

  setIsAttend(result) {
    let music = success;
    let timeOut = 5000;

    if (!result.success) {
      music = error;
      timeOut = 10000;
    }
    window.setTimeout(() => { this.setComplete(); }, timeOut);
    this.setState({
      isAttend: result.success,
      errorMessage: result.message,
      playing: 'PLAYING',
      dialogOpen: true,
      music,
    });
  }

  setComplete() {
    this.setState({ dialogOpen: false, playing: 'STOPPED', loading: false });
  }

  handleScan = (result) => {
    if (!result) {
      return;
    }
    let loading = false;
    const value = result.text;
    const { data } = this.state;
    if (value.match(/^[A-Za-z0-9]*$/)) {
      this.props.dispatch(actResetAttendanceEntryFlg());
      this.props.dispatch(actCallApiCreateAttendanceEntry({
        bookingId: data.id,
        memberNo: value,
      }));
      loading = true;
    }
    this.setState({ dialogOpen: false, loading });
  }

  render() {
    const { classes } = this.props;
    const {
      data, isAttend, dialogOpen, music, playing, errorMessage, loading,
    } = this.state;
    return (
      <div>
        <Sound url={music} playStatus={playing} />
        <Container className={classes.container}>
          <Card variant="outlined">
            <Box p={3} textAlign="center">
              <Box fontWeight="fontWeightBold" fontSize={36}>
                {data.targetDate}
              </Box>
              <Divider />
              <Divider className={classes.marginTop} />
            </Box>
            <Alert icon={<img src={phoneLogo} alt="リーダー" width="22px" height="22px" />} severity="error" className={classes.alert}>会員カードをカメラにかざしてください</Alert>
            <Box mb={4} textAlign="center" fontWeight="fontWeightBold" fontSize="h5.fontSize" color="secondary.main">受付中</Box>
            <Box my={4} textAlign="center" fontWeight="fontWeightBold" fontSize="h5.fontSize">
              <Box>{data.menuName}</Box>
              <Box>{data.targetTime}</Box>
            </Box>
            <Box display={loading ? 'none' : ''}>
              <QrReader
                scanDelay={300}
                onResult={this.handleScan}
                style={{ width: '100%' }}
              />
            </Box>
          </Card>
          <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
            <DialogContent dividers>
              {isAttend
                ? (<Box>認証が完了しました。</Box>)
                : (<Box color="#ff0000">{errorMessage}</Box>)}
            </DialogContent>
          </Dialog>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attendanceEntry: state.attendanceEntry,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(AttendanceEntryDetail));
