import { all } from 'redux-saga/effects';

import watchCallCommon from './common/common/saga';
import watchCallHeaderChangePassword from './common/headerChangePassword/saga';

import watchCallLogin from './other/login/saga';
import watchCallPasswordUpdate from './other/changePassword/saga';
import watchCallHome from './other/home/saga';

import watchCallSystemUsers from './userSetting/systemUserList/saga';
import watchCallSystemUserDetail from './userSetting/systemUserDetail/saga';
import watchCallRoleList from './userSetting/roleList/saga';
import watchCallRoleDetail from './userSetting/roleDetail/saga';

import watchCallBannerImageList from './setting/bannerImage/saga';
import watchCallRules from './setting/rule/saga';
import watchCallMailList from './setting/mailList/saga';
import watchCallMailDetail from './setting/mailDetail/saga';
import watchCallSiteSetting from './setting/siteSetting/saga';
import watchCallSurveySetting from './setting/surveySetting/saga';

import watchCallHealthCheck from './info/healthCheck/saga';
import watchCallClassInfomation from './info/classInfomation/saga';
import watchCallTeacherContactTaskList from './info/teacherContactTaskList/saga';
import watchCallAttendanceEntry from './info/attendanceEntry/saga';

import watchCallMemberList from './member/memberList/saga';
import watchCallEntry from './member/entry/saga';
import watchCallMemberDetail from './member/memberDetail/saga';

import watchCallBookingList from './booking/bookingList/saga';
import watchCallBookingDetail from './booking/bookingDetail/saga';
import watchCallSubscriberList from './booking/subscriberList/saga';
import watchCallRoomSchedule from './booking/roomSchedule/saga';
import watchCallGolfSchedule from './booking/golfSchedule/saga';

import watchCallEventWeekScheduleList from './event/eventWeekScheduleList/saga';
import watchCallEventWeekScheduleDetail from './event/eventWeekScheduleDetail/saga';
import watchCallEventCalendarList from './event/eventCalendarList/saga';
import watchCallCalendarDetailRoom from './event/eventCalendarDetailRoom/saga';
import watchCallCalendarDetailGolf from './event/eventCalendarDetailGolf/saga';

import watchCallSchoolWeekScheduleList from './school/schoolWeekScheduleList/saga';
import watchCallSchoolWeekScheduleDetail from './school/schoolWeekScheduleDetail/saga';
import watchCallMemberLinkage from './school/memberLinkage/saga';
import watchCallWeekSelect from './school/weekSelect/saga';
import watchCallSchoolCalendarList from './school/schoolCalenderList/saga';

import watchCallWeekScheduleMenu from './share/weekScheduleMenu/saga';
import watchCallCalendarScheduleDetail from './share/calendarScheduleDetail/saga';
import watchCallCalendarScheduleMenu from './share/calendarScheduleMenu/saga';

import watchCallTeacherList from './master/teacherList/saga';
import watchCallHolidayList from './setting/holiday/saga';
import watchCallNotice from './master/notice/saga';

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* rootSaga() {
  yield all([

    watchCallCommon(),
    watchCallHeaderChangePassword(),

    watchCallLogin(),
    watchCallPasswordUpdate(),
    watchCallHome(),

    watchCallSystemUsers(),
    watchCallSystemUserDetail(),
    watchCallRoleList(),
    watchCallRoleDetail(),

    watchCallBannerImageList(),
    watchCallRules(),
    watchCallMailList(),
    watchCallMailDetail(),
    watchCallSiteSetting(),
    watchCallSurveySetting(),
    watchCallHolidayList(),

    watchCallHealthCheck(),
    watchCallClassInfomation(),
    watchCallTeacherContactTaskList(),
    watchCallAttendanceEntry(),

    watchCallMemberList(),
    watchCallEntry(),
    watchCallMemberDetail(),

    watchCallBookingList(),
    watchCallBookingDetail(),
    watchCallSubscriberList(),
    watchCallRoomSchedule(),
    watchCallGolfSchedule(),

    watchCallEventWeekScheduleList(),
    watchCallEventWeekScheduleDetail(),
    watchCallEventCalendarList(),
    watchCallCalendarDetailRoom(),
    watchCallCalendarDetailGolf(),

    watchCallSchoolWeekScheduleList(),
    watchCallSchoolWeekScheduleDetail(),
    watchCallMemberLinkage(),
    watchCallWeekSelect(),
    watchCallSchoolCalendarList(),

    watchCallWeekScheduleMenu(),
    watchCallCalendarScheduleDetail(),
    watchCallCalendarScheduleMenu(),

    watchCallTeacherList(),
    watchCallNotice(),
  ]);
}
