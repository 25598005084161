// #region Type
export const ACTION_TYPE = {
  CALL_API_ATTENDANCE_ENTRY_SELECT: 'CALL_API_ATTENDANCE_ENTRY_SELECT',
  CALL_API_ATTENDANCE_ENTRY_SELECT_SUCCESS: 'CALL_API_ATTENDANCE_ENTRY_SELECT_SUCCESS',
  CALL_API_GET_ATTENDANCE_ENTRY_LIST: 'CALL_API_GET_ATTENDANCE_ENTRY_LIST',
  CALL_API_GET_ATTENDANCE_ENTRY_LIST_SUCCESS: 'CALL_API_GET_ATTENDANCE_ENTRY_LIST_SUCCESS',
  CALL_API_CREATE_ATTENDANCE_ENTRY: 'CALL_API_CREATE_ATTENDANCE_ENTRY',
  CALL_API_CREATE_ATTENDANCE_ENTRY_SUCCESS: 'CALL_API_CREATE_ATTENDANCE_ENTRY_SUCCESS',
  RESET_ATTENDANCE_ENTRY_FLG: 'RESET_ATTENDANCE_ENTRY_FLG',
};
// #endregion

// #region Type
export const actCallApiAttendanceEntrySelect = () => ({
  type: ACTION_TYPE.CALL_API_ATTENDANCE_ENTRY_SELECT,
});

export const actCallApiAttendanceEntrySelectSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_ATTENDANCE_ENTRY_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetAttendanceEntry = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_ATTENDANCE_ENTRY_LIST,
  param,
});

export const actCallApiGetAttendanceEntrySuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_ATTENDANCE_ENTRY_LIST_SUCCESS,
  datas,
});

export const actCallApiCreateAttendanceEntry = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_ATTENDANCE_ENTRY,
  param,
});

export const actCallApiCreateAttendanceEntrySuccess = (result) => ({
  type: ACTION_TYPE.CALL_API_CREATE_ATTENDANCE_ENTRY_SUCCESS,
  result,
});

export const actResetAttendanceEntryFlg = () => ({
  type: ACTION_TYPE.RESET_ATTENDANCE_ENTRY_FLG,
});
// #endregion
