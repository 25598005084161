import React, { Component } from 'react';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Box, Button,
} from '@material-ui/core';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { actCallApiGetRoomSchedule, actApiRoomScheduleFlgReset } from '../../../redux/booking/roomSchedule/action';

const drawerWidth = 400;
const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: 500,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5) + 1,
    },
  },
  papperRoot: {
    paddingTop: '100px',
    zIndex: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
});

class RoomSearch extends Component {
  constructor(props) {
    super(props);

    let store = props.common.user ? props.common.user.store : null;
    store = props.roomSchedule.info ? props.roomSchedule.info.store : store;

    const date = props.date ? props.date : format(new Date(), 'yyyy/MM/dd');

    this.state = {
      date,
      store,
      isMain: props.common.user ? props.common.user.main : false,
    };
    if (props.common.user) {
      this.search(store, date);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.roomSchedule.isUpsertSuccessFlg !== this.props.roomSchedule.isUpsertSuccessFlg) {
      if (this.props.roomSchedule.isUpsertSuccessFlg && !this.props.roomSchedule.isDuplicateFlg) {
        this.search(this.state.store, this.state.date);
      }
    }
    if (prevProps.roomSchedule.isDeleteByDetailSuccess
      !== this.props.roomSchedule.isDeleteByDetailSuccess) {
      if (this.props.roomSchedule.isDeleteByDetailSuccess) {
        this.search(this.state.store, this.state.date);
      }
    }
  }

  setUser(user) {
    const store = this.props.roomSchedule.info ? this.props.roomSchedule.info.store : user.store;
    const curDate = this.props.date ? this.props.date : format(new Date(), 'yyyy/MM/dd');

    this.setState({ isMain: user.main, store });
    this.search(store, curDate);
  }

  onDateChange = (_, date) => {
    this.setState({ date });
    const { store } = this.state;
    this.search(store, date);
    this.props.onDateChange(date);
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { date } = this.state;
    this.setState({ [name]: value });
    this.search(value, date);
    this.props.onStoreChange(value);
  }

  onSearch = () => {
    const { date, store } = this.state;
    this.search(store, date);
  }

  search(store, date) {
    this.props.dispatch(actApiRoomScheduleFlgReset());
    this.props.dispatch(actCallApiGetRoomSchedule(
      { searchStoreId: store.id, searchTargetDate: date },
    ));
  }

  render() {
    const {
      date, isMain, store,
    } = this.state;
    const { stores } = this.props;
    return (
      <Box display="flex">
        <Box>
          <Datepicker
            value={date}
            name="date"
            handleChange={this.onDateChange}
          />
        </Box>
        <Box display={isMain ? '' : 'none'} style={{ width: '50%' }} ml={1}>
          <SearchSelectBox options={stores} value={store} disableClearable="true" onChange={this.onChange} name="store" />
        </Box>
        <Box ml={2} alignItems="center" display="flex"><Button variant="contained" color="primary" onClick={this.onSearch}>検索</Button></Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, roomSchedule: state.roomSchedule });

export default withStyles(styles)(connect(mapStateToProps)(RoomSearch));
