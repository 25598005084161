import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actCallInitSiteSettingSuccess,
  actCallApiSiteSettingSearchSuccess,
  actCallApiSiteSettingUpdateSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { getStores } from '../../../services/common/selectList.service';
import {
  getSiteSetting, updateSiteSetting,
} from '../../../services/setting/siteSetting.service';

/**
 * 初期表示
 */
function* callApiInitSiteSetting() {
  try {
    const stores = yield call(getStores);
    yield put(actCallInitSiteSettingSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員規約、会員名表示フラグ情報取得
 */
function* callApiSiteSettingSearch({ param }) {
  try {
    const siteSetting = yield call(getSiteSetting, param);
    yield put(actCallApiSiteSettingSearchSuccess(siteSetting));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 */
function* callApiSiteSettingUpdate({ param }) {
  try {
    const siteSetting = yield call(updateSiteSetting, param);
    yield put(actCallApiSiteSettingUpdateSuccess(siteSetting));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSiteSetting() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SITE_SETTING, callApiInitSiteSetting);
  yield takeLatest(ACTION_TYPE.CALL_API_SITE_SETTING_SEARCH, callApiSiteSettingSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_SITE_SETTING_UPDATE, callApiSiteSettingUpdate);
}
