import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から会員情報を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(storeId) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.MAIL_LIST.MAIL_LIST, storeId)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 配信状況を更新する
 *
 * @param
 */
export function updateStopFlg(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.SETTING.MAIL_LIST.UPDATE_STOP_FLG, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
