// region Type
export const ACTION_TYPE = {
  CALL_INIT_TEACHER_CONTACT_SELECT_LIST: 'CALL_INIT_TEACHER_CONTACT_SELECT_LIST',
  CALL_INIT_TEACHER_CONTACT_SELECT_LIST_SUCCESS: 'CALL_INIT_TEACHER_CONTACT_SELECT_LIST_SUCCESS',
  CALL_API_TEACHER_CONTACT_SEARCH_LIST: 'CALL_API_TEACHER_CONTACT_SEARCH_LIST',
  CALL_API_TEACHER_CONTACT_SEARCH_LIST_SUCCESS: 'CALL_API_TEACHER_CONTACT_SEARCH_LIST_SUCCESS',
  CALL_TEACHER_CONTACT_SEARCH_CONDITION_SAVE: 'CALL_TEACHER_CONTACT_SEARCH_CONDITION_SAVE',
  CALL_TEACHER_CONTACT_TASK_CONTACTED: 'CALL_TEACHER_CONTACT_TASK_CONTACTED',
  CALL_TEACHER_CONTACT_TASK_CONTACTED_SUCCESS: 'CALL_TEACHER_CONTACT_TASK_CONTACTED_SUCCESS',
  RESET_TEACHER_CONTACT_SUCCESS_FLG: 'RESET_TEACHER_CONTACT_SUCCESS_FLG',
};
// endregion

export const actCallInitTeacherContactSelectList = (param) => ({
  type: ACTION_TYPE.CALL_INIT_TEACHER_CONTACT_SELECT_LIST,
  param,
});

export const actCallInitTeacherContactSelectListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_INIT_TEACHER_CONTACT_SELECT_LIST_SUCCESS,
  selects,
});

export const actCallTeacherContactSearchList = (param) => ({
  type: ACTION_TYPE.CALL_API_TEACHER_CONTACT_SEARCH_LIST,
  param,
});

export const actCallSearchConditionSave = ({ searchCondition }) => ({
  type: ACTION_TYPE.CALL_TEACHER_CONTACT_SEARCH_CONDITION_SAVE,
  searchCondition,
});

export const actCallTeacherContactSearchListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_TEACHER_CONTACT_SEARCH_LIST_SUCCESS,
  datas,
});

export const actCallTeacherContactTaskContacted = (params) => ({
  type: ACTION_TYPE.CALL_TEACHER_CONTACT_TASK_CONTACTED,
  params,
});

export const actCallTeacherContactTaskContactedSuccess = () => ({
  type: ACTION_TYPE.CALL_TEACHER_CONTACT_TASK_CONTACTED_SUCCESS,
});

export const actResetTeacherContactSuccessFlg = () => ({
  type: ACTION_TYPE.RESET_TEACHER_CONTACT_SUCCESS_FLG,
});
