import React from 'react';
import {
  TextField, Box, Typography, Button, withStyles,
} from '@material-ui/core';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { getRoomDatas, compareTime, checkValidation } from './rule-list-func';
import Validation from './validation';

const styles = () => ({
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class RoomRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: getRoomDatas(),
      data: getRoomDatas(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 2) {
        this.setData(this.props.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ data: data || getRoomDatas(), messages: getRoomDatas() });
  }

  onChange = (event) => {
    const { data, messages } = this.state;
    const { value, name } = event.target;
    let tempMessage = { ...messages, [name]: Validation.formValidate(name, value) };

    if (name === 'startTime' && value && data.endTime) {
      tempMessage = compareTime(tempMessage, value, data.endTime);
    }
    if (name === 'endTime' && value && data.startTime) {
      tempMessage = compareTime(tempMessage, data.startTime, value);
    }

    this.setState({
      data: { ...data, [name]: value },
      messages: tempMessage,
    });
  }

  onSave = (event) => {
    const { data, messages } = this.state;
    const errObj = checkValidation(data, messages);
    this.setState({ messages: errObj.messages });
    if (errObj.isError) return;
    this.props.onSave(event, data);
  }

  render() {
    const {
      value, classes,
    } = this.props;
    const { data, messages } = this.state;

    return (
      <TabPanel value={value} index={2}>
        <Box p={2}>
          <Box textAlign="right">
            <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約カレンダー表示開始時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="startTime"
                value={data.startTime}
                onChange={this.onChange}
                error={messages.startTime !== ''}
              />
              <FormErrorText>{messages.startTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約カレンダー表示終了時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="endTime"
                className={classes.timeInput}
                value={data.endTime}
                onChange={this.onChange}
                error={messages.endTime !== ''}
              />
              <FormErrorText>{messages.endTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <FormTitle title="予約受付締切日" isRequired />
            <TextField
              variant="outlined"
              name="closeDay"
              value={data.closeDay}
              onChange={this.onChange}
              error={messages.closeDay !== ''}
              inputProps={{ maxLength: 2 }}
            />
            <Typography className={classes.ruleLabel} component="span">日前</Typography>
            <FormErrorText>{messages.closeDay}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="キャンセル可能日" isRequired />
            <TextField
              variant="outlined"
              name="cancelDay"
              value={data.cancelDay}
              onChange={this.onChange}
              error={messages.cancelDay !== ''}
              inputProps={{ maxLength: 2 }}
            />
            <Typography className={classes.ruleLabel} component="span">日前</Typography>
            <FormErrorText>{messages.cancelDay}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="最低予約時間単位" isRequired />
            <TextField
              variant="outlined"
              name="reservationUnit"
              value={data.reservationUnit}
              onChange={this.onChange}
              error={messages.reservationUnit !== ''}
              inputProps={{ maxLength: 2 }}
            />
            <Typography className={classes.ruleLabel} component="span">分</Typography>
            <FormErrorText>{messages.reservationUnit}</FormErrorText>
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="カルチャー前準備時間" isRequired />
              <TextField
                variant="outlined"
                name="cultureBeforeTime"
                value={data.cultureBeforeTime}
                onChange={this.onChange}
                error={messages.cultureBeforeTime !== ''}
                inputProps={{ maxLength: 2 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.cultureBeforeTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="カルチャー後準備時間" isRequired />
              <TextField
                variant="outlined"
                name="cultureAfterTime"
                value={data.cultureAfterTime}
                onChange={this.onChange}
                error={messages.cultureAfterTime !== ''}
                inputProps={{ maxLength: 2 }}
              />
              <Typography className={classes.ruleLabel} component="span">分</Typography>
              <FormErrorText>{messages.cultureAfterTime}</FormErrorText>
            </Box>
          </Box>
          <Box mt={1}>
            <FormTitle title="予約ルール" />
            <TextField
              id="memberRule"
              name="memberRule"
              multiline
              fullWidth
              rows={25}
              variant="outlined"
              value={data.memberRule}
              onChange={this.onChange}
              inputProps={{
                maxLength: 30000,
              }}
            />
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default withStyles(styles)(RoomRules);
