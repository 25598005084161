import { ACTION_TYPE } from './action';

const calendarScheduleDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_DELETE_SUCCESS:
      return {
        ...state,
        isBookingDeleted: true,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_FLG_RESET:
      return {
        ...state,
        isCreated: false,
        isSaved: false,
        isDeleted: false,
        isUpdated: false,
        isFrameUpdated: false,
        isRoomCreated: false,
        isBookingDeleted: false,
      };
    case ACTION_TYPE.CALL_EVENT_CALENDAR_LIST_SET_IS_CREATED:
      return {
        ...state,
        isCreated: true,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_BOOKING_SEARCH_SUCCESS:
      return {
        ...state,
        bookingDatas: action.bookingDatas,
      };
    case ACTION_TYPE.CALL_SET_HEADER:
      return {
        ...state,
        header: action.header,
      };
    default:
      return state;
  }
};

export default calendarScheduleDetail;
