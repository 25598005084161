// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_CALENDAR_GOLF_HEADER: 'CALL_API_GET_CALENDAR_GOLF_HEADER',
  CALL_API_GET_CALENDAR_GOLF_HEADER_SUCCESS: 'CALL_API_GET_CALENDAR_GOLF_HEADER_SUCCESS',
  CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE: 'CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE',
  CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE_SUCCESS: 'CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE_SUCCESS',
  CALL_API_GET_GOLF_BOOKING_DETAIL: 'CALL_API_GET_GOLF_BOOKING_DETAIL',
  CALL_API_GET_GOLF_BOOKING_DETAIL_SUCCESS: 'CALL_API_GET_GOLF_BOOKING_DETAIL_SUCCESS',
  CALL_API_CALENDAR_GOLF_FRAME_UPDATE: 'CALL_API_CALENDAR_GOLF_FRAME_UPDATE',
  CALL_API_CALENDAR_GOLF_FRAME_UPDATE_SUCCESS: 'CALL_API_CALENDAR_GOLF_FRAME_UPDATE_SUCCESS',
  CALL_GOLF_BOOKING_RESET_FLG: 'CALL_GOLF_BOOKING_RESET_FLG',
  CALL_API_CALENDAR_GOLF_BULK_UPDATE: 'CALL_API_CALENDAR_GOLF_BULK_UPDATE',
  CALL_API_CALENDAR_GOLF_BULK_UPDATE_SUCCESS: 'CALL_API_CALENDAR_GOLF_BULK_UPDATE_SUCCESS',
};
// #endregion

// #region action
export const actCallGolfBookingResetFlg = () => ({
  type: ACTION_TYPE.CALL_GOLF_BOOKING_RESET_FLG,
});

export const actCallApiGetGolfHeader = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_CALENDAR_GOLF_HEADER,
  param,
});

export const actCallApiGetGolfHeaderSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_CALENDAR_GOLF_HEADER_SUCCESS,
  data,
});

export const actCallApiUpdateGolfPublishDate = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE,
  param,
});

export const actCallApiUpdateGolfPublishDateSuccess = () => ({
  type: ACTION_TYPE.CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE_SUCCESS,
});

export const actCallApiGetGolfBookingDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_BOOKING_DETAIL,
  param,
});

export const actCallApiGetGolfBookingDetailSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_BOOKING_DETAIL_SUCCESS,
  datas,
});

export const actCallApiUpdateGolfFrame = (param) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_GOLF_FRAME_UPDATE,
  param,
});

export const actCallApiUpdateGolfFrameSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_GOLF_FRAME_UPDATE_SUCCESS,
});

export const apiCallUpdateBulkFrameLimit = (data) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_GOLF_BULK_UPDATE,
  data,
});

export const apiCallUpdateBulkFrameLimitSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_GOLF_BULK_UPDATE_SUCCESS,
});

// #endregion
