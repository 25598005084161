import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約枠ヘッダー取得
 * @param id
 */
export function getCalendarHeaderRoom(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.CALENDAR_DETAIL_ROOM.HEADER_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会議室予約枠取得
 * @param id
 */
export function searchRoomBookings(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.CALENDAR_DETAIL_ROOM.ROOM_BOOKING_DETAIL, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会議室予約枠作成
 * @param datas
 */
export function createRoomBookings(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.CALENDAR_DETAIL_ROOM.ROOM_BOOKING_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
