import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog, Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, TextField, LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import BookingMemberDataTable from './BookingMemberDataTable';
import {
  actCallApiBookingDialogMemberList,
  actCallApiInsertBookingMember,
  actApiBookingMemberFlgReset,
} from '../../../redux/booking/bookingDetail/action';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class BookingMemberAddDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: {},
      loading: true,
      changeLoading: false,
      searchMemberNo: '',
      searchMemberName: '',
      searchMemberNameKana: '',
      page: 0,
      records: 10,
      addMemberIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.bookingDetail.members !== this.props.bookingDetail.members) {
      if (this.props.bookingDetail.members) {
        this.setDatas(this.props.bookingDetail.members);
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  setDatas(members) {
    this.setState({ members });
  }

  init = () => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, page, records,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
    this.setState({
      loading: false,
      addMemberIds: [],
      changeLoading: false,
    });
  }

  search = (
    page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
  ) => {
    const { booking } = this.props.bookingDetail;

    this.props.dispatch(
      actCallApiBookingDialogMemberList({
        page,
        records,
        searchStoreId: booking.mstStoreId,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
        bookingId: booking.id,
        contentId: booking.mstContentId,
      }),
    );
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  setCheckedMember = (value) => {
    this.setState({ addMemberIds: value });
  }

  handleSave = () => {
    const { addMemberIds } = this.state;
    const { isCancel } = this.props;
    const { booking } = this.props.bookingDetail;
    this.setState({ changeLoading: true });
    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(actCallApiInsertBookingMember({
      id: booking.id,
      storeId: booking.mstStoreId,
      contentId: booking.mstContentId,
      isRegular: booking.regular,
      addMemberIds,
      targetType: booking.targetType,
      cancel: isCancel,
    }));
  }

  onSearch = () => {
    this.setState({ addMemberIds: [] });
    const {
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  }

  handleChangePage = (_, newPage) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(
      newPage, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchMemberNo, searchMemberName, searchMemberNameKana);
  };

  render() {
    const {
      classes, onClose, open, linkMemberCount, isCancel,
    } = this.props;

    const {
      members, loading, searchMemberNo, searchMemberName, searchMemberNameKana,
      page, records, addMemberIds, changeLoading,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {isCancel ? 'キャンセル待ちの追加' : '予約者の追加' }
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <SearchBox onSearch={this.onSearch}>
              <Box display="flex">
                <Box mr={2} width="20%">
                  <FormTitle title="会員番号" />
                  <TextField id="search-member-no" variant="outlined" fullWidth value={searchMemberNo} name="searchMemberNo" onChange={this.onChange} />
                </Box>
                <Box mr={2} width="20%">
                  <FormTitle title="名前" />
                  <TextField id="search-name" variant="outlined" fullWidth value={searchMemberName} name="searchMemberName" onChange={this.onChange} />
                </Box>
                <Box>
                  <FormTitle title="フリガナ" />
                  <TextField id="search-name-kana" variant="outlined" fullWidth value={searchMemberNameKana} name="searchMemberNameKana" onChange={this.onChange} />
                </Box>
              </Box>
            </SearchBox>
            <BookingMemberDataTable
              rows={members.content ? members.content : []}
              addButtonLabel=""
              deleteButtonLabel=""
              page={page}
              rowsPerPage={records}
              totalElements={members.totalElements}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              deleteDialogTitle=""
              deleteDialogContent=""
              dialogFlg
              onChecked={this.setCheckedMember}
              isFromMemberAdd
            />
          </Container>
        </DialogContent>
        <DialogActions style={{ display: loading ? 'none' : '' }}>
          <LoadingButton loading={changeLoading} onClick={this.handleSave} label="選択した会員を追加する" disabled={addMemberIds.length <= 0 || (!isCancel && addMemberIds.length > linkMemberCount)} />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(BookingMemberAddDialog));
