import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 定期曜日別スケジュール詳細取得
 * @param id
 */
export function searchSchoolWeekSchedule(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.SCHOOL.SCHOOL_WEEK_SCHEDULE.SCHOOL_WEEK_SCHEDULE_DETAIL_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 定期曜日別スケジュール詳細作成/更新
 * @param datas
 */
export function saveSchoolWeekSchedule(data) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.SCHOOL_WEEK_SCHEDULE.SCHOOL_WEEK_SCHEDULE_DETAIL, data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 定期曜日別スケジュール詳細削除
 * @param id
 */
export function deleteSchoolWeekSchedule(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .delete(REST_API.SCHOOL.SCHOOL_WEEK_SCHEDULE.SCHOOL_WEEK_SCHEDULE_DETAIL_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュール詳細取得
 * @param id
 */
export function searchEventWeekSchedule(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_DETAIL_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュール詳細作成
 * @param datas
 */
export function saveEventWeekSchedule(data) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_DETAIL, data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュール詳細削除
 * @param id
 */
export function deleteEventWeekSchedule(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .delete(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_DETAIL_INFO, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
