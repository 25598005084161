import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField, Link, Grid,
  Box, Typography, withStyles,
  CssBaseline, Paper, IconButton,
  Collapse, Backdrop, CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { actCallApiLogin, actLoginDataReset } from '../../../redux/other/login/action';
import urls from '../../../constants/urls';
import logo from '../../../public/assets/logo.svg';
import logoPng from '../../../public/assets/logo.png';

// #region css
const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logoPng})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFFFFF',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '60px',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  error: {
    color: '#f50057',
    lineHeight: '1',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
// #endregion

class Login extends React.Component {
  constructor(props) {
    super(props);
    document.cookie = 'jwt=; path=/; max-age=0';
    localStorage.clear();
    props.dispatch(actLoginDataReset());
    const query = window.location.search;
    this.state = {
      info: {
        email: '',
        password: '',
      },
      message: '',
      loading: false,
      passwordSuccessOpen: query.indexOf('success') !== -1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      let token = '';
      let message = '';
      if (this.props.users.isError === false) {
        token = this.props.users.token.token;
        document.cookie = `jwt=${token}; path=/; max-age=604800`;
        this.props.history.push(urls.OTHER.HOME);
      }
      if (this.props.users.isError === true) {
        message = 'メールアドレスまたはパスワードが間違っています';
      }
      this.setStatus(message);
    }
  }

  setStatus(message) {
    const { info } = this.state;
    this.setState({ message, info: { ...info, password: '' }, loading: false });
  }

  setPasswordSuccessOpen = () => {
    this.setState({ passwordSuccessOpen: false });
  }

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.email || !info.password) {
      return;
    }

    this.props.dispatch(
      actCallApiLogin({
        email: info.email,
        password: info.password,
      }),
    );
  };

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [key]: value } });
  };

  render() {
    const { classes } = this.props;
    const {
      info, message, loading, passwordSuccessOpen,
    } = this.state;

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {loading && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box className={classes.avatar}>
              <img src={logo} alt="logo" />
            </Box>
            <Typography component="h1" variant="h5">ログイン</Typography>
            {message && <div className={classes.error}>{message}</div>}

            <Box my={2} display={passwordSuccessOpen ? '' : 'none'}>
              <Collapse in={passwordSuccessOpen}>
                <Alert
                  action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={this.setPasswordSuccessOpen}><CloseIcon fontSize="inherit" /></IconButton>
                  }
                >
                  パスワードの変更が完了しました
                </Alert>
              </Collapse>
            </Box>
            <form className={classes.form} onSubmit={this.handleBtnLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                autoComplete="off"
                name="email"
                autoFocus
                value={info.email}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                autoComplete="off"
                id="password"
                required
                value={info.password}
                onChange={this.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                className={classes.submit}
              >
                ログイン
              </Button>
            </form>
          </div>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.vertex-group.co.jp/">VERTEX</Link>
              {' '}
              All Rights Reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({ users: state.users });

export default withStyles(styles)(connect(mapStateToProps)(Login));
