import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, getEventSelects, createData, deleteData, copyData,
} from '../../../services/event/eventWeekScheduleList.service';
import {
  ACTION_TYPE,
  actCallInitEventScheduleListSuccess,
  actCallEventScheduleListSearchSuccess,
  actCallEventScheduleListCreateSuccess,
  actCallEventScheduleListDeleteSuccess,
  actCallEventScheduleListCopySuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitEventScheduleList() {
  try {
    const selects = yield call(getEventSelects);
    yield put(actCallInitEventScheduleListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュール一覧検索
 *
 * @param
 */
function* callApiEventScheduleListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallEventScheduleListSearchSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュールヘッダー作成
 *
 * @param
 */
function* callApiEventScheduleListCreate({ params }) {
  try {
    const resultData = yield call(createData, params);
    yield put(actCallEventScheduleListCreateSuccess(resultData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュールヘッダー削除
 *
 * @param
 */
function* callApiEventScheduleListDelete({ deleteList }) {
  try {
    yield call(deleteData, deleteList);
    yield put(actCallEventScheduleListDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 曜日別スケジュールコピー
 *
 * @param
 */
function* callApiEventScheduleListCopy({ params }) {
  try {
    const resultDatas = yield call(copyData, params);
    yield put(actCallEventScheduleListCopySuccess(resultDatas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallEventWeekScheduleList() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_LIST, callApiInitEventScheduleList,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_SEARCH, callApiEventScheduleListSearch,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_CREATE, callApiEventScheduleListCreate,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_DELETE, callApiEventScheduleListDelete,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_COPY, callApiEventScheduleListCopy,
  );
}
