import { ACTION_TYPE } from './action';

const bookings = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_BOOKING_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_BOOKING_SEARCH_CONDITION_SAVE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    case ACTION_TYPE.CALL_API_BOOKING_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_BOOKING_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
      };
    case ACTION_TYPE.CALL_API_BOOKING_SUM_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isSumUpdateSuccess: true,
      };
    case ACTION_TYPE.RESET_UPDATE_SUCCESS_FLG:
      return {
        ...state,
        isSuccess: false,
        isSumUpdateSuccess: false,
      };
    case ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.members,
        isSearchSuccess: true,
      };
    default:
      return state;
  }
};

export default bookings;
