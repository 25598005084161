import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { isShowItem } from './checkShowScreen';

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class HeaderMenuItem extends React.Component {
  onClick = (_, link) => {
    this.props.history.push({
      pathname: link,
    });
    this.props.onClose();
  }

  render() {
    const {
      url, menuName, common, isNotNested, classes, location,
    } = this.props;

    const { user } = common;
    const screenList = user ? user.screenList : [];

    const isShow = isShowItem(screenList, url);
    const urls = location.pathname.split('/');
    const selected = url === `/${urls[1]}`;

    return (
      <ListItem
        className={isNotNested ? '' : classes.nested}
        button
        component="button"
        onClick={(event) => this.onClick(event, url)}
        style={{ display: isShow ? '' : 'none' }}
        selected={selected}
      >
        <ListItemText primary={menuName} />
      </ListItem>
    );
  }
}

HeaderMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  menuName: PropTypes.string.isRequired,
  isNotNested: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
HeaderMenuItem.defaultProps = {
  isNotNested: false,
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(HeaderMenuItem)));
