// #region Type
export const ACTION_TYPE = {
  CALL_API_ROOM_SCHEDULE_GET_SELECTS: 'CALL_API_ROOM_SCHEDULE_GET_SELECTS',
  CALL_API_ROOM_SCHEDULE_GET_SELECT_SUCCESS: 'CALL_API_ROOM_SCHEDULE_GET_SELECT_SUCCESS',
  CALL_API_GET_ROOM_SCHEDULE: 'CALL_API_GET_ROOM_SCHEDULE',
  CALL_API_GET_ROOM_SCHEDULE_SUCCESS: 'CALL_API_GET_ROOM_SCHEDULE_SUCCESS',
  CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST: 'CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST',
  CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST_SUCCESS: 'CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST_SUCCESS',
  CALL_API_ROOM_SCHEDULE_FLG_RESET: 'CALL_API_ROOM_SCHEDULE_FLG_RESET',
  CALL_API_ROOM_SCHEDULE_UPSERT: 'CALL_API_ROOM_SCHEDULE_UPSERT',
  CALL_API_ROOM_SCHEDULE_UPSERT_SUCCESS: 'CALL_API_ROOM_SCHEDULE_UPSERT_SUCCESS',
  CALL_API_GET_ROOM_SCHEDULE_DATA: 'CALL_API_GET_ROOM_SCHEDULE_DATA',
  CALL_API_GET_ROOM_SCHEDULE_DATA_SUCCESS: 'CALL_API_GET_ROOM_SCHEDULE_DATA_SUCCESS',
  CALL_SET_SEARCH_INFO: 'CALL_SET_SEARCH_INFO',
  CALL_API_GET_ROOM_SCHEDULE_DETAIL: 'CALL_API_GET_ROOM_SCHEDULE_DETAIL',
  CALL_API_GET_ROOM_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_GET_ROOM_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_POST_ROOM_MEMBER_UPDATE: 'CALL_API_POST_ROOM_MEMBER_UPDATE',
  CALL_API_POST_ROOM_MEMBER_UPDATE_SUCCESS: 'CALL_API_POST_ROOM_MEMBER_UPDATE_SUCCESS',
  CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL: 'CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL',
  CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL_SUCCESS: 'CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL_SUCCESS',
};
// #endregion

// #region Type
export const actCallApiGetSelects = () => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_GET_SELECTS,
});

export const actCallApiGetSelectsSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_GET_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetRoomSchedule = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE,
  searchCondition,
});

export const actCallApiGetRoomScheduleSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_SUCCESS,
  datas,
});

export const actCallApiRoomScheduleMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST,
  searchCondition,
});

export const actCallApiRoomScheduleMemberListSuccess = (members) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST_SUCCESS,
  members,
});

export const actApiRoomScheduleFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_FLG_RESET,
});

export const actApiRoomScheduleUpsert = (params) => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_UPSERT,
  params,
});

export const actApiRoomScheduleUpsertSuccess = (existData) => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_UPSERT_SUCCESS,
  existData,
});

export const actCallApiGetRoomScheduleData = (id) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DATA,
  id,
});

export const actCallApiGetRoomScheduleDataSuccess = (scheduleData) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DATA_SUCCESS,
  scheduleData,
});

export const actCallApiRoomScheduleDeleteByDeteil = (id) => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL,
  id,
});
export const actCallApiRoomScheduleDeleteByDeteilSuccess = (id) => ({
  type: ACTION_TYPE.CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL_SUCCESS,
  id,
});

export const actSetSearchInfo = (info) => ({
  type: ACTION_TYPE.CALL_SET_SEARCH_INFO,
  info,
});

export const actCallApiGetRoomScheduleDetail = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DETAIL,
  data,
});

export const actCallApiGetRoomScheduleDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DETAIL_SUCCESS,
  data,
});

export const actCallApiPostUpdateRoomMember = (data) => ({
  type: ACTION_TYPE.CALL_API_POST_ROOM_MEMBER_UPDATE,
  data,
});

export const actCallApiPostUpdateRoomMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_POST_ROOM_MEMBER_UPDATE_SUCCESS,
  data,
});
// #endregion
