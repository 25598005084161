import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 予約情報を取得する
 *
 * @param {object} searchCondition
 */
export function getBookingDetail(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約者を取得する
 *
 * @param {object} searchCondition
 */
export function getBookingMembers(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_MEMBERS, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員追加モーダル内の条件による会員検索
 *
 * @param {object} searchCondition
 */
export function getMembers(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_FIND_MEMBERS, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約者追加
 *
 *
 */
export function insertBookingMemberList(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_MEMBERS, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約者削除
 *
 * @param {object}
 */
export function deleteBookingMember(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_MEMBERS, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約ステータスを更新する
 *
 * @param {object} params
 */
export function updateStatus(id, param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_UPDATE, { id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 振替をキャンセルする
 *
 * @param {object} params
 */
export function transferCancel(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_CANCEL, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 体験者を登録する
 *
 * @param {object} params
 */
export function insertExperience(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_DETAIL_EXPERIENCE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 先生へZoomUrlを再送する
 *
 * @param {object} params
 */
export function reSendZoomUrlTeacher(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.BOOKING_DETAIL.RESEND_ZOOMURL_TEACHER, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function createWebinars(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.BOOKING_DETAIL.CREATE_ZOOM_WEBINARS, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約内部メモを更新する
 *
 * @param {object} params
 */
export function updateMemo(id, param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.BOOKING_DETAIL.BOOKING_MEMO_UPDATE, { id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 打席番号をを取得する
 *
 * @param {object} storeId
 */
export function getGolfBoxs(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_DETAIL.GOLF_BOXS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 打席番号を更新する
 *
 * @param {object} params
 */
export function updateGolfBox(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.BOOKING_DETAIL.GOLF_BOX_UPDATE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
