import { ACTION_TYPE } from './action';

const golfSchedule = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GOLF_SCHEDULE_GET_STORES_SUCCESS:
      return {
        ...state,
        select: action.select,
      };
    case ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_GOLF_SCHEDULE_FLG_RESET:
      return {
        ...state,
        isSaveSuccess: false,
        isUpdateSuccess: false,
        isAddSuccess: false,
        isDeleteSuccess: false,
      };
    case ACTION_TYPE.CALL_API_SAVE_GOLF_SCHEDULE_SUCCESS:
      return {
        ...state,
        isSaveSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_ADD_SUCCESS:
      return {
        ...state,
        newData: action.data,
      };
    case ACTION_TYPE.CALL_API_GOLF_SCHEDULE_ADD_SUCCESS:
      return {
        ...state,
        isAddSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GOLF_SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GOLF_SCHEDULE_SET_CURDATE:
      return {
        ...state,
        curDate: action.date,
      };
    default:
      return state;
  }
};

export default golfSchedule;
