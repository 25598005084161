import React from 'react';
import {
  Card, Box, Checkbox, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  checkbox: {
    padding: '0px',
    paddingBottom: '5px',
  },
  card: {
    height: '100px',
  },
}));

export default function WeekSelectBookingCard(props) {
  const classes = useStyles();

  const {
    menuInfo,
    handleChange,
    selectedList,
  } = props;

  const checked = selectedList.filter((id) => id === menuInfo.id).length > 0;

  return (
    <Box mt={2}>
      <Card>
        <Box className={classes.card} alignItems="center" display="flex" bgcolor={menuInfo.exists === false && menuInfo.remainRegister === 0 ? '#C0C0C0' : menuInfo.menuColor} p={1} style={{ wordBreak: 'break-all' }}>
          <Box width="35%" m={0}>
            {
              (
                menuInfo.exists === false
                && menuInfo.remainRegister === 0
              )
                ? ''
                : (
                  <Checkbox className={classes.checkbox} checked={checked} onChange={(event) => handleChange(event, menuInfo)} color="primary" />
                )
            }
            <Box height="45px" justifyContent="center" alignItems="center" display="flex" bgcolor="#fff">
              <Box fontWeight="fontWeightBold" m={0} fontSize={15} align="center">
                <Box width="100%" mt={1} mb={1}>
                  {menuInfo.capacity}
                  名
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width="65%" align="center" p={1} pb={0} pt={0} style={{ wordBreak: 'break-all' }}>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>{menuInfo.menuName}</Box>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>
              {menuInfo.startTime}
              ~
              {menuInfo.endTime}
            </Box>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>
              残り
              {menuInfo.remainRegister}
              名
            </Box>
            {menuInfo.scheduleChanged && (
              <Box fontWeight="fontWeightBold" color="red" m={0} fontSize={15}>
                来月変更有
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
