import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatas, updateHeader } from '../../../services/event/eventWeekScheduleDetail.service';
import { getHeader } from '../../../services/event/eventWeekScheduleList.service';
import {
  ACTION_TYPE,
  actInitEventScheduleDetailSuccess,
  actEventScheduleDetailUpdateSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitEventScheduleDetail(id) {
  try {
    const headerData = yield call(getHeader, id);
    const datas = yield call(getDatas, id);
    yield put(actInitEventScheduleDetailSuccess(headerData, datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * ヘッダー更新
 *
 * @param
 */
function* callApiEventScheduleDetailUpdate(params) {
  try {
    yield call(updateHeader, params);
    yield put(actEventScheduleDetailUpdateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallEventWeekScheduleDetail() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_DETAIL, callApiInitEventScheduleDetail,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_UPDATE, callApiEventScheduleDetailUpdate,
  );
}
