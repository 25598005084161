import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 選択肢を取得する
 *
 * @param {object} searchCondition
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_LIST.BOOKING_SELECT)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.BOOKING_LIST.BOOKING_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約ステータスを更新する
 *
 * @param {object} params
 */
export function updateStatus(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.BOOKING_LIST.BOOKING_LIST, null, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
