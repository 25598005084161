// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_HOME: 'CALL_API_INIT_HOME',
  CALL_API_INIT_HOME_SUCCESS: 'CALL_API_INIT_HOME_SUCCESS',
  ACT_CALL_API_HOME: 'ACT_CALL_API_HOME',
  ACT_CALL_API_HOME_SUCCESS: 'ACT_CALL_API_HOME_SUCCESS',
};
// #endregion

// #region action
export const actCallInitHome = () => ({
  type: ACTION_TYPE.CALL_API_INIT_HOME,
});

export const actInitHomeSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_INIT_HOME_SUCCESS,
  stores,
});

export const actCallApiHome = (storeId) => ({
  type: ACTION_TYPE.ACT_CALL_API_HOME,
  storeId,
});

export const actApiHomeSuccess = (data) => ({
  type: ACTION_TYPE.ACT_CALL_API_HOME_SUCCESS,
  data,
});

// #endregion
