import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * メニュー編集モーダル用リスト取得
 *
 * @param
 */
export function getWeekSheduleMenuSelectList(contentId, storeId) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SHARE.SELECT_LIST, { contentId, storeId })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getStores() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.COMMON.STORES)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
