// #region Type
export const ACTION_TYPE = {
  CALL_API_CALENDAR_SCHEDULE_DELETE: 'CALL_API_CALENDAR_SCHEDULE_DELETE',
  CALL_API_CALENDAR_SCHEDULE_DELETE_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_DELETE_SUCCESS',
  CALL_API_CALENDAR_SCHEDULE_UPDATE: 'CALL_API_CALENDAR_SCHEDULE_UPDATE',
  CALL_API_CALENDAR_SCHEDULE_UPDATE_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_UPDATE_SUCCESS',
  CALL_API_CALENDAR_SCHEDULE_DETAIL: 'CALL_API_CALENDAR_SCHEDULE_DETAIL',
  CALL_API_CALENDAR_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_BOOKING_DELETE: 'CALL_API_BOOKING_DELETE',
  CALL_API_BOOKING_DELETE_SUCCESS: 'CALL_API_BOOKING_DELETE_SUCCESS',
  CALL_API_CALENDAR_SCHEDULE_FLG_RESET: 'CALL_API_CALENDAR_SCHEDULE_FLG_RESET',
  CALL_EVENT_CALENDAR_LIST_SET_IS_CREATED: 'CALL_EVENT_CALENDAR_LIST_SET_IS_CREATED',
  CALL_SET_HEADER: 'CALL_SET_HEADER',
};
// #endregion

// #region action
export const actCallApiCalendarScheduleDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DETAIL,
  param,
});

export const actCallApiCalendarScheduleDetailSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DETAIL_SUCCESS,
  datas,
});

export const actCallApiCalendarScheduleUpdate = (param) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_UPDATE,
  param,
});

export const actCallApiCalendarScheduleUpdateSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_UPDATE_SUCCESS,
});

export const actCallApiCalendarScheduleFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_FLG_RESET,
});

export const actCallEventCalendarListSetIsCreated = () => ({
  type: ACTION_TYPE.CALL_EVENT_CALENDAR_LIST_SET_IS_CREATED,
});

export const actSetHeader = (header) => ({
  type: ACTION_TYPE.CALL_SET_HEADER,
  header,
});

export const actCallApiCalendarScheduleDelete = (id, regular) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DELETE,
  id,
  regular,
});

export const actCallApiCalendarScheduleDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DELETE_SUCCESS,
});

export const actCallApiBookingDelete = (param) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DELETE,
  param,
});

export const actCallApiBookingDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DELETE_SUCCESS,
});
// #endregion
