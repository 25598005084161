const selectValidation = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

const nameValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'content':
      return selectValidation(value);
    case 'name':
      return nameValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
