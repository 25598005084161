import format from 'date-fns/format';
import { isAfter } from 'date-fns';

export function getBackground(data) {
  let background = '#C1FFC1';
  if (data.targetType === 2) {
    background = '#FFC1C1';
  }
  if (data.targetType === 3) {
    background = '#C1C1FF';
  }
  return background;
}

export function getStyle(data, curDate) {
  const today = new Date(format(new Date(), 'yyyy/MM/dd'));
  const targetDate = new Date(curDate);
  const dateCheck = ((targetDate.getTime() === today.getTime()) || isAfter(targetDate, today));

  const startDate = new Date(`1999/01/01 ${data.startTime}`);
  const endDate = new Date(`1999/01/01 ${data.endTime}`);
  const diffDate = (endDate.getTime() - startDate.getTime()) / (60 * 1000) / 30;

  return {
    width: '100%',
    height: `${diffDate * 40}px`,
    background: getBackground(data),
    zIndex: 5,
    cursor: (!dateCheck && data.contentId === 5) ? 'default' : 'move',
    borderRadius: '5px',
    border: 'none',
    color: '#000',
    display: 'block',
  };
}

export function getNewStyle(data, startTime, boxs) {
  const startDate = new Date(`1999/01/01 ${data.startTime}`);
  const baseDate = new Date(`1999/01/01 ${startTime}`);
  const topDiff = (startDate.getTime() - baseDate.getTime()) / (60 * 1000) / 30;
  let index = 0;
  let isExist = false;
  boxs.forEach((value) => {
    if (value.boxNumber === data.boxNumber) {
      isExist = true;
    }
    if (!isExist) {
      index += 1;
    }
  });

  return {
    top: `${topDiff * 40}px`,
    left: `${50 + (100 * index)}px`,
    width: '100%',
    minHeight: '80px',
    maxHeight: '80px',
    backgroundColor: 'rgb(3, 155, 229)',
    cursor: 'default',
    borderRadius: '5px',
    border: '1px solid rgb(3, 155, 229)',
    color: '#fff',
    display: 'block',
    position: 'absolute',
    minWidth: '100px',
    maxWidth: '100px',
    zIndex: 1000,
    boxShadow: '0px 6px 10px 0px rgba(0,0,0,.14), 0px 1px 18px 0px rgba(0,0,0,.12), 0px 3px 5px -1px rgba(0,0,0,.2)',
  };
}

export function getPosition(data, startTime, times) {
  const startDate = new Date(`1999/01/01 ${data.startTime}`);
  const baseDate = new Date(`1999/01/01 ${startTime}`);
  const index = times.length - 2;
  const topDiff = (startDate.getTime() - baseDate.getTime()) / (60 * 1000) / 30;

  return {
    y: -(80 + (index * 40)) + (topDiff * 40),
    x: 0,
  };
}

export function getLessonStyle(lesson) {
  const startDate = new Date(`1999/01/01 ${lesson.startTime}`);
  const endDate = new Date(`1999/01/01 ${lesson.endTime}`);
  const diffDate = (endDate.getTime() - startDate.getTime()) / (60 * 1000) / 30;

  return {
    width: '100%',
    height: `${diffDate * 40}px`,
    background: '#fadbda',
    zIndex: 5,
    borderRadius: '5px',
    border: 'none',
    color: '#000',
    display: 'block',
  };
}
