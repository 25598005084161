import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from '@material-ui/core';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

export default function BookingMemberConfirmDialog(props) {
  const {
    open,
    handleClose,
    subTitle,
    handleExecute,
    loading,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
    >
      <DialogTitle>
        <Box>振替のキャンセル</Box>
        {subTitle && <Box>{subTitle}</Box>}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>振替をキャンセルします。この操作は元に戻すことができません。</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <LoadingButton
          onClick={handleExecute}
          label="振替をキャンセルする"
          loading={loading}
          color="secondary"
        />
      </DialogActions>
    </Dialog>
  );
}

BookingMemberConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
  handleExecute: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

BookingMemberConfirmDialog.defaultProps = {
  subTitle: null,
  loading: false,
};
