import { ACTION_TYPE } from './action';

const rules = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_RULE_GET_STORE_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_RULE_SEARCH_SUCCESS:
      return {
        ...state,
        isGetSuccess: true,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_RULE_UPDATE_SUCCESS:
      return {
        ...state,
        resultDatas: action.resultDatas,
        flg: action.flg,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_RULE_GOLF_TIME_UPDATE_SUCCESS:
      return {
        ...state,
        results: action.results,
        isBox: action.isBox,
        isTimeUpdateSuccess: true,
      };
    case ACTION_TYPE.RESET_RULE_FLG:
      return {
        ...state,
        isGetSuccess: false,
        isUpdateSuccess: false,
        isTimeUpdateSuccess: false,
      };
    case ACTION_TYPE.RULE_DATA_UPDATE:
      return {
        ...state,
        datas: action.update,
      };
    default:
      return state;
  }
};

export default rules;
