// region Type
export const ACTION_TYPE = {
  CALL_API_CHANGE_PASSWORD: 'CALL_API_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  PASSWORD_RESET_FLG: 'PASSWORD_RESET_FLG',
};
// endregion

// region action
export const actCallApiChangePassword = (params) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD,
  params,
});

export const actChangePasswordSuccess = (datas) => ({
  type: ACTION_TYPE.CHANGE_PASSWORD_SUCCESS,
  datas,
});

// 更新成功フラグ初期化
export const actResetUpdateFlg = () => ({
  type: ACTION_TYPE.PASSWORD_RESET_FLG,
});
// endregion
