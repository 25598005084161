import { call, put, takeLatest } from 'redux-saga/effects';
import { getDetailDatas, insertSchoolScheduleCopy } from '../../../services/school/schoolWeekScheduleDetail.service';
import {
  searchSchoolScheduleHeaderInfo, insertSchoolSchedule,
} from '../../../services/school/schoolWeekScheduleList.service';
import {
  ACTION_TYPE,
  actInitSchoolScheduleDetailSuccess,
  actInsertApiSchoolScheduleSuccess,
  actInsertApiSchoolScheduleCopySuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitSchoolScheduleDetail(id) {
  try {
    const headerData = yield call(searchSchoolScheduleHeaderInfo, id);
    const datas = yield call(getDetailDatas, id);
    yield put(actInitSchoolScheduleDetailSuccess(headerData, datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 定期曜日別スケジュール追加
 *
 * @param
 */
function* callApiSchoolScheduleListInsert({ param }) {
  try {
    const data = yield call(insertSchoolSchedule, param);
    yield put(actInsertApiSchoolScheduleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 定期曜日別スケジュールコピー
 *
 * @param
 */
function* callApiSchoolScheduleListCopy({ param }) {
  try {
    const data = yield call(insertSchoolScheduleCopy, param);
    yield put(actInsertApiSchoolScheduleCopySuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSchoolWeekScheduleDetail() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_INIT_SCHOOL_SCHEDULE_DETAIL, callApiInitSchoolScheduleDetail,
  );
  yield takeLatest(ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_INSERT,
    callApiSchoolScheduleListInsert);
  yield takeLatest(ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_COPY,
    callApiSchoolScheduleListCopy);
}
