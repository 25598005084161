import React from 'react';
import connect from 'react-redux/es/connect/connect';
import format from 'date-fns/format';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  LinearProgress,
} from '@material-ui/core';
import Validation from '../validation';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import RequiredChip from '../../../../components/atoms/requiredChip/RequiredChip';
import DateTimePicker from '../../../../components/atoms/dateTimePicker/DateTimePicker';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import {
  actCallApiSchoolCalendarListModalData, actCallApiSchoolCalendarListCreate,
  actCallApiSchoolCalendarListFlgReset,
} from '../../../../redux/school/schoolCalenderList/action';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';

class SchoolCalendarListSaveDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      isError: false,
      publishedDate: null,
      errorMessage: '',
      data: {
        id: '',
        contentId: '',
        contentName: '',
        targetedOn: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saveOpen !== this.props.saveOpen) {
      if (this.props.saveOpen) {
        this.init();
      }
    }
    if (prevProps.schoolCalendarList.isGetModalSuccess
      !== this.props.schoolCalendarList.isGetModalSuccess) {
      if (this.props.schoolCalendarList.isGetModalSuccess) {
        this.setModalData(this.props.schoolCalendarList.modalData);
      }
    }
  }

  setModalData(modalData) {
    let isError = true;
    let data = {
      id: '',
      contentId: '',
      contentName: '',
      targetedOn: null,
    };
    if (modalData) {
      data = modalData;
      isError = false;
    }
    this.setState({
      data, isError, loading: false, btnLoading: false,
    });
  }

  onPublishedDateChange = (publishedDate) => {
    this.setState({
      publishedDate,
      errorMessage: Validation.formValidate('date', publishedDate),
    });
  }

  onCalendarSave = () => {
    const {
      publishedDate, data,
    } = this.state;
    const { storeId } = this.props.selectedRow;
    const errorMessage = Validation.formValidate('date', publishedDate);
    this.setState({ errorMessage });

    if (errorMessage) {
      return;
    }
    this.setState({ btnLoading: true });
    this.props.dispatch(actCallApiSchoolCalendarListCreate(
      {
        id: data.id,
        storeId,
        contentId: data.contentId,
        targetedOn: data.targetedOn,
        publishedDate,
      },
    ));
  }

  init() {
    const { contentId, storeId, targetDate } = this.props.selectedRow;
    this.props.dispatch(actCallApiSchoolCalendarListFlgReset());
    this.props.dispatch(actCallApiSchoolCalendarListModalData({
      searchStoreId: storeId,
      searchContentId: contentId,
      searchDate: targetDate,
    }));
    this.setState({
      loading: true,
      btnLoading: true,
      isError: false,
      publishedDate: null,
    });
  }

  render() {
    const {
      loading,
      btnLoading,
      publishedDate,
      isError,
      data,
      errorMessage,
    } = this.state;

    const {
      saveOpen,
      onCalendarModalClose,
    } = this.props;

    return (
      <Dialog
        open={saveOpen}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableBackdropClick
        onEscapeKeyDown={onCalendarModalClose}
      >
        <DialogTitle error={isError}>
          {isError ? 'エラー' : '予約カレンダーの作成'}
        </DialogTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: isError || loading ? 'none' : '' }}>
          <Box fontSize={17}>以下の曜日別スケジュールより予約カレンダーを作成します。</Box>
          <Box display="flex" mt={3}>
            <Box mb={3} mr={6}>
              <Box fontWeight="fontWeightBold" fontSize={15} mb={1}>
                対象年月
              </Box>
              <Box fontSize={15}>
                {format(new Date(data.targetedOn), 'yyyy年MM月')}
              </Box>
            </Box>
            <Box mb={3} mr={6}>
              <Box fontWeight="fontWeightBold" fontSize={15} mb={1}>
                コンテンツ
              </Box>
              <Box fontSize={15}>
                {data.contentName}
              </Box>
            </Box>
          </Box>
          <Box mb={3}>
            <Box fontWeight="fontWeightBold" fontSize={15} mb={1}>
              公開日
              <RequiredChip />
            </Box>
            <DateTimePicker
              id="publishDate"
              handleChange={(_, value) => this.onPublishedDateChange(value)}
              value={publishedDate}
              error={errorMessage !== ''}
            />
            <FormErrorText>{errorMessage}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogContent dividers style={{ display: !isError || loading ? 'none' : '' }}>
          <Box>
            曜日別スケジュールが作成されていません。
          </Box>
          <Box>
            作成後、再度適用を行ってください。
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display={isError ? 'none' : 'flex'}>
            <Button onClick={onCalendarModalClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.onCalendarSave} label="作成する" loading={btnLoading} />
          </Box>
          <Box display={isError ? '' : 'none'}>
            <Button onClick={onCalendarModalClose}>
              閉じる
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolCalendarList: state.schoolCalendarList,
});

export default connect(mapStateToProps)(SchoolCalendarListSaveDialog);
