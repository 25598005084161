import { ACTION_TYPE } from './action';

// region Reducer
const eventCalendarList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_EVENT_CALENDAR_LIST_SUCCESS:
      return {
        ...state,
        searchSelects: action.searchSelects,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        weekSchedules: action.weekSchedules,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK_SUCCESS:
      return {
        ...state,
        reservationFlg: action.reservationFlg,
        resetFlg: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESET_SUCCESS:
      return {
        ...state,
        deletedFlg: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK_SUCCESS:
      return {
        ...state,
        roomCultureCheckFlg: action.roomCultureCheckFlg,
        checkedFlg: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_CREATE_SUCCESS:
      return {
        ...state,
        response: action.response,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_FLG_RESET:
      return {
        ...state,
        deletedFlg: false,
        resetFlg: false,
        checkedFlg: false,
        isCreateSuccess: false,
      };
    case ACTION_TYPE.SET_SEARCH_EVENT_CALENDAR_SCHEDULE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    default:
      return state;
  }
};

export default eventCalendarList;
