import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSelects,
  getDatas, entryCreate,
} from '../../../services/info/attendanceEntry.service';
import {
  ACTION_TYPE,
  actCallApiAttendanceEntrySelectSuccess,
  actCallApiGetAttendanceEntrySuccess,
  actCallApiCreateAttendanceEntrySuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 */
function* callApiGetSelectList() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallApiAttendanceEntrySelectSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠データ取得
 */
function* callApiGetAttendanceEntryList({ param }) {
  try {
    const datas = yield call(getDatas, param);
    yield put(actCallApiGetAttendanceEntrySuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 出席登録
 * @param {} param0
 */
function* callApiCreateAttendanceEntry({ param }) {
  try {
    const result = yield call(entryCreate, param);
    yield put(actCallApiCreateAttendanceEntrySuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallAttendanceEntry() {
  yield takeLatest(ACTION_TYPE.CALL_API_ATTENDANCE_ENTRY_SELECT, callApiGetSelectList);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ATTENDANCE_ENTRY_LIST, callApiGetAttendanceEntryList);
  yield takeLatest(
    ACTION_TYPE.CALL_API_CREATE_ATTENDANCE_ENTRY, callApiCreateAttendanceEntry,
  );
}
