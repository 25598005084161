import React, { Component } from 'react';
import {
  Box, Container, withStyles, Card, Divider, Dialog, DialogContent,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import Sound from 'react-sound';
import phoneLogo from '../../../public/assets/phoneLogo.svg';
import success from '../../../public/assets/success.mp3';
import error from '../../../public/assets/error.mp3';
import { actCallApiSearchRooms, actCallApiAttend, actisUpdateReset } from '../../../redux/info/classInfomation/aciton';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
  elcLogo: {
    width: '45%',
  },
  marginTop: {
    marginTop: 5,
  },
  alert: {
    width: '80%',
    margin: '0 auto',
    justifyContent: 'center',
    marginBottom: 30,
  },
  tableContainer: {
    maxHeight: '400px',
    transform: 'translateZ(0)',
  },
  container: {
    padding: 10,
  },
  text: {
    position: 'relative',
    top: '10px',
    left: '10px',
    zIndex: '-1',
  },
  center: {
    textAlign: 'center',
  },
  largeFont: {
    fontSize: 30,
  },
  font: {
    fontSize: 20,
  },
  textContainer: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
});

class ClassInfomationDetail extends Component {
  constructor(props) {
    super(props);

    const storeId = props.common.user.store.id;
    const {
      searchRooms, date, ids,
    } = props.location.state;

    this.props.dispatch(actCallApiSearchRooms(ids, date, storeId));

    const day = format(new Date(date), 'yyyy年MM月dd日');

    const today = new Date();
    this.isSendApi = false;
    this.state = {
      searchRooms,
      date,
      day,
      today,
      isAttend: 1,
      dialogOpen: false,
      storeId,
      datas: [],
      playing: 'STOPPED',
      music: success,
      info: '',
    };
    this.intervalId = setInterval(() => {
      this.update();
    }, 120000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classInfomation.datas !== this.props.classInfomation.datas) {
      if (this.props.classInfomation.datas) {
        this.setDatas(this.props.classInfomation.datas);
      }
    }
    if (prevProps.classInfomation.isUpdate !== this.props.classInfomation.isUpdate) {
      if (this.props.classInfomation.isUpdate) {
        this.setIsAttend(this.props.classInfomation.isAttend);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setDatas(datas) {
    const { date } = this.state;
    const today = format(new Date(), 'yyyy/MM/dd HH:mm');

    const result = datas.map((data) => {
      const param = data;
      const startTime = format(new Date(new Date(`${date} ${param.start}`) - 600000), 'yyyy/MM/dd HH:mm');
      const endTime = format(new Date(`${date} ${param.end}`), 'yyyy/MM/dd HH:mm');

      param.isReception = false;
      if (startTime <= today && today <= endTime) {
        param.isReception = true;
      }
      return param;
    });
    this.setState({ datas: result });
  }

  setIsAttend(isAttend) {
    let music = success;
    const playing = 'PLAYING';
    const dialogOpen = true;
    let timeOut = 5000;

    if (isAttend !== 1) {
      music = error;
      timeOut = 10000;
    }
    window.setTimeout(() => { this.setComplete(); }, timeOut);
    this.setState({
      isAttend, playing, dialogOpen, music,
    });
  }

  setComplete() {
    this.memberNoRef.onChange = null;
    this.setState({ dialogOpen: false, playing: 'STOPPED', info: '' });
    this.isSendApi = false;
  }

  update = () => {
    const { date, searchRooms } = this.props.location.state;
    const { storeId } = this.state;
    const ids = [];
    searchRooms.map((room) => ids.push(room.id));
    this.props.dispatch(actCallApiSearchRooms(ids, date, storeId));
  }

  onIdChange = (event) => {
    const { value } = event.target;
    const {
      today, day, datas, date,
    } = this.state;
    if (this.isSendApi) {
      event.preventDefault();
      return;
    }
    const checkDate = format(new Date(today), 'yyyy年MM月dd日');
    let memberNo = value;
    if (checkDate === day && memberNo.length === 10) {
      this.isSendApi = true;
      this.props.dispatch(actisUpdateReset());
      const ids = [];
      datas.map((data) => ids.push(data.id));
      this.props.dispatch(actCallApiAttend(
        ids, value, date,
      ));
      memberNo = '';
    } else {
      this.isSendApi = false;
    }
    this.setState({ info: memberNo, dialogOpen: false });
  }

  onIdBlur = (event) => {
    event.preventDefault();
    if (this.isSendApi) {
      return;
    }
    this.memberNoRef.focus();
  }

  render() {
    const { classes } = this.props;
    const {
      searchRooms, day, datas, info, isAttend, dialogOpen, music, playing,
    } = this.state;
    return (
      <div className={classes.root}>
        <Sound
          url={music}
          playStatus={playing}
        />
        <Container className={classes.container}>
          <Card variant="outlined">
            <Box p={3} textAlign="center">
              <Box fontWeight="fontWeightBold" fontSize={36}>
                {day}
                のスケジュール
              </Box>
              <Divider />
              <Divider className={classes.marginTop} />
              <Box mt={4}>
                {searchRooms.map((room) => (
                  <Box key={room.id} mt={1} fontWeight="fontWeightBold" fontSize={36} style={{ wordBreak: 'break-all' }}>
                    {room.name}
                  </Box>
                ))}
              </Box>
            </Box>
            <Alert icon={<img src={phoneLogo} alt="リーダー" width="22px" height="22px" />} severity="error" className={classes.alert}>会員カードをQR読込端末にかざしてください</Alert>
            <Box display={datas.length > 0 ? '' : 'none'}>
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>開始時間</TableCell>
                      <TableCell>終了時間</TableCell>
                      <TableCell>教室名</TableCell>
                      <TableCell>講師</TableCell>
                      <TableCell>Room</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {datas.map((room) => (
                      <TableRow key={room.id}>
                        <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                          {room.start}
                        </TableCell>
                        <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                          {room.end}
                        </TableCell>
                        <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                          {room.menuName}
                        </TableCell>
                        <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                          {room.teacher}
                        </TableCell>
                        <TableCell className={room.isReception ? classes.largeFont : classes.font}>
                          {room.roomName}
                        </TableCell>
                        <TableCell>
                          <Box display={room.isReception ? '' : 'none'}>
                            <Chip color="secondary" label="受付中" />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display={datas.length > 0 ? 'none' : ''}>
              <Box fontWeight="fontWeightBold" fontSize={36} className={classes.center}>予定されている講義がありません</Box>
            </Box>
            <Box>
              <Box fontWeight="fontWeightBold" mt={3} fontSize={15}>＜注意事項＞</Box>
              <Box fontWeight="fontWeightBold" m={1} fontSize={15}>受講者様は受付で入館処理をお済ませください。</Box>
              <Box fontWeight="fontWeightBold" m={1} fontSize={15}>入室は教室開始10分前からとさせていただきます。</Box>
            </Box>
            <Box className={classes.textContainer}>
              <TextField
                id="member_no"
                value={info}
                autoComplete="off"
                autoFocus
                onChange={this.onIdChange}
                onBlur={this.onIdBlur}
                inputRef={(node) => { this.memberNoRef = node; }}
              />
            </Box>
          </Card>
          <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
            <DialogContent dividers>
              {isAttend === 1 && (
                <Box>認証が完了しました。</Box>
              )}
              {isAttend === 0 && (
                <Box color="#ff0000">認証に失敗しました。恐れ入りますが、受付にお問い合わせください。</Box>
              )}
              {isAttend === 2 && (
                <Box color="#ff0000">教材費をお支払いの上、入室ください。</Box>
              )}
            </DialogContent>
          </Dialog>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  classInfomation: state.classInfomation,
  common: state.common,
});
export default withStyles(styles)(connect(mapStateToProps)(ClassInfomationDetail));
