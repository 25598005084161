import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  TextField, Box, Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import format from 'date-fns/format';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import urls from '../../../constants/urls';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import BookingListDataTable from './BookingListDataTable';
import BookingUpdateStatusDialog from './BookingUpdateStatusDialog';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

import {
  actCallInitBookingSearchList,
  actCallApiBookingListSearch,
  actResetUpdateSuccessFlg,
  actCallSearchConditionSave,
} from '../../../redux/booking/bookingList/action';

class BookingList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallInitBookingSearchList());

    this.breadcrumbsText = [
      { name: '予約検索' },
    ];

    this.headCells = [
      { id: 'targetDate', numeric: false, label: '実施日' },
      { id: 'targetTime', numeric: false, label: '時間' },
      { id: 'contentName', numeric: false, label: 'コンテンツ' },
      { id: 'menuName', numeric: false, label: 'メニュー' },
      { id: 'reservationNum', numeric: false, label: '予約数' },
      { id: 'capacity', numeric: false, label: '定員' },
      { id: 'statusName', numeric: false, label: 'ステータス' },
    ];

    let tempSearchCondition = {
      page: 0,
      records: 10,
      searchStartDate: format(new Date(), 'yyyy/MM/dd'),
      searchEndDate: format(new Date(), 'yyyy/MM/dd'),
      searchStartTime: '',
      searchEndTime: '',
      searchStore: props.common.user ? props.common.user.store : null,
      searchContents: [],
      searchMenu: null,
      searchTeacher: null,
      searchFree: false,
    };

    const { searchCondition } = props.booking;

    if (searchCondition) {
      tempSearchCondition = searchCondition;
    }

    this.state = {
      isMain: props.common.user ? props.common.user.main : false,
      selects: {
        stores: [],
        contents: [],
        menus: [],
        teachers: [],
        storeContentLinks: [],
        status: {},
      },
      searchCondition: tempSearchCondition,
      datas: {},
      statusSuccessSnackOpen: false,
      updateStatusModal: false,
      targetReservationInfo: {},
      snackMessage: '',
    };
    this.search(tempSearchCondition);
  }

  componentDidMount() {
    document.title = '予約検索';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }

    if (prevProps.booking.selects !== this.props.booking.selects) {
      if (this.props.booking.selects) {
        this.setSelects(this.props.booking.selects);
      }
    }

    if (prevProps.booking.datas !== this.props.booking.datas) {
      if (this.props.booking.datas) {
        this.setDatas(this.props.booking.datas);
      }
    }

    if (prevProps.booking.isSuccess !== this.props.booking.isSuccess) {
      if (this.props.booking.isSuccess) {
        this.handleModalClose();
        this.onStatusUpdateSuccess(`${this.state.targetReservationInfo.statusCode === 0 ? '休講処理' : '休講取消'}が完了しました`);
      }
    }

    if (prevProps.booking.isSumUpdateSuccess !== this.props.booking.isSumUpdateSuccess) {
      if (this.props.booking.isSumUpdateSuccess) {
        this.onStatusUpdateSuccess('休講処理が完了しました');
      }
    }
  }

  setUser(user) {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchStore: user.store,
    };
    this.setState({ isMain: user.main, searchCondition: tempSearchCondition });
    this.search(tempSearchCondition);
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setDatas(datas) {
    this.setState({ datas: { ...datas, content: datas.content } });
  }

  setSearchConditionSave = (searchCondition) => {
    this.props.dispatch(actCallSearchConditionSave(searchCondition));
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempSearchCondition });
    this.search(tempSearchCondition);
  }

  onSearchClear = () => {
    this.setState({
      searchCondition: {
        page: 0,
        records: 10,
        searchStartDate: null,
        searchEndDate: null,
        searchStartTime: '',
        searchEndTime: '',
        searchStore: this.props.common.user ? this.props.common.user.store : null,
        searchContents: [],
        searchMenu: null,
        searchTeacher: null,
        searchFree: false,
      },
    });
  }

  onItemChange = (event) => {
    const { searchCondition } = this.state;
    const { name, value } = event.target;
    const tempSearchCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onStoreChange = (event) => {
    const { searchCondition } = this.state;
    const { name, value } = event.target;
    const tempSearchCondition = {
      ...searchCondition,
      [name]: value,
      searchContents: [],
      searchMenu: null,
      searchTeacher: null,
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onFreeChange = (event) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchFree: event.target.checked,
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onDateChange = (value, name) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onTodayClick = () => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchStartDate: format(new Date(), 'yyyy/MM/dd'),
      searchEndDate: format(new Date(), 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onTomorrowClick = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchStartDate: format(date, 'yyyy/MM/dd'),
      searchEndDate: format(date, 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  handleClickNowTime = () => {
    const date = new Date();
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchStartTime: format(date, 'HH:mm'),
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onStatusClick = (_, row) => {
    this.props.dispatch(actResetUpdateSuccessFlg());

    this.setState({
      updateStatusModal: true,
      statusSuccessSnackOpen: false,
      targetReservationInfo: row,
    });
  }

  onDetailClick = (_, row) => {
    const breadcrumbsObj = [
      {
        url: urls.BOOKING.BOOKING_LIST,
        name: '予約検索',
      },
      { name: row.isRegular ? '定期予約検索詳細' : '予約検索詳細' },
    ];

    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_DETAIL,
      state: {
        bookingInfo: row,
        breadcrumbsObj,
      },
    });
  }

  onStatusUpdateSuccess = (message) => {
    this.setState({ statusSuccessSnackOpen: true, snackMessage: message });

    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  handleModalClose = () => {
    this.setState({ updateStatusModal: false });
  }

  handleUpdateSuccessSnackbarClose = () => {
    this.setState({ statusSuccessSnackOpen: false });
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempSearchCondition });

    this.search(tempSearchCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const { searchCondition } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempSearchCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };

    this.search(tempSearchCondition);
  };

  getContents = (selects, searchStore) => {
    if (!searchStore || selects.contents.length <= 0) {
      return selects.contents || [];
    }

    const linkedContents = selects.storeContentLinks.filter(
      (link) => link.mstStoreId === searchStore.id,
    ).map((link) => link.mstContentId);
    return selects.contents.filter((content) => linkedContents.includes(content.id));
  }

  search = (searchCondition) => {
    const searchContentList = searchCondition.searchContents.map((n) => n.id);

    this.setSearchConditionSave(searchCondition);
    this.props.dispatch(
      actCallApiBookingListSearch({
        ...searchCondition,
        searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
        searchContentsIds: searchContentList && searchContentList,
        searchMenuId: searchCondition.searchMenu && searchCondition.searchMenu.id,
        searchTeacherId: searchCondition.searchTeacher && searchCondition.searchTeacher.id,
        isSearchFree: searchCondition.searchFree,
      }),
    );
  }

  render() {
    const {
      isMain,
      selects,
      searchCondition,
      datas,
      statusSuccessSnackOpen,
      updateStatusModal,
      targetReservationInfo,
      snackMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch} onSecondFunc={this.onSearchClear} onSecondFuncText="クリアする">
            <Box display={isMain ? '' : 'none'}>
              <FormTitle title="店舗" />
              <SearchSelectBox options={selects.stores} value={searchCondition.searchStore} onChange={this.onStoreChange} name="searchStore" />
            </Box>
            <Box mt={3}>
              <FormTitle title="実施日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker value={searchCondition.searchStartDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchStartDate')} />
                </Box>
                <Box mr={4} ml={4}>～</Box>
                <Box>
                  <Datepicker value={searchCondition.searchEndDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchEndDate')} />
                </Box>
                <Box mr={2} ml={2}>
                  <Button variant="outlined" size="large" onClick={this.onTodayClick}>今日</Button>
                </Box>
                <Box>
                  <Button variant="outlined" size="large" onClick={this.onTomorrowClick}>明日</Button>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="時間" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <TextField
                    type="time"
                    variant="outlined"
                    name="searchStartTime"
                    value={searchCondition.searchStartTime}
                    onChange={this.onItemChange}
                  />
                </Box>
                <Box mr={2} ml={2}>～</Box>
                <Box>
                  <TextField
                    type="time"
                    variant="outlined"
                    name="searchEndTime"
                    value={searchCondition.searchEndTime}
                    onChange={this.onItemChange}
                  />
                </Box>
                <Box ml={2}>
                  <Button variant="outlined" size="large" onClick={this.handleClickNowTime}>現在時刻</Button>
                </Box>
              </Box>
            </Box>
            <Box width="80%" mt={3}>
              <FormTitle title="コンテンツ" />
              <SearchMultiSelectBox
                name="searchContents"
                value={searchCondition.searchContents}
                options={this.getContents(selects, searchCondition.searchStore)}
                onChange={this.onItemChange}
              />
            </Box>
            <Box display="flex" mt={3}>
              <Box width="45%">
                <FormTitle title="メニュー" />
                <SearchSelectBox
                  options={
                    searchCondition.searchStore ? selects.menus.filter(
                      (key) => key.mstOrganizationCompanyId
                        === searchCondition.searchStore.mstOrganizationCompanyId,
                    ) : selects.menus
                  }
                  value={searchCondition.searchMenu}
                  onChange={this.onItemChange}
                  name="searchMenu"
                />
              </Box>
              <Box ml={4} width="45%">
                <FormTitle title="先生" />
                <SearchSelectBox
                  options={
                    searchCondition.searchStore ? selects.teachers.filter(
                      (key) => key.mstStoreId === searchCondition.searchStore.id,
                    ) : selects.teachers
                  }
                  value={searchCondition.searchTeacher}
                  onChange={this.onItemChange}
                  name="searchTeacher"
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={searchCondition.seachFree} onChange={this.onFreeChange} />}
                label="空きスケジュールのみ"
              />
            </Box>
          </SearchBox>

          <BookingListDataTable
            rows={datas.content ? datas.content : []}
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            onDetailClick={this.onDetailClick}
            onStatusClick={this.onStatusClick}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            headCells={this.headCells}
          />

          <SuccessSnackbar
            open={statusSuccessSnackOpen}
            handleClose={this.handleUpdateSuccessSnackbarClose}
            message={snackMessage}
          />

          <BookingUpdateStatusDialog
            open={updateStatusModal}
            handleClose={this.handleModalClose}
            info={targetReservationInfo}
          />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ booking: state.booking, common: state.common });

export default withRouter(connect(mapStateToProps)(BookingList));
