import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員追加モーダル内の条件による会員検索
 *
 * @param {object} searchCondition
 */
export function getMembers(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_FIND_MEMBERS, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function insertRoomSchedule(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_INSERT, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateRoomSchedule(id, param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_UPDATE, { id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRoomScheduleData(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_FIND_SCHEDULE, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRoomMmebers(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_DETAIL_ID, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateRoomMember(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_UPDATE_MEMBER, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function deleteRoomScheduleByDetail(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .delete(REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_DELETE, { id })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
