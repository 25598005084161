export const ACTION_TYPE = {
  CALL_API_INIT_EVENT_SCHEDULE_DETAIL: 'CALL_API_INIT_EVENT_SCHEDULE_DETAIL',
  CALL_API_INIT_EVENT_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_INIT_EVENT_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_EVENT_SCHEDULE_DETAIL_UPDATE: 'CALL_API_EVENT_SCHEDULE_DETAIL_UPDATE',
  CALL_API_EVENT_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_EVENT_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_CREATED: 'CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_CREATED',
  CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_COPIED: 'CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_COPIED',
  CALL_API_EVENT_SCHEDULE_DETAIL_FLG_RESET: 'CALL_API_EVENT_SCHEDULE_DETAIL_FLG_RESET',
};

export const actCallInitEventScheduleDetail = (id) => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_DETAIL,
  id,
});

export const actInitEventScheduleDetailSuccess = (headerData, datas) => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_DETAIL_SUCCESS,
  headerData,
  datas,
});

export const actApiEventScheduleDetailUpdate = (params) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_UPDATE,
  params,
});

export const actEventScheduleDetailUpdateSuccess = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SUCCESS,
});

export const actCallEventScheduleDetailSetIsCreated = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_CREATED,
});

export const actCallEventScheduleDetailSetIsCopied = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_COPIED,
});

export const actApiEventScheduleDetailFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_FLG_RESET,
});
