import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Button, withStyles,
} from '@material-ui/core';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import urls from '../../../../constants/urls';
import EventCalendarListSaveDialog from './EventCalendarListSaveDialog';
import EventCalendarListResetDialog from './EventCalendarListResetDialog';
import EventCalendarListDuplicateDialog from './EventCalendarListDuplicateDialog';
import {
  actCallEventCalendarListSetIsCreated,
} from '../../../../redux/share/calendarScheduleDetail/action';
import {
  actCallApiEventCalendarListFlgReset,
} from '../../../../redux/event/eventCalendarList/action';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  paperTabel: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
});

class EventCalendarListTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetModalOpen: false,
      resetSuccessSnackOpen: false,
      selectedRow: {},
      saveModalOpen: false,
      duplicateDialogOpen: false,
      duplicateMsg: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventCalendarList.deletedFlg !== this.props.eventCalendarList.deletedFlg) {
      if (this.props.eventCalendarList.deletedFlg) {
        this.setDeletedSuccess(this.props.eventCalendarList.deletedFlg);
      }
    }
    if (prevProps.eventCalendarList.isCreateSuccess !== this.props.eventCalendarList.isCreateSuccess
      && this.props.eventCalendarList.isCreateSuccess) {
      this.setResultData(this.props.eventCalendarList.response);
    }
  }

  setDeletedSuccess(deletedFlg) {
    this.setState({
      resetSuccessSnackOpen: deletedFlg,
      resetModalOpen: false,
    });
    this.props.onSearch();
  }

  setResultData = (response) => {
    let resultDatasId = response;
    if (this.state.selectedRow.contentId === 17) {
      resultDatasId = response.resultDatasId;
    }
    this.props.dispatch(actCallApiEventCalendarListFlgReset());

    if (resultDatasId) {
      this.props.dispatch(actCallEventCalendarListSetIsCreated());
      this.onDetail(null, this.state.selectedRow, resultDatasId);
    } else {
      this.onSaveModalClose();
      this.onDuplicateModalOpen(response.errMsgEntity);
    }
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ resetSuccessSnackOpen: false });
  }

  onResetModalClose = () => {
    this.setState({ resetModalOpen: false });
  }

  onSaveModalOpen = (_, row) => {
    this.setState({
      saveModalOpen: true,
      resetSuccessSnackOpen: false,
      selectedRow: row,
    });
  }

  onSaveModalClose = () => {
    this.setState({
      saveModalOpen: false,
    });
  }

  onResetModalOpen = (_, row) => {
    this.setState({
      resetModalOpen: true,
      resetSuccessSnackOpen: false,
      selectedRow: row,
    });
  }

  onDetail = (_, row, id) => {
    const {
      storeId, contentId, targetDate,
    } = row;
    const { isMain } = this.props;

    let pathname = urls.EVENT.EVENT_CALENDARS_DETAIL;
    if (contentId === 5) {
      pathname = urls.EVENT.ROOM_CALENDAR_DETAIL;
    }
    if (contentId === 2) {
      pathname = urls.EVENT.GOLF_CALENDAR_DETAIL;
    }
    this.props.history.push({
      pathname,
      state: {
        headerId: id, isMain, storeId, contentId, targetDate, regular: false,
      },
    });
  }

  onDuplicateModalOpen = (msgList) => {
    this.setState({
      duplicateDialogOpen: true,
      duplicateMsg: msgList,
    });
  }

  handleCloseDuplicateModal = () => {
    this.setState({
      duplicateDialogOpen: false,
      duplicateMsg: [],
    });
  }

  render() {
    const {
      classes, datas,
    } = this.props;

    const {
      resetModalOpen,
      resetSuccessSnackOpen, saveModalOpen, selectedRow,
      duplicateDialogOpen, duplicateMsg,
    } = this.state;

    const contentId = datas.length > 0 ? datas[0].contentId : 0;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperTabel}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead className={classes.header}>
                <TableRow className={classes.headerRow}>
                  <TableCell key="month-cell" align="left" padding="default">
                    月
                  </TableCell>
                  <TableCell key="content-cell" align="left" padding="default">
                    コンテンツ
                  </TableCell>
                  <TableCell key="name-cell" align="left" padding="default" style={{ display: contentId !== 5 ? '' : 'none' }}>
                    適用済み曜日別スケジュール
                  </TableCell>
                  <TableCell key="date-cell" align="left" padding="default">
                    公開日
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.targetMonth}
                  >
                    <TableCell component="th" scope="row" padding="default">
                      {`${row.targetMonth}月`}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      {row.contentName}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default" style={{ display: contentId !== 5 ? '' : 'none' }}>
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      {row.publishedOn}
                    </TableCell>
                    {row.id ? (
                      <TableCell component="th" id={row.id} scope="row" padding="default" className={classes.tableCell}>
                        <Button color="primary" onClick={(event) => this.onDetail(event, row, row.id)}>詳細を見る</Button>
                        <Button color="secondary" onClick={(event) => this.onResetModalOpen(event, row)}>適用をリセットする</Button>
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row" padding="default" className={classes.tableCell}>
                        <Button color="primary" onClick={(event) => this.onSaveModalOpen(event, row)}>適用する</Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <EventCalendarListSaveDialog
          open={saveModalOpen}
          selectedRow={selectedRow}
          onClose={this.onSaveModalClose}
          onSaveModalClose={this.onSaveModalClose}
        />

        <EventCalendarListResetDialog
          open={resetModalOpen}
          selectedRow={selectedRow}
          onResetModalClose={this.onResetModalClose}
        />

        <SuccessSnackbar
          open={resetSuccessSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message="予約枠のリセットが完了しました"
        />
        <EventCalendarListDuplicateDialog
          open={duplicateDialogOpen}
          handleClose={this.handleCloseDuplicateModal}
          contentText={duplicateMsg}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventCalendarList: state.eventCalendarList,
  calendarDetailRoom: state.calendarDetailRoom,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventCalendarListTable)));
