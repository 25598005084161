import { ACTION_TYPE } from './action';

const notices = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_NOTICE_LIST_SUCCESS:
      return {
        ...state,
        select: action.select,
      };
    case ACTION_TYPE.CALL_API_NOTICE_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_NOTICE_DETAIL_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_SAVE_NOTICE_SUCCESS:
      return {
        ...state,
        saveId: action.saveId,
        isSaveSuccess: true,
      };
    case ACTION_TYPE.RESET_NOTICE_FLG:
      return {
        ...state,
        isSaveSuccess: false,
        isDeleteSuccess: false,
      };
    case ACTION_TYPE.CALL_API_DELETE_NOTICE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    default:
      return state;
  }
};

export default notices;
