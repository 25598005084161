export const ACTION_TYPE = {
  CALL_API_INIT_EVENT_SCHEDULE_LIST: 'CALL_API_INIT_EVENT_SCHEDULE_LIST',
  CALL_API_INIT_EVENT_SCHEDULE_LIST_SUCCESS: 'CALL_API_INIT_EVENT_SCHEDULE_LIST_SUCCESS',
  CALL_API_EVENT_SCHEDULE_LIST_SEARCH: 'CALL_API_EVENT_SCHEDULE_LIST_SEARCH',
  CALL_API_EVENT_SCHEDULE_LIST_SEARCH_SUCCESS: 'CALL_API_EVENT_SCHEDULE_LIST_SEARCH_SUCCESS',
  CALL_API_EVENT_SCHEDULE_LIST_CREATE: 'CALL_API_EVENT_SCHEDULE_LIST_CREATE',
  CALL_API_EVENT_SCHEDULE_LIST_CREATE_SUCCESS: 'CALL_API_EVENT_SCHEDULE_LIST_CREATE_SUCCESS',
  CALL_API_EVENT_SCHEDULE_LIST_DELETE: 'CALL_API_EVENT_SCHEDULE_LIST_DELETE',
  CALL_API_EVENT_SCHEDULE_LIST_DELETE_SUCCESS: 'CALL_API_EVENT_SCHEDULE_LIST_DELETE_SUCCESS',
  CALL_API_EVENT_SCHEDULE_LIST_COPY: 'CALL_API_EVENT_SCHEDULE_LIST_COPY',
  CALL_API_EVENT_SCHEDULE_LIST_COPY_SUCCESS: 'CALL_API_EVENT_SCHEDULE_LIST_COPY_SUCCESS',
  EVENT_SCHEDULE_LIST_FLG_RESET: 'EVENT_SCHEDULE_LIST_FLG_RESET',
  SET_SEARCH_EVENT_SCHEDULE: 'SET_SEARCH_EVENT_SCHEDULE',
};

export const actCallInitEventScheduleList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_LIST,
});

export const actCallInitEventScheduleListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_LIST_SUCCESS,
  selects,
});

export const actCallApiEventScheduleListSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_SEARCH,
  searchCondition,
});

export const actCallEventScheduleListSearchSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_SEARCH_SUCCESS,
  datas,
});

export const actApiEventScheduleListCreate = (params) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_CREATE,
  params,
});

export const actCallEventScheduleListCreateSuccess = (resultData) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_CREATE_SUCCESS,
  resultData,
});

export const actCallApiEventScheduleListDelete = (deleteList) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_DELETE,
  deleteList,
});

export const actCallEventScheduleListDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_DELETE_SUCCESS,
});

export const actApiEventScheduleListCopy = (params) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_COPY,
  params,
});

export const actCallEventScheduleListCopySuccess = (resultDatas) => ({
  type: ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_COPY_SUCCESS,
  resultDatas,
});

export const actEventScheduleListFlgReset = () => ({
  type: ACTION_TYPE.EVENT_SCHEDULE_LIST_FLG_RESET,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_SEARCH_EVENT_SCHEDULE,
  searchCondition,
});
