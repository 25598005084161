import { ACTION_TYPE } from './action';

const memberDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO_SUCCESS:
      return {
        ...state,
        isCheckResult: action.data,
        isCheckSuccess: true,
      };
    case ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        isMemberUpdate: true,
      };
    case ACTION_TYPE.CALL_API_GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.data,
      };
    case ACTION_TYPE.CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS_SUCCESS:
      return {
        ...state,
        zipcode: action.zipcode,
      };
    case ACTION_TYPE.CALL_API_CONTRACT_INSERT_SUCCESS:
      return {
        ...state,
        isContractInsertSuccess: true,
      };
    case ACTION_TYPE.CALL_API_CONTRACT_DELETE_SUCCESS:
      return {
        ...state,
        isContractDeleteSuccess: true,
      };
    case ACTION_TYPE.CALL_API_MEMBER_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        isMemberWithdrawalUpdate: true,
      };
    case ACTION_TYPE.CALL_ACT_RESET_CONTRACT_FLG:
      return {
        ...state,
        isContractInsertSuccess: false,
        isContractDeleteSuccess: false,
        isMemberUpdate: false,
        isMemberWithdrawalUpdate: false,
        isCheckSuccess: false,
      };
    default:
      return state;
  }
};

export default memberDetail;
