import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getMemberLinkageInfo, getMemberDatas, deleteMemberLinkageList,
  getMembers, InsertMemberLinkageList,
} from '../../../services/school/memberLinkage.service';
import {
  ACTION_TYPE,
  actInitMemberLinkageSuccess,
  actCallApiMemberLinkageListSuccess,
  actCallApiMemberLinkageDeleteSuccess,
  actCallApiLinkageDialogMemberListSuccess,
  actCallApiInsertMemberLinkageSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * スケジュール情報取得
 *
 * @param
 */
function* callApiInitMemberLinkage(id) {
  try {
    const infoData = yield call(getMemberLinkageInfo, id);
    yield put(actInitMemberLinkageSuccess(infoData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員紐付け一覧取得
 *
 * @param
 */
function* callApiMemberLinkageList({ param }) {
  try {
    const datas = yield call(getMemberDatas, param);
    yield put(actCallApiMemberLinkageListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiMemberLinkageDelete({ values }) {
  try {
    const datas = yield call(deleteMemberLinkageList, values);
    yield put(actCallApiMemberLinkageDeleteSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員追加モーダル内会員検索
 *
 * @param userInfo
 */
function* callApiLinkageDialogMemberList({ searchCondition }) {
  try {
    const members = yield call(getMembers, searchCondition);
    yield put(actCallApiLinkageDialogMemberListSuccess(members));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員紐付け追加
 *
 * @param
 */
function* callApiMemberLinkageInsert({ values }) {
  try {
    const datas = yield call(InsertMemberLinkageList, values);
    yield put(actCallApiInsertMemberLinkageSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberLinkage() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_MEMBER_LINKAGE, callApiInitMemberLinkage);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_LINKAGE_LIST, callApiMemberLinkageList);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_LINKAGE_DELETE, callApiMemberLinkageDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_LINKAGE_DIALOG_MEMBER_LIST, callApiLinkageDialogMemberList);
  yield takeLatest(ACTION_TYPE.CALL_API_INSERT_MEMBER_LINKAGE, callApiMemberLinkageInsert);
}
