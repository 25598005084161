export const ACTION_TYPE = {
  CALL_API_INIT_SCHOOL_SCHEDULE_DETAIL: 'CALL_API_INIT_SCHOOL_SCHEDULE_DETAIL',
  INIT_SCHOOL_SCHEDULE_DETAIL_SUCCESS: 'INIT_SCHOOL_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_LIST_INSERT: 'CALL_API_SCHOOL_SCHEDULE_LIST_INSERT',
  CALL_API_SCHOOL_SCHEDULE_LIST_INSERT_SUCCESS: 'CALL_API_SCHOOL_SCHEDULE_LIST_INSERT_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_LIST_COPY: 'CALL_API_SCHOOL_SCHEDULE_LIST_COPY',
  CALL_API_SCHOOL_SCHEDULE_LIST_COPY_SUCCESS: 'CALL_API_SCHOOL_SCHEDULE_LIST_COPY_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_CREATED: 'CALL_API_SCHOOL_SCHEDULE_CREATED',
  CALL_API_SCHOOL_SCHEDULE_COPIED: 'CALL_API_SCHOOL_SCHEDULE_COPIED',
  CALL_API_SCHOOL_SCHEDULE_FLG_RESET: 'CALL_API_SCHOOL_SCHEDULE_FLG_RESET',
};

export const actCallInitSchoolScheduleDetail = (id) => ({
  type: ACTION_TYPE.CALL_API_INIT_SCHOOL_SCHEDULE_DETAIL,
  id: id.id,
});

export const actInitSchoolScheduleDetailSuccess = (headerData, datas) => ({
  type: ACTION_TYPE.INIT_SCHOOL_SCHEDULE_DETAIL_SUCCESS,
  headerData,
  datas,
});

export const actInsertApiSchoolSchedule = (param) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_INSERT,
  param,
});

export const actInsertApiSchoolScheduleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_INSERT_SUCCESS,
  data,
});

export const actInsertApiSchoolScheduleCopy = (param) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_COPY,
  param,
});

export const actInsertApiSchoolScheduleCopySuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_COPY_SUCCESS,
  data,
});

export const actCallSchoolScheduleDetailSetIsCreated = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_CREATED,
});

export const actCallSchoolScheduleDetailSetIsCopied = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_COPIED,
});

export const actApiSchoolScheduleDetailFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_FLG_RESET,
});
