import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import {
  TextField, Button, withStyles, Box, Drawer, AppBar, Tabs, Tab,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Container, Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import urls from '../../../constants/urls';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import { actCallInitMailDetail, actUpdateMailDetail, actCallUpdateMailFlgReset } from '../../../redux/setting/mailDetail/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import Validation from './validation';
import {
  getContractInfoTags,
  getMemberInfoTags,
  getBookingInfoTags,
  getOtherInfoTags,
  getBookingEntryTags,
  getBookingZoomTags,
} from './getTags';

const drawerWidth = '35%';

const styles = (theme) => ({
  root: {
    padding: '24px',
    paddingTop: '60px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 100,
    height: '85%',
  },
  drawerBox: {
    flex: '0 0 auto',
    padding: '8px',
    alignItems: 'center',
    display: 'flex',
  },
  drawerTitle: {
    flex: '1 1 50%',
  },
  paper: {
    width: 'fit-content',
  },
  mainContent: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  brealAll: {
    wordBreak: 'break-all',
  },
});

class MailDetail extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.SETTING.MAIL_LIST,
        name: '自動配信メール設定',
      },
      {
        name: '自動配信メール詳細',
      },
    ];

    this.contractInfoTags = getContractInfoTags();
    this.memberInfoTags = getMemberInfoTags();
    this.bookingInfoTags = getBookingInfoTags();
    this.otherInfoTags = getOtherInfoTags();
    this.entryInfoTags = getBookingEntryTags();
    this.zoomInfoTags = getBookingZoomTags();

    this.state = {
      drawerOpen: false,
      activeIndex: 0,
      data: [],
      successSnackOpen: false,
      info: {
        mailTitle: '',
        mailContent: '',
      },
      message: {
        mailTitle: '',
        mailContent: '',
      },
      useTab: [],
    };

    if (!props.location.state) {
      this.props.history.push({
        pathname: urls.SETTING.MAIL_LIST,
      });
      return;
    }

    this.props.dispatch(actCallInitMailDetail({ id: this.props.location.state.detailId }));
  }

  componentDidMount() {
    document.title = '自動配信メール詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mailDetail.data !== this.props.mailDetail.data) {
      if (this.props.mailDetail.data) {
        this.setData(this.props.mailDetail.data);
      }
    }
    if (prevProps.mailDetail.isUpdateSuccess !== this.props.mailDetail.isUpdateSuccess) {
      if (this.props.mailDetail.isUpdateSuccess) {
        this.setUpdateSuccess(this.props.mailDetail.isUpdateSuccess);
      }
    }
  }

  setData(data) {
    const { useTab } = this.state;
    if (data.showContractFlg) {
      useTab.push({
        name: 'contract',
        func: this.contractInfoTags,
      });
    }

    if (data.showMemberFlg) {
      useTab.push({
        name: 'member',
        func: this.memberInfoTags,
      });
    }

    if (data.showBookingFlg) {
      useTab.push({
        name: 'booking',
        func: this.bookingInfoTags,
      });
    }

    if (data.showOtherFlg) {
      useTab.push({
        name: 'other',
        func: this.otherInfoTags,
      });
    }

    if (data.showEntryFlg) {
      useTab.push({
        name: 'entry',
        func: this.entryInfoTags,
      });
    }

    if (data.showZoomFlg) {
      useTab.push({
        name: 'zoom',
        func: this.zoomInfoTags,
      });
    }
    this.setState({ data, info: { mailTitle: data.title, mailContent: data.contents }, useTab });
  }

  setUpdateSuccess(isUpdateSuccess) {
    this.setState({ successSnackOpen: isUpdateSuccess });
  }

  handleDrawerClose = () => {
    this.mailContentRef.focus();
    this.setState({ drawerOpen: false });
  }

  handleDrawerOpen = () => {
    this.mailContentRef.focus();
    this.setState({ drawerOpen: true });
  }

  handleActiveIndexUpdate = (_, newValue) => {
    this.setState({ activeIndex: newValue });
  };

  onChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info, message } = this.state;

    this.setState({
      info: { ...info, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  updateMailDetail = () => {
    const { info, data, message } = this.state;
    let tempMessage = message;

    Object.keys(info).map(
      (key) => {
        const msg = Validation.formValidate(key, info[key]);
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        return msg;
      },
    );

    this.setState({ message: tempMessage });
    this.props.dispatch(actCallUpdateMailFlgReset());
    if (!tempMessage.mailTitle && !tempMessage.mailContent) {
      this.props.dispatch(actUpdateMailDetail({
        id: data.id, title: info.mailTitle, contents: info.mailContent,
      }));
    }
  }

  insertTag = (_, tag) => {
    const { info, message } = this.state;
    const textarea = this.mailContentRef;
    let { value } = textarea;
    const start = textarea.selectionStart;
    const end = value.length;
    const before = value.substr(0, start);
    const after = value.substr(start, end);
    value = before + tag + after;

    this.setState({
      info: { ...info, mailContent: value },
      message: { ...message, mailContent: '' },
    });
    const focusIndex = (before + tag).length;
    textarea.focus();
    textarea.value = value;
    textarea.setSelectionRange(focusIndex, focusIndex);
  };

  render() {
    const { classes } = this.props;

    const {
      drawerOpen, data, successSnackOpen, info, message,
      useTab, activeIndex,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container maxWidth={false} className={classes.root}>
          <main
            className={clsx(classes.mainContent, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <Box display="flex" p={3}>
                <Box>
                  <FormTitle title="店舗" />
                  <Box>{data.storeName}</Box>
                </Box>
                <Box ml={4}>
                  <FormTitle title="配信種類" />
                  <Box>{data.type}</Box>
                </Box>
                <Box ml={4}>
                  <FormTitle title="配信タイミング" />
                  <Box>{data.timing}</Box>
                </Box>
                <Box ml={4}>
                  <FormTitle title="配信状況" />
                  <Box>{data.stopFlg ? ('配信停止中') : ('配信中')}</Box>
                </Box>
              </Box>
            </Paper>
            <Box display={data.annotation ? '' : 'none'} mt={3}>
              <Alert severity="warning">
                <Box className={classes.brealAll}>{data.annotation && data.annotation.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
              </Alert>
            </Box>
            <Box mt={3}>
              <Paper elevation={0} variant="outlined">
                <Box p={3}>
                  <FormTitle title="タイトル" isRequired />
                  <Box mb={3}>
                    <TextField
                      id="title"
                      fullWidth
                      name="mailTitle"
                      variant="outlined"
                      onChange={this.onChange}
                      value={info.mailTitle || ''}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                    <FormErrorText>{message.mailTitle}</FormErrorText>
                  </Box>
                  <FormTitle title="内容" isRequired />
                  <Box>
                    <TextField
                      id="content"
                      name="mailContent"
                      multiline
                      fullWidth
                      rows={25}
                      variant="outlined"
                      value={info.mailContent}
                      onChange={this.onChange}
                      inputProps={{
                        maxLength: 30000,
                      }}
                      inputRef={(node) => { this.mailContentRef = node; }}
                    />
                    <FormErrorText>{message.mailContent}</FormErrorText>
                  </Box>
                  <Box>
                    {!drawerOpen && useTab.length > 0
                      ? (
                        <Button color="primary" size="large" onClick={this.handleDrawerOpen}>挿入タグ一覧</Button>
                      ) : (
                        <Button disabled />
                      )}
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box textAlign="center" mt={3}>
              <Button onClick={this.updateMailDetail} variant="contained" color="primary" size="large">保存</Button>
            </Box>
            <SuccessSnackbar
              open={successSnackOpen}
              handleClose={this.handleSuccessSnackbarClose}
              message="自動配信メール詳細の更新が完了しました"
            />
          </main>
        </Container>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box m={1}>
            <Box className={classes.drawerBox}>
              <Box className={classes.drawerTitle}>
                <Box fontWeight="fontWeightBold" fontSize={17}>挿入タグ</Box>
              </Box>
              <Box>
                <Button onClick={this.handleDrawerClose}>
                  閉じる
                </Button>
              </Box>
            </Box>
            <Box my={2}>
              下の各置換文字をメール文面に入れると、配信時に置換されます。
            </Box>
            <Box>
              <AppBar position="static" color="default">
                <Tabs
                  value={activeIndex}
                  onChange={this.handleActiveIndexUpdate}
                  aria-label="tag tabs"
                  indicatorColor="secondary"
                  textColor="primary"
                >
                  {data.showContractFlg && <Tab label="契約者情報" />}
                  {data.showMemberFlg && <Tab label="会員情報" />}
                  {data.showBookingFlg && <Tab label="予約情報" />}
                  {data.showOtherFlg && <Tab label="その他" />}
                  {data.showEntryFlg && <Tab label="置換情報" />}
                  {data.showZoomFlg && <Tab label="Zoom情報" />}
                </Tabs>
              </AppBar>
              <Box mt={1}>
                {useTab.map((tab, idx) => {
                  const labelId = tab.name;

                  return (
                    <TabPanel value={activeIndex} index={idx} key={`${labelId}-tab-panel`}>
                      <TableContainer className={classes.tableContainer}>
                        <Table
                          className={classes.table}
                          aria-labelledby={`tableTitle-${labelId}-info`}
                          size="medium"
                          aria-label="enhanced table"
                          stickyHeader
                        >
                          <TableHead className={classes.header}>
                            <TableRow className={classes.headerRow}>
                              <TableCell
                                key={`${labelId}-tag-add-name`}
                                align="left"
                                padding="default"
                                className={classes.tableCell}
                              >
                                名前
                              </TableCell>
                              <TableCell
                                key={`${labelId}-tag-add-mailtag`}
                                align="left"
                                padding="default"
                                className={classes.tableCell}
                              >
                                置換文字
                              </TableCell>
                              <TableCell key={`${labelId}-tag-add-maicelltag`} />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tab.func.map((tag) => (
                              <TableRow className={classes.bodyRow} key={tag.id}>
                                <TableCell key={tag.name} scope="row" padding="default">
                                  {tag.name}
                                </TableCell>
                                <TableCell key={tag.tag} scope="row" padding="default">
                                  {tag.tag}
                                </TableCell>
                                <TableCell scope="row" padding="default">
                                  <Button color="primary" onClick={(event) => this.insertTag(event, tag.tag)}>挿入</Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ mailDetail: state.mailDetail });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MailDetail)));
