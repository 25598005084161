import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, createData, deleteDatas,
} from '../../../services/setting/holiday.service';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actHolidayListSuccess,
  actHolidayCreateSuccess,
  actHolidayDeleteSuccess,
  actCallHolidayGetStoreSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示データ
 *
 * @param
 */
function* callApiHolidayList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actHolidayListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}
/**
 * 初期表示
 * @param rule
 */
function* actCallHolidayGetStore() {
  try {
    const stores = yield call(getStores);
    yield put(actCallHolidayGetStoreSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 休日作成
 * @param {*} param0
 */
function* callApiHolidayCreate({ data }) {
  try {
    const resultData = yield call(createData, data);
    yield put(actHolidayCreateSuccess(resultData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiHolidayDelete({ deleteList }) {
  try {
    yield call(deleteDatas, deleteList);
    yield put(actHolidayDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallHolidayList() {
  yield takeLatest(ACTION_TYPE.CALL_API_HOLIDAY_LIST, callApiHolidayList);
  yield takeLatest(ACTION_TYPE.CALL_API_HOLIDAY_CREATE, callApiHolidayCreate);
  yield takeLatest(ACTION_TYPE.CALL_API_HOLIDAY_DELETE, callApiHolidayDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_HOLIDAY_GET_STORE, actCallHolidayGetStore);
}
