import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actCallApiInitSurveySettingSuccess,
  actCallApiGetSurveySettingSuccess,
  actCallApiUpsertSurveySettingSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { getStores } from '../../../services/common/selectList.service';
import {
  getDatas, upsert,
} from '../../../services/setting/surveySetting.service';

/**
 * 初期表示
 */
function* callApiInitSurveySetting() {
  try {
    const stores = yield call(getStores);
    yield put(actCallApiInitSurveySettingSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * データ取得
 * @param {*} param0
 */
function* callApiGetSurveySetting({ param }) {
  try {
    const datas = yield call(getDatas, param);
    yield put(actCallApiGetSurveySettingSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 */
function* callApiUpsertSurveySetting({ param }) {
  try {
    const data = yield call(upsert, param);
    yield put(actCallApiUpsertSurveySettingSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSurveySetting() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SURVEY_SETTING, callApiInitSurveySetting);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SURVEY_SETTING, callApiGetSurveySetting);
  yield takeLatest(ACTION_TYPE.CALL_API_UPSERT_SURVEY_SETTING, callApiUpsertSurveySetting);
}
