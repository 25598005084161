export const ACTION_TYPE = {
  CALL_API_INIT_SCHOOL_SCHEDULE_LIST: 'CALL_API_INIT_SCHOOL_SCHEDULE_LIST',
  INIT_SCHOOL_SCHEDULE_LIST_SUCCESS: 'INIT_SCHOOL_SCHEDULE_LIST_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_LIST_SEARCH: 'CALL_API_SCHOOL_SCHEDULE_LIST_SEARCH',
  CALL_API_SCHOOL_SCHEDULE_LIST_SUCCESS: 'CALL_API_SCHOOL_SCHEDULE_LIST_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_LIST_DELETE: 'CALL_API_SCHOOL_SCHEDULE_LIST_DELETE',
  CALL_API_SCHOOL_SCHEDULE_LIST_DELETE_SUCCESS: 'CALL_API_SCHOOL_SCHEDULE_LIST_DELETE_SUCCESS',
  CALL_API_SCHOOL_SCHEDULE_HEADER_INFO: 'CALL_API_SCHOOL_SCHEDULE_HEADER_INFO',
  CALL_API_SCHOOL_SCHEDULE_HEADER_INFO_SUCCESS: 'CALL_API_SCHOOL_SCHEDULE_HEADER_INFO_SUCCESS',
  SET_SEARCH_SCHOOL_SCHEDULE: 'SET_SEARCH_SCHOOL_SCHEDULE',
  CALL_API_SCHOOL_SCHEDULE_LIST_FLG_RESET: 'CALL_API_SCHOOL_SCHEDULE_LIST_FLG_RESET',
};

export const actCallInitSchoolScheduleList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SCHOOL_SCHEDULE_LIST,
});

export const actCallInitSchoolScheduleListSuccess = (selects) => ({
  type: ACTION_TYPE.INIT_SCHOOL_SCHEDULE_LIST_SUCCESS,
  selects,
});

export const actCallApiSchoolScheduleList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_SEARCH,
  searchCondition,
});

export const actCallApiSchoolScheduleListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_SUCCESS,
  datas,
});

export const actCallApiSchoolScheduleListDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_DELETE,
  values,
});

export const actCallApiSchoolScheduleListDeleteSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_DELETE_SUCCESS,
  datas,
});

export const actCallApiSchoolScheduleHeaderInfo = (id) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_HEADER_INFO,
  id,
});

export const actCallApiSchoolScheduleHeaderInfoSuccess = (headerData) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_HEADER_INFO_SUCCESS,
  headerData,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_SEARCH_SCHOOL_SCHEDULE,
  searchCondition,
});

export const actApiSchoolScheduleListFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_FLG_RESET,
});
