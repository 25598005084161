import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, deleteSchoolScheduleList, searchSchoolScheduleHeaderInfo, getSelects,
} from '../../../services/school/schoolWeekScheduleList.service';
import {
  ACTION_TYPE,
  actCallInitSchoolScheduleListSuccess,
  actCallApiSchoolScheduleListSuccess,
  actCallApiSchoolScheduleListDeleteSuccess,
  actCallApiSchoolScheduleHeaderInfoSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param userInfo
 */
function* callApiInitSchoolScheduleList() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallInitSchoolScheduleListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param userInfo
 */
function* callApiSchoolScheduleListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiSchoolScheduleListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiSchoolScheduleListDelete({ values }) {
  try {
    const datas = yield call(deleteSchoolScheduleList, values);
    yield put(actCallApiSchoolScheduleListDeleteSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * スクール型曜日別スケジュールヘッダー検索(ヘッダーID)
 *
 * @param userInfo
 */
function* callApiSchoolScheduleHeaderInfo(id) {
  try {
    const headerData = yield call(searchSchoolScheduleHeaderInfo, id);
    yield put(actCallApiSchoolScheduleHeaderInfoSuccess(headerData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSchoolWeekScheduleList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SCHOOL_SCHEDULE_LIST, callApiInitSchoolScheduleList);
  yield takeLatest(ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_SEARCH,
    callApiSchoolScheduleListSearch);
  yield takeLatest(ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_LIST_DELETE,
    callApiSchoolScheduleListDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_SCHOOL_SCHEDULE_HEADER_INFO,
    callApiSchoolScheduleHeaderInfo);
}
