import { ACTION_TYPE } from './action';

// region Reducer
const siteSetting = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_SITE_SETTING_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_SITE_SETTING_SEARCH_SUCCESS:
      return {
        ...state,
        siteSetting: action.siteSetting,
      };
    case ACTION_TYPE.CALL_API_SITE_SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
        siteSetting: action.siteSetting,
      };
    case ACTION_TYPE.RESET_SITE_SETTING_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};

export default siteSetting;
