import React from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    lineHeight: '4.5',
  },
  root: {
    padding: theme.spacing(2),
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper variant="outlined" className={classes.root}>
        <Box fontWeight="fontWeightBold" fontSize={30} textAlign="center">お探しのページが見つかりません</Box>
        <Box fontSize={18} textAlign="center">アクセスしようとしたページはリンクが無効になっているか、URLが間違っています。</Box>
      </Paper>
    </Container>
  );
}
