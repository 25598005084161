import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box, TextField } from '@material-ui/core';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import urls from '../../../constants/urls';
import EventScheduleDialog from '../eventWeekScheduleDialog/EventWeekScheduleDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallInitEventScheduleList,
  actCallApiEventScheduleListSearch,
  actCallApiEventScheduleListDelete,
  actEventScheduleListFlgReset,
  actSetSearchCondition,
} from '../../../redux/event/eventWeekScheduleList/action';
import {
  actCallEventScheduleDetailSetIsCreated,
  actCallEventScheduleDetailSetIsCopied,
  actCallInitEventScheduleDetail,
} from '../../../redux/event/eventWeekScheduleDetail/action';

class EventWeekScheduleList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallInitEventScheduleList());

    this.breadcrumbsText = [
      { name: '曜日別スケジュール一覧' },
    ];

    let isMain = false;
    let defaultStore = null;
    if (props.common.user) {
      isMain = props.common.user.main;
      defaultStore = props.common.user.store;
      this.props.dispatch(
        actCallApiEventScheduleListSearch({
          page: 0,
          records: 10,
          searchStoreId: defaultStore && defaultStore.id,
        }),
      );
    }

    this.headCells = [
      { id: 'contentName', numeric: false, label: 'コンテンツ' },
      { id: 'name', numeric: false, label: '管理名' },
      { id: 'storeName', numeric: false, label: '店舗' },
    ];

    let page = 0;
    let records = 10;
    let searchName = '';
    let searchStore = defaultStore;
    let searchContents = null;

    if (props.eventWeekScheduleList.searchCondition) {
      searchStore = props.eventWeekScheduleList.searchCondition.searchStore;
      searchContents = props.eventWeekScheduleList.searchCondition.searchContents;
      searchName = props.eventWeekScheduleList.searchCondition.searchName;
      page = 0;
      records = props.eventWeekScheduleList.searchCondition.records;
    }
    this.search(page, records, searchStore, searchContents, searchName);

    this.state = {
      isMain,
      defaultStore,
      stores: [],
      contents: [],
      storeContentLinks: [],
      searchName,
      searchStore,
      searchContents,
      datas: {},
      header: {
        id: null,
        store: {},
        content: {},
        name: '',
      },
      page,
      records,
      successSnackOpen: false,
      scheduleModalOpen: false,
      copyFlg: null,
      linkedContents: '',
    };
  }

  componentDidMount() {
    document.title = '曜日別スケジュール一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
        this.initSearch(this.props.common.user.store);
      }
    }
    if (prevProps.eventWeekScheduleList.selects !== this.props.eventWeekScheduleList.selects) {
      if (this.props.eventWeekScheduleList.selects) {
        this.setSelects(this.props.eventWeekScheduleList.selects);
      }
    }
    if (prevProps.eventWeekScheduleList.datas !== this.props.eventWeekScheduleList.datas) {
      if (this.props.eventWeekScheduleList.datas) {
        this.setDatas(this.props.eventWeekScheduleList.datas);
      }
    }
    if (prevProps.eventWeekScheduleList.resultData
      !== this.props.eventWeekScheduleList.resultData) {
      if (this.props.eventWeekScheduleList.resultData) {
        this.setResultData(this.props.eventWeekScheduleList.resultData, false);
      }
    }
    if (prevProps.eventWeekScheduleList.copyDatas
      !== this.props.eventWeekScheduleList.copyDatas) {
      if (this.props.eventWeekScheduleList.copyDatas) {
        this.setResultData(this.props.eventWeekScheduleList.copyDatas, true);
      }
    }
    if (prevProps.eventWeekScheduleList.isDeleted !== this.props.eventWeekScheduleList.isDeleted) {
      this.handleSuccessSnackbarClose();
      if (this.props.eventWeekScheduleList.isDeleted) {
        this.setSuccessMessage();
        this.onSearch();
      }
    }
    if (prevProps.eventWeekScheduleDetail.headerData
      !== this.props.eventWeekScheduleDetail.headerData) {
      if (this.props.eventWeekScheduleDetail.headerData) {
        this.setHeaderData(this.props.eventWeekScheduleDetail.headerData);
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main, defaultStore: user.store });
  }

  setSelects(selects) {
    const { searchStore } = this.state;
    this.setState({
      stores: selects.stores,
      contents: selects.contents,
      storeContentLinks: selects.storeContentLinks,
      linkedContents: this.mapStoreContentLinks(searchStore, selects.storeContentLinks),
    });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setResultData(resultData, copyFlg) {
    const { isMain } = this.state;

    if (copyFlg) {
      this.props.dispatch(actCallEventScheduleDetailSetIsCopied());
    } else {
      this.props.dispatch(actCallEventScheduleDetailSetIsCreated());
    }

    this.props.history.push({
      pathname: urls.EVENT.WEEK_SCHEDULES_DETAIL,
      state: {
        headerId: resultData.id, isMain,
      },
    });
  }

  setHeaderData(header) {
    this.setState({
      header,
      copyFlg: 3,
      scheduleModalOpen: true,
    });
  }

  setSuccessMessage = () => {
    this.setState({ successSnackOpen: true });
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    this.setState({ searchStore: value });
    if (!value) {
      return;
    }
    const { searchContents, storeContentLinks } = this.state;

    const linkedContents = this.mapStoreContentLinks(value, storeContentLinks);

    if (searchContents && !linkedContents.includes(searchContents.id)) {
      this.setState({ searchContents: null });
    }
    this.setState({ linkedContents });
  }

  onContentChange = (event) => {
    this.setState({ searchContents: event.target.value });
  }

  onNameChange = (event) => {
    this.setState({ searchName: event.target.value });
  }

  onSearch = () => {
    const {
      records, searchStore, searchContents, searchName,
    } = this.state;
    this.setState({ page: 0 });
    this.props.dispatch(actSetSearchCondition({
      page: 0, records, searchStore, searchContents, searchName,
    }));
    this.search(0, records, searchStore, searchContents, searchName);
  }

  onAdd = () => {
    const {
      page, records, searchStore, searchContents, searchName,
    } = this.state;
    this.setState({
      scheduleModalOpen: true,
      copyFlg: 1,
      defaultStore: this.props.common.user.store,
    });
    this.props.dispatch(actSetSearchCondition({
      page, records, searchStore, searchContents, searchName,
    }));
  }

  onDetail = (_, row) => {
    const { isMain } = this.state;
    const copyFlg = 2;
    this.props.history.push({
      pathname: urls.EVENT.WEEK_SCHEDULES_DETAIL,
      state: {
        headerId: row.id, isMain, copyFlg,
      },
    });
  }

  onDelete = (value) => {
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actEventScheduleListFlgReset());
    this.props.dispatch(
      actCallApiEventScheduleListDelete(value),
    );
  }

  onCopy = (_, row) => {
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actCallInitEventScheduleDetail({ id: row.id }));
  }

  handleChangePage = (_, newPage) => {
    const {
      searchStore, searchContents, searchName, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(newPage, records, searchStore, searchContents, searchName);
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchStore, searchContents, searchName,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchStore, searchContents, searchName);
  };

  handleDialogClose = () => {
    this.setState({ scheduleModalOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  initSearch = (values) => {
    this.setState({ searchStore: values });
    this.props.dispatch(
      actCallApiEventScheduleListSearch({
        page: 0,
        records: 10,
        searchStoreId: values && values.id,
      }),
    );
  }

  search = (page, records, searchStore, searchContents, searchName) => {
    this.props.dispatch(actCallApiEventScheduleListSearch(
      {
        page,
        records,
        searchStoreId: searchStore
        && searchStore.id,
        searchContentsId: searchContents
        && searchContents.id,
        searchName,
      },
    ));
  }

  mapStoreContentLinks = ((values, storeContentLinks) => storeContentLinks
    .filter((link) => link.mstStoreId === (values && values.id))
    .map((link) => link.mstContentId)
  );

  render() {
    const {
      isMain, defaultStore, stores, contents, storeContentLinks,
      searchStore, searchContents, searchName, page, records,
      datas, successSnackOpen, scheduleModalOpen, copyFlg, header, linkedContents,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display={isMain ? '' : 'none'} width="30%">
              <FormTitle title="店舗" />
              <SearchSelectBox
                options={stores}
                value={searchStore}
                onChange={this.onStoreChange}
                name="searchStore"
              />
            </Box>
            <Box display="flex" mt={1}>
              <Box width="30%" mr={2}>
                <FormTitle title="コンテンツ" />
                <SearchSelectBox
                  options={(searchStore) ? (
                    contents.filter((contentFilter) => contents
                    && linkedContents.includes(contentFilter.id))
                  ) : (contents)}
                  value={searchContents}
                  onChange={this.onContentChange}
                  name="searchContents"
                />
              </Box>
              <Box>
                <FormTitle title="管理名" />
                <TextField
                  onChange={this.onNameChange}
                  variant="outlined"
                  value={searchName}
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="曜日別スケジュールの追加"
            deleteButtonLabel="曜日別スケジュールの削除"
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            deleteDialogTitle="曜日別スケジュールの削除"
            deleteDialogContent="選択した曜日別スケジュールを削除します。この操作は元に戻すことができません。"
            onDetail={this.onDetail}
            onSecondFunc={this.onCopy}
            secondFuncText="コピーする"
            isMain={isMain}
          />
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="曜日別スケジュールの削除が完了しました" />
        </MainBody>
        <EventScheduleDialog
          open={scheduleModalOpen}
          handleClose={this.handleDialogClose}
          id={header.id}
          isMain={isMain}
          defaultStore={defaultStore}
          stores={stores}
          contents={contents}
          storeContentLinks={storeContentLinks}
          copyFlg={copyFlg}
          header={header}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventWeekScheduleList: state.eventWeekScheduleList,
  common: state.common,
  eventWeekScheduleDetail: state.eventWeekScheduleDetail,
});

export default withRouter(connect(mapStateToProps)(EventWeekScheduleList));
