import React, { Component } from 'react';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import ja from 'date-fns/locale/ja';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Button, Tooltip, Container,
} from '@material-ui/core';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import { isAfter } from 'date-fns';
import urls from '../../../constants/urls';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import RoomSearch from './RoomSearch';
import '../../../public/css/calendar.css';
import { getStyle } from './room-schedule-proc';
import { actCallApiGetSelects, actSetSearchInfo } from '../../../redux/booking/roomSchedule/action';
import RoomScheduleDialog from './roomScheduleDialog/RoomScheduleDialog';
import RoomDetail from './RoomDetail';

const styles = (theme) => ({
  accordionRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    padding: '24px',
    paddingTop: '60px',
  },
});

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    maxWidth: 220,
    fontSize: '15px',
    border: '1px solid #000',
  },
  tooltipPlacementTop: {
    top: '1000px',
  },
}))(Tooltip);

class RoomSchedule extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      { name: '教室スケジュール' },
    ];

    let store = props.common.user ? props.common.user.store : null;
    store = props.roomSchedule.info ? props.roomSchedule.info.store : store;

    const curDate = props.roomSchedule.info ? props.roomSchedule.info.curDate : format(new Date(), 'yyyy/MM/dd');
    const dispDate = format(new Date(curDate), 'yyyy年MM月dd日 (eeee)', { locale: ja });

    this.state = {
      selectedId: '',
      isSearchOpen: false,
      isInsUpdDialogOpen: false,
      rooms: [],
      times: [],
      datas: [],
      selected: [],
      curDate,
      store,
      successSnackOpen: false,
      snackMessage: '',
      dispDate,
      detailData: {
      },
      isClick: false,
      selects: {
        stores: [],
      },
    };
    this.props.dispatch(actCallApiGetSelects());
  }

  componentDidMount() {
    document.title = '教室スケジュール';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roomSchedule.datas !== this.props.roomSchedule.datas) {
      if (this.props.roomSchedule.datas) {
        this.setDatas(this.props.roomSchedule.datas);
      }
    }
    if (prevProps.roomSchedule.selects !== this.props.roomSchedule.selects) {
      if (this.props.roomSchedule.selects) {
        this.setSelects(this.props.roomSchedule.selects);
      }
    }
    if (prevProps.roomSchedule.isUpsertSuccessFlg !== this.props.roomSchedule.isUpsertSuccessFlg) {
      if (this.props.roomSchedule.isUpsertSuccessFlg && !this.props.roomSchedule.isDuplicateFlg) {
        this.handleSuccessSnackbarOpen('貸し会議室スケジュールを保存しました');
      }
    }
  }

  setSelects = (selects) => {
    this.setState({ selects });
  };

  setDatas = (data) => {
    this.setState({
      rooms: data.rooms || [],
      times: data.times || [],
      datas: data.datas,
    });
  }

  onSearchDrawerOpen = () => {
    this.setState({ isSearchOpen: true });
  }

  onSearchDrawerClose = () => {
    this.setState({ isSearchOpen: false });
  }

  handleChangeDate = (setDate) => {
    this.setState({
      curDate: setDate,
      dispDate: format(new Date(setDate), 'yyyy年MM月dd日 (eeee)', { locale: ja }),
    });
  }

  handleChangeStore = (setStore) => {
    this.setState({ store: setStore });
  }

  handleCreateRoomScheduleDialogOpen = () => {
    this.setState({ isInsUpdDialogOpen: true, selectedId: '' });
  }

  handleCreateRoomScheduleDialogClose = () => {
    this.setState({
      selectedId: '',
      isInsUpdDialogOpen: false,
    });
  }

  handleEditRoomScheduleDialogOpen = (selectedId) => {
    this.setState({
      selectedId,
      isInsUpdDialogOpen: true,
    });
  }

  handleSuccessSnackbarOpen = (message) => {
    this.setState({
      successSnackOpen: true,
      snackMessage: message,
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({
      successSnackOpen: false,
      snackMessage: '',
    });
  }

  handleClickCell = (_, row) => {
    const { curDate, store } = this.state;
    const today = new Date(format(new Date(), 'yyyy/MM/dd'));
    const targetDate = new Date(curDate);
    const dateCheck = ((targetDate.getTime() === today.getTime()) || isAfter(targetDate, today));

    if (dateCheck && row.contentId === 5) {
      this.setState({
        selectedId: row.bookingId,
        isInsUpdDialogOpen: true,
      });
    } else if (row.contentId !== 5) {
      const breadcrumbsObj = [
        {
          url: urls.BOOKING.ROOM_SCHEDULES,
          name: '教室スケジュール',
        },
        {
          name: row.isRegular ? '定期予約検索詳細' : '予約検索詳細',
        },
      ];
      this.props.dispatch(actSetSearchInfo({
        curDate,
        store,
      }));
      const bookingInfo = {
        bookingId: row.bookingId,
        contentId: row.contentId,
        isRegular: row.isRegular,
        targetType: null,
      };
      this.props.history.push({
        pathname: urls.BOOKING.BOOKING_DETAIL,
        state: {
          bookingInfo,
          breadcrumbsObj,
        },
      });
    }
  }

  handleClearisClick = () => {
    this.setState({
      isClick: false,
    });
  }

  handleClickCell2= (_, data) => {
    this.setState({
      isSearchOpen: true,
      detailData: data,
      isClick: true,
    });
  }

  setMemo = (data) => {
    let retMemo = data.memo;
    if (data.memo && data.memo.length > 13) {
      retMemo = `${data.memo.substr(0, 13)}...`;
    }
    return retMemo;
  }

  render() {
    const { classes } = this.props;
    const {
      isSearchOpen, rooms, times, datas, selectedId,
      selected, isInsUpdDialogOpen, curDate,
      store, successSnackOpen, snackMessage,
      dispDate, detailData, isClick, selects,
    } = this.state;
    const startTime = times[0];

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container
          maxWidth={false}
          className={classes.root}
        >
          <RoomSearch
            date={curDate}
            stores={selects.stores}
            onDateChange={this.handleChangeDate}
            onStoreChange={this.handleChangeStore}
          />
          <Box textAlign="left" m={1}>
            <h2 style={{ fontWeight: 'bold' }}>
              {dispDate}
            </h2>
          </Box>
          <Box textAlign="left" m={1}>
            <Button variant="contained" color="primary" size="medium" onClick={this.handleCreateRoomScheduleDialogOpen}>貸し会議室スケジュールの追加</Button>
          </Box>
          <div className={isSearchOpen ? `${classes.contentShift} rbc-time-view` : 'rbc-time-view'}>
            <div className="rbc-time-header rbc-overflowing">
              <div className="rbc-label rbc-time-header-gutter" style={{ width: '50px' }} />
              <div className="rbc-time-header-content">
                <div className="rbc-row rbc-time-header-cell">
                  {rooms.map((room) => (
                    <div key={room.id} className="rbc-header">{room.name}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rbc-time-content">
              <div className="rbc-time-gutter rbc-time-column">
                {times.map((time) => (
                  <div key={time} className="rbc-timeslot-group">
                    <div className="rbc-time-slot" style={{ width: '50px' }}><span className="rbc-label">{time}</span></div>
                  </div>
                ))}
              </div>
              {rooms.map((room) => (
                <div
                  className="rbc-day-slot rbc-time-column"
                  key={room.id}
                >
                  {times.map((time) => (
                    <div
                      className={selected.includes(`${time}-${room.id}`) ? `time-table-${room.id} rbc-timeslot-group rbc-selected-cell` : `time-table-${room.id} rbc-timeslot-group`}
                      key={`time${time}${room.id}`}
                      data-key={`${time}-${room.id}`}
                    >
                      <div className="rbc-time-slot" />
                      <div className="rbc-time-slot" />
                    </div>
                  ))}
                  <div>
                    {datas.filter((data) => data.roomId === room.id).map((data) => (
                      <HtmlTooltip
                        placement="left-start"
                        title={(
                          <div>
                            <Box fontWeight="fontWeightBold" fontSize={15}>{data.menuName}</Box>
                            <Box>{`${data.startTime}-${data.endTime}`}</Box>
                            <Box mt={1}>
                              {data.bookingStatusCode === 2 && <Box>休講</Box>}
                              {data.bookingStatusCode !== 2 && <Box>{`予約${data.count}人`}</Box>}
                            </Box>
                            <Box mt={1}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>メモ</Box>
                              <Box>{data.memo && data.memo.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
                            </Box>
                            <Box mt={1} display={data.receptPeriod > 0 ? '' : 'none'}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>予約受付締切日</Box>
                              <Box>{data.closeDay}</Box>
                            </Box>
                            <Box mt={1} display={data.receptPeriod <= 0 ? '' : 'none'}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>受付終了</Box>
                            </Box>
                          </div>
                        )}
                      >
                        <div
                          role="button"
                          aria-hidden="true"
                          onClick={(event) => this.handleClickCell2(event, data)}
                          key="key"
                          title={data.menuName}
                          className="rbc-event"
                          style={getStyle(data, startTime, curDate)}
                        >
                          <div>{`${data.startTime}-${data.endTime}`}</div>
                          <div>
                            {data.materialFee && data.materialFee > 0 ? <MonetizationOn style={{ fontSize: 'small' }} /> : ''}
                            {data.menuName}
                          </div>
                          {data.bookingStatusCode === 2 && <div>休講</div>}
                          {data.bookingStatusCode !== 2 && <div>{`予約${data.count}人`}</div>}
                          <div>{this.setMemo(data)}</div>
                        </div>
                      </HtmlTooltip>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
        <RoomDetail
          open={isSearchOpen}
          date={curDate}
          detailData={detailData}
          isClick={isClick}
          handleClearisClick={this.handleClearisClick}
          onStoreChange={this.handleChangeStore}
          onClose={this.onSearchDrawerClose}
          onOpen={this.onSearchDrawerOpen}
          editData={this.handleEditRoomScheduleDialogOpen}
          id={selectedId}
          selects={selects}
        />
        <RoomScheduleDialog
          open={isInsUpdDialogOpen}
          onClose={this.handleCreateRoomScheduleDialogClose}
          targetDate={curDate}
          targetStore={store}
          id={selectedId}
        />
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={snackMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, roomSchedule: state.roomSchedule });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RoomSchedule)));
