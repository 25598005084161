import { ACTION_TYPE } from './action';

// region Reducer
const healthChecks = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_HEALTH_CHECK_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case ACTION_TYPE.CALL_INSERT_HEALTH_CHECK_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        member: action.member,
      };
    default:
      return state;
  }
};

export default healthChecks;
