import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

export default function ErrorSnackbar(props) {
  const { open, handleClose, message } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseCheck}>
      <MuiAlert
        elevation={10}
        variant="filled"
        onClose={handleClose}
        severity="error"
        action={(
          <IconButton aria-label="delete" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
