// region Type
export const ACTION_TYPE = {
  CALL_API_INIT_SURVEY_SETTING: 'CALL_API_INIT_SURVEY_SETTING',
  CALL_API_INIT_SURVEY_SETTING_SUCCESS: 'CALL_API_INIT_SURVEY_SETTING_SUCCESS',
  CALL_API_GET_SURVEY_SETTING: 'CALL_API_GET_SURVEY_SETTING',
  CALL_API_GET_SURVEY_SETTING_SUCCESS: 'CALL_API_GET_SURVEY_SETTING_SUCCESS',
  CALL_API_UPSERT_SURVEY_SETTING: 'CALL_API_UPSERT_SURVEY_SETTING',
  CALL_API_UPSERT_SURVEY_SETTING_SUCCESS: 'CALL_API_UPSERT_SURVEY_SETTING_SUCCESS',
  RESET_SURVEY_SETTING_FLG: 'RESET_SURVEY_SETTING_FLG',
};
// endregion

// region action
export const actCallApiInitSurveySetting = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SURVEY_SETTING,
});

export const actCallApiInitSurveySettingSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_INIT_SURVEY_SETTING_SUCCESS,
  stores,
});

export const actCallApiGetSurveySetting = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_SETTING,
  param,
});

export const actCallApiGetSurveySettingSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_SETTING_SUCCESS,
  datas,
});

export const actCallApiUpsertSurveySetting = (param) => ({
  type: ACTION_TYPE.CALL_API_UPSERT_SURVEY_SETTING,
  param,
});

export const actCallApiUpsertSurveySettingSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPSERT_SURVEY_SETTING_SUCCESS,
  data,
});

export const actResetSurveySettingFlg = () => ({
  type: ACTION_TYPE.RESET_SURVEY_SETTING_FLG,
});

// endregion
