import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField, Box,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import RequiredChip from '../../../components/atoms/requiredChip/RequiredChip';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import Validation from './validation';
import { actApiBookingMemberFlgReset, actCallApiBookingExperienceInsert } from '../../../redux/booking/bookingDetail/action';

class BookingExperienceAddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeLoading: false,
      info: {},
      message: {},
      title: '',
      btnLabel: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { info, message } = this.state;
    this.setState({
      info: { ...info, [key]: value },
      message: { ...message, [key]: Validation.formValidate(key, value) },
    });
  }

  handleSave = () => {
    const { info, message } = this.state;

    let tempMessage = message;
    let msg = '';
    let isError = false;

    Object.keys(info).map(
      (key) => {
        if (key === 'phone' && message.phone) { return null; }
        msg = Validation.formValidate(key, info[key]);
        if (msg) {
          isError = true;
        }
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        return msg;
      },
    );
    this.setState({ message: tempMessage, changeLoading: !isError });

    if (isError) {
      return;
    }

    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(actCallApiBookingExperienceInsert(info));
    this.props.handleClose();
  }

  init() {
    const {
      booking,
    } = this.props;

    const info = {
      memberName: '',
      phone: '',
      id: booking.id,
      contentId: booking.mstContentId,
      storeId: booking.mstStoreId,
      isRegular: booking.regular,
      targetType: booking.targetType,
    };

    const message = {
      memberName: '',
      phone: '',
    };
    let title = 'ビジターの追加';
    let btnLabel = 'ビジターを追加する';
    if (booking.regular) {
      title = '体験者の追加';
      btnLabel = '体験者を追加する';
    }
    this.setState({
      info,
      message,
      changeLoading: false,
      title,
      btnLabel,
    });
  }

  render() {
    const {
      open,
      handleClose,
    } = this.props;

    const {
      changeLoading,
      info,
      message,
      title,
      btnLabel,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <Box>
            <DialogTitle>{title}</DialogTitle>
          </Box>
          <DialogContent dividers>
            <Box mb={2}>
              <Box fontWeight="fontWeightBold" m={1} fontSize={15}>
                名前
                <RequiredChip />
              </Box>
              <TextField
                name="memberName"
                variant="outlined"
                fullWidth
                value={info.memberName}
                onChange={this.handleChange}
                error={message.memberName !== ''}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <FormErrorText>{message.memberName}</FormErrorText>
            </Box>
            <Box mb={2}>
              <Box fontWeight="fontWeightBold" m={1} fontSize={15}>
                電話番号
                <RequiredChip />
              </Box>
              <TextField
                id="phone-No"
                name="phone"
                variant="outlined"
                fullWidth
                value={info.phone}
                onChange={this.handleChange}
                error={message.phone !== ''}
                inputProps={{
                  maxLength: 20,
                }}
              />
              <FormErrorText>{message.phone}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <LoadingButton loading={changeLoading} onClick={this.handleSave} label={btnLabel} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

export default connect(mapStateToProps)(BookingExperienceAddDialog);
