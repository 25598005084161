import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 *  曜日別スケジュールヘッダーのリスト取得する
 *
 * @param {object} id
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュール用コンテンツリスト取得
 * @param {*}
 */
export function getEventSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST_SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュールヘッダー作成
 * @param {*} params
 */
export function createData(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 曜日別スケジュールヘッダー削除
 *
 * @param {*} deleteList
 */
export function deleteData(deleteList) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST, deleteList)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 *  型曜日別スケジュールヘッダーを一件取得する
 *
 * @param {object} id
 */
export function getHeader(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST_INFO, id.id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 *  型曜日別スケジュールヘッダーを一件取得する
 *
 * @param {object} id
 */
export function copyData(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.EVENT.EVENT_WEEK_SCHEDULE.EVENT_WEEK_SCHEDULE_LIST_COPY, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
