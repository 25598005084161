import { ACTION_TYPE } from './action';

// region Reducer
const eventWeekScheduleDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        headerData: action.headerData,
        datas: action.datas,
      };

    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdated: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_CREATED:
      return {
        ...state,
        isCreated: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_SET_IS_COPIED:
      return {
        ...state,
        isCopied: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_DETAIL_FLG_RESET:
      return {
        ...state,
        isUpdated: false,
        isCreated: false,
        isCopied: false,
      };
    default:
      return state;
  }
};

export default eventWeekScheduleDetail;
