import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  LinearProgress, Box, Typography,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import Validation from './validation';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Monthpicker from '../../../components/atoms/monthpicker/Monthpicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actInsertApiSchoolSchedule, actInsertApiSchoolScheduleCopy,
  actCallSchoolScheduleDetailSetIsCreated, actCallSchoolScheduleDetailSetIsCopied,
} from '../../../redux/school/schoolWeekScheduleDetail/action';
import urls from '../../../constants/urls';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

class SchoolWeekScheduleDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        date: '',
        store: null,
        contents: null,
      },
      message: {
        date: null,
        store: null,
        contents: null,
      },
      changeLoading: false,
      linkedContents: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.schoolWeekScheduleDetail.insertSchedule
      !== this.props.schoolWeekScheduleDetail.insertSchedule) {
      if (this.props.schoolWeekScheduleDetail.insertSchedule) {
        this.props.handleClose();
        this.branchDialog();
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  handleSave = () => {
    const { info, message } = this.state;
    const {
      copyFlg, copyHeaderId, copyContent, defaultStore,
    } = this.props;

    let tempMessage = message;
    let isError = false;
    Object.keys(info).map(
      (key) => {
        const msg = Validation.formValidate(key, info[key]);
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
        if (msg) {
          isError = true;
        }
        return msg;
      },
    );
    this.setState({ message: tempMessage });

    if (!copyFlg && !isError) {
      this.setState({ changeLoading: true });
      this.props.dispatch(actInsertApiSchoolSchedule(
        {
          storeId: info.store.id,
          contentId: info.contents.id,
          date: `${info.date}/01`,
        },
      ));
    }

    if (copyFlg && !isError) {
      this.setState({ changeLoading: true });
      this.props.dispatch(actInsertApiSchoolScheduleCopy(
        {
          id: copyHeaderId,
          storeId: defaultStore.id,
          contentId: copyContent.id,
          date: `${info.date}/01`,
        },
      ));
    }
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    const { info, message } = this.state;
    const { storeContentLinks } = this.props;
    const linkedContents = this.mapStoreContentLinks(value, storeContentLinks);

    let { contents } = info;
    if (info.contents && !linkedContents.includes(info.contents.id)) {
      contents = null;
    }
    this.setState({
      info: {
        ...info, store: value, contents,
      },
      message: { ...message, store: null },
      linkedContents,
    });
  }

  onContentChange = (event) => {
    const { value } = event.target;
    const { info, message } = this.state;
    this.setState({ info: { ...info, contents: value }, message: { ...message, contents: null } });
  }

  handleDateChange = (_, values) => {
    const { info, message } = this.state;
    this.setState({
      info: { ...info, date: values },
      message: { ...message, date: Validation.formValidate('date', values) },
    });
  }

  branchDialog = () => {
    const { copyFlg, isMain } = this.props;
    this.props.dispatch(actCallSchoolScheduleDetailSetIsCreated());
    if (copyFlg) {
      this.props.dispatch(actCallSchoolScheduleDetailSetIsCopied());
    }
    this.props.history.push({
      pathname: urls.SCHOOL.WEEK_SCHEDULE_DETAIL,
      state: {
        id: this.props.schoolWeekScheduleDetail.insertSchedule.id,
        isMain,
      },
    });
  }

  mapStoreContentLinks = ((values, storeContentLinks) => storeContentLinks
    .filter((link) => link.mstStoreId === values.id)
    .map((link) => link.mstContentId)
  );

  init() {
    const { defaultStore, copyContent } = this.props;

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = `${year}/${month}`;

    this.setState({
      info: {
        contents: copyContent, store: defaultStore, date,
      },
      message: {
        date: null, contents: null, store: null,
      },
      changeLoading: false,
      linkedContents: this.mapStoreContentLinks(defaultStore, this.props.storeContentLinks),
    });
  }

  render() {
    const {
      stores, open, handleClose, copyFlg, contents,
      isMain, defaultStore, copyContent,
    } = this.props;
    const {
      info, message, loading, changeLoading, linkedContents,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>{ copyFlg ? '曜日別スケジュールのコピー' : '曜日別スケジュールの追加' }</DialogTitle>
          <LinearProgress style={{ display: loading ? '' : 'none' }} />
          <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
            <Box display={isMain ? '' : 'none'} mb={copyFlg ? 2 : 3}>
              {!copyFlg ? (
                <Box>
                  <FormTitle title="店舗" isRequired />
                  <SearchSelectBox options={stores} value={info.store} onChange={this.onStoreChange} name="store" disableClearable />
                  <FormErrorText>{message.store}</FormErrorText>
                </Box>
              ) : (
                <Box fontWeight="fontWeightBold" ml={0.8} mt={0.5} fontSize={15}>
                  <FormTitle title="店舗" isRequired />
                  <Typography variant="subtitle1" gutterBottom>{defaultStore.name}</Typography>
                </Box>
              )}
            </Box>
            <Box mb={3}>
              {!copyFlg ? (
                <Box>
                  <FormTitle title="コンテンツ" isRequired />
                  <SearchSelectBox
                    options={
                      contents.filter((contentFilter) => contents
                        && linkedContents.includes(contentFilter.id))
                      }
                    value={info.contents}
                    onChange={this.onContentChange}
                    name="contents"
                    disableClearable
                  />
                  <FormErrorText>{message.contents}</FormErrorText>
                </Box>
              ) : (
                <Box fontWeight="fontWeightBold" ml={0.8} mt={0.5} fontSize={15}>
                  <FormTitle title="コンテンツ" isRequired />
                  <Typography variant="subtitle1" gutterBottom>{copyContent.name}</Typography>
                </Box>
              )}
            </Box>
            <Box mb={3}>
              <FormTitle title="対象年月" isRequired />
              <Monthpicker
                value={info.date}
                handleChange={this.handleDateChange}
              />
              <FormErrorText>{message.date}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions style={{ display: loading ? 'none' : '' }}>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.handleSave} label={!copyFlg ? '作成' : 'コピーして作成'} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolWeekScheduleDetail: state.schoolWeekScheduleDetail,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(SchoolWeekScheduleDialog));
