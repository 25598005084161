import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年MMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

export default function DateTimePicker(props) {
  const {
    value,
    handleChange,
    error,
    minDate,
    maxDate,
    disableToolbar,
  } = props;
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDateTimePicker
        format="yyyy/MM/dd HH:mm"
        inputVariant="outlined"
        autoComplete="off"
        value={value}
        variant="inline"
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        cancelLabel="キャンセル"
        okLabel="設定"
        clearLabel="クリア"
        todayLabel="今日"
        showTodayButton
        ampm={false}
        clearable
        autoOk
        helperText=""
        error={error}
        minDate={minDate}
        maxDate={maxDate}
        disableToolbar={disableToolbar}

      />
    </MuiPickersUtilsProvider>
  );
}
DateTimePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  minDate: PropTypes.PropTypes.instanceOf(Date),
  maxDate: PropTypes.PropTypes.instanceOf(Date),
};
DateTimePicker.defaultProps = {
  error: false,
  minDate: new Date('1970/01/01'),
  maxDate: new Date('9999/12/31'),
};
