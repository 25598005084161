import { ACTION_TYPE } from './action';

const changePassword = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.PASSWORD_RESET_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};

export default changePassword;
