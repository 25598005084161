import { ACTION_TYPE } from './action';

const subscriber = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_SUBSCRIBER_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateStatus: true,
      };
    case ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED_SUCCESS:
      return {
        ...state,
        isAcceptedUpdateStatus: true,
      };
    case ACTION_TYPE.RESET_UPDATE_SUCCESS_FLG:
      return {
        ...state,
        isUpdateStatus: false,
        isAcceptedUpdateStatus: false,
        isGetDetailSuccess: false,
        isTransferCancelSuccess: false,
      };
    case ACTION_TYPE.CALL_API_SUBSCRIBER_DETAIL_SUCCESS:
      return {
        ...state,
        isGetDetailSuccess: true,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_SUBSCRIBER_CANCEL_SUCCESS:
      return {
        ...state,
        isTransferCancelSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeList: action.datas,
      };
    default:
      return state;
  }
};

export default subscriber;
