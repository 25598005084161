// region Type
export const ACTION_TYPE = {
  CALL_API_RULE_GET_STORE: 'CALL_API_RULE_GET_STORE',
  CALL_API_RULE_GET_STORE_SUCCESS: 'CALL_API_RULE_GET_STORE_SUCCESS',
  CALL_API_RULE_SEARCH: 'CALL_API_RULE_SEARCH',
  CALL_API_RULE_SEARCH_SUCCESS: 'CALL_API_RULE_SEARCH_SUCCESS',
  CALL_API_RULE_UPDATE: 'CALL_API_RULE_UPDATE',
  CALL_API_RULE_UPDATE_SUCCESS: 'CALL_API_RULE_UPDATE_SUCCESS',
  CALL_API_RULE_GOLF_TIME_UPDATE: 'CALL_API_RULE_GOLF_TIME_UPDATE',
  CALL_API_RULE_GOLF_TIME_UPDATE_SUCCESS: 'CALL_API_RULE_GOLF_TIME_UPDATE_SUCCESS',
  RESET_RULE_FLG: 'RESET_RULE_FLG',
  RULE_DATA_UPDATE: 'RULE_DATA_UPDATE',
};
// endregion

// region action
export const actCallRuleGetStore = () => ({
  type: ACTION_TYPE.CALL_API_RULE_GET_STORE,
});

export const actCallRuleGetStoreSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_RULE_GET_STORE_SUCCESS,
  stores,
});

// 検索処理
export const actCallApiGetRule = (storeId) => ({
  type: ACTION_TYPE.CALL_API_RULE_SEARCH,
  storeId,
});

export const actCallApiGetRuleSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_RULE_SEARCH_SUCCESS,
  datas,
});

// 更新処理
export const actCallApiRulesUpdate = (updateContents, flg) => ({
  type: ACTION_TYPE.CALL_API_RULE_UPDATE,
  updateContents,
  flg,
});

export const actRulesUpdateSuccess = (resultDatas, flg) => ({
  type: ACTION_TYPE.CALL_API_RULE_UPDATE_SUCCESS,
  resultDatas,
  flg,
});

export const actCallApiRuleGolfTimeUpdate = (updateContents, isBox) => ({
  type: ACTION_TYPE.CALL_API_RULE_GOLF_TIME_UPDATE,
  updateContents,
  isBox,
});

export const actCallApiRuleGolfTimeUpdateSuccess = (results, isBox) => ({
  type: ACTION_TYPE.CALL_API_RULE_GOLF_TIME_UPDATE_SUCCESS,
  results,
  isBox,
});

// Data更新
export const actRuleDataUpdate = (update) => ({
  type: ACTION_TYPE.RULE_DATA_UPDATE,
  update,
});

// 更新成功フラグ初期化
export const actResetRuleFlg = () => ({
  type: ACTION_TYPE.RESET_RULE_FLG,
});
// endregion
