import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actCallApiGetSelectsSuccess,
  actCallApiGetRoomScheduleSuccess,
  actCallApiRoomScheduleMemberListSuccess,
  actApiRoomScheduleUpsertSuccess,
  actCallApiGetRoomScheduleDataSuccess,
  actCallApiGetRoomScheduleDetailSuccess,
  actCallApiPostUpdateRoomMemberSuccess,
  actCallApiRoomScheduleDeleteByDeteilSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { REST_API } from '../../../constants/restApiUrls';
import {
  getMembers, insertRoomSchedule, updateRoomSchedule,
  getRoomScheduleData,
  getRoomMmebers, updateRoomMember,
  deleteRoomScheduleByDetail,
} from '../../../services/booking/roomSchedule.service';
import { getDatas } from '../../../services/base.service';

/**
 * 初期表示
 */
function* callApiGetSelects() {
  try {
    const selects = yield call(getDatas, REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE_SELECT);
    yield put(actCallApiGetSelectsSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetDatas({ searchCondition }) {
  try {
    const datas = yield call(getDatas,
      REST_API.BOOKING.ROOM_SCHEDULE.ROOM_SCHEDULE, searchCondition);
    yield put(actCallApiGetRoomScheduleSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetMemberDatas({ searchCondition }) {
  try {
    const members = yield call(getMembers, searchCondition);
    yield put(actCallApiRoomScheduleMemberListSuccess(members));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiRoomScheduleUpsert({ params }) {
  try {
    const datas = {
      menuName: params.menuName,
      startTime: params.startTime,
      endTime: params.endTime,
      rooms: params.rooms,
      memo: params.memo,
      memberId: params.memberId,
      storeId: params.storeId,
      targetDate: params.targetDate,
    };

    let existData = null;
    if (!params.id) {
      existData = yield call(insertRoomSchedule, datas);
    } else {
      existData = yield call(updateRoomSchedule, params.id, datas);
    }
    yield put(actApiRoomScheduleUpsertSuccess(existData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetRoomScheduleData(id) {
  try {
    const scheduleData = yield call(getRoomScheduleData, id);
    yield put(actCallApiGetRoomScheduleDataSuccess(scheduleData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetRoomScheduleDetail({ data }) {
  try {
    const detailData = yield call(getRoomMmebers, data);
    yield put(actCallApiGetRoomScheduleDetailSuccess(detailData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* actCallApiPostUpdateRoomMember({ data }) {
  try {
    const detailData = yield call(updateRoomMember, data);
    yield put(actCallApiPostUpdateRoomMemberSuccess(detailData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* actCallApiRoomScheduleDeleteByDeteil({ id }) {
  try {
    yield call(deleteRoomScheduleByDetail, id);
    yield put(actCallApiRoomScheduleDeleteByDeteilSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRoomSchedule() {
  yield takeLatest(ACTION_TYPE.CALL_API_ROOM_SCHEDULE_GET_SELECTS, callApiGetSelects);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE, callApiGetDatas);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_MEMBER_LIST, callApiGetMemberDatas);
  yield takeLatest(ACTION_TYPE.CALL_API_ROOM_SCHEDULE_UPSERT, callApiRoomScheduleUpsert);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DATA, callApiGetRoomScheduleData);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROOM_SCHEDULE_DETAIL, callApiGetRoomScheduleDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_POST_ROOM_MEMBER_UPDATE, actCallApiPostUpdateRoomMember);
  yield takeLatest(ACTION_TYPE.CALL_API_ROOM_SCHEDULE_DELETE_BY_DETAIL,
    actCallApiRoomScheduleDeleteByDeteil);
}
