// #region Type
export const ACTION_TYPE = {
  CALL_API_WEEK_SCHEDULE_SAVE: 'CALL_API_WEEK_SCHEDULE_SAVE',
  CALL_API_WEEK_SCHEDULE_SAVE_SUCCESS: 'CALL_API_WEEK_SCHEDULE_SAVE_SUCCESS',
  CALL_API_WEEK_SCHEDULE_SEARCH: 'CALL_API_WEEK_SCHEDULE_SEARCH',
  CALL_API_WEEK_SCHEDULE_SEARCH_SUCCESS: 'CALL_API_WEEK_SCHEDULE_SEARCH_SUCCESS',
  CALL_API_WEEK_SCHEDULE_DELETE: 'CALL_API_WEEK_SCHEDULE_DELETE',
  CALL_API_WEEK_SCHEDULE_DELETE_SUCCESS: 'CALL_API_WEEK_SCHEDULE_DELETE_SUCCESS',
  CALL_API_WEEK_SCHEDULE_FLG_RESET: 'CALL_API_WEEK_SCHEDULE_FLG_RESET',
  CALL_API_WEEK_MENU_SELECT_LIST: 'CALL_API_WEEK_MENU_SELECT_LIST',
  CALL_API_WEEK_MENU_SELECT_LIST_SUCCESS: 'CALL_API_WEEK_MENU_SELECT_LIST_SUCCESS',
};
// #endregion

// #region action
export const actCallApiWeekScheduleSearch = (schoolFlg, id) => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SEARCH,
  schoolFlg,
  id,
});

export const actCallApiWeekScheduleSearchSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SEARCH_SUCCESS,
  data,
});

export const actCallApiWeekScheduleSave = (schoolFlg, data) => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SAVE,
  schoolFlg,
  data,
});

export const actCallApiWeekScheduleSaveSuccess = () => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_SAVE_SUCCESS,
});

export const actCallApiWeekScheduleDelete = (schoolFlg, id) => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_DELETE,
  schoolFlg,
  id,
});

export const actCallApiWeekScheduleDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_DELETE_SUCCESS,
});

export const actCallApiWeekScheduleFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_WEEK_SCHEDULE_FLG_RESET,
});

export const actCallApiSelectList = (contentId, storeId) => ({
  type: ACTION_TYPE.CALL_API_WEEK_MENU_SELECT_LIST,
  contentId,
  storeId,
});

export const actCallApiSelectListSuccess = (selectList) => ({
  type: ACTION_TYPE.CALL_API_WEEK_MENU_SELECT_LIST_SUCCESS,
  selectList,
});
// #endregion
