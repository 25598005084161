import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Checkbox, Card, CardContent, Grid, Button, withStyles, Box, Toolbar, Typography, IconButton,
  Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Divider, Tooltip,
} from '@material-ui/core';
import { isAfter, isEqual, format } from 'date-fns';
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actResetContractFlg,
  actSearchMember, actContractDelete,
} from '../../../redux/member/memberDetail/action';
import urls from '../../../constants/urls';
import ChangePassword from '../../other/changePassword/ChangePassword';
import MemberDetailChangeDialog from './MemberDetailChangeDialog';
import AddContractDialog from './AddContractDialog';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import WithdrawalDialog from './WithdrawalDialog';

const styles = () => ({
  container: {
    maxHeight: '70vh',
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  title: {
    flex: '1 1 50%',
  },
});

class MemberDetail extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      {
        url: urls.MEMBER.MEMBER_LIST,
        name: '会員一覧',
      },
      {
        name: '会員詳細',
      },
    ];

    const memberId = props.location.state.memberId || null;

    this.state = {
      memberId,
      contracts: [],
      selectContractIds: [],
      isPasswordModalOpen: false,
      memberChangeModalOpen: false,
      memberDetailInfo: {
        member: {},
        store: {
          name: '',
        },
      },
      isContracter: false,
      addContractModalOpen: false,
      deleteContractModalOpen: false,
      btnLoading: false,
      successSnackOpen: false,
      successSnackMessage: false,
      isWithdrawalOpen: false,
      isShowPassword: false,
    };

    if (!props.location.state) {
      props.history.push({
        pathname: urls.MEMBER.MEMBER_LIST,
      });
    } else {
      props.dispatch(actSearchMember({
        id: memberId,
      }));
    }
  }

  componentDidMount() {
    document.title = '会員詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberDetail.data !== this.props.memberDetail.data) {
      if (this.props.memberDetail.data) {
        this.setData(this.props.memberDetail.data);
      }
    }

    if (prevProps.memberDetail.isContractInsertSuccess
        !== this.props.memberDetail.isContractInsertSuccess) {
      if (this.props.memberDetail.isContractInsertSuccess) {
        this.setCommonSuccess('契約を登録しました');
      }
    }

    if (prevProps.memberDetail.isContractDeleteSuccess
        !== this.props.memberDetail.isContractDeleteSuccess) {
      if (this.props.memberDetail.isContractDeleteSuccess) {
        this.setCommonSuccess('契約を削除しました');
      }
    }

    if (prevProps.memberDetail.isMemberUpdate
      !== this.props.memberDetail.isMemberUpdate) {
      if (this.props.memberDetail.isMemberUpdate) {
        this.setCommonSuccess(`${this.state.isContracter ? '契約者' : '会員'}の変更が完了しました`);
      }
    }
  }

  setData = (data) => {
    this.props.dispatch(actResetContractFlg());
    this.setState({
      memberDetailInfo: {
        member: data.memberData,
        contract: data.contractData,
        store: data.storeData,
      },
      contracts: data.detailList,
    });
  }

  handleHeaderClose = () => {
    this.setState({ selectContractIds: [] });
  }

  onAddContract = () => {
    this.setState({ addContractModalOpen: true, successSnackOpen: false });
  }

  handleAddModalClose = () => {
    this.setState({ addContractModalOpen: false });
  }

  onDeleteContractModal = () => {
    this.setState({ deleteContractModalOpen: true });
  }

  onDeleteContract = () => {
    const { selectContractIds } = this.state;

    this.props.dispatch(actContractDelete({
      contractDetailIdList: selectContractIds,
    }));
  }

  onPreWithdrawal = () => {
    this.setState({ isWithdrawalOpen: true, successSnackOpen: false });
  }

  onWithdrawal = (type, date) => {
    const { contracts, memberDetailInfo } = this.state;

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));
    const contractDetailDatas = contracts.filter(
      (v) => isEqual(new Date(v.endedOn), today) || isAfter(new Date(v.endedOn), today),
    );

    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL_WITHDRAWAL,
      state: {
        contractDetailDatas,
        id: memberDetailInfo.member.id,
        type,
        date,
      },
    });
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteContractModalOpen: false, isWithdrawalOpen: false });
  }

  onChangePasswordModalOpen = () => {
    this.setState({ isPasswordModalOpen: true, successSnackOpen: false });
  }

  onChangePasswordModalClose = () => {
    this.setState({ isPasswordModalOpen: false });
  }

  handleSelectAllClick = () => {
    const { contracts, selectContractIds: contractIds } = this.state;
    let selectedData = [];
    if (contractIds.length !== contracts.length) {
      const newSelecteds = contracts.map((scr) => scr.id);
      selectedData = newSelecteds;
    }

    this.setState({ selectContractIds: selectedData });
  };

  handleClick = (_, screenId) => {
    const { selectContractIds: contractIds } = this.state;
    const selectedIndex = contractIds.indexOf(screenId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(contractIds, screenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(contractIds.slice(1));
    } else if (selectedIndex === contractIds.length - 1) {
      newSelected = newSelected.concat(contractIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        contractIds.slice(0, selectedIndex),
        contractIds.slice(selectedIndex + 1),
      );
    }

    this.setState({ selectContractIds: newSelected });
  };

  memberChangeModalOpen = () => {
    this.setState({
      memberChangeModalOpen: true,
      successSnackOpen: false,
      isContracter: false,
    });
  }

  contractChangeModalOpen = () => {
    this.setState({
      memberChangeModalOpen: true,
      successSnackOpen: false,
      isContracter: true,
    });
  }

  memberChangeModalClose = () => {
    this.setState({ memberChangeModalOpen: false });
  }

  setChangePasswordSuccess = () => {
    this.setState({ successSnackOpen: true, isPasswordModalOpen: false, successSnackMessage: 'パスワードの変更が完了しました' });
  }

  setCommonSuccess = (msg) => {
    const { memberId } = this.state;
    this.setState({
      successSnackOpen: true,
      memberChangeModalOpen: false,
      successSnackMessage: msg,
      addContractModalOpen: false,
      deleteContractModalOpen: false,
      selectContractIds: [],
    });
    this.props.dispatch(actSearchMember({
      id: memberId,
    }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  isSelected = (screenId) => this.state.selectContractIds.includes(screenId);

  onClickPassword = (_, isShowPassword) => {
    this.setState({ isShowPassword });
  };

  render() {
    const { classes } = this.props;

    const {
      memberId, memberDetailInfo, isPasswordModalOpen,
      successSnackOpen, successSnackMessage, contracts, selectContractIds, memberChangeModalOpen,
      isContracter, addContractModalOpen, deleteContractModalOpen,
      btnLoading, isWithdrawalOpen, isShowPassword,
    } = this.state;

    const startDate = memberDetailInfo.member.startedOn ? format(new Date(memberDetailInfo.member.startedOn.replaceAll('-', '/')), 'yyyy年MM月dd日') : '';
    const endDate = memberDetailInfo.member.startedOn ? format(new Date(memberDetailInfo.member.endedOn.replaceAll('-', '/')), 'yyyy年MM月dd日') : '';
    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box fontWeight="fontWeightBold" mb={1} fontSize={20}>
                    {memberDetailInfo.member.lastName}
                    {' '}
                    {memberDetailInfo.member.firstName}
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="所属店舗" />
                    <Box>{memberDetailInfo.store.name}</Box>
                  </Box>
                  <Box mt={2}>
                    <FormTitle title="会員番号" />
                    <Box>{memberDetailInfo.member.memberNo}</Box>
                  </Box>
                  <Box mt={2}>
                    <FormTitle title="利用可能期間" />
                    <Box>{`${startDate} - ${endDate}`}</Box>
                  </Box>
                  <Box mt={2}>
                    <FormTitle title="ログインパスワード" />
                    {!memberDetailInfo.member.password && (
                      <Box>未設定</Box>
                    )}
                    {(memberDetailInfo.member.password
                      && !memberDetailInfo.member.passwordNoCrypt) && (
                      <Box>未解読</Box>
                    )}
                    {(memberDetailInfo.member.passwordNoCrypt && isShowPassword) && (
                      <Box display="flex">
                        <Box>{memberDetailInfo.member.passwordNoCrypt}</Box>
                        <IconButton aria-label="delete" size="small" onClick={this.handleHeaderClose}>
                          <VisibilityOffIcon fontSize="inherit" onClick={(e) => this.onClickPassword(e, false)} />
                        </IconButton>
                      </Box>
                    )}
                    {(memberDetailInfo.member.passwordNoCrypt && !isShowPassword) && (
                      <Box display="flex">
                        <Box>{'●'.repeat(memberDetailInfo.member.passwordNoCrypt.length)}</Box>
                        <IconButton aria-label="delete" size="small" onClick={this.handleHeaderClose}>
                          <VisibilityIcon fontSize="inherit" onClick={(e) => this.onClickPassword(e, true)} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CardContent>
                <Divider />
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" onClick={this.onChangePasswordModalOpen}>パスワード変更</Button>
                </Box>
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" onClick={this.memberChangeModalOpen}>会員詳細を見る</Button>
                </Box>
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" onClick={this.contractChangeModalOpen}>契約者詳細を見る</Button>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper>
                <Toolbar className={selectContractIds.length > 0 ? classes.highlight : ''}>
                  {selectContractIds.length > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                      <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                      {selectContractIds.length}
                      {' '}
                      件選択中
                    </Typography>
                  ) : (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
                  )}

                  {selectContractIds.length > 0 && (
                  <Tooltip title="契約削除">
                    <Button color="secondary" onClick={this.onDeleteContractModal}>契約削除</Button>
                  </Tooltip>
                  )}
                  {selectContractIds.length === 0 && (
                  <Tooltip title="契約追加">
                    <Button color="primary" onClick={this.onAddContract}>契約追加</Button>
                  </Tooltip>
                  )}
                  {selectContractIds.length === 0 && (
                  <Tooltip title="退会処理">
                    <Button color="secondary" onClick={this.onPreWithdrawal}>退会処理</Button>
                  </Tooltip>
                  )}
                </Toolbar>
                <TableContainer className={classes.container}>
                  <Table
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow
                        key="table-header-check"
                        padding="checkbox"
                      >
                        <TableCell align="center" padding="checkbox">
                          {' '}
                          <Checkbox
                            onClick={this.handleSelectAllClick}
                            checked={
                              selectContractIds.length === contracts.length
                            }
                          />

                        </TableCell>
                        <TableCell align="center">商品名</TableCell>
                        <TableCell align="center">契約開始日</TableCell>
                        <TableCell align="center">契約終了日</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(contracts || []).map((contract) => {
                        const isSelected = this.isSelected(contract.id);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={`box-check-${contract.id}`}
                            selected={isSelected}
                          >
                            <TableCell role="checkbox">
                              <Checkbox
                                checked={isSelected}
                                inputProps={{ 'aria-labelledby': contract.id }}
                                color="primary"
                                onClick={(event) => this.handleClick(event, contract.id)}
                              />
                            </TableCell>
                            <TableCell align="center">{contract.productName}</TableCell>
                            <TableCell align="center">{contract.startedOn}</TableCell>
                            <TableCell align="center">{contract.endedOn}</TableCell>
                          </TableRow>
                        );
                      })}
                      {
                        (contracts || []).length < 1 && (
                          <TableRow>
                            <TableCell className={classes.emptyTableCell} colSpan={5}>
                              データがありません
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />
        </MainBody>
        <ChangePassword
          open={isPasswordModalOpen}
          userId={memberDetailInfo.member.id}
          memberFlg
          onClose={this.onChangePasswordModalClose}
          changePasswordSuccess={this.setChangePasswordSuccess}
          name={`${memberDetailInfo.member.lastName} ${memberDetailInfo.member.firstName}`}
        />
        <AddContractDialog
          open={addContractModalOpen}
          handleClose={this.handleAddModalClose}
          mstStore={memberDetailInfo.store}
          memberId={memberId}
        />
        <MemberDetailChangeDialog
          open={memberChangeModalOpen}
          onClose={this.memberChangeModalClose}
          member={isContracter ? memberDetailInfo.contract : memberDetailInfo.member}
          isContracter={isContracter}
        />
        <WithdrawalDialog
          open={isWithdrawalOpen}
          handleClose={this.handleDeleteModalClose}
          handleExecute={this.onWithdrawal}
        />
        <ConfirmDialog
          open={deleteContractModalOpen}
          handleClose={this.handleDeleteModalClose}
          executeText="削除する"
          handleExecute={this.onDeleteContract}
          title="選択した契約の削除"
          contentText="選択した契約を削除します。この操作は元に戻すことができません。"
          loading={btnLoading}
          buttonColor="secondary"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
  members: state.members,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberDetail)));
