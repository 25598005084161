const selectValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const selectListValidation = (value) => {
  if (value.length === 0) return '選択してください';
  return '';
};

const compareTimeCheck = (startTime, endTime) => {
  if (startTime > endTime) return '開始時以降の値を入力してください';
  return '';
};

const nameValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'menuName':
        return nameValidation(value);
      case 'startTime':
      case 'endTime':
        return selectValidation(value);
      case 'rooms':
        return selectListValidation(value);
      default:
        return '';
    }
  };

  static compareTime = (startTime, endTime) => compareTimeCheck(startTime, endTime);
}

export default Validation;
