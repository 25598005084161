import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  withStyles,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Checkbox,
  TableSortLabel,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '../../../components/atoms/pagination/Pagination';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import { actCallApiBookingUpdateStatus, actResetUpdateSuccessFlg } from '../../../redux/booking/bookingList/action';
import { getComparator, stableSort, getSelected } from '../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  funcBtn: {
    width: '100px',
    'white-space': 'nowrap',
  },
});

class BookingListDataTable extends React.Component {
  constructor() {
    super();

    this.state = {
      selected: [],
      selectedCanceledOpen: false,
      loading: false,
      order: 'asc',
      orderBy: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      if (this.props.rows) {
        this.handleHeaderClose();
      }
    }
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.statusCode === 0 && row.contentId !== 2)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
  };

  onPreCanceled = () => {
    this.setState({ selectedCanceledOpen: true, loading: false });
  }

  handleClose = () => {
    this.setState({ selectedCanceledOpen: false });
  }

  onCanceled = () => {
    const { selected } = this.state;
    const { rows } = this.props;
    this.setState({ selectedCanceledOpen: false, selected: [], loading: true });

    const selectedBookingIds = rows
      .filter((row) => selected.indexOf(row.id) !== -1)
      .map((n) => n.bookingId);

    this.props.dispatch(
      actResetUpdateSuccessFlg(),
    );

    this.props.dispatch(
      actCallApiBookingUpdateStatus({
        ids: selectedBookingIds,
        status: 2,
        summarizeUpdFlg: true,
      }),
    );
  }

  getTableHeadCell(headCell) {
    const { isMain, classes } = this.props;
    const { order, orderBy } = this.state;
    if (headCell.id === 'storeName' && !isMain) {
      return null;
    }
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? 'right' : 'left'}
        padding="default"
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={this.createSortHandler(headCell.id)}
          className={classes.tableLabel}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {
    const {
      classes,
      rows,
      onDetailClick,
      onStatusClick,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      headCells,
    } = this.props;

    const {
      selected,
      selectedCanceledOpen,
      loading,
      order,
      orderBy,
    } = this.state;

    return (
      <div>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : ''}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 && (
              <Tooltip title="休講にする">
                <Button color="primary" onClick={this.onPreCanceled}>休講にする</Button>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length
                        && rows.filter((row) => row.statusCode === 0
                          && row.contentId !== 2)
                          .length !== selected.length}
                      checked={
                        rows.filter((row) => row.statusCode === 0
                          && row.contentId !== 2).length > 0
                        && rows.filter((row) => row.statusCode === 0
                          && row.contentId !== 2)
                          .length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length < 1 || rows.filter(
                          (row) => row.statusCode === 0 && row.contentId !== 2,
                        ).length < 1
                      }
                    />
                  </TableCell>
                  {headCells.map((headCell) => this.getTableHeadCell(headCell))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = this.isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {row.statusCode === 0 && row.contentId !== 2 && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.targetDate}
                      </TableCell>
                      <TableCell>
                        {row.targetTime}
                      </TableCell>
                      <TableCell>
                        {row.contentName}
                      </TableCell>
                      <TableCell>
                        {row.menuName}
                      </TableCell>
                      <TableCell>
                        {row.reservationNum}
                      </TableCell>
                      <TableCell>
                        {row.capacity}
                      </TableCell>
                      <TableCell>
                        {row.statusName}
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={0} wrap="nowrap">
                          <Grid item xs>
                            <Button className={classes.funcBtn} color="primary" onClick={(event) => onDetailClick(event, row)}>詳細を見る</Button>
                          </Grid>
                          <Grid item xs>
                            {
                              (row.contentId !== 2)
                              && (row.statusCode === 0 || row.statusCode === 2)
                                ? (<Button className={classes.funcBtn} color={row.statusCode === 0 ? 'primary' : 'secondary'} onClick={(event) => onStatusClick(event, row)}>{row.statusCode === 0 ? ('休講にする') : ('休講を取消する')}</Button>)
                                : (<Button className={classes.funcBtn} disabled />)
                            }
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={headCells.length + 2}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <ConfirmDialog
            open={selectedCanceledOpen}
            handleClose={this.handleClose}
            executeText="休講にする"
            handleExecute={this.onCanceled}
            title="選択したスケジュールの休講"
            contentText="選択したスケジュールを休講にします。"
            loading={loading}
            buttonColor="primary"
          />
        </Paper>
      </div>
    );
  }
}

BookingListDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

BookingListDataTable.defaultProps = {
  totalElements: 0,
};

const mapStateToProps = (state) => ({
  bookingTable: state.booking,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingListDataTable)));
