import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';

import {
  Card, CardContent, Grid, withStyles, Box, Container,
} from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import urls from '../../../constants/urls';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MemberLinkageDataTable from './MemberLinkageDataTable';
import MemberLinkageDialog from './MemberLinkageDialog';
import {
  actCallInitMemberLinkage, actCallApiMemberLinkageList,
  actCallApiMemberLinkageDelete, actApiMemberLinkageFlgReset,
} from '../../../redux/school/memberLinkage/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
});

class MemberLinkage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      records: 10,
      datas: {},
      scheduleInfo: [],
      successSnackOpen: false,
      dialogOpen: false,
      snackMessage: '',
      addFlg: false,
      linkMemberCount: null,
    };

    this.breadcrumbsText = [];
    this.isMain = false;

    if (!props.location.state) {
      this.props.history.push({
        pathname: urls.SCHOOL.WEEK_SCHEDULES,
      });
      return;
    }

    this.isMain = this.props.location.state.isMain;
    this.props.dispatch(actCallInitMemberLinkage(
      { id: props.location.state.detailId },
    ));
    this.props.dispatch(actCallApiMemberLinkageList(
      { page: 0, records: 10, detailId: props.location.state.detailId },
    ));
    this.breadcrumbsText = [
      {
        url: urls.SCHOOL.WEEK_SCHEDULES,
        name: '定期曜日別スケジュール一覧',
      },
      {
        url: urls.SCHOOL.WEEK_SCHEDULE_DETAIL,
        name: '定期曜日別スケジュール詳細',
        id: props.location.state.headerId,
      },
      {
        name: '会員紐付け',
      },
    ];
  }

  componentDidMount() {
    document.title = '会員紐付け';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberLinkage.infoData !== this.props.memberLinkage.infoData) {
      if (this.props.memberLinkage.infoData) {
        this.setInfoData(this.props.memberLinkage.infoData);
      }
    }
    if (prevProps.memberLinkage.datas !== this.props.memberLinkage.datas) {
      if (this.props.memberLinkage.datas) {
        this.setMemberDatas(this.props.memberLinkage.datas);
      }
    }
    if (prevProps.memberLinkage.isDeleteSuccess !== this.props.memberLinkage.isDeleteSuccess) {
      if (this.props.memberLinkage.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.memberLinkage.isInsertSuccess !== this.props.memberLinkage.isInsertSuccess) {
      if (this.props.memberLinkage.isInsertSuccess) {
        this.setInsertSuccess();
      }
    }
  }

  setInfoData(infoData) {
    this.setState({ scheduleInfo: infoData });
  }

  setMemberDatas(datas) {
    this.setState({ datas, linkMemberCount: datas.content.length });
  }

  setDeleteSuccess() {
    this.setState({ successSnackOpen: true, snackMessage: '会員紐付けの削除が完了しました' });
    const { page, records, scheduleInfo } = this.state;
    this.props.dispatch(actCallApiMemberLinkageList(
      { page, records, detailId: scheduleInfo.id },
    ));
  }

  setInsertSuccess() {
    this.setState({ addFlg: true });
  }

  handleChangePage = (_, newPage) => {
    const { scheduleInfo, records } = this.state;
    this.setState({ page: newPage });
    this.props.dispatch(actCallApiMemberLinkageList(
      { page: newPage, records, detailId: scheduleInfo.id },
    ));
  };

  handleChangeRowsPerPage = (event) => {
    const { scheduleInfo } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.props.dispatch(actCallApiMemberLinkageList(
      { page: 0, records: rowsPerPage, detailId: scheduleInfo.id },
    ));
  };

  onDelete = (value) => {
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actApiMemberLinkageFlgReset());
    this.props.dispatch(actCallApiMemberLinkageDelete({ deleteList: value }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  onAdd = () => {
    this.setState({ dialogOpen: true, successSnackOpen: false });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
    const { addFlg } = this.state;
    if (addFlg) {
      const { page, records, scheduleInfo } = this.state;
      this.props.dispatch(actCallApiMemberLinkageList(
        { page, records, detailId: scheduleInfo.id },
      ));
    }
    this.setState({ addFlg: false });
  }

  render() {
    const { classes } = this.props;

    const {
      page, records, datas, scheduleInfo, successSnackOpen,
      dialogOpen, snackMessage, linkMemberCount,
    } = this.state;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <Box mb={3} display={this.isMain ? '' : 'none'}>
                    <FormTitle title="店舗" />
                    <Box>{scheduleInfo.storeName}</Box>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="対象年月" />
                    <Box>{scheduleInfo.date}</Box>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="コンテンツ" />
                    <Box>{scheduleInfo.contentName}</Box>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="メニュー" />
                    <Box>{scheduleInfo.menuName}</Box>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="曜日" />
                    <Box>{scheduleInfo.week}</Box>
                  </Box>
                  <Box mb={3}>
                    <FormTitle title="開催時間" />
                    <Box>
                      {scheduleInfo.startTime}
                      ~
                      {scheduleInfo.endTime}
                    </Box>
                  </Box>
                  <Box>
                    <FormTitle title="定員" />
                    <Box>
                      {scheduleInfo.capacity}
                      名
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <MemberLinkageDataTable
                rows={datas.content ? datas.content : []}
                addButtonLabel="会員紐付の追加"
                deleteButtonLabel="会員紐付の削除"
                page={page}
                rowsPerPage={records}
                totalElements={datas.totalElements}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                onDelete={this.onDelete}
                onAdd={this.onAdd}
                deleteDialogTitle="選択した会員紐付けの削除"
                deleteDialogContent="選択した会員紐付けを削除します。この操作は元に戻すことができません。"
                capacity={scheduleInfo.capacity}
              />
            </Grid>
          </Grid>
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={snackMessage}
          />
          <MemberLinkageDialog
            open={dialogOpen}
            onClose={this.handleDialogClose}
            storeId={scheduleInfo.storeId}
            detailId={scheduleInfo.id}
            dialogCapacity={scheduleInfo.capacity}
            LinkMemberCount={linkMemberCount}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ memberLinkage: state.memberLinkage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberLinkage)));
