import axios from 'axios';
import UrlHelper from '../helpers/url.helper';
import Common from '../helpers/common';
import { actToggleLoadingScreen, actCallApiResetServerError } from '../redux/common/common/action';
import store from '../store/store';

/**
 * Define http connection method: GET, POST, PUT, DELETE
 */
export default class HttpConnection {
  constructor() {
    store.dispatch(actCallApiResetServerError());
    this.header = this.headerOptions();
    this.common = new Common();
    if (window.location.pathname.indexOf('login') < 0) {
      store.dispatch(actToggleLoadingScreen(true));
    }
    axios.defaults.withCredentials = true;
  }

  /**
   * Function use for get header
   */
  headerOptions = () => {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  uploadHeaderOptions = () => {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async get(url, parameter) {
    const getUrl = encodeURI(UrlHelper.convertQueryUrl(url, parameter));
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async getById(url, parameter) {
    const getUrl = UrlHelper.convertPathUrl(url, parameter);
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async post(url, parameter) {
    const requestOptions = {
      method: 'POST',
      headers: this.header,
      data: parameter,
      url,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Put
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async put(url, queryParameter, parameter) {
    const putUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'PUT',
      headers: this.header,
      data: parameter,
      url: putUrl,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Delete
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async delete(url, parameter) {
    const deleteUrl = UrlHelper.convertPathUrl(url, parameter);
    const requestOptions = {
      method: 'DELETE',
      headers: this.header,
      url: deleteUrl,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http deletes
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async deletes(url, parameter) {
    const requestOptions = {
      method: 'DELETE',
      headers: this.header,
      url,
      data: parameter,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post(create)
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async create(url, parameter) {
    const requestOptions = {
      method: 'POST',
      headers: this.uploadHeaderOptions(),
      data: parameter,
      url,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Pup(upload)
   * @param {*} url
   * @param {*} queryParameter
   * @param {*} parameter
   */
  async update(url, queryParameter, parameter) {
    const putUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'PUT',
      headers: this.uploadHeaderOptions(),
      data: parameter,
      url: putUrl,
    };
    const result = await axios(requestOptions)
      .then(this.common.handleResponse)
      .catch((error) => {
        this.common.handleErrorResponse(error);
      });
    return result;
  }
}
