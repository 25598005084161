import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  Box, Paper,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import {
  actCallApiUpdateSubscriberStatus,
} from '../../../redux/booking/subscriberList/action';
import RequiredChip from '../../../components/atoms/requiredChip/RequiredChip';

class SubscriberUpdateStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusCodes: [],
      updateStatus: null,
      changeLoading: false,
      contentsMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  onItemChange = (_, values) => {
    this.setState({ updateStatus: values });
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  onChangeStatus = (event) => {
    this.setState({ updateStatus: event.target.value, contentsMessage: '' });
  }

  handleUpdateStatus = () => {
    let changeLoading = true;
    let contentsMessage = '';
    const { row } = this.props;
    const { updateStatus } = this.state;

    if (!updateStatus) {
      changeLoading = false;
      contentsMessage = '選択してください';
    }
    this.setState({ contentsMessage, changeLoading });
    if (contentsMessage) {
      return;
    }

    this.props.dispatch(
      actCallApiUpdateSubscriberStatus({
        id: row.bookingCode,
        searchCondition: {
          status: updateStatus.id,
          contentId: row.contentId,
          transferAheadFlg: row.transferAheadId !== null,
        },
      }),
    );
    this.props.close();
  }

  init() {
    const { row, subscriber } = this.props;
    const memberStatusCode = row.memberStatus;

    const statusList = row.regular
      ? subscriber.selects.status.regularStatusList
      : subscriber.selects.status.eventStatusList;

    let statusCodes = statusList.filter((code) => code.id !== memberStatusCode);

    if (row.transferAheadId) {
      statusCodes = statusList.filter(
        (code) => code.id !== memberStatusCode && code.id !== 3,
      );
    }

    this.setState({
      statusCodes,
      updateStatus: null,
      contentsMessage: '',
      changeLoading: false,
    });
  }

  render() {
    const {
      open,
      close,
      row,
    } = this.props;
    const {
      updateStatus,
      changeLoading,
      statusCodes,
      contentsMessage,
    } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="xs"
          aria-labelledby="update-memberStatus-title"
          aria-describedby="update-memberStatus-description"
          disableBackdropClick
          onEscapeKeyDown={close}
        >
          <DialogTitle>
            <Box>ステータスを変更</Box>
          </DialogTitle>
          <DialogContent dividers>
            <Paper elevation={0} variant="outlined">
              <Box m={2}>
                <Box mr={10}>
                  <Box fontWeight="fontWeightBold" fontSize={16}>予約日</Box>
                  <Box fontSize={16} mt={1}>{row.targetDate}</Box>
                </Box>
              </Box>
              <Box m={2}>
                <Box mr={10}>
                  <Box fontWeight="fontWeightBold" fontSize={16}>時間</Box>
                  <Box fontSize={16} mt={1}>{row.targetTime}</Box>
                </Box>
              </Box>
              <Box m={2}>
                <Box mr={10}>
                  <Box fontWeight="fontWeightBold" fontSize={16}>メニュー</Box>
                  <Box fontSize={16} mt={1}>{row.menuName}</Box>
                </Box>
              </Box>
              <Box m={2}>
                <Box mr={10}>
                  <Box fontWeight="fontWeightBold" fontSize={16}>名前</Box>
                  <Box fontSize={16} mt={1}>{row.memberNameKana}</Box>
                  <Box fontSize={16} mt={1}>{row.memberName}</Box>
                </Box>
              </Box>
            </Paper>
            <Box mt={3}>
              <Box mb={3}>
                <Box fontWeight="fontWeightBold" m={1} fontSize={17}>変更前</Box>
                <Box fontSize={16} mt={1} ml={1}>
                  {row.memberStatusName}
                </Box>
              </Box>
              <Box>
                <Box fontWeight="fontWeightBold" m={1} fontSize={17}>
                  変更後
                  <RequiredChip />
                </Box>
                <SearchSelectBox
                  options={statusCodes}
                  value={updateStatus}
                  onChange={this.onChangeStatus}
                  name="updateStatus"
                  error={contentsMessage !== ''}
                  disableClearable
                />
                <FormErrorText>{contentsMessage}</FormErrorText>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>
              キャンセル
            </Button>
            <LoadingButton color="primary" label="変更する" onClick={this.handleUpdateStatus} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriber: state.subscriber,
});

export default withRouter(connect(mapStateToProps)(SubscriberUpdateStatusDialog));
