import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Container, Button,
  AppBar, Tab, Tabs,
} from '@material-ui/core';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import CalendarScheduleMenuDialog from '../calendarScheduleMenuDialog/CalendarScheduleMenuDialog';
import { actCallApiSelectList } from '../../../redux/share/weekScheduleMenu/action';
import {
  actCallApiCalendarScheduleDetail, actCallApiCalendarScheduleFlgReset, actSetHeader,
  actCallApiBookingDelete,
} from '../../../redux/share/calendarScheduleDetail/action';
import urls from '../../../constants/urls';
import CalendarSchedulePublishDialog from './CalendarSchedulePublishDialog';
import CalendarScheduleCalendarTable from './share/CalendarScheduleCalendarTable';
import BookingHeader from './BookingHeader';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import DataTable from './DataTable';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
  headerUpdateBtn: {
    borderTop: '1px solid #eeeeee',
    paddingTop: '10px',
  },
  paper: {
    width: 'fit-content',
    marginBottom: '20px',
  },
});

class CalendarScheduleDetail extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop();
    this.breadcrumbsText = [
      {
        url: urls.EVENT.EVENT_CALENDARS,
        name: '予約カレンダー作成',
      },
      {
        name: '予約カレンダー詳細',
      },
    ];

    let headerId = null;
    let storeId = null;
    let contentId = null;
    let isRelationMain = false;
    let regular = false;

    if (props.calendarScheduleDetail.header) {
      const { header, isMain } = props.calendarScheduleDetail.header;
      headerId = header.id;
      storeId = header.storeId;
      contentId = header.contentId;
      regular = header.regular;
      isRelationMain = isMain;
    }

    if (props.location.state) {
      headerId = props.location.state.headerId;
      storeId = props.location.state.storeId;
      contentId = props.location.state.contentId;
      isRelationMain = props.location.state.isMain;
      regular = props.location.state.regular;
    }

    if (regular === true) {
      this.breadcrumbsText = [
        {
          url: urls.SCHOOL.SCHOOL_CALENDARS,
          name: '定期予約カレンダー作成',
        },
        {
          name: '定期予約カレンダー詳細',
        },
      ];
    }

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.calendarScheduleDetail.isCreated) {
      successSnackOpen = true;
      successSnackMessage = '予約カレンダーの作成が完了しました';
    }
    this.props.dispatch(actCallApiCalendarScheduleFlgReset());

    if (!headerId) {
      props.history.push({
        pathname: urls.OTHER.HOME,
      });
      return;
    }
    if (headerId) {
      this.props.dispatch(actCallApiSelectList(contentId, storeId));
      this.props.dispatch(actCallApiCalendarScheduleDetail({ headerId }));
    }

    this.state = {
      header: {
        id: headerId,
        storeId,
        storeName: '',
        contentId,
        contentName: '',
        targetedOn: null,
        publishedOn: '',
        regular,
      },
      bookings: [],
      activeIndex: 0,
      isMain: isRelationMain,
      headerDialogOpen: false,
      scheduleDetailId: null,
      scheduleModalOpen: false,
      successSnackOpen,
      successSnackMessage,
    };
  }

  componentDidMount() {
    document.title = '予約カレンダー詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarScheduleDetail.datas !== this.props.calendarScheduleDetail.datas) {
      if (this.props.calendarScheduleDetail.datas) {
        this.setData(this.props.calendarScheduleDetail.datas);
      }
    }
    if (prevProps.calendarScheduleDetail.isUpdated
      !== this.props.calendarScheduleDetail.isUpdated) {
      if (this.props.calendarScheduleDetail.isUpdated) {
        this.setSuccessMessage('公開日の変更が完了しました');
      }
    }
    if (prevProps.calendarScheduleMenu.isSaved !== this.props.calendarScheduleMenu.isSaved) {
      if (this.props.calendarScheduleMenu.isSaved) {
        this.setSuccessMessage('予約枠の保存が完了しました');
      }
    }
    if (prevProps.calendarScheduleDetail.isDeleted
      !== this.props.calendarScheduleDetail.isDeleted) {
      if (this.props.calendarScheduleDetail.isDeleted) {
        this.setSuccessMessage('予約枠の削除が完了しました');
      }
    }
    if (prevProps.calendarScheduleDetail.isBookingDeleted
      !== this.props.calendarScheduleDetail.isBookingDeleted) {
      if (this.props.calendarScheduleDetail.isBookingDeleted) {
        this.setSuccessMessage('予約枠の削除が完了しました');
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  setSuccessMessage = (message) => {
    const { header } = this.state;
    this.setState({
      successSnackOpen: true,
      successSnackMessage: message,
      headerDialogOpen: false,
      scheduleModalOpen: false,
    });
    this.props.dispatch(actCallApiCalendarScheduleDetail({ headerId: header.id }));
  }

  setData = (datas) => {
    this.setState({
      header: datas.header,
      bookings: datas.bookings,
    });
  }

  onBookingDetailLinkage = (_, id, targetType) => {
    const {
      isMain, header,
    } = this.state;

    const breadcrumbsObj = [
      {
        url: header.regular ? urls.SCHOOL.SCHOOL_CALENDARS : urls.EVENT.EVENT_CALENDARS,
        name: header.regular ? '定期予約カレンダー作成' : '予約カレンダー作成',
      },
      {
        url:
          header.regular ? urls.SCHOOL.SCHOOL_CALENDARS_DETAIL
            : urls.EVENT.EVENT_CALENDARS_DETAIL,
        name: header.regular ? '定期予約カレンダー詳細' : '予約カレンダー詳細',
      },
      { name: header.regular ? '定期予約検索詳細' : '予約検索詳細' },
    ];
    this.props.dispatch(actSetHeader({
      isMain, header,
    }));
    const bookingInfo = {
      bookingId: id,
      contentId: header.contentId,
      isRegular: header.regular,
      targetType,
    };
    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_DETAIL,
      state: {
        bookingInfo,
        breadcrumbsObj,
      },
    });
  };

  handleScheduleHeaderModalOpen = () => {
    this.setState({ headerDialogOpen: true, successSnackOpen: false });
  }

  handleScheduleHeaderModalClose = () => {
    this.setState({ headerDialogOpen: false });
  }

  handleMenuDialogOpen = (_, id) => {
    this.setState({
      scheduleModalOpen: true,
      scheduleDetailId: id,
      successSnackOpen: false,
    });
  }

  handleMenuDialogClose = () => {
    this.setState({ scheduleModalOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  handleActiveIndexUpdate = (_, newValue) => {
    this.setState({ activeIndex: newValue });
  };

  onDelete = (deleteList) => {
    this.props.dispatch(actCallApiCalendarScheduleFlgReset());
    this.props.dispatch(actCallApiBookingDelete({ deleteList }));
  };

  render() {
    const { classes } = this.props;
    const {
      isMain, header, scheduleDetailId, scheduleModalOpen, activeIndex,
      successSnackOpen, successSnackMessage, headerDialogOpen, bookings,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <BookingHeader
            header={header}
            isMain={isMain}
            handleOpen={this.handleScheduleHeaderModalOpen}
            contentId={header.contentId}
          />

          <AppBar position="static" color="default">
            <Tabs
              value={activeIndex}
              onChange={this.handleActiveIndexUpdate}
              aria-label="tag tabs"
              indicatorColor="secondary"
              textColor="primary"
            >
              <Tab label="カレンダー" />
              <Tab label="一覧" />
            </Tabs>
          </AppBar>
          <TabPanel value={activeIndex} index={0}>
            <Box textAlign="right" m={1}>
              <Button variant="contained" color="primary" size="large" onClick={(event) => this.handleMenuDialogOpen(event, null)}>予約枠の追加</Button>
            </Box>
            <CalendarScheduleCalendarTable
              header={header}
              bookings={bookings}
              handleMenuDialogOpen={this.handleMenuDialogOpen}
              onBookingDetailLinkage={this.onBookingDetailLinkage}
            />
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            <DataTable
              rows={bookings}
              onDelete={this.onDelete}
            />
          </TabPanel>

          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />

          <CalendarSchedulePublishDialog
            open={headerDialogOpen}
            onClose={this.handleScheduleHeaderModalClose}
            headerId={header.id}
            contentId={header.contentId}
          />

          <CalendarScheduleMenuDialog
            open={scheduleModalOpen}
            onClose={this.handleMenuDialogClose}
            id={scheduleDetailId}
            contentId={header.contentId}
            storeId={header.storeId}
            targetDate={new Date(header.targetedOn)}
            headerId={header.id}
            regular={header.regular}
            bookings={bookings}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarScheduleDetail: state.calendarScheduleDetail,
  calendarScheduleMenu: state.calendarScheduleMenu,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(CalendarScheduleDetail)));
