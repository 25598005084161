import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * パスワードを更新する
 * @param {*} password
 */
export default function setUpdate(id, password) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .put(REST_API.OTHER.PASSWORD.PASSWORD, { id }, password)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
