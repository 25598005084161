export function getContractInfoTags() {
  return (
    [
      {
        id: 1,
        name: '店舗',
        tag: '%contractStoreName%',
      },
      {
        id: 2,
        name: '姓',
        tag: '%contractLastName%',
      },
      {
        id: 3,
        name: '名',
        tag: '%contractFirstName%',
      },
      {
        id: 4,
        name: '姓フリガナ',
        tag: '%contractLastNameKana%',
      },
      {
        id: 5,
        name: '名フリガナ',
        tag: '%contractFirstNameKana%',
      },
      {
        id: 6,
        name: '電話番号',
        tag: '%contractPhone%',
      },
      {
        id: 7,
        name: '電話番号２',
        tag: '%contractPhoneTwo%',
      },
      {
        id: 8,
        name: 'メールアドレス',
        tag: '%contractEmail%',
      },
      {
        id: 9,
        name: '生年月日',
        tag: '%contractBirthday%',
      },
      {
        id: 10,
        name: '性別',
        tag: '%contractGender%',
      },
      {
        id: 11,
        name: '郵便番号',
        tag: '%contractZipCode%',
      },
      {
        id: 12,
        name: '都道府県',
        tag: '%contractPrefecture%',
      },
      {
        id: 13,
        name: '市区町村',
        tag: '%contractCity%',
      },
      {
        id: 14,
        name: '町名番地',
        tag: '%contractStreet%',
      },
      {
        id: 15,
        name: '緊急連絡先姓',
        tag: '%contractEmergencyLastName%',
      },
      {
        id: 16,
        name: '緊急連絡先名',
        tag: '%contractEmergencyFirstName%',
      },
      {
        id: 17,
        name: '緊急連絡先電話番号',
        tag: '%contractEmergencyPhone%',
      },
      {
        id: 18,
        name: '申込日',
        tag: '%requestedOn%',
      },
    ]
  );
}

export function getMemberInfoTags() {
  return (
    [
      {
        id: 1,
        name: '店舗',
        tag: '%memberStoreName%',
      },
      {
        id: 2,
        name: '会員番号',
        tag: '%memberNo%',
      },
      {
        id: 3,
        name: '姓',
        tag: '%memberLastName%',
      },
      {
        id: 4,
        name: '名',
        tag: '%memberFirstName%',
      },
      {
        id: 5,
        name: '姓フリガナ',
        tag: '%memberLastNameKana%',
      },
      {
        id: 6,
        name: '名フリガナ',
        tag: '%memberFirstNameKana%',
      },
      {
        id: 7,
        name: '電話番号',
        tag: '%memberPhone%',
      },
      {
        id: 8,
        name: '電話番号２',
        tag: '%memberPhoneTwo%',
      },
      {
        id: 9,
        name: 'メールアドレス',
        tag: '%memberEmail%',
      },
      {
        id: 10,
        name: '生年月日',
        tag: '%memberBirthday%',
      },
      {
        id: 11,
        name: '性別',
        tag: '%memberGender%',
      },
      {
        id: 12,
        name: '郵便番号',
        tag: '%memberZipCode%',
      },
      {
        id: 13,
        name: '都道府県',
        tag: '%memberPrefecture%',
      },
      {
        id: 14,
        name: '市区町村',
        tag: '%memberCity%',
      },
      {
        id: 15,
        name: '町名番地',
        tag: '%memberStreet%',
      },
      {
        id: 16,
        name: '利用開始日',
        tag: '%memberStartedOn%',
      },
      {
        id: 17,
        name: '利用終了日',
        tag: '%memberEndedOn%',
      },
    ]
  );
}

export function getBookingInfoTags() {
  return (
    [
      {
        id: 1,
        name: '予約番号',
        tag: '%bookingCode%',
      },
      {
        id: 2,
        name: '予約状況',
        tag: '%bookingStatus%',
      },
      {
        id: 3,
        name: '店舗',
        tag: '%bookingStoreName%',
      },
      {
        id: 4,
        name: 'コンテンツ',
        tag: '%bookingContentName%',
      },
      {
        id: 5,
        name: '日付',
        tag: '%bookingDate%',
      },
      {
        id: 6,
        name: '開始時間',
        tag: '%bookingStartTime%',
      },
      {
        id: 7,
        name: '終了時間',
        tag: '%bookingEndTime%',
      },
      {
        id: 8,
        name: '曜日',
        tag: '%bookingWeek%',
      },
      {
        id: 9,
        name: 'メニュー名',
        tag: '%bookingMenuName%',
      },
      {
        id: 10,
        name: '内容',
        tag: '%bookingContent%',
      },
      {
        id: 11,
        name: '特記事項',
        tag: '%bookingAnnotation%',
      },
      {
        id: 12,
        name: '教材費',
        tag: '%bookingMaterialFee%',
      },
      {
        id: 13,
        name: '先生名',
        tag: '%bookingTeacherName%',
      },
      {
        id: 14,
        name: 'Room名',
        tag: '%bookingRoomName%',
      },
    ]
  );
}

export function getOtherInfoTags() {
  return (
    [
      {
        id: 1,
        name: '先生コード',
        tag: '%teacherCode%',
      },
      {
        id: 2,
        name: '先生姓',
        tag: '%teacherLastName%',
      },
      {
        id: 3,
        name: '先生名',
        tag: '%teacherFirstName%',
      },
      {
        id: 4,
        name: '先生メール',
        tag: '%teacherEmail%',
      },
      {
        id: 5,
        name: 'パスワードリセット用URL',
        tag: '%resetUrl%',
      },
      {
        id: 6,
        name: 'システムエラーメッセージ',
        tag: '%errorDetail%',
      },
    ]
  );
}

export function getBookingEntryTags() {
  return (
    [
      { id: 1, name: '会員姓', tag: '%memberLastName%' },
      { id: 2, name: '会員名', tag: '%memberFirstName%' },
      { id: 3, name: '契約者姓', tag: '%contractLastName%' },
      { id: 4, name: '契約者名', tag: '%contractFirstName%' },
      { id: 5, name: '出席日時', tag: '%entryOn%' },
      { id: 6, name: '店舗名', tag: '%bookingStoreName%' },
      { id: 7, name: 'Room名', tag: '%bookingRoomName%' },
      { id: 8, name: '対象日', tag: '%bookingDate%' },
      { id: 9, name: '開始時間', tag: '%bookingStartTime%' },
      { id: 10, name: '終了時間', tag: '%bookingEndTime%' },
      { id: 11, name: '曜日', tag: '%bookingWeek%' },
      { id: 12, name: 'メニュー名', tag: '%bookingMenuName%' },
      { id: 13, name: '内容', tag: '%bookingContent%' },
      { id: 14, name: '特記事項', tag: '%bookingAnnotation%' },
      { id: 15, name: '先生名', tag: '%bookingTeacherName%' },
    ]
  );
}
export function getBookingZoomTags() {
  return (
    [
      { id: 1, name: '管理用ZoomUrl', tag: '%zoomMainUrl%' },
      { id: 2, name: '会員用ZoomUrl', tag: '%zoomJoinUrl%' },
    ]
  );
}
