import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  TextField, Box, Button,
} from '@material-ui/core';
import format from 'date-fns/format';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import urls from '../../../constants/urls';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import AttendanceEntryDataTable from './AttendanceEntryDataTable';
import {
  actCallApiAttendanceEntrySelect,
  actCallApiGetAttendanceEntry,
} from '../../../redux/info/attendanceEntry/action';

class AttendanceEntryList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallApiAttendanceEntrySelect());

    this.breadcrumbsText = [
      { name: '出席登録' },
    ];

    const searchCondition = {
      page: 0,
      records: 10,
      searchStore: props.common.user ? props.common.user.store : null,
      searchStartTime: '',
      searchEndTime: '',
      searchRoom: null,
      searchMenu: null,
      searchTeacher: null,
    };

    this.state = {
      isMain: props.common.user ? props.common.user.main : false,
      selects: {
        stores: [],
        rooms: [],
        menus: [],
        teachers: [],
      },
      searchCondition,
      datas: {},
    };

    this.search(searchCondition);
  }

  componentDidMount() {
    document.title = '出席登録';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.onStoreChangeSearch(this.props.common.user);
      }
    }

    if (prevProps.attendanceEntry.selects !== this.props.attendanceEntry.selects) {
      if (this.props.attendanceEntry.selects) {
        this.setSelects(this.props.attendanceEntry.selects);
      }
    }

    if (prevProps.attendanceEntry.datas !== this.props.attendanceEntry.datas) {
      if (this.props.attendanceEntry.datas) {
        this.setDatas(this.props.attendanceEntry.datas);
      }
    }
  }

  onStoreChangeSearch = (user) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStore: user.store,
    };
    this.setState({ isMain: user.main, searchCondition: tempCondition });

    this.search(tempCondition);
  }

  setSelects = (selects) => {
    this.setState({ selects });
  }

  setDatas = (datas) => {
    this.setState({ datas });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiGetAttendanceEntry({
        ...searchCondition,
        searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
        searchRoomId: searchCondition.searchRoom && searchCondition.searchRoom.id,
        searchMenuId: searchCondition.searchMenu && searchCondition.searchMenu.id,
        searchTeacherId: searchCondition.searchTeacher && searchCondition.searchTeacher.id,
      }),
    );
  }

  onSearchClear = () => {
    this.setState({
      searchCondition: {
        page: 0,
        records: 10,
        searchStore: this.props.common.user ? this.props.common.user.store : null,
        searchStartTime: '',
        searchEndTime: '',
        searchRoom: null,
        searchMenu: null,
        searchTeacher: null,
      },
    });
  }

  onItemChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  handleClickNowTime = () => {
    const date = new Date();
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      searchStartTime: format(date, 'HH:mm'),
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onDetailClick = (_, row) => {
    this.props.history.push({
      pathname: urls.INFO.ATTENDANCE_ENTRY_DETAIL,
      state: { data: row },
    });
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempSearchCondition });

    this.search(tempSearchCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const { searchCondition } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempSearchCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };

    this.search(tempSearchCondition);
  };

  render() {
    const {
      isMain,
      selects,
      searchCondition,
      datas,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch} onSecondFunc={this.onSearchClear} onSecondFuncText="クリアする">
            <Box display={isMain ? '' : 'none'}>
              <FormTitle title="店舗" />
              <SearchSelectBox
                options={selects.stores}
                value={searchCondition.searchStore}
                onChange={this.onItemChange}
                name="searchStore"
              />
            </Box>
            <Box mt={3}>
              <FormTitle title="実施日" />
              <Box>{format(new Date(), 'yyyy/MM/dd')}</Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="時間" />
              <Box display="flex" mt={1} alignItems="center">
                <TextField
                  type="time"
                  variant="outlined"
                  name="searchStartTime"
                  value={searchCondition.searchStartTime}
                  onChange={this.onItemChange}
                />
                <Box mr={2} ml={2}>～</Box>
                <TextField
                  type="time"
                  variant="outlined"
                  name="searchEndTime"
                  value={searchCondition.searchEndTime}
                  onChange={this.onItemChange}
                />
                <Box ml={2}>
                  <Button variant="outlined" size="large" onClick={this.handleClickNowTime}>現在時刻</Button>
                </Box>
              </Box>
            </Box>
            <Box width="80%" mt={3}>
              <FormTitle title="Room" />
              <SearchSelectBox
                options={
                  searchCondition.searchStore ? selects.rooms.filter(
                    (key) => key.mstStoreId === searchCondition.searchStore.id,
                  ) : selects.rooms
                }
                value={searchCondition.searchRoom}
                onChange={this.onItemChange}
                name="searchRoom"
              />
            </Box>
            <Box display="flex" mt={3}>
              <Box width="30%">
                <FormTitle title="メニュー" />
                <SearchSelectBox
                  options={
                    searchCondition.searchStore ? selects.menus.filter(
                      (key) => key.mstOrganizationCompanyId
                        === searchCondition.searchStore.mstOrganizationCompanyId,
                    ) : selects.menus
                  }
                  value={searchCondition.searchMenu}
                  onChange={this.onItemChange}
                  name="searchMenu"
                />
              </Box>
              <Box ml={4} width="30%">
                <FormTitle title="先生" />
                <SearchSelectBox
                  options={
                    searchCondition.searchStore ? selects.teachers.filter(
                      (key) => key.mstStoreId === searchCondition.searchStore.id,
                    ) : selects.teachers
                  }
                  value={searchCondition.searchTeacher}
                  onChange={this.onItemChange}
                  name="searchMenu"
                />
              </Box>
            </Box>
          </SearchBox>

          <AttendanceEntryDataTable
            rows={datas.content ? datas.content : []}
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            onDetailClick={this.onDetailClick}
            onStatusClick={this.onStatusClick}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            headCells={this.headCells}
          />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  attendanceEntry: state.attendanceEntry,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(AttendanceEntryList));
