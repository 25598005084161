import { ACTION_TYPE } from './action';

// region Reducer
const bannerImages = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.BANNER_IMAGE_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.BANNER_RELEASE_SUCCESS:
      return {
        ...state,
        isReleaseSuccess: true,
        datas: action.datas,
      };
    case ACTION_TYPE.BANNER_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploadSuccess: true,
        datas: action.datas,
      };
    case ACTION_TYPE.BANNER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.BANNER_FLG_RESET:
      return {
        ...state,
        isReleaseSuccess: false,
        isDeleteSuccess: false,
        isUploadSuccess: false,
      };
    default:
      return state;
  }
};

export default bannerImages;
