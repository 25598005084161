import { ACTION_TYPE } from './action';

const calendarDetailRoom = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_CALENDAR_HEADER_ROOM_SUCCESS:
      return {
        ...state,
        headerDatas: action.headerDatas.header,
        calendarList: action.headerDatas.calendarList,
      };
    case ACTION_TYPE.RESET_ROOM_CREATE_FLG:
      return {
        ...state,
        isRoomCreated: false,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE_SUCCESS:
      return {
        ...state,
        isRoomCreated: true,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_ROOM_DETAIL_SUCCESS:
      return {
        ...state,
        datas: action.bookingDatas,
      };
    default:
      return state;
  }
};

export default calendarDetailRoom;
