import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box, TextField, FormControl,
  Select, Radio, RadioGroup, FormControlLabel,
  Button, withStyles, Paper, FormHelperText,
} from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import FormTitle from '../../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../../../components/atoms/searchSelectBox/SearchSelectBox';
import { errorMessageForm, createContractForm } from '../entry-proc';
import validation from '../validation';
import { getZipCodeAddress, actCallSetMemberEntryForm } from '../../../../../redux/member/entry/action';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '75px',
    },
  },
  smallTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
  smallTextFieldDate: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
});
// #endregion

class ContractForm extends React.Component {
  constructor(props) {
    super(props);
    let isMain = false;
    let mstStoreId = { mstOrganizationCompanyId: null };
    if (props.common.user) {
      isMain = props.common.user.main;
      if (!isMain) {
        mstStoreId = props.common.user.store;
      }
    }
    this.state = {
      errorMessage: errorMessageForm(),
      contract: createContractForm(),
      mstStoreId,
      isMain,
      mstCodeContractCode: 2,
      isLoading: false,
      errorMessageShowFlg: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entry.zipcode !== this.props.entry.zipcode) {
      if (this.props.entry.zipcode) {
        this.setAddress(this.props.entry.zipcode);
      }
    }
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setStore(this.props.common.user);
      }
    }
  }

  setStore = (user) => {
    let mstStoreId = null;
    if (!user.main) {
      mstStoreId = user.store;
    }
    this.setState({ isMain: user.main, mstStoreId });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  setAddress = (value) => {
    const { contract, errorMessage } = this.state;
    let mstCodePrefectureCode = null;
    let city = '';
    let street = '';
    let zipCode = '';

    if (value.results && value.results.length > 0) {
      mstCodePrefectureCode = {
        name: value.results[0].address1,
        id: parseInt(value.results[0].prefcode, 10),
      };
      city = value.results[0].address2;
      street = value.results[0].address3;
    }
    if (!value.results) {
      zipCode = '存在しない郵便番号です';
    }
    this.setState({
      contract: {
        ...contract,
        mstCodePrefectureCode,
        city,
        street,
      },
      errorMessage: {
        ...errorMessage,
        zipCode,
        mstCodePrefectureCode: validation.formValidate('mstCodePrefectureCode', mstCodePrefectureCode),
        city: validation.formValidate('city', city),
        street: validation.formValidate('street', street),
      },
      isLoading: false,
    });
  }

  onClickNextUserForm = () => {
    const {
      onClickToNext,
      isCorporation,
      isFromConfirmForm,
      entry,
    } = this.props;

    const {
      errorMessage,
      contract,
      mstStoreId,
      mstCodeContractCode,
    } = this.state;
    const { memberEntry } = entry;
    const companyId = mstStoreId && mstStoreId.mstOrganizationCompanyId;

    let tempMessage = errorMessageForm();

    const birthdayVal = `${contract.birthdayYear}/${contract.birthdayMonth}/${contract.birthdayDay}`;
    const birthdayKey = (companyId === 4 || mstCodeContractCode === 3) ? 'birthdayNoRequired' : 'birthday';
    const birthday = validation.formValidate(birthdayKey, birthdayVal);

    const phoneVal = `${contract.phoneBefore}-${contract.phoneMiddle}-${contract.phoneAfter}`;
    const phone = validation.formValidate('phone', phoneVal);

    const phoneTwoVal = `${contract.phoneTwoBefore}-${contract.phoneTwoMiddle}-${contract.phoneTwoAfter}`;
    const phoneTwo = validation.formValidate('phoneTwo', phoneTwoVal);

    const zipCodeVal = `${contract.zipCodeBefore}-${contract.zipCodeAfter}`;
    const zipCode = validation.formValidate('zipCode', zipCodeVal);

    const emergencyPhoneVal = `${contract.emergencyPhoneBefore}-${contract.emergencyPhoneMiddle}-${contract.emergencyPhoneAfter}`;
    let emergencyPhone = validation.formValidate('emergencyPhone', emergencyPhoneVal);

    const mstStoreIdMsg = validation.formValidate('mstStoreId', mstStoreId);

    let email = validation.formValidate('email', contract.email);
    let emergencyLastName = validation.formValidate('emergencyLastName', contract.emergencyLastName);
    let emergencyFirstName = validation.formValidate('emergencyFirstName', contract.emergencyFirstName);

    if (companyId === 4) {
      email = validation.formValidate('emailNoRequired', contract.email);
      emergencyPhone = '';
      emergencyLastName = '';
      emergencyFirstName = '';
    }

    tempMessage = {
      ...tempMessage,
      birthday,
      phone,
      phoneTwo,
      zipCode,
      emergencyPhone,
      email,
      mstStoreId: mstStoreIdMsg,
      emergencyLastName,
      emergencyFirstName,
    };

    Object.keys(errorMessage).map(
      (key) => {
        if ((!isCorporation && key === 'corporationName')
        || (key === 'birthday') || (key === 'phone')
        || (key === 'phoneTwo') || (key === 'zipCode')
        || (key === 'mstStoreId') || (key === 'email')
        || (key === 'emergencyPhone')
        || (key === 'emergencyLastName') || (key === 'emergencyFirstName')) { return null; }

        tempMessage = {
          ...tempMessage,
          [key]: validation.formValidate(key, contract[key]),
        };
        return null;
      },
    );

    const isError = Object.values(tempMessage).filter((value) => value !== '').length > 0;

    const tmpMemberEntry = {
      ...memberEntry,
      mstStoreId,
      contract,
      mstCodeContractCode: !isCorporation ? 1 : mstCodeContractCode,
    };

    if (!isError) {
      this.props.dispatch(actCallSetMemberEntryForm(tmpMemberEntry));
    }

    let activeStep = null;
    if (!isFromConfirmForm && !isError) {
      activeStep = 1;
    }
    if (isFromConfirmForm && !isError) {
      activeStep = 3;
    }
    if (activeStep) {
      onClickToNext(activeStep);
    }
    this.setState({ errorMessageShowFlg: isError, errorMessage: tempMessage });
    this.scrollToTop();
  }

  onChangeTextField = (event) => {
    const { name, value } = event.target;
    const { contract, errorMessage, mstStoreId } = this.state;
    const companyId = mstStoreId.mstOrganizationCompanyId;

    contract[name] = value;

    let validationName = name;
    if (companyId === 4 && name === 'email') {
      validationName = 'emailNoRequired';
    }
    if (companyId === 4 && (name === 'emergencyFirstName' || name === 'emergencyLastName')) {
      validationName = '';
    }

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: validation.formValidate(validationName, value),
      },
    });
  }

  onChangePhone = (event) => {
    const { contract, errorMessage } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'phoneBefore') {
      phoneVal = `${value}-${contract.phoneMiddle}-${contract.phoneAfter}`;
    }
    if (name === 'phoneMiddle') {
      phoneVal = `${contract.phoneBefore}-${value}-${contract.phoneAfter}`;
    }
    if (name === 'phoneAfter') {
      phoneVal = `${contract.phoneBefore}-${contract.phoneMiddle}-${value}`;
    }

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phone: validation.formValidate('phone', phoneVal),
      },
    });
  }

  onChangePhoneTwo = (event) => {
    const { contract, errorMessage } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'phoneTwoBefore') {
      phoneVal = `${value}-${contract.phoneTwoMiddle}-${contract.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoMiddle') {
      phoneVal = `${contract.phoneTwoBefore}-${value}-${contract.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoAfter') {
      phoneVal = `${contract.phoneTwoBefore}-${contract.phoneTwoMiddle}-${value}`;
    }

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phoneTwo: validation.formValidate('phoneTwo', phoneVal),
      },
    });
  }

  onChangeEmergencyPhone = (event) => {
    const { contract, errorMessage, mstStoreId } = this.state;
    const { name, value } = event.target;
    let phoneVal = null;
    if (name === 'emergencyPhoneBefore') {
      phoneVal = `${value}-${contract.emergencyPhoneMiddle}-${contract.emergencyPhoneAfter}`;
    }
    if (name === 'emergencyPhoneMiddle') {
      phoneVal = `${contract.emergencyPhoneBefore}-${value}-${contract.emergencyPhoneAfter}`;
    }
    if (name === 'emergencyPhoneAfter') {
      phoneVal = `${contract.emergencyPhoneBefore}-${contract.emergencyPhoneMiddle}-${value}`;
    }

    const key = mstStoreId.mstOrganizationCompanyId === 4 ? 'phoneTwo' : 'emergencyPhone';

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        emergencyPhone: validation.formValidate(key, phoneVal),
      },
    });
  }

  onChangeBirthday = (event) => {
    const { name, value } = event.target;
    const { contract, errorMessage, mstStoreId } = this.state;
    let birthdayVal = null;
    if (name === 'birthdayYear') {
      birthdayVal = `${value}/${contract.birthdayMonth}/${contract.birthdayDay}`;
    }
    if (name === 'birthdayMonth') {
      birthdayVal = `${contract.birthdayYear}/${value}/${contract.birthdayDay}`;
    }
    if (name === 'birthdayDay') {
      birthdayVal = `${contract.birthdayYear}/${contract.birthdayMonth}/${value}`;
    }

    const key = mstStoreId.mstOrganizationCompanyId === 4 ? 'birthdayNoRequired' : 'birthday';

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        birthday: validation.formValidate(key, birthdayVal),
      },
    });
  }

  onChangeZipCode = (event) => {
    const { name, value } = event.target;
    const { contract, errorMessage } = this.state;

    let zipCodeVal = null;
    if (name === 'zipCodeBefore') {
      zipCodeVal = `${value}-${contract.zipCodeAfter}`;
    }
    if (name === 'zipCodeAfter') {
      zipCodeVal = `${contract.zipCodeBefore}-${value}`;
    }

    let isLoading = false;
    if (zipCodeVal.length === 8) {
      isLoading = true;
      this.props.dispatch(getZipCodeAddress({
        zipCode: zipCodeVal,
        pageFlg: 0,
      }));
    }

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        zipCode: validation.formValidate('zipCode', zipCodeVal),
      },
      isLoading,
    });
  }

  onChangeSearchSelectBox = (event) => {
    const { name, value } = event.target;
    const { contract, errorMessage } = this.state;

    this.setState({
      contract: {
        ...contract,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: validation.formValidate([name], value),
      },
    });
  }

  onChangeSearchSelectBoxMstStoreId = (event) => {
    const { errorMessage } = this.state;
    const { value } = event.target;
    this.setState({
      mstStoreId: value,
      errorMessage: {
        ...errorMessage,
        mstStoreId: validation.formValidate('mstStoreId', value),
      },
    });
  }

  onChangeRadio = (event) => {
    const { contract } = this.state;
    const { name, value } = event.target;

    this.setState({
      contract: {
        ...contract,
        [name]: parseInt(value, 10),
      },
    });
  }

  onChangeContractCodeRadio = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: parseInt(value, 10) });
  }

  render() {
    const {
      classes,
      isCorporation,
      isFromConfirmForm,
      initSelectValues,
    } = this.props;

    const {
      errorMessage,
      errorMessageShowFlg,
      contract,
      mstStoreId,
      mstCodeContractCode,
      isLoading,
      isMain,
    } = this.state;

    const companyId = mstStoreId && mstStoreId.mstOrganizationCompanyId;

    return (
      <div className={classes.root}>
        <Box my={3}>
          <Alert style={{ display: errorMessageShowFlg ? '' : 'none' }} severity="error">入力エラーがあります。確認してください。</Alert>
        </Box>

        <Paper elevation={0} variant="outlined">
          <Box p={3}>
            <Box mb={3} display={isCorporation ? '' : 'none'}>
              <FormTitle title="ご契約タイプ" isRequired />
              <RadioGroup row aria-label="mstCodeContractCode" onChange={this.onChangeContractCodeRadio}>
                {initSelectValues.contractCodeSelectList.map((option) => (
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value={option.id}
                    key={option.id}
                    name="mstCodeContractCode"
                    label={option.name}
                    checked={mstCodeContractCode === option.id}
                  />
                ))}
              </RadioGroup>
            </Box>

            <Box display={isMain ? '' : 'none'}>
              <FormTitle title="ご利用店舗" isRequired />
              {initSelectValues.storesSelectList && (
                <SearchSelectBox
                  name="mstStoreId"
                  onChange={this.onChangeSearchSelectBoxMstStoreId}
                  value={mstStoreId}
                  options={initSelectValues.storesSelectList}
                  error={errorMessage.mstStoreId !== ''}
                  placeholder="選択してください"
                  disableClearable
                />
              )}
              <FormErrorText>{errorMessage.mstStoreId}</FormErrorText>
            </Box>

            <Box mb={3}>
              <Box mt={3} fontWeight="fontWeightBold" fontSize="h6.fontSize">ご契約者様情報</Box>
              <hr />
            </Box>

            <Box mb={3} display={isCorporation ? '' : 'none'}>
              <FormTitle title="法人名" isRequired />
              <TextField
                name="corporationName"
                value={contract.corporationName}
                variant="outlined"
                autoComplete="off"
                onChange={this.onChangeTextField}
                placeholder="例）株式会社ヴァーテックス"
                error={errorMessage.corporationName !== ''}
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
              <FormErrorText>{errorMessage.corporationName}</FormErrorText>
            </Box>

            <Box mb={3}>
              <FormTitle title="氏名" isRequired />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box key="lastNameKey">
                    <TextField
                      name="lastName"
                      id="lastNameContract"
                      value={contract.lastName}
                      variant="outlined"
                      onChange={this.onChangeTextField}
                      placeholder="姓"
                      autoComplete="off"
                      error={errorMessage.lastName !== ''}
                      inputProps={{ maxLength: 80 }}
                    />
                    <FormErrorText>{errorMessage.lastName}</FormErrorText>
                  </Box>
                  <Box ml={2} key="firstNameKey">
                    <TextField
                      name="firstName"
                      id="firstNameContract"
                      value={contract.firstName}
                      variant="outlined"
                      onChange={this.onChangeTextField}
                      placeholder="名"
                      autoComplete="off"
                      error={errorMessage.firstName !== ''}
                      inputProps={{ maxLength: 40 }}
                    />
                    <FormErrorText>{errorMessage.firstName}</FormErrorText>
                  </Box>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="氏名カナ" isRequired />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="lastNameKana"
                      id="lastNameKanaContract"
                      variant="outlined"
                      value={contract.lastNameKana}
                      onChange={this.onChangeTextField}
                      placeholder="セイカナ"
                      autoComplete="off"
                      error={errorMessage.lastNameKana !== ''}
                      inputProps={{ maxLength: 100 }}
                    />
                    <FormErrorText>{errorMessage.lastNameKana}</FormErrorText>
                  </Box>
                  <Box ml={2} key="firstNameKanaKey">
                    <TextField
                      name="firstNameKana"
                      id="firstNameKanaContract"
                      variant="outlined"
                      value={contract.firstNameKana}
                      onChange={this.onChangeTextField}
                      placeholder="メイカナ"
                      autoComplete="off"
                      error={errorMessage.firstNameKana !== ''}
                      inputProps={{ maxLength: 100 }}
                    />
                    <FormErrorText>{errorMessage.firstNameKana}</FormErrorText>
                  </Box>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="郵便番号" isRequired />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="zipCodeBefore"
                      id="zipCodeBeforeContract"
                      value={contract.zipCodeBefore}
                      variant="outlined"
                      onChange={this.onChangeZipCode}
                      placeholder="950"
                      autoComplete="off"
                      className={classes.smallTextField}
                      error={errorMessage.zipCode !== ''}
                      inputProps={{ maxLength: 3 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box>
                    <TextField
                      name="zipCodeAfter"
                      id="zipCodeAfterContract"
                      value={contract.zipCodeAfter}
                      variant="outlined"
                      onChange={this.onChangeZipCode}
                      placeholder="0032"
                      autoComplete="off"
                      className={classes.smallTextField}
                      error={errorMessage.zipCode !== ''}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <FormErrorText>{errorMessage.zipCode}</FormErrorText>
                </Box>
                <Box>
                  <FormHelperText style={{ color: 'black' }}>
                    郵便番号をご入力頂くと、住所の一部が自動入力されます
                  </FormHelperText>
                </Box>
                <Box display={isLoading ? '' : 'none'}>
                  <FormHelperText style={{ color: 'black' }}>
                    検索しています..
                  </FormHelperText>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="都道府県" isRequired />
              <SearchSelectBox
                name="mstCodePrefectureCode"
                onChange={this.onChangeSearchSelectBox}
                value={contract.mstCodePrefectureCode}
                options={initSelectValues.prefexturesSelectList}
                error={errorMessage.mstCodePrefectureCode !== ''}
                placeholder="選択してください"
                disableClearable
              />
              <FormErrorText>{errorMessage.mstCodePrefectureCode}</FormErrorText>
            </Box>

            <Box mb={3}>
              <FormTitle title="市区町村" isRequired />
              <TextField
                name="city"
                id="cityContract"
                variant="outlined"
                autoComplete="off"
                onChange={this.onChangeTextField}
                placeholder="例）新潟市江南区両川"
                value={contract.city}
                error={errorMessage.city !== ''}
                inputProps={{ maxLength: 255 }}
                fullWidth
              />
              <FormErrorText>{errorMessage.city}</FormErrorText>
            </Box>

            <Box mb={3}>
              <FormTitle title="町名・番地・その他" isRequired />
              <TextField
                name="street"
                id="streetContract"
                variant="outlined"
                onChange={this.onChangeTextField}
                placeholder="例）2丁目3927-15"
                autoComplete="off"
                value={contract.street}
                error={errorMessage.street !== ''}
                inputProps={{ maxLength: 255 }}
                fullWidth
              />
              <FormErrorText>{errorMessage.street}</FormErrorText>
            </Box>

            <Box mb={3}>
              <FormTitle title="メールアドレス" isRequired={companyId !== 4} />
              <TextField
                name="email"
                id="emailContract"
                variant="outlined"
                onChange={this.onChangeTextField}
                placeholder="例）example@email.com"
                value={contract.email}
                autoComplete="off"
                error={errorMessage.email !== ''}
                inputProps={{ maxLength: 80 }}
                fullWidth
              />
              <FormErrorText>{errorMessage.email}</FormErrorText>
            </Box>

            <Box mb={3}>
              <FormTitle title="電話番号" isRequired />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="phoneBefore"
                      id="phoneBeforeContract"
                      variant="outlined"
                      onChange={this.onChangePhone}
                      placeholder="025"
                      autoComplete="off"
                      value={contract.phoneBefore}
                      error={errorMessage.phone !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box key="phoneMiddleKey">
                    <TextField
                      name="phoneMiddle"
                      id="phoneMiddleContract"
                      variant="outlined"
                      onChange={this.onChangePhone}
                      placeholder="250"
                      autoComplete="off"
                      value={contract.phoneMiddle}
                      error={errorMessage.phone !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box key="phoneAfterKey">
                    <TextField
                      name="phoneAfter"
                      id="phoneAfterContract"
                      variant="outlined"
                      onChange={this.onChangePhone}
                      placeholder="7112"
                      autoComplete="off"
                      value={contract.phoneAfter}
                      error={errorMessage.phone !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <FormErrorText>{ errorMessage.phone }</FormErrorText>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="電話番号2" />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box key="phoneTwoBeforeKey">
                    <TextField
                      name="phoneTwoBefore"
                      id="phoneTwoBeforeContract"
                      variant="outlined"
                      onChange={this.onChangePhoneTwo}
                      placeholder="090"
                      autoComplete="off"
                      value={contract.phoneTwoBefore}
                      error={errorMessage.phoneTwo !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box key="phoneTwoMiddleKey">
                    <TextField
                      name="phoneTwoMiddle"
                      id="phoneTwoMiddleContract"
                      variant="outlined"
                      onChange={this.onChangePhoneTwo}
                      placeholder="1234"
                      autoComplete="off"
                      value={contract.phoneTwoMiddle}
                      error={errorMessage.phoneTwo !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box key="phoneTwoAfterKey">
                    <TextField
                      name="phoneTwoAfter"
                      id="phoneTwoAfterContract"
                      variant="outlined"
                      onChange={this.onChangePhoneTwo}
                      placeholder="5678"
                      autoComplete="off"
                      value={contract.phoneTwoAfter}
                      error={errorMessage.phoneTwo !== ''}
                      className={classes.phoneTextField}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <FormErrorText>{ errorMessage.phoneTwo }</FormErrorText>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="生年月日" isRequired={(companyId !== 4 && mstCodeContractCode !== 3)} />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="birthdayYear"
                      id="birthdayYearContract"
                      onChange={this.onChangeBirthday}
                      variant="outlined"
                      placeholder="年"
                      autoComplete="off"
                      value={contract.birthdayYear}
                      error={errorMessage.birthday !== ''}
                      className={classes.smallTextFieldDate}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} pr={2} pl={1}><span>年</span></Box>
                  <Box>
                    <FormControl variant="outlined" error={errorMessage.birthday !== ''} className={classes.smallTextFieldDate}>
                      <Select native name="birthdayMonth" id="birthdayMonthContract" onChange={this.onChangeBirthday} value={contract.birthdayMonth}>
                        <option aria-label="None" value="" />
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={classes.textCenter} pr={2} pl={1}><span>月</span></Box>
                  <Box>
                    <TextField
                      name="birthdayDay"
                      id="birthdayDayContract"
                      value={contract.birthdayDay}
                      onChange={this.onChangeBirthday}
                      variant="outlined"
                      placeholder="日"
                      autoComplete="off"
                      className={classes.smallTextFieldDate}
                      error={errorMessage.birthday !== ''}
                      inputProps={{ maxLength: 2 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} pr={2} pl={1}><span>日</span></Box>
                </Box>
                <Box display="flex">
                  <FormErrorText>{errorMessage.birthday}</FormErrorText>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="性別" isRequired={companyId !== 4} />
              <RadioGroup row aria-label="gender" id="mstCodeGenderCode" value={contract.gender} onChange={this.onChangeRadio}>
                {initSelectValues.genderSelectList.map((option) => (
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value={option.id}
                    key={option.id}
                    name="mstCodeGenderCode"
                    label={option.name}
                    checked={contract.mstCodeGenderCode === option.id}
                  />
                ))}
              </RadioGroup>
            </Box>

            <Box mb={3}>
              <FormTitle title="緊急連絡先氏名" isRequired={companyId !== 4} />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="emergencyLastName"
                      id="emergencyLastNameContract"
                      value={contract.emergencyLastName}
                      onChange={this.onChangeTextField}
                      variant="outlined"
                      placeholder="姓"
                      autoComplete="off"
                      error={errorMessage.emergencyLastName !== ''}
                      inputProps={{ maxLength: 80 }}
                    />
                    <FormErrorText>{errorMessage.emergencyLastName}</FormErrorText>
                  </Box>
                  <Box ml={2}>
                    <TextField
                      name="emergencyFirstName"
                      id="emergencyFirstNameContract"
                      value={contract.emergencyFirstName}
                      onChange={this.onChangeTextField}
                      variant="outlined"
                      placeholder="名"
                      autoComplete="off"
                      error={errorMessage.emergencyFirstName !== ''}
                      inputProps={{ maxLength: 40 }}
                    />
                    <FormErrorText>{errorMessage.emergencyFirstName}</FormErrorText>
                  </Box>
                </Box>
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormTitle title="緊急連絡先電話番号" isRequired={companyId !== 4} />
              <FormControl component="fieldset">
                <Box display="flex">
                  <Box>
                    <TextField
                      name="emergencyPhoneBefore"
                      id="emergencyPhoneBeforeContract"
                      value={contract.emergencyPhoneBefore}
                      onChange={this.onChangeEmergencyPhone}
                      variant="outlined"
                      placeholder="025"
                      autoComplete="off"
                      className={classes.phoneTextField}
                      error={errorMessage.emergencyPhone !== ''}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box>
                    <TextField
                      name="emergencyPhoneMiddle"
                      id="emergencyPhoneMiddleContract"
                      value={contract.emergencyPhoneMiddle}
                      onChange={this.onChangeEmergencyPhone}
                      variant="outlined"
                      placeholder="2502"
                      autoComplete="off"
                      className={classes.phoneTextField}
                      error={errorMessage.emergencyPhone !== ''}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                  <Box className={classes.textCenter} p={2}><span>-</span></Box>
                  <Box>
                    <TextField
                      name="emergencyPhoneAfter"
                      id="emergencyPhoneAfterContract"
                      value={contract.emergencyPhoneAfter}
                      onChange={this.onChangeEmergencyPhone}
                      variant="outlined"
                      placeholder="7112"
                      autoComplete="off"
                      className={classes.phoneTextField}
                      error={errorMessage.emergencyPhone !== ''}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <FormErrorText>{ errorMessage.emergencyPhone }</FormErrorText>
                </Box>
              </FormControl>
            </Box>
          </Box>
        </Paper>

        <Box align="center" my={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onClickNextUserForm}
            endIcon={<ArrowForwardIosIcon />}
          >
            { isFromConfirmForm === true ? '入力内容の確認へ進む' : 'ご利用者様情報の入力へ進む' }
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  entry: state.entry,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ContractForm)));
