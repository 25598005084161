import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import { format, isAfter } from 'date-fns';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actGetProductList, actContractInsert } from '../../../redux/member/memberDetail/action';

class AddContractDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        id: null,
        product: null,
        startDate: format(new Date(), 'yyyy/MM/dd'),
        endDate: format(new Date(), 'yyyy/MM/dd'),
      },
      btnLoading: false,
      productList: [],
      errorMessage: {
        product: '',
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.getProduct(this.props.mstStore.mstOrganizationCompanyId);
      }
    }
    if (prevProps.memberDetail.productList !== this.props.memberDetail.productList) {
      this.setProductList(this.props.memberDetail.productList);
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  getProduct = (mstOrganizationCompanyId) => {
    this.setState({
      info: {
        id: null,
        product: null,
        startDate: format(new Date(), 'yyyy/MM/dd'),
        endDate: format(new Date(), 'yyyy/MM/dd'),
      },
      btnLoading: false,
      productList: [],
      errorMessage: {
        product: '',
        startDate: '',
        endDate: '',
      },
    });
    this.props.dispatch(actGetProductList({
      mstOrganizationCompanyId, memberId: this.props.memberId,
    }));
  }

  setProductList(data) {
    this.setState({ productList: data.productList });
  }

  handleSave = () => {
    const { info, errorMessage } = this.state;

    const tempMessage = {
      ...errorMessage,
      product: this.checkProduct(info.product),
    };

    const isError = !!tempMessage.product || !!tempMessage.startDate || !!tempMessage.endDate;

    this.setState({
      btnLoading: !isError,
      errorMessage: tempMessage,
    });

    if (isError) {
      return;
    }

    this.props.dispatch(actContractInsert({
      productId: info.product.id,
      startDate: info.startDate,
      endDate: info.endDate,
      mstOrganizationCompanyId: this.props.mstStore.mstOrganizationCompanyId,
      memberId: this.props.memberId,
    }));
  }

  onProductChange = (event) => {
    const { info } = this.state;
    this.setState({ info: { ...info, product: event.target.value } });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { info, errorMessage } = this.state;
    this.setState({
      info: { ...info, [name]: value },
      errorMessage: {
        ...errorMessage,
        [name]: !value ? '入力してください。' : '',
      },
    });
  }

  onChangeDate = (value, name) => {
    const targetDate = format(new Date(value), 'yyyy/MM/dd');
    const { info, errorMessage } = this.state;
    const tempInfo = {
      ...info,
      [name]: targetDate,
    };

    const { startDate, endDate } = tempInfo;
    let tempErrorMessage = {
      ...errorMessage,
      startDate: '',
      endDate: '',
    };
    if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
      tempErrorMessage = {
        ...errorMessage,
        startDate: '契約開始日は契約終了日より未来の日付は入力できません。',
        endDate: '契約終了日は契約開始日より過去の日付は入力できません。',
      };
    }

    this.setState({ info: { ...info, [name]: targetDate }, errorMessage: tempErrorMessage });
  }

  checkProduct = (product) => {
    if (!product) {
      return '選択してください';
    }
    return '';
  }

  render() {
    const {
      open, handleClose,
    } = this.props;
    const {
      info, btnLoading, errorMessage,
      productList,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>契約追加</DialogTitle>
          <DialogContent dividers>
            <Box mb={3} width="100%">
              <FormTitle title="商品" isRequired />
              <SearchSelectBox
                options={productList}
                value={info.product}
                onChange={this.onProductChange}
                name="product"
                disableClearable
              />
              { errorMessage.product && <FormErrorText>{errorMessage.product}</FormErrorText> }
            </Box>
            <Box mb={3}>
              <FormTitle title="契約開始日" />
              <Datepicker
                value={info.startDate}
                name="startDate"
                handleChange={(value) => this.onChangeDate(value, 'startDate')}
              />
              { errorMessage.startDate && <FormErrorText>{errorMessage.startDate}</FormErrorText> }
            </Box>
            <Box>
              <FormTitle title="契約終了日" />
              <Datepicker
                value={info.endDate}
                name="endDate"
                handleChange={(value) => this.onChangeDate(value, 'endDate')}
              />
              { errorMessage.endDate && <FormErrorText>{errorMessage.endDate}</FormErrorText> }
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.handleSave} label="保存" loading={btnLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  members: state.members,
  memberDetail: state.memberDetail,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(AddContractDialog));
