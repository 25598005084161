import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'yyyy', { locale: this.locale });
  }
}

export default function Yearpicker(props) {
  const {
    value,
    handleChange,
    error,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        views={['year']}
        inputVariant="outlined"
        format="yyyy"
        value={value}
        autoComplete="off"
        cancelLabel="キャンセル"
        okLabel="設定"
        clearLabel="クリア"
        todayLabel="今日"
        showTodayButton
        clearable
        onChange={handleChange}
        autoOk
        helperText=""
        error={error}
      />
    </MuiPickersUtilsProvider>
  );
}

Yearpicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

Yearpicker.defaultProps = {
  value: null,
};
