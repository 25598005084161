import React from 'react';
import {
  TextField, Box, Button, Typography,
  FormControlLabel, Switch, withStyles,
} from '@material-ui/core';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import { getCultureDatas, compareTime, checkValidation } from './rule-list-func';
import Validation from './validation';

const styles = () => ({
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class CultureRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: getCultureDatas(),
      data: getCultureDatas(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 0) {
        this.setData(this.props.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ data: data || getCultureDatas(), messages: getCultureDatas() });
  }

  onChange = (event) => {
    const { data, messages } = this.state;
    const { value, name } = event.target;
    let tempMessage = { ...messages, [name]: Validation.formValidate(name, value) };

    if (name === 'startTime' && value && data.endTime) {
      tempMessage = compareTime(tempMessage, value, data.endTime);
    }
    if (name === 'endTime' && value && data.startTime) {
      tempMessage = compareTime(tempMessage, data.startTime, value);
    }
    this.setState({
      data: { ...data, [name]: value },
      messages: tempMessage,
    });
  }

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: checked,
      },
    });
  }

  onSave = (event) => {
    const { data, messages } = this.state;
    const errObj = checkValidation(data, messages);
    this.setState({ messages: errObj.messages });
    if (errObj.isError) return;
    this.props.onSave(event, data);
  }

  render() {
    const { value, classes } = this.props;

    const { data, messages } = this.state;

    return (
      <TabPanel value={value} index={0}>
        <Box p={2}>
          <Box textAlign="right">
            <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
          </Box>
          <Box m={3} display="flex">
            <Box>
              <FormTitle title="予約カレンダー表示開始時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="startTime"
                value={data.startTime}
                onChange={this.onChange}
                error={messages.startTime !== ''}
              />
              <FormErrorText>{messages.startTime}</FormErrorText>
            </Box>
            <Box ml={5}>
              <FormTitle title="予約カレンダー表示終了時間" isRequired />
              <TextField
                type="time"
                variant="outlined"
                name="endTime"
                value={data.endTime}
                onChange={this.onChange}
                error={messages.endTime !== ''}
              />
              <FormErrorText>{messages.endTime}</FormErrorText>
            </Box>
          </Box>
          <Box m={3}>
            <FormTitle title="キャンセル待ち受付" isRequired />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.cancelWaitAccepted}
                  onChange={this.onCheckChange}
                  name="cancelWaitAccepted"
                  color="primary"
                />
              )}
              label="キャンセル待ちを受け付ける"
            />
          </Box>
          <Box m={3}>
            <FormTitle title="事前予約可能" />
            <FormControlLabel
              control={(
                <Switch
                  checked={data.advanceTimeLimited}
                  onChange={this.onCheckChange}
                  name="advanceTimeLimited"
                  color="primary"
                />
              )}
              label="期間制限あり"
            />
            <Box display="flex">
              <Box mt={1}>
                <FormTitle title="基準日" isRequired />
                <Datepicker
                  handleChange={(_, dateValue) => this.onChange({
                    target: {
                      name: 'advanceReferencedOn',
                      value: dateValue,
                    },
                  })}
                  formatter="yyyy/MM/dd"
                  value={data.advanceReferencedOn}
                  error={messages.advanceReferencedOn !== ''}
                />
                <Typography className={classes.ruleLabel} component="span">から</Typography>
                <FormErrorText>{messages.advanceReferencedOn}</FormErrorText>
              </Box>
            </Box>
            <Box display={data.advanceTimeLimited ? 'flex' : 'none'}>
              <Box mt={1}>
                <FormTitle title="間隔" isRequired />
                <TextField
                  variant="outlined"
                  name="advanceInterval"
                  style={{ width: '50%' }}
                  value={data.advanceInterval}
                  onChange={this.onChange}
                  error={messages.advanceInterval !== ''}
                  inputProps={{ maxLength: 9 }}
                />
                <Typography className={classes.ruleLabel} component="span">日間ごとに</Typography>
                <FormErrorText>{messages.advanceInterval}</FormErrorText>
              </Box>
            </Box>
            <Box display="flex">
              <Box mt={1}>
                <FormTitle title="事前予約可能数" isRequired />
                <TextField
                  variant="outlined"
                  name="advanceNumber"
                  style={{ width: '50%' }}
                  value={data.advanceNumber}
                  onChange={this.onChange}
                  error={messages.advanceNumber !== ''}
                  inputProps={{ maxLength: 9 }}
                />
                <Typography className={classes.ruleLabel} component="span">枠まで</Typography>
                <FormErrorText>{messages.advanceNumber}</FormErrorText>
              </Box>
            </Box>
            <Box mt={1}>
              <FormTitle title="予約ルール" />
              <TextField
                id="memberRule"
                name="memberRule"
                multiline
                fullWidth
                rows={25}
                variant="outlined"
                value={data.memberRule}
                onChange={this.onChange}
                inputProps={{
                  maxLength: 30000,
                }}
              />
            </Box>
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default withStyles(styles)(CultureRules);
