import { ACTION_TYPE } from './action';

const roleDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_SCREENS_SUCCESS:
      return {
        ...state,
        screens: action.screens,
      };
    case ACTION_TYPE.CALL_API_GET_ROLES_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREENS_SUCCESS:
      return {
        ...state,
        roleScreens: action.datas,
        isScreenUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_INSERT_ROLE_SUCCESS:
      return {
        ...state,
        isCreatedSuccess: true,
        createData: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateData: action.data,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.SET_ROLE_CREATED_SUCCESS_MESSAGE:
      return {
        ...state,
        isCreatedSuccessMessage: true,
      };
    case ACTION_TYPE.RESET_ROLE_DETAIL_FLG:
      return {
        ...state,
        isCreatedSuccess: false,
        isUpdateSuccess: false,
        isScreenUpdateSuccess: false,
        isCreatedSuccessMessage: false,
      };
    default:
      return state;
  }
};

export default roleDetail;
