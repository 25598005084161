import { call, put, takeLatest } from 'redux-saga/effects';
import { getStores } from '../../../services/common/selectList.service';
import {
  getDatas, saveCultureRules, saveGolfRules, saveRoomRules,
  saveRentalCourtRules, saveRegularRules, saveGolfTimes,
  saveOnlineCultureRules,
} from '../../../services/setting/rule.service';
import {
  ACTION_TYPE,
  actCallRuleGetStoreSuccess,
  actCallApiGetRuleSuccess,
  actRulesUpdateSuccess, actCallApiRuleGolfTimeUpdateSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 * @param rule
 */
function* callApiRuleGetStore() {
  try {
    const stores = yield call(getStores);
    yield put(actCallRuleGetStoreSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param rule
 */
function* callApiGetRule({ storeId }) {
  try {
    const datas = yield call(getDatas, storeId);
    yield put(actCallApiGetRuleSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 */
function* callApiRulesUpdate({ updateContents, flg }) {
  try {
    let resultDatas = null;
    switch (flg) {
      case 0: resultDatas = yield call(saveCultureRules, updateContents); break;
      case 1: resultDatas = yield call(saveGolfRules, updateContents); break;
      case 2: resultDatas = yield call(saveRoomRules, updateContents); break;
      case 3: resultDatas = yield call(saveRentalCourtRules, updateContents); break;
      case 4: resultDatas = yield call(saveRegularRules, updateContents); break;
      case 5: resultDatas = yield call(saveOnlineCultureRules, updateContents); break;
      default: resultDatas = null;
    }
    yield put(actRulesUpdateSuccess(resultDatas, flg));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGolfTimeUpdate({ updateContents, isBox }) {
  try {
    const results = yield call(saveGolfTimes, updateContents);
    yield put(actCallApiRuleGolfTimeUpdateSuccess(results, isBox));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRules() {
  yield takeLatest(ACTION_TYPE.CALL_API_RULE_GET_STORE, callApiRuleGetStore);
  yield takeLatest(ACTION_TYPE.CALL_API_RULE_SEARCH, callApiGetRule);
  yield takeLatest(ACTION_TYPE.CALL_API_RULE_UPDATE, callApiRulesUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_RULE_GOLF_TIME_UPDATE, callApiGolfTimeUpdate);
}
