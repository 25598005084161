import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 先生連絡タスク一覧データ取得
 *
 * @param
 */
export function getDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.TEACHER_CONTACT_TASK_LIST.TEACHER_CONTACT_TASK_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 選択肢取得
 *
 * @returns
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.TEACHER_CONTACT_TASK_LIST.SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約ステータスを更新する
 *
 * @param {object} params
 */
export function updateContacted(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.INFO.TEACHER_CONTACT_TASK_LIST.TEACHER_CONTACT_TASK_LIST, null, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
