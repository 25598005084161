import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, Dialog,
  DialogContent, DialogActions, Box,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiUpdateTeacherPassword, actResetTeacherListFlg } from '../../../redux/master/teacherList/action';
import Validation from './validation';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confPassword: '',
      passwordMessage: '',
      confPasswordMessage: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  onPasswordChange = (event) => {
    const { confPassword, confPasswordMessage } = this.state;
    const { value } = event.target;

    let tempConfPasswordMessage = confPasswordMessage;
    if (confPassword) {
      tempConfPasswordMessage = Validation.formValidate('confPassword', value, confPassword);
    }
    this.setState({
      password: event.target.value,
      passwordMessage: Validation.formValidate('password', value, null),
      confPasswordMessage: tempConfPasswordMessage,
    });
  }

  onConfPasswordChange = (event) => {
    const { password } = this.state;

    this.setState({
      confPassword: event.target.value,
      confPasswordMessage: Validation.formValidate('confPassword', password, event.target.value),
    });
  }

  onSave = () => {
    const { password, confPassword } = this.state;
    const { selectedRow } = this.props;
    const passwordMessage = Validation.formValidate('password', password, null);
    const confPasswordMessage = Validation.formValidate('confPassword', password, confPassword);
    let loading = true;

    if (passwordMessage || confPasswordMessage) {
      loading = false;
    }

    this.setState({ loading, passwordMessage, confPasswordMessage });

    if (!loading) {
      return;
    }

    this.props.dispatch(actResetTeacherListFlg());
    this.props.dispatch(
      actCallApiUpdateTeacherPassword({
        id: selectedRow.id,
        password,
      }),
    );
  }

  init() {
    this.setState({
      password: '',
      confPassword: '',
      passwordMessage: '',
      confPasswordMessage: '',
      loading: false,
    });
  }

  render() {
    const {
      password,
      confPassword,
      passwordMessage,
      confPasswordMessage,
      loading,
    } = this.state;

    const { open, onClose, selectedRow } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        disableBackdropClick
      >
        <DialogTitle>
          <Box>
            <Box>次のパスワードを再設定</Box>
            <Box>{selectedRow.name}</Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Alert severity="info">
              <AlertTitle>パスワードの安全度</AlertTitle>
              <Box mb={1}>・8文字以上、16文字以下で設定してください</Box>
              <Box>・半角英数字、記号(! # $ @ . _)のみで入力出来ます</Box>
            </Alert>
          </Box>
          <Box>
            <Box mt={2}>
              <FormTitle title="新しいパスワード" isRequired />
              <PasswordField
                password={password}
                onChange={this.onPasswordChange}
                isError={passwordMessage !== ''}
              />
              <FormErrorText>{passwordMessage}</FormErrorText>
            </Box>
            <Box mt={2} mb={1}>
              <FormTitle title="新しいパスワードの確認" isRequired />
              <PasswordField
                password={confPassword}
                onChange={this.onConfPasswordChange}
                isError={confPasswordMessage !== ''}
              />
              <FormErrorText>{confPasswordMessage}</FormErrorText>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <LoadingButton onClick={this.onSave} label="パスワードを変更" loading={loading} />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  teachers: state.teachers,
});

export default connect(mapStateToProps)(ChangePassword);
