// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_BOOKING_SEARCH_LIST: 'CALL_API_INIT_BOOKING_SEARCH_LIST',
  INIT_BOOKING_SEARCH_LIST_SUCCESS: 'INIT_BOOKING_SEARCH_LIST_SUCCESS',
  CALL_BOOKING_SEARCH_CONDITION_SAVE: 'CALL_BOOKING_SEARCH_CONDITION_SAVE',
  CALL_API_BOOKING_LIST_SEARCH: 'CALL_API_BOOKING_LIST_SEARCH',
  CALL_API_BOOKING_LIST_SEARCH_SUCCESS: 'CALL_API_BOOKING_LIST_SEARCH_SUCCESS',
  CALL_API_BOOKING_UPDATE_STATUS: 'CALL_API_BOOKING_UPDATE_STATUS',
  CALL_API_BOOKING_UPDATE_STATUS_SUCCESS: 'CALL_API_BOOKING_UPDATE_STATUS_SUCCESS',
  CALL_API_BOOKING_SUM_UPDATE_STATUS_SUCCESS: 'CALL_API_BOOKING_SUM_UPDATE_STATUS_SUCCESS',
  RESET_UPDATE_SUCCESS_FLG: 'RESET_UPDATE_SUCCESS_FLG',
  CALL_API_BOOKING_DIALOG_MEMBER_LIST: 'CALL_API_BOOKING_DIALOG_MEMBER_LIST',
  CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS: 'CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS',
};
// #endregion

// #region Type
export const actCallInitBookingSearchList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_BOOKING_SEARCH_LIST,
});

export const actInitBookingSearchListSuccess = (selects) => ({
  type: ACTION_TYPE.INIT_BOOKING_SEARCH_LIST_SUCCESS,
  selects,
});

export const actCallSearchConditionSave = (searchCondition) => ({
  type: ACTION_TYPE.CALL_BOOKING_SEARCH_CONDITION_SAVE,
  searchCondition,
});

export const actCallApiBookingListSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_LIST_SEARCH,
  searchCondition,
});

export const actCallApiBookingListSearchSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_LIST_SEARCH_SUCCESS,
  datas,
});

export const actCallApiBookingUpdateStatus = (params) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_UPDATE_STATUS,
  params,
});

export const actCallApiBookingUpdateStatusSuccess = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_UPDATE_STATUS_SUCCESS,
});

export const actCallApiBookingSumUpdateStatusSuccess = () => ({
  type: ACTION_TYPE.CALL_API_BOOKING_SUM_UPDATE_STATUS_SUCCESS,
});

export const actResetUpdateSuccessFlg = () => ({
  type: ACTION_TYPE.RESET_UPDATE_SUCCESS_FLG,
});

export const actCallApiBookingDialogMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST,
  searchCondition,
});

export const actCallApiBookingDialogMemberListSuccess = (members) => ({
  type: ACTION_TYPE.CALL_API_BOOKING_DIALOG_MEMBER_LIST_SUCCESS,
  members,
});
// #endregion
