import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import RequiredChip from '../requiredChip/RequiredChip';

export default function FormTitle(props) {
  const { title, isRequired } = props;

  return (
    <Box fontWeight="fontWeightBold" mb={1} fontSize={15}>
      {title}
      {isRequired && (<RequiredChip />)}
    </Box>
  );
}

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

FormTitle.defaultProps = {
  isRequired: false,
};
