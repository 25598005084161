import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { CSVLink } from 'react-csv';
import {
  DialogActions, DialogContent, CircularProgress, Box,
  Button, Dialog,
} from '@material-ui/core';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import TransferList from '../../../components/templates/transferList/TransferList';
import getCsvItem from './member-proc';
import { actCallApiGetCsvMemberData } from '../../../redux/member/memberList/action';

class MemberDownloadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      right: getCsvItem(),
      left: [],
      loading: false,
      isDone: false,
      csvDatas: {
        datas: [],
        headers: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.members.csvDatas !== this.props.members.csvDatas) {
      if (this.props.members.csvDatas) {
        this.setCsvData(this.props.members.csvDatas);
      }
    }
  }

  init = () => {
    this.setState({
      right: getCsvItem(),
      left: [],
      loading: false,
      isDone: false,
    });
  }

  setCsvData = (csvDatas) => {
    this.setState({ csvDatas, loading: false, isDone: true });
  }

  onSetValue = (right, left) => {
    this.setState({ right, left });
  }

  onGetData = () => {
    const { right } = this.state;
    const { searchCondition } = this.props;
    this.setState({ loading: true });
    const items = right.map((n) => n.value);
    this.props.dispatch(actCallApiGetCsvMemberData({
      storeId: searchCondition.searchStore.id,
      organizationId: searchCondition.searchStore.mstOrganizationCompanyId,
      memberNo: searchCondition.searchMemberNo,
      memberName: searchCondition.searchMemberName,
      memberNameKana: searchCondition.searchMemberNameKana,
      items,
    }));
  }

  render() {
    const {
      open,
      onClose,
    } = this.props;
    const {
      right, left, loading, isDone, csvDatas,
    } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogColorTitle>CSV出力</DialogColorTitle>
        <DialogContent dividers>
          {(!isDone && !loading) && (
            <TransferList
              right={right}
              left={left}
              leftTitle="非出力項目"
              rightTitle="出力項目"
              onSetValue={this.onSetValue}
            />
          )}
          {loading && (
            <Box textAlign="center">
              <CircularProgress color="secondary" />
              <Box mt={3}>出力の準備をしています。このまましばらくお待ちください。</Box>
            </Box>
          )}
          {isDone && (
            <Box>
              <Box mb={3}>出力の準備が整いました。</Box>
              <CSVLink
                data={csvDatas.datas}
                headers={csvDatas.headers}
                filename="member.csv"
                ref={(node) => { this.csvLinkRef = node; }}
              >
                ダウンロードする
              </CSVLink>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{isDone ? '閉じる' : 'キャンセル' }</Button>
          {!isDone && (
            <LoadingButton
              color="primary"
              onClick={this.onGetData}
              loading={loading}
              disabled={right.length === 0}
              label="出力する"
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  members: state.members,
});

export default connect(mapStateToProps)(MemberDownloadModal);
