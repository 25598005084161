import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から権限一覧を取得する
 *
 * @param {object} serachConditions
 */
export function getDatas(serachCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.BANNER.BANNERS, serachCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 広告バナー画像作成
 * @param data
 */
export function createData(data) {
  const httpConnection = new HttpConnection();

  const datas = new FormData();
  datas.append('uploadFile', data);

  return httpConnection
    .create(REST_API.SETTING.BANNER.BANNERS, datas)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * メインバナー画像更新
 * @param id
 */
export function updateData(id, data) {
  const httpConnection = new HttpConnection();

  const datas = new FormData();
  datas.append('uploadFile', data);

  return httpConnection
    .update(REST_API.SETTING.BANNER.BANNER_UPDATE, { id }, datas)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} deleteList
 */
export function deleteDatas(deleteList) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.SETTING.BANNER.BANNERS, deleteList)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * バナーリリース
 * @param id
 */
export function releaseData(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.SETTING.BANNER.BANNER_RELEASE, id, null)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
