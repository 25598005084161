import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 選択肢を取得する
 *
 * @param {object} searchCondition
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.ATTENDANCE_ENTRY.ATTENDANCE_ENTRY_SELECT)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.ATTENDANCE_ENTRY.ATTENDANCE_ENTRIES, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 出席登録
 *
 * @param {*} param
 * @returns
 */
export function entryCreate(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.INFO.ATTENDANCE_ENTRY.ATTENDANCE_ENTRIES, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
