import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 作成処理
 *
 * @param {*}
 */
export function createSystemUser(user) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER_SETTING.SYSTEM_USER.SYSTEM_USERS, user)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新処理
 *
 * @param {*}
 */
export function updateSystemUser(info) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER_SETTING.SYSTEM_USER.SYSTEM_USER, { id: info.id }, info)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 一件取得処理
 *
 */
export function getData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.USER_SETTING.SYSTEM_USER.SYSTEM_USER, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * メール存在チェック
 *
 */
export function checkMail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER_SETTING.SYSTEM_USER.MAIL_CHECK, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
