const menuValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const startTimeValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const endTimeValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const WeeklyNumberValidation = (value) => {
  if (value.length === 0) return '選択してください';
  return '';
};

const CapacityValidation = (value) => {
  if (value.length === 0) return '入力してください';
  if (value < 0) return '0以上の数値を入力してください';
  const regex = /^([1-9]\d*|0)$/;
  if (!regex.test(value)) return '整数値を入力してください';
  return '';
};

const compareTimeCheck = (startTime, endTime) => {
  if (startTime > endTime) return '開始時間以降の値を入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'menu':
        return menuValidation(value);
      case 'weeklyNumber':
        return WeeklyNumberValidation(value);
      case 'startTime':
        return startTimeValidation(value);
      case 'endTime':
        return endTimeValidation(value);
      case 'capacity':
        return CapacityValidation(value);
      default:
        return '';
    }
  };

  static compareTime = (startTime, endTime) => compareTimeCheck(startTime, endTime);
}

export default Validation;
