import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Tabs, Tab, Box, Card, TextField,
  FormGroup, FormControlLabel, Switch, Button,
} from '@material-ui/core';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import {
  actCallInitSiteSetting, actCallApiSiteSettingSearch,
  actCallApiSiteSettingUpdate, actResetSiteSettingFlg,
} from '../../../redux/setting/siteSetting/action';
import Validation from './validation';

const styles = (theme) => ({
  cardMenu: {
    display: 'flex',
    minHeight: '300px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: '20px',
  },
});

const tempErrorMessage = {
  membership: '',
  privacyPolicy: '',
};

class SiteSetting extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsTextList = [
      { name: 'サイト設定' },
    ];

    let isMain = false;
    let searchStore = null;

    if (props.common.user) {
      isMain = props.common.user.main;
      searchStore = props.common.user.store;
    }

    this.props.dispatch(actCallInitSiteSetting());

    if (searchStore !== null) {
      this.search(searchStore);
    }

    this.state = {
      isMain,
      searchStore,
      stores: [],
      tabIndex: 0,
      errorMessage: tempErrorMessage,
      siteSetting: {
        pointValid: false,
        membership: '',
        privacyPolicy: '',
        nameDisplayed: false,
        nameKanaDisplayed: false,
      },
      successSnackOpen: false,
    };
  }

  componentDidMount() {
    document.title = 'サイト設定';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.siteSetting.stores !== this.props.siteSetting.stores) {
      if (this.props.siteSetting.stores) {
        this.setStores(this.props.siteSetting.stores);
      }
    }
    if (prevProps.siteSetting.siteSetting !== this.props.siteSetting.siteSetting) {
      if (this.props.siteSetting.siteSetting) {
        this.setSiteSetting(this.props.siteSetting.siteSetting);
      }
    }
    if (prevProps.siteSetting.isUpdateSuccess !== this.props.siteSetting.isUpdateSuccess) {
      if (this.props.siteSetting.isUpdateSuccess) {
        this.setResultDatas();
      }
    }
  }

  setResultDatas() {
    this.setState({ successSnackOpen: true });
  }

  setUser(user) {
    const { searchStore } = this.state;
    this.setState({
      isMain: user.main,
      searchStore: user.store,
    });
    this.search(searchStore);
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setSiteSetting(siteSetting) {
    this.setState({ siteSetting });
  }

  onSearch = () => {
    const { searchStore } = this.state;
    this.search(searchStore);
    this.setState({ errorMessage: tempErrorMessage });
  }

  search = (searchStore) => {
    const searchStoreId = searchStore && searchStore.id;
    this.props.dispatch(actCallApiSiteSettingSearch({
      id: searchStoreId,
    }));
  }

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { siteSetting, errorMessage } = this.state;

    this.setState({
      siteSetting: {
        ...siteSetting,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { siteSetting } = this.state;
    this.setState({ siteSetting: { ...siteSetting, [name]: checked } });
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  onSave = () => {
    const { siteSetting } = this.state;

    let isError = false;
    let tempMessage = tempErrorMessage;
    Object.keys(siteSetting).forEach((key) => {
      const msg = Validation.formValidate(key, siteSetting[key]);
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      if (msg) {
        isError = true;
      }
    });

    this.setState({ errorMessage: tempMessage });

    if (isError) {
      return;
    }

    this.props.dispatch(actResetSiteSettingFlg());
    this.props.dispatch(actCallApiSiteSettingUpdate(siteSetting));
  }

  onTabChange = (_, tabIndex) => {
    const { siteSetting } = this.props.siteSetting;
    this.setState({ siteSetting, tabIndex, errorMessage: tempErrorMessage });
  };

  render() {
    const { classes } = this.props;
    const {
      isMain, stores, searchStore, tabIndex, siteSetting, errorMessage, successSnackOpen,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsTextList} />
        <MainBody>
          {isMain && (
            <Box mb={2}>
              <SearchBox onSearch={this.onSearch}>
                <Box display="flex">
                  <Box width="30%">
                    <FormTitle title="店舗" />
                    <SearchSelectBox
                      options={stores}
                      value={searchStore}
                      onChange={this.onStoreChange}
                      disableClearable
                      name="searchStore"
                    />
                  </Box>
                </Box>
              </SearchBox>
            </Box>
          )}

          <Card className={classes.cardMenu}>
            <Tabs
              orientation="vertical"
              value={tabIndex}
              onChange={this.onTabChange}
              className={classes.tabs}
            >
              <Tab label="会員ページ" />
              <Tab label="先生ページ" />
            </Tabs>

            <TabPanel value={0} index={tabIndex}>
              <Box m={3}>
                <Box textAlign="right">
                  <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
                </Box>
                <Box mb={3}>
                  <FormTitle title="ポイント機能" />
                  <FormGroup row>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={siteSetting.pointValid}
                          onChange={this.onCheckChange}
                          name="pointValid"
                          color="primary"
                        />
                      )}
                      label="ポイント機能の有効"
                    />
                  </FormGroup>
                </Box>
                <Box>
                  <FormTitle title="会員規約" isRequired />
                  <TextField
                    variant="outlined"
                    multiline
                    fullWidth
                    name="membership"
                    value={siteSetting.membership}
                    error={errorMessage.membership !== ''}
                    onChange={this.onChange}
                    inputProps={{ maxLength: 30000 }}
                    rows={12}
                  />
                  <FormErrorText>{errorMessage.membership}</FormErrorText>
                </Box>
                <Box mt={4}>
                  <FormTitle title="プライバシーポリシー" isRequired />
                  <TextField
                    variant="outlined"
                    multiline
                    fullWidth
                    name="privacyPolicy"
                    value={siteSetting.privacyPolicy}
                    error={errorMessage.privacyPolicy !== ''}
                    onChange={this.onChange}
                    inputProps={{ maxLength: 30000 }}
                    rows={12}
                  />
                  <FormErrorText>{errorMessage.privacyPolicy}</FormErrorText>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={1} index={tabIndex}>
              <Box m={3}>
                <Box textAlign="right">
                  <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
                </Box>
                <Box mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={siteSetting.nameDisplayed}
                          onChange={this.onCheckChange}
                          name="nameDisplayed"
                          color="primary"
                        />
                      )}
                      label="会員名を表示する"
                    />
                  </FormGroup>
                </Box>
                <Box mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={siteSetting.nameKanaDisplayed}
                          onChange={this.onCheckChange}
                          name="nameKanaDisplayed"
                          color="primary"
                        />
                      )}
                      label="会員カナを表示する"
                    />
                  </FormGroup>
                </Box>
                <Box>
                  <FormGroup row>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={siteSetting.openZoomDisplayed}
                          onChange={this.onCheckChange}
                          name="openZoomDisplayed"
                          color="primary"
                        />
                      )}
                      label="zoomを開くボタンを表示する"
                    />
                  </FormGroup>
                </Box>
              </Box>
            </TabPanel>

          </Card>
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="保存が完了しました" />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  siteSetting: state.siteSetting,
});

export default withStyles(styles)(connect(mapStateToProps)(SiteSetting));
