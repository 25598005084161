export function createForm() {
  return {
    contract: {
      corporationName: '',
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      zipCodeBefore: '',
      zipCodeAfter: '',
      mstCodePrefectureCode: null,
      city: '',
      street: '',
      email: '',
      phoneBefore: '',
      phoneMiddle: '',
      phoneAfter: '',
      phoneTwoBefore: '',
      phoneTwoMiddle: '',
      phoneTwoAfter: '',
      birthdayYear: '',
      birthdayMonth: '',
      birthdayDay: '',
      mstCodeGenderCode: 1,
      emergencyLastName: '',
      emergencyFirstName: '',
      emergencyPhoneBefore: '',
      emergencyPhoneMiddle: '',
      emergencyPhoneAfter: '',
    },
    members: [{
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      zipCodeBefore: '',
      zipCodeAfter: '',
      mstCodePrefectureCode: null,
      city: '',
      street: '',
      email: '',
      phoneBefore: '',
      phoneMiddle: '',
      phoneAfter: '',
      phoneTwoBefore: '',
      phoneTwoMiddle: '',
      phoneTwoAfter: '',
      birthdayYear: '',
      birthdayMonth: '',
      birthdayDay: '',
      mstCodeGenderCode: 1,
    }],
    mstStoreId: null,
    password: '',
    mstCodeContractCode: '',
    userType: '',
  };
}

export function createMemberListForm() {
  return [{
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    zipCodeBefore: '',
    zipCodeAfter: '',
    mstCodePrefectureCode: null,
    city: '',
    street: '',
    email: '',
    phoneBefore: '',
    phoneMiddle: '',
    phoneAfter: '',
    phoneTwoBefore: '',
    phoneTwoMiddle: '',
    phoneTwoAfter: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    mstCodeGenderCode: 1,
  }];
}

export function createMemberForm() {
  return {
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    zipCodeBefore: '',
    zipCodeAfter: '',
    mstCodePrefectureCode: null,
    city: '',
    street: '',
    email: '',
    phoneBefore: '',
    phoneMiddle: '',
    phoneAfter: '',
    phoneTwoBefore: '',
    phoneTwoMiddle: '',
    phoneTwoAfter: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    mstCodeGenderCode: 1,
  };
}

export function createContractForm() {
  return {
    corporationName: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    zipCodeBefore: '',
    zipCodeAfter: '',
    mstCodePrefectureCode: null,
    city: '',
    street: '',
    email: '',
    phoneBefore: '',
    phoneMiddle: '',
    phoneAfter: '',
    phoneTwoBefore: '',
    phoneTwoMiddle: '',
    phoneTwoAfter: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    mstCodeGenderCode: 1,
    emergencyLastName: '',
    emergencyFirstName: '',
    emergencyPhoneBefore: '',
    emergencyPhoneMiddle: '',
    emergencyPhoneAfter: '',
  };
}

export function errorMessageForm() {
  return {
    corporationName: '',
    mstStoreId: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    zipCode: '',
    mstCodePrefectureCode: '',
    city: '',
    street: '',
    email: '',
    phone: '',
    phoneTwo: '',
    birthday: '',
    mstCodeGenderCode: '',
    emergencyLastName: '',
    emergencyFirstName: '',
    emergencyPhone: '',
  };
}

export function errorMessageFormMember() {
  return {
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    zipCode: '',
    phone: '',
    phoneTwo: '',
    birthdayNoRequired: '',
    email: '',
  };
}

export function createMultipleItemNames() {
  return {
    phone: ['phoneBefore', 'phoneMiddle', 'phoneAfter'],
    phoneTwo: ['phoneTwoBefore', 'phoneTwoMiddle', 'phoneTwoAfter'],
    emergencyPhone: ['emergencyPhoneBefore', 'emergencyPhoneMiddle', 'emergencyPhoneAfter'],
    zipCode: ['zipCodeBefore', 'zipCodeAfter'],
  };
}
