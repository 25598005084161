import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box, TextField,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import {
  actCallApiBookingMemoUpdate,
} from '../../../redux/booking/bookingDetail/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class BookingMemoAddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateMemo: null,
      changeLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  onChangeMemo = (event) => {
    this.setState({
      updateMemo: event.target.value,
    });
  }

  handleUpdateMemo = () => {
    this.setChangeLoading(true);
    const { booking } = this.props;
    const { updateMemo } = this.state;

    if (updateMemo !== booking.bookingMemo) {
      this.props.dispatch(
        actCallApiBookingMemoUpdate({
          id: booking.id,
          memo: updateMemo,
          contentId: booking.mstContentId,
        }),
      );
    }
    this.setChangeLoading(false);
    this.props.onClose();
  }

  init() {
    const { booking } = this.props;
    this.setState({
      updateMemo: booking.bookingMemo,
    });
  }

  render() {
    const {
      open,
      onClose,
      booking,
    } = this.props;
    const {
      changeLoading,
      updateMemo,
    } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="xs"
          aria-labelledby="update-memberStatus-title"
          aria-describedby="update-memberStatus-description"
          disableBackdropClick
          onEscapeKeyDown={onClose}
        >
          <DialogTitle>
            <Box>{`内部メモを${booking.memo ? '変更' : '追加'}`}</Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Box>
                <FormTitle title="内部メモ" isRequired />
                <TextField
                  id="memo"
                  name="bookingMemo"
                  multiline
                  fullWidth
                  rows={3}
                  variant="outlined"
                  value={updateMemo}
                  onChange={this.onChangeMemo}
                  inputProps={{
                    maxLength: 30000,
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              キャンセル
            </Button>
            <LoadingButton color="primary" label="変更" onClick={this.handleUpdateMemo} loading={changeLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

export default withRouter(connect(mapStateToProps)(BookingMemoAddDialog));
