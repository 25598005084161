import { ACTION_TYPE } from './action';

// region Reducer
const teacherContact = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_INIT_TEACHER_CONTACT_SELECT_LIST_SUCCESS:
      return {
        ...state,
        options: action.selects,
      };
    case ACTION_TYPE.CALL_API_TEACHER_CONTACT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_TEACHER_CONTACT_SEARCH_CONDITION_SAVE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    case ACTION_TYPE.RESET_TEACHER_CONTACT_SUCCESS_FLG:
      return {
        ...state,
        isContactedSuccess: false,
      };
    case ACTION_TYPE.CALL_TEACHER_CONTACT_TASK_CONTACTED_SUCCESS:
      return {
        ...state,
        isContactedSuccess: true,
      };
    default:
      return state;
  }
};

export default teacherContact;
