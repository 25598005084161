import React from 'react';
import { makeStyles, DialogTitle as Title } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  errorTitle: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default function DialogTitle(props) {
  const classes = useStyles();
  const { error, children } = props;

  return (
    <Title className={error ? classes.errorTitle : classes.title}>{children}</Title>
  );
}

DialogTitle.defaultProps = {
  error: false,
};
