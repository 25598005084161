import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actCallApiInitSchoolCalendarListSuccess,
  actCallApiSchoolCalendarListSearchSuccess,
  actCallApiSchoolCalendarListResetSuccess,
  actCallApiSchoolCalendarListExperiencerCheckSuccess,
  actCallApiSchoolCalendarListModalDataSuccess,
  actCallApiSchoolCalendarListCreateSuccess,
} from './action';
import { getSelectes } from '../../../services/event/eventCalendarList.service';
import { actCallApiServerError } from '../../common/common/action';
import {
  getDatas, resetDatas, experiencerCheck, getModalData, createDatas,
} from '../../../services/school/schoolCalendarList.service';

/**
 * カレンダー作成（スクール系、イベント系）用のセレクトボックスリスト
 */
function* callApiInitCalendarList() {
  try {
    const datas = yield call(getSelectes, { regularFlg: 2 });
    yield put(actCallApiInitSchoolCalendarListSuccess({
      stores: datas.stores,
      contents: datas.contents,
      storeContentLinks: datas.storeContentLinks,
    }));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  カレンダー作成一覧検索
 * @param
 */
function* callApiSchoolCalendarListSearch({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiSchoolCalendarListSearchSuccess(
      datas,
    ));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  カレンダー作成
 * @param
 */
function* callApiSchoolCalendarListCreate({ params }) {
  try {
    const resultDatasId = yield call(createDatas, params);
    yield put(actCallApiSchoolCalendarListCreateSuccess(resultDatasId));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  体験者確認
 * @param
 */
function* callApiSchoolCalendarListExperiencerCheck({ searchCondition }) {
  try {
    const resetFlg = yield call(experiencerCheck, searchCondition);
    yield put(actCallApiSchoolCalendarListExperiencerCheckSuccess(resetFlg));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  モーダルデータ取得
 * @param
 */
function* callApiSchoolCalendarListModalData({ searchCondition }) {
  try {
    const modalData = yield call(getModalData, searchCondition);
    yield put(actCallApiSchoolCalendarListModalDataSuccess(modalData));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 *  予約枠リセット
 * @param
 */
function* callApiSchoolCalendarListReset({ resetCondition }) {
  try {
    yield call(resetDatas, resetCondition);
    yield put(actCallApiSchoolCalendarListResetSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSchoolCalendarList() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SCHOOL_CALENDAR_LIST, callApiInitCalendarList);
  yield takeLatest(
    ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_CREATE, callApiSchoolCalendarListCreate,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_SEARCH, callApiSchoolCalendarListSearch,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK,
    callApiSchoolCalendarListExperiencerCheck,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_RESET, callApiSchoolCalendarListReset,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA, callApiSchoolCalendarListModalData,
  );
}
