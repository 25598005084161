import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から権限一覧を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER_SETTING.ROLE.ROLES, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} deleteList
 */
export function deleteRoles(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.USER_SETTING.ROLE.ROLES, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
