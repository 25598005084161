import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から休日一覧を取得する
 *
 * @param {object} serachConditions
 */
export function getDatas(serachCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.HOLIDAY.HOLIDAYS, serachCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 休日設定
 * @param data
 */
export function createData(data) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.HOLIDAY.HOLIDAYS, data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} deleteList
 */
export function deleteDatas(deleteList) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.SETTING.HOLIDAY.HOLIDAYS, deleteList)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
