// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_EVENT_CALENDAR_LIST: 'CALL_API_INIT_EVENT_CALENDAR_LIST',
  CALL_API_INIT_EVENT_CALENDAR_LIST_SUCCESS: 'CALL_API_INIT_EVENT_CALENDAR_LIST_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_SEARCH: 'CALL_API_EVENT_CALENDAR_LIST_SEARCH',
  CALL_API_EVENT_CALENDAR_LIST_SEARCH_SUCCESS: 'CALL_API_EVENT_CALENDAR_LIST_SEARCH_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_CREATE: 'CALL_API_EVENT_CALENDAR_LIST_CREATE',
  CALL_API_EVENT_CALENDAR_LIST_CREATE_SUCCESS: 'CALL_API_EVENT_CALENDAR_LIST_CREATE_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK: 'CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK',
  CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK_SUCCESS: 'CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_RESET: 'CALL_API_EVENT_CALENDAR_LIST_RESET',
  CALL_API_EVENT_CALENDAR_LIST_RESET_SUCCESS: 'CALL_API_EVENT_CALENDAR_LIST_RESET_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK: 'CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK',
  CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK_SUCCESS: 'CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK_SUCCESS',
  CALL_API_EVENT_CALENDAR_LIST_FLG_RESET: 'CALL_API_EVENT_CALENDAR_LIST_FLG_RESET',
  SET_SEARCH_EVENT_CALENDAR_SCHEDULE: 'SET_SEARCH_EVENT_CALENDAR_SCHEDULE',
};
// #endregion

// #region action

export const actCallApiInitEventCalendarList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_CALENDAR_LIST,
});

export const actCallApiInitEventCalendarListSuccess = (searchSelects) => ({
  type: ACTION_TYPE.CALL_API_INIT_EVENT_CALENDAR_LIST_SUCCESS,
  searchSelects,
});

export const actCallApiEventCalendarListSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_SEARCH,
  searchCondition,
});

export const actCallApiEventCalendarListSearchSuccess = (datas, weekSchedules) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_SEARCH_SUCCESS,
  datas,
  weekSchedules,
});

export const actCallApiEventCalendarListCreate = (params) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_CREATE,
  params,
});

export const actCallApiEventCalendarListCreateSuccess = (response) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_CREATE_SUCCESS,
  response,
});

export const actCallApiEventCalendarListReservationCheck = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK,
  searchCondition,
});

export const actCallApiEventCalendarListReservationCheckSuccess = (reservationFlg) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESERVATION_CHECK_SUCCESS,
  reservationFlg,
});

export const actCallApiEventCalendarListReset = (resetCondition) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESET,
  resetCondition,
});

export const actCallApiEventCalendarListResetSuccess = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_RESET_SUCCESS,
});

export const actCallApiEventCalendarListRoomCultureCheck = (checkCondition) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK,
  checkCondition,
});

export const actCallApiEventCalendarListRoomCultureCheckSuccess = (roomCultureCheckFlg) => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_ROOM_CULTURE_CHECK_SUCCESS,
  roomCultureCheckFlg,
});

export const actCallApiEventCalendarListFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_EVENT_CALENDAR_LIST_FLG_RESET,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_SEARCH_EVENT_CALENDAR_SCHEDULE,
  searchCondition,
});
// #endregion
