import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {
  Button, Dialog, Box,
  DialogContent, DialogActions,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiChangePassword, actResetUpdateFlg } from '../../../redux/common/headerChangePassword/action';
import Validation from '../../other/changePassword/validation';
import urls from '../../../constants/urls';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordCheck: '',
      passwordMessage: '',
      passwordCheckMessage: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setLoading(this.props.common.isLoading);
    }
    if (prevProps.headerChangePassword.isUpdateSuccess
        !== this.props.headerChangePassword.isUpdateSuccess) {
      if (this.props.headerChangePassword.isUpdateSuccess) {
        this.logout();
      }
    }
  }

  setLoading(loading) {
    this.setState({ loading });
  }

  // #region オンファンクション（onFunc）
  onPasswordChange = (event) => {
    const { passwordCheck } = this.state;

    this.setState({
      password: event.target.value,
      passwordMessage: Validation.formValidate('password', event.target.value, null),
    });

    if (passwordCheck) {
      this.setState({
        passwordCheckMessage: Validation.formValidate('passwordCheck', event.target.value, passwordCheck),
      });
    }
  }

  onPasswordCheckChange = (event) => {
    const { password } = this.state;

    this.setState({
      passwordCheck: event.target.value,
      passwordCheckMessage: Validation.formValidate('passwordCheck', password, event.target.value),
    });
  }

  onSave = () => {
    const {
      passwordMessage,
      passwordCheckMessage,
      password,
      passwordCheck,
    } = this.state;
    const { userId } = this.props;

    const msg = Validation.formValidate('password', password, null);
    const checkMsg = Validation.formValidate('passwordCheck', password, passwordCheck);

    if (passwordMessage || passwordCheckMessage || msg || checkMsg) {
      this.setState({
        passwordMessage: msg,
        passwordCheckMessage: checkMsg,
      });
      return null;
    }

    this.setState({ loading: true });
    this.props.dispatch(actResetUpdateFlg());
    this.props.dispatch(
      actCallApiChangePassword({
        id: userId,
        param: {
          password,
        },
      }),
    );
    return null;
  }
  // #endregion

  logout = () => {
    window.location.href = `${urls.OTHER.LOGIN}/?success`;
  }

  init() {
    this.setState({
      password: '',
      passwordCheck: '',
      passwordMessage: '',
      passwordCheckMessage: '',
      loading: false,
    });
  }

  render() {
    const {
      password,
      passwordCheck,
      passwordMessage,
      passwordCheckMessage,
      loading,
    } = this.state;

    const {
      open,
      onClose,
      name,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="password-dialog"
        fullWidth
        maxWidth="xs"
        disableBackdropClick
      >
        <DialogTitle>
          <Box display={name ? '' : 'none'}>
            <Box>次のパスワードを再設定</Box>
            <Box>{name}</Box>
          </Box>
          <Box display={name ? 'none' : ''}>パスワード変更</Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Alert severity="warning">パスワードを変更すると自動でログアウトします</Alert>
          </Box>
          <Box mt={1}>
            <Alert severity="info">
              <AlertTitle>パスワードの安全度</AlertTitle>
              <Box mb={1}>・8文字以上、16文字以下で設定してください</Box>
              <Box>・半角英数字、記号(! # $ @ . _)のみで入力出来ます</Box>
            </Alert>
          </Box>
          <Box>
            <Box mt={2}>
              <FormTitle title="新しいパスワード" isRequired />
              <PasswordField
                password={password}
                onChange={this.onPasswordChange}
                isError={passwordMessage !== ''}
              />
              <FormErrorText>{passwordMessage}</FormErrorText>
            </Box>
            <Box mt={2} mb={1}>
              <FormTitle title="新しいパスワードの確認" isRequired />
              <PasswordField
                password={passwordCheck}
                onChange={this.onPasswordCheckChange}
                isError={passwordCheckMessage !== ''}
              />
              <FormErrorText>{passwordCheckMessage}</FormErrorText>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <LoadingButton onClick={this.onSave} label="パスワードを変更" loading={loading} />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  headerChangePassword: state.headerChangePassword,
  users: state.users,
  members: state.members,
  common: state.common,
});

export default connect(mapStateToProps)(ChangePassword);

ChangePassword.propTypes = {
  userId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string,
};

ChangePassword.defaultProps = {
  name: null,
  userId: null,
};
