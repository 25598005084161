import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
// import format from 'date-fns/format';
import {
  Button, Dialog, DialogActions, DialogContent, Grid, Card, CardContent,
  Box, TextField, Divider,
} from '@material-ui/core';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
// import Validation from './validation';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import { actCallDialogMemberProductInfo } from '../../../redux/school/weekSelect/action';

class WeekSelectLinkDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchMemberNo: '',
      selectedDatas: [],
      unSelectedDatas: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.weekSelect.dialogMemberData !== this.props.weekSelect.dialogMemberData) {
      if (this.props.weekSelect.dialogMemberData) {
        this.setMemberContract(this.props.weekSelect.dialogMemberData);
      }
    }
  }

  setMemberContract(memberInfo) {
    this.setState({ memberInfo });
  }

  onSearchMemberInfo = () => {
    const { searchMemberNo } = this.state;
    if (searchMemberNo.length !== 10) {
      this.setState({ memberInfo: null });
    }

    this.props.dispatch(actCallDialogMemberProductInfo({ searchMemberNo }));
  }

  onMemberNoChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handlePreSave = (event) => {
    const { searchMemberNo, memberInfo } = this.state;
    this.props.handleSave(event, searchMemberNo, memberInfo);
  }

  init() {
    const {
      datas, selectedIds, searchCondition, selectedMemberInfo,
    } = this.props;

    const selectedDatas = datas.filter((data) => selectedIds.includes(data.id));
    const unSelectedDatas = datas.filter(
      (data) => data.exists === true && !selectedIds.includes(data.id),
    );

    this.setState({
      targetContentName: searchCondition.searchContent.name,
      targetMonth: searchCondition.searchMonth,
      selectedDatas,
      unSelectedDatas,
      memberInfo: selectedMemberInfo,
      searchMemberNo: (selectedMemberInfo && selectedMemberInfo.memberNo) ? selectedMemberInfo.memberNo : '',
      errorMessage: '',
    });
  }

  render() {
    const {
      open, handleClose,
    } = this.props;

    const {
      selectedDatas, unSelectedDatas, searchMemberNo, errorMessage,
      targetContentName, targetMonth, memberInfo,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>会員の紐付け</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card variant="outlined">
                  <CardContent>
                    <Box>
                      <FormTitle title="コンテンツ" />
                      <Box>{targetContentName}</Box>
                    </Box>
                    <Box mt={3}>
                      <FormTitle title="適用開始月" />
                      <Box>{targetMonth}</Box>
                    </Box>
                    <Box mt={3} display={selectedDatas.length > 0 ? '' : 'none'}>
                      <FormTitle title="選択したスケジュール" />
                      {selectedDatas.map((data, index) => (
                        <div key={data.id}>
                          {index !== 0 ? (<Divider />) : ''}
                          <Box>{data.menuName}</Box>
                          <Box>{`${data.weekName}曜日`}</Box>
                          <Box>{`${data.startTime}~${data.endTime}`}</Box>
                        </div>
                      ))}
                    </Box>
                    <Box mt={3} display={unSelectedDatas.length > 0 ? '' : 'none'}>
                      <FormTitle title="解除したスケジュール" />
                      {unSelectedDatas.map((data, index) => (
                        <div key={data.id}>
                          {index !== 0 ? (<Divider />) : ''}
                          <Box>{data.menuName}</Box>
                          <Box>{`${data.weekName}曜日`}</Box>
                          <Box>{`${data.startTime}~${data.endTime}`}</Box>
                        </div>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" ml={5} mt={2}>
                  <Box>
                    <FormTitle title="会員番号" />
                    <TextField
                      name="searchMemberNo"
                      variant="outlined"
                      value={searchMemberNo}
                      onChange={this.onMemberNoChange}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <FormErrorText>{errorMessage}</FormErrorText>
                  </Box>
                  <Box ml={2} display="flex" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={this.onSearchMemberInfo}
                      disabled={searchMemberNo.length !== 10}
                    >
                      検索
                    </Button>
                  </Box>
                </Box>
                {
                  (!memberInfo || !memberInfo.memberId)
                    ? (
                      <Box mt={3} ml={5}>
                        <Box fontWeight="fontWeightBold" m={1} fontSize={17}>会員が存在しません</Box>
                      </Box>
                    ) : (
                      <Box mt={3} ml={5}>
                        <FormTitle title="名前" />
                        <Box>{memberInfo.nameKana}</Box>
                        <Box>{memberInfo.name}</Box>
                      </Box>
                    )
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button
              disabled={
                (selectedDatas.length === 0 && unSelectedDatas.length === 0)
                  || (!memberInfo || !memberInfo.memberId)
              }
              onClick={this.handlePreSave}
              color="primary"
            >
              この内容で登録する
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common, weekSelect: state.weekSelect,
});

export default withRouter(connect(mapStateToProps)(WeekSelectLinkDialog));
