import React from 'react';
import {
  Card, CardContent, Box, Divider, Button,
} from '@material-ui/core';
import {
  format, isEqual, isBefore,
} from 'date-fns';
import OpenInNew from '@material-ui/icons/OpenInNew';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

export default function BookingDetailInfoCard(props) {
  const {
    booking,
    handleReSendZoomUrlModalOpen,
    onClickCreateZoom,
    onClickAddMemo,
  } = props;

  const checkZoomOpenBtnDisp = () => {
    const {
      mstContentId, zoomMainUrl, targetedOn,
      endTime, mstCodeBookingStatusCode,
    } = props.booking;

    if (mstContentId !== 17 || mstCodeBookingStatusCode !== 0) {
      return false;
    }

    const targetEndDate = new Date(`${targetedOn} ${endTime}`);
    const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
    const result = zoomMainUrl && isBefore(today, targetEndDate);
    return result;
  };

  const checkZoomCreateBtnDisp = () => {
    const {
      mstContentId, zoomMainUrl, targetedOn,
      endTime, mstCodeBookingStatusCode,
    } = props.booking;

    if (mstContentId !== 17 || mstCodeBookingStatusCode !== 0) {
      return false;
    }

    const targetEndDate = new Date(`${targetedOn} ${endTime}`);
    const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
    const result = !zoomMainUrl && isBefore(today, targetEndDate);
    return result;
  };

  const checkReSendZoomUrlBtnDisp = () => {
    const {
      mstContentId, zoomMainUrl, targetedOn,
      endTime, mstCodeBookingStatusCode,
    } = props.booking;

    if (mstContentId !== 17 || mstCodeBookingStatusCode !== 0) {
      return false;
    }

    const targetEndDate = new Date(`${targetedOn} ${endTime}`);
    const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
    const result = zoomMainUrl
                && (isBefore(today, targetEndDate)
                || isEqual(targetEndDate, today));
    return result;
  };

  const handleClickZoomOpen = () => {
    window.open(props.booking.zoomMainUrl);
  };

  const boxStyle = { whiteSpace: 'pre-line' };

  return (
    <div>
      <Card>
        <CardContent>
          <Box>
            <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" style={boxStyle}>
              {booking.targetDate}
            </Box>
            <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" style={boxStyle}>
              {booking.targetTime}
            </Box>
            <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" style={boxStyle}>
              {booking.menuName}
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" size="medium" onClick={onClickAddMemo}>内部メモ追加</Button>
            </Box>
            {checkZoomCreateBtnDisp() && (
              <Box mt={2}>
                <Button variant="contained" color="primary" size="medium" onClick={onClickCreateZoom}>ウェビナーURLを作成</Button>
              </Box>
            )}
            {checkZoomOpenBtnDisp() && (
              <Box mt={2}>
                <Button variant="contained" color="primary" size="medium" startIcon={<OpenInNew />} onClick={handleClickZoomOpen}>Zoomを開く</Button>
              </Box>
            )}
            {checkReSendZoomUrlBtnDisp() && (
              <Box mt={2}>
                <Button variant="contained" size="medium" onClick={handleReSendZoomUrlModalOpen}>再送する</Button>
              </Box>
            )}
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Box mb={3}>
            <FormTitle title="店舗" />
            <Box style={boxStyle}>{booking.storeName}</Box>
          </Box>
          <Box mb={3} display={booking.content ? '' : 'none'}>
            <FormTitle title="内容" />
            <Box style={boxStyle}>{booking.content}</Box>
          </Box>
          <Box mb={3} display={booking.annotation ? '' : 'none'}>
            <FormTitle title="特記事項" />
            <Box style={boxStyle}>{booking.annotation}</Box>
          </Box>
          <Box mb={3}>
            <FormTitle title="先生" />
            <Box style={boxStyle}>{booking.teacherName}</Box>
          </Box>
          <Box mb={3} display={booking.roomName ? '' : 'none'}>
            <FormTitle title="Room" />
            <Box style={boxStyle}>{booking.roomName}</Box>
          </Box>
          <Box mb={3} display={booking.materialFee ? '' : 'none'}>
            <FormTitle title="教材費" />
            <Box style={boxStyle}>{booking.materialFee}</Box>
          </Box>
          <Box mb={3} display={booking.managementFee ? '' : 'none'}>
            <FormTitle title="管理費" />
            <Box style={boxStyle}>{booking.managementFee}</Box>
          </Box>
          <Box mb={3}>
            <FormTitle title="定員" />
            <Box style={boxStyle}>{booking.capacity}</Box>
          </Box>
          <Box mb={3}>
            <FormTitle title="メニュー内部メモ" />
            <Box style={boxStyle}>{booking.memo}</Box>
          </Box>
          <Box mb={3}>
            <FormTitle title="内部メモ" />
            <Box style={boxStyle}>{booking.bookingMemo}</Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
