import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, getSelects,
  deleteSystemUser,
} from '../../../services/userSetting/sysytemUserList.service';
import {
  ACTION_TYPE,
  actCallApiGetSelectListSuccess,
  actCallApiGetSystemUsersSuccess,
  actCallApiSystemUserDeleteSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param userInfo
 */
function* callApiInitSystemUsers() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallApiGetSelectListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param userInfo
 */
function* callApiSystemUsers({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiGetSystemUsersSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param userInfo
 */
function* callApiSystemUserDelete({ values }) {
  try {
    yield call(deleteSystemUser, values);
    yield put(actCallApiSystemUserDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSystemUsers() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SYSTEM_USER, callApiInitSystemUsers);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_SEARCH, callApiSystemUsers);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_DELETE, callApiSystemUserDelete);
}
