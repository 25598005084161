import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getCalendarScheduleDetail,
  updateHeaderCalendarSchedule,
  deleteCalendarSchedule,
  deleteBooking,
} from '../../../services/share/calendarScheduleDetail.service';
import {
  ACTION_TYPE,
  actCallApiCalendarScheduleUpdateSuccess,
  actCallApiCalendarScheduleDetailSuccess,
  actCallApiCalendarScheduleDeleteSuccess,
  actCallApiBookingDeleteSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 予約枠カレンダー(ヘッダー、詳細)取得
 * @param
 */
function* callApiCalendarScheduleDetail(param) {
  try {
    const datas = yield call(getCalendarScheduleDetail, param.param.headerId);
    yield put(actCallApiCalendarScheduleDetailSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠ヘッダー公開日変更
 * @param
 */
function* callApiCalendarScheduleHeaderUpdate({ param }) {
  try {
    yield call(updateHeaderCalendarSchedule, param);
    yield put(actCallApiCalendarScheduleUpdateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠カレンダー削除
 */
function* callApiCalendarScheduleDelete({ id, regular }) {
  try {
    yield call(deleteCalendarSchedule, id, regular);
    yield put(actCallApiCalendarScheduleDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiBookingDelete({ param }) {
  try {
    yield call(deleteBooking, param);
    yield put(actCallApiBookingDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCalendarScheduleDetail() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DETAIL, callApiCalendarScheduleDetail,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_UPDATE, callApiCalendarScheduleHeaderUpdate,
  );
  yield takeLatest(ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_DELETE, callApiCalendarScheduleDelete);
  yield takeLatest(ACTION_TYPE.CALL_API_BOOKING_DELETE, callApiBookingDelete);
}
