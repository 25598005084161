import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, withStyles, Button,
} from '@material-ui/core';
import urls from '../../../../constants/urls';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import SchoolCalendarListResetDialog from './SchoolCalendarListResetDialog';
import SchoolCalendarListSaveDialog from './SchoolCalenderListSaveDialog';
import { actCallEventCalendarListSetIsCreated } from '../../../../redux/share/calendarScheduleDetail/action';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  paperTabel: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
});

class SchoolCalendarListTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetModalOpen: false,
      resetSuccessSnackOpen: false,
      calendarModalOpen: false,
      selectedRow: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolCalendarList.deletedFlg !== this.props.schoolCalendarList.deletedFlg) {
      if (this.props.schoolCalendarList.deletedFlg) {
        this.setDeletedSuccess(this.props.schoolCalendarList.deletedFlg);
      }
    }
    if (prevProps.schoolCalendarList.resultDatasId
      !== this.props.schoolCalendarList.resultDatasId) {
      if (this.props.schoolCalendarList.resultDatasId) {
        this.setResultData(this.props.schoolCalendarList.resultDatasId);
      }
    }
  }

  setResultData = (resultDatasId) => {
    this.props.dispatch(actCallEventCalendarListSetIsCreated());
    this.onDetail(null, this.state.selectedRow, resultDatasId);
  }

  setDeletedSuccess(deletedFlg) {
    this.setState({ resetSuccessSnackOpen: deletedFlg });
    this.onResetModalClose();
    this.props.onSearch();
  }

  onResetModalClose = () => {
    this.setState({ resetModalOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ resetSuccessSnackOpen: false });
  }

  onReset = (_, row) => {
    this.setState({ selectedRow: row, resetModalOpen: true });
  }

  onCalendarModalOpen = (_, row) => {
    this.setState({
      calendarModalOpen: true,
      selectedRow: row,
    });
  }

  onDetail = (_, row, id) => {
    const {
      storeId, contentId,
    } = row;
    const { isMain } = this.props;

    this.props.history.push({
      pathname: urls.SCHOOL.SCHOOL_CALENDARS_DETAIL,
      state: {
        headerId: id, isMain, storeId, contentId, regular: true,
      },
    });
  }

  onCalendarModalClose = () => {
    this.setState({ calendarModalOpen: false });
  }

  onResetModalOpen = () => {
    this.setState({ resetModalOpen: true });
  }

  render() {
    const { classes, datas } = this.props;

    const {
      resetModalOpen, resetSuccessSnackOpen, calendarModalOpen, selectedRow,
    } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperTabel}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead className={classes.header}>
                <TableRow className={classes.headerRow}>
                  <TableCell key="month-cell" align="left">
                    月
                  </TableCell>
                  <TableCell key="content-cell" align="left">
                    コンテンツ
                  </TableCell>
                  <TableCell key="date-cell" align="left">
                    公開日
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.targetMonth}
                  >
                    <TableCell className={classes.tableCell}>
                      {`${row.targetMonth}月`}
                    </TableCell>
                    <TableCell>
                      {row.contentName}
                    </TableCell>
                    <TableCell>
                      {row.publishedOn}
                    </TableCell>
                    {row.id ? (
                      <TableCell>
                        <Button color="primary" onClick={(event) => this.onDetail(event, row, row.id)}>詳細を見る</Button>
                        <Button color="secondary" onClick={(event) => this.onReset(event, row)}>適用をリセットする</Button>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Button color="primary" onClick={(event) => this.onCalendarModalOpen(event, row)}>適用する</Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <SchoolCalendarListSaveDialog
          saveOpen={calendarModalOpen}
          onCalendarModalClose={this.onCalendarModalClose}
          selectedRow={selectedRow}
        />
        <SchoolCalendarListResetDialog
          resetOpen={resetModalOpen}
          onResetModalClose={this.onResetModalClose}
          selectedRow={selectedRow}
        />
        <SuccessSnackbar
          open={resetSuccessSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message="予約枠のリセットが完了しました"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolCalendarList: state.schoolCalendarList,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(SchoolCalendarListTable)));
