import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box, Container, withStyles, Card, Divider,
  Button,
} from '@material-ui/core';
import { format } from 'date-fns';
import {
  actResetContractFlg, actMemberWithdrawal,
} from '../../../redux/member/memberDetail/action';
import urls from '../../../constants/urls';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
  container: {
    padding: 10,
  },
});

class MemberWithdrawal extends Component {
  constructor(props) {
    super(props);
    const {
      contractDetailDatas,
      date, id, type,
    } = props.location.state;

    this.state = {
      contractDetailDatas,
      date,
      id,
      type,
      isComplete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberDetail.isMemberWithdrawalUpdate
      !== this.props.memberDetail.isMemberWithdrawalUpdate) {
      if (this.props.memberDetail.isMemberWithdrawalUpdate) {
        this.complete();
      }
    }
  }

  complete = () => {
    this.setState({ isComplete: true });
  }

  onClickUpdate = () => {
    const {
      id, type, date,
    } = this.state;
    this.props.dispatch(actResetContractFlg());
    this.props.dispatch(actMemberWithdrawal({ type, memberId: id, withdrawalDate: date }));
  };

  onBack = () => {
    const {
      id,
    } = this.state;
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: {
        memberId: id,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const {
      contractDetailDatas, date, isComplete,
    } = this.state;
    if (isComplete) {
      return (
        <Box classN ame={classes.root}>
          <Container className={classes.container}>
            <Card variant="outlined">
              <Box p={3} textAlign="center">
                <Box fontWeight="fontWeightBold" fontSize={36}>
                  退会処理
                </Box>
                <Divider />
                <Divider className={classes.marginTop} />
                <Box mt={4} fontWeight="fontWeightBold" fontSize={20}>退会処理が完了しました。</Box>
                <Box mt={1} fontWeight="fontWeightBold" fontSize={20}>ご利用ありがとうございました。</Box>
                <Box mt={4} fontWeight="fontWeightBold" fontSize={20}>こちらの画面のままスタッフに渡してください。</Box>
                <Box mt={10}>
                  <Button onClick={this.onBack}>戻る</Button>
                </Box>
              </Box>
            </Card>
          </Container>
        </Box>
      );
    }
    return (
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Card variant="outlined">
            <Box p={3} textAlign="center">
              <Box fontWeight="fontWeightBold" fontSize={36}>
                退会処理
              </Box>
              <Divider />
              <Divider className={classes.marginTop} />
              <Box mt={4} fontWeight="fontWeightBold">以下の契約を解約します。</Box>
              <Box mt={1}>
                {contractDetailDatas.map((contractDetail) => (
                  <Box key={contractDetail.productName} mt={1} fontSize={20} style={{ wordBreak: 'break-all' }}>
                    {contractDetail.productName}
                  </Box>
                ))}
              </Box>
              <Box display={contractDetailDatas.length > 0 ? 'none' : ''}>
                <Box fontWeight="fontWeightBold" fontSize={36}>契約中の商品がありません</Box>
              </Box>
              <Box mt={4} fontWeight="fontWeightBold">{`${format(new Date(date), 'yyyy年MM月dd日')}以降はご利用できません`}</Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box align="center" my={2} ml={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.onClickUpdate}
                >
                  退会する
                </Button>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
  members: state.members,
  common: state.common,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberWithdrawal)));
