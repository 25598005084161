import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/restApiUrls';
import {
  ACTION_TYPE, actSearchMemberSuccess,
  getZipCodeAddressSuccess,
  actGetProductListSuccess,
  actContractInsertSuccess,
  actContractDeleteSuccess,
  actUpdateMemberSuccess,
  actCheckMemberNoSuccess,
  actMemberWithdrawalSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { deleteDatas, saveData, getDatas } from '../../../services/base.service';

/**
 * 会員取得
 * @param {*} param0
 */
function* callSearchMember({ searchCondition }) {
  try {
    const datas = yield call(
      getDatas, REST_API.MEMBER.DETAIL.MEMBER_DETAIL, searchCondition,
    );
    yield put(actSearchMemberSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

function* callCheckMemberNo({ searchCondition }) {
  try {
    const datas = yield call(
      getDatas, REST_API.MEMBER.DETAIL.MEMBER_DETAIL_CHECK, searchCondition,
    );
    yield put(actCheckMemberNoSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

function* callUpdateMember({ param }) {
  try {
    const datas = yield call(saveData, REST_API.MEMBER.DETAIL.MEMBER_DETAIL, param);
    yield put(actUpdateMemberSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

function* callGetProductList({ searchCondition }) {
  try {
    const datas = yield call(
      getDatas,
      REST_API.MEMBER.DETAIL.MEMBER_DETAIL_PRODUCT,
      searchCondition,
    );
    yield put(actGetProductListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

function* callApiGetZipCodeAddress({ zipcode }) {
  try {
    const zipCodeAddress = yield call(getDatas, REST_API.COMMON.ZIPCODEADDRESS, zipcode.zipcode);
    yield put(getZipCodeAddressSuccess(zipCodeAddress));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 契約明細登録
 * @param {*} param0
 */
function* callInsertContract({ searchCondition }) {
  try {
    yield call(saveData, REST_API.MEMBER.DETAIL.MEMBER_DETAIL_CONTRACT, searchCondition);
    yield put(actContractInsertSuccess());
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * 契約明細削除
 * @param {*} param0
 */
function* callDeleteContract({ searchCondition }) {
  try {
    yield call(deleteDatas, REST_API.MEMBER.DETAIL.MEMBER_DETAIL_CONTRACT, searchCondition);
    yield put(actContractDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

function* callMemberWithdrawal({ param }) {
  try {
    yield call(saveData, REST_API.MEMBER.DETAIL.MEMBER_DETAIL_WITHDRAWAL, param);
    yield put(actMemberWithdrawalSuccess());
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL, callSearchMember);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL, callUpdateMember);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_PRODUCT_LIST, callGetProductList);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS,
    callApiGetZipCodeAddress);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO,
    callCheckMemberNo);
  yield takeLatest(ACTION_TYPE.CALL_API_CONTRACT_INSERT, callInsertContract);
  yield takeLatest(ACTION_TYPE.CALL_API_CONTRACT_DELETE, callDeleteContract);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_WITHDRAWAL, callMemberWithdrawal);
}
