import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog, Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogContent, TextField, LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchBox from '../../../../components/templates/searchBox/SearchBox';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import RoomMemberDataTable from './RoomMemberDataTable';
import {
  actCallApiRoomScheduleMemberList,
} from '../../../../redux/booking/roomSchedule/action';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class RoomMemberAddDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: {},
      loading: true,
      searchMemberNo: '',
      searchMemberName: '',
      searchMemberNameKana: '',
      page: 0,
      records: 10,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.roomSchedule.members !== this.props.roomSchedule.members) {
      if (this.props.roomSchedule.members) {
        this.setDatas(this.props.roomSchedule.members);
      }
    }
  }

  setDatas(members) {
    this.setState({ members });
  }

  init = () => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, page, records,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
    this.setState({
      loading: false,
    });
  }

  search = (
    page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
  ) => {
    const { targetStore } = this.props;

    this.props.dispatch(
      actCallApiRoomScheduleMemberList({
        page,
        records,
        searchStoreId: targetStore.id,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
      }),
    );
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onSearch = () => {
    const {
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  }

  handleChangePage = (_, newPage) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(
      newPage, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchMemberNo, searchMemberName, searchMemberNameKana);
  };

  handleClickSelectUser = (_, row) => {
    this.props.handleClickSelectUser(row);
  }

  render() {
    const {
      classes, onClose, open,
    } = this.props;

    const {
      members, loading, searchMemberNo, searchMemberName, searchMemberNameKana,
      page, records,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              予約者の追加
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <SearchBox onSearch={this.onSearch}>
              <Box display="flex">
                <Box mr={2} width="20%">
                  <FormTitle title="会員番号" />
                  <TextField id="search-member-no" variant="outlined" fullWidth value={searchMemberNo} name="searchMemberNo" onChange={this.onChange} />
                </Box>
                <Box mr={2} width="20%">
                  <FormTitle title="名前" />
                  <TextField id="search-name" variant="outlined" fullWidth value={searchMemberName} name="searchMemberName" onChange={this.onChange} />
                </Box>
                <Box>
                  <FormTitle title="フリガナ" />
                  <TextField id="search-name-kana" variant="outlined" fullWidth value={searchMemberNameKana} name="searchMemberNameKana" onChange={this.onChange} />
                </Box>
              </Box>
            </SearchBox>
            <RoomMemberDataTable
              rows={members.content ? members.content : []}
              page={page}
              rowsPerPage={records}
              totalElements={members.totalElements}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              dialogFlg
              onFirstFunc={this.handleClickSelectUser}
            />
          </Container>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  roomSchedule: state.roomSchedule,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(RoomMemberAddDialog));
