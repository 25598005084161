import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 画面一覧取得
 *
 * @param
 */
export function getScreens() {
  const httpConnection = new HttpConnection();
  return httpConnection
    .get(REST_API.USER_SETTING.ROLE.SCREENS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限取得
 *
 * @param
 */
export function getRole(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.USER_SETTING.ROLE.ROLE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限追加
 *
 *
 */
export function insertData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER_SETTING.ROLE.ROLES, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限更新
 *
 *
 */
export function updateData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER_SETTING.ROLE.ROLE, { id: param.roleId }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限画面更新
 *
 * @param
 */
export function updateRoleScreen(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER_SETTING.ROLE.ROLE_SCREENS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
