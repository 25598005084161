import React from 'react';
import {
  Box, Button, TextField, Checkbox,
  TableContainer, Table, TableHead,
  TableCell, TableRow, TableBody,
} from '@material-ui/core';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import Validation from './time-validation';
import { compareTime, checkTimeValidation } from '../rule-list-func';

class BoxTimeRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxes: [{
        key: 'boxes_key_1',
        boxNumber: 1,
        startTime: '',
        endTime: '',
        lessonBox: false,
      }],
      messages: [{
        startTime: '', endTime: '',
      }],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 1) {
        this.setData(this.props.golfDatas.boxList);
      }
    }
  }

  setData = (boxList) => {
    const messages = [];
    const boxes = boxList.slice().map((c, idx) => {
      messages.push({ startTime: '', endTime: '' });
      return {
        ...c,
        key: `boxes_key_${idx + 1}`,
      };
    });
    if (boxList.length === 0) {
      messages.push({ startTime: '', endTime: '' });
      boxes.push({
        key: 'boxes_key_1',
        boxNumber: null,
        startTime: '',
        endTime: '',
        lessonBox: false,
      });
    }

    this.setState({
      boxes,
      messages,
    });
  }

  onAdd = () => {
    const { boxes, messages } = this.state;
    const boxNumber = null;
    boxes.push({
      key: `boxes_key_${boxes.length + 1}`,
      boxNumber,
      startTime: '',
      endTime: '',
      lessonBox: false,
    });
    messages.push({
      startTime: '',
      endTime: '',
    });
    this.setState({ boxes, messages });
  }

  onChange = (event, index) => {
    const { boxes, messages } = this.state;
    const { name, value } = event.target;

    let tempBox = boxes[index];
    tempBox = {
      ...tempBox,
      [name]: value,
    };
    boxes[index] = tempBox;
    const message = Validation.formValidate(name, value);
    let tempMessage = { ...messages[index], [name]: message };

    if (name === 'startTime' && value && tempBox.endTime) {
      const endMessage = Validation.formValidate('endTime', tempBox.endTime);
      if (!endMessage) {
        tempMessage = compareTime(tempMessage, value, tempBox.endTime);
      }
    }
    if (name === 'endTime' && value && tempBox.startTime && !message) {
      tempMessage = compareTime(tempMessage, tempBox.startTime, value);
    }
    messages[index] = tempMessage;

    this.setState({ boxes, messages });
  }

  onCheckChange = (event, index) => {
    const { boxes, messages } = this.state;
    let tempBox = boxes[index];
    tempBox = {
      ...tempBox,
      lessonBox: event.target.checked,
    };
    boxes[index] = tempBox;
    this.setState({ boxes, messages });
  }

  onDelete = (_, index) => {
    const { boxes } = this.state;
    boxes.splice(index, 1);
    this.setState({ boxes });
  }

  onSave = (event) => {
    const { boxes, messages } = this.state;
    let isError = false;
    const tempMessages = [];
    boxes.forEach((box, index) => {
      const errObj = checkTimeValidation(box, messages[index]);
      isError = errObj.isError;
      tempMessages.push(errObj.messages);
    });
    this.setState({ messages: tempMessages });
    if (isError) {
      return;
    }
    this.props.onSave(event, { isBox: true, times: boxes });
  }

  render() {
    const { value } = this.props;
    const { boxes, messages } = this.state;

    return (
      <TabPanel value={value} index={1}>
        <Box p={2}>
          <Box textAlign="right">
            <Button color="primary" size="medium" onClick={this.onSave}>保存する</Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">打席番号</TableCell>
                  <TableCell width="30%">開始時間</TableCell>
                  <TableCell width="30%">終了時間</TableCell>
                  <TableCell width="20%">レッスン打席</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {boxes.map((box, index) => (
                  <TableRow key={box.key}>
                    <TableCell>
                      <TextField
                        type="number"
                        variant="outlined"
                        name="boxNumber"
                        value={box.boxNumber}
                        onChange={(event) => this.onChange(event, index)}
                      />
                      <FormErrorText>{messages[index].boxNumber}</FormErrorText>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="time"
                        variant="outlined"
                        name="startTime"
                        value={box.startTime}
                        onChange={(event) => this.onChange(event, index)}
                      />
                      <FormErrorText>{messages[index].startTime}</FormErrorText>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="time"
                        variant="outlined"
                        name="endTime"
                        value={box.endTime}
                        onChange={(event) => this.onChange(event, index)}
                      />
                      <FormErrorText>{messages[index].endTime}</FormErrorText>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        onClick={(event) => this.onCheckChange(event, index)}
                        checked={box.lessonBox}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="medium"
                        onClick={(event) => this.onDelete(event, index)}
                        style={{ display: (index !== 0 && boxes.length === index + 1) ? '' : 'none' }}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Button color="primary" size="medium" onClick={this.onAdd}>打席追加</Button>
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default BoxTimeRule;
