import { ACTION_TYPE } from './action';

// region Reducer
const roles = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_ROLE_LIST_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_ROLE_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_ROLE_LIST_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.SET_ROLE_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    case ACTION_TYPE.RESET_ROLE_LIST_FLG:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default roles;
