const idValidation = (value) => {
  if (!value) return '入力してください';

  return '';
};

const nameValidation = (value) => {
  if (!value) return '入力してください';

  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'memberId':
      return idValidation(value);
    case 'otherName':
      return nameValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
