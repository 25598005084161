// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_CLASS_INFOMATION: 'CALL_API_INIT_CLASS_INFOMATION',
  CALL_API_INIT_CLASS_INFOMATION_SUCCESS: 'CALL_API_INIT_CLASS_INFOMATION_SUCCESS',
  ACT_CALL_API_SEARCH_ROOMS: 'ACT_CALL_API_SEARCH_ROOMS',
  ACT_SEARCH_ROOMS_SUCCESS: 'ACT_SEARCH_ROOMS_SUCCESS',
  ACT_CALL_API_ATTEND: 'ACT_CALL_API_ATTEND',
  ACT_ATTEND_SUCCESS: 'ACT_ATTEND_SUCCESS',
  ACT_ISUPDATE_RESET: 'ACT_ISUPDATE_RESET',
};
// #endregion

// #region action
export const actCallInitClassInfomation = (param) => ({
  type: ACTION_TYPE.CALL_API_INIT_CLASS_INFOMATION,
  param,
});

export const actInitClassInfomationSuccess = (rooms) => ({
  type: ACTION_TYPE.CALL_API_INIT_CLASS_INFOMATION_SUCCESS,
  rooms,
});

export const actCallApiSearchRooms = (ids, date, storeId) => ({
  type: ACTION_TYPE.ACT_CALL_API_SEARCH_ROOMS,
  ids,
  date,
  storeId,
});

export const actSearchRoomsSuccess = (datas) => ({
  type: ACTION_TYPE.ACT_SEARCH_ROOMS_SUCCESS,
  datas,
});

export const actCallApiAttend = (ids, value, date) => ({
  type: ACTION_TYPE.ACT_CALL_API_ATTEND,
  ids,
  value,
  date,
});

export const actAttendSuccess = (result) => ({
  type: ACTION_TYPE.ACT_ATTEND_SUCCESS,
  result,
});

export const actisUpdateReset = () => ({
  type: ACTION_TYPE.ACT_ISUPDATE_RESET,
});

// #endregion
