import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年MMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

export default function Datepicker(props) {
  const {
    value,
    handleChange,
    error,
    minDate,
    maxDate,
    variant,
    disableToolbar,
    onBlur,
  } = props;
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        views={['year', 'month', 'date']}
        inputVariant="outlined"
        autoComplete="off"
        format="yyyy/MM/dd"
        value={value}
        variant={variant}
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        cancelLabel="キャンセル"
        okLabel="設定"
        clearLabel="クリア"
        todayLabel="今日"
        showTodayButton
        clearable
        autoOk
        helperText=""
        error={error}
        minDate={minDate}
        maxDate={maxDate}
        disableToolbar={disableToolbar}
        onBlur={onBlur}

      />
    </MuiPickersUtilsProvider>
  );
}
Datepicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  minDate: PropTypes.PropTypes.instanceOf(Date),
  maxDate: PropTypes.PropTypes.instanceOf(Date),
};
Datepicker.defaultProps = {
  error: false,
  minDate: new Date('1970/01/01'),
  maxDate: new Date('9999/12/31'),
};
