import isValid from 'date-fns/isValid';

const multiSelecteValidation = (value) => {
  if (value.length === 0) return '選択してください';
  return '';
};

const selecteValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const yearValidation = (value) => {
  const regex = /^([1-9]\d*|0)$/;
  if (!value || !regex.test(value)) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '無効な日付です';
  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'date':
      return dateValidation(value);
    case 'contents':
      return selecteValidation(value);
    case 'schedules':
      return multiSelecteValidation(value);
    case 'year':
      return yearValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
