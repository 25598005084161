import { call, put, takeLatest } from 'redux-saga/effects';
import {
  searchRoomBookings, createRoomBookings, getCalendarHeaderRoom,
} from '../../../services/event/eventCalendarDetailRoom.service';
import {
  ACTION_TYPE,
  actCallApiCalendarRoomDetailSuccess,
  actCallApiCalendarScheduleRoomCreateSuccess,
  actCallApiCalendarHeaderRoomSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 予約ヘッダー検索
 */
function* callApiCalendarRoomHeader(param) {
  try {
    const header = yield call(getCalendarHeaderRoom, param.param.headerId);
    yield put(actCallApiCalendarHeaderRoomSuccess(header));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 予約枠詳細検索
 */
function* callApiCalendarDetailRoom({ searchCondition }) {
  try {
    const bookingDatas = yield call(searchRoomBookings, searchCondition);
    yield put(actCallApiCalendarRoomDetailSuccess(bookingDatas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会議室予約枠作成
 * @param param0
 */
function* callApiCalendarScheduleRoomCreate({ param }) {
  try {
    yield call(createRoomBookings, param);
    yield put(actCallApiCalendarScheduleRoomCreateSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCalendarDetailRoom() {
  yield takeLatest(
    ACTION_TYPE.CALL_API_GET_CALENDAR_HEADER_ROOM, callApiCalendarRoomHeader,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_ROOM_DETAIL,
    callApiCalendarDetailRoom,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_ROOM_CREATE,
    callApiCalendarScheduleRoomCreate,
  );
}
