// #region Type
export const ACTION_TYPE = {
  CALL_API_CALENDAR_SCHEDULE_SEARCH: 'CALL_API_CALENDAR_SCHEDULE_SEARCH',
  CALL_API_CALENDAR_SCHEDULE_SEARCH_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_SEARCH_SUCCESS',
  CALL_API_CALENDAR_SCHEDULE_SAVE: 'CALL_API_CALENDAR_SCHEDULE_SAVE',
  CALL_API_CALENDAR_SCHEDULE_SAVE_SUCCESS: 'CALL_API_CALENDAR_SCHEDULE_SAVE_SUCCESS',
  CALL_MENU_SAVE_FLG_RESET: 'CALL_MENU_SAVE_FLG_RESET',
};
// #endregion

// #region action
export const actCallResetMenuSaveFlg = () => ({
  type: ACTION_TYPE.CALL_MENU_SAVE_FLG_RESET,
});

export const actCallApiCalendarScheduleSearch = (contentId, id) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SEARCH,
  contentId,
  id,
});

export const actCallApiCalendarScheduleSearchSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SEARCH_SUCCESS,
  data,
});

export const actCallApiCalendarScheduleSave = (contentId, data) => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SAVE,
  contentId,
  data,
});

export const actCallApiCalendarScheduleSaveSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SAVE_SUCCESS,
});

// #endregion
