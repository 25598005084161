// region Type
export const ACTION_TYPE = {
  CALL_API_BANNER_IMAGE_LIST: 'CALL_API_BANNER_IMAGE_LIST',
  BANNER_IMAGE_LIST_SUCCESS: 'BANNER_IMAGE_LIST_SUCCESS',
  CALL_API_MAIN_BANNER_CREATE: 'CALL_API_MAIN_BANNER_CREATE',
  CALL_API_MAIN_BANNER_UPDATE: 'CALL_API_MAIN_BANNER_UPDATE',
  BANNER_UPLOAD_SUCCESS: 'BANNER_CREATE_SUCCESS',
  CALL_API_BANNER_DELETE: 'CALL_API_BANNER_DELETE',
  BANNER_DELETE_SUCCESS: 'BANNER_DELETE_SUCCESS',
  CALL_API_BANNER_RELEASE: 'CALL_API_BANNER_RELEASE',
  BANNER_RELEASE_SUCCESS: 'BANNER_RELEASE_SUCCESS',
  BANNER_FLG_RESET: 'BANNER_FLG_RESET',
};
// endregion

// region action
export const actCallApiBannerImageList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_BANNER_IMAGE_LIST,
  searchCondition,
});

export const actBannerImageListSuccess = (datas) => ({
  type: ACTION_TYPE.BANNER_IMAGE_LIST_SUCCESS,
  datas,
});

export const actCallApiMainBannerCreate = (data) => ({
  type: ACTION_TYPE.CALL_API_MAIN_BANNER_CREATE,
  data,
});

export const actCallApiMainBannerUpdate = (id, data) => ({
  type: ACTION_TYPE.CALL_API_MAIN_BANNER_UPDATE,
  id,
  data,
});

export const actBannerUploadSuccess = (datas) => ({
  type: ACTION_TYPE.BANNER_UPLOAD_SUCCESS,
  datas,
});

export const actCallApiBannerDelete = (deleteList) => ({
  type: ACTION_TYPE.CALL_API_BANNER_DELETE,
  deleteList,
});

export const actBannerDeleteSuccess = () => ({
  type: ACTION_TYPE.BANNER_DELETE_SUCCESS,
});

export const actCallApiBannerRelease = (id) => ({
  type: ACTION_TYPE.CALL_API_BANNER_RELEASE,
  id,
});

export const actBannerReleaseSuccess = (datas) => ({
  type: ACTION_TYPE.BANNER_RELEASE_SUCCESS,
  datas,
});

export const actBannerFlgReset = () => ({
  type: ACTION_TYPE.BANNER_FLG_RESET,
});
// endregion
