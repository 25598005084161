// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_MEMBER_DETAIL: 'CALL_API_GET_MEMBER_DETAIL',
  CALL_API_GET_MEMBER_DETAIL_SUCCESS: 'CALL_API_GET_MEMBER_DETAIL_SUCCESS',
  CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO: 'CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO',
  CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO_SUCCESS: 'CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO_SUCCESS',
  CALL_API_UPDATE_MEMBER_DETAIL: 'CALL_API_UPDATE_MEMBER_DETAIL',
  CALL_API_UPDATE_MEMBER_DETAIL_SUCCESS: 'CALL_API_UPDATE_MEMBER_DETAIL_SUCCESS',
  CALL_API_GET_PRODUCT_LIST: 'CALL_API_GET_PRODUCT_LIST',
  CALL_API_GET_PRODUCT_LIST_SUCCESS: 'CALL_API_GET_PRODUCT_LIST_SUCCESS',
  CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS: 'CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS',
  CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS_SUCCESS: 'CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS_SUCCESS',
  CALL_API_CONTRACT_INSERT: 'CALL_API_CONTRACT_INSERT',
  CALL_API_CONTRACT_INSERT_SUCCESS: 'CALL_API_CONTRACT_INSERT_SUCCESS',
  CALL_API_CONTRACT_DELETE: 'CALL_API_CONTRACT_DELETE',
  CALL_API_CONTRACT_DELETE_SUCCESS: 'CALL_API_CONTRACT_DELETE_SUCCESS',
  CALL_API_MEMBER_WITHDRAWAL: 'CALL_API_MEMBER_WITHDRAWAL',
  CALL_API_MEMBER_WITHDRAWAL_SUCCESS: 'CALL_API_MEMBER_WITHDRAWAL_SUCCESS',
  CALL_ACT_RESET_CONTRACT_FLG: 'CALL_ACT_RESET_CONTRACT_FLG',
};
  // #endregion

// #region Type
export const actSearchMember = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL,
  searchCondition,
});

export const actSearchMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_SUCCESS,
  data,
});

export const actCheckMemberNo = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO,
  searchCondition,
});

export const actCheckMemberNoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_DETAIL_CHECK_MEMBER_NO_SUCCESS,
  data,
});

export const actUpdateMember = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL,
  param,
});

export const actUpdateMemberSuccess = () => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_SUCCESS,
});

export const actGetProductList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_PRODUCT_LIST,
  searchCondition,
});

export const actGetProductListSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_PRODUCT_LIST_SUCCESS,
  data,
});

export const getZipCodeAddress = ({ zipcode }) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS,
  zipcode,
});
export const getZipCodeAddressSuccess = (zipcode) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_CORRECT_GET_ZIP_CODE_ADDRESS_SUCCESS,
  zipcode,
});

export const actContractInsert = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_INSERT,
  searchCondition,
});

export const actContractInsertSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_INSERT_SUCCESS,
  data,
});

export const actContractDelete = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_DELETE,
  searchCondition,
});

export const actContractDeleteSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_DELETE_SUCCESS,
  data,
});

export const actMemberWithdrawal = (param) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_WITHDRAWAL,
  param,
});

export const actMemberWithdrawalSuccess = () => ({
  type: ACTION_TYPE.CALL_API_MEMBER_WITHDRAWAL_SUCCESS,
});

export const actResetContractFlg = () => ({
  type: ACTION_TYPE.CALL_ACT_RESET_CONTRACT_FLG,
});
  // #endregion
