import isValid from 'date-fns/isValid';

const selectValidation = (value) => {
  if (!value) return '選択してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '無効な日付です';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'searchStore':
      case 'targetStore':
        return selectValidation(value);
      case 'searchMonth':
      case 'targetDate':
        return dateValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
