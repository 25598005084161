import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '16px',
  },
  searchTitle: {
    minHeight: '0px!v',
  },
  heading: {
    margin: theme.spacing(0),
  },
  accordionAction: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  detail: {
    display: 'block',
  },
}));

export default function SearchBox(props) {
  const classes = useStyles();

  const {
    children,
    onSearch,
    onSecondFunc,
    onSecondFuncText,
  } = props;

  return (
    <Accordion defaultExpanded className={classes.root}>
      <AccordionSummary
        className={classes.searchTitle}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <Box fontWeight="fontWeightBold" m={1} fontSize={17} className={classes.heading}>検索条件</Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.detail}>
        {children}
      </AccordionDetails>
      <AccordionActions className={classes.accordionAction}>
        {
          onSecondFuncText && onSecondFunc && (
            <Button onClick={onSecondFunc} variant="contained" color="default" size="large">{onSecondFuncText}</Button>
          )
        }
        <Button onClick={onSearch} variant="contained" color="primary" size="large">検索する</Button>
      </AccordionActions>
    </Accordion>
  );
}

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onSecondFunc: PropTypes.func,
  onSecondFuncText: PropTypes.string,
};

SearchBox.defaultProps = {
  onSecondFunc: null,
  onSecondFuncText: null,
};
