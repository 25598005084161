import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Dialog, Box, withStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, TextField, LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import MemberLinkageDataTable from './MemberLinkageDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { actCallApiLinkageDialogMemberList, actCallApiInsertMemberLinkage, actApiMemberLinkageFlgReset } from '../../../redux/school/memberLinkage/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const styles = () => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class MemberLinkageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: {},
      loading: true,
      changeLoading: false,
      searchMemberNo: '',
      searchMemberName: '',
      searchMemberNameKana: '',
      page: 0,
      records: 10,
      addMemberIds: [],
      successSnackOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.memberLinkage.members !== this.props.memberLinkage.members) {
      if (this.props.memberLinkage.members) {
        this.setDatas(this.props.memberLinkage.members);
      }
    }
    if (prevProps.memberLinkage.isInsertSuccess !== this.props.memberLinkage.isInsertSuccess) {
      if (this.props.memberLinkage.isInsertSuccess) {
        this.setNewMemberDatas();
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  setDatas(members) {
    this.setState({ members });
  }

  setNewMemberDatas() {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, page, records,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
    this.setState({ successSnackOpen: true, addMemberIds: [] });
  }

  init = () => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, page, records,
    } = this.state;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
    this.setState({
      loading: false,
      successSnackOpen: false,
      addMemberIds: [],
      changeLoading: false,
    });
  }

  search = (
    page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
  ) => {
    this.props.dispatch(
      actCallApiLinkageDialogMemberList({
        page,
        records,
        searchStoreId: this.props.storeId,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
        detailId: this.props.detailId,
      }),
    );
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  setCheckedMember = (value) => {
    this.setState({ addMemberIds: value });
  }

  handleSave = () => {
    const { addMemberIds } = this.state;
    const { detailId } = this.props;
    this.setState({ successSnackOpen: false, changeLoading: true });
    this.props.dispatch(actApiMemberLinkageFlgReset());
    this.props.dispatch(actCallApiInsertMemberLinkage({ detailId, addMemberIds }));
  }

  onSearch = () => {
    const {
      records, searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    const page = 0;
    this.search(
      page, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
    this.setState({ page });
  }

  handleChangePage = (_, newPage) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(
      newPage, records, searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchMemberNo, searchMemberName, searchMemberNameKana);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  render() {
    const {
      classes, onClose, open, dialogCapacity, LinkMemberCount,
    } = this.props;

    const {
      members, loading, searchMemberNo, searchMemberName, searchMemberNameKana,
      page, records, addMemberIds, successSnackOpen, changeLoading,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              会員の追加
            </Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Container>
            <SearchBox onSearch={this.onSearch}>
              <Box display="flex">
                <Box width="20%" mr={2}>
                  <FormTitle title="会員番号" />
                  <TextField id="search-member-no" variant="outlined" fullWidth value={searchMemberNo} name="searchMemberNo" onChange={this.onChange} />
                </Box>
                <Box width="20%" mr={2}>
                  <FormTitle title="名前" />
                  <TextField id="search-name" variant="outlined" fullWidth value={searchMemberName} name="searchMemberName" onChange={this.onChange} />
                </Box>
                <Box width="20%">
                  <FormTitle title="フリガナ" />
                  <TextField id="search-name-kana" variant="outlined" fullWidth value={searchMemberNameKana} name="searchMemberNameKana" onChange={this.onChange} />
                </Box>
              </Box>
            </SearchBox>
            <MemberLinkageDataTable
              rows={members.content ? members.content : []}
              addButtonLabel=""
              deleteButtonLabel=""
              page={page}
              rowsPerPage={records}
              totalElements={members.totalElements}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              deleteDialogTitle=""
              deleteDialogContent=""
              dialogFlg
              onChecked={this.setCheckedMember}
            />
            <SuccessSnackbar
              open={successSnackOpen}
              handleClose={this.handleSuccessSnackbarClose}
              message="会員紐付けの追加が完了しました"
            />
          </Container>
        </DialogContent>
        <DialogActions style={{ display: loading ? 'none' : '' }}>
          <LoadingButton loading={changeLoading} onClick={this.handleSave} label="選択した会員を追加する" disabled={addMemberIds.length <= 0 || dialogCapacity < (addMemberIds.length + LinkMemberCount)} />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memberLinkage: state.memberLinkage,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(MemberLinkageDialog));
