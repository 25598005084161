import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatas, updateContacted, getSelects } from '../../../services/info/teacherContactTaskList.service';

import {
  ACTION_TYPE,
  actCallTeacherContactSearchListSuccess,
  actCallInitTeacherContactSelectListSuccess,
  actCallTeacherContactTaskContactedSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 */
function* callInitApiTeacherContactSelectList() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallInitTeacherContactSelectListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索
 */
function* callApiTeacherContactSearchList({ param }) {
  try {
    const datas = yield call(getDatas, param);
    yield put(actCallTeacherContactSearchListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 連絡済み更新
 */
function* callApiContacted({ params }) {
  try {
    yield call(updateContacted, { ids: params.ids });
    yield put(actCallTeacherContactTaskContactedSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallHealthCheck() {
  yield takeLatest(ACTION_TYPE.CALL_INIT_TEACHER_CONTACT_SELECT_LIST,
    callInitApiTeacherContactSelectList);
  yield takeLatest(ACTION_TYPE.CALL_API_TEACHER_CONTACT_SEARCH_LIST,
    callApiTeacherContactSearchList);
  yield takeLatest(ACTION_TYPE.CALL_TEACHER_CONTACT_TASK_CONTACTED,
    callApiContacted);
}
