const emailValidation = (email) => {
  if (!email) return '入力してください';
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!regex.test(email)) return '正しい形式でメールアドレスを入力してください';
  return '';
};

const passwordValidation = (password) => {
  if (!password) return '入力してください';
  const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
  if (!regex.test(password)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
  return '';
};

const nameValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const roleValidation = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'email':
        return emailValidation(value);
      case 'password':
        return passwordValidation(value);
      case 'firstName':
      case 'lastName':
        return nameValidation(value);
      case 'mstRole':
        return roleValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
