import { combineReducers } from 'redux';

import common from './common/common/reducer';
import headerChangePassword from './common/headerChangePassword/reducer';

import users from './other/login/reducer';
import changePassword from './other/changePassword/reducer';
import home from './other/home/reducer';

import weekScheduleMenu from './share/weekScheduleMenu/reducer';
import calendarScheduleDetail from './share/calendarScheduleDetail/reducer';
import calendarScheduleMenu from './share/calendarScheduleMenu/reducer';

import systemUsers from './userSetting/systemUserList/reducer';
import systemUserDetail from './userSetting/systemUserDetail/reducer';
import roles from './userSetting/roleList/reducer';
import roleDetail from './userSetting/roleDetail/reducer';

import rules from './setting/rule/reducer';
import bannerImages from './setting/bannerImage/reducer';
import mails from './setting/mailList/reducer';
import mailDetail from './setting/mailDetail/reducer';
import siteSetting from './setting/siteSetting/reducer';
import surveySetting from './setting/surveySetting/reducer';
import holiday from './setting/holiday/reducer';

import healthChecks from './info/healthCheck/reducer';
import classInfomation from './info/classInfomation/reducer';
import teacherContactTaskList from './info/teacherContactTaskList/reducer';
import attendanceEntry from './info/attendanceEntry/reducer';

import members from './member/memberList/reducer';
import entry from './member/entry/reducer';
import memberDetail from './member/memberDetail/reducer';

import eventWeekScheduleList from './event/eventWeekScheduleList/reducer';
import eventWeekScheduleDetail from './event/eventWeekScheduleDetail/reducer';
import eventCalendarList from './event/eventCalendarList/reducer';
import calendarDetailRoom from './event/eventCalendarDetailRoom/reducer';
import calendarDetailGolf from './event/eventCalendarDetailGolf/reducer';

import schoolWeekSchedules from './school/schoolWeekScheduleList/reducer';
import schoolWeekScheduleDetail from './school/schoolWeekScheduleDetail/reducer';
import schoolCalendarList from './school/schoolCalenderList/reducer';
import memberLinkage from './school/memberLinkage/reducer';
import weekSelect from './school/weekSelect/reducer';

import booking from './booking/bookingList/reducer';
import bookingDetail from './booking/bookingDetail/reducer';
import subscriber from './booking/subscriberList/reducer';
import roomSchedule from './booking/roomSchedule/reducer';
import golfSchedule from './booking/golfSchedule/reducer';

import teachers from './master/teacherList/reducer';
import notices from './master/notice/reducer';

const appReducer = combineReducers({
  common,
  headerChangePassword,

  users,
  changePassword,
  home,

  weekScheduleMenu,
  calendarScheduleDetail,
  calendarScheduleMenu,

  systemUsers,
  systemUserDetail,
  roles,
  roleDetail,

  rules,
  bannerImages,
  mails,
  mailDetail,
  siteSetting,
  surveySetting,
  holiday,

  healthChecks,
  classInfomation,
  teacherContactTaskList,
  attendanceEntry,

  members,
  entry,
  memberDetail,

  eventWeekScheduleList,
  eventWeekScheduleDetail,
  eventCalendarList,
  calendarDetailRoom,
  calendarDetailGolf,

  schoolWeekSchedules,
  schoolWeekScheduleDetail,
  schoolCalendarList,
  memberLinkage,
  weekSelect,

  booking,
  bookingDetail,
  subscriber,
  roomSchedule,
  golfSchedule,

  teachers,
  notices,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
