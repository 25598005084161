import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';

export default function FormErrorText(props) {
  const { children } = props;

  return (
    <FormHelperText error>{children}</FormHelperText>
  );
}

FormErrorText.propTypes = {
  children: PropTypes.node,
};

FormErrorText.defaultProps = {
  children: null,
};
