import { ACTION_TYPE } from './action';

// region Reducer
const schoolCalendarList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_SCHOOL_CALENDAR_LIST_SUCCESS:
      return {
        ...state,
        searchSelects: action.searchSelects,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        weekSchedules: action.weekSchedules,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK_SUCCESS:
      return {
        ...state,
        isCheckSuccess: true,
        resetFlg: action.resetFlg,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_RESET_SUCCESS:
      return {
        ...state,
        deletedFlg: true,
      };

    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        modalData: action.modalData,
        isGetModalSuccess: true,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_CREATE_SUCCESS:
      return {
        ...state,
        resultDatasId: action.resultDatasId,
      };
    case ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_FLG_RESET:
      return {
        ...state,
        deletedFlg: false,
        checkedFlg: false,
        isCheckSuccess: false,
        isGetModalSuccess: false,
      };
    case ACTION_TYPE.SET_SEARCH_SCHOOL_CALENDAR_SCHEDULE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    default:
      return state;
  }
};

export default schoolCalendarList;
