import React from 'react';
import { Card, Button, Box } from '@material-ui/core';

export default function WeekBookingCard(props) {
  const {
    menuInfo, onMenuUpdateDialog, schoolFlg, onMemberLinkage,
  } = props;

  const setWeeklyNumber = (weeklyNumber) => {
    const tempWeekly = weeklyNumber.split(',');
    if (tempWeekly.length === 5) {
      return '毎週';
    }
    return `第 ${weeklyNumber.replaceAll(',', '·')}`;
  };

  return (
    <Box mt={2}>
      <Card>
        <Box alignItems="center" display="flex" bgcolor={menuInfo.menuColor} p={1} style={{ wordBreak: 'break-all' }}>
          <Box width="35%" justifyContent="center" alignItems="center" display="flex" bgcolor="#fff">
            <Box fontWeight="fontWeightBold" m={0} fontSize={15} align="center">
              {schoolFlg ? (
                <Box width="100%" mt={1} mb={1}>
                  {menuInfo.capacity}
                  名
                </Box>
              ) : (
                <Box width="100%" mt={1} mb={1}>
                  <Box>
                    {setWeeklyNumber(menuInfo.weeklyNumber)}
                  </Box>
                  <Box>
                    {menuInfo.capacity}
                    名
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box width="65%" align="center" p={1} pb={0} pt={0} style={{ wordBreak: 'break-all' }}>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>{menuInfo.menuName}</Box>
            <Box fontWeight="fontWeightBold" m={0} fontSize={15}>
              {menuInfo.startTime}
              ~
              {menuInfo.endTime}
            </Box>
          </Box>
        </Box>
        <Box p={1} pt={0} display="flex" bgcolor={menuInfo.menuColor} justifyContent="flex-end">
          <Box m={0.3} mt={0}>
            {schoolFlg && <Button size="small" variant="contained" color="primary" onClick={(event) => onMemberLinkage(event, menuInfo.id)}>会員紐付け</Button>}
          </Box>
          <Box m={0.3} mt={0}>
            <Button size="small" variant="contained" color="primary" onClick={(event) => onMenuUpdateDialog(event, menuInfo.id)}>変更</Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
