import { ACTION_TYPE } from './action';

const calendarScheduleMenu = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_MENU_SAVE_FLG_RESET:
      return {
        ...state,
        isSaved: false,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_SCHEDULE_SAVE_SUCCESS:
      return {
        ...state,
        isSaved: true,
      };
    default:
      return state;
  }
};

export default calendarScheduleMenu;
