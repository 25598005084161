// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_SCHOOL_CALENDAR_LIST: 'CALL_API_INIT_SCHOOL_CALENDAR_LIST',
  CALL_API_INIT_SCHOOL_CALENDAR_LIST_SUCCESS: 'CALL_API_INIT_SCHOOL_CALENDAR_LIST_SUCCESS',
  CALL_API_SCHOOL_CALENDAR_LIST_SEARCH: 'CALL_API_SCHOOL_CALENDAR_LIST_SEARCH',
  CALL_API_SCHOOL_CALENDAR_LIST_SEARCH_SUCCESS: 'CALL_API_SCHOOL_CALENDAR_LIST_SEARCH_SUCCESS',
  CALL_API_SCHOOL_CALENDAR_LIST_FLG_RESET: 'CALL_API_SCHOOL_CALENDAR_LIST_FLG_RESET',
  CALL_API_SCHOOL_CALENDAR_LIST_RESET: 'CALL_API_SCHOOL_CALENDAR_LIST_RESET',
  CALL_API_SCHOOL_CALENDAR_LIST_RESET_SUCCESS: 'CALL_API_SCHOOL_CALENDAR_LIST_RESET_SUCCESS',
  CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK: 'CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK',
  CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK_SUCCESS: 'CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK_SUCCESS',
  CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA: 'CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA',
  CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA_SUCCESS: 'CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA_SUCCESS',
  CALL_API_SCHOOL_CALENDAR_LIST_CREATE: 'CALL_API_SCHOOL_CALENDAR_LIST_CREATE',
  CALL_API_SCHOOL_CALENDAR_LIST_CREATE_SUCCESS: 'CALL_API_SCHOOL_CALENDAR_LIST_CREATE_SUCCESS',
  SET_SEARCH_SCHOOL_CALENDAR_SCHEDULE: 'SET_SEARCH_SCHOOL_CALENDAR_SCHEDULE',
};
// #endregion

// #region action

export const actCallApiInitSchoolCalendarList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SCHOOL_CALENDAR_LIST,
});

export const actCallApiInitSchoolCalendarListSuccess = (searchSelects) => ({
  type: ACTION_TYPE.CALL_API_INIT_SCHOOL_CALENDAR_LIST_SUCCESS,
  searchSelects,
});

export const actCallApiSchoolCalendarListSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_SEARCH,
  searchCondition,
});

export const actCallApiSchoolCalendarListSearchSuccess = (datas, weekSchedules) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_SEARCH_SUCCESS,
  datas,
  weekSchedules,
});

export const actCallApiSchoolCalendarListExperiencerCheck = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK,
  searchCondition,
});

export const actCallApiSchoolCalendarListExperiencerCheckSuccess = (resetFlg) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK_SUCCESS,
  resetFlg,
});

export const actCallApiSchoolCalendarListReset = (resetCondition) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_RESET,
  resetCondition,
});

export const actCallApiSchoolCalendarListResetSuccess = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_RESET_SUCCESS,
});

export const actCallApiSchoolCalendarListModalData = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA,
  searchCondition,
});

export const actCallApiSchoolCalendarListModalDataSuccess = (modalData) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_MODAL_DATA_SUCCESS,
  modalData,
});

export const actCallApiSchoolCalendarListCreate = (params) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_CREATE,
  params,
});

export const actCallApiSchoolCalendarListCreateSuccess = (resultDatasId) => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_CREATE_SUCCESS,
  resultDatasId,
});

export const actCallApiSchoolCalendarListFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_SCHOOL_CALENDAR_LIST_FLG_RESET,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_SEARCH_SCHOOL_CALENDAR_SCHEDULE,
  searchCondition,
});
// #endregion
