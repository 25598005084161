// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_MAIL_LIST: 'CALL_API_INIT_MAIL_LIST',
  INIT_MAIL_LIST_SUCCESS: 'INIT_MAIL_LIST_SUCCESS',
  CALL_API_MAIL_LIST: 'CALL_API_MAIL_LIST',
  CALL_API_MAIL_LIST_SUCCESS: 'CALL_API_MAIL_LIST_SUCCESS',
  CHANGE_MAIL_STOP_FLG: 'CHANGE_MAIL_STOP_FLG',
  CHANGE_MAIL_STOP_FLG_SUCCESS: 'CHANGE_MAIL_STOP_FLG_SUCCESS',
  SET_SEARCH_MAILS: 'SET_SEARCH_MAILS',
  SET_CHANGE_MAIL_STOP_FLG_RESET: 'SET_CHANGE_MAIL_STOP_FLG_RESET',
};
// #endregion

// #region Type
export const actCallInitMailList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_MAIL_LIST,
});

export const actInitMailListSuccess = (stores) => ({
  type: ACTION_TYPE.INIT_MAIL_LIST_SUCCESS,
  stores,
});

export const actCallApiMailList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MAIL_LIST,
  storeId: searchCondition.searchStoreId,
});

export const actCallApiMailListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MAIL_LIST_SUCCESS,
  datas,
});

export const actChangeMailStopFlg = (id) => ({
  type: ACTION_TYPE.CHANGE_MAIL_STOP_FLG,
  id,
});

export const actChangeMailStopFlgSuccess = () => ({
  type: ACTION_TYPE.CHANGE_MAIL_STOP_FLG_SUCCESS,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_SEARCH_MAILS,
  searchCondition,
});

export const actChangeMailStopFlgReset = () => ({
  type: ACTION_TYPE.SET_CHANGE_MAIL_STOP_FLG_RESET,
});
// #endregion
