export const ACTION_TYPE = {
  CALL_API_INIT_MEMBER_LINKAGE: 'CALL_API_INIT_MEMBER_LINKAGE',
  INIT_MEMBER_LINKAGE_SUCCESS: 'INIT_MEMBER_LINKAGE_SUCCESS',
  CALL_API_MEMBER_LINKAGE_LIST: 'CALL_API_MEMBER_LINKAGE_LIST',
  CALL_API_MEMBER_LINKAGE_LIST_SUCCESS: 'CALL_API_MEMBER_LINKAGE_LIST_SUCCESS',
  CALL_API_MEMBER_LINKAGE_DELETE: 'CALL_API_MEMBER_LINKAGE_DELETE',
  CALL_API_MEMBER_LINKAGE_DELETE_SUCCESS: 'CALL_API_MEMBER_LINKAGE_DELETE_SUCCESS',
  CALL_API_LINKAGE_DIALOG_MEMBER_LIST: 'CALL_API_LINKAGE_DIALOG_MEMBER_LIST',
  CALL_API_LINKAGE_DIALOG_MEMBER_LIST_SUCCESS: 'CALL_API_LINKAGE_DIALOG_MEMBER_LIST_SUCCESS',
  CALL_API_INSERT_MEMBER_LINKAGE: 'CALL_API_INSERT_MEMBER_LINKAGE',
  CALL_API_INSERT_MEMBER_LINKAGE_SUCCESS: 'CALL_API_INSERT_MEMBER_LINKAGE_SUCCESS',
  CALL_API_MEMBER_LINKAGE_FLG_RESET: 'CALL_API_MEMBER_LINKAGE_FLG_RESET',
};

export const actCallInitMemberLinkage = (id) => ({
  type: ACTION_TYPE.CALL_API_INIT_MEMBER_LINKAGE,
  id: id.id,
});

export const actInitMemberLinkageSuccess = (infoData) => ({
  type: ACTION_TYPE.INIT_MEMBER_LINKAGE_SUCCESS,
  infoData,
});

export const actCallApiMemberLinkageList = (param) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LINKAGE_LIST,
  param,
});

export const actCallApiMemberLinkageListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LINKAGE_LIST_SUCCESS,
  datas,
});

export const actCallApiMemberLinkageDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LINKAGE_DELETE,
  values,
});

export const actCallApiMemberLinkageDeleteSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LINKAGE_DELETE_SUCCESS,
  datas,
});

export const actCallApiLinkageDialogMemberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_LINKAGE_DIALOG_MEMBER_LIST,
  searchCondition,
});

export const actCallApiLinkageDialogMemberListSuccess = (members) => ({
  type: ACTION_TYPE.CALL_API_LINKAGE_DIALOG_MEMBER_LIST_SUCCESS,
  members,
});

export const actCallApiInsertMemberLinkage = (values) => ({
  type: ACTION_TYPE.CALL_API_INSERT_MEMBER_LINKAGE,
  values,
});

export const actCallApiInsertMemberLinkageSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_INSERT_MEMBER_LINKAGE_SUCCESS,
  datas,
});

export const actApiMemberLinkageFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_MEMBER_LINKAGE_FLG_RESET,
});
