import { call, put, takeLatest } from 'redux-saga/effects';
import { getMailDetail, updateMailDetail } from '../../../services/setting/mailDetail.service';
import {
  ACTION_TYPE,
  actInitMailDetailSuccess, actUpdateMailDetailSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiInitMailDetail({ id }) {
  try {
    const data = yield call(getMailDetail, id);
    yield put(actInitMailDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メール詳細更新
 *
 * @param
 */
function* callApiUpdateMailDetail({ id, title, contents }) {
  try {
    const data = yield call(updateMailDetail, { id, title, contents });
    yield put(actUpdateMailDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMailDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_MAIL_DETAIL, callApiInitMailDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_MAIL_DETAIL, callApiUpdateMailDetail);
}
