import React from 'react';
import MUIEditor, { MUIEditorState } from 'react-mui-draft-wysiwyg';

export default function Wysiwyg(props) {
  const { value, onChange, name } = props;
  const editorConfig = {};
  return (
    <MUIEditor
      editorState={value}
      onChange={(state) => onChange({ target: { name, value: state } })}
      localization={{ locale: 'ja' }}
      config={editorConfig}
    />
  );
}

Wysiwyg.defaultProps = {
  value: MUIEditorState.createEmpty(),
};
