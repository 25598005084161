import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import { isValid, format } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import {
  actCallApiCalendarScheduleFlgReset, actCallApiCalendarScheduleUpdate,
} from '../../../redux/share/calendarScheduleDetail/action';
import { actCallGolfBookingResetFlg, actCallApiUpdateGolfPublishDate } from '../../../redux/event/eventCalendarDetailGolf/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import DateTimePicker from '../../../components/atoms/dateTimePicker/DateTimePicker';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

const objectVal = {
  messages: {
    publishedDate: '',
    releaseStartDate: '',
    releaseEndDate: '',
  },
  loading: false,
  publishedDate: null,
  releaseStartDate: null,
  releaseEndDate: null,
};

class CalendarSchedulePublishDialog extends Component {
  constructor(props) {
    super(props);

    this.state = objectVal;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
  }

  setChangeLoading(loading) {
    this.setState({ loading });
  }

  onChange = (value, name) => {
    const { messages } = this.state;
    this.setState({
      [name]: value,
      messages: {
        ...messages,
        [name]: this.dateValidationCheck(value),
      },
    });
  }

  handleHeaderUpdate = () => {
    const { headerId, contentId } = this.props;
    const { publishedDate, releaseStartDate, releaseEndDate } = this.state;
    const publishMsg = this.dateValidationCheck(publishedDate);
    const startMsg = this.dateValidationCheck(releaseStartDate);
    const endMsg = this.dateValidationCheck(releaseEndDate);
    this.setState({
      messages: {
        publishedDate: publishMsg,
        releaseStartDate: startMsg,
        releaseEndDate: endMsg,
      },
    });
    if (startMsg || startMsg || endMsg) {
      return;
    }

    if (contentId !== 2) {
      this.props.dispatch(actCallApiCalendarScheduleFlgReset());
      this.props.dispatch(actCallApiCalendarScheduleUpdate({
        headerId,
        publishedOn: publishedDate,
        releaseStartDate,
        releaseEndDate,
      }));
    }
    if (contentId === 2) {
      this.props.dispatch(actCallGolfBookingResetFlg());
      this.props.dispatch(actCallApiUpdateGolfPublishDate({
        headerId,
        publishedOn: publishedDate,
        releaseStartDate,
        releaseEndDate,
      }));
    }
  }

  dateValidationCheck(publishedDate) {
    const { publishedOn } = this.props;
    if (!publishedDate) return '入力してください';
    if (!isValid(new Date(publishedDate))) return '無効な日付です';
    if (publishedOn === format(new Date(publishedDate), 'yyyy年MM月dd日')) return '変更がありません';
    return '';
  }

  init() {
    this.setState(objectVal);
  }

  render() {
    const {
      open, onClose,
    } = this.props;

    const {
      messages, loading, publishedDate, releaseStartDate, releaseEndDate,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={onClose}>
          <DialogTitle>公開日の変更</DialogTitle>
          <DialogContent dividers>
            <Box mb={3}>
              <FormTitle title="変更対象日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker value={releaseStartDate} handleChange={(dateValue, value) => this.onChange(value, 'releaseStartDate')} error={messages.releaseStartDate !== ''} />
                  <FormErrorText>{messages.releaseStartDate}</FormErrorText>
                </Box>
                <Box mr={4} ml={4}>～</Box>
                <Box>
                  <Datepicker value={releaseEndDate} handleChange={(dateValue, value) => this.onChange(value, 'releaseEndDate')} error={messages.releaseEndDate !== ''} />
                  <FormErrorText>{messages.releaseStartDate}</FormErrorText>
                </Box>
              </Box>
            </Box>
            <Box mb={3}>
              <FormTitle title="変更後公開日" isRequired />
              <DateTimePicker
                id="date-picker-published-date"
                handleChange={(dateValue, value) => this.onChange(value, 'publishedDate')}
                formatter="yyyy/MM/dd"
                value={publishedDate}
                error={messages.publishedDate !== ''}
              />
              <FormErrorText>{messages.publishedDate}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              キャンセル
            </Button>
            <LoadingButton
              onClick={this.handleHeaderUpdate}
              label="変更する"
              loading={loading}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(CalendarSchedulePublishDialog));
