import { ACTION_TYPE } from './action';

const mailDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_MAIL_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isError: false,
      };
    case ACTION_TYPE.UPDATE_MAIL_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isUpdateSuccess: true,
        isError: false,
      };
    case ACTION_TYPE.CALL_UPDATE_MAIL_FLG_RESET:
      return {
        ...state,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};

export default mailDetail;
