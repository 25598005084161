// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_SUBSCRIBER_SEARCH_LIST: 'CALL_API_INIT_SUBSCRIBER_SEARCH_LIST',
  INIT_SUBSCRIBER_SEARCH_LIST_SUCCESS: 'INIT_SUBSCRIBER_SEARCH_LIST_SUCCESS',
  CALL_API_SUBSCRIBER_LIST: 'CALL_API_SUBSCRIBER_LIST',
  CALL_API_SUBSCRIBER_LIST_SUCCESS: 'CALL_API_SUBSCRIBER_LIST_SUCCESS',
  CALL_API_UPDATE_SUBSCRIBER_STATUS: 'CALL_API_UPDATE_SUBSCRIBER_STATUS',
  CALL_API_UPDATE_SUBSCRIBER_STATUS_SUCCESS: 'CALL_API_UPDATE_SUBSCRIBER_STATUS_SUCCESS',
  CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED: 'CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED',
  CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED_SUCCESS: 'CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED_SUCCESS',
  RESET_UPDATE_SUCCESS_FLG: 'RESET_UPDATE_SUCCESS_FLG',
  CALL_API_SUBSCRIBER_DETAIL: 'CALL_API_SUBSCRIBER_DETAIL',
  CALL_API_SUBSCRIBER_DETAIL_SUCCESS: 'CALL_API_SUBSCRIBER_DETAIL_SUCCESS',
  CALL_API_SUBSCRIBER_CANCEL: 'CALL_API_SUBSCRIBER_CANCEL',
  CALL_API_SUBSCRIBER_CANCEL_SUCCESS: 'CALL_API_SUBSCRIBER_CANCEL_SUCCESS',
  CALL_API_GET_CHANGE_STATUS: 'CALL_API_GET_CHANGE_STATUS',
  CALL_API_GET_CHANGE_STATUS_SUCCESS: 'CALL_API_GET_CHANGE_STATUS_SUCCESS',
};
// #endregion

// #region Type
export const actCallApiSubscriberDetail = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_DETAIL,
  searchCondition,
});

export const actCallApiSubscriberDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_DETAIL_SUCCESS,
  data,
});

export const actCallInitSubscriberSearchList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SUBSCRIBER_SEARCH_LIST,
});

export const actInitSubscriberListSearchSuccess = (selects) => ({
  type: ACTION_TYPE.INIT_SUBSCRIBER_SEARCH_LIST_SUCCESS,
  selects,
});

export const actCallApiSubscriberList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_LIST,
  searchCondition,
});

export const actCallApiSubscriberListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_LIST_SUCCESS,
  datas,
});

export const actCallApiUpdateSubscriberStatus = (params) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS,
  id: params.id,
  searchCondition: params.searchCondition,
});

export const actCallApiUpdateSubscriberStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_SUCCESS,
  datas,
});

export const actCallApiUpdateSubscriberStatusAccepted = (params) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED,
  id: params.id,
  searchCondition: params.searchCondition,
});

export const actCallApiUpdateSubscriberStatusAcceptedSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_SUBSCRIBER_STATUS_ACCEPTED_SUCCESS,
  datas,
});

export const actResetUpdateSuccessFlg = () => ({
  type: ACTION_TYPE.RESET_UPDATE_SUCCESS_FLG,
});

export const actCallApiSubscriberCancel = (id) => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_CANCEL,
  id,
});

export const actCallApiSubscriberCancelSuccess = () => ({
  type: ACTION_TYPE.CALL_API_SUBSCRIBER_CANCEL_SUCCESS,
});

export const actCallApiGetChangeStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_CHANGE_STATUS,
  param,
});

export const actCallApiGetChangeStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_CHANGE_STATUS_SUCCESS,
  datas,
});
// #endregion
