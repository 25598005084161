import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Container,
} from '@material-ui/core';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import BasicBreadcrumbs from '../../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import CalendarScheduleMenuDialog from '../../../share/calendarScheduleMenuDialog/CalendarScheduleMenuDialog';
import { actCallApiSelectList } from '../../../../redux/share/weekScheduleMenu/action';
import {
  actCallApiCalendarScheduleFlgReset,
} from '../../../../redux/share/calendarScheduleDetail/action';
import { actCallApiGetGolfHeader, actCallApiGetGolfBookingDetail } from '../../../../redux/event/eventCalendarDetailGolf/action';
import urls from '../../../../constants/urls';
import CalendarSchedulePublishDialog from '../../../share/calendarScheduleDetail/CalendarSchedulePublishDialog';
import EventCalendarGolfTable from './EventCalendarGolfTable';
import EventCalendarBulkChangeModal from './EventCalendarBulkChangeModal';
import BookingHeader from '../../../share/calendarScheduleDetail/BookingHeader';

const styles = () => (
  {
    root: {
      paddingTop: '60px',
      paddingBottom: '40px',
    },
    headerUpdateBtn: {
      borderTop: '1px solid #eeeeee',
      paddingTop: '10px',
    },
    paper: {
      width: 'fit-content',
      marginBottom: '20px',
    },
  });

class EventCalendarDetailGolf extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop();
    this.breadcrumbsText = [
      {
        url: urls.EVENT.EVENT_CALENDARS,
        name: '予約カレンダー作成',
      },
      {
        name: '予約カレンダー詳細',
      },
    ];

    let headerId = null;
    let storeId = null;
    let contentId = null;
    let isRelationMain = false;
    let selectedDate = null;

    if (props.calendarScheduleDetail.header) {
      const { header, isMain, targetDate } = props.calendarScheduleDetail.header;
      headerId = header.id;
      storeId = header.storeId;
      contentId = header.contentId;
      selectedDate = targetDate;
      isRelationMain = isMain;
    }

    if (props.location.state) {
      headerId = props.location.state.headerId;
      storeId = props.location.state.storeId;
      contentId = props.location.state.contentId;
      isRelationMain = props.location.state.isMain;
      selectedDate = props.location.state.targetDate;
    }

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.calendarScheduleDetail.isCreated) {
      successSnackOpen = true;
      successSnackMessage = '予約カレンダーの作成が完了しました';
    }
    props.dispatch(actCallApiCalendarScheduleFlgReset());
    props.dispatch(actCallApiSelectList(contentId, storeId));

    if (!headerId) {
      props.history.push({
        pathname: urls.OTHER.HOME,
      });
      return;
    }
    if (headerId) {
      props.dispatch(actCallApiGetGolfHeader({ headerId }));
      props.dispatch(actCallApiGetGolfBookingDetail({ storeId, targetedOn: selectedDate }));
    }

    this.state = {
      header: {
        id: headerId,
        storeId,
        storeName: '',
        contentId,
        contentName: '',
        targetedOn: null,
        publishedOn: '',
        isRegular: null,
      },
      selectedDate,
      isMain: isRelationMain,
      headerDialogOpen: false,
      scheduleDetailId: null,
      scheduleModalOpen: false,
      bulkChangeDialogOpen: false,
      successSnackOpen,
      successSnackMessage,
    };
  }

  componentDidMount() {
    document.title = '予約カレンダー詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarDetailGolf.header !== this.props.calendarDetailGolf.header) {
      if (this.props.calendarDetailGolf.header) {
        this.setHeader(this.props.calendarDetailGolf.header);
      }
    }
    if (prevProps.calendarDetailGolf.isPublishDateUpdate
      !== this.props.calendarDetailGolf.isPublishDateUpdate) {
      if (this.props.calendarDetailGolf.isPublishDateUpdate) {
        this.success('公開日の変更が完了しました');
      }
    }
    if (prevProps.calendarDetailGolf.isBulkFrameUpdate
      !== this.props.calendarDetailGolf.isBulkFrameUpdate) {
      if (this.props.calendarDetailGolf.isBulkFrameUpdate) {
        this.success('打席の一括変更が完了しました');
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  setSuccessMessage = (message) => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: message,
      headerDialogOpen: false,
      scheduleModalOpen: false,
      bulkChangeDialogOpen: false,
    });
  }

  setHeader = (header) => {
    this.setState({ header });
  }

  success = (message) => {
    const { header } = this.state;
    this.props.dispatch(actCallApiGetGolfHeader({ headerId: header.id }));
    this.setSuccessMessage(message);
  }

  handleScheduleHeaderModalOpen = () => {
    this.setState({ headerDialogOpen: true, successSnackOpen: false });
  }

  bulkChangeModalOpen = () => {
    this.setState({ bulkChangeDialogOpen: true, successSnackOpen: false });
  }

  bulkChangeModalClose = () => {
    this.setState({ bulkChangeDialogOpen: false });
  }

  handleScheduleHeaderModalClose = () => {
    this.setState({ headerDialogOpen: false });
  }

  handleMenuDialogOpen = (_, id) => {
    this.setState({
      scheduleModalOpen: true,
      scheduleDetailId: id,
      successSnackOpen: false,
    });
  }

  handleMenuDialogClose = () => {
    this.setState({ scheduleModalOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  render() {
    const { classes } = this.props;
    const {
      isMain, header, scheduleDetailId, scheduleModalOpen,
      successSnackOpen, successSnackMessage, headerDialogOpen, selectedDate,
      bulkChangeDialogOpen,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <BookingHeader
            header={header}
            isMain={isMain}
            handleOpen={this.handleScheduleHeaderModalOpen}
            bulkChangeModalOpen={this.bulkChangeModalOpen}
            contentId={header.contentId}
          />

          <EventCalendarGolfTable
            header={header}
            isMain={isMain}
            handleMenuDialogOpen={this.handleMenuDialogOpen}
            success={this.setSuccessMessage}
            selectedDate={selectedDate}
            onCloseMessage={this.handleSuccessSnackbarClose}
          />

          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successSnackMessage}
          />

          <CalendarSchedulePublishDialog
            open={headerDialogOpen}
            onClose={this.handleScheduleHeaderModalClose}
            headerId={header.id}
            contentId={header.contentId}
          />

          <CalendarScheduleMenuDialog
            open={scheduleModalOpen}
            onClose={this.handleMenuDialogClose}
            id={scheduleDetailId}
            contentId={header.contentId}
            storeId={header.storeId}
            targetDate={new Date(selectedDate)}
            headerId={header.id}
            regular={false}
          />
          <EventCalendarBulkChangeModal
            open={bulkChangeDialogOpen}
            onClose={this.bulkChangeModalClose}
            storeId={header.storeId}
            targetDate={new Date(selectedDate)}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarScheduleDetail: state.calendarScheduleDetail,
  calendarDetailGolf: state.calendarDetailGolf,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventCalendarDetailGolf)));
