import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * データ取得
 * @param {*} param
 * @returns
 */
export function getDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.SURVEY_SETTING.SURVEY_SETTING, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 登録更新
 * @param {*} param
 * @returns
 */
export function upsert(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.SURVEY_SETTING.SURVEY_SETTING, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
