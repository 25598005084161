import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Tabs, Tab, Box, Card,
} from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import {
  actCallRuleGetStore, actCallApiGetRule,
  actCallApiRulesUpdate, actRuleDataUpdate,
  actResetRuleFlg, actCallApiRuleGolfTimeUpdate,
} from '../../../redux/setting/rule/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import CultureRules from './CultureRules';
import GolfRules from './golf/GolfRules';
import RoomRules from './RoomRules';
import RentalCourtRules from './RentalCourtRules';
import RegularRules from './RegularRules';
import OnlineCultureRules from './OnlineCultureRules';
import { getObject } from './rule-list-func';

const styles = (theme) => ({
  cardMenu: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: '20px',
  },
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class RuleList extends Component {
  constructor(props) {
    super(props);
    props.dispatch(actCallRuleGetStore());

    this.breadcrumbsTextList = [
      { name: '予約ルール登録' },
    ];

    this.state = {
      isMain: props.common.user ? props.common.user.main : false,
      tabIndex: 0,
      stores: [],
      searchStore: props.common.user ? props.common.user.store : null,
      storeId: props.common.user ? props.common.user.store.id : null,
      successSnackOpen: false,
      ruleList: [],
      datas: getObject(),
    };

    if (props.common.user) {
      props.dispatch(actResetRuleFlg());
      props.dispatch(actCallApiGetRule(props.common.user.store.id));
    }
  }

  componentDidMount() {
    document.title = '予約ルール登録';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.rules.stores !== this.props.rules.stores) {
      if (this.props.rules.stores) {
        this.setStores(this.props.rules.stores);
      }
    }
    if (prevProps.rules.isGetSuccess !== this.props.rules.isGetSuccess) {
      if (this.props.rules.isGetSuccess) {
        this.setDatas(this.props.rules.datas);
      }
    }
    if (prevProps.rules.isUpdateSuccess !== this.props.rules.isUpdateSuccess) {
      if (this.props.rules.isUpdateSuccess) {
        this.setResultDatas(this.props.rules.resultDatas, this.props.rules.flg);
      }
    }
    if (prevProps.rules.isTimeUpdateSuccess !== this.props.rules.isTimeUpdateSuccess) {
      if (this.props.rules.isTimeUpdateSuccess) {
        this.setResultTimes(this.props.rules.results, this.props.rules.isBox);
      }
    }
  }

  setUser(user) {
    this.setState({
      isMain: user.main,
      searchStore: user.store,
      storeId: user.store.id,
    });
    this.props.dispatch(actResetRuleFlg());
    this.props.dispatch(actCallApiGetRule(user.store.id));
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setDatas(data) {
    this.setState({
      datas: data,
      tabIndex: data.ruleList.length > 0 ? data.ruleList.sort()[0] : 6,
      ruleList: data.ruleList,
    });
  }

  setResultTimes = (results, isBox) => {
    const { datas } = this.state;
    let key = 'targetList';
    const { golfDatas } = datas;
    if (isBox) {
      key = 'boxList';
    }
    const tempDatas = {
      ...datas,
      golfDatas: {
        ...golfDatas,
        [key]: results,
      },
    };

    this.setState({
      datas: tempDatas,
      successSnackOpen: true,
    });
    this.props.dispatch(actRuleDataUpdate(tempDatas));
  }

  setResultDatas = (resultDatas, flg) => {
    const { datas } = this.state;
    let key = '';
    let tempResultDatas = resultDatas;
    switch (flg) {
      case 0: key = 'cultureDatas'; break;
      case 1: {
        key = 'golfDatas';
        const { golfDatas } = datas;
        tempResultDatas = {
          ...golfDatas,
          golfDatas: resultDatas,
        };
        break;
      }
      case 2: key = 'roomDatas'; break;
      case 3: key = 'rentalCourtDatas'; break;
      case 4: key = 'regularDatas'; break;
      case 5: key = 'onlineCultureDatas'; break;
      default: break;
    }
    const tempDatas = {
      ...datas,
      [key]: tempResultDatas,
    };
    this.setState({
      datas: tempDatas,
      successSnackOpen: true,
    });
    this.props.dispatch(actRuleDataUpdate(tempDatas));
  }

  onTabChange = (_, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  };

  onSearch = () => {
    const { searchStore } = this.state;
    this.setState({ storeId: searchStore.id, successSnackOpen: false, tabIndex: 6 });
    this.props.dispatch(actResetRuleFlg());
    this.props.dispatch(actCallApiGetRule(searchStore.id));
  }

  onSave = (_, data) => {
    const { storeId, tabIndex } = this.state;
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actResetRuleFlg());
    this.props.dispatch(actCallApiRulesUpdate({ ...data, mstStoreId: storeId }, tabIndex));
  }

  onTimeSave = (_, data) => {
    const { storeId } = this.state;
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actResetRuleFlg());
    this.props.dispatch(actCallApiRuleGolfTimeUpdate(
      { box: data.isBox, mstStoreId: storeId, times: data.times },
      data.isBox,
    ));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  render() {
    const { classes } = this.props;
    const {
      isMain, tabIndex, stores, searchStore, successSnackOpen, datas, ruleList,
    } = this.state;

    const {
      cultureDatas, golfDatas, roomDatas, rentalCourtDatas, regularDatas, onlineCultureDatas,
    } = datas;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsTextList} />
        <MainBody>
          <Box display={isMain ? '' : 'none'} mb={2}>
            <SearchBox onSearch={this.onSearch}>
              <Box display="flex">
                <Box width="30%">
                  <FormTitle title="店舗" />
                  <SearchSelectBox
                    options={stores}
                    value={searchStore}
                    onChange={this.onStoreChange}
                    disableClearable
                    name="searchStore"
                  />
                </Box>
              </Box>
            </SearchBox>
          </Box>
          <Card className={classes.cardMenu}>
            <Tabs
              orientation="vertical"
              value={tabIndex}
              onChange={this.onTabChange}
              className={classes.tabs}
            >
              <Tab
                label="カルチャー"
                style={{ display: ruleList.indexOf(0) !== -1 ? '' : 'none' }}
              />
              <Tab
                label="ゴルフ"
                style={{ display: ruleList.indexOf(1) !== -1 ? '' : 'none' }}
              />
              <Tab
                label="Room"
                style={{ display: ruleList.indexOf(2) !== -1 ? '' : 'none', textTransform: 'none' }}
              />
              <Tab
                label="レンタルコート"
                style={{
                  display: ruleList.indexOf(3) !== -1 ? '' : 'none',
                }}
              />
              <Tab
                label="定期"
                style={{
                  display: ruleList.indexOf(4) !== -1 ? '' : 'none',
                }}
              />
              <Tab
                label="オンラインカルチャー"
                style={{ display: ruleList.indexOf(5) !== -1 ? '' : 'none' }}
              />
              <Tab style={{ display: 'none' }} />
            </Tabs>
            <CultureRules
              value={tabIndex}
              data={cultureDatas}
              onSave={this.onSave}
            />
            <GolfRules
              value={tabIndex}
              data={golfDatas}
              onTimeSave={this.onTimeSave}
              onSave={this.onSave}
            />
            <RoomRules
              value={tabIndex}
              data={roomDatas}
              onSave={this.onSave}
            />
            <RentalCourtRules
              value={tabIndex}
              data={rentalCourtDatas}
              onSave={this.onSave}
            />
            <RegularRules
              value={tabIndex}
              data={regularDatas}
              onSave={this.onSave}
            />
            <OnlineCultureRules
              value={tabIndex}
              data={onlineCultureDatas}
              onSave={this.onSave}
            />
            <div role="tabpanel" hidden={tabIndex !== 6} style={{ height: '100px', width: '100%' }} />
          </Card>
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="ルールの保存が完了しました" />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rules: state.rules,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(RuleList));
