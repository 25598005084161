import format from 'date-fns/format';
import { isAfter } from 'date-fns';

export function getStyle(data, startTime, curDate) {
  const today = new Date(format(new Date(), 'yyyy/MM/dd'));
  const targetDate = new Date(curDate);
  const dateCheck = ((targetDate.getTime() === today.getTime()) || isAfter(targetDate, today));

  const startDate = new Date(`1999/01/01 ${data.startTime}`);
  const endDate = new Date(`1999/01/01 ${data.endTime}`);
  const baseDate = new Date(`1999/01/01 ${startTime}`);

  const diffDate = (endDate.getTime() - startDate.getTime()) / (60 * 1000) / 30;
  const topDiff = (startDate.getTime() - baseDate.getTime()) / (60 * 1000) / 30;
  const color = data.color ? data.color : '#fffacd';
  return {
    top: `${topDiff * 40}px`,
    left: 0,
    width: '100%',
    height: `${diffDate * 40}px`,
    backgroundColor: color,
    cursor: (!dateCheck && data.contentId === 5) ? 'default' : 'pointer',
    borderRadius: '5px',
    border: `1px solid ${color}`,
    color: '#000',
    display: 'block',
  };
}

export function compareDateCheck(date1, date2) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth() + 1;
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth() + 1;
  const day2 = date2.getDate();

  if (year1 === year2) {
    if (month1 === month2) {
      return day1 < day2;
    }
    return month1 < month2;
  }
  return year1 < year2;
}
