const experienceNameValidation = (experienceName) => {
  if (!experienceName) return '入力してください';
  return '';
};

const phoneValidation = (phone) => {
  if (!phone) return '入力してください';
  const regex = /^([0-9-]*)$/;
  if (!regex.test(phone)) return '数値と-のみを入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'experienceName':
        return experienceNameValidation(value);
      case 'phone':
        return phoneValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
