// region Type
export const ACTION_TYPE = {
  CALL_API_INIT_HEALTH_CHECK: 'CALL_API_INIT_HEALTH_CHECK',
  INIT_HEALTH_CHECK_SUCCESS: 'INIT_HEALTH_CHECK_SUCCESS',
  CALL_INSERT_HEALTH_CHECK_RESULT: 'CALL_INSERT_HEALTH_CHECK_RESULT',
  CALL_INSERT_HEALTH_CHECK_SUCCESS: 'CALL_INSERT_HEALTH_CHECK_SUCCESS',
  CALL_API_GET_MEMBER_INFO: 'CALL_API_GET_MEMBER_INFO',
  CALL_API_GET_MEMBER_INFO_SUCCESS: 'CALL_API_GET_MEMBER_INFO_SUCCESS',
};
  // endregion

export const actCallInitHealthCheck = (param) => ({
  type: ACTION_TYPE.CALL_API_INIT_HEALTH_CHECK,
  param,
});

export const actInitHealthCheckSuccess = (items) => ({
  type: ACTION_TYPE.INIT_HEALTH_CHECK_SUCCESS,
  items,
});

export const actInsertHealthCheckResult = (values) => ({
  type: ACTION_TYPE.CALL_INSERT_HEALTH_CHECK_RESULT,
  values,
});

export const actInsertHealthCheckResultSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_INSERT_HEALTH_CHECK_SUCCESS,
  datas,
});

export const actCallApiMemberInfo = (memberNo) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_INFO,
  memberNo,
});

export const actCallApiMemberInfoSuccess = (member) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_INFO_SUCCESS,
  member,
});
