const titleValidation = (value) => {
  if (!value) return '入力してください';

  return '';
};

const contentValidation = (value) => {
  if (!value) return '入力してください';

  return '';
};

class Validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'mailTitle':
      return titleValidation(value);
    case 'mailContent':
      return contentValidation(value);
    default:
      return '';
  }
};
}

export default Validation;
