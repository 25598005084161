import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box, Button, withStyles, Popover,
  Dialog, DialogActions, DialogContent,
  Card, CardMedia,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  actCallApiBannerImageList, actCallApiBannerRelease,
  actBannerFlgReset, actCallApiMainBannerUpdate, actCallApiBannerDelete,
  actCallApiMainBannerCreate,
} from '../../../redux/setting/bannerImage/action';
import MainBody from '../../../components/templates/mainBody/MainBody';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import BannerImageDataTable from './BannerImageDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';

const styles = () => ({
  modalContainer: {
    marginBottom: '10px',
  },
  previewContainer: {
    marginTop: '1px',
    marginLeft: '1px',
    width: '100%',
    wordBreak: 'break-all',
  },
  previewItem: {
    padding: '5px!important',
  },
  previewImageCard: {
    padding: '5px',
  },
  popover: {
    width: '60%',
    height: '60%',
  },
});

class BannerImageList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallApiBannerImageList({ page: 0, records: 10 }));

    this.breadcrumbsText = [
      { name: 'バナー画像登録' },
    ];

    this.state = {
      datas: {},
      page: 0,
      records: 10,
      id: null,
      changeLoading: false,
      modalOpen: false,
      anchorEl: null,
      previewUrl: null,
      releaseSuccessSnackOpen: false,
      deleteSuccessSnackOpen: false,
      uploadSuccessSnackOpen: false,
      releaseSuccessSnackName: '',
      uploadSuccessSnackName: '',
      bannerImage: null,
    };
  }

  componentDidMount() {
    document.title = 'バナー画像登録';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.banner.datas !== this.props.banner.datas) {
      if (this.props.banner.datas) {
        this.setDatas(this.props.banner.datas);
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.banner.isReleaseSuccess !== this.props.banner.isReleaseSuccess) {
      this.handleSuccessSnackClose();
      if (this.props.banner.isReleaseSuccess) {
        this.setReleaseSuccess(this.props.banner.datas);
      }
    }
    if (prevProps.banner.isDeleteSuccess !== this.props.banner.isDeleteSuccess) {
      this.handleSuccessSnackClose();
      if (this.props.banner.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.banner.isUploadSuccess !== this.props.banner.isUploadSuccess) {
      this.handleSuccessSnackClose();
      if (this.props.banner.isUploadSuccess) {
        this.setUploadSuccess(this.props.banner.datas);
      }
    }
  }

  setChangeLoading(changeLoading) {
    this.setState({ changeLoading });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setAnchorEl(value) {
    this.setState({ anchorEl: value });
  }

  setReleaseSuccess(datas) {
    const { page, records } = this.state;
    const { displayFileName, released } = datas;

    let releaseSuccessSnackName = '';
    if (released) {
      releaseSuccessSnackName = `${displayFileName}を公開しました`;
    } else {
      releaseSuccessSnackName = `${displayFileName}を公開停止しました`;
    }
    this.handleClose();
    this.setState({ releaseSuccessSnackOpen: true, releaseSuccessSnackName });
    this.props.dispatch(actCallApiBannerImageList({ page, records }));
  }

  setDeleteSuccess() {
    const { page, records } = this.state;

    this.handleClose();
    this.setState({ deleteSuccessSnackOpen: true });
    this.props.dispatch(actCallApiBannerImageList({ page, records }));
  }

  setUploadSuccess(datas) {
    const { page, records } = this.state;
    const { displayFileName } = datas;
    const uploadSuccessSnackName = `${displayFileName}をアップロードしました`;

    this.handleClose();
    this.setState({ uploadSuccessSnackOpen: true, uploadSuccessSnackName });
    this.props.dispatch(actCallApiBannerImageList({ page, records }));
  }

  onAdd = (_, id) => {
    this.setState({ modalOpen: true, id });
  }

  onRelease = (id) => {
    this.setState({ id });
    this.props.dispatch(actBannerFlgReset());
    this.props.dispatch(actCallApiBannerRelease({ id }));
  }

  onPreview = (event, url) => {
    this.setState({ previewUrl: url });
    this.setAnchorEl(event.currentTarget);
  }

  onDelete = (deleteList) => {
    this.props.dispatch(actBannerFlgReset());
    this.props.dispatch(
      actCallApiBannerDelete(deleteList),
    );
  }

  setBannerImage = (fileObjs) => {
    const bannerImage = fileObjs[0];
    this.setState({ bannerImage });
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  }

  handleSuccessSnackClose = () => {
    this.setState({
      releaseSuccessSnackOpen: false,
      deleteSuccessSnackOpen: false,
      uploadSuccessSnackOpen: false,
    });
  }

  handleChangePage = (_, newPage) => {
    const {
      records,
    } = this.state;

    this.setState({ page: newPage });

    this.props.dispatch(
      actCallApiBannerImageList({
        page: newPage,
        records,
      }),
    );
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.props.dispatch(
      actCallApiBannerImageList({
        page: 0,
        records: rowsPerPage,
      }),
    );
  };

  handleSave = (bannerId) => {
    const { bannerImage } = this.state;
    const id = bannerId;

    if (bannerImage && id) {
      this.setState({ changeLoading: true });
      this.props.dispatch(actBannerFlgReset());
      this.props.dispatch(actCallApiMainBannerUpdate(id, bannerImage));
    }

    if (bannerImage && !id) {
      this.setState({ changeLoading: true });
      this.props.dispatch(actBannerFlgReset());
      this.props.dispatch(actCallApiMainBannerCreate(bannerImage));
    }
  }

  previewClose = () => {
    this.setAnchorEl(null);
  }

  render() {
    const { classes } = this.props;

    const {
      datas, page, records, id, changeLoading,
      modalOpen, anchorEl, previewUrl,
      releaseSuccessSnackOpen, uploadSuccessSnackOpen, deleteSuccessSnackOpen,
      releaseSuccessSnackName, uploadSuccessSnackName,
    } = this.state;

    const previewOpen = Boolean(anchorEl);
    const previewId = previewOpen ? 'simple-popover' : undefined;

    return (
      <div>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <BannerImageDataTable
            rows={datas.content ? datas.content : []}
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            onDetail={this.onDetail}
            onPreview={this.onPreview}
            previewClose={this.previewClose}
            onPreviewUpdate={this.onAdd}
            previewRelease={this.onRelease}
          />
          <Box>
            <Dialog open={modalOpen} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={this.handleClose}>
              <DialogTitle>{id ? 'メイン画像の編集' : '広告バナーの追加'}</DialogTitle>
              <DialogContent dividers>
                <Box mb={2}>
                  <Alert severity="info">複数枚の画像を一度にアップロードできません</Alert>
                </Box>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  previewText="プレビュー"
                  onChange={(fileObjs) => this.setBannerImage(fileObjs)}
                  filesLimit={1}
                  maxFileSize={2097152}
                  showPreviews
                  showFileNamesInPreview
                  showPreviewsInDropzone={false}
                  showAlerts={false}
                  dropzoneClass={classes.modalContainer}
                  previewGridClasses={{
                    container: classes.previewContainer,
                    item: classes.previewItem,
                    image: classes.previewImage,
                  }}
                  dropzoneText="画像をドラッグ＆ドロップする または クリックしてファイルを選択"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>キャンセル</Button>
                <LoadingButton loading={changeLoading} onClick={() => this.handleSave(id)} label="保存" />
              </DialogActions>
            </Dialog>
          </Box>
          <Popover
            className={classes.popover}
            id={previewId}
            open={previewOpen}
            anchorEl={anchorEl}
            onClose={this.previewClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Card className={classes.previewImageCard} onClick={this.previewClose}>
              <CardMedia
                component="img"
                src={previewUrl}
                title="Paella dish"
              />
            </Card>
          </Popover>
        </MainBody>
        <SuccessSnackbar
          open={deleteSuccessSnackOpen}
          handleClose={this.handleSuccessSnackClose}
          message="バナー画像の削除が完了しました"
        />
        <SuccessSnackbar
          open={releaseSuccessSnackOpen}
          handleClose={this.handleSuccessSnackClose}
          message={releaseSuccessSnackName}
        />
        <SuccessSnackbar
          open={uploadSuccessSnackOpen}
          handleClose={this.handleSuccessSnackClose}
          message={uploadSuccessSnackName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  banner: state.bannerImages,
  common: state.common,
});

export default withStyles(styles)(connect(mapStateToProps)(BannerImageList));
