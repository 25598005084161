export const ACTION_TYPE = {
  CALL_API_INIT_WEEK_SELECT: 'CALL_API_INIT_WEEK_SELECT',
  CALL_API_INIT_WEEK_SELECT_SUCCESS: 'CALL_API_INIT_WEEK_SELECT_SUCCESS',
  CALL_API_MEMBER_PRODUCT_INFO: 'CALL_API_MEMBER_PRODUCT_INFO',
  CALL_API_MEMBER_PRODUCT_INFO_SUCCESS: 'CALL_API_MEMBER_PRODUCT_INFO_SUCCESS',
  CALL_API_SEARCH_WEEK_SELECT_SCHEDULE: 'CALL_API_SEARCH_WEEK_SELECT_SCHEDULE',
  CALL_API_SEARCH_WEEK_SELECT_SCHEDULE_SUCCESS: 'CALL_API_SEARCH_WEEK_SELECT_SCHEDULE_SUCCESS',
  CALL_API_DIALOG_MEMBER_PRODUCT_INFO: 'CALL_API_DIALOG_MEMBER_PRODUCT_INFO',
  CALL_API_DIALOG_MEMBER_PRODUCT_INFO_SUCCESS: 'CALL_API_DIALOG_MEMBER_PRODUCT_INFO_SUCCESS',

  CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE: 'CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE',
  CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE_SUCCESS: 'CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE_SUCCESS',
  CALL_API_WEEK_SELECT_FLG_RESET: 'CALL_API_WEEK_SELECT_FLG_RESET',
};

export const actCallInitWeekSelect = () => ({
  type: ACTION_TYPE.CALL_API_INIT_WEEK_SELECT,
});

export const actCallInitWeekSelectSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_INIT_WEEK_SELECT_SUCCESS,
  selects,
});

export const actCallMemberProductInfo = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_PRODUCT_INFO,
  searchCondition,
});

export const actCallMemberProductInfoSuccess = (memberInfoData) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_PRODUCT_INFO_SUCCESS,
  memberInfoData,
});

export const actCallApiSearchWeekSelectSchedule = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SEARCH_WEEK_SELECT_SCHEDULE,
  searchCondition,
});

export const actCallApiSearchWeekSelectScheduleSuccess = (scheduleDatas) => ({
  type: ACTION_TYPE.CALL_API_SEARCH_WEEK_SELECT_SCHEDULE_SUCCESS,
  scheduleDatas,
});

export const actApiInsertWeekSelectMemberLinkage = (param) => ({
  type: ACTION_TYPE.CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE,
  param,
});

export const actApiInsertWeekSelectMemberLinkageSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_INSERT_WEEK_SELECT_MEMBER_LINKAGE_SUCCESS,
  datas,
});

export const actCallDialogMemberProductInfo = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_DIALOG_MEMBER_PRODUCT_INFO,
  searchCondition,
});

export const actCallDialogMemberProductInfoSuccess = (dialogMemberData) => ({
  type: ACTION_TYPE.CALL_API_DIALOG_MEMBER_PRODUCT_INFO_SUCCESS,
  dialogMemberData,
});

export const actApiWeekSelectFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_WEEK_SELECT_FLG_RESET,
});
