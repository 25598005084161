import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Button, Box, TextField, FormControl, RadioGroup, FormControlLabel, Radio,
  Select, Paper, withStyles, FormHelperText,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowForwardIos as ArrowForwardIosIcon, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import FormTitle from '../../../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../../../components/atoms/formErrorText/FormErrorText';
import { actCallSetMemberEntryForm, getZipCodeAddress } from '../../../../../redux/member/entry/action';
import {
  createMemberForm,
  createMemberListForm,
  errorMessageFormMember,
} from '../entry-proc';
import validation from '../validation';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '75px',
    },
  },
  smallTextField: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
  smallTextFieldDate: {
    width: '10ch',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
  userTitle: {
    marginTop: '20px',
  },
  buttonFirst: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginBottom: '20px',
    },
  },
});
// #endregion

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: [errorMessageFormMember()],
      members: createMemberListForm(),
      userType: 3,
      isLoading: false,
      isSameContractUser: '0',
      userIndex: 0,
      errorMessageShowFlg: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entry.zipcodeUser !== this.props.entry.zipcodeUser) {
      if (this.props.entry.zipcodeUser) {
        this.setAddress(this.props.entry.zipcodeUser);
      }
    }
  }

  setAddress = (value) => {
    const { members, errorMessages, userIndex } = this.state;
    let mstCodePrefectureCode = null;
    let city = '';
    let street = '';
    let zipCode = '';

    if (value.results && value.results.length > 0) {
      mstCodePrefectureCode = {
        name: value.results[0].address1,
        id: parseInt(value.results[0].prefcode, 10),
      };
      city = value.results[0].address2;
      street = value.results[0].address3;
    }
    if (!value.results) {
      zipCode = '存在しない郵便番号です';
    }

    const tempMembers = {
      ...members[userIndex],
      mstCodePrefectureCode,
      city,
      street,
    };
    members[userIndex] = tempMembers;

    const tempErrorMessage = {
      ...errorMessages[userIndex],
      zipCode,
    };
    errorMessages[userIndex] = tempErrorMessage;

    this.setState({
      members,
      errorMessages,
      isLoading: false,
    });
  }

  onClickGoBack = () => {
    this.setState({ errorMessageShowFlg: false });
    const { onClickToNext } = this.props;
    onClickToNext(0);
  }

  onClickGoNext = () => {
    const { isFromConfirmForm, onClickToNext, onClickToNextSetContract } = this.props;
    const { isSameContractUser, userType } = this.state;
    const { memberEntry } = this.props.entry;
    const { mstCodeContractCode } = memberEntry;

    let activeIndex = 2;
    if (isFromConfirmForm) {
      activeIndex = 3;
    }

    if (mstCodeContractCode === 3) {
      const tmpMemberEntry = {
        ...memberEntry,
        userType,
      };
      this.props.dispatch(actCallSetMemberEntryForm(tmpMemberEntry));
      onClickToNext(activeIndex);
      return;
    }

    if (isSameContractUser === '0' && mstCodeContractCode === 1) {
      onClickToNextSetContract(activeIndex, isSameContractUser);
      return;
    }

    this.contractMemberDifference(activeIndex, isSameContractUser);
  }

  onClickUserAdd = () => {
    const { members, errorMessages } = this.state;
    members.push(createMemberForm());
    errorMessages.push(errorMessageFormMember());
    this.setState({ members, errorMessages });
  }

  onClickUserDelete = (index) => {
    const { members, errorMessages } = this.state;
    members.splice(index, 1);
    errorMessages.splice(index, 1);
    this.setState({ members, errorMessages });
  }

  onChangeTextField = (event, index) => {
    const { name, value } = event.target;
    const { members, errorMessages } = this.state;
    const tempMembers = this.getMember(members, index, name, value);
    const tempErrors = this.getError(errorMessages, index, name, value);

    this.setState({ members: tempMembers, errorMessages: tempErrors });
  }

  onChangePhoneField = (event, index) => {
    const { name, value } = event.target;
    const { members, errorMessages } = this.state;
    const tempMembers = this.getMember(members, index, name, value);
    const {
      mstStoreId,
    } = this.props.entry.memberEntry;
    const companyId = mstStoreId.mstOrganizationCompanyId;

    const tempMember = members[index];
    let phoneVal = null;
    if (name === 'phoneBefore') {
      phoneVal = `${value}-${tempMember.phoneMiddle}-${tempMember.phoneAfter}`;
    }
    if (name === 'phoneMiddle') {
      phoneVal = `${tempMember.phoneBefore}-${value}-${tempMember.phoneAfter}`;
    }
    if (name === 'phoneAfter') {
      phoneVal = `${tempMember.phoneBefore}-${tempMember.phoneMiddle}-${value}`;
    }

    const key = companyId === 4 ? 'phoneTwo' : 'phone';
    const tempErrors = this.getError(errorMessages, index, key, phoneVal, 'phone');

    this.setState({ members: tempMembers, errorMessages: tempErrors });
  }

  onChangePhoneTwoField = (event, index) => {
    const { name, value } = event.target;
    const { members, errorMessages } = this.state;
    const tempMembers = this.getMember(members, index, name, value);

    const tempMember = members[index];
    let phoneVal = null;
    if (name === 'phoneTwoBefore') {
      phoneVal = `${value}-${tempMember.phoneTwoMiddle}-${tempMember.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoMiddle') {
      phoneVal = `${tempMember.phoneTwoBefore}-${value}-${tempMember.phoneTwoAfter}`;
    }
    if (name === 'phoneTwoAfter') {
      phoneVal = `${tempMember.phoneTwoBefore}-${tempMember.phoneTwoMiddle}-${value}`;
    }
    const tempErrors = this.getError(errorMessages, index, 'phoneTwo', phoneVal);

    this.setState({ members: tempMembers, errorMessages: tempErrors });
  }

  onChangeBirthday = (event, index) => {
    const { name, value } = event.target;
    const { members, errorMessages } = this.state;
    const tempMembers = this.getMember(members, index, name, value);

    const birthdayVal = `${tempMembers[index].birthdayYear}/${tempMembers[index].birthdayMonth}/${tempMembers[index].birthdayDay}`;
    const tempErrors = this.getError(errorMessages, index, 'birthdayNoRequired', birthdayVal);

    this.setState({ members: tempMembers, errorMessages: tempErrors });
  }

  onChangeZipCode = (event, index) => {
    const { name, value } = event.target;
    const { members, errorMessages } = this.state;

    const tempMembers = this.getMember(members, index, name, value);

    const tempMember = members[index];
    let zipCodeVal = null;
    if (name === 'zipCodeBefore') {
      zipCodeVal = `${value}-${tempMember.zipCodeAfter}`;
    }
    if (name === 'zipCodeAfter') {
      zipCodeVal = `${tempMember.zipCodeBefore}-${value}`;
    }

    const tempErrorMessages = errorMessages;
    let tempErrorMessage = tempErrorMessages[index];
    tempErrorMessage = {
      ...tempErrorMessage,
      zipCode: validation.formValidate('zipCodeNorequired', zipCodeVal),
    };
    tempErrorMessages[index] = tempErrorMessage;

    let isLoading = false;
    if (zipCodeVal.length === 8) {
      this.props.dispatch(getZipCodeAddress({
        zipCode: zipCodeVal,
        pageFlg: 1,
      }));
      isLoading = true;
    }
    this.setState({
      members: tempMembers,
      errorMessages: tempErrorMessages,
      isLoading,
      userIndex: index,
    });
  }

  onChangeSearchSelectBox = (event, index) => {
    const { members } = this.state;
    const { name, value } = event.target;
    const tempMembers = this.getMember(members, index, name, value);
    this.setState({ members: tempMembers });
  }

  onChangeContractCodeRadio = (event) => {
    const { value } = event.target;
    this.setState({ userType: parseInt(value, 10) });
  }

  onChangeContractUserTypeRadio = (event) => {
    const { value } = event.target;
    this.setState({ isSameContractUser: value });
  }

  onChangeRadio = (event, index) => {
    const { members } = this.state;
    const { name, value } = event.target;
    const intVal = parseInt(value, 10);
    const tempMembers = this.getMember(members, index, name, intVal);

    this.setState({ members: tempMembers });
  }

  getMember = (members, index, name, value) => {
    const tempMembers = members;
    let tempMember = members[index];
    tempMember = {
      ...tempMember,
      [name]: value,
    };
    tempMembers[index] = tempMember;
    return tempMembers;
  }

  getError = (errorMessages, index, name, value, filed) => {
    const filedName = filed || name;
    const tempErrorMessages = errorMessages;
    let tempErrorMessage = tempErrorMessages[index];
    tempErrorMessage = {
      ...tempErrorMessage,
      [filedName]: validation.formValidate(name, value),
    };
    tempErrorMessages[index] = tempErrorMessage;
    return tempErrorMessages;
  }

  scrollToTop = () => window.scrollTo(0, 0);

  contractMemberDifference = (activeIndex, isSameContractUser) => {
    const { members } = this.state;
    const { memberEntry } = this.props.entry;
    const { mstCodeContractCode } = memberEntry;
    const { onClickToNextSetContract, onClickToNext } = this.props;
    const { mstStoreId } = this.props.entry.memberEntry;
    const companyId = mstStoreId && mstStoreId.mstOrganizationCompanyId;

    const tempMessageList = [];

    members.forEach((item) => {
      let tempMessage = errorMessageFormMember();

      const zipCodeVal = `${item.zipCodeBefore}-${item.zipCodeAfter}`;
      const zipCode = validation.formValidate('zipCodeNorequired', zipCodeVal);

      const phoneVal = `${item.phoneBefore}-${item.phoneMiddle}-${item.phoneAfter}`;
      const phoneKey = companyId !== 4 ? 'phone' : 'phoneTwo';
      const phone = validation.formValidate(phoneKey, phoneVal);

      const phoneTwoVal = `${item.phoneBefore}-${item.phoneMiddle}-${item.phoneAfter}`;
      const phoneTwo = validation.formValidate('phoneTwo', phoneTwoVal);

      const birthdayVal = `${item.birthdayYear}/${item.birthdayMonth}/${item.birthdayDay}`;
      const birthday = validation.formValidate('birthdayNoRequired', birthdayVal);

      let email = '';

      if (companyId !== 4) {
        email = validation.formValidate('email', item.email);
      }

      tempMessage = {
        ...tempMessage,
        zipCode,
        phone,
        phoneTwo,
        birthday,
        email,
      };

      Object.keys(item).filter((value) => value === 'lastName' || value === 'firstName' || value === 'lastNameKana' || value === 'firstNameKana').map((key) => {
        tempMessage = {
          ...tempMessage,
          [key]: validation.formValidate(key, item[key]),
        };
        return null;
      });
      tempMessageList.push(tempMessage);
    });

    const tmpMemberEntry = {
      ...memberEntry,
      members,
    };

    let isError = false;
    tempMessageList.forEach((item) => {
      if (Object.values(item).filter((value) => value !== '').length > 0) {
        isError = true;
      }
    });

    if (!isError) {
      this.props.dispatch(actCallSetMemberEntryForm(tmpMemberEntry));
      if (mstCodeContractCode === 1) {
        onClickToNextSetContract(activeIndex, isSameContractUser);
      } else {
        onClickToNext(activeIndex);
      }
    }
    this.setState({ errorMessages: tempMessageList, errorMessageShowFlg: isError });
    this.scrollToTop();
  }

  render() {
    const {
      classes,
      isCorporation,
      isFromConfirmForm,
      initSelectValues,
    } = this.props;

    const {
      isSameContractUser,
      errorMessages,
      errorMessageShowFlg,
      members,
      userType,
      isLoading,
    } = this.state;
    const { memberEntry } = this.props.entry;
    const {
      mstCodeContractCode,
      mstStoreId,
    } = memberEntry;
    const companyId = mstStoreId && mstStoreId.mstOrganizationCompanyId;

    return (
      <div className={classes.root}>

        <Box my={3}>
          <Alert style={{ display: errorMessageShowFlg ? '' : 'none' }} severity="error">入力エラーがあります。確認してください。</Alert>
        </Box>

        <Paper elevation={0} variant="outlined">
          <Box p={3}>
            <Box display={mstCodeContractCode === 3 ? '' : 'none'}>
              <FormTitle title="ご利用者タイプ" isRequired />
              <FormControl component="fieldset">
                <RadioGroup row aria-label="userType" onChange={this.onChangeContractCodeRadio}>
                  {initSelectValues.applicantTypeSelectList.map((option) => (
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value={option.id}
                      key={option.id}
                      name="userType"
                      label={option.name}
                      checked={userType === option.id}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box display={isCorporation ? 'none' : ''}>
              <FormTitle title="ご利用者様情報" isRequired />
              <FormControl component="fieldset">
                <RadioGroup row aria-label="sameContractUser" name="isSameContractUser" value={isSameContractUser} onChange={this.onChangeContractUserTypeRadio}>
                  <FormControlLabel value="0" control={<Radio color="primary" />} label="ご契約者様と同じ" checked={isSameContractUser === '0'} />
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="ご契約者様と異なる" checked={isSameContractUser === '1'} />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box display={isSameContractUser === '1' || mstCodeContractCode === 2 ? '' : 'none'}>
              {members.map((member, index) => {
                const keyIdx = index + 1;
                return (
                  <Paper key={keyIdx} elevation={0}>
                    <Box display="flex" className={classes.userTitle}>
                      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize" paddingBottom="3px">
                        {`${'ご利用者様情報'}${index + 1}${'人目'}`}
                      </Box>
                      <Button variant="contained" style={{ display: index !== 0 ? '' : 'none', marginLeft: 'auto' }} onClick={() => this.onClickUserDelete(index)}>
                        削除する
                      </Button>
                    </Box>
                    <hr />
                    <Box my={3}>
                      <FormTitle title="氏名" isRequired />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="lastName"
                              autoComplete="off"
                              value={member.lastName}
                              onChange={(event) => this.onChangeTextField(event, index)}
                              variant="outlined"
                              placeholder="姓"
                              error={errorMessages[index].lastName !== ''}
                              inputProps={{ maxLength: 80 }}
                            />
                            <FormErrorText>{errorMessages[index].lastName}</FormErrorText>
                          </Box>
                          <Box ml={2}>
                            <TextField
                              name="firstName"
                              autoComplete="off"
                              value={member.firstName}
                              onChange={(event) => this.onChangeTextField(event, index)}
                              variant="outlined"
                              placeholder="名"
                              error={errorMessages[index].firstName !== ''}
                              inputProps={{ maxLength: 40 }}
                            />
                            <FormErrorText>{errorMessages[index].firstName}</FormErrorText>
                          </Box>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="氏名カナ" isRequired />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="lastNameKana"
                              autoComplete="off"
                              value={member.lastNameKana}
                              onChange={(event) => this.onChangeTextField(event, index)}
                              variant="outlined"
                              placeholder="セイカナ"
                              error={errorMessages[index].lastNameKana !== ''}
                              inputProps={{ maxLength: 100 }}
                            />
                            <FormErrorText>{errorMessages[index].lastNameKana}</FormErrorText>
                          </Box>
                          <Box ml={2}>
                            <TextField
                              name="firstNameKana"
                              autoComplete="off"
                              value={member.firstNameKana}
                              onChange={(event) => this.onChangeTextField(event, index)}
                              variant="outlined"
                              placeholder="メイカナ"
                              error={errorMessages[index].firstNameKana !== ''}
                              inputProps={{ maxLength: 100 }}
                            />
                            <FormErrorText>{errorMessages[index].firstNameKana}</FormErrorText>
                          </Box>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="郵便番号" />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="zipCodeBefore"
                              autoComplete="off"
                              value={member.zipCodeBefore}
                              variant="outlined"
                              onChange={(event) => this.onChangeZipCode(event, index)}
                              placeholder="950"
                              className={classes.smallTextField}
                              error={errorMessages[index].zipCode !== ''}
                              inputProps={{ maxLength: 3 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="zipCodeAfter"
                              autoComplete="off"
                              value={member.zipCodeAfter}
                              variant="outlined"
                              onChange={(event) => this.onChangeZipCode(event, index)}
                              placeholder="0032"
                              className={classes.smallTextField}
                              error={errorMessages[index].zipCode !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <FormErrorText>{errorMessages[index].zipCode}</FormErrorText>
                        </Box>
                        <Box>
                          <FormHelperText style={{ color: 'black' }}>
                            郵便番号をご入力頂くと、住所の一部が自動入力されます
                          </FormHelperText>
                        </Box>
                        <Box>
                          <FormHelperText style={{ display: isLoading ? '' : 'none', color: 'black' }}>
                            検索しています..
                          </FormHelperText>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="都道府県" />
                      <SearchSelectBox
                        name="mstCodePrefectureCode"
                        onChange={(event) => this.onChangeSearchSelectBox(event, index)}
                        value={member.mstCodePrefectureCode}
                        options={initSelectValues.prefexturesSelectList}
                        placeholder="選択してください"
                      />
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="市区町村" />
                      <TextField
                        name="city"
                        autoComplete="off"
                        value={member.city}
                        variant="outlined"
                        onChange={(event) => this.onChangeTextField(event, index)}
                        placeholder="例）新潟市江南区両川"
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                      />
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="町名・番地・その他" />
                      <TextField
                        name="street"
                        autoComplete="off"
                        value={member.street}
                        variant="outlined"
                        onChange={(event) => this.onChangeTextField(event, index)}
                        placeholder="例）2丁目3927-15"
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                      />
                    </Box>
                    {companyId !== 4 && (
                      <Box mb={3}>
                        <FormTitle title="メールアドレス" isRequired />
                        <TextField
                          name="email"
                          autoComplete="off"
                          value={member.email}
                          variant="outlined"
                          onChange={(event) => this.onChangeTextField(event, index)}
                          error={errorMessages[index].email !== ''}
                          placeholder="例）example@email.com"
                          inputProps={{ maxLength: 80 }}
                          fullWidth
                        />
                        <FormErrorText>{errorMessages[index].email}</FormErrorText>
                      </Box>
                    )}
                    <Box mb={3}>
                      <FormTitle title="電話番号" isRequired={companyId !== 4} />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="phoneBefore"
                              autoComplete="off"
                              value={member.phoneBefore}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneField(event, index)}
                              placeholder="025"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phone !== ''}
                              inputProps={{ maxLength: 5 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="phoneMiddle"
                              autoComplete="off"
                              value={member.phoneMiddle}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneField(event, index)}
                              placeholder="2502"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phone !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="phoneAfter"
                              autoComplete="off"
                              value={member.phoneAfter}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneField(event, index)}
                              placeholder="7112"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phone !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <FormErrorText>{errorMessages[index].phone}</FormErrorText>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="電話番号2" />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="phoneTwoBefore"
                              autoComplete="off"
                              value={member.phoneTwoBefore}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneTwoField(event, index)}
                              placeholder="090"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phoneTwo !== ''}
                              inputProps={{ maxLength: 5 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="phoneTwoMiddle"
                              autoComplete="off"
                              value={member.phoneTwoMiddle}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneTwoField(event, index)}
                              placeholder="1234"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phoneTwo !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} p={2}><span>-</span></Box>
                          <Box>
                            <TextField
                              name="phoneTwoAfter"
                              autoComplete="off"
                              value={member.phoneTwoAfter}
                              variant="outlined"
                              onChange={(event) => this.onChangePhoneTwoField(event, index)}
                              placeholder="5678"
                              className={classes.phoneTextField}
                              error={errorMessages[index].phoneTwo !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <FormErrorText>{errorMessages[index].phoneTwo}</FormErrorText>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="生年月日" />
                      <FormControl component="fieldset">
                        <Box display="flex">
                          <Box>
                            <TextField
                              name="birthdayYear"
                              autoComplete="off"
                              value={member.birthdayYear}
                              onChange={(event) => this.onChangeBirthday(event, index)}
                              variant="outlined"
                              placeholder="年"
                              className={classes.smallTextFieldDate}
                              error={errorMessages[index].birthdayNoRequired !== ''}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} pr={2} pl={1}><span>年</span></Box>
                          <Box>
                            <FormControl variant="outlined" placeholder="月" error={errorMessages[index].birthdayNoRequired !== ''} className={classes.smallTextFieldDate}>
                              <Select native name="birthdayMonth" id="birthdayMonthUser" onChange={(event) => this.onChangeBirthday(event, index)} value={member.birthdayMonth}>
                                <option aria-label="None" value="" />
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box className={classes.textCenter} pr={2} pl={1}><span>月</span></Box>
                          <Box>
                            <TextField
                              name="birthdayDay"
                              autoComplete="off"
                              value={member.birthdayDay}
                              onChange={(event) => this.onChangeBirthday(event, index)}
                              variant="outlined"
                              placeholder="日"
                              className={classes.smallTextFieldDate}
                              error={errorMessages[index].birthdayNoRequired !== ''}
                              inputProps={{ maxLength: 2 }}
                            />
                          </Box>
                          <Box className={classes.textCenter} pr={2} pl={1}><span>日</span></Box>
                        </Box>
                        <Box display="flex">
                          <FormErrorText>{ errorMessages[index].birthdayNoRequired }</FormErrorText>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box mb={3}>
                      <FormTitle title="性別" isRequired />
                      <RadioGroup row aria-label="gender" name="mstCodeGenderCode" id="genderUser" value={member.gender} onChange={(event) => this.onChangeRadio(event, index)}>
                        {initSelectValues.genderSelectList.map((option) => (
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            key={option.id}
                            value={option.id}
                            label={option.name}
                            checked={member.mstCodeGenderCode === option.id}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </Paper>
                );
              })}

              <Box mt={3}>
                <Button variant="contained" onClick={this.onClickUserAdd}>ご利用者情報をさらに追加する</Button>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Box align="center" textAlign="center" mt={5} display={{ xs: 'block', sm: 'flex' }} justifyContent="center">
          <Box>
            <Button
              variant="contained"
              onClick={this.onClickGoBack}
              startIcon={<ArrowBackIosIcon />}
              className={classes.buttonFirst}
            >
              ご契約者様情報へ戻る
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onClickGoNext}
              endIcon={<ArrowForwardIosIcon />}
            >
              { isFromConfirmForm === true ? '入力内容の確認へ進む' : 'ログイン情報の入力へ進む' }
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ entry: state.entry, common: state.common });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(UserForm)));
