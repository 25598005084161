const calendarTimeValidation = (limit) => {
  if (!limit) return '入力してください';
  return '';
};

const limitNumberCheck = (value) => {
  if (value === '' || value === null) return '入力してください';
  const regex = /^([1-9]\d*|0)$/;
  if (!regex.test(value)) return '数値を入力してください';
  return '';
};

const unlimitNumberCheck = (value) => {
  if (!value) return '';
  const regex = /^([1-9]\d*|0)$/;
  if (!regex.test(value)) return '数値を入力してください';
  return '';
};

const compareTimeCheck = (startTime, endTime) => {
  if (startTime > endTime) return '開始時以降の値を入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'startTime':
      case 'endTime':
      case 'advanceReferencedOn':
        return calendarTimeValidation(value);
      case 'cancelDay':
      case 'closeDay':
      case 'reservationUnit':
      case 'freeCloseTime':
      case 'freeCancelTime':
      case 'lessonCloseTime':
      case 'lessonCancelTime':
      case 'targetCloseTime':
      case 'targetCancelTime':
      case 'cultureBeforeTime':
      case 'cultureAfterTime':
      case 'transferCloseDay':
      case 'limitOnce':
      case 'limitTwice':
      case 'limitThree':
      case 'advanceInterval':
      case 'advanceNumber':
      case 'memberPossibleEnterNumber':
      case 'teacherPossibleEnterNumber':
        return limitNumberCheck(value);
      case 'freeAdvanceNumber':
      case 'lessonAdvanceNumber':
      case 'targetAdvanceNumber':
        return unlimitNumberCheck(value);
      default:
        return '';
    }
  };

  static compareTime = (startTime, endTime) => compareTimeCheck(startTime, endTime);
}
export default Validation;
