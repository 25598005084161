import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { isValid } from 'date-fns';
import {
  Button, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import {
  actCallApiCalendarScheduleFlgReset,
  actCallApiCalendarScheduleDelete,
} from '../../../../redux/share/calendarScheduleDetail/action';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import Datepicker from '../../../../components/atoms/datepicker/Datepicker';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiCalendarScheduleRoomCreate, actCallRoomCreateFlg } from '../../../../redux/event/eventCalendarDetailRoom/action';

const obj = {
  isShowPublishDate: false,
  publishedOn: null,
  publishedOnErrorMessage: '',
};

class EventCalendarRoomDialog extends Component {
  constructor(props) {
    super(props);
    this.state = obj;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  init = () => {
    const { targetedOn, monthly } = this.props;
    let isShowPublishDate = true;
    let publishedOn = null;
    const targets = monthly.filter((row) => row.targetDate === targetedOn);
    if (targets[0].publishDate) {
      isShowPublishDate = false;
      publishedOn = targets[0].publishDate;
    }

    this.setState({
      ...obj,
      isShowPublishDate,
      publishedOn,
    });
  }

  onClickDelete = () => {
    const { selectedId, onClose } = this.props;
    onClose();
    this.props.dispatch(actCallApiCalendarScheduleFlgReset());
    this.props.dispatch(actCallApiCalendarScheduleDelete(selectedId));
  }

  onClickCreate = () => {
    const {
      selectedRoom, startTime, endTime, storeId, targetedOn, headerId, onClose,
    } = this.props;
    const { isShowPublishDate, publishedOn } = this.state;
    const publishedOnErrorMessage = this.dateValidation(publishedOn);
    this.setState({ publishedOnErrorMessage });

    if (isShowPublishDate && publishedOnErrorMessage) {
      return;
    }

    onClose();

    this.props.dispatch(actCallRoomCreateFlg());
    this.props.dispatch(
      actCallApiCalendarScheduleRoomCreate({
        roomId: selectedRoom.id,
        startTime,
        endTime,
        storeId,
        targetedOn,
        headerId,
        publishedOn,
      }),
    );
  }

  onPublishDateChange = (_, publishedOn) => {
    this.setState({
      publishedOn,
      publishedOnErrorMessage: this.dateValidation(publishedOn),
    });
  }

  dateValidation = (value) => {
    if (!value) return '入力してください';
    if (!isValid(new Date(value))) return '無効な日付です';
    return '';
  };

  render() {
    const {
      open, onClose,
      selectedRoom, startTime, endTime,
      selectedId,
    } = this.props;
    const {
      isShowPublishDate,
      publishedOn,
      publishedOnErrorMessage,
    } = this.state;
    const addFlg = !selectedId;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={onClose}>
          <DialogTitle>{addFlg ? '予約枠の作成' : '予約枠の削除'}</DialogTitle>
          <DialogContent dividers>
            <Box mb={3}>
              <Box display={addFlg ? '' : 'none'}>
                <Box my={1} mb={3} fontSize={17}>
                  {`${selectedRoom.name}に${startTime}~${endTime}の予約枠を作成します`}
                </Box>
              </Box>
              <Box display={addFlg ? 'none' : ''} my={1} fontSize={17}>
                {`${selectedRoom.name}の${startTime}~${endTime}の予約枠を削除します`}
              </Box>
              { (isShowPublishDate && addFlg) && (
                <Box mt={3}>
                  <FormTitle title="公開日" isRequired />
                  <Datepicker
                    handleChange={this.onPublishDateChange}
                    formatter="yyyy/MM/dd HH:mm"
                    value={publishedOn}
                    error={publishedOnErrorMessage !== ''}
                  />
                  <FormErrorText>{publishedOnErrorMessage}</FormErrorText>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              キャンセル
            </Button>
            <Button
              style={{ display: !addFlg ? '' : 'none' }}
              onClick={this.onClickDelete}
              color="secondary"
            >
              削除する
            </Button>
            <Button
              style={{ display: addFlg ? '' : 'none' }}
              onClick={this.onClickCreate}
              color="primary"
            >
              作成する
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  weekScheduleMenu: state.weekScheduleMenu,
  calendarDetailRoom: state.calendarDetailRoom,
});

export default connect(mapStateToProps)(EventCalendarRoomDialog);
