import React, { Component } from 'react';
import {
  withStyles, Paper, Box, Container, List, ListItem,
  ListItemText, Button, Card, CardContent, Typography, Grid,
} from '@material-ui/core';
import ja from 'date-fns/locale/ja';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import { Alert, AlertTitle } from '@material-ui/lab';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { actCallInitHome, actCallApiHome } from '../../../redux/other/home/action';
import urls from '../../../constants/urls';

const useStyles = ((theme) => ({
  list: {
    display: 'inline-block',
    width: '40%',
    marginTop: 70,
    padding: 15,
    marginRight: 20,
    marginBottom: 40,
  },
  root: {
    width: '250px',
    height: '100px',
    textAlign: 'center',
    padding: 10,
    marginRight: 20,
    marginTop: 40,
  },
  container: {
    marginTop: 40,
  },
  iconRoot: {
    float: 'left',
    color: '#FFFFFF',
    padding: '15px',
    borderRadius: '3px',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)',
    justifyContent: 'center',
    display: 'flex',
  },
  textRoot: {
    float: 'center',
    color: '#FFFFFF',
    padding: '10px',
    borderRadius: '3px',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)',
    justifyContent: 'center',
    display: 'flex',
  },
  iconSize: {
    fontSize: '2.0rem',
  },
  button: {
    position: 'relative',
    top: '15px',
  },
  card: {
    width: '100%',
    display: 'flex',
    padding: '20px',
  },
  store: {
    position: 'relative',
    top: '10px',
  },
  padding0: {
    padding: 0,
  },
  selectBox: {
    width: '250px',
  },
  date: {
    padding: 20,
  },
  alertStyle: {
    width: '100%',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

class Home extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallInitHome());

    const date = format(new Date(), 'yyyy年MM月dd日 (eeee)', { locale: ja });

    let isMain = false;
    let searchStore = null;
    if (this.props.common.user) {
      isMain = this.props.common.user.main;
      searchStore = this.props.common.user.store;
      this.props.dispatch(actCallApiHome(searchStore.id));
    }

    this.state = {
      data: {
        eventList: [],
        schoolList: [],
        cultureCancelLogCount: 0,
        eventCount: 0,
        schoolCount: 0,
        total: 0,
      },
      stores: [],
      isMain,
      searchStore,
      date,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.home.data !== this.props.home.data) {
      if (this.props.home.data) {
        this.setData(this.props.home.data);
      }
    }
    if (prevProps.home.stores !== this.props.home.stores) {
      if (this.props.home.stores) {
        this.setStores(this.props.home.stores);
      }
    }
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
  }

  setData = (data) => {
    this.setState({ data });
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setUser(user) {
    this.setState({ isMain: user.main, searchStore: user.store });
    this.props.dispatch(actCallApiHome(user.store.id));
  }

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  }

  onSearch = () => {
    const { searchStore } = this.state;
    this.props.dispatch(actCallApiHome(searchStore.id));
  }

  teacherContractClick = () => {
    this.props.history.push({
      pathname: urls.INFO.TEACHER_CONTACT_TASK_LIST,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      data, date, stores, searchStore, isMain,
    } = this.state;
    return (
      <div>
        <Container className={classes.container}>
          <Card variant="outlined">
            <CardContent className={classes.date}>
              <Typography variant="h5" component="h2">
                {date}
              </Typography>
            </CardContent>
          </Card>
          <Box display={isMain ? 'flex' : 'none'} mb={3} mt={3}>
            <Card className={classes.card} variant="outlined">
              <Box fontWeight="fontWeightBold" p={1} mr={2} fontSize={17} component="span" className={classes.store}>店舗</Box>
              <Box className={classes.selectBox} mr={2}>
                <SearchSelectBox
                  name="searchStore"
                  options={stores}
                  value={searchStore}
                  onChange={this.onStoreChange}
                  disableClearable
                />
              </Box>
              <Box>
                <Button onClick={this.onSearch} variant="contained" color="primary" size="small" component="span" className={classes.button}>店舗切り替え</Button>
              </Box>
            </Card>
          </Box>
          <Box display={data.cultureCancelLogCount > 0 ? 'flex' : 'none'} mb={3} mt={3}>
            <div className={classes.alertStyle}>
              <Alert severity="error">
                <AlertTitle>
                  <Box>休講となった講義が存在します。</Box>
                </AlertTitle>
                <Box display="flex">
                  <div className={classes.link} onClick={() => this.teacherContractClick()} aria-hidden="true">
                    こちら
                  </div>
                  から確認の上、先生へ連絡をしてください。
                </Box>
              </Alert>
            </div>
          </Box>
          <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
            <Paper elevation={3} className={classes.root} style={{ display: data.eventList.length === 0 && data.schoolList.length === 0 ? 'none' : '' }}>
              <Box textAlign="right">
                <Box className={classes.iconRoot} mt="-28px" style={{ backgroundColor: '#B1CA02' }}><EventAvailableIcon className={classes.iconSize} /></Box>
                <Box fontSize={20}>本日の総数</Box>
                <Box fontSize={30} fontWeight="bold" mt={1} mr={2}>{data.total}</Box>
              </Box>
            </Paper>
            <Paper elevation={3} className={classes.root} style={{ display: data.eventList.length === 0 ? 'none' : '' }}>
              <Box textAlign="right">
                <Box className={classes.iconRoot} mt="-28px" style={{ backgroundColor: '#FF9900' }}><DirectionsRunIcon className={classes.iconSize} /></Box>
                <Box fontSize={20}>本日の予約者</Box>
                <Box fontSize={30} fontWeight="bold" mt={1} mr={2}>{data.eventCount}</Box>
              </Box>
            </Paper>
            <Paper elevation={3} className={classes.root} style={{ display: data.schoolList.length === 0 ? 'none' : '' }}>
              <Box textAlign="right">
                <Box className={classes.iconRoot} mt="-28px" style={{ backgroundColor: '#228866' }}><EmojiPeopleIcon className={classes.iconSize} /></Box>
                <Box fontSize={20}>本日の予定者</Box>
                <Box fontSize={30} fontWeight="bold" mt={1} mr={2}>{data.schoolCount}</Box>
              </Box>
            </Paper>
          </Grid>

          <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
            <Paper elevation={3} className={classes.list} style={{ display: data.eventList.length === 0 ? 'none' : '' }}>
              <List className={classes.padding0}>
                <Box className={classes.textRoot} mt="-33px" fontSize={20} fontWeight="bold" style={{ backgroundColor: '#FF9900' }}>本日の予約者</Box>
                <ListItem className={classes.padding0}>
                  <ListItemText>
                    {data.eventList.map((count) => (
                      <Box key={count.id} mt={1}>
                        {count.contentName}
                      </Box>
                    ))}
                  </ListItemText>
                  <ListItemText>
                    {data.eventList.map((count) => (
                      <Box key={count.id} mt={1} ml={3}>{count.count}</Box>
                    ))}
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Paper elevation={3} className={classes.list} style={{ display: data.schoolList.length === 0 ? 'none' : '' }}>
              <List className={classes.padding0}>
                <Box className={classes.textRoot} mt="-33px" fontSize={20} fontWeight="bold" style={{ backgroundColor: '#228866' }}>本日の予定者</Box>
                <ListItem className={classes.padding0}>
                  <ListItemText>
                    {data.schoolList.map((count) => (
                      <Box key={count.id} mt={1}>
                        {count.contentName}
                      </Box>
                    ))}
                  </ListItemText>
                  <ListItemText>
                    {data.schoolList.map((count) => (
                      <Box key={count.id} mt={1} ml={3}>{count.count}</Box>
                    ))}
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ home: state.home, common: state.common });
export default withStyles(useStyles)(connect(mapStateToProps)(Home));
