// region Type
export const ACTION_TYPE = {
  CALL_API_INIT_ROLE_LIST: 'CALL_API_INIT_ROLE_LIST',
  CALL_API_INIT_ROLE_LIST_SUCCESS: 'CALL_API_INIT_ROLE_LIST_SUCCESS',
  CALL_API_ROLE_LIST_SEARCH: 'CALL_API_ROLE_LIST_SEARCH',
  CALL_API_ROLE_LIST_SEARCH_SUCCESS: 'CALL_API_ROLE_LIST_SEARCH_SUCCESS',
  CALL_API_ROLE_LIST_DELETE: 'CALL_API_ROLE_LIST_DELETE',
  CALL_API_ROLE_LIST_DELETE_SUCCESS: 'CALL_API_ROLE_LIST_DELETE_SUCCESS',
  SET_ROLE_SEARCH_CONDITION: 'SET_ROLE_SEARCH_CONDITION',
  RESET_ROLE_LIST_FLG: 'RESET_ROLE_LIST_FLG',
};
// endregion

// region action
export const actCallApiSelectRoleList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_ROLE_LIST,
});

export const actCallApiSelectRoleListSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_INIT_ROLE_LIST_SUCCESS,
  stores,
});

export const actCallApiGetRoleList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_ROLE_LIST_SEARCH,
  searchCondition,
});

export const actCallApiGetRoleListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_ROLE_LIST_SEARCH_SUCCESS,
  datas,
});

export const actCallApiRoleListDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_ROLE_LIST_DELETE,
  values,
});

export const actCallApiRoleListDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_ROLE_LIST_DELETE_SUCCESS,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_ROLE_SEARCH_CONDITION,
  searchCondition,
});

export const actResetRoleListFlg = () => ({
  type: ACTION_TYPE.RESET_ROLE_LIST_FLG,
});
// endregion
