import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import format from 'date-fns/format';

import MainBody from '../../../components/templates/mainBody/MainBody';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Monthpicker from '../../../components/atoms/monthpicker/Monthpicker';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import urls from '../../../constants/urls';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SchoolWeekScheduleDialog from '../schoolWeekScheduleDialog/SchoolWeekScheduleDialog';
import Validation from '../schoolWeekScheduleDialog/validation';
import {
  actCallInitSchoolScheduleList, actCallApiSchoolScheduleList,
  actCallApiSchoolScheduleListDelete, actCallApiSchoolScheduleHeaderInfo,
  actSetSearchCondition, actApiSchoolScheduleListFlgReset,
} from '../../../redux/school/schoolWeekScheduleList/action';

class SchoolWeekScheduleList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallInitSchoolScheduleList());

    this.breadcrumbsText = [
      { name: '定期曜日別スケジュール一覧' },
    ];

    this.headCells = [
      { id: 'contentName', numeric: false, label: 'コンテンツ' },
      { id: 'name', numeric: false, label: '対象年月' },
      { id: 'storeName', numeric: false, label: '店舗' },
    ];

    let isMain = false;
    let defaultStore = null;
    let searchStore = null;
    if (this.props.common.user) {
      isMain = this.props.common.user.main;
      defaultStore = this.props.common.user.store;
      searchStore = this.props.common.user.store;
    }

    let page = 0;
    let records = 10;
    let searchContents = null;
    let date = format(new Date(), 'yyyy/MM');

    if (this.props.schoolWeekSchedules.searchCondition) {
      searchStore = this.props.schoolWeekSchedules.searchCondition.searchStore;
      searchContents = this.props.schoolWeekSchedules.searchCondition.searchContents;
      date = this.props.schoolWeekSchedules.searchCondition.date;
      page = this.props.schoolWeekSchedules.searchCondition.page;
      records = this.props.schoolWeekSchedules.searchCondition.records;
      const error = Validation.formValidate('date', date);
      if (error) {
        date = null;
      }
    }

    this.search(page, records, searchStore, searchContents, date);

    this.state = {
      isMain,
      defaultStore,
      selects: {
        stores: [],
        contents: [],
        storeContentLinks: [],
      },
      date,
      searchStore,
      searchContents,
      datas: {},
      page,
      records,
      successSnackOpen: false,
      scheduleModalOpen: false,
      linkedContents: [],
    };
  }

  componentDidMount() {
    document.title = '定期曜日別スケジュール一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
        this.onStoreChangeSearch(this.props.common.user.store);
      }
    }
    if (prevProps.schoolWeekSchedules.selects !== this.props.schoolWeekSchedules.selects) {
      if (this.props.schoolWeekSchedules.selects) {
        this.setSelects(this.props.schoolWeekSchedules.selects);
      }
    }
    if (prevProps.schoolWeekSchedules.datas !== this.props.schoolWeekSchedules.datas) {
      if (this.props.schoolWeekSchedules.datas) {
        this.setDatas(this.props.schoolWeekSchedules.datas);
      }
    }
    if (prevProps.schoolWeekSchedules.isDeleteSuccess
      !== this.props.schoolWeekSchedules.isDeleteSuccess) {
      if (this.props.schoolWeekSchedules.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.schoolWeekSchedules.headerData !== this.props.schoolWeekSchedules.headerData) {
      if (this.props.schoolWeekSchedules.headerData) {
        this.openCopyScheduleDialog(this.props.schoolWeekSchedules.headerData);
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main, defaultStore: user.store });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  onStoreChangeSearch = (values) => {
    const { date } = this.state;
    this.setState({ searchStore: values });
    this.search(0, 10, values, null, null, date);
  }

  setSelects(selects) {
    const { searchStore } = this.state;
    this.setState({
      selects,
      linkedContents: this.mapStoreContentLinks(searchStore, selects.storeContentLinks),
    });
  }

  onStoreChange = (event) => {
    const { value } = event.target;
    this.setState({ searchStore: value });
    if (!value) {
      return;
    }
    const { searchContents, selects } = this.state;
    let newContents = searchContents;
    const linkedContents = this.mapStoreContentLinks(value, selects.storeContentLinks);
    if (searchContents && !linkedContents.includes(searchContents.id)) {
      newContents = null;
    }
    this.setState({ linkedContents, searchContents: newContents });
  }

  onContentChange = (event) => {
    this.setState({ searchContents: event.target.value });
  }

  handleDateChange = (_, values) => {
    this.setState({ date: values });
  }

  onSearch = () => {
    const {
      page, records, searchStore, searchContents, date,
    } = this.state;
    this.props.dispatch(actSetSearchCondition({
      page, records, searchStore, searchContents, date,
    }));
    this.search(page, records, searchStore, searchContents, date);
  }

  handleChangePage = (_, newPage) => {
    const {
      searchStore, searchContents, date, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(newPage, records, searchStore, searchContents, date);
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchStore, searchContents, date,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(0, rowsPerPage, searchStore, searchContents, date);
  };

  onDelete = (value) => {
    this.setState({ successSnackOpen: false });
    this.props.dispatch(actApiSchoolScheduleListFlgReset());
    this.props.dispatch(actCallApiSchoolScheduleListDelete({ deleteList: value }));
  }

  setDeleteSuccess() {
    const {
      page, records, searchStore, searchContents, date,
    } = this.state;
    this.search(page, records, searchStore, searchContents, date);
    this.setState({ successSnackOpen: true });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  search = (page, records, searchStore, searchContents, date) => {
    let searchDate = null;
    if (date) {
      searchDate = `${date}/01`;
    }
    this.props.dispatch(actCallApiSchoolScheduleList(
      {
        page,
        records,
        searchStoreId: searchStore
        && searchStore.id,
        searchContentId: searchContents
        && searchContents.id,
        date: searchDate,
      },
    ));
  }

  onAdd = () => {
    const {
      page, records, searchStore, searchContents, date,
    } = this.state;
    this.setState({
      scheduleModalOpen: true,
      copyFlg: false,
      defaultStore: this.props.common.user.store,
      successSnackOpen: false,
      copyContent: null,
    });
    this.props.dispatch(actSetSearchCondition({
      page, records, searchStore, searchContents, date,
    }));
  }

  onCopySchedule = (_, row) => {
    this.props.dispatch(actCallApiSchoolScheduleHeaderInfo(row.id));
  }

  handleModalClose = () => {
    this.setState({ scheduleModalOpen: false });
  }

  onDetail = (_, row) => {
    const { isMain } = this.state;
    this.props.history.push({
      pathname: urls.SCHOOL.WEEK_SCHEDULE_DETAIL,
      state: { id: row.id, isMain },
    });
  }

  mapStoreContentLinks = ((values, storeContentLinks) => (storeContentLinks && values
    ? (storeContentLinks
      .filter((link) => link.mstStoreId === values.id).map((link) => link.mstContentId))
    : []));

  openCopyScheduleDialog(headerData) {
    this.setState({
      scheduleModalOpen: true,
      copyHeaderId: headerData.id,
      copyFlg: true,
      successSnackOpen: false,
      copyContent: { name: headerData.contentName, id: headerData.contentId },
      defaultStore: { name: headerData.storeName, id: headerData.storeId },
    });
  }

  render() {
    const {
      searchStore, searchContents,
      isMain, selects, date,
      page, records, datas, successSnackOpen, scheduleModalOpen, defaultStore,
      copyContent, copyHeaderId, copyFlg, linkedContents,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box width="20%" display={isMain ? '' : 'none'}>
              <FormTitle title="店舗" />
              <SearchSelectBox options={selects.stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" />
            </Box>
            <Box display="flex" mt={2}>
              <Box width="20%" mr={2}>
                <FormTitle title="コンテンツ" />
                <SearchSelectBox
                  options={(searchStore) ? (
                    selects.contents.filter((contentFilter) => selects.contents
                    && linkedContents.includes(contentFilter.id))
                  ) : (selects.contents)}
                  value={searchContents}
                  onChange={this.onContentChange}
                  name="searchContents"
                />
              </Box>
              <Box width="20%">
                <FormTitle title="対象年月" />
                <Monthpicker
                  value={date}
                  handleChange={this.handleDateChange}
                />
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="曜日別スケジュールの追加"
            deleteButtonLabel="曜日別スケジュールの削除"
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            deleteDialogTitle="曜日別スケジュールの削除"
            deleteDialogContent="選択した曜日別スケジュールを削除します。この操作は元に戻すことができません。"
            onDetail={this.onDetail}
            onSecondFunc={this.onCopySchedule}
            secondFuncText="コピーする"
            isMain={isMain}
          />
          <SuccessSnackbar open={successSnackOpen} handleClose={this.handleSuccessSnackbarClose} message="曜日別スケジュールの削除が完了しました" />
        </MainBody>

        <SchoolWeekScheduleDialog
          isMain={isMain}
          stores={selects.stores}
          contents={selects.contents}
          storeContentLinks={selects.storeContentLinks}
          open={scheduleModalOpen}
          handleClose={this.handleModalClose}
          defaultStore={defaultStore}
          copyContent={copyContent}
          copyHeaderId={copyHeaderId}
          copyFlg={copyFlg}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  schoolWeekSchedules: state.schoolWeekSchedules, common: state.common,
});

export default withRouter(connect(mapStateToProps)(SchoolWeekScheduleList));
