/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { format } from 'date-fns';
import {
  Dialog, Button, makeStyles, Slide, AppBar, Toolbar, IconButton, Typography, Container,
  DialogActions, DialogContent, Box, Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
  },
  root: {
    fontSize: '15px',
  },
  tableTitle: {
    background: '#f2f2f2',
    padding: '25px',
    fontSize: '20px',
  },
  tableContent: {
    textAlign: 'center',
    letterSpacing: '3px',
    fontSize: '20px',
  },
  addressTitle: {
    background: '#f2f2f2',
    padding: '10px',
  },
  memberTable: {
    borderCollapse: 'collapse',
    borderColor: '#000000',
  },
  memberTd: {
    textAlign: 'center',
    height: '50px',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FaxDialog(props) {
  const {
    onClose, open, booking, members,
  } = props;
  const classes = useStyles();
  const height = (6 - members.length) < 0 ? 0 : (6 - members.length);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box display="block" displayPrint="none">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>Fax印刷</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <DialogContent>
        <Container className={classes.root}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box fontWeight="fontWeightBold" fontSize={35}>
                  <Box display="flex" justifyContent="center">料理教室</Box>
                  <Box display="flex" justifyContent="center">受講生連絡票</Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <table width="100%">
                  <tr>
                    <td rowSpan={2} className={classes.tableTitle} width="50%">送信日時</td>
                    <td className={classes.tableContent}>{` ${format(new Date(), 'yyyy年MM月dd日')}`}</td>
                  </tr>
                  <tr>
                    <td className={classes.tableContent}>{format(new Date(), 'HH時mm分')}</td>
                  </tr>
                </table>
              </Grid>
            </Grid>
          </Box>
          <Box my={4}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className={classes.addressTitle}>送信先：</Box>
                <Box height="40px" display="flex" alignItems="center"> </Box>
                <Box height="40px" display="flex" alignItems="center" borderTop={1} borderBottom={1} borderColor="grey.900">古町ガスホール　御中</Box>
                <Box height="40px" display="flex" alignItems="center"> </Box>
                <Box height="40px" display="flex" alignItems="center" borderTop={1} borderColor="grey.900">TEL:　025-223-0495</Box>
                <Box height="40px" display="flex" alignItems="center" borderTop={1} borderBottom={1} borderColor="grey.900">FAX:　025-229-0812</Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.addressTitle}>発信者：</Box>
                <Box height="40px" display="flex" alignItems="center">{`〒${booking.storeZipCode}`}</Box>
                <Box height="40px" alignItems="center">{booking.storeAddress && booking.storeAddress.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
                <Box height="40px" display="flex" alignItems="center"> </Box>
                <Box height="40px" display="flex" alignItems="center" borderTop={1} borderColor="grey.900">{`TEL:  ${booking.storePhone}`}</Box>
                <Box height="40px" display="flex" alignItems="center" borderTop={1} borderBottom={1} borderColor="grey.900">{`FAX:  ${booking.storeFax}`}</Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box className={classes.addressTitle} mb={3}>受講生情報：</Box>
            <Box mb={1} ml={2}>{`〇講座名:  ${booking.menuName}`}</Box>
            <Box borderBottom={1} borderColor="grey.900" width="50%"> </Box>
            <Box mt={2} mb={1} ml={2}>{`〇講座日:  ${booking.targetDate}`}</Box>
            <Box borderBottom={1} borderColor="grey.900" width="50%"> </Box>
          </Box>
          <Box mt={4}>
            <table width="100%" border="1" className={classes.memberTable}>
              <tr>
                <th> </th>
                <th className={classes.memberTd}>
                  <Box>会員番号</Box>
                  <Box>(ヴァーテックス様ご記入)</Box>
                </th>
                <th className={classes.memberTd}>
                  <Box>北陸ガス</Box>
                  <Box>照合欄</Box>
                </th>
              </tr>
              {members.map((member, index) => (
                <tr>
                  <td className={classes.memberTd}>{index + 1}</td>
                  <td className={classes.memberTd}>{member.memberNo}</td>
                  <td className={classes.memberTd}> </td>
                </tr>
              ))}
            </table>
          </Box>
          <Box height={`${height * 50}px`}> </Box>
          <Box my={5}>
            <Box className={classes.addressTitle}>備考：</Box>
            <Box height="50px" borderBottom={1} borderColor="grey.900"> </Box>
            <Box height="50px" borderBottom={1} borderColor="grey.900"> </Box>
            <Box height="50px" borderBottom={1} borderColor="grey.900"> </Box>
            <Box height="50px" borderBottom={1} borderColor="grey.900"> </Box>
          </Box>
        </Container>
      </DialogContent>
      <Box display="block" displayPrint="none">
        <DialogActions>
          <Button onClick={onClose}>閉じる</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
