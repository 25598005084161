import React from 'react';
import {
  makeStyles, Box, Button, Paper,
} from '@material-ui/core';
import format from 'date-fns/format';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

const useStyles = makeStyles(() => ({
  headerUpdateBtn: {
    borderTop: '1px solid #eeeeee',
    paddingTop: '10px',
  },
  paper: {
    width: 'fit-content',
    marginBottom: '20px',
  },
}));

export default function BookingHeader(props) {
  const classes = useStyles();
  const {
    header, isMain, handleOpen, bulkChangeModalOpen, contentId,
  } = props;

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <Box display="flex" m={2}>
        <Box mr={10} display={isMain ? '' : 'none'}>
          <FormTitle title="店舗" />
          <Box>{header.storeName}</Box>
        </Box>
        <Box mr={10}>
          <FormTitle title="表示年月" />
          <Box>{format(new Date(header.targetedOn), 'yyyy年MM月')}</Box>
        </Box>
        <Box mr={10}>
          <FormTitle title="コンテンツ" />
          <Box>{header.contentName}</Box>
        </Box>
      </Box>
      <Box className={classes.headerUpdateBtn} textAlign="right" m={1}>
        {contentId && contentId === 2
          ? <Button color="primary" size="medium" onClick={bulkChangeModalOpen}>一括打席変更</Button>
          : ''}
        <Button color="primary" size="medium" onClick={handleOpen}>公開日を変更する</Button>
      </Box>
    </Paper>
  );
}
