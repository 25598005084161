export const ACTION_TYPE = {
  CALL_API_GET_SCREENS: 'CALL_API_GET_SCREENS',
  CALL_API_GET_SCREENS_SUCCESS: 'CALL_API_GET_SCREENS_SUCCESS',
  CALL_API_GET_ROLES: 'CALL_API_GET_ROLES',
  CALL_API_GET_ROLES_SUCCESS: 'CALL_API_GET_ROLES_SUCCESS',
  CALL_API_UPDATE_ROLE_SCREENS: 'CALL_API_UPDATE_ROLE_SCREENS',
  CALL_API_UPDATE_ROLE_SCREENS_SUCCESS: 'CALL_API_UPDATE_ROLE_SCREENS_SUCCESS',
  CALL_API_UPDATE_ROLE: 'CALL_API_UPDATE_ROLE',
  CALL_API_UPDATE_ROLE_SUCCESS: 'CALL_API_UPDATE_ROLE_SUCCESS',
  CALL_API_INSERT_ROLE: 'CALL_API_INSERT_ROLE',
  CALL_API_INSERT_ROLE_SUCCESS: 'CALL_API_INSERT_ROLE_SUCCESS',
  RESET_ROLE_DETAIL_FLG: 'RESET_ROLE_DETAIL_FLG',
  SET_ROLE_CREATED_SUCCESS_MESSAGE: 'SET_ROLE_CREATED_SUCCESS_MESSAGE',
};

export const actCallApiInitScreens = () => ({
  type: ACTION_TYPE.CALL_API_GET_SCREENS,
});

export const actCallApiInitScreensSuccess = (screens) => ({
  type: ACTION_TYPE.CALL_API_GET_SCREENS_SUCCESS,
  screens,
});

export const actCallApiGetRole = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLES,
  param,
});

export const actCallApiGetRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLES_SUCCESS,
  data,
});

export const actCallApiUpdateRoleScreen = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREENS,
  param,
});

export const actCallApiUpdateRoleScreenSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREENS_SUCCESS,
  datas,
});

export const actCallApiInsertRole = (param) => ({
  type: ACTION_TYPE.CALL_API_INSERT_ROLE,
  param,
});

export const actCallApiInsertRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_INSERT_ROLE_SUCCESS,
  data,
});

export const actCallApiUpdateRole = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE,
  param,
});

export const actCallApiUpdateRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE_SUCCESS,
  data,
});

export const actResetRoleDetailFlg = () => ({
  type: ACTION_TYPE.RESET_ROLE_DETAIL_FLG,
});

export const actSetRoleCreateSuccessMessage = () => ({
  type: ACTION_TYPE.SET_ROLE_CREATED_SUCCESS_MESSAGE,
});
