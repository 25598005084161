const urls = {
  OTHER: {
    HOME: '/',
    LOGIN: '/login',
  },
  SETTING: {
    RULES: '/rules',
    BANNERS: '/banners',
    MAIL_LIST: '/mails',
    MAIL_DETAIL: '/mails/detail',
    SITE_SETTING: '/site-settings',
    SURVEY_SETTING: '/survey-settings',
    HOLIDAY: '/holidays',
  },
  USER_SETTING: {
    ROLES: '/roles',
    ROLE_DETAIL: '/roles/detail',
    SYSTEM_USERS: '/system-users',
  },
  INFO: {
    HEALTH_CHECK: '/health-checks',
    CLASS_INFOMATION: '/class-infos',
    CLASS_INFOMATION_DETAIL: '/class-infos/detail',
    ROOM_INFO: '/room-info',
    ROOM_INFO_DETAIL: '/room-info/detail',
    TEACHER_CONTACT_TASK_LIST: '/teacher-contact-tasks',
    ATTENDANCE_ENTRY_LIST: '/attendance-entries',
    ATTENDANCE_ENTRY_DETAIL: '/attendance-entries/detail',
  },
  BOOKING: {
    BOOKING_LIST: '/bookings',
    BOOKING_DETAIL: '/bookings/detail',
    SUBSCRIBER_LIST: '/subscribers',
    ROOM_SCHEDULES: '/room-schedules',
    GOLF_SCHEDULES: '/golf-schedules',
  },
  MEMBER: {
    MEMBER_LIST: '/members',
    INDIVIDUAL_ENTRY: '/members/individual',
    CORPORATION_ENTRY: '/members/corporation',
    MEMBER_DETAIL: '/members/detail',
    MEMBER_DETAIL_WITHDRAWAL: '/members/detail/withdrawal',
  },
  SCHOOL: {
    WEEK_SCHEDULES: '/school-week-schedules',
    WEEK_SCHEDULE_DETAIL: '/school-week-schedules/detail',
    SCHOOL_CALENDARS: '/school-calendars',
    SCHOOL_CALENDARS_DETAIL: '/school-calendars/detail',
    MEMBER_LINKAGE: '/school-schedules/linkage',
    WEEK_SELECT: '/week-selects',
  },
  EVENT: {
    WEEK_SCHEDULES: '/event-week-schedules',
    WEEK_SCHEDULES_DETAIL: '/event-week-schedules/detail',
    EVENT_CALENDARS: '/event-calendars',
    EVENT_CALENDARS_DETAIL: '/event-calendars/detail',
    ROOM_CALENDAR_DETAIL: '/event-calendars/room',
    GOLF_CALENDAR_DETAIL: '/event-calendars/golf',
  },
  MASTER: {
    TEACHER_LIST: '/teachers',
    NOTICE_LIST: '/notices',
    NOTICE_DETAIL: '/notices/detail',
  },
  SYSTEM_ERROR: '/error',
};
export default urls;
