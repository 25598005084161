const membershipValidation = (limit) => {
  if (!limit) return '入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'membership':
      case 'privacyPolicy':
        return membershipValidation(value);
      default:
        return '';
    }
  };
}
export default Validation;
