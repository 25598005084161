import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * イベント系カレンダー作成の予約枠ヘッダー検索
 *
 * @param {object} id
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDER_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * イベント系カレンダー作成用の曜日別スケジュールリスト取得する
 * @param {*}
 */
export function getWeekSchedules(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDAR_LIST_WEEK_SCHEDULES, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * スクールカレンダー作成
 * @param {*}
 */
export function createDatas(params) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDER_LIST, params)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * スクール系カレンダー作成の体験者チェック
 * @param {object} id
 */
export function experiencerCheck(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * モーダル用のデータを取得する
 * @param {*}
 */
export function getModalData(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDAR_LIST_MODAL_DATA, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 予約枠リセット
 * @param {*} resetCondition
 */
export function resetDatas(resetCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.SCHOOL.SCHOOL_CALENDER.SCHOOL_CALENDER_LIST, resetCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
