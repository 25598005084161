import { ACTION_TYPE } from './action';

const holiday = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        isError: false,
      };
    case ACTION_TYPE.CALL_API_HOLIDAY_GET_STORE_SUCCESS:
      return {
        ...state,
        stores: action.datas,
        isError: false,
      };
    case ACTION_TYPE.HOLIDAY_CREATE_SUCCESS:
      return {
        ...state,
        isCreatedSuccess: true,
        isError: false,
      };
    case ACTION_TYPE.HOLIDAY_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
        isError: false,
      };
    case ACTION_TYPE.HOLIDAY_FLG_RESET:
      return {
        ...state,
        isCreatedSuccess: false,
        isDeleteSuccess: false,
        isError: false,
      };
    default:
      return state;
  }
};

export default holiday;
