import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import getDate from 'date-fns/getDate';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';

function isSomeMonth(d1, d2) {
  return (d1.getFullYear() + d1.getMonth()) - (d2.getFullYear() + d2.getMonth()) === 0;
}

export function lowerThanDate(date1, date2) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth() + 1;
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth() + 1;
  const day2 = date2.getDate();

  if (year1 === year2) {
    if (month1 === month2) {
      return day1 < day2;
    }

    return month1 < month2;
  }
  return year1 < year2;
}

export function getMonthly(paramDate) {
  const targetDate = new Date(paramDate);

  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(targetDate),
      end: endOfMonth(targetDate),
    },
    { weekStartsOn: 1 },
  );
  const monthly = weeks.map((week) => eachDayOfInterval(
    {
      start: week,
      end: endOfWeek(week, { weekStartsOn: 1 }),
    },
  ));
  const monthArray = [];
  monthly.forEach((item) => {
    const weekArray = [];
    item.forEach((fnsDate) => {
      weekArray.push(
        { date: fnsDate, day: getDate(fnsDate), isSomeMonth: isSomeMonth(fnsDate, targetDate) },
      );
    });
    monthArray.push(weekArray);
  });
  return monthArray;
}

export function getSpecificDateDatas(date, datas) {
  const targetDate = format(date, 'yyyy/MM/dd');
  return datas.filter((row) => row.targetedOn === targetDate);
}

export function getPublishDate(date, datas) {
  const targetDate = format(date, 'yyyy/MM/dd');
  const targets = datas.filter((row) => row.targetedOn === targetDate);
  if (targets.length > 0) {
    return `公開日:${targets[0].publishedOn}`;
  }
  return null;
}
