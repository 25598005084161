import { ACTION_TYPE } from './action';

const calendarDetailGolf = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_CALENDAR_GOLF_HEADER_SUCCESS:
      return {
        ...state,
        header: action.data.header,
        calendarList: action.data.calendarList,
      };
    case ACTION_TYPE.CALL_API_UPDATE_GOLF_PUBLISH_DATE_UPDATE_SUCCESS:
      return {
        ...state,
        isPublishDateUpdate: true,
      };
    case ACTION_TYPE.CALL_API_GET_GOLF_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_GOLF_FRAME_UPDATE_SUCCESS:
      return {
        ...state,
        isFrameUpdated: true,
      };
    case ACTION_TYPE.CALL_API_CALENDAR_GOLF_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        isBulkFrameUpdate: true,
      };
    case ACTION_TYPE.CALL_GOLF_BOOKING_RESET_FLG:
      return {
        ...state,
        isFrameUpdated: false,
        isPublishDateUpdate: false,
        isBulkFrameUpdate: false,
      };
    default:
      return state;
  }
};

export default calendarDetailGolf;
