import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE,
  actInitHomeSuccess, actApiHomeSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { getStores } from '../../../services/common/selectList.service';
import getData from '../../../services/other/home.service';

/**
 * 初期表示
 *
 */
function* callApiInitHome() {
  try {
    const stores = yield call(getStores);
    yield put(actInitHomeSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索表示
 *
 */
function* callApiHome(storeId) {
  try {
    const data = yield call(getData, storeId);
    yield put(actApiHomeSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

export default function* watchCallHome() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_HOME, callApiInitHome);
  yield takeLatest(ACTION_TYPE.ACT_CALL_API_HOME, callApiHome);
}
