import React from 'react';
import {
  TextField, Box, Typography, Button, withStyles,
} from '@material-ui/core';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { getRegularDatas, checkValidation } from './rule-list-func';
import Validation from './validation';

const styles = () => ({
  ruleLabel: {
    position: 'relative',
    top: '25px',
    left: '10px',
  },
});

class RegularRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: getRegularDatas(),
      data: getRegularDatas(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 4) {
        this.setData(this.props.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ data: data || getRegularDatas(), messages: getRegularDatas() });
  }

  onChange = (event) => {
    const { data, messages } = this.state;
    const { value, name } = event.target;
    const tempMessage = { ...messages, [name]: Validation.formValidate(name, value) };
    this.setState({
      data: { ...data, [name]: value },
      messages: tempMessage,
    });
  }

  onSave = (event) => {
    const { data, messages } = this.state;
    const errObj = checkValidation(data, messages);
    this.setState({ messages: errObj.messages });
    if (errObj.isError) return;
    this.props.onSave(event, data);
  }

  render() {
    const {
      value, classes,
    } = this.props;

    const { data, messages } = this.state;

    return (
      <TabPanel value={value} index={4}>
        <Box p={2}>
          <Box textAlign="right">
            <Button onClick={this.onSave} color="primary" size="medium">保存する</Button>
          </Box>
          <Box m={3}>
            <FormTitle title="振替受付締切日" isRequired />
            <TextField
              variant="outlined"
              name="transferCloseDay"
              value={data.transferCloseDay}
              onChange={this.onChange}
              error={messages.transferCloseDay !== ''}
              inputProps={{ maxLength: 2 }}
            />
            <Typography className={classes.ruleLabel} component="span">日前</Typography>
            <FormErrorText>{messages.transferCloseDay}</FormErrorText>
          </Box>
          <Box m={3}>
            <FormTitle title="振替制限" isRequired />
            <Box display="flex">
              <Box m={1}>
                <Box mb={1} fontWeight="fontWeightBold" fontSize={15}>週1回</Box>
                <TextField
                  variant="outlined"
                  name="limitOnce"
                  style={{ width: '50%' }}
                  value={data.limitOnce}
                  onChange={this.onChange}
                  error={messages.limitOnce !== ''}
                  inputProps={{ maxLength: 9 }}
                />
                <FormErrorText>{messages.limitOnce}</FormErrorText>
              </Box>
              <Box m={1}>
                <Box mb={1} fontWeight="fontWeightBold" fontSize={15}>週2回</Box>
                <TextField
                  variant="outlined"
                  name="limitTwice"
                  style={{ width: '50%' }}
                  value={data.limitTwice}
                  onChange={this.onChange}
                  error={messages.limitTwice !== ''}
                  inputProps={{ maxLength: 9 }}
                />
                <FormErrorText>{messages.limitTwice}</FormErrorText>
              </Box>
              <Box m={1}>
                <Box mb={1} fontWeight="fontWeightBold" fontSize={15}>週3回</Box>
                <TextField
                  variant="outlined"
                  name="limitThree"
                  style={{ width: '50%' }}
                  value={data.limitThree}
                  onChange={this.onChange}
                  error={messages.limitThree !== ''}
                  inputProps={{ maxLength: 9 }}
                />
                <FormErrorText>{messages.limitThree}</FormErrorText>
              </Box>
            </Box>
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

export default withStyles(styles)(RegularRules);
