import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, Box,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import {
  actCallApiInsertRole, actCallApiUpdateRole, actResetRoleDetailFlg, actSetRoleCreateSuccessMessage,
} from '../../../redux/userSetting/roleDetail/action';
import urls from '../../../constants/urls';
import LoadingButton from '../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class RoleDetailDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        mstStore: null,
        name: '',
      },
      stores: [],
      isMain: false,
      nameErrorMessage: '',
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.common.isLoading !== this.props.common.isLoading) {
      this.setChangeLoading(this.props.common.isLoading);
    }
    if (prevProps.roleDetail.isCreatedSuccess !== this.props.roleDetail.isCreatedSuccess) {
      if (this.props.roleDetail.isCreatedSuccess) {
        this.goRoleDetail(this.props.roleDetail.createData);
      }
    }
  }

  setChangeLoading(btnLoading) {
    this.setState({ btnLoading });
  }

  handleSave = () => {
    const { info } = this.state;
    const { roleId } = this.props;

    const tempMessage = this.checkName(info.name);

    this.setState({ nameErrorMessage: tempMessage, btnLoading: !tempMessage });

    if (tempMessage) {
      return;
    }

    this.props.dispatch(actResetRoleDetailFlg());

    if (!roleId) {
      this.props.dispatch(actCallApiInsertRole({
        storeId: info.mstStore.id,
        authName: info.name,
      }));
    } else {
      this.props.dispatch(actCallApiUpdateRole({
        storeId: info.mstStore.id,
        authName: info.name,
        roleId,
      }));
    }
  }

  onStoreChange = (event) => {
    const { info } = this.state;
    this.setState({ info: { ...info, mstStore: event.target.value } });
  }

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    const { info } = this.state;
    this.setState({
      info: { ...info, [key]: value },
      nameErrorMessage: this.checkName(value),
    });
  }

  goRoleDetail = (data) => {
    this.props.dispatch(actSetRoleCreateSuccessMessage());
    this.props.history.push({
      pathname: urls.USER_SETTING.ROLE_DETAIL,
      state: {
        roleId: data.id,
      },
    });
    this.props.handleClose();
  }

  init = () => {
    const { mstStore, roleName } = this.props;

    this.setState({
      info: {
        mstStore: mstStore || this.props.common.user.store,
        name: roleName,
      },
      stores: this.props.roles.stores,
      nameErrorMessage: '',
      isMain: this.props.common.user.main,
      btnLoading: false,
    });
  }

  checkName = (name) => {
    if (!name) {
      return '入力してください';
    }
    return '';
  }

  render() {
    const {
      open, handleClose, roleId,
    } = this.props;
    const {
      info, stores, isMain, btnLoading, nameErrorMessage,
    } = this.state;

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={handleClose}>
          <DialogTitle>{ roleId ? '権限の変更' : '権限の追加' }</DialogTitle>
          <DialogContent dividers>
            <Box display={isMain ? '' : 'none'} mb={3} width="100%">
              <FormTitle title="店舗" isRequired />
              <SearchSelectBox
                options={stores}
                value={info.mstStore}
                onChange={this.onStoreChange}
                name="mstStore"
                disableClearable
                disabled={!!roleId}
              />
            </Box>
            <Box>
              <FormTitle title="権限名" isRequired />
              <TextField
                id="auth-name"
                name="name"
                variant="outlined"
                value={info.name}
                onChange={this.handleChange}
                fullWidth
                error={nameErrorMessage !== ''}
                inputProps={{
                  maxLength: 50,
                }}
              />
              <FormErrorText>{nameErrorMessage}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              キャンセル
            </Button>
            <LoadingButton onClick={this.handleSave} label="保存" loading={btnLoading} />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles,
  roleDetail: state.roleDetail,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(RoleDetailDialog));
