import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE, actInitClassInfomationSuccess, actSearchRoomsSuccess, actAttendSuccess,
} from './aciton';
import { actCallApiServerError } from '../../common/common/action';
import { getDatas, getAttend, getSelects } from '../../../services/info/classInfomation.service';

/**
 * 初期表示
 *
 */
function* callApiInitClassInfomation({ param }) {
  try {
    const rooms = yield call(getSelects, param);
    yield put(actInitClassInfomationSuccess(rooms));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索表示
 *
 */
function* callApiSearchRooms(rooms) {
  try {
    const datas = yield call(getDatas, rooms);
    yield put(actSearchRoomsSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 出席管理
 *
 */
function* callApiAttend(users) {
  try {
    const result = yield call(getAttend, users);
    yield put(actAttendSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

export default function* watchCallClassInfomation() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_CLASS_INFOMATION, callApiInitClassInfomation);
  yield takeLatest(ACTION_TYPE.ACT_CALL_API_SEARCH_ROOMS, callApiSearchRooms);
  yield takeLatest(ACTION_TYPE.ACT_CALL_API_ATTEND, callApiAttend);
}
