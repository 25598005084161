import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 会員情報及び契約情報取得
 *
 * @param
 */
export function getMemberProductInfo(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.WEEK_SELECT.MEMBER_PRODUCT_INFO, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 選択肢取得
 *
 * @returns
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.WEEK_SELECT.SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 検索条件に当たる曜日別スケジュール取得
 *
 * @param
 */
export function getWeekSelectSchedule(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SCHOOL.WEEK_SELECT.WEEK_SELECT_SCHEDULE, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員紐付け登録
 *
 * @param
 */
export function insertWeekSelectMemberLinkage(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SCHOOL.WEEK_SELECT.WEEK_SELECT_SCHEDULE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
