// #region Type
export const ACTION_TYPE = {
  CALL_API_GOLF_SCHEDULE_GET_STORES: 'CALL_API_GOLF_SCHEDULE_GET_STORES',
  CALL_API_GOLF_SCHEDULE_GET_STORES_SUCCESS: 'CALL_API_GOLF_SCHEDULE_GET_STORES_SUCCESS',
  CALL_API_GET_GOLF_SCHEDULE: 'CALL_API_GET_GOLF_SCHEDULE',
  CALL_API_GET_GOLF_SCHEDULE_SUCCESS: 'CALL_API_GET_GOLF_SCHEDULE_SUCCESS',
  CALL_API_GOLF_SCHEDULE_FLG_RESET: 'CALL_API_GOLF_SCHEDULE_FLG_RESET',
  CALL_API_SAVE_GOLF_SCHEDULE_SUCCESS: 'CALL_API_SAVE_GOLF_SCHEDULE_SUCCESS',
  CALL_API_SAVE_GOLF_SCHEDULE: 'CALL_API_SAVE_GOLF_SCHEDULE',
  CALL_API_GET_GOLF_SCHEDULE_DETAIL: 'CALL_API_GET_GOLF_SCHEDULE_DETAIL',
  CALL_API_GET_GOLF_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_GET_GOLF_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL: 'CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL',
  CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL_SUCCESS: 'CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL_SUCCESS',
  CALL_API_GET_GOLF_SCHEDULE_ADD: 'CALL_API_GET_GOLF_SCHEDULE_ADD',
  CALL_API_GET_GOLF_SCHEDULE_ADD_SUCCESS: 'CALL_API_GET_GOLF_SCHEDULE_ADD_SUCCESS',
  CALL_API_GOLF_SCHEDULE_ADD: 'CALL_API_GOLF_SCHEDULE_ADD',
  CALL_API_GOLF_SCHEDULE_ADD_SUCCESS: 'CALL_API_GOLF_SCHEDULE_ADD_SUCCESS',
  CALL_API_GOLF_SCHEDULE_DELETE: 'CALL_API_GOLF_SCHEDULE_DELETE',
  CALL_API_GOLF_SCHEDULE_DELETE_SUCCESS: 'CALL_API_GOLF_SCHEDULE_DELETE_SUCCESS',
  CALL_API_GOLF_SCHEDULE_SET_CURDATE: 'CALL_API_GOLF_SCHEDULE_SET_CURDATE',
};
// #endregion

// #region Type
export const actCallApiGetStores = () => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_GET_STORES,
});

export const actCallApiGetStoresSuccess = (select) => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_GET_STORES_SUCCESS,
  select,
});

export const actCallApiGetGolfSchedule = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE,
  searchCondition,
});

export const actCallApiGetGolfScheduleSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_SUCCESS,
  datas,
});

export const actApiGolfScheduleFlgReset = () => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_FLG_RESET,
});

export const actCallApiSaveGolfSchedule = (param) => ({
  type: ACTION_TYPE.CALL_API_SAVE_GOLF_SCHEDULE,
  param,
});

export const actCallApiSaveGolfScheduleSuccess = () => ({
  type: ACTION_TYPE.CALL_API_SAVE_GOLF_SCHEDULE_SUCCESS,
});

export const actCallApiGetGolfScheduleDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_DETAIL,
  param,
});

export const actCallApiGetGolfScheduleDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_DETAIL_SUCCESS,
  data,
});

export const actCallApiUpdateGolfScheduleDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL,
  param,
});

export const actCallApiUpdateGolfScheduleDetailSuccess = () => ({
  type: ACTION_TYPE.CALL_API_UPDATE_GOLF_SCHEDULE_DETAIL_SUCCESS,
});

export const actCallApiGetGolfScheduleAdd = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_ADD,
  param,
});

export const actCallApiGetGolfScheduleAddSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_GOLF_SCHEDULE_ADD_SUCCESS,
  data,
});

export const actCallApiGolfScheduleAdd = (param) => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_ADD,
  param,
});

export const actCallApiGolfScheduleAddSuccess = () => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_ADD_SUCCESS,
});

export const actCallApiGolfScheduleDelete = (param) => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_DELETE,
  param,
});

export const actCallApiGolfScheduleDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_DELETE_SUCCESS,
});

export const actGolfScheduleSetCurDate = (date) => ({
  type: ACTION_TYPE.CALL_API_GOLF_SCHEDULE_SET_CURDATE,
  date,
});
// #endregion
