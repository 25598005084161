import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Grid, withStyles, Container,
} from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import BookingMemberDataTable from './BookingMemberDataTable';
import BookingMemberAddDialog from './BookingMemberAddDialog';
import {
  actCallInitBookingDetailSearch,
  actApiBookingMemberFlgReset,
  actCallApiBookingMemberDelete,
  actCallApiBookingMemberCancel,
  actCallApiBookingDetailMemberList,
  actCallApiReSendZoomUrlTeacher, actCallApiCreateWebinar,
} from '../../../redux/booking/bookingDetail/action';
import BookingMemberChangeStatusDialog from './BookingMemberChangeStatusDialog';
import BookingMemoAddDialog from './BookingMemoAddDialog';
import BookingDetailInfoCard from './BookingDetailInfoCard';
import BookingExperienceAddDialog from './BookingExperienceAddDialog';
import BookingMemberConfirmDialog from './BookingMemberConfirmDialog';
import urls from '../../../constants/urls';
import FaxDialog from './FaxDialog';
import BookingMemberChangeGolfBoxDialog from './BookingMemberChangeGolfBoxDialog';

const styles = () => ({
  root: {
    paddingTop: '60px',
    paddingBottom: '40px',
  },
});

class BookingEventDetail extends Component {
  constructor(props) {
    super(props);

    const {
      bookingInfo,
      breadcrumbsObj,
    } = props.location.state;

    if (!bookingInfo || Object.keys(bookingInfo).length === 0) {
      window.location.href = urls.BOOKING.BOOKING_LIST;
    }

    this.onSearch(
      0,
      10,
      bookingInfo.bookingId,
      bookingInfo.contentId,
      bookingInfo.targetType ? bookingInfo.targetType : 0,
    );

    this.breadcrumbsText = breadcrumbsObj;

    this.state = {
      booking: {},
      bookingMembers: {},
      page: 0,
      records: 10,
      memberAddDialogOpen: false,
      updateDialogOpen: false,
      experienceDialogOpen: false,
      transferCancelDialogOpen: false,
      reSendZoomUrlDialogOpen: false,
      successSnackOpen: false,
      golfBoxDialogOpen: false,
      snackMessage: '',
      selectedRow: {},
      bookingNotAcceptFlg: false,
      regularFlg: bookingInfo.isRegular,
      cancelRow: {},
      loading: false,
      confirmDisabled: false,
      isOpenFaxDialog: false,
      isOpenBookingMemoAddDialog: false,
      golfFlg: bookingInfo.contentId === 2,
      golfBoxRow: {},
      isCancel: false,
    };
  }

  componentDidMount() {
    document.title = this.props.location.state.bookingInfo.isRegular ? '定期予約検索詳細' : '予約検索詳細';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingDetail.booking !== this.props.bookingDetail.booking) {
      if (this.props.bookingDetail.booking) {
        this.setData(this.props.bookingDetail);
      }
    }
    if (prevProps.bookingDetail.bookingMembers !== this.props.bookingDetail.bookingMembers) {
      if (this.props.bookingDetail.bookingMembers) {
        this.setMemberData(this.props.bookingDetail.bookingMembers);
      }
    }
    if (prevProps.bookingDetail.isStatusUpdateSuccess
      !== this.props.bookingDetail.isStatusUpdateSuccess) {
      if (this.props.bookingDetail.isStatusUpdateSuccess) {
        this.setTransactionSuccess(`${this.state.selectedRow.name}のステータスを更新しました`);
      }
    }

    if (prevProps.bookingDetail.isCancelSuccess
      !== this.props.bookingDetail.isCancelSuccess) {
      if (this.props.bookingDetail.isCancelSuccess) {
        this.setTransactionSuccess('振替をキャンセルしました');
        this.handleTransferCancelModalClose();
      }
    }

    if (prevProps.bookingDetail.isDeleteSuccess !== this.props.bookingDetail.isDeleteSuccess) {
      if (this.props.bookingDetail.isDeleteSuccess) {
        this.setTransactionSuccess('予約者の削除が完了しました');
      }
    }

    if (prevProps.bookingDetail.isInsertSuccess !== this.props.bookingDetail.isInsertSuccess) {
      if (this.props.bookingDetail.isInsertSuccess) {
        this.setTransactionSuccess('予約者の追加が完了しました');
        this.handleMemberAddDialogClose();
      }
    }

    if (prevProps.bookingDetail.isExperienceInsertSuccess
      !== this.props.bookingDetail.isExperienceInsertSuccess) {
      if (this.props.bookingDetail.isExperienceInsertSuccess) {
        this.setTransactionSuccess('予約者の追加が完了しました');
      }
    }

    if (prevProps.bookingDetail.isReSendZoomUrlTeacherSuccess
      !== this.props.bookingDetail.isReSendZoomUrlTeacherSuccess) {
      if (this.props.bookingDetail.isReSendZoomUrlTeacherSuccess) {
        this.setTransactionSuccess('先生へZoomUrlを再送しました');
        this.handleReSendZoomUrlModalClose();
      }
    }

    if (prevProps.bookingDetail.isCreateZoomSuccess
      !== this.props.bookingDetail.isCreateZoomSuccess) {
      if (this.props.bookingDetail.isCreateZoomSuccess) {
        this.onSuccessCreateZoom();
      }
    }

    if (prevProps.bookingDetail.isBookingMemoUpdateSuccess
      !== this.props.bookingDetail.isBookingMemoUpdateSuccess) {
      if (this.props.bookingDetail.isBookingMemoUpdateSuccess) {
        this.onSuccessUpdateMemo();
      }
    }

    if (prevProps.bookingDetail.isGolfBoxUpdateSuccess
      !== this.props.bookingDetail.isGolfBoxUpdateSuccess) {
      if (this.props.bookingDetail.isGolfBoxUpdateSuccess) {
        this.setTransactionSuccess('打席番号の変更が完了しました');
        this.handleGolfBoxModalClose();
      }
    }
  }

  onSearch(page, records, id, contentId, targetType) {
    this.props.dispatch(actCallInitBookingDetailSearch({
      page,
      records,
      id,
      contentId,
      targetType,
    }));
    this.props.dispatch(actCallApiBookingDetailMemberList(
      {
        page,
        records,
        id,
        contentId,
        targetType,
      },
    ));
  }

  onSuccessCreateZoom() {
    this.setState({ successSnackOpen: true, snackMessage: 'ウェビナー作成が完了しました' });
    const { records, booking } = this.state;

    this.setState({ page: 0 });

    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(actCallInitBookingDetailSearch({
      page: 0,
      records,
      id: booking.id,
      contentId: booking.mstContentId,
      targetType: booking.targetType,
    }));
  }

  onSuccessUpdateMemo() {
    this.setState({ successSnackOpen: true, snackMessage: '内部メモを更新しました' });
    const { records, booking } = this.state;

    this.setState({ page: 0 });

    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(actCallInitBookingDetailSearch({
      page: 0,
      records,
      id: booking.id,
      contentId: booking.mstContentId,
      targetType: booking.targetType,
    }));
  }

  setMemberData(bookingMembers) {
    this.setState({ bookingMembers });
  }

  setData(bookingDetail) {
    this.setState({
      booking: bookingDetail.booking,
      bookingNotAcceptFlg: bookingDetail.booking.pastBooking,
    });
  }

  setTransactionSuccess(snackMessage) {
    this.setState({ successSnackOpen: true, snackMessage });
    const { records, booking } = this.state;

    this.setState({ page: 0 });

    this.props.dispatch(actApiBookingMemberFlgReset());
    this.onSearch(0, records, booking.id, booking.mstContentId, booking.targetType);
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  handleMemberAddModalOpen = (isCancel) => {
    this.setState({
      memberAddDialogOpen: true,
      successSnackOpen: false,
      isCancel,
    });
  }

  handleMemberAddDialogClose = () => {
    this.setState({ memberAddDialogOpen: false });
  }

  handleStatusUpdateModalOpen = (_, row) => {
    this.setState({
      updateDialogOpen: true,
      selectedRow: row,
      successSnackOpen: false,
    });
  }

  handleUpdateStatusModalClose = () => {
    this.setState({ updateDialogOpen: false });
  }

  handleTransferCancelModalOpen = (_, row) => {
    this.setState({
      transferCancelDialogOpen: true,
      cancelRow: row,
      successSnackOpen: false,
    });
  }

  handleTransferCancel = () => {
    this.setState({
      loading: true,
      successSnackOpen: false,
    });
    const { cancelRow } = this.state;

    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(
      actCallApiBookingMemberCancel({
        id: cancelRow.id,
      }),
    );
  }

  handleTransferCancelModalClose = () => {
    this.setState({ transferCancelDialogOpen: false, cancelRow: {}, loading: false });
  }

  handleExperienceModalOpen = () => {
    this.setState({
      experienceDialogOpen: true,
      successSnackOpen: false,
    });
  }

  handleExperienceModalClose = () => {
    this.setState({ experienceDialogOpen: false });
  }

  handleReSendZoomUrlModalOpen = () => {
    const { teacherEmail } = this.state.booking;
    this.setState({
      reSendZoomUrlDialogOpen: true,
      successSnackOpen: false,
      confirmDisabled: !teacherEmail,
    });
  };

  handleReSendZoomUrlModalClose = () => {
    this.setState({ reSendZoomUrlDialogOpen: false });
  };

  handleGolfBoxModalOpen = (_, row) => {
    this.setState({
      golfBoxDialogOpen: true,
      golfBoxRow: row,
      successSnackOpen: false,
    });
  }

  handleGolfBoxModalClose = () => {
    this.setState({ golfBoxDialogOpen: false });
  };

  onDelete = (value) => {
    this.setState({ successSnackOpen: false });
    const { booking } = this.state;
    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(actCallApiBookingMemberDelete({
      deleteList: value,
      contentId: booking.mstContentId,
    }));
  }

  handleChangePage = (_, newPage) => {
    const { booking, records } = this.state;
    this.setState({ page: newPage });
    this.onSearch(newPage, records, booking.id, booking.mstContentId, booking.targetType);
  };

  handleChangeRowsPerPage = (event) => {
    const { booking } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.onSearch(0, rowsPerPage, booking.id, booking.mstContentId, booking.targetType);
  };

  onReSendZoomUrl = () => {
    this.setState({ successSnackOpen: false });
    const { booking } = this.state;
    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(
      actCallApiReSendZoomUrlTeacher({ id: booking.id }),
    );
  }

  onClickCreateZoom = () => {
    this.setState({ successSnackOpen: false });
    const { booking } = this.state;
    this.props.dispatch(actApiBookingMemberFlgReset());
    this.props.dispatch(
      actCallApiCreateWebinar({ id: booking.id }),
    );
  }

  onOpenAddMemo = () => {
    this.setState({ isOpenBookingMemoAddDialog: true });
  }

  onCloseAddMemo = () => {
    this.setState({ isOpenBookingMemoAddDialog: false });
  }

  onOpenFaxDialog = () => {
    this.setState({ isOpenFaxDialog: true });
  }

  onCloseFaxDialog = () => {
    this.setState({ isOpenFaxDialog: false });
  }

  render() {
    const { classes } = this.props;
    const {
      booking,
      bookingMembers,
      page,
      records,
      memberAddDialogOpen,
      successSnackOpen,
      snackMessage,
      selectedRow,
      updateDialogOpen,
      bookingNotAcceptFlg,
      regularFlg,
      experienceDialogOpen,
      transferCancelDialogOpen,
      reSendZoomUrlDialogOpen,
      loading,
      confirmDisabled,
      cancelRow,
      isOpenFaxDialog,
      isOpenBookingMemoAddDialog,
      golfFlg,
      golfBoxDialogOpen,
      golfBoxRow,
      isCancel,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container className={classes.root} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BookingDetailInfoCard
                booking={booking}
                regularFlg={regularFlg}
                onClickAddMemo={this.onOpenAddMemo}
                handleReSendZoomUrlModalOpen={this.handleReSendZoomUrlModalOpen}
                onClickCreateZoom={this.onClickCreateZoom}
              />
            </Grid>
            <Grid item xs={9}>
              <BookingMemberDataTable
                rows={bookingMembers.content ? bookingMembers.content : []}
                onAdd={this.handleMemberAddModalOpen}
                onAddSecond={this.handleExperienceModalOpen}
                onDelete={this.onDelete}
                onFirstFunc={this.handleStatusUpdateModalOpen}
                onSecondFunc={this.handleTransferCancelModalOpen}
                onThirdFunc={this.handleGolfBoxModalOpen}
                page={page}
                rowsPerPage={records}
                totalElements={bookingMembers.totalElements}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                booking={booking}
                bookingNotAcceptFlg={bookingNotAcceptFlg}
                regularFlg={regularFlg}
                dialogFlg={false}
                onOpenFaxDialog={this.onOpenFaxDialog}
                isShowFaxBtn={booking.companyId === 1}
                golfFlg={golfFlg}
              />
            </Grid>
          </Grid>
          <SuccessSnackbar
            open={successSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={snackMessage}
          />
          <BookingMemberAddDialog
            open={memberAddDialogOpen}
            onClose={this.handleMemberAddDialogClose}
            dialogCapacity={booking.capacity}
            LinkMemberCount={booking.capacity - booking.bookingNumber}
            isCancel={isCancel}
            dialogFlg
          />
          <BookingMemberChangeStatusDialog
            open={updateDialogOpen}
            handleClose={this.handleUpdateStatusModalClose}
            row={selectedRow}
            regularFlg={regularFlg}
            contentId={booking.mstContentId}
          />
          <BookingExperienceAddDialog
            open={experienceDialogOpen}
            handleClose={this.handleExperienceModalClose}
            booking={booking}
          />
          <BookingMemberConfirmDialog
            open={transferCancelDialogOpen}
            handleClose={this.handleTransferCancelModalClose}
            handleExecute={this.handleTransferCancel}
            subTitle={cancelRow.name}
            loading={loading}
          />
          <FaxDialog
            open={isOpenFaxDialog}
            onClose={this.onCloseFaxDialog}
            booking={booking}
            members={bookingMembers.content ? bookingMembers.content : []}
          />
          <ConfirmDialog
            open={reSendZoomUrlDialogOpen}
            handleClose={this.handleReSendZoomUrlModalClose}
            executeText="再送する"
            handleExecute={this.onReSendZoomUrl}
            title="先生へZoomUrlを再送"
            contentText={!booking.teacherEmail ? 'メールアドレスが設定されていないため、再送できません' : '先生へZoomUrlを再送します。'}
            loading={loading}
            buttonColor="primary"
            disabled={confirmDisabled}
          />
          <BookingMemoAddDialog
            open={isOpenBookingMemoAddDialog}
            onClose={this.onCloseAddMemo}
            booking={booking}
          />
          <BookingMemberChangeGolfBoxDialog
            open={golfBoxDialogOpen}
            handleClose={this.handleGolfBoxModalClose}
            golfBoxRow={golfBoxRow}
            booking={booking}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingEventDetail)));
