import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Table, TableBody, TableCell, Card, TableContainer, TableHead, TableRow, Button,
  withStyles, Grid, Box,
} from '@material-ui/core';
import format from 'date-fns/format';
import { lowerThanDate } from '../../../share/calendarScheduleDetail/share/calendar-schedule-detail-proc';
import {
  actCallApiCalendarRoomDetail, actCallApiCalendarHeaderRoom,
} from '../../../../redux/event/eventCalendarDetailRoom/action';
import { actSetHeader } from '../../../../redux/share/calendarScheduleDetail/action';
import urls from '../../../../constants/urls';
import CalendarScheduleRoomDialog from './EventCalendarRoomDialog';
import getWeekName from './room-proc';

const styles = (theme) => ({
  tableContainer: {
    maxHeight: '700px',
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
  },
  bookingBtn: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  hideButton: {
    display: 'none',
  },
  borderCell: {
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
  },
  highlight: {
    color: '#000',
    backgroundColor: '#E8F0FE',
  },
});

class EventCalendarRoomTable extends React.Component {
  constructor(props) {
    super(props);

    const { selectedDate } = props;

    this.state = {
      bookingDatas: {
        timeList: [],
        roomBookings: [],
        roomList: [],
      },
      monthly: [],
      targetDate: selectedDate,
      roomDialogOpen: false,
      selectedId: null,
      selectedRoom: {
        name: '',
      },
      startTime: '',
      endTime: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarDetailRoom.datas !== this.props.calendarDetailRoom.datas) {
      if (this.props.calendarDetailRoom.datas) {
        this.setBookingDatas(this.props.calendarDetailRoom.datas);
      }
    }
    if (prevProps.calendarDetailRoom.calendarList !== this.props.calendarDetailRoom.calendarList) {
      if (this.props.calendarDetailRoom.calendarList) {
        this.setCalendarList(this.props.calendarDetailRoom.calendarList);
      }
    }
    if (prevProps.calendarScheduleDetail.isDeleted
      !== this.props.calendarScheduleDetail.isDeleted) {
      if (this.props.calendarScheduleDetail.isDeleted) {
        this.setSuccessMessage('予約枠の削除が完了しました');
      }
    }
    if (prevProps.calendarDetailRoom.isRoomCreated
      !== this.props.calendarDetailRoom.isRoomCreated) {
      if (this.props.calendarDetailRoom.isRoomCreated) {
        this.setSuccessMessage('予約枠の作成が完了しました');
      }
    }
  }

  setCalendarList(monthly) {
    this.setState({ monthly });
  }

  setBookingDatas(bookingDatas) {
    this.setState({ bookingDatas });
  }

  onDetail = (_, targetDate) => {
    const { storeId } = this.props.header;
    this.setState({ targetDate });
    this.props.dispatch(
      actCallApiCalendarRoomDetail({ storeId, targetedOn: targetDate }),
    );
  }

  setSuccessMessage = (message) => {
    const { header, success } = this.props;
    const { targetDate } = this.state;
    this.props.dispatch(
      actCallApiCalendarRoomDetail({ storeId: header.storeId, targetedOn: targetDate }),
    );
    this.props.dispatch(actCallApiCalendarHeaderRoom({ headerId: header.id }));
    success(message);
  }

  handleRoomDialogOpen = (_, selectedId, selectedRoom, startTime, endTime, targetDate) => {
    this.props.onMessageClose();
    this.setState({
      selectedId,
      selectedRoom,
      startTime,
      endTime,
      roomDialogOpen: true,
      targetDate,
    });
  }

  handleRoomDialogClose = () => {
    this.setState({ roomDialogOpen: false });
  }

  onBookingDetailLinkage = (_, id, targetType) => {
    const { header, isMain } = this.props;
    const { targetDate } = this.state;

    const breadcrumbsObj = [
      {
        url: urls.EVENT.EVENT_CALENDARS,
        name: '予約カレンダー作成',
      },
      {
        url: urls.EVENT.ROOM_CALENDAR_DETAIL,
        name: '予約カレンダー詳細',
      },
      { name: '予約検索詳細' },
    ];
    this.props.dispatch(actSetHeader({
      isMain, header, targetDate,
    }));
    const bookingInfo = {
      bookingId: id,
      contentId: header.contentId,
      regular: false,
      targetType,
    };
    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_DETAIL,
      state: {
        bookingInfo,
        breadcrumbsObj,
      },
    });
  };

  setRoomTableCell = (time, room, index, targetDate) => {
    const { classes } = this.props;
    const { timeList, roomBookings } = this.state.bookingDatas;

    const roomBookingList = roomBookings.filter(
      (booking) => booking.startTime === time && room.id === booking.roomId,
    );

    if (roomBookingList.length === 0) {
      return (
        <TableCell align="center" key={`emptyCell-${room.id}-${time}`} className={classes.borderCell}>
          <Box p={3} mb={1} />
          <Box p={1} pt={0} display="flex" justifyContent="center">
            <Button
              style={{ display: lowerThanDate(new Date(targetDate), new Date()) ? 'none' : '' }}
              size="small"
              variant="contained"
              color="primary"
              className={classes.bookingBtn}
              onClick={(event) => this.handleRoomDialogOpen(
                event, null, room, time, timeList[index + 1], targetDate,
              )}
            >
              追加
            </Button>
          </Box>
        </TableCell>
      );
    }

    return (
      roomBookingList.map((row) => (
        <TableCell component="th" key={row.bookingId} scope="row" padding="default" align="center" className={classes.borderCell}>
          <Box pb={2} pt={2}>〇</Box>
          <Box p={1} pt={0} display="flex" justifyContent="center">
            <Button
              style={{ display: lowerThanDate(new Date(targetDate), new Date()) ? 'none' : '' }}
              size="small"
              variant="contained"
              color="secondary"
              className={row.delImposibleFlg ? classes.hideButton : classes.bookingBtn}
              onClick={(event) => this.handleRoomDialogOpen(
                event, row.bookingId, room, time, timeList[index + 1], targetDate,
              )}
            >
              削除
            </Button>
            <Button size="small" variant="contained" color="primary" className={classes.bookingBtn} onClick={(event) => this.onBookingDetailLinkage(event, row.bookingId, 0)}>詳細</Button>
          </Box>
        </TableCell>
      )));
  }

  render() {
    const {
      classes,
      header,
    } = this.props;

    const {
      bookingDatas, selectedId, targetDate,
      roomDialogOpen, selectedRoom, startTime, endTime, monthly,
    } = this.state;

    const { roomList, timeList } = bookingDatas;

    return (
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TableContainer className={classes.tableContainer}>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                stickyHeader
              >
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell align="center">日</TableCell>
                    <TableCell align="center">曜日</TableCell>
                    <TableCell align="center">公開日</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monthly.map((row) => (
                    <TableRow
                      tabIndex={-1}
                      key={row.day}
                      className={row.targetDate === targetDate ? classes.highlight : ''}
                    >
                      <TableCell align="center">{row.day}</TableCell>
                      <TableCell align="center">{getWeekName(row.targetDate)}</TableCell>
                      <TableCell align="center">{row.publishedOn}</TableCell>
                      <TableCell align="center">
                        <Button color="primary" onClick={(event) => this.onDetail(event, row.targetDate)}>詳細を見る</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={9}>
            <Card>
              <Box p={2} fontWeight="fontWeightBold" fontSize={23}>{format(new Date(targetDate), 'yyyy年MM月dd日')}</Box>
              <TableContainer className={classes.tableContainer}>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      <TableCell size="small" className={classes.borderCell} />
                      {roomList.map((room) => (
                        <TableCell key={room.name} align="center" padding="default" className={classes.borderCell}>
                          {room.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timeList.map((time, index) => (
                      timeList.length - 1 !== index && (
                      <TableRow
                        tabIndex={-1}
                        key={time}
                      >
                        <TableCell component="th" scope="row" padding="default" align="center" className={classes.borderCell}>
                          {time}
                        </TableCell>
                        {roomList.map((room) => (
                          this.setRoomTableCell(time, room, index, targetDate)))}
                      </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>

        <CalendarScheduleRoomDialog
          open={roomDialogOpen}
          onClose={this.handleRoomDialogClose}
          selectedRoom={selectedRoom}
          startTime={startTime}
          endTime={endTime}
          selectedId={selectedId}
          storeId={header.storeId}
          targetedOn={targetDate}
          headerId={header.id}
          monthly={monthly}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarScheduleDetail: state.calendarScheduleDetail,
  calendarDetailRoom: state.calendarDetailRoom,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(EventCalendarRoomTable)));
