import { ACTION_TYPE } from './action';

const mails = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_MAIL_LIST_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_MAIL_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CHANGE_MAIL_STOP_FLG_SUCCESS:
      return {
        ...state,
        isChangeSuccess: true,
      };
    case ACTION_TYPE.SET_SEARCH_MAILS:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    case ACTION_TYPE.SET_CHANGE_MAIL_STOP_FLG_RESET:
      return {
        ...state,
        isChangeSuccess: false,
      };
    default:
      return state;
  }
};

export default mails;
