import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Drawer, IconButton, Divider, Box,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { actCallApiGetChangeStatus } from '../../../redux/booking/subscriberList/action';

const styles = (theme) => ({
  drawer: {
    width: 500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class HistoryStatusDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { datas: [] };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isHistoryDrawer !== this.props.isHistoryDrawer) {
      if (this.props.isHistoryDrawer) {
        this.init();
      }
    }
    if (prevProps.subscriber.changeList !== this.props.subscriber.changeList) {
      if (this.props.subscriber.changeList) {
        this.setData(this.props.subscriber.changeList);
      }
    }
  }

  setData(datas) {
    this.setState({
      datas,
    });
  }

  init() {
    const { row, handleHistoryClear } = this.props;
    handleHistoryClear();
    this.props.dispatch(actCallApiGetChangeStatus({
      bookingCode: row.bookingCode,
      contentId: row.contentId,
    }));
  }

  render() {
    const { classes, isOpen, handleHistoryClose } = this.props;
    const { datas } = this.state;

    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleHistoryClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <Box>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>変更日時</TableCell>
                    <TableCell>変更者</TableCell>
                    <TableCell>変更前</TableCell>
                    <TableCell>変更後</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((row) => (
                    <TableRow
                      hover
                      className="tableRow"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>{row.changeDate}</TableCell>
                      <TableCell>{row.changeName}</TableCell>
                      <TableCell>{row.beforeStatus}</TableCell>
                      <TableCell>{row.afterStatus}</TableCell>
                    </TableRow>
                  ))}
                  {
                    datas.length < 1 && (
                      <TableRow>
                        <TableCell className={classes.emptyTableCell} colSpan={8}>
                          データがありません
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({ subscriber: state.subscriber });

export default withStyles(styles)(connect(mapStateToProps)(HistoryStatusDrawer));
