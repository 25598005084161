import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallInitTeacherContactSelectList,
  actCallSearchConditionSave,
  actCallTeacherContactSearchList,
} from '../../../redux/info/teacherContactTaskList/action';
import TeacherContactTaskListDataTable from './TeacherContactTaskListDataTable';

class TeacherContactTaskList extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallInitTeacherContactSelectList());

    this.breadcrumbsText = [
      { name: '先生連絡一覧' },
    ];

    this.headCells = [
      { id: 'targetDate', numeric: false, label: '実施予定日時' },
      { id: 'menuName', numeric: false, label: 'メニュー' },
      { id: 'teacherName', numeric: false, label: '先生名' },
      { id: 'teacherPhone', numeric: false, label: '電話番号' },
      { id: 'teacherEmail', numeric: false, label: 'メールアドレス' },
    ];

    let page = 0;
    let records = 10;
    let searchStartDate = '';
    let searchEndDate = '';
    let searchStore = props.common.user ? props.common.user.store : null;
    let searchNotContacted = true;
    let searchTeacher = null;

    const { searchCondition } = props.teacherContactTaskList;

    if (searchCondition && Object.keys(searchCondition).length > 0) {
      page = searchCondition.page;
      records = searchCondition.records;
      searchStartDate = searchCondition.searchStartDate;
      searchEndDate = searchCondition.searchEndDate;
      searchStore = searchCondition.searchStore;
      searchNotContacted = searchCondition.searchNotContacted;
      searchTeacher = searchCondition.searchTeacher;
    }

    this.state = {
      isMain: props.common.user ? props.common.user.main : false,
      stores: [],
      teachers: [],
      searchStartDate,
      searchEndDate,
      searchStore,
      searchNotContacted,
      datas: {},
      page,
      records,
      statusSuccessSnackOpen: false,
      snackMessage: '',
      searchTeacher,
    };

    this.search(
      page, records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    );
  }

  componentDidMount() {
    document.title = '先生連絡一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
        this.onStoreChangeSearch(this.props.common.user.store);
      }
    }

    if (prevProps.teacherContactTaskList.options
      !== this.props.teacherContactTaskList.options) {
      if (this.props.teacherContactTaskList.options) {
        this.setOptions(this.props.teacherContactTaskList.options);
      }
    }

    if (prevProps.teacherContactTaskList.datas !== this.props.teacherContactTaskList.datas) {
      if (this.props.teacherContactTaskList.datas) {
        this.setDatas(this.props.teacherContactTaskList.datas);
      }
    }

    if (prevProps.teacherContactTaskList.isContactedSuccess
        !== this.props.teacherContactTaskList.isContactedSuccess) {
      if (this.props.teacherContactTaskList.isContactedSuccess) {
        this.handleSuccessContacted();
      }
    }
  }

  setUser(user) {
    this.setState({ isMain: user.main });
  }

  setOptions(options) {
    this.setState({
      stores: options.stores,
      teachers: options.teachers,
    });
  }

  setDatas(datas) {
    this.setState({ datas: { ...datas, content: datas.content } });
  }

  setSearchConditionSave(
    page, records,
    searchStore, searchStartDate,
    searchEndDate, searchNotContacted,
    searchTeacher,
  ) {
    this.props.dispatch(actCallSearchConditionSave({
      searchCondition: {
        page,
        records,
        searchStore,
        searchStartDate,
        searchEndDate,
        searchNotContacted,
        searchTeacher,
      },
    }));
  }

  onSearch = () => {
    const {
      records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    } = this.state;

    this.setState({ page: 0 });
    this.search(
      0, records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    );
  }

  search = (
    page, records,
    searchStore, searchStartDate,
    searchEndDate, searchNotContacted,
    searchTeacher,
  ) => {
    this.setSearchConditionSave(
      page, records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    );
    this.props.dispatch(
      actCallTeacherContactSearchList({
        page,
        records,
        searchStoreId: searchStore && searchStore.id,
        searchStartDate,
        searchEndDate,
        searchNotContacted,
        searchTeacherId: searchTeacher && searchTeacher.id,
      }),
    );
  }

  onSearchClear = () => {
    const { isMain, searchStore } = this.state;

    this.setState({
      searchStore: isMain ? null : searchStore,
      searchStartDate: null,
      searchEndDate: null,
      searchNotContacted: false,
      searchTeacher: null,
    });
  }

  onStoreChange = (event) => {
    const { name, value } = event.target;
    const { teachers, searchTeacher } = this.state;
    const filterTeachers = (teachers.length > 0 && value)
      ? teachers.filter((teacher) => teacher.storeId === value.id) : [];
    const isMenuExist = searchTeacher && filterTeachers.filter(
      (row) => row.id === searchTeacher.id,
    ).length > 0;
    let tempTeacher = searchTeacher;
    if (!isMenuExist) {
      tempTeacher = null;
    }
    this.setState({ [name]: value, searchTeacher: tempTeacher });
  }

  onTeacherChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onStoreChangeSearch = (values) => {
    this.setState({ searchStore: values });

    const {
      page, records,
      searchStartDate, searchEndDate,
      searchNotContacted,
      searchTeacher,
    } = this.state;
    this.search(
      page, records,
      values,
      searchStartDate, searchEndDate,
      searchNotContacted,
      searchTeacher,
    );
  }

  onNotContactedChange = (event) => {
    this.setState({ searchNotContacted: event.target.checked });
  }

  onDateChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  }

  handleChangePage = (_, newPage) => {
    const {
      records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    } = this.state;

    this.setState({ page: newPage });

    this.search(
      newPage, records,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    );
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);

    this.setState({ page: 0, records: rowsPerPage });

    this.search(
      0, rowsPerPage,
      searchStore, searchStartDate,
      searchEndDate, searchNotContacted,
      searchTeacher,
    );
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ statusSuccessSnackOpen: false });
  }

  handleSuccessContacted = () => {
    this.onSearch();
    this.setState({
      statusSuccessSnackOpen: true,
      snackMessage: '連絡済み処理が完了しました',
    });
  }

  storeLinkTeacher = (teachers) => {
    const { searchStore } = this.state;
    return teachers && teachers.length > 0 && searchStore
      ? teachers.filter((teacher) => teacher.mstStoreId === searchStore.id) : [];
  }

  render() {
    const {
      isMain,
      stores,
      teachers,
      searchStore,
      searchTeacher,
      searchStartDate,
      searchEndDate,
      searchNotContacted,
      datas,
      page,
      records,
      statusSuccessSnackOpen,
      snackMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch} onSecondFunc={this.onSearchClear} onSecondFuncText="クリアする">
            <Box display="flex" mt={1} alignItems="center">
              <Box display={isMain ? '' : 'none'} mr={4} width="30%">
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" />
              </Box>
              <Box width="30%">
                <FormTitle title="先生" />
                <SearchSelectBox options={this.storeLinkTeacher(teachers)} value={searchTeacher} onChange={this.onTeacherChange} name="searchTeacher" />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="実施予定日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker id="date-picker-start" formatter="yyyy/MM/dd" value={searchStartDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchStartDate')} />
                </Box>
                <Box mr={4} ml={4}>～</Box>
                <Box>
                  <Datepicker id="date-picker-end" formatter="yyyy/MM/dd" value={searchEndDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchEndDate')} />
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={searchNotContacted} onChange={this.onNotContactedChange} />}
                label="未連絡のみ"
              />
            </Box>
          </SearchBox>

          <TeacherContactTaskListDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            page={page}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          <SuccessSnackbar
            open={statusSuccessSnackOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={snackMessage}
          />
        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  teacherContactTaskList: state.teacherContactTaskList,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(TeacherContactTaskList));
