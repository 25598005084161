import { ACTION_TYPE } from './action';

// region Reducer
const eventWeekScheduleList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_EVENT_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_CREATE_SUCCESS:
      return {
        ...state,
        resultData: action.resultData,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_DELETE_SUCCESS:
      return {
        ...state,
        datas: action.datas,
        isDeleted: true,
      };
    case ACTION_TYPE.CALL_API_EVENT_SCHEDULE_LIST_COPY_SUCCESS:
      return {
        ...state,
        copyDatas: action.resultDatas,
      };
    case ACTION_TYPE.EVENT_SCHEDULE_LIST_FLG_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case ACTION_TYPE.SET_SEARCH_EVENT_SCHEDULE:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    default:
      return state;
  }
};

export default eventWeekScheduleList;
