import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 店舗ごとのヘルスチェック一覧取得
 *
 * @param
 */
export function getItems(id) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.HEALTH_CHECK.HEALTH_CHECK_ITEMS, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ヘルスチェック結果DBに追加
 *
 * @param
 */
export function insertData(values) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.INFO.HEALTH_CHECK.HEALTH_CHECK, values)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員番号に該当する会員名取得
 *
 * @param
 */
export function getMemberInfo(memberNo) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.INFO.HEALTH_CHECK.MEMBER_INFO, memberNo)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
