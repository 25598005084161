// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_SYSTEM_USER: 'CALL_API_INIT_SYSTEM_USER',
  CALL_API_INIT_SYSTEM_USER_SUCCESS: 'CALL_API_INIT_SYSTEM_USER_SUCCESS',
  CALL_API_SYSTEM_USER_SEARCH: 'CALL_API_SYSTEM_USER_SEARCH',
  CALL_API_SYSTEM_USER_SEARCH_SUCCESS: 'CALL_API_SYSTEM_USER_SEARCH_SUCCESS',
  CALL_API_SYSTEM_USER_DELETE: 'CALL_API_SYSTEM_USER_DELETE',
  CALL_API_SYSTEM_USER_DELETE_SUCCESS: 'CALL_API_SYSTEM_USER_DELETE_SUCCESS',
  RESET_SYSTEM_USER_LIST_FLG: 'RESET_SYSTEM_USER_LIST_FLG',
};
// #endregion

// #region action
export const actCallApiGetSelectList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SYSTEM_USER,
});

export const actCallApiGetSelectListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_INIT_SYSTEM_USER_SUCCESS,
  selects,
});

export const actCallApiGetSystemUsers = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_SEARCH,
  searchCondition,
});

export const actCallApiGetSystemUsersSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_SEARCH_SUCCESS,
  datas,
});

export const actCallApiSystemUserDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_DELETE,
  values,
});

export const actCallApiSystemUserDeleteSuccess = () => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_DELETE_SUCCESS,
});

export const actResetSystemUserFlg = () => ({
  type: ACTION_TYPE.RESET_SYSTEM_USER_LIST_FLG,
});
// #endregion
