import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent,
  Box, TextField,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import {
  actCallGolfBookingResetFlg, actCallApiUpdateGolfFrame,
} from '../../../../redux/event/eventCalendarDetailGolf/action';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import LoadingButton from '../../../../components/organisms/loadingButton/LoadingButton';
import DialogTitle from '../../../../components/atoms/dialogTitle/DialogTitle';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';

class EventCalendarGolfFrameDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      loading: false,
      updateFrame: '',
      menuList: [],
      menu: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  onUpdateFrameChange = (event) => {
    const { value } = event.target;
    this.setState({
      updateFrame: value,
      message: this.updateFrameValidationCheck(value),
    });
  }

  handleHeaderUpdate = () => {
    const { selectedId, freeFlg, onClose } = this.props;
    const { updateFrame, menu } = this.state;
    const message = this.updateFrameValidationCheck(updateFrame);
    const menuMessage = this.menuValidation(menu);
    this.setState({ message, menuMessage });
    if (message !== '' || menuMessage !== '') {
      return;
    }

    onClose();
    this.props.dispatch(actCallGolfBookingResetFlg());
    this.props.dispatch(actCallApiUpdateGolfFrame({
      selectedId, updateFrame, freeFlg, menuId: menu.id,
    }));
  }

  updateFrameValidationCheck = (updateFrame) => {
    if (!updateFrame) return '入力してください';
    if (updateFrame < 0) return '0以上の数値を入力してください';
    const regex = /^([1-9]\d*|0)$/;
    if (!regex.test(updateFrame)) return '整数値を入力してください';
    return '';
  }

  menuValidation = (value) => {
    if (!value) {
      return '選択してください';
    }
    return '';
  }

  onChangeSelectBox = (event) => {
    const { value } = event.target;
    this.setState({
      menu: value,
      menuMessage: this.menuValidation(value),
    });
  }

  init() {
    const { menuId, freeFlg } = this.props;
    const { selectList } = this.props.weekScheduleMenu;
    const golfType = freeFlg ? '1' : '3';
    const menuList = selectList.menuList.filter((row) => row.golfMenuType === golfType);
    const menu = selectList.menuList.filter((row) => row.id === menuId)[0];
    this.setState({
      message: '',
      loading: false,
      updateFrame: '',
      menuList,
      menu,
      menuMessage: '',
    });
  }

  render() {
    const {
      open, onClose, frame, freeFlg,
    } = this.props;

    const {
      message, loading, updateFrame, menuList, menu, menuMessage,
    } = this.state;

    const frameName = freeFlg ? 'シミュレーション枠' : '的打ち';

    return (
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick onEscapeKeyDown={onClose}>
          <DialogTitle>{`${frameName}の変更`}</DialogTitle>
          <DialogContent dividers>
            <Box mb={3}>
              <FormTitle title="メニュー" isRequired />
              <SearchSelectBox
                name="menu"
                options={menuList}
                onChange={this.onChangeSelectBox}
                value={menu}
                disableClearable
                error={menuMessage !== ''}
              />
              <FormErrorText>{menuMessage}</FormErrorText>
            </Box>
            <Box mb={3}>
              <FormTitle title={`変更前${frameName}`} />
              <Box fontSize={16}>{frame}</Box>
            </Box>
            <Box mb={3}>
              <FormTitle title={`変更後${frameName}`} isRequired />
              <TextField
                value={updateFrame}
                onChange={this.onUpdateFrameChange}
                variant="outlined"
                error={message.length > 0}
                inputProps={{ maxLength: 9 }}
              />
              <FormErrorText>{message}</FormErrorText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              キャンセル
            </Button>
            <LoadingButton
              onClick={this.handleHeaderUpdate}
              label="変更する"
              loading={loading}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  weekScheduleMenu: state.weekScheduleMenu,
});

export default withRouter(connect(mapStateToProps)(EventCalendarGolfFrameDialog));
