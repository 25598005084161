import React from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, withStyles, TableContainer,
  Box, Button,
} from '@material-ui/core';
import WeekSelectBookingCard from './WeekSelectBookingCard';

const styles = () => ({
  headerCell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  headerCellEnd: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  tableCell: {
    width: '14%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    padding: '0 10px 15px',
    verticalAlign: 'top',
    background: '#fff',
  },
  tableCellEnd: {
    width: '14%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: '0 10px 15px',
    verticalAlign: 'top',
    background: '#fff',
  },
  container: {
    maxHeight: '70vh',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  emptyTableCell: {
    textAlign: 'center',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    fontSize: '17px',
    background: '#fff',
  },
});

class WeekScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: [],
      monMenus: [],
      tueMenus: [],
      wedMenus: [],
      thuMenus: [],
      friMenus: [],
      satMenus: [],
      sunMenus: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.datas !== this.props.datas) {
      if (this.props.datas) {
        this.setDivideWeekMenus(this.props.datas);
      }
    }
  }

  setDivideWeekMenus(datas) {
    let monMenus = [];
    let tueMenus = [];
    let wedMenus = [];
    let thuMenus = [];
    let friMenus = [];
    let satMenus = [];
    let sunMenus = [];

    if (datas) {
      monMenus = datas.filter((data) => data.week === 1);
      tueMenus = datas.filter((data) => data.week === 2);
      wedMenus = datas.filter((data) => data.week === 3);
      thuMenus = datas.filter((data) => data.week === 4);
      friMenus = datas.filter((data) => data.week === 5);
      satMenus = datas.filter((data) => data.week === 6);
      sunMenus = datas.filter((data) => data.week === 7);
    }
    const selectedList = datas.filter((data) => data.exists === true).map((n) => n.id);
    this.setState({
      monMenus,
      tueMenus,
      wedMenus,
      thuMenus,
      friMenus,
      satMenus,
      sunMenus,
      selectedList,
    });
  }

  handleChange = (event, row) => {
    const { selectedList } = this.state;
    const tempSelected = selectedList;
    const isChecked = event.target.checked;
    if (isChecked) {
      tempSelected.push(row.id);
    } else {
      const idx = tempSelected.indexOf(row.id);
      if (idx >= 0) {
        tempSelected.splice(idx, 1);
      }
    }
    this.setState({ selectedList: tempSelected });
  }

  onClickLinkage = (event) => {
    const { selectedList } = this.state;
    this.props.handleLinkOpen(event, selectedList);
  }

  render() {
    const {
      classes,
      datas,
    } = this.props;

    const {
      monMenus, tueMenus, wedMenus, thuMenus, friMenus, satMenus, sunMenus, selectedList,
    } = this.state;

    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box m={1}>
            <Button variant="contained" color="primary" size="large" onClick={this.onClickLinkage} disabled={!datas || datas.length < 1}>選択したスケジュールに会員を紐づける</Button>
          </Box>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">月</TableCell>
                <TableCell className={classes.headerCell} align="center">火</TableCell>
                <TableCell className={classes.headerCell} align="center">水</TableCell>
                <TableCell className={classes.headerCell} align="center">木</TableCell>
                <TableCell className={classes.headerCell} align="center">金</TableCell>
                <TableCell className={classes.headerCell} align="center">土</TableCell>
                <TableCell className={classes.headerCellEnd} align="center">日</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(datas && datas.length >= 1) && (
              <TableRow>
                <TableCell name="mon" className={classes.tableCell}>
                  {monMenus.map((mon) => (
                    <WeekSelectBookingCard
                      key={`mon-menu-${mon.id}`}
                      menuInfo={mon}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="tue" className={classes.tableCell}>
                  {tueMenus.map((tue) => (
                    <WeekSelectBookingCard
                      key={`tue-menu-${tue.id}`}
                      menuInfo={tue}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="wed" className={classes.tableCell}>
                  {wedMenus.map((wed) => (
                    <WeekSelectBookingCard
                      key={`wed-menu-${wed.id}`}
                      menuInfo={wed}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="thu" className={classes.tableCell}>
                  {thuMenus.map((thu) => (
                    <WeekSelectBookingCard
                      key={`thu-menu-${thu.id}`}
                      menuInfo={thu}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="fri" className={classes.tableCell}>
                  {friMenus.map((fri) => (
                    <WeekSelectBookingCard
                      key={`fri-menu-${fri.id}`}
                      menuInfo={fri}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="sat" className={classes.tableCell}>
                  {satMenus.map((sat) => (
                    <WeekSelectBookingCard
                      key={`sat-menu-${sat.id}`}
                      menuInfo={sat}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
                <TableCell name="sun" className={classes.tableCellEnd}>
                  {sunMenus.map((sun) => (
                    <WeekSelectBookingCard
                      key={`sun-menu-${sun.id}`}
                      menuInfo={sun}
                      handleChange={this.handleChange}
                      selectedList={selectedList}
                    />
                  ))}
                </TableCell>
              </TableRow>
              )}
              {(!datas || datas.length < 1) && (
                <TableRow>
                  <TableCell className={classes.emptyTableCell} colSpan={7}>
                    データがありません
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withStyles(styles)(WeekScheduleTable);
