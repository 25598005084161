import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import LoadingButton from '../../organisms/loadingButton/LoadingButton';
import DialogTitle from '../../atoms/dialogTitle/DialogTitle';

export default function ConfirmDialog(props) {
  const {
    open,
    handleClose,
    title,
    contentText,
    handleExecute,
    executeText,
    loading,
    buttonColor,
    disabled,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <LoadingButton
          onClick={handleExecute}
          label={executeText}
          loading={loading}
          color={buttonColor}
          disabled={disabled}
        />
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  handleExecute: PropTypes.func.isRequired,
  executeText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  disabled: false,
};
